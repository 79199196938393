import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { findConnectedServer } from "helpers/addons";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import EmptyList from "components/shared/emptyList/EmptyList";

import { getCloudboxConnection } from "redux/handlers/addonsHandle";
import { getAllServersNotPaged } from "redux/handlers/serverHandler";

import { getCloudboxDataset } from "redux/selectors/cloudboxSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { ICloudDirectoryAPI } from "types/api/addonsApiInterface";
import { IAllServers } from "types/api/serversApiInterface";

import LinkServer from "../actions/LinkServer";
import RemoveServer from "../actions/RemoveServer";

const CloudboxServers = (): ReactElement => {
 const dispatch = useAppDispatch();

 const params = useParams<{ id: string; section: string }>();
 const cloudboxData = useSelector(getCloudboxDataset).find(
  (element) => element.id === Number(params.id)
 );

 const [servers, setServers] = useState<Array<IAllServers>>([]);
 const [directory, setDirectory] = useState<Array<ICloudDirectoryAPI>>([]);

 useEffect(() => {
  (async () => {
   setDirectory(await dispatch(getCloudboxConnection(Number(params.id))));
   setServers(await dispatch(getAllServersNotPaged()));
  })();
 }, [cloudboxData]);

 return (
  <Stack>
   {directory && directory.length > 0 ? (
    <Stack spacing={1} pt={1}>
     <Stack direction="row">
      <LinkServer
       servers={servers.filter((element) => element.os !== "windows")}
       cloud_id={Number(params.id)}
      />
     </Stack>
     <TableContainer component="div">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
       <TableHead>
        <TableRow>
         <TableCell>
          <FormattedMessage id="addons.cloudbox.serverName" />
         </TableCell>
         <TableCell>
          <FormattedMessage id="addons.cloudbox.status" />
         </TableCell>
         <TableCell>
          <FormattedMessage id="addons.cloudbox.directory" />
         </TableCell>
         <TableCell align="right">
          <FormattedMessage id="app.actions" />
         </TableCell>
        </TableRow>
       </TableHead>
       <TableBody>
        {directory.map((server, index) => {
         let res = findConnectedServer(server.server_id_db, servers);
         return (
          <TableRow key={`server-cloud-${index}`}>
           <TableCell>{res?.server_name}</TableCell>
           <TableCell>
            <Chip label="connected" color="success" size="small" />
           </TableCell>
           <TableCell>
            <Typography variant="caption">{server.folder}</Typography>
           </TableCell>
           <TableCell align="right">
            <RemoveServer id={server.id} />
           </TableCell>
          </TableRow>
         );
        })}
       </TableBody>
      </Table>
     </TableContainer>
    </Stack>
   ) : (
    <Stack p={1}>
     <EmptyList />
     <LinkServer
      servers={servers.filter((element) => element.os !== "windows")}
      cloud_id={Number(params.id)}
     />
    </Stack>
   )}
  </Stack>
 );
};

export default CloudboxServers;
