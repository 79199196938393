import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

import CreateCronjob from "../../actions/CreateCronjob";

const CronjobNotFound = ({ id }: { id: number }): ReactElement => {
 return (
  <Stack spacing={2}>
   <Alert severity="warning">
    <FormattedMessage id="docker.website.noCronjobFoundMessage" />
   </Alert>
   <Stack direction="row">
    <CreateCronjob id={id} />
   </Stack>
  </Stack>
 );
};

export default CronjobNotFound;
