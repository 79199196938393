import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postVirusActions } from "redux/handlers/gatewayHandler";

import { useAppDispatch } from "hooks/reduxHook";

const VirusOperations = ({
 id,
 virusId,
 action,
 closeMenu
}: {
 id: number;
 virusId: string;
 action: string;
 closeMenu: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postVirusActions(id, action, virusId));
  handleClose();
  setLoading(false);
  closeMenu();
 };

 return (
  <>
   <MenuItem onClick={() => handleOpen()}>
    <FormattedMessage id={`gateway.virus.${action}Mail`} />
   </MenuItem>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: `gateway.virus.${action}Mail` })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}>
    <Typography>
     <FormattedMessage id={`gateway.virus.${action}MailMessage`} />
    </Typography>
   </AppModal>
  </>
 );
};

export default VirusOperations;
