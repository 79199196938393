import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const DomainIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_5_212)">
   <rect width="20" height="20" fill="none" />
   <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M4.00239 10.25H0.503226C0.625557 14.9855 4.21321 18.8588 8.82426 19.428C6.02577 17.7622 4.07971 14.2887 4.00239 10.25ZM0 10V10.25H0.00306396C0.133705 15.5742 4.42576 19.8663 9.75 19.9969V20H9.99991L10 20L10.0001 20H10.25V19.9969C15.5742 19.8663 19.8663 15.5742 19.9969 10.25H20V10V9.75H19.9969C19.8663 4.42576 15.5742 0.133705 10.25 0.00306398V0H10H9.75V0.00306398C4.42576 0.133705 0.133706 4.42576 0.003064 9.75H0V10ZM0.503226 9.75C0.625559 5.01444 4.21328 1.14112 8.82439 0.572023C6.02584 2.23776 4.07973 5.71128 4.00239 9.75H0.503226ZM19.4968 9.75C19.3744 5.01445 15.7867 1.14112 11.1756 0.572024C13.9742 2.23776 15.9203 5.71129 15.9976 9.75H19.4968ZM15.4975 9.75C15.4139 5.53191 13.2195 2.02242 10.25 0.648372V9.75H15.4975ZM10.25 10.25H15.4975C15.4139 14.4681 13.2195 17.9776 10.25 19.3517V10.25ZM9.75 10.25V19.3517C6.7805 17.9776 4.58611 14.4681 4.50248 10.25H9.75ZM9.75 9.75H4.50248C4.58613 5.53192 6.78051 2.02243 9.75 0.648376V9.75ZM15.9976 10.25H19.4968C19.3744 14.9855 15.7868 18.8588 11.1757 19.428C13.9742 17.7622 15.9203 14.2887 15.9976 10.25Z"
    fill="currentColor"
   />
  </g>
  <defs>
   <clipPath id="clip0_5_212">
    <rect width="20" height="20" fill="none" />
   </clipPath>
  </defs>
 </svg>,
 "Domain"
);
