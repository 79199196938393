import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import notificationReducer from "redux/reducers/notificationReducer";

import { INotification, IPreferences, ITypesRenew } from "types/api/notificationApiInterface";

import { ApiService } from "service/ApiService";

export const getNotifications = async (): Promise<Array<INotification>> => {
 try {
  const { data } = await ApiService<Array<INotification>>("/notific/getnotifics");
  return data;
 } catch (error: any) {
  console.warn(error);
  return [];
 }
};

export const getUserTypes = async (): Promise<Array<string>> => {
 try {
  const { data } = await ApiService("/services/gettypes");
  return data;
 } catch (error: any) {
  console.warn(error);
  return [];
 }
};

export const getUserSingleType = async (
 type: string
): Promise<{ email_list: Array<string>; send_by_email: boolean }> => {
 try {
  const { data } = await ApiService<{ email_list: Array<string>; send_by_email: boolean }>(
   "/user/gettype",
   "POST",
   { type: type }
  );
  return data;
 } catch (error: any) {
  console.warn(error);
  return { email_list: [], send_by_email: false };
 }
};

export const getUserPreferences = async (): Promise<Array<IPreferences>> => {
 try {
  const { data } = await ApiService<Array<IPreferences>>("/usernotification/preferences");
  return data;
 } catch (error: any) {
  console.warn(error);
  return [];
 }
};

export const getUserChannels =
 (type: string): AppAction<Promise<ITypesRenew | null>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<ITypesRenew>("/usernotification/getbytypeforrenews", "POST", {
    type: type
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get type data - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return null;
  }
 };

export const postMarkAsRead =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/notific/markasread", "POST", {
    id: id
   });
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to set notification read - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postMarkAllAsRead = (): AppAction<Promise<void>> => async (dispatch) => {
 try {
  await ApiService("/notific/markallasread");
 } catch (error: any) {
  dispatch(
   notificationReducer.actions.addAlert({
    id: generateUniqueId(),
    message: `Failed to set notification read - ${error?.message || "unknown error"}`,
    timestamp: Date.now(),
    type: "error"
   })
  );
 }
};

export const postDeleteAllNotific = (): AppAction<Promise<void>> => async (dispatch) => {
 try {
  await ApiService("/notific/deleteallnotifics");
 } catch (error: any) {
  dispatch(
   notificationReducer.actions.addAlert({
    id: generateUniqueId(),
    message: `Failed to delete all notification - ${error?.message || "unknown error"}`,
    timestamp: Date.now(),
    type: "error"
   })
  );
 }
};

export const postDeleteNotification =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/notific/deletenotific", "POST", {
    id: id
   });
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete notification - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateChannel =
 (email_list: Array<string>, send_by_email: boolean): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/user/editprofiledata", "POST", {
    email_list: email_list,
    send_by_email: send_by_email
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Settings updated",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update settings - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateSettings =
 (id: number, type_active: boolean, frequency: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/usernotification/update", "POST", {
    id: id,
    type_active: type_active,
    frequency: frequency
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Settings updated",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete notification - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateNotificationSettings =
 (
  id: number,
  type: string,
  type_active: boolean,
  send_by_mail: boolean,
  send_by_telegram: boolean,
  sub_types: string[],
  sub_types_active: boolean[],
  frequency: number,
  email_list: string[]
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/usernotification/update", "POST", {
    id: id,
    type: type,
    type_active: type_active,
    send_by_mail: send_by_mail,
    send_by_telegram: send_by_telegram,
    sub_types: sub_types,
    sub_types_active: sub_types_active,
    frequency: frequency,
    email_list: email_list
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Settings updated",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete notification - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };
