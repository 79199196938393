import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

export const websiteApi = (state: AppState) => state.Dockerwebsite;

export const getDockerWebsiteNumber = createSelector(websiteApi, (websites) => {
 return websites?.totalCount || 0;
});

export const getDockerWebsitesList = createSelector(websiteApi, (websites) => {
 return websites?.dataset || [];
});
