import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const RamIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <path
   d="M2 12H18M4 12V15M12 12V15M6 12V15M14 12V15M8 12V15M16 12V15M10 12V14.5M7 5V12M13 5V12M9.21115 14.8944L9.55279 14.7236C9.83431 14.5828 10.1657 14.5828 10.4472 14.7236L10.7889 14.8944C10.9277 14.9639 11.0808 15 11.2361 15H17C17.5523 15 18 14.5523 18 14V6C18 5.44772 17.5523 5 17 5H3C2.44772 5 2 5.44772 2 6V14C2 14.5523 2.44772 15 3 15H8.76393C8.91918 15 9.07229 14.9639 9.21115 14.8944Z"
   stroke="currentColor"
   strokeWidth="1"
  />
 </svg>,
 "Ram"
);
