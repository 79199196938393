import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { IServerService } from "types/api/serversApiInterface";

const initialState: IServerService = {
 apache2: "no",
 redis: "no",
 nginx: "no",
 ssh: "no",
 dovecot: "no",
 php: "no",
 ftp: "no",
 memcache: "no",
 postfix: "no",
 mysql: "no",
 sshOn: false,
 mysql_log: "",
 apache_log: "",
 postfix_log: "",
 ftp_log: "",
 redis_log: "",
 cpu_apache: 0,
 cpu_mysql: 0,
 cpu_nginx: 0,
 cpu_phpengine: 0
};

const serverServicesReducer = createSlice({
 name: "serverServices",
 initialState,
 reducers: {
  setServerServices: (state, { payload }: PayloadAction<IServerService>) => {
   return payload;
  },
  resetServers: () => {
   return initialState;
  }
 }
});

export default serverServicesReducer;
