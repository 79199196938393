import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

import { IJobqueueDataset } from "types/api/jobqueueApiInterface";

export const jobqueueApi = (state: AppState) => state.Jobqueue;

export const getJobqueueNumber = createSelector(jobqueueApi, (job) => {
 return job?.totalCount || 0;
});

export const getJobqueueList = createSelector(jobqueueApi, (job) => {
 return job?.dataset.map((jobData: IJobqueueDataset) => ({
  action: jobData.action,
  status: jobData.status,
  id: jobData.id,
  isp_id: jobData.isp_id,
  params: jobData.params,
  response: jobData.response,
  type: jobData.type,
  createdAt: jobData.createdAt
 }));
});
