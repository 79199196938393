import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { IFirewallApi } from "types/api/addonsApiInterface";

const initialState: IFirewallApi = { dataset: [], totalCount: 0 };

const FirewallReducer = createSlice({
 name: "firewalls",
 initialState,
 reducers: {
  setFirewalls: (state, { payload }: PayloadAction<IFirewallApi>) => {
   return payload;
  },
  resetFirewalls: () => {
   return initialState;
  }
 }
});

export default FirewallReducer;
