import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

import { IspDataset } from "types/api/IspIdApiInterface";

export const ispApi = (state: AppState) => state.Isp;

export const getIspNumber = createSelector(ispApi, (isp) => {
 return isp?.totalCount || 0;
});

export const getIspList = createSelector(ispApi, (isp) => {
 return (isp?.dataset.map((ispData: IspDataset) => ({
  id: ispData.id,
  userid: ispData.userid,
  ip1: ispData.ip1,
  ip2: ispData.ip2,
  hostname: ispData.hostname,
  php_mode: ispData.php_mode,
  php_versions: ispData.php_versions,
  server_id: ispData.server_id,
  webserver_type: ispData.webserver_type
 })) || []) as IspDataset[];
});
