import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import { sitesLinks } from "constants/staticLinks";

import SectionsBreadCrumbs from "components/shared/breadCrumbs/SectionsBreadCrumbs";
import MobileSectionMenu from "components/shared/sideMenu/MobileSectionMenu";
import SectionMenu from "components/shared/sideMenu/SectionMenu";
import WebsitesDetailsPage from "components/websites/websitesDetails/WebsitesDetailsPage";

import { postSyncronizeSocket } from "redux/handlers/socketHandler";
import { getWebsiteDetail } from "redux/handlers/websitesHandler";

import { useAppDispatch } from "hooks/reduxHook";

import WebsitesBackup from "../backup/WebsitesBackup";
import WebsitesCronjob from "../cronjob/WebsitesCronjob";

import WebsiteUpperPage from "./WebsiteUpperPage";

const WebsitesManagePage = (): ReactElement => {
 const location = useLocation();
 const { socket } = useContext(AppContext);
 const dispatch = useAppDispatch();

 const params = useParams<{ domain_id: string; section: string }>();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [reloadData, setReloadData] = useState<boolean>(false);

 const RenderWebsitePages = (): ReactElement => {
  switch (params.section) {
   case "details":
    return <WebsitesDetailsPage />;
   case "backup":
    return <WebsitesBackup />;
   case "cronjob":
    return <WebsitesCronjob />;
   default:
    return <WebsitesDetailsPage />;
  }
 };

 useEffect(() => {
  !isNil(socket) &&
   socket.on("app_data", (value): void => {
    if (value?.completed && value?.section === "site") {
     setReloadData(true);
    }
   });
  return () => {
   !isNil(socket) && socket.off("app_data");
  };
 }, [socket]);

 useEffect(() => {
  (async () => {
   if (reloadData) {
    params.domain_id && (await dispatch(getWebsiteDetail(Number(params.domain_id))));
    setReloadData(false);
   }
  })();
 }, [reloadData]);

 useEffect(() => {
  (async () => {
   params.domain_id && (await dispatch(getWebsiteDetail(Number(params.domain_id))));
   setIsLoading(false);
   await dispatch(postSyncronizeSocket("site"));
  })();
 }, [params.domain_id]);

 return !isLoading ? (
  <Container maxWidth="xl">
   <Stack pt={1}>
    <SectionsBreadCrumbs
     links={[
      { name: "home", url: "/" },
      { name: "apps", url: "/app/sites" },
      { name: "sites", url: "/app/sites" },
      { name: params?.section || "", url: location.pathname }
     ]}
    />
   </Stack>
   <Grid container>
    {desktopViewPort ? (
     <Grid xs={desktopViewPort ? 3 : 0}>
      <Stack mt={6}>
       <SectionMenu
        menuList={sitesLinks.map((item) => ({
         name: item.name,
         url: `/app/sites/${params?.domain_id}/${item.url}`,
         icon: item.icon
        }))}
       />
      </Stack>
     </Grid>
    ) : (
     <MobileSectionMenu links={sitesLinks} disabled={[]} url={`/app/sites/${params?.domain_id}`} />
    )}
    <Grid xs={desktopViewPort ? 9 : 12} mt={3}>
     <WebsiteUpperPage />
     <RenderWebsitePages />
    </Grid>
   </Grid>
  </Container>
 ) : (
  <></>
 );
};

export default WebsitesManagePage;
