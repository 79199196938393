import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import AddIcon from "@mui/icons-material/Add";

import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";

import AppModal from "components/shared/modal/AppModal";

import { postAddProject } from "redux/handlers/hetznerHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { HetznerData } from "types/api/hetznerApi";
import { IUsersList } from "types/api/userApiInterface";

const AddProject = ({
 contacts,
 reloadData
}: {
 contacts: Array<IUsersList>;
 reloadData?: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);

 const handleOpen = () => {
  reset();
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const { control, handleSubmit, setValue, reset } = useForm({
  defaultValues: {
   partner: 0,
   name: "",
   apiKey: ""
  }
 });

 const onSubmit: SubmitHandler<HetznerData> = async (data: HetznerData) => {
  setIsLoading(true);
  await dispatch(postAddProject(data.name, data.apiKey, data.partner));
  setIsLoading(false);
  reloadData && reloadData();
  setOpenModal(false);
 };

 return (
  <Stack>
   {desktopViewPort ? (
    <Button onClick={handleOpen} variant="kxActionButton" endIcon={<AddIcon />}>
     <FormattedMessage id="configurator.add.project" />
    </Button>
   ) : (
    <IconButton onClick={handleOpen}>
     <AddIcon />
    </IconButton>
   )}
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    handleConfirm={handleSubmit(onSubmit)}
    title={intl.formatMessage({ id: "configurator.add.project" })}>
    <form onSubmit={handleSubmit(onSubmit)}>
     <Stack>
      <Controller
       name="partner"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <Autocomplete
         fullWidth={true}
         autoHighlight
         sx={{ my: 2 }}
         onChange={(e, value) => {
          setValue("partner", value?.id || 0);
         }}
         options={contacts.map((element) => {
          return {
           label:
            `${element.firstname} ${element.lastname} - ${element.companyName}` || "Not provided",
           id: element.id,
           key: element.userid
          };
         })}
         renderOption={(props, option) => {
          return (
           <li {...props} key={`client-add-${option.id}`}>
            {option.label}
           </li>
          );
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           {...field}
           size="small"
           InputLabelProps={{ shrink: true }}
           label={intl.formatMessage({ id: "configurator.hetzner.partner" })}
          />
         )}
        />
       )}
      />
      <Controller
       name={"name"}
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         size="small"
         label={intl.formatMessage({ id: "configurator.hetzner.name" })}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         autoComplete="off"
        />
       )}
      />
      <Controller
       name={"apiKey"}
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         size="small"
         label={intl.formatMessage({ id: "configurator.hetzner.apiKey" })}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         autoComplete="off"
        />
       )}
      />
     </Stack>
    </form>
   </AppModal>
  </Stack>
 );
};

export default AddProject;
