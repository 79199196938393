import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const FloatingipIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
 </svg>,
 "Floatingip"
);
