import React, { ReactElement, useState } from "react";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";

import { isNil } from "ramda";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";

import { IZoneList } from "types/api/appsApiInterface";
import { IMailNotPagedData } from "types/api/mailApiInterface";
import { IAllWebsiteData } from "types/api/websitesApiInterface";
import { appPermissionsType } from "types/global/user";

type sectionType =
 | "editPowerOffOnSite"
 | "editPowerOffOnMail"
 | "editPowerOffOnZone"
 | "showZoneRecords"
 | "editZoneRecords"
 | "showMailDetails"
 | "editMailDetails"
 | "showMailbox"
 | "editMailbox"
 | "showAutomaticMail"
 | "editAutomaticMail"
 | "showWhiteBlackMailList"
 | "editWhiteBlackMailList"
 | "showSiteDetails"
 | "editSiteDetails"
 | "showSiteFtpCredentials"
 | "editSiteFtpCredentials"
 | "showSiteAlias"
 | "editSiteAlias"
 | "showSiteDatabase"
 | "editSiteDatabase"
 | "showSiteBackups"
 | "editSiteBackups"
 | "showSiteCronjobs"
 | "editSiteCronjobs";

type arrayType = "sites" | "mails" | "zones";

const AppsSection = ({
 control,
 sites,
 mails,
 zone,
 selectedSites,
 selectedMails,
 selectedZone,
 appsPermissions,
 setApp,
 addApp,
 setPermission
}: {
 control: any;
 sites: Array<IAllWebsiteData>;
 mails: Array<IMailNotPagedData>;
 zone: Array<IZoneList>;
 selectedSites: Array<number>;
 selectedMails: Array<number>;
 selectedZone: Array<number>;
 appsPermissions: appPermissionsType;
 setApp: (section: arrayType, app: Array<number>) => void;
 addApp: (section: arrayType, value: Array<{ id: number; label: string }>) => void;
 setPermission: (section: sectionType, value: boolean) => void;
}): ReactElement => {
 const intl = useIntl();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const [section, setSection] = useState<number>(0);

 const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
  setSection(newValue);
 };

 const RenderTabContent = (): ReactElement => {
  switch (section) {
   case 0:
    return (
     <Stack spacing={2}>
      <Controller
       name="sites"
       control={control}
       render={({ field }) => (
        <Autocomplete
         {...field}
         multiple
         id="tags-outlined"
         options={sites.map((element) => {
          return {
           id: element.id,
           label: element.site
          };
         })}
         filterSelectedOptions
         value={sites
          .filter((element) => selectedSites.includes(element.id))
          .map((element) => {
           return {
            id: element.id,
            label: element.site
           };
          })}
         getOptionLabel={(option) => option.label}
         onChange={(e, value) => {
          isNil(value) ? setApp("sites", selectedSites) : addApp("sites", value);
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           label={intl.formatMessage({ id: "teams.usersettings.selectSites" })}
           placeholder="Site..."
          />
         )}
        />
       )}
      />
      <Stack direction={"row"}>
       <Controller
        name="editPowerOffOnSite"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={appsPermissions.editPowerOffOnSite}
            onChange={(e, checked) => {
             setPermission("editPowerOffOnSite", checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.editPowerOffOnSite" })}
         />
        )}
       />
       <Controller
        name="showSiteDetails"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={appsPermissions.showSiteDetails}
            onChange={(e, checked) => {
             setPermission("showSiteDetails", checked);
             if (!checked) setPermission("editSiteDetails", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showSiteDetails" })}
         />
        )}
       />
       <Controller
        name="showSiteFtpCredentials"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={appsPermissions.showSiteFtpCredentials}
            onChange={(e, checked) => {
             setPermission("showSiteFtpCredentials", checked);
             if (!checked) setPermission("editSiteFtpCredentials", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showSiteFtpCredentials" })}
         />
        )}
       />
       <Controller
        name="showSiteAlias"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={appsPermissions.showSiteAlias}
            onChange={(e, checked) => {
             setPermission("showSiteAlias", checked);
             if (!checked) setPermission("editSiteAlias", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showSiteAlias" })}
         />
        )}
       />
       <Controller
        name="showSiteDatabase"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={appsPermissions.showSiteDatabase}
            onChange={(e, checked) => {
             setPermission("showSiteDatabase", checked);
             if (!checked) setPermission("editSiteDatabase", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showSiteDatabase" })}
         />
        )}
       />
       <Controller
        name="showSiteBackups"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={appsPermissions.showSiteBackups}
            onChange={(e, checked) => {
             setPermission("showSiteBackups", checked);
             if (!checked) setPermission("editSiteBackups", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showSiteBackups" })}
         />
        )}
       />
       <Controller
        name="showSiteCronjobs"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={appsPermissions.showSiteCronjobs}
            onChange={(e, checked) => {
             setPermission("showSiteCronjobs", checked);
             if (!checked) setPermission("editSiteCronjobs", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showSiteCronjobs" })}
         />
        )}
       />
      </Stack>
      <Stack direction="row">
       {appsPermissions.showSiteDetails && (
        <Controller
         name="editSiteDetails"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={appsPermissions.editSiteDetails}
             onChange={(e, checked) => {
              setPermission("editSiteDetails", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editSiteDetails" })}
          />
         )}
        />
       )}
       {appsPermissions.showSiteFtpCredentials && (
        <Controller
         name="editSiteFtpCredentials"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={appsPermissions.editSiteFtpCredentials}
             onChange={(e, checked) => {
              setPermission("editSiteFtpCredentials", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editSiteFtpCredentials" })}
          />
         )}
        />
       )}
       {appsPermissions.showSiteAlias && (
        <Controller
         name="editSiteAlias"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={appsPermissions.editSiteAlias}
             onChange={(e, checked) => {
              setPermission("editSiteAlias", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editSiteAlias" })}
          />
         )}
        />
       )}
       {appsPermissions.showSiteDatabase && (
        <Controller
         name="editSiteDatabase"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={appsPermissions.editSiteDatabase}
             onChange={(e, checked) => {
              setPermission("editSiteDatabase", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editSiteDatabase" })}
          />
         )}
        />
       )}
       {appsPermissions.showSiteBackups && (
        <Controller
         name="editSiteBackups"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={appsPermissions.editSiteBackups}
             onChange={(e, checked) => {
              setPermission("editSiteBackups", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editSiteBackups" })}
          />
         )}
        />
       )}
       {appsPermissions.showSiteCronjobs && (
        <Controller
         name="editSiteCronjobs"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={appsPermissions.editSiteCronjobs}
             onChange={(e, checked) => {
              setPermission("editSiteCronjobs", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editSiteCronjobs" })}
          />
         )}
        />
       )}
      </Stack>
     </Stack>
    );
   case 1:
    return (
     <Stack spacing={2}>
      <Controller
       name="mails"
       control={control}
       render={({ field }) => (
        <Autocomplete
         {...field}
         multiple
         id="tags-outlined"
         options={mails.map((element) => {
          return {
           id: element.id,
           label: element.domain
          };
         })}
         filterSelectedOptions
         value={mails
          .filter((element) => selectedMails.includes(element.id))
          .map((element) => {
           return {
            id: element.id,
            label: element.domain
           };
          })}
         getOptionLabel={(option) => option.label}
         onChange={(e, value) => {
          isNil(value) ? setApp("mails", selectedMails) : addApp("mails", value);
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           label={intl.formatMessage({ id: "teams.usersettings.selectMails" })}
           placeholder="Mail domain..."
          />
         )}
        />
       )}
      />
      <Stack direction={"row"}>
       <Controller
        name="editPowerOffOnMail"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={appsPermissions.editPowerOffOnMail}
            onChange={(e, checked) => {
             setPermission("editPowerOffOnMail", checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.editPowerOffOnMail" })}
         />
        )}
       />
       <Controller
        name="showMailDetails"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={appsPermissions.showMailDetails}
            onChange={(e, checked) => {
             setPermission("showMailDetails", checked);
             if (!checked) setPermission("editMailDetails", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showMailDetails" })}
         />
        )}
       />
       <Controller
        name="showMailbox"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={appsPermissions.showMailbox}
            onChange={(e, checked) => {
             setPermission("showMailbox", checked);
             if (!checked) setPermission("editMailbox", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showMailbox" })}
         />
        )}
       />
       <Controller
        name="showAutomaticMail"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={appsPermissions.showAutomaticMail}
            onChange={(e, checked) => {
             setPermission("showAutomaticMail", checked);
             if (!checked) setPermission("editAutomaticMail", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showAutomaticMail" })}
         />
        )}
       />
       <Controller
        name="showWhiteBlackMailList"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={appsPermissions.showWhiteBlackMailList}
            onChange={(e, checked) => {
             setPermission("showWhiteBlackMailList", checked);
             if (!checked) setPermission("editWhiteBlackMailList", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showWhiteBlackMailList" })}
         />
        )}
       />
      </Stack>
      <Stack direction="row">
       {appsPermissions.showMailDetails && (
        <Controller
         name="editMailDetails"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={appsPermissions.editMailDetails}
             onChange={(e, checked) => {
              setPermission("editMailDetails", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editMailDetails" })}
          />
         )}
        />
       )}
       {appsPermissions.showMailbox && (
        <Controller
         name="editMailbox"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={appsPermissions.editMailbox}
             onChange={(e, checked) => {
              setPermission("editMailbox", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editMailbox" })}
          />
         )}
        />
       )}
       {appsPermissions.showAutomaticMail && (
        <Controller
         name="editAutomaticMail"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={appsPermissions.editAutomaticMail}
             onChange={(e, checked) => {
              setPermission("editAutomaticMail", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editAutomaticMail" })}
          />
         )}
        />
       )}
       {appsPermissions.showWhiteBlackMailList && (
        <Controller
         name="editWhiteBlackMailList"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={appsPermissions.editWhiteBlackMailList}
             onChange={(e, checked) => {
              setPermission("editWhiteBlackMailList", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editWhiteBlackMailList" })}
          />
         )}
        />
       )}
      </Stack>
     </Stack>
    );
   case 2:
    return (
     <Stack spacing={2}>
      <Controller
       name="zones"
       control={control}
       render={({ field }) => (
        <Autocomplete
         {...field}
         multiple
         id="tags-outlined"
         options={zone.map((element) => {
          return {
           id: element.id,
           label: element.zone
          };
         })}
         filterSelectedOptions
         value={zone
          .filter((element) => selectedZone.includes(element.id))
          .map((element) => {
           return {
            id: element.id,
            label: element.zone
           };
          })}
         getOptionLabel={(option) => option.label}
         onChange={(e, value) => {
          isNil(value) ? setApp("zones", selectedZone) : addApp("zones", value);
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           label={intl.formatMessage({ id: "teams.usersettings.selectZones" })}
           placeholder="Zone..."
          />
         )}
        />
       )}
      />
      <Stack direction={"row"}>
       <Controller
        name="editPowerOffOnZone"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={appsPermissions.editPowerOffOnZone}
            onChange={(e, checked) => {
             setPermission("editPowerOffOnZone", checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.editPowerOffOnZone" })}
         />
        )}
       />
       <Controller
        name="showZoneRecords"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={appsPermissions.showZoneRecords}
            onChange={(e, checked) => {
             setPermission("showZoneRecords", checked);
             if (!checked) setPermission("editZoneRecords", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showZoneRecords" })}
         />
        )}
       />
      </Stack>
      <Stack direction="row">
       {appsPermissions.showZoneRecords && (
        <Controller
         name="editZoneRecords"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={appsPermissions.editZoneRecords}
             onChange={(e, checked) => {
              setPermission("editZoneRecords", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editZoneRecords" })}
          />
         )}
        />
       )}
      </Stack>
     </Stack>
    );
   default:
    return (
     <Stack spacing={2}>
      <Controller
       name="sites"
       control={control}
       render={({ field }) => (
        <Autocomplete
         {...field}
         multiple
         id="tags-outlined"
         options={sites.map((element) => {
          return {
           id: element.id,
           label: element.site
          };
         })}
         filterSelectedOptions
         value={sites
          .filter((element) => selectedSites.includes(element.id))
          .map((element) => {
           return {
            id: element.id,
            label: element.site
           };
          })}
         getOptionLabel={(option) => option.label}
         onChange={(e, value) => {
          isNil(value) ? setApp("sites", selectedSites) : addApp("sites", value);
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           label={intl.formatMessage({ id: "teams.usersettings.selectSites" })}
           placeholder="Site..."
          />
         )}
        />
       )}
      />
      <Stack direction={"row"}>
       <Controller
        name="editPowerOffOnSite"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={appsPermissions.editPowerOffOnSite}
            onChange={(e, checked) => {
             setPermission("editPowerOffOnSite", checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.editPowerOffOnSite" })}
         />
        )}
       />
      </Stack>
     </Stack>
    );
  }
 };

 return (
  <Accordion>
   <AccordionSummary expandIcon={<ExpandMoreIcon />}>Apps</AccordionSummary>
   <AccordionDetails>
    <Tabs
     value={section}
     onChange={handleChangeTab}
     variant={desktopViewPort ? "standard" : "scrollable"}
     scrollButtons={true}
     allowScrollButtonsMobile
     sx={{ mb: 2 }}>
     <Tab label={intl.formatMessage({ id: "app.sitesTitle" })} />
     <Tab label={intl.formatMessage({ id: "app.mail" })} />
     <Tab label={intl.formatMessage({ id: "app.zone" })} />
    </Tabs>
    <RenderTabContent />
   </AccordionDetails>
  </Accordion>
 );
};

export default AppsSection;
