import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postDeleteContainer } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

const DeleteContainer = ({
 id,
 container_id,
 closeMenu
}: {
 id: number;
 container_id: number;
 closeMenu: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postDeleteContainer(id, container_id));
  handleClose();
  setLoading(false);
  closeMenu();
 };

 return (
  <>
   <MenuItem onClick={() => handleOpen()}>
    <FormattedMessage id="docker.container.deleteContainer" />
   </MenuItem>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.container.deleteContainer" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}>
    <Typography>
     <FormattedMessage id="docker.container.deleteContainerMessage" />
    </Typography>
   </AppModal>
  </>
 );
};

export default DeleteContainer;
