import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import Alert from "@mui/material/Alert";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import AppModal from "components/shared/modal/AppModal";

import { postMountVolume } from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

const MountVolume = ({ id, closeMenu }: { id: number; closeMenu: () => void }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => {
  reset();
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const { control, reset, handleSubmit, setValue, formState, watch } = useForm({
  defaultValues: {
   directory: "",
   flag: false
  }
 });

 const onSubmit: SubmitHandler<{ directory: string; flag: boolean }> = async (data: {
  directory: string;
  flag: boolean;
 }) => {
  setLoading(true);
  await dispatch(postMountVolume(id, data.directory, data.flag));
  handleClose();
  setLoading(false);
  closeMenu();
 };

 return (
  <>
   <MenuItem onClick={handleOpen}>
    <FormattedMessage id="addons.volumes.mountVolume" />
   </MenuItem>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "addons.volumes.mountVolume" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={loading || watch("directory") === ""}>
    <form onSubmit={handleSubmit(onSubmit)}>
     <Controller
      name="directory"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
         e.stopPropagation();
        }}
        label={intl.formatMessage({ id: "addons.volumes.directory" })}
        error={formState.isDirty && !!formState?.errors?.directory}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
       />
      )}
     />
     <Controller
      name="flag"
      control={control}
      render={({ field }) => (
       <FormControlLabel
        {...field}
        control={
         <Checkbox
          checked={watch("flag")}
          onChange={(e) => {
           setValue("flag", e.target.checked);
          }}
         />
        }
        label={intl.formatMessage({ id: "addons.volumes.deleteFlag" })}
       />
      )}
     />
     {watch("flag") && (
      <Alert severity="warning">
       <FormattedMessage id="addons.volumes.deletingMessage" />
      </Alert>
     )}
    </form>
   </AppModal>
  </>
 );
};

export default MountVolume;
