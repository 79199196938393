import React, { ReactElement, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import useMediaQuery from "@mui/material/useMediaQuery";

import { getAllUserNetworks } from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

import { IUserNetwork } from "types/api/addonsApiInterface";

import NetworkingPrivateNetwork from "./NetworkingPrivateNetwork";
import NetworkingPublicNetwork from "./NetworkingPublicNetwork";

const LoadBalancerNetwork = (): ReactElement => {
 const intl = useIntl();
 const theme = useTheme();
 const dispatch = useAppDispatch();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const [section, setSection] = useState<number>(0);
 const [networkList, setNetworkList] = useState<Array<IUserNetwork>>([]);
 const [loading, setLoading] = useState<boolean>(true);

 useEffect(() => {
  (async () => {
   setNetworkList(await dispatch(getAllUserNetworks()));
   setLoading(false);
  })();
 }, []);

 const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
  setSection(newValue);
 };

 const RenderTabContent = (): ReactElement => {
  switch (section) {
   case 0:
    return <NetworkingPublicNetwork />;
   case 1:
    return <NetworkingPrivateNetwork networkList={networkList} />;
   default:
    return <NetworkingPublicNetwork />;
  }
 };

 return loading ? (
  <Paper elevation={0} sx={{ mt: 3, pb: 0.7, borderRadius: "10px", boxShadow: 0 }}>
   <CircularProgress />
  </Paper>
 ) : (
  <Paper elevation={0} sx={{ mt: 3, pb: 0.7, borderRadius: "10px", boxShadow: 0 }}>
   <Tabs
    value={section}
    onChange={handleChangeTab}
    variant={desktopViewPort ? "standard" : "scrollable"}
    scrollButtons={true}
    allowScrollButtonsMobile>
    <Tab label={intl.formatMessage({ id: "addons.loadBalancer.publicNetwork" })} />
    <Tab label={intl.formatMessage({ id: "addons.loadBalancer.privateNetwork" })} />
   </Tabs>
   <RenderTabContent />
  </Paper>
 );
};

export default LoadBalancerNetwork;
