import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { searchOs } from "helpers/server";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { IOsDetails } from "types/api/serversApiInterface";

const ServerOsBox = ({
 selectedOs,
 osList,
 selectOs
}: {
 selectedOs: number;
 osList: Array<IOsDetails>;
 selectOs: (os: IOsDetails) => void;
}): ReactElement => {
 return (
  <Stack direction="row" flexWrap="wrap">
   {osList.map((element, index) => {
    return selectedOs === element.id ? (
     <Paper
      sx={{
       backgroundColor: "#c8e6c9",
       px: 4,
       py: 2,
       m: 1
      }}
      onClick={() => selectOs(element)}>
      <img src={searchOs(element.name)} alt="os-logo" height={40} />
      <Stack direction="row" justifyContent="center" alignItems="center" mt={1}>
       <Typography fontWeight="bold" textTransform="uppercase">
        {element.name}
       </Typography>
      </Stack>
     </Paper>
    ) : (
     <Tooltip key={`os-paper-${index}`} title={<FormattedMessage id="server.add.selectOsTitle" />}>
      <Paper
       sx={{
        px: 4,
        py: 2,
        cursor: "pointer",
        m: 1
       }}
       onClick={() => selectOs(element)}>
       <img src={searchOs(element.name)} alt="os-logo" height={40} />
       <Stack direction="row" justifyContent="center" alignItems="center" mt={1}>
        <Typography fontWeight="bold" textTransform="uppercase">
         {element.name}
        </Typography>
       </Stack>
      </Paper>
     </Tooltip>
    );
   })}
  </Stack>
 );
};

export default ServerOsBox;
