import React, { ReactElement, useEffect, useState } from "react";

import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { getAllFtpUsers } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IFtpData } from "types/api/dockerApiInterface";

import CreateFtpUser from "../../actions/CreateFtpUser";
import DeleteFtpUser from "../../actions/DeleteFtpUser";

import FtpuserNotFound from "./FtpuserNotFound";

const FtpUserSection = ({ id }: { id: number }): ReactElement => {
 const dispatch = useAppDispatch();

 const [cronjobs, setCronjobs] = useState<Array<IFtpData>>([]);
 const [loading, setLoading] = useState<boolean>(false);

 useEffect(() => {
  (async () => {
   setLoading(true);
   if (id) {
    const allCronjob = await dispatch(getAllFtpUsers(id));
    setCronjobs(allCronjob);
   }
   setLoading(false);
  })();
 }, []);

 return loading ? (
  <CircularProgress />
 ) : cronjobs.length > 0 ? (
  <Stack width="stretch">
   <Stack direction="row">
    <CreateFtpUser id={id} />
   </Stack>
   <TableContainer component="div">
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
     {cronjobs.map((element, index) => {
      return (
       <TableRow key={`cron-table-${index}`}>
        <TableCell component="th" scope="row">
         <Chip
          size="small"
          label={element.status}
          color={element.status === "online" ? "success" : "error"}
         />
        </TableCell>
        <TableCell component="th" scope="row">
         <Stack>
          <Typography>{`Username: ${element.ftp_user}`}</Typography>
          <Typography>{`Password: ${element.ftp_password}`}</Typography>
         </Stack>
        </TableCell>
        <TableCell component="th" scope="row">
         {element.ftp_address}
        </TableCell>
        <TableCell component="th" scope="row">
         {element.ftp_directory}
        </TableCell>
        <TableCell align="right">
         <DeleteFtpUser id={element.id} />
        </TableCell>
       </TableRow>
      );
     })}
    </Table>
   </TableContainer>
  </Stack>
 ) : (
  <FtpuserNotFound id={id} />
 );
};

export default FtpUserSection;
