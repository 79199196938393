import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postChangeCronjobStatus } from "redux/handlers/websitesHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { cronjobTableData } from "types/global/table";

const ActivateCronjob = ({
 rowData,
 closeMenu
}: {
 rowData: cronjobTableData;
 closeMenu: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const params = useParams<{ domain_id: string; section: string }>();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(
   postChangeCronjobStatus(
    rowData.id,
    rowData.isp_id,
    rowData.command,
    "url",
    rowData.parent_domain_id,
    rowData.run_min,
    rowData.run_hour,
    rowData.run_mday,
    rowData.run_month,
    rowData.run_wday || "",
    Number(params.domain_id),
    "y"
   )
  );
  setLoading(false);
  handleClose();
  closeMenu();
 };

 return (
  <>
   <MenuItem onClick={handleOpen}>
    <FormattedMessage id="sites.cronjob.activate" />
   </MenuItem>
   <AppModal
    open={openModal}
    close={handleClose}
    disabled={loading}
    title={intl.formatMessage({ id: "sites.cronjob.activate" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}>
    <Typography>
     <FormattedMessage id="sites.cronjob.activateMessage" />
    </Typography>
   </AppModal>
  </>
 );
};

export default ActivateCronjob;
