import React, { ReactElement, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPriceWithoutIva } from "helpers/generic";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";

import { postCompleteStripePayment } from "redux/handlers/rechargeHandler";

import { getUserTax } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

const PaymentConfirm = (): ReactElement => {
 const navigate = useNavigate();
 const dispatch = useAppDispatch();

 const tax = useSelector(getUserTax);

 useEffect(() => {
  const amount = new URLSearchParams(window.location.search).get("amount");

  if (!amount) return;

  dispatch(
   postCompleteStripePayment(Number(amount) / 100, getPriceWithoutIva(Number(amount) / 100, tax))
  );
 }, []);

 const handleReturnToRecharge = () => {
  navigate("/profile/recharge");
 };

 return (
  <Grid container>
   <Grid xs={12} p={2} justifyContent="center" display="flex">
    <Paper elevation={0} sx={{ p: 2, minWidth: "500px", borderRadius: "10px", boxShadow: 0 }}>
     <Stack spacing={2}>
      <Alert severity="success">
       <FormattedMessage id="recharge.paymentConfirm" />
      </Alert>
      <Button variant="kxActionButton" onClick={handleReturnToRecharge}>
       <FormattedMessage id="recharge.returnToProfile" />
      </Button>
     </Stack>
    </Paper>
   </Grid>
  </Grid>
 );
};

export default PaymentConfirm;
