import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux/es/exports";
import { useLocation, useNavigate } from "react-router-dom";

import { isNil } from "ramda";

import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LanIcon from "@mui/icons-material/Lan";
import WebIcon from "@mui/icons-material/Web";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { sxStyle } from "theme/sections/server";

import IconSelector from "components/shared/images/IconSelector";

import { getServerTypeNumber } from "redux/handlers/serverHandler";

import { getServerDetails } from "redux/selectors/serversSelector";

import { useAppDispatch } from "hooks/reduxHook";

const ServerManageTypes = (): ReactElement => {
 const navigate = useNavigate();
 const dispatch = useAppDispatch();
 const location = useLocation();

 const server = useSelector(getServerDetails);

 const [siteNumber, setSiteNumber] = useState<number>(0);
 const [mailNumber, setMailNumber] = useState<number>(0);
 const [zoneNumber, setZoneNumber] = useState<number>(0);

 useEffect(() => {
  (async () => {
   if (server.id && server.serverName !== "no data") {
    const result = await dispatch(getServerTypeNumber(server.serverName));
    setSiteNumber(result.web);
    setMailNumber(result.mail);
    setZoneNumber(result.zones);
   }
  })();
 }, [server]);

 const handleNavigate = (type: string) => {
  switch (type) {
   case "webserver":
    navigate("/app/sites", { state: { server: server.id } });
    break;
   case "mailserver":
    navigate("/app/mail", { state: { server: server.id } });
    break;
   case "gatewayserver":
    navigate(`/servers/manage/${server.id}/gateway`);
    break;
   case "container":
    navigate(`/servers/manage/${server.id}/container`);
    break;
   default:
    navigate("/app/zone");
    break;
  }
 };

 const checkForRender = (): boolean => {
  return (
   !isNil(server.type) &&
   (server?.type?.includes("webserver") ||
    server?.type?.includes("mailserver") ||
    server?.type?.includes("nameserver") ||
    server?.type?.includes("gatewayserver") ||
    server?.type?.includes("container"))
  );
 };

 return (
  <Stack py={2} spacing={1} mr={4}>
   {checkForRender() && (
    <Stack alignItems="flex-start" mb={1}>
     <Typography variant="caption" fontWeight="bold">
      TYPES
     </Typography>
    </Stack>
   )}
   {server.type?.includes("webserver") && (
    <Stack
     direction="row"
     justifyContent="space-between"
     alignItems="center"
     sx={sxStyle}
     onClick={() => handleNavigate("webserver")}>
     <Stack direction="row" alignItems="center">
      <Stack
       sx={{
        backgroundColor: "lightgray",
        justifyContent: "center",
        borderRadius: "50%",
        height: "35px",
        minWidth: "35px",
        alignItems: "center"
       }}>
       <WebIcon color="action" />
      </Stack>
      <Typography sx={{ ml: 4 }}>
       <FormattedMessage id="server.webDomains" />
      </Typography>
     </Stack>
     <Chip color="primary" size="small" sx={{ mr: 3 }} label={siteNumber} />
    </Stack>
   )}
   {server.type?.includes("mailserver") && (
    <Stack
     direction="row"
     justifyContent="space-between"
     alignItems="center"
     sx={sxStyle}
     onClick={() => handleNavigate("mailserver")}>
     <Stack direction="row" alignItems="center">
      <Stack
       sx={{
        backgroundColor: "lightgray",
        justifyContent: "center",
        borderRadius: "50%",
        height: "35px",
        minWidth: "35px",
        alignItems: "center"
       }}>
       <AlternateEmailIcon color="action" />
      </Stack>
      <Typography sx={{ ml: 4 }}>
       <FormattedMessage id="server.mailDomains" />
      </Typography>
     </Stack>
     <Chip color="primary" size="small" sx={{ mr: 3 }} label={mailNumber} />
    </Stack>
   )}
   {server.type?.includes("nameserver") && (
    <Stack
     direction="row"
     justifyContent="space-between"
     alignItems="center"
     sx={sxStyle}
     onClick={() => handleNavigate("nameserver")}>
     <Stack direction="row" alignItems="center">
      <Stack
       sx={{
        backgroundColor: "lightgray",
        justifyContent: "center",
        borderRadius: "50%",
        height: "35px",
        minWidth: "35px",
        alignItems: "center"
       }}>
       <LanIcon color="action" />
      </Stack>
      <Typography sx={{ ml: 4 }}>
       <FormattedMessage id="server.zones" />
      </Typography>
     </Stack>
     <Chip color="primary" size="small" sx={{ mr: 3 }} label={zoneNumber} />
    </Stack>
   )}
   {server.type?.includes("gatewayserver") && (
    <Stack
     direction="row"
     justifyContent="space-between"
     alignItems="center"
     sx={sxStyle}
     onClick={() => handleNavigate("gatewayserver")}>
     <Stack direction="row" alignItems="center">
      <Stack
       sx={{
        backgroundColor: location.pathname.slice(1).includes("gateway") ? "#EF711A" : "lightgray",
        justifyContent: "center",
        borderRadius: "50%",
        height: "35px",
        minWidth: "35px",
        alignItems: "center"
       }}>
       <IconSelector
        icon="RouterIcon"
        props={{ color: location.pathname.slice(1).includes("gateway") ? "tertiary" : "disabled" }}
       />
      </Stack>
      <Typography sx={{ ml: 4 }}>
       <FormattedMessage id="server.gateway" />
      </Typography>
     </Stack>
    </Stack>
   )}
   {server.type?.includes("container") && (
    <Stack
     direction="row"
     justifyContent="space-between"
     alignItems="center"
     sx={sxStyle}
     onClick={() => handleNavigate("container")}>
     <Stack direction="row" alignItems="center">
      <Stack
       sx={{
        backgroundColor: location.pathname.slice(1).includes("container") ? "#EF711A" : "lightgray",
        justifyContent: "center",
        borderRadius: "50%",
        height: "35px",
        minWidth: "35px",
        alignItems: "center"
       }}>
       <IconSelector
        icon="DockerIcon"
        props={{
         color: location.pathname.slice(1).includes("container") ? "tertiary" : "disabled"
        }}
       />
      </Stack>
      <Typography sx={{ ml: 4 }}>
       <FormattedMessage id="server.container" />
      </Typography>
     </Stack>
    </Stack>
   )}
  </Stack>
 );
};

export default ServerManageTypes;
