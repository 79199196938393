import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const RechargeIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <circle cx="10" cy="10" r="10" fill="transparent" />
  <path
   d="M13.8582 5.63469C13.9956 5.62201 14.0968 5.50028 14.0842 5.36279L13.8776 3.1223C13.8649 2.98481 13.7432 2.88363 13.6057 2.8963C13.4682 2.90898 13.367 3.03071 13.3797 3.1682L13.5633 5.15975L11.5718 5.34337C11.4343 5.35605 11.3331 5.47778 11.3458 5.61527C11.3584 5.75276 11.4802 5.85394 11.6177 5.84126L13.8582 5.63469ZM5.57802 6.32457C7.60789 3.88238 11.2332 3.54813 13.6754 5.57801L13.995 5.19349C11.3405 2.9871 7.39988 3.35042 5.1935 6.00497L5.57802 6.32457ZM5.63819 13.748C3.83856 11.659 3.75702 8.51547 5.57802 6.32457L5.1935 6.00497C3.21382 8.38678 3.30299 11.8034 5.25937 14.0744L5.63819 13.748Z"
   fill="currentColor"
  />
  <path
   d="M6.14184 14.3653C6.00435 14.378 5.90317 14.4997 5.91585 14.6372L6.12242 16.8777C6.1351 17.0152 6.25683 17.1164 6.39432 17.1037C6.5318 17.091 6.63298 16.9693 6.62031 16.8318L6.43669 14.8402L8.42824 14.6566C8.56573 14.644 8.66691 14.5222 8.65424 14.3847C8.64156 14.2472 8.51983 14.1461 8.38234 14.1587L6.14184 14.3653ZM14.422 13.6754C12.3921 16.1176 8.76678 16.4519 6.32459 14.422L6.00499 14.8065C8.65955 17.0129 12.6001 16.6496 14.8065 13.995L14.422 13.6754ZM14.3618 6.25195C16.1614 8.34097 16.243 11.4845 14.422 13.6754L14.8065 13.995C16.7862 11.6132 16.697 8.19658 14.7406 5.92561L14.3618 6.25195Z"
   fill="currentColor"
  />
  <path
   fillRule="evenodd"
   clipRule="evenodd"
   d="M9.5 6.55208V5H10.5V6.56301C11.3626 6.78503 12 7.56808 12 8.5H11C11 7.94771 10.5523 7.5 10 7.5C9.68355 7.5 9.43864 7.59489 9.27807 7.74278C9.12422 7.88447 9 8.11825 9 8.5C9 8.73018 9.09095 8.88913 9.28484 9.04733C9.50471 9.22673 9.81321 9.36855 10.2043 9.54365C10.2142 9.54808 10.2242 9.55253 10.2342 9.55701C10.5875 9.71509 11.0141 9.906 11.3473 10.1778C11.7159 10.4786 12 10.9017 12 11.5C12 12.1183 11.7884 12.6345 11.3994 12.9928C11.1431 13.2288 10.8317 13.3763 10.5 13.4479V15H9.5V13.437C8.63739 13.215 7.99999 12.4319 7.99999 11.5H8.99999C8.99999 12.0523 9.44771 12.5 10 12.5C10.3165 12.5 10.5614 12.4051 10.7219 12.2572C10.8758 12.1155 11 11.8817 11 11.5C11 11.2698 10.909 11.1109 10.7151 10.9527C10.4953 10.7733 10.1868 10.6315 9.79568 10.4563C9.78579 10.4519 9.77583 10.4475 9.76582 10.443C9.41252 10.2849 8.98584 10.094 8.65266 9.82215C8.28405 9.5214 8 9.09825 8 8.5C8 7.88175 8.21156 7.36553 8.60061 7.00722C8.85688 6.7712 9.16831 6.62369 9.5 6.55208Z"
   fill="currentColor"
  />
 </svg>,
 "Recharge"
);
