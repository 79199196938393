import React, { ReactElement, useEffect, useState } from "react";

import Stack from "@mui/material/Stack";

import Snapshots from "components/addons/snapshots/Snapshots";
import SkeletonAddons from "components/shared/skeletons/SkeletonAddons";

import { getAllSnapshots } from "redux/handlers/addonsHandle";
import { getAllServers } from "redux/handlers/serverHandler";

import { useAppDispatch } from "hooks/reduxHook";

const SnapshotPage = (): ReactElement => {
 const dispatch = useAppDispatch();

 const [isLoading, setIsLoading] = useState<boolean>(true);

 useEffect(() => {
  (async () => {
   await dispatch(getAllSnapshots(0, 10));
   await dispatch(getAllServers(0, 10));
   setIsLoading(false);
  })();
 }, []);

 return <Stack>{isLoading ? <SkeletonAddons id="addons.snapshots" /> : <Snapshots />}</Stack>;
};

export default SnapshotPage;
