import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postDeleteSubnetFromNetwork } from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

const DeleteSubnet = ({ id, subnet_id }: { id: number; subnet_id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postDeleteSubnetFromNetwork(id, subnet_id));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Tooltip title={<FormattedMessage id="addons.network.deleteSubnet" />}>
    <IconButton onClick={() => handleOpen()}>
     <IconSelector icon="DeleteIcon" />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "addons.network.deleteSubnet" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}>
    <Typography>
     <FormattedMessage id="addons.network.deleteSubnetMessage" />
    </Typography>
   </AppModal>
  </>
 );
};

export default DeleteSubnet;
