import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import { useMediaQuery, useTheme } from "@mui/material";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";

import { balancerLinks } from "constants/staticLinks";

import LoadBalancerCertificates from "components/addons/loadBalancer/manageSections/certificates/LoadBalancerCertificates";
import LoadBalancerManage from "components/addons/loadBalancer/manageSections/LoadBalancerManage";
import LoadBalancerMonitoring from "components/addons/loadBalancer/manageSections/LoadBalancerMonitoring";
import LoadBalancerUpperPart from "components/addons/loadBalancer/manageSections/LoadBalancerUpperPart";
import LoadBalancerNetwork from "components/addons/loadBalancer/manageSections/networking/LoadBalancerNetwork";
import LoadBalancerRescale from "components/addons/loadBalancer/manageSections/rescale/LoadBalancerRescale";
import LoadBalancerService from "components/addons/loadBalancer/manageSections/services/LoadBalancerService";
import LoadBalancerTargets from "components/addons/loadBalancer/manageSections/targets/LoadBalancerTargets";
import SectionsBreadCrumbs from "components/shared/breadCrumbs/SectionsBreadCrumbs";
import MobileSectionMenu from "components/shared/sideMenu/MobileSectionMenu";
import SectionMenu from "components/shared/sideMenu/SectionMenu";

import { getSpecificLoadBalancer } from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

const LoadBalancerManagePage = (): ReactElement => {
 const dispatch = useAppDispatch();
 const location = useLocation();
 const { socket } = useContext(AppContext);
 const params = useParams<{ id: string; section: string }>();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const [reloadData, setReloadData] = useState<boolean>(true);

 useEffect(() => {
  !isNil(socket) &&
   socket.on("addons_data", (value): void => {
    if (value?.completed && value?.section === "load_balancer") {
     setReloadData(true);
    }
   });
  return () => {
   !isNil(socket) && socket.off("addons_data");
  };
 }, [socket]);

 useEffect(() => {
  (async () => {
   if (reloadData) {
    await dispatch(getSpecificLoadBalancer(Number(params.id)));
    setReloadData(false);
   }
  })();
 }, [reloadData]);

 const RenderBalancerPages = (): ReactElement => {
  switch (params.section) {
   case "details":
    return <LoadBalancerManage />;
   case "certificate":
    return <LoadBalancerCertificates />;
   case "networking":
    return <LoadBalancerNetwork />;
   case "rescale":
    return <LoadBalancerRescale />;
   case "services":
    return <LoadBalancerService />;
   case "targets":
    return <LoadBalancerTargets />;
   default:
    return <></>;
  }
 };

 return (
  <Container maxWidth="xl">
   <Stack pt={1}>
    <SectionsBreadCrumbs
     links={[
      { name: "home", url: "/" },
      { name: "addons", url: "/addons/cloudbox" },
      { name: "load balancers", url: "/addons/loadBalancer" },
      { name: params?.section || "", url: location.pathname }
     ]}
    />
   </Stack>
   <Grid container>
    {desktopViewPort ? (
     <Grid xs={desktopViewPort ? 3 : 0}>
      <Stack mt={6}>
       <SectionMenu
        menuList={balancerLinks.map((item) => ({
         name: item.name,
         url: `/addons/loadBalancer/manage/${params?.id}/${item.url}`,
         icon: item.icon
        }))}
       />
      </Stack>
     </Grid>
    ) : (
     <MobileSectionMenu
      links={balancerLinks}
      disabled={[]}
      url={`/addons/loadBalancer/${params?.id}`}
     />
    )}
    <Grid xs={desktopViewPort ? 9 : 12} mt={3}>
     <LoadBalancerUpperPart />
     <LoadBalancerMonitoring />
     <RenderBalancerPages />
    </Grid>
   </Grid>
  </Container>
 );
};

export default LoadBalancerManagePage;
