import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const UpgradeIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_49_68)">
   <rect width="20" height="20" fill="transparent" />
   <path
    d="M1 14.5V16.75C1 17.3467 1.23705 17.919 1.65901 18.341C2.08097 18.7629 2.65326 19 3.25 19H16.75C17.3467 19 17.919 18.7629 18.341 18.341C18.7629 17.919 19 17.3467 19 16.75V14.5M5.5 5.5L10 1M10 1L14.5 5.5M10 1V14.5"
    stroke="currentColor"
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
   />
  </g>
  <defs>
   <clipPath id="clip0_49_68">
    <rect width="20" height="20" fill="transparent" />
   </clipPath>
  </defs>
 </svg>,
 "Upgrade"
);
