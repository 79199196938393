export const handleChangePageNumber = (
 currentRow: number,
 currentPage: number,
 rowPerPage: number
): { newRow: number; newPage: number } => {
 const calculatedNewPage =
  (currentPage * rowPerPage + 1) / currentRow <= 0
   ? 0
   : (currentPage * rowPerPage + 1) / currentRow;

 return { newRow: currentRow, newPage: calculatedNewPage };
};
