import React, { ReactElement, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { isNil } from "ramda";

import DvrIcon from "@mui/icons-material/Dvr";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { steps } from "constants/baremetal";

import SectionsBreadCrumbs from "components/shared/breadCrumbs/SectionsBreadCrumbs";
import AppModal from "components/shared/modal/AppModal";

import { postCreateBaremetal } from "redux/handlers/baremetalHandle";

import { useAppDispatch } from "hooks/reduxHook";

const BaremetalWizard = (): ReactElement => {
 const intl = useIntl();
 const navigate = useNavigate();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const [activeStep, setActiveStep] = useState<number>(0);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [loading, setLoading] = useState<boolean>(false);

 useEffect(() => {
  (async () => {
   setIsLoading(false);
  })();
 }, []);

 const { control, formState, reset, watch, getValues, setValue } = useForm({
  defaultValues: {
   name: "",
   ram: 0,
   disk_type: "",
   disk_size: 0
  }
 });

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);
 const handleReset = () => {
  reset();
  setActiveStep(0);
 };

 const handleNext = () => {
  setActiveStep((prevActiveStep) => prevActiveStep + 1);
 };

 const handleBack = () => {
  setActiveStep((prevActiveStep) => prevActiveStep - 1);
 };

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(
   postCreateBaremetal(
    getValues("name"),
    getValues("ram"),
    getValues("disk_size"),
    getValues("disk_type")
   )
  );
  setLoading(false);
  handleClose();
  handleReset();
  navigate("/servers");
 };

 const renderStepContent = (index: number) => {
  switch (index) {
   case 0:
    return (
     <Controller
      name="name"
      control={control}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        onChange={(e) => {
         setValue("name", e.target.value);
        }}
        label={intl.formatMessage({ id: "baremetal.add.name" })}
        error={formState.isDirty && !!formState?.errors?.name}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="new-password"
       />
      )}
     />
    );
   case 1:
    return (
     <Controller
      name="ram"
      control={control}
      render={({ field }) => (
       <TextField
        {...field}
        select
        label={intl.formatMessage({ id: "baremetal.add.ram" })}
        onChange={(event) =>
         !isNil(event.target.value)
          ? setValue("ram", Number(event.target.value))
          : setValue("ram", 0)
        }>
        <MenuItem value={16}>16 GB</MenuItem>
        <MenuItem value={32}>32 GB</MenuItem>
        <MenuItem value={64}>64 GB</MenuItem>
        <MenuItem value={128}>128 GB</MenuItem>
       </TextField>
      )}
     />
    );
   case 2:
    return (
     <Stack spacing={2}>
      <Controller
       name="disk_type"
       control={control}
       render={({ field }) => (
        <TextField
         {...field}
         select
         label={<FormattedMessage id="baremetal.add.diskType" />}
         onChange={(event) => {
          !isNil(event.target.value)
           ? setValue("disk_type", event.target.value)
           : setValue("disk_type", "");
          setValue("disk_size", 0);
         }}>
         <MenuItem value={"SSD"}>SSD</MenuItem>
         <MenuItem value={"SATA"}>SATA</MenuItem>
        </TextField>
       )}
      />
      {watch("disk_type") === "SSD" && (
       <Controller
        name="disk_size"
        control={control}
        render={({ field }) => (
         <TextField
          {...field}
          select
          label={intl.formatMessage({ id: "baremetal.add.ssdSize" })}
          onChange={(event) =>
           !isNil(event.target.value)
            ? setValue("disk_size", Number(event.target.value))
            : setValue("disk_size", 0)
          }>
          <MenuItem value={252}>252 GB</MenuItem>
          <MenuItem value={512}>512 GB</MenuItem>
          <MenuItem value={1024}>1 TB</MenuItem>
         </TextField>
        )}
       />
      )}
      {watch("disk_type") === "SATA" && (
       <Controller
        name="disk_size"
        control={control}
        render={({ field }) => (
         <TextField
          {...field}
          select
          label={intl.formatMessage({ id: "baremetal.add.sataSize" })}
          onChange={(event) =>
           !isNil(event.target.value)
            ? setValue("disk_size", Number(event.target.value))
            : setValue("disk_size", 0)
          }>
          <MenuItem value={1024}>1 TB</MenuItem>
          <MenuItem value={2048}>2 TB</MenuItem>
          <MenuItem value={4096}>4 TB</MenuItem>
         </TextField>
        )}
       />
      )}
      {watch("disk_size") > 0 && (
       <Alert severity="warning">
        <FormattedMessage id="baremetal.add.creationNotInstantMessage" />
       </Alert>
      )}
     </Stack>
    );
  }
 };

 const disableNextButton = (index: number) => {
  switch (index) {
   case 0:
    return watch("name").length === 0;
   case 1:
    return watch("ram") === 0;
   case 2:
    return watch("disk_type") === "" || watch("disk_size") === 0;
   default:
    return false;
  }
 };

 const checkDisableConfirm = () => {
  return (
   watch("name") === "" ||
   watch("ram") === 0 ||
   watch("disk_type") === "" ||
   watch("disk_size") === 0
  );
 };

 return !isLoading ? (
  <Container maxWidth="xl">
   <Stack mt={2}>
    <SectionsBreadCrumbs
     links={[
      { name: "home", url: "/" },
      { name: "server", url: "/servers" }
     ]}
    />
   </Stack>
   <Stack spacing={2}>
    <Stack alignItems="center" mt={5} p={3}>
     <DvrIcon fontSize="large" />
     <Typography>
      <FormattedMessage id={"baremetal.add.addNewBaremetal"} />
     </Typography>
    </Stack>
    <Stack direction={desktopViewPort ? "row" : "column"} justifyContent="center">
     <Box
      sx={{ maxWidth: desktopViewPort ? 1000 : "auto", minWidth: desktopViewPort ? 500 : "auto" }}
      m={10}>
      <Stepper activeStep={activeStep} orientation="vertical">
       {steps.map((step, index) => (
        <Step key={step.label}>
         <StepLabel>
          <FormattedMessage id={step.label} />
         </StepLabel>
         <StepContent>
          <Stack spacing={2}>
           <Alert severity="info">
            <Typography>
             <FormattedMessage id={step.description} />
            </Typography>
           </Alert>
           {renderStepContent(index)}
          </Stack>
          <Box sx={{ mb: 2 }}>
           <div>
            {index !== steps.length - 1 ? (
             <Button
              disabled={disableNextButton(index)}
              variant="contained"
              onClick={handleNext}
              sx={{ mt: 1, mr: 1 }}>
              <FormattedMessage id="app.wizard.continue" />
             </Button>
            ) : (
             <></>
            )}
            {index === 2 && (
             <>
              <Button
               sx={{ mt: 1, mr: 1 }}
               variant="contained"
               disabled={checkDisableConfirm()}
               onClick={handleOpen}>
               <FormattedMessage id="app.wizard.confirm" />
              </Button>
              <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
               <FormattedMessage id="app.wizard.reset" />
              </Button>
             </>
            )}
            <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
             <FormattedMessage id="app.wizard.back" />
            </Button>
           </div>
          </Box>
         </StepContent>
        </Step>
       ))}
      </Stepper>
     </Box>
     <Paper
      elevation={0}
      sx={{
       m: 5,
       p: 2,
       maxHeight: 330,
       position: "sticky",
       top: 30,
       minWidth: desktopViewPort ? 400 : "auto",
       borderRadius: "10px",
       boxShadow: 0
      }}>
      <Stack mb={2}>
       <Typography variant="h5" fontWeight="bold">
        <FormattedMessage id="baremetal.add.recap" />
       </Typography>
      </Stack>
      <Divider textAlign="left">
       <Typography variant="overline">
        <FormattedMessage id="baremetal.add.baremetalData" />
       </Typography>
      </Divider>
      <Stack direction="row" spacing={2}>
       <Typography minWidth={120} textAlign="start" fontWeight="bold">
        <FormattedMessage id="baremetal.add.name" />:
       </Typography>
       <Typography noWrap>{watch("name")}</Typography>
      </Stack>
      <Divider textAlign="left" sx={{ mt: 2 }}>
       <Typography variant="overline">
        <FormattedMessage id="baremetal.add.baremetalSpecs" />
       </Typography>
      </Divider>
      <Stack direction="row" spacing={2}>
       <Typography minWidth={120} textAlign="start" fontWeight="bold">
        <FormattedMessage id="baremetal.add.ram" />:
       </Typography>
       <Typography noWrap>{`${watch("ram")} GB`}</Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
       <Typography minWidth={120} textAlign="start" fontWeight="bold">
        <FormattedMessage id="baremetal.add.diskType" />:
       </Typography>
       <Typography noWrap>{watch("disk_type")}</Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
       <Typography minWidth={120} textAlign="start" fontWeight="bold">
        <FormattedMessage id="baremetal.add.size" />:
       </Typography>
       <Typography noWrap>{`${
        watch("disk_size") >= 1024 ? watch("disk_size") / 1024 : watch("disk_size")
       } ${watch("disk_size") >= 1024 ? "TB" : "GB"}`}</Typography>
      </Stack>
     </Paper>
    </Stack>
    <AppModal
     open={openModal}
     close={handleClose}
     title={intl.formatMessage({ id: "baremetal.add.createNewBaremetal" })}
     handleClose={handleClose}
     disabled={loading}
     handleConfirm={handleConfirm}>
     <Stack spacing={2}>
      <Alert severity="warning">
       <FormattedMessage id="baremetal.add.creationNotInstantMessage" />
      </Alert>
      <Typography>
       <FormattedMessage id="baremetal.add.confirmCreation" />
      </Typography>
     </Stack>
    </AppModal>
   </Stack>
  </Container>
 ) : (
  <></>
 );
};

export default BaremetalWizard;
