import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { isNil } from "ramda";

import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postCreateRouteForNetwork } from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

import { IAllServers } from "types/api/serversApiInterface";

const CreateRoute = ({
 id,
 servers,
 network_id
}: {
 id: number;
 servers: Array<IAllServers>;
 network_id: number;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [selectedServer, setSelectedServer] = useState<string>("");
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postCreateRouteForNetwork(id, selectedServer));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Button variant="kxActionButton" onClick={() => handleOpen()}>
    <FormattedMessage id="addons.network.createRoute" />
   </Button>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "addons.network.createRoute" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}>
    <Stack>
     <Typography>
      <FormattedMessage id="addons.network.createRouteMessage" />
     </Typography>
     <Autocomplete
      fullWidth={true}
      autoHighlight
      sx={{ my: 2 }}
      onChange={(e, value) =>
       isNil(value) ? setSelectedServer("") : setSelectedServer(value.gateway)
      }
      options={servers
       .filter(
        (element) =>
         element.private_net &&
         element.private_net.length !== 0 &&
         element.private_net.find((net) => net.network === network_id)
       )
       .map((element) => {
        return {
         server_id: element.id,
         gateway: element.private_net[0].ip,
         label: element.server_name
        };
       })}
      renderInput={(params) => (
       <TextField
        {...params}
        label={<FormattedMessage id="addons.network.server" />}
        InputLabelProps={{ shrink: true }}
       />
      )}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default CreateRoute;
