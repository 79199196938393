import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postUpdateLoadBalancerName } from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

const ManageUpdateName = ({ id, oldName }: { id: number; oldName: string }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => {
  reset();
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const { control, reset, watch, handleSubmit, formState } = useForm({
  defaultValues: {
   name: oldName
  }
 });

 const onSubmit: SubmitHandler<{ name: string }> = async (data: { name: string }) => {
  setLoading(true);
  await dispatch(postUpdateLoadBalancerName(id, data.name));
  handleClose();
  setLoading(false);
 };

 return (
  <>
   <Tooltip title={intl.formatMessage({ id: "app.edit" })} placement="top">
    <IconButton onClick={handleOpen}>
     <IconSelector icon="EditIcon" props={{ fontSize: "small" }} />
    </IconButton>
   </Tooltip>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "addons.loadBalancer.updateName" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={loading || watch("name") === oldName}>
    <Stack spacing={2}>
     <Controller
      name="name"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
       <TextField
        {...field}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
         e.stopPropagation();
        }}
        label={intl.formatMessage({ id: "addons.loadBalancer.name" })}
        InputLabelProps={{ shrink: true }}
        error={formState.isDirty && !!formState?.errors?.name}
        helperText={
         formState.isDirty &&
         !!formState?.errors?.name &&
         intl.formatMessage({ id: "addons.loadBalancer.invalidName" })
        }
       />
      )}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default ManageUpdateName;
