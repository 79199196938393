import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Alert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postRestoreBackup } from "redux/handlers/websitesHandler";

import { getWebsitesDetails } from "redux/selectors/websitesSelector";

import { useAppDispatch } from "hooks/reduxHook";

const RestoreBackup = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const websitedetails = useSelector(getWebsitesDetails);
 const params = useParams<{ domain_id: string; section: string }>();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postRestoreBackup(id, Number(params.domain_id)));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <MenuItem disabled={websitedetails.backup_restore} onClick={handleOpen}>
    <FormattedMessage id="sites.backups.restore" />
   </MenuItem>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "sites.backups.restoreBackup" })}
    handleClose={handleClose}
    disabled={loading}
    handleConfirm={handleConfirm}>
    <Stack spacing={2}>
     <Alert severity="warning">
      <FormattedMessage id="sites.backup.restoreWarning" />
     </Alert>
     <Typography>
      <FormattedMessage id="sites.backup.restoreMessage" />
     </Typography>
    </Stack>
   </AppModal>
  </>
 );
};

export default RestoreBackup;
