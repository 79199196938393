import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import IconButton from "@mui/material/IconButton";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postStatusMailDomain } from "redux/handlers/mailsHandler";

import { useAppDispatch } from "hooks/reduxHook";

const ChangeMailStatus = ({
 id,
 isp_id,
 domain,
 status
}: {
 id: number;
 isp_id: number;
 domain: string;
 status: boolean;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postStatusMailDomain(id, isp_id, domain));
  setLoading(false);
 };

 return (
  <>
   <IconButton onClick={handleOpen}>
    <IconSelector icon="PowerIcon" props={{ color: status ? "success" : "error" }} />
   </IconButton>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "mails.changeMailStatus" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}>
    <FormattedMessage
     id={status ? "mails.changeMailStatusMessageOn" : "mails.changeMailStatusMessageOff"}
    />
   </AppModal>
  </>
 );
};

export default ChangeMailStatus;
