import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

import { cloudboxSettingsElements } from "constants/addons";

import { postUpdatecloudbox } from "redux/handlers/addonsHandle";

import { getCloudboxDataset } from "redux/selectors/cloudboxSelector";

import { useAppDispatch } from "hooks/reduxHook";

const CloudboxSettings = (): ReactElement => {
 const dispatch = useAppDispatch();
 const params = useParams<{ id: string; section: string }>();
 const cloudboxData = useSelector(getCloudboxDataset).find(
  (element) => element.id === Number(params.id)
 );

 const [samba, setSamba] = useState<boolean>(false);
 const [webdav, setWebdav] = useState<boolean>(false);
 const [external, setExternal] = useState<boolean>(false);
 const [ssh, setSsh] = useState<boolean>(false);

 useEffect(() => {
  setSamba(cloudboxData?.samba || false);
  setWebdav(cloudboxData?.webdav || false);
  setExternal(cloudboxData?.external_reachability || false);
  setSsh(cloudboxData?.ssh || false);
 }, [cloudboxData]);

 const handleChangeSettings = async (section: string, value: boolean) => {
  await dispatch(
   postUpdatecloudbox(
    cloudboxData?.id || 0,
    cloudboxData?.name || "",
    section === "samba" ? value : samba,
    section === "webdav" ? value : webdav,
    section === "ssh" ? value : ssh,
    section === "external" ? value : external,
    cloudboxData?.zfs || false
   )
  );
 };

 const handleSwitchToggle = (section: string, value: boolean) => {
  switch (section) {
   case "samba":
    setSamba(value);
    handleChangeSettings(section, value);
    break;
   case "webdav":
    setWebdav(value);
    handleChangeSettings(section, value);
    break;
   case "ssh":
    setSsh(value);
    handleChangeSettings(section, value);
    break;
   case "external":
    setExternal(value);
    handleChangeSettings(section, value);
    break;
   default:
    break;
  }
 };

 const RenderSettings = ({ section }: { section: string }): ReactElement => {
  switch (section) {
   case "samba":
    return (
     <Stack direction="row" alignItems="center" pr={2}>
      <Switch
       checked={samba}
       onChange={(event) => handleSwitchToggle("samba", event.target.checked)}
      />
     </Stack>
    );
   case "webdav":
    return (
     <Stack direction="row" alignItems="center" pr={2}>
      <Switch
       checked={webdav}
       onChange={(event) => handleSwitchToggle("webdav", event.target.checked)}
      />
     </Stack>
    );
   case "ssh":
    return (
     <Stack direction="row" alignItems="center" pr={2}>
      <Switch checked={ssh} onChange={(event) => handleSwitchToggle("ssh", event.target.checked)} />
     </Stack>
    );
   case "external":
    return (
     <Stack direction="row" alignItems="center" pr={2}>
      <Switch
       checked={external}
       onChange={(event) => handleSwitchToggle("external", event.target.checked)}
      />
     </Stack>
    );
   default:
    return <></>;
  }
 };

 return (
  <>
   {cloudboxSettingsElements.map((element, index) => {
    return (
     <Fragment key={`settings-element-${index}`}>
      <Stack direction={"row"} alignItems="center" py={0.5} pl={1} justifyContent="space-between">
       <Stack>
        <Typography noWrap variant="subtitle1" px={1} fontWeight="bold">
         <FormattedMessage id={element.text} />
        </Typography>
       </Stack>

       <RenderSettings section={element.section} />
      </Stack>
      {cloudboxSettingsElements.length - 1 !== index && <Divider />}
     </Fragment>
    );
   })}
  </>
 );
};

export default CloudboxSettings;
