/* eslint-disable react/style-prop-object */
import React, { ReactElement, useState } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatServerPrice } from "helpers/server";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postBuyServer } from "redux/handlers/serverHandler";

import { getUserBalance } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IServerTypes } from "types/api/serversApiInterface";
import { contaboDataToSend } from "types/global/server";

const BuyContaboServer = ({
 open,
 serverSizeData,
 dataToSend,
 handleClose,
 handleReset
}: {
 open: boolean;
 serverSizeData: IServerTypes | null;
 dataToSend: contaboDataToSend;
 handleClose: () => void;
 handleReset: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const navigate = useNavigate();

 const userBalance = useSelector(getUserBalance);

 const [loading, setLoading] = useState<boolean>(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(
   postBuyServer(
    dataToSend.location,
    dataToSend.os,
    dataToSend.name,
    dataToSend.provider,
    dataToSend.serverSize,
    false,
    false,
    false,
    false,
    dataToSend.serverType,
    dataToSend.productId
   )
  );
  handleClose();
  handleReset();
  setLoading(false);
  navigate("/servers");
 };

 return (
  <AppModal
   open={open}
   close={handleClose}
   title={intl.formatMessage({ id: "server.newServer" })}
   handleClose={handleClose}
   handleConfirm={handleConfirm}
   disabled={
    userBalance <
     formatServerPrice(
      serverSizeData ? [serverSizeData] : [],
      false,
      false,
      false,
      serverSizeData?.id || 0
     ) || loading
   }>
   <Stack>
    <FormattedMessage id="server.add.confirmNewServer" />
    <Stack direction="row" spacing={2}>
     <Typography minWidth={150} textAlign="start" fontWeight="bold">
      <FormattedMessage id="server.add.balance" />
     </Typography>
     <Typography>
      <FormattedNumber value={userBalance} style={"currency"} currency="EUR" />
     </Typography>
    </Stack>
    <Stack direction="row" spacing={2} alignItems="center">
     <Typography minWidth={150} textAlign="start" fontWeight="bold">
      <FormattedMessage id="server.add.totalPrice" />
     </Typography>
     <Typography>
      <FormattedNumber
       value={
        formatServerPrice(
         serverSizeData ? [serverSizeData] : [],
         false,
         false,
         false,
         serverSizeData?.id || 0
        ) || 0
       }
       style={"currency"}
       currency="EUR"
      />
     </Typography>
    </Stack>
   </Stack>
  </AppModal>
 );
};

export default BuyContaboServer;
