import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { IFloatingIpApi } from "types/api/addonsApiInterface";

const initialState: IFloatingIpApi = { dataset: [], totalCount: 0 };

const FloatingIpReducer = createSlice({
 name: "floatingip",
 initialState,
 reducers: {
  setFloatingIp: (state, { payload }: PayloadAction<IFloatingIpApi>) => {
   return payload;
  },
  resetFloatingIp: () => {
   return initialState;
  }
 }
});

export default FloatingIpReducer;
