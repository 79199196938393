import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { IWebsitesApi } from "types/api/websitesApiInterface";

const initialState: IWebsitesApi = { sites: [], length: 0 };

const websitesReducer = createSlice({
 name: "websites",
 initialState,
 reducers: {
  setWebsites: (state, { payload }: PayloadAction<IWebsitesApi>) => {
   return payload;
  },
  resetWebsites: () => {
   return initialState;
  }
 }
});

export default websitesReducer;
