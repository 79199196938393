import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const SnapshotIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <path
   d="M4.78835 5.55874L4.78911 5.55753L5.61011 4.24153L5.61011 4.24153L5.61089 4.24026C5.77446 3.97456 5.99963 3.75219 6.26735 3.59196C6.53486 3.43185 6.83698 3.3385 7.14818 3.3198C8.88148 3.22673 10.6185 3.22673 12.3518 3.3198C12.663 3.3385 12.9651 3.43185 13.2327 3.59196C13.5004 3.75219 13.7255 3.97456 13.8891 4.24026L13.89 4.24165L14.712 5.55765L14.7126 5.55872C14.912 5.87434 15.178 6.14253 15.492 6.34449C15.8057 6.54635 16.1599 6.67718 16.5295 6.72781C16.906 6.78131 17.2818 6.83931 17.6569 6.90181L17.657 6.90182C18.5826 7.05579 19.25 7.87345 19.25 8.82421V17.2502C19.25 17.7806 19.0393 18.2893 18.6642 18.6644C18.2891 19.0395 17.7804 19.2502 17.25 19.2502H2.25C1.71957 19.2502 1.21086 19.0395 0.835786 18.6644C0.460714 18.2893 0.25 17.7806 0.25 17.2502V8.82421C0.25 7.87335 0.916515 7.05579 1.84299 6.90182L1.84321 6.90179C2.21785 6.83918 2.59261 6.78152 2.97049 6.72782C3.34029 6.67732 3.69458 6.54657 4.00853 6.34473C4.32269 6.14275 4.58886 5.87448 4.78835 5.55874Z"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
  <path
   d="M16.5 9.50018C16.3619 9.50018 16.25 9.61211 16.25 9.75018V9.75818C16.25 9.89625 16.3619 10.0082 16.5 10.0082H16.508C16.6461 10.0082 16.758 9.89625 16.758 9.75818V9.75018C16.758 9.61211 16.6461 9.50018 16.508 9.50018H16.5ZM13.1088 15.3589C13.9996 14.4681 14.5 13.26 14.5 12.0002C14.5 10.7404 13.9996 9.53222 13.1088 8.64143C12.218 7.75063 11.0098 7.25018 9.75 7.25018C8.49022 7.25018 7.28204 7.75063 6.39124 8.64143C5.50045 9.53222 5 10.7404 5 12.0002C5 13.26 5.50045 14.4681 6.39124 15.3589C7.28204 16.2497 8.49022 16.7502 9.75 16.7502C11.0098 16.7502 12.218 16.2497 13.1088 15.3589Z"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
 </svg>,
 "Snapshot"
);
