export type serverData = {
 id: number;
 hz_id: number;
 status: string;
 os: string;
 serverName: string;
 ipAddress: string;
 owner: string;
 countryCode: string;
 renewDate: string;
 serverMachine: string;
 price: string;
};

export const linuxOs = ["ubuntu", "debian", "fedora", "rocky", "centos"];

export type hetznerDataToSend = {
 name: string;
 provider: number;
 os: number;
 location: number;
 serverSize: string;
 enableIpv4: boolean;
 enableIpv6: boolean;
 enableBackup: boolean;
 serverType: string;
 enableContinuity: boolean;
};

export type contaboDataToSend = {
 name: string;
 provider: number;
 os: number;
 location: number;
 serverSize: string;
 serverType: string;
 productId: string;
};
