/* eslint-disable react/style-prop-object */
import React, { ReactElement, useState } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postRescaleBalancer } from "redux/handlers/addonsHandle";

import { getUserBalance } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

const ConfirmRescale = ({
 id,
 rescaleId,
 price
}: {
 id: number;
 rescaleId: number;
 price: number;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const userBalance = useSelector(getUserBalance);

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postRescaleBalancer(id, rescaleId, price));
  handleClose();
  setLoading(false);
 };

 return (
  <>
   <Button onClick={handleOpen} variant="kxActionButton">
    <FormattedMessage id="addons.loadBalancer.rescaleBalancer" />
   </Button>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "addons.loadBalancer.rescaleBalancer" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading || price > userBalance}>
    <Stack>
     <Typography>
      <FormattedMessage id="addons.loadBalancer.rescaleBalancerMessage" />
     </Typography>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.add.balance" />
      </Typography>
      <Typography>
       <FormattedNumber value={userBalance} style={"currency"} currency="EUR" />
      </Typography>
     </Stack>
     <Stack direction="row" spacing={2} alignItems="center">
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.add.totalPrice" />
      </Typography>
      <Typography>
       <FormattedNumber value={price} style={"currency"} currency="EUR" />
      </Typography>
     </Stack>
    </Stack>
   </AppModal>
  </>
 );
};

export default ConfirmRescale;
