import { isNil } from "ramda";

import {
 customerTableData,
 domainTableData,
 hostTableData,
 jobqueueTableData,
 mailsTableData,
 serverJobQueueTableData,
 serverTableData,
 serviceTableData,
 snapshotTableData,
 TableContent,
 TableKeys,
 volumeTableData,
 websitesTableData,
 zonesTableData
} from "types/global/table";

export const sortTable = (
 caller: string,
 a: TableContent,
 b: TableContent,
 sortBy: TableKeys,
 sortDirection: string
): number => {
 switch (caller) {
  case "server":
   return sortServerTable(
    a as serverTableData,
    b as serverTableData,
    sortBy as keyof serverTableData,
    sortDirection
   );
  case "domains":
   return sortDomainTable(
    a as domainTableData,
    b as domainTableData,
    sortBy as keyof domainTableData,
    sortDirection
   );
  case "mails":
   return sortMailsTable(
    a as mailsTableData,
    b as mailsTableData,
    sortBy as keyof mailsTableData,
    sortDirection
   );
  case "websites":
   return sortWebsitesTable(
    a as websitesTableData,
    b as websitesTableData,
    sortBy as keyof websitesTableData,
    sortDirection
   );
  case "zones":
   return sortZonesTable(
    a as zonesTableData,
    b as zonesTableData,
    sortBy as keyof zonesTableData,
    sortDirection
   );
  case "jobqueue":
   return sortJobqueueTable(
    a as jobqueueTableData,
    b as jobqueueTableData,
    sortBy as keyof jobqueueTableData,
    sortDirection
   );
  case "snapshots":
   return sortSnapshotTable(
    a as snapshotTableData,
    b as snapshotTableData,
    sortBy as keyof snapshotTableData,
    sortDirection
   );
  case "volumes":
   return sortVolumeTable(
    a as volumeTableData,
    b as volumeTableData,
    sortBy as keyof volumeTableData,
    sortDirection
   );
  case "hosts":
   return sortHostTable(
    a as hostTableData,
    b as hostTableData,
    sortBy as keyof hostTableData,
    sortDirection
   );
  case "services":
   return sortServiceTable(
    a as serviceTableData,
    b as serviceTableData,
    sortBy as keyof serviceTableData,
    sortDirection
   );
  case "customers":
   return sortCustomerTable(
    a as customerTableData,
    b as customerTableData,
    sortBy as keyof customerTableData,
    sortDirection
   );
  case "serverJobQueue":
   return sortServerJobTable(
    a as serverJobQueueTableData,
    b as serverJobQueueTableData,
    sortBy as keyof serverJobQueueTableData,
    sortDirection
   );
  default:
   return 0;
 }
};

const sortServerTable = (
 a: serverTableData,
 b: serverTableData,
 sortBy: keyof serverTableData,
 sortDirection: string
): number => {
 const valueA = a[sortBy];
 const valueB = b[sortBy];

 if (!isNil(valueA) && !isNil(valueB) && valueA < valueB) return sortDirection === "asc" ? -1 : 1;
 if (!isNil(valueA) && !isNil(valueB) && valueA > valueB) return sortDirection === "asc" ? 1 : -1;
 return 0;
};

const sortServerJobTable = (
 a: serverJobQueueTableData,
 b: serverJobQueueTableData,
 sortBy: keyof serverJobQueueTableData,
 sortDirection: string
): number => {
 const valueA = a[sortBy];
 const valueB = b[sortBy];

 if (!isNil(valueA) && !isNil(valueB) && valueA < valueB) return sortDirection === "asc" ? -1 : 1;
 if (!isNil(valueA) && !isNil(valueB) && valueA > valueB) return sortDirection === "asc" ? 1 : -1;
 return 0;
};

const sortDomainTable = (
 a: domainTableData,
 b: domainTableData,
 sortBy: keyof domainTableData,
 sortDirection: string
): number => {
 const valueA = a[sortBy];
 const valueB = b[sortBy];

 if (!isNil(valueA) && !isNil(valueB) && valueA < valueB) return sortDirection === "asc" ? -1 : 1;
 if (!isNil(valueA) && !isNil(valueB) && valueA > valueB) return sortDirection === "asc" ? 1 : -1;
 return 0;
};

const sortMailsTable = (
 a: mailsTableData,
 b: mailsTableData,
 sortBy: keyof mailsTableData,
 sortDirection: string
): number => {
 const valueA = a[sortBy];
 const valueB = b[sortBy];

 if (valueA < valueB) return sortDirection === "asc" ? -1 : 1;
 if (valueA > valueB) return sortDirection === "asc" ? 1 : -1;
 return 0;
};

const sortWebsitesTable = (
 a: websitesTableData,
 b: websitesTableData,
 sortBy: keyof websitesTableData,
 sortDirection: string
): number => {
 const valueA = a[sortBy];
 const valueB = b[sortBy];

 if (valueA < valueB) return sortDirection === "asc" ? -1 : 1;
 if (valueA > valueB) return sortDirection === "asc" ? 1 : -1;
 return 0;
};

const sortZonesTable = (
 a: zonesTableData,
 b: zonesTableData,
 sortBy: keyof zonesTableData,
 sortDirection: string
): number => {
 const valueA = a[sortBy];
 const valueB = b[sortBy];

 if (!isNil(valueA) && !isNil(valueB) && valueA < valueB) return sortDirection === "asc" ? -1 : 1;
 if (!isNil(valueA) && !isNil(valueB) && valueA > valueB) return sortDirection === "asc" ? 1 : -1;
 return 0;
};

const sortJobqueueTable = (
 a: jobqueueTableData,
 b: jobqueueTableData,
 sortBy: keyof jobqueueTableData,
 sortDirection: string
): number => {
 const valueA = a[sortBy];
 const valueB = b[sortBy];

 if (!isNil(valueA) && !isNil(valueB) && valueA < valueB) return sortDirection === "asc" ? -1 : 1;
 if (!isNil(valueA) && !isNil(valueB) && valueA > valueB) return sortDirection === "asc" ? 1 : -1;
 return 0;
};

const sortSnapshotTable = (
 a: snapshotTableData,
 b: snapshotTableData,
 sortBy: keyof snapshotTableData,
 sortDirection: string
): number => {
 const valueA = a[sortBy];
 const valueB = b[sortBy];

 if (!isNil(valueA) && !isNil(valueB) && valueA < valueB) return sortDirection === "asc" ? -1 : 1;
 if (!isNil(valueA) && !isNil(valueB) && valueA > valueB) return sortDirection === "asc" ? 1 : -1;
 return 0;
};

const sortVolumeTable = (
 a: volumeTableData,
 b: volumeTableData,
 sortBy: keyof volumeTableData,
 sortDirection: string
): number => {
 const valueA = a[sortBy];
 const valueB = b[sortBy];

 if (!isNil(valueA) && !isNil(valueB) && valueA < valueB) return sortDirection === "asc" ? -1 : 1;
 if (!isNil(valueA) && !isNil(valueB) && valueA > valueB) return sortDirection === "asc" ? 1 : -1;
 return 0;
};

const sortHostTable = (
 a: hostTableData,
 b: hostTableData,
 sortBy: keyof hostTableData,
 sortDirection: string
): number => {
 const valueA = a[sortBy];
 const valueB = b[sortBy];

 if (!isNil(valueA) && !isNil(valueB) && valueA < valueB) return sortDirection === "asc" ? -1 : 1;
 if (!isNil(valueA) && !isNil(valueB) && valueA > valueB) return sortDirection === "asc" ? 1 : -1;
 return 0;
};

const sortServiceTable = (
 a: serviceTableData,
 b: serviceTableData,
 sortBy: keyof serviceTableData,
 sortDirection: string
): number => {
 const valueA = a[sortBy];
 const valueB = b[sortBy];

 if (!isNil(valueA) && !isNil(valueB) && valueA < valueB) return sortDirection === "asc" ? -1 : 1;
 if (!isNil(valueA) && !isNil(valueB) && valueA > valueB) return sortDirection === "asc" ? 1 : -1;
 return 0;
};

const sortCustomerTable = (
 a: customerTableData,
 b: customerTableData,
 sortBy: keyof customerTableData,
 sortDirection: string
): number => {
 const valueA = a[sortBy];
 const valueB = b[sortBy];

 if (!isNil(valueA) && !isNil(valueB) && valueA < valueB) return sortDirection === "asc" ? -1 : 1;
 if (!isNil(valueA) && !isNil(valueB) && valueA > valueB) return sortDirection === "asc" ? 1 : -1;
 return 0;
};
