/* eslint-disable no-unused-vars */
export enum EServerOS {
 CENTOS = "centos",
 DEBIAN = "debian",
 FEDORA = "fedora",
 ROCKY = "rocky",
 UBUNTU = "ubuntu",
 PFSENSE = "pfsense",
 WINDOWS = "windows"
}

export enum EServerStatus {
 ACTIVE = "ACTIVE",
 RENEW = "RENEW",
 EXPIRED = "EXPIRED",
 WRONG_RENEW = "WRONG RENEW"
}

export enum EServerProvider {
 HETZNER = "hetzner",
 CONTABO = "ctb",
 OVH = "ovh",
 GCLOUD = "gcloud",
 KX = "kx"
}
export enum EDomainProvider {
 INTERNETBS = "internetbs",
 ASCIO = "ascio"
}

export enum EServerRoles {
 GATEWAY = "gateway",
 VOIP = "voip",
 WEB = "web",
 ZIMBRA = "zimbra",
 CUSTOM = "custom",
 MAIL = "mail",
 NAME = "name"
}

export interface IPhpData {
 phpType: string;
 phpVersion: string;
 phpPostMax: string;
 phpMaxVars: string;
 phpMaxExec: string;
 phpMemoryLimit: string;
 phpFileSize: string;
 phpTimezone: string;
 phpError: boolean;
}

export interface IBackup {
 id: number;
 server: number;
 status: string;
 created: string;
 description: string;
 image_size: string;
}

export interface IPhpValues {
 agentoken: string;
 createdAt: string;
 id: number;
 maxExecution: number;
 maxUpload: number;
 maxVars: number;
 memoryLimit: number;
 phpErrors: boolean;
 updatedAt: string;
 version: string;
 timezone?: string;
}

export interface IIso {
 architecture: string;
 deprecation: string | null;
 description: string;
 id: number;
 name: string;
 type: string;
}
