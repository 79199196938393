import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import UpdateIcon from "@mui/icons-material/Update";

import { Alert } from "@mui/material";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { cronjobData } from "constants/tables";

import SortingTable from "components/shared/tables/SortingTable";

import { getWebsitesDetails } from "redux/selectors/websitesSelector";

import AddCronjob from "./actions/AddCronjob";

const WebsitesCronjob = (): ReactElement => {
 const websitedetails = useSelector(getWebsitesDetails);
 const params = useParams<{ domain_id: string; section: string }>();

 return (
  <Paper elevation={0} sx={{ mt: 2, borderRadius: "10px", boxShadow: 0 }}>
   <Stack direction="row" alignItems="center" justifyContent="space-between" p={2} spacing={5}>
    <Stack direction={"row"} spacing={1}>
     <UpdateIcon color="secondary" />
     <Typography variant="body1" fontWeight="bold" textTransform="uppercase">
      <FormattedMessage id="sites.cronjob" />
     </Typography>
    </Stack>
    <AddCronjob
     isp_id={websitedetails.isp_id}
     domain_id={websitedetails.domain_id}
     domain_db_id={Number(params.domain_id)}
    />
   </Stack>
   <Stack direction="column" alignItems="flex-start" p={2} spacing={3}>
    {websitedetails.cronjob.length === 0 ? (
     <Stack>
      <Alert severity="info">
       <Typography variant="small">
        <FormattedMessage id="sites.cronjob.message" />
       </Typography>
      </Alert>
     </Stack>
    ) : (
     <SortingTable
      tableData={cronjobData}
      maxWidth={650}
      caller="cronjob"
      listToShow={websitedetails.cronjob}
     />
    )}
   </Stack>
   <Divider />
  </Paper>
 );
};

export default WebsitesCronjob;
