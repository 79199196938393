import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postDeleteBackup } from "redux/handlers/backupsHandler";

import { useAppDispatch } from "hooks/reduxHook";

const DeleteBackup = ({
 id,
 reloadData,
 closeMenu
}: {
 id: number;
 reloadData: () => void;
 closeMenu: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postDeleteBackup(id));
  setLoading(false);
  reloadData();

  handleClose();
  closeMenu();
 };

 return (
  <>
   <MenuItem onClick={handleOpen}>
    <FormattedMessage id="server.backups.delete" />
   </MenuItem>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "server.backups.deleteTitle" })}
    handleClose={handleClose}
    disabled={loading}
    handleConfirm={handleConfirm}>
    <Typography>
     <FormattedMessage id="server.backups.deleteModalMessage" />
    </Typography>
   </AppModal>
  </>
 );
};

export default DeleteBackup;
