import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import ChangeProtection from "components/addons/actions/ChangeProtection";
import IconSelector from "components/shared/images/IconSelector";

import { getAllServerAddons } from "redux/handlers/serverHandler";

import { getServerDbId, getServerProviderId } from "redux/selectors/serversSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IVolumesDataset } from "types/api/addonsApiInterface";

import VolumesMenu from "./volumesActions/VolumesMenu";

const ServerVolumes = (): ReactElement => {
 const navigate = useNavigate();
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const server_id = useSelector(getServerProviderId);
 const id = useSelector(getServerDbId);

 const [serverVolumes, setServerVolumes] = useState<Array<IVolumesDataset>>([]);

 useEffect(() => {
  (async () => {
   const addons = await dispatch(getAllServerAddons(id));

   setServerVolumes(addons ? addons.volume : []);
  })();
 }, [server_id]);

 return (
  <>
   <Paper elevation={0} sx={{ mt: 2, borderRadius: "10px", boxShadow: 0 }}>
    <Stack p={2} spacing={2}>
     <Stack direction="row" justifyContent={"space-between"}>
      <Stack direction="row" spacing={1}>
       <IconSelector icon="CameraAltIcon" props={{ fontSize: "medium", color: "secondary" }} />
       <Typography fontWeight="bold" fontSize={16} textTransform="uppercase">
        <FormattedMessage id="server.addons.volumes" />
       </Typography>
      </Stack>
      <Button
       variant="kxActionButton"
       endIcon={<AddIcon />}
       onClick={() => navigate("/addons/volumes/add")}>
       <FormattedMessage id="addons.volumes.addVolume" />
      </Button>
     </Stack>
     {serverVolumes.length === 0 ? (
      <Alert severity="warning">
       <FormattedMessage id="server.addons.volumesNotFound" />
      </Alert>
     ) : (
      <TableContainer component="div">
       <Table sx={{ minWidth: desktopViewPort ? 650 : 450 }} aria-label="simple table">
        <TableHead>
         <TableRow>
          <TableCell>
           <FormattedMessage id="app.status" />
          </TableCell>
          <TableCell>
           <FormattedMessage id="app.name" />
          </TableCell>
          <TableCell>
           <FormattedMessage id="app.size" />
          </TableCell>
          <TableCell>
           <FormattedMessage id="app.protection" />
          </TableCell>
          <TableCell>
           <FormattedMessage id="table.actions" />
          </TableCell>
         </TableRow>
        </TableHead>
        <TableBody>
         {serverVolumes.map((element, index) => (
          <TableRow key={`server-snapshot-${index}`}>
           <TableCell>
            <Chip
             label={element.status}
             color={element.status === "available" ? "success" : "error"}
             size="small"
            />
           </TableCell>
           <TableCell>{element.name}</TableCell>
           <TableCell>
            <Typography variant="inherit">{`${element.size} ${intl.formatMessage({
             id: "server.manage.gb"
            })}`}</Typography>
           </TableCell>
           <TableCell>
            <ChangeProtection id={element.id} protection={element.protection} type="volume" />
           </TableCell>
           <TableCell>
            <VolumesMenu rowData={element} />
           </TableCell>
          </TableRow>
         ))}
        </TableBody>
       </Table>
      </TableContainer>
     )}
    </Stack>
   </Paper>
  </>
 );
};

export default ServerVolumes;
