import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import SkeletonTable from "components/shared/skeletons/SkeletonTable";

interface SkeletonProps {
 id: string;
}

const SkeletonDomains = ({ id }: SkeletonProps): ReactElement => {
 return (
  <Stack mt={6}>
   <Stack alignItems="flex-start">
    <Typography color="disabled" fontSize={16} textTransform="uppercase">
     <FormattedMessage id={id} />
    </Typography>
   </Stack>
   <Skeleton variant="rectangular" width="100%" height={60} />
   <SkeletonTable numberOfRows={8} tableHeight={60}></SkeletonTable>
  </Stack>
 );
};

export default SkeletonDomains;
