import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import notificationReducer from "redux/reducers/notificationReducer";

import { IIspDatas, ispData } from "types/api/IspIdApiInterface";

import { ApiService } from "service/ApiService";

export const fetchIspList =
 (
  currentIndex: number,
  sizePerPage: number,
  q?: string,
  partner?: string
 ): AppAction<Promise<{ dataset: Array<IIspDatas>; totalCount: number }>> =>
 async (dispatch): Promise<{ dataset: Array<IIspDatas>; totalCount: number }> => {
  try {
   const { data } = await ApiService<{ dataset: Array<IIspDatas>; totalCount: number }>(
    `/ispconfig?page=${currentIndex}&limit=${sizePerPage}&q=${q || ""}&partner=${partner || ""}`
   );
   return data;
  } catch (error) {
   console.error("Error getting ISP list:", error);
   return { dataset: [], totalCount: 0 };
  }
 };

export const postAddIsp =
 (dataToPass: ispData): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/ispconfig", "POST", {
    description: dataToPass.description,
    username: dataToPass.username,
    api_password: dataToPass.passwordApi,
    mysql_password: dataToPass.passwordSql,
    isp_password: dataToPass.passwordIsp,
    server_password: dataToPass.passwordServer,
    soap_location: dataToPass.soapLocation,
    soap_url: dataToPass.soapUrl,
    server_id: dataToPass.server,
    partner: dataToPass.partner,
    webserver: dataToPass.webserver,
    mailserver: dataToPass.mailserver,
    nameserver: dataToPass.nameserver,
    visible: dataToPass.visibleGlobally
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "New ISP created",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create ISP - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const putEditIsp =
 (id: number, dataToPass: ispData): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService(`/ispconfig/modify/${id}`, "PUT", {
    description: dataToPass.description,
    username: dataToPass.username,
    api_password: dataToPass.passwordApi,
    mysql_password: dataToPass.passwordSql,
    isp_password: dataToPass.passwordIsp,
    server_password: dataToPass.passwordServer,
    soap_location: dataToPass.soapLocation,
    soap_url: dataToPass.soapUrl,
    server_id: dataToPass.server,
    partner: dataToPass.partner,
    webserver: dataToPass.webserver,
    mailserver: dataToPass.mailserver,
    nameserver: dataToPass.nameserver,
    visible: dataToPass.visibleGlobally
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Isp updated",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update ISP - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const deleteIsp =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService(`/ispconfig/${id}`, "DELETE");
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Isp deleted",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete ISP - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };
