import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postPowerOffSite } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

const PowerOffSite = ({ id, type }: { id: number; type: string }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = async () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postPowerOffSite(id, type));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Tooltip title={<FormattedMessage id="docker.website.powerOffSite" />}>
    <IconButton onClick={() => handleOpen()}>
     <IconSelector icon="PowerIcon" props={{ color: "error" }} />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.powerOffSite" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}>
    <Typography>
     <FormattedMessage id="docker.website.powerOffSiteMessage" />
    </Typography>
   </AppModal>
  </>
 );
};

export default PowerOffSite;
