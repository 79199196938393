import React, { ReactElement, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import useDebounce from "react-use/lib/useDebounce";

import { isNil } from "ramda";

import SearchIcon from "@mui/icons-material/Search";

import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { fetchIspList } from "redux/handlers/ispHandler";
import { getAllServersNotPaged } from "redux/handlers/serverHandler";
import { getUsersList } from "redux/handlers/userHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IIspDatas } from "types/api/IspIdApiInterface";
import { IAllServers } from "types/api/serversApiInterface";
import { IUsersList } from "types/api/userApiInterface";

import AddIsp from "./actions/AddIsp";
import IspMenu from "./IspMenu";

const IspConfig = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const searchRef = useRef<HTMLInputElement>(null);

 const [loading, setLoading] = useState(true);
 const [contactsList, setContactsList] = useState<Array<IUsersList>>([]);
 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);
 const [searchValue, setSearchValue] = useState<string>("");
 const [ispList, setIspList] = useState<{ dataset: Array<IIspDatas>; totalCount: number }>({
  dataset: [],
  totalCount: 0
 });
 const [allServers, setAllServers] = useState<Array<IAllServers>>([]);
 const [selectedPartner, setSelectedPartner] = useState<string>("");

 useEffect(() => {
  (async () => {
   setLoading(true);
   setAllServers(await dispatch(getAllServersNotPaged()));
   setContactsList(await dispatch(getUsersList()));
   setIspList(await dispatch(fetchIspList(0, 10)));
   setLoading(false);
  })();
 }, []);

 useDebounce(
  async () => {
   if (!loading) {
    setCurrentPage(0);
    setIspList(
     await dispatch(
      fetchIspList(currentPage, rowPerPage, searchRef?.current?.value, selectedPartner)
     )
    );
   }
  },
  1000,
  [searchValue]
 );

 const handleOnRowsPerPageChange = async (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const newRowsPerPage = parseInt(event.target.value, 10);
  setRowPerPage(newRowsPerPage);
  setCurrentPage(0);
  setIspList(
   await dispatch(fetchIspList(0, newRowsPerPage, searchRef?.current?.value, selectedPartner))
  );
 };

 const handleOnPageChange = async (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  const startIndex = newPage * rowPerPage;
  setCurrentPage(newPage);
  setIspList(
   await dispatch(fetchIspList(startIndex, rowPerPage, searchRef?.current?.value, selectedPartner))
  );
 };

 const handleSearchPartner = async (partnerIdValue: string) => {
  setCurrentPage(0);
  setSelectedPartner(partnerIdValue);
  setIspList(
   await dispatch(
    fetchIspList(currentPage, rowPerPage, searchRef?.current?.value || "", partnerIdValue)
   )
  );
 };

 return !loading ? (
  <Stack spacing={2}>
   <Stack justifyContent="space-between" direction="row">
    <Stack direction={desktopViewPort ? "row" : "column"} spacing={2} alignItems="center">
     <Typography>
      {ispList.totalCount} <FormattedMessage id="configurator.isp.totalIsps" />
     </Typography>
     <TextField
      size="small"
      autoComplete="new-password"
      label={<FormattedMessage id="configurator.search.isps" />}
      inputRef={searchRef}
      sx={{ ml: 4 }}
      InputLabelProps={{ shrink: true }}
      InputProps={{ startAdornment: <SearchIcon color="disabled" />, autoComplete: "off" }}
      onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
     />
     <Autocomplete
      autoHighlight
      size="small"
      sx={{ mx: 1, width: desktopViewPort ? 300 : 240 }}
      onChange={(e, value) =>
       isNil(value) ? handleSearchPartner("") : handleSearchPartner(value.userid)
      }
      options={contactsList.map((element) => {
       return {
        label: `${element.firstname} ${element.lastname} - ${element.companyName}`,
        userid: element.userid
       };
      })}
      renderInput={(params) => (
       <TextField
        {...params}
        label={intl.formatMessage({ id: "app.search.partner" })}
        InputLabelProps={{ shrink: true }}
       />
      )}
     />
    </Stack>
    <Stack justifyContent={desktopViewPort ? "flex-start" : "center"}>
     <AddIsp
      contacts={contactsList}
      servers={allServers}
      reloadData={async () => setIspList(await dispatch(fetchIspList(0, 10)))}
     />
    </Stack>
   </Stack>
   <Stack>
    <TableContainer component="div">
     <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
       <TableRow>
        <TableCell>
         <FormattedMessage id="configurator.isp.partner" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="configuratos.isp.name" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="configurator.isp.ip" />
        </TableCell>
        <TableCell align="center">
         <FormattedMessage id="configurator.isp.actions" />
        </TableCell>
       </TableRow>
      </TableHead>
      <TableBody>
       {ispList.dataset.map((row, index) => (
        <TableRow key={`isp-config-list-${index}`}>
         <TableCell component="th" scope="row">
          <Typography variant="subtitle2" fontWeight="bold">
           {`${contactsList.find((element) => element.userid === row.userid)?.companyName}`}
          </Typography>
         </TableCell>
         <TableCell component="th" scope="row">
          <Typography variant="subtitle2">{row.server}</Typography>
         </TableCell>
         <TableCell>
          <Typography variant="subtitle2">{row.ip1}</Typography>
         </TableCell>
         <TableCell align="center">
          <IspMenu
           contacts={contactsList}
           servers={allServers}
           ispData={row}
           id={row.id}
           reloadData={async () =>
            setIspList(
             await dispatch(fetchIspList(currentPage, rowPerPage, searchRef?.current?.value))
            )
           }
          />
         </TableCell>
        </TableRow>
       ))}
      </TableBody>
     </Table>
    </TableContainer>
    <TablePagination
     component="div"
     count={ispList.totalCount || 0}
     page={currentPage}
     rowsPerPage={rowPerPage}
     onPageChange={handleOnPageChange}
     onRowsPerPageChange={handleOnRowsPerPageChange}
    />
   </Stack>
  </Stack>
 ) : (
  <></>
 );
};

export default IspConfig;
