import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { websiteContainerDatabaseSteps } from "constants/server";

const DbUsername = ({
 activeStep,
 firstValue,
 handleNext,
 handleBack,
 handleReset,
 setPropUsername
}: {
 activeStep: number;
 firstValue: string;
 handleNext: () => void;
 handleBack: () => void;
 handleReset: () => void;
 setPropUsername: (value: string) => void;
}): ReactElement => {
 const intl = useIntl();

 const [username, setUsername] = useState<string>(firstValue);

 const goNext = () => {
  setPropUsername(username);
  handleNext();
 };

 return (
  <Stack p={2} spacing={2}>
   <TextField
    autoComplete="off"
    value={username}
    autoFocus
    label={intl.formatMessage({ id: "docker.website.databaseUser" })}
    InputLabelProps={{ shrink: true }}
    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
     e.stopPropagation();
    }}
    onChange={({ currentTarget }) => setUsername(currentTarget.value)}
   />
   <Stack direction="row" spacing={2} justifyContent="center">
    <Button disabled={activeStep === 0} onClick={handleBack}>
     <FormattedMessage id="app.back" />
    </Button>
    <Button onClick={handleReset}>Reset</Button>
    <Button
     disabled={activeStep === websiteContainerDatabaseSteps.length - 1 || username === ""}
     onClick={goNext}>
     <FormattedMessage id="app.next" />
    </Button>
   </Stack>
  </Stack>
 );
};

export default DbUsername;
