import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postDeleteTarget } from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

const DeleteTarget = ({
 id,
 type,
 server_id
}: {
 id: number;
 type: string;
 server_id: number;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postDeleteTarget(id, type, server_id));
  handleClose();
  setLoading(false);
 };

 return (
  <>
   <Tooltip title={intl.formatMessage({ id: "app.delete" })} placement="top">
    <IconButton onClick={handleOpen}>
     <IconSelector icon="DeleteIcon" props={{ fontSize: "small" }} />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "addons.loadBalancer.deleteTarget" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}>
    <Typography>
     <FormattedMessage id="addons.loadBalancer.deleteTargetMessage" />
    </Typography>
   </AppModal>
  </>
 );
};

export default DeleteTarget;
