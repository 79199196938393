import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { IServerApi } from "types/api/serversApiInterface";

const initialState: IServerApi = { dataset: [], totalCount: 0 };

const serversReducer = createSlice({
 name: "servers",
 initialState,
 reducers: {
  setServers: (state, { payload }: PayloadAction<IServerApi>) => {
   return payload;
  },
  resetServers: () => {
   return initialState;
  }
 }
});

export default serversReducer;
