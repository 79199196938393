import React, { ReactElement, useEffect, useState } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { formatBytes } from "helpers/numberFormatting";

import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { BackupIcon } from "components/shared/images/svgs/Backup";

import { postGetMailboxBackup } from "redux/handlers/mailsHandler";

import { getMailsList } from "redux/selectors/mailsSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IMailbox, IMailboxBackup } from "types/api/mailApiInterface";

import DisableBackup from "../actions/DisableBackup";
import EnableBackup from "../actions/EnableBackup";
import RestoreFromBackup from "../actions/RestoreFromBackup";
import UpdateBackupSchedule from "../actions/UpdateBackupSchedule";

const MailBackup = (): ReactElement => {
 const dispatch = useAppDispatch();
 const params = useParams<{ mail_id: string; section: string }>();
 const maildetails = useSelector(getMailsList).find(
  (element) => element.id === Number(params.mail_id)
 );

 const [mailboxes, setMailboxes] = useState<Array<IMailbox>>(maildetails?.mailbox || []);
 const [selectedMailbox, setSelectedMailbox] = useState<number>(maildetails?.mailbox[0].id || 0);
 const [loading, setLoading] = useState<boolean>(false);
 const [backups, setBackups] = useState<Array<IMailboxBackup>>([]);

 useEffect(() => {
  setMailboxes(maildetails?.mailbox || []);
 }, [maildetails]);

 useEffect(() => {
  (async () => {
   if (selectedMailbox !== 0) {
    setLoading(true);
    setBackups(await dispatch(postGetMailboxBackup(selectedMailbox)));
    setLoading(false);
   }
  })();
 }, [selectedMailbox]);

 return (
  <Paper elevation={0} sx={{ mt: 2, borderRadius: "10px", boxShadow: 0 }}>
   <Stack direction="row" alignItems="center" justifyContent="space-between" p={2} spacing={5}>
    <Stack direction={"row"} spacing={1}>
     <BackupIcon color="secondary" />
     <Typography variant="body1" fontWeight="bold" textTransform="uppercase">
      <FormattedMessage id="sites.backup" />
     </Typography>
    </Stack>
   </Stack>
   {mailboxes.length === 0 ? (
    <Stack>
     <Alert severity="warning">
      <FormattedMessage id="mails.noMailboxFoundInBackup" />
     </Alert>
    </Stack>
   ) : (
    <Stack spacing={1}>
     <Stack direction="row" px={2} pb={1} alignItems="center" justifyContent="space-between">
      <FormControl>
       <InputLabel>
        <FormattedMessage id="mails.mailboxName" />
       </InputLabel>
       <Select
        value={selectedMailbox}
        label={<FormattedMessage id="mails.mailboxName" />}
        onChange={(e) => setSelectedMailbox(Number(e.target.value))}>
        {mailboxes.map((element, index) => {
         return (
          <MenuItem key={`graph-value-${index}`} value={element.id}>
           {element.email}
          </MenuItem>
         );
        })}
       </Select>
      </FormControl>
      {mailboxes.find((element) => element.id === selectedMailbox)?.backup_interval === "none" ? (
       <EnableBackup id={selectedMailbox} />
      ) : (
       <Stack direction="row" spacing={1}>
        <UpdateBackupSchedule
         id={selectedMailbox}
         copy={
          mailboxes.find((element) => element.id === selectedMailbox)?.backup_copies.toString() ||
          ""
         }
         interval={
          mailboxes.find((element) => element.id === selectedMailbox)?.backup_interval || ""
         }
        />
        <DisableBackup id={selectedMailbox} />
       </Stack>
      )}
     </Stack>
     {loading ? (
      <Stack p={2}>
       <CircularProgress />
      </Stack>
     ) : mailboxes.find((element) => element.id === selectedMailbox)?.backup_interval === "none" ? (
      <Stack p={2}>
       <Alert severity="info">
        <FormattedMessage id="mails.backup.disabled" />
       </Alert>
      </Stack>
     ) : backups.length > 0 ? (
      <TableContainer component="div">
       <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
         <TableRow sx={{ backgroundColor: "transparent" }}>
          <TableCell>
           <Typography variant="kxColoredSmall" fontWeight="bold">
            <FormattedMessage id="mails.backup.name" />
           </Typography>
          </TableCell>
          <TableCell>
           <Typography variant="kxColoredSmall" fontWeight="bold">
            <FormattedMessage id="mails.backup.size" />
           </Typography>
          </TableCell>
          <TableCell>
           <Typography variant="kxColoredSmall" fontWeight="bold">
            <FormattedMessage id="mails.backup.created" />
           </Typography>
          </TableCell>
          <TableCell align="right">
           <Typography variant="kxColoredSmall" fontWeight="bold">
            <FormattedMessage id="app.actions" />
           </Typography>
          </TableCell>
         </TableRow>
        </TableHead>
        <TableBody>
         {backups.map((element, index) => {
          return (
           <TableRow key={`mail-backup-${index}`}>
            <TableCell>{element.filename}</TableCell>
            <TableCell>{`${formatBytes(element.filesize, "MB").toFixed(0)} MB`}</TableCell>
            <TableCell>
             <FormattedDate value={new Date(Number(element.tstamp) * 1000)} />
            </TableCell>
            <TableCell align="right">
             <RestoreFromBackup id={element.id} />
            </TableCell>
           </TableRow>
          );
         })}
        </TableBody>
       </Table>
      </TableContainer>
     ) : (
      <Stack p={2}>
       <Alert severity="info">
        <FormattedMessage id="mails.noBackupFound" />
       </Alert>
      </Stack>
     )}
    </Stack>
   )}
  </Paper>
 );
};

export default MailBackup;
