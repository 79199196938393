import React, { ReactElement, useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { AppContext } from "AppContext";
import { formatMemLimit, formatMemVisual } from "helpers/addons";

import { isNil } from "ramda";

import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";

import { getServerDetails } from "redux/selectors/serversSelector";

import EditSiteTuning from "../../actions/EditSiteTuning";
import InstallModule from "../../actions/InstallModule";
import PowerOffSite from "../../actions/PowerOffSite";
import PowerOnSite from "../../actions/PowerOnSite";
import RebootSite from "../../actions/RebootSite";
import RunCustomScript from "../../actions/RunCustomScript";

const StatisticSection = ({
 id,
 hasDb,
 name,
 dbOnline,
 installed_modules,
 version,
 site_mem_limit,
 site_cpu_limit,
 db_mem_limit,
 db_cpu_limit
}: {
 id: number;
 hasDb: boolean;
 name: string;
 dbOnline: string;
 installed_modules: Array<string>;
 version: string;
 site_mem_limit: string;
 site_cpu_limit: number;
 db_mem_limit: string;
 db_cpu_limit: number;
}): ReactElement => {
 const intl = useIntl();

 const { socket } = useContext(AppContext);
 const { agentoken } = useSelector(getServerDetails);

 const [netIo, setNetIo] = useState<string>("not available");
 const [blockIo, setBlockIo] = useState<string>("not available");
 const [netIoDb, setNetIoDb] = useState<string>("not available");
 const [blockIoDb, setBlockIoDb] = useState<string>("not available");
 const [cpu, setCpu] = useState<number>(0);
 const [ram, setRam] = useState<number>(0);
 const [siteCpu, setSiteCpu] = useState<number>(0);
 const [siteRam, setSiteRam] = useState<number>(0);

 useEffect(() => {
  if (!isNil(socket)) {
   socket.on("container_website_performance", (value): void => {
    if (value?.length > 0) {
     for (let element of value) {
      if (element.token === agentoken && element.name_ct === name) {
       setSiteCpu(Number(element?.cpu_ct.substring(0, element?.cpu_ct.length - 1)));
       setSiteRam(
        Number(element?.mem_usage_ct_pc.substring(0, element?.mem_usage_ct_pc.length - 1))
       );
       setNetIo(element?.net_io);
       setBlockIo(element?.block_io);
      }
      if (element.token === agentoken && element.name_ct === `db_${name}`) {
       setCpu(Number(element?.cpu_ct.substring(0, element?.cpu_ct.length - 1)));
       setRam(Number(element?.mem_usage_ct_pc.substring(0, element?.mem_usage_ct_pc.length - 1)));
       setNetIoDb(element?.net_io);
       setBlockIoDb(element?.block_io);
      }
     }
    }
   });
  }
  return () => {
   !isNil(socket) && socket.off("container_website_performance");
  };
 }, [socket]);

 return (
  <Grid container spacing={2}>
   <Grid xs={6}>
    <Stack spacing={2}>
     <Stack spacing={2}>
      <Typography fontWeight="bold">
       <FormattedMessage id="docker.website.siteStatistics" />
       {` ${name}`}
      </Typography>
      <Stack>
       <Stack direction="row" alignItems="center">
        <Typography variant="body1" fontSize={12} textTransform="uppercase" minWidth={70}>
         {`${intl.formatMessage({ id: "server.cpu" })} ${siteCpu.toFixed(0)}%`}
        </Typography>
        <LinearProgress
         sx={{
          width: "100%",
          ml: 2,
          backgroundColor: "#F1F2F7",
          "& .MuiLinearProgress-bar": {
           backgroundColor: siteCpu < 50 ? "#41C58C" : siteCpu < 75 ? "#FF9800" : "#F44336"
          },
          height: 10,
          borderRadius: 0.8
         }}
         variant="determinate"
         value={siteCpu > 100 ? 100 : siteCpu}
        />
       </Stack>
       <Stack direction="row" alignItems="center">
        <Typography variant="body1" fontSize={12} textTransform="uppercase" minWidth={70}>
         {`${intl.formatMessage({ id: "server.ram" })} ${siteRam.toFixed(0)}%`}
        </Typography>
        <LinearProgress
         sx={{
          width: "100%",
          ml: 2,
          backgroundColor: "#F1F2F7",
          "& .MuiLinearProgress-bar": {
           backgroundColor: siteRam < 50 ? "#41C58C" : siteRam < 75 ? "#FF9800" : "#F44336"
          },
          height: 10,
          borderRadius: 0.8
         }}
         variant="determinate"
         value={siteRam > 100 ? 100 : siteRam}
        />
       </Stack>
       <Stack direction="row" alignItems="center">
        <Typography variant="body1" fontSize={12} textTransform="uppercase" minWidth={20}>
         {`MEM limit: ${site_mem_limit ? formatMemVisual(site_mem_limit) : "not available"}`}
        </Typography>
       </Stack>
       <Stack direction="row" alignItems="center">
        <Typography variant="body1" fontSize={12} textTransform="uppercase" minWidth={20}>
         {`CPU core limit: ${site_cpu_limit || "not available"}`}
        </Typography>
       </Stack>
       <Stack direction="row" alignItems="center">
        <Typography variant="body1" fontSize={12} textTransform="uppercase" minWidth={20}>
         {`NET I/O: ${netIo}`}
        </Typography>
       </Stack>
       <Stack direction="row" alignItems="center">
        <Typography variant="body1" fontSize={12} textTransform="uppercase" minWidth={20}>
         {`BLOCK I/O: ${blockIo}`}
        </Typography>
       </Stack>
      </Stack>
      <Stack>
       <Typography fontWeight="bold">
        <FormattedMessage id="docker.website.siteOperations" />
       </Typography>
       <Stack direction="row" spacing={1}>
        <RebootSite id={id} type="site" />
        <PowerOffSite id={id} type="site" />
        <EditSiteTuning
         id={id}
         type="site"
         defaultMem={formatMemLimit(site_mem_limit)}
         defaultCpu={site_cpu_limit}
        />
        <InstallModule id={id} installed_modules={installed_modules} version={version} />
        <RunCustomScript id={id} />
       </Stack>
      </Stack>
     </Stack>
    </Stack>
   </Grid>
   <Grid xs={6}>
    <Stack spacing={2}>
     <Typography fontWeight="bold">
      <FormattedMessage id="docker.website.databaseStatistics" />
      {` db_${name}`}
     </Typography>
     {hasDb ? (
      dbOnline === "online" ? (
       <Stack spacing={2}>
        <Stack>
         <Stack direction="row" alignItems="center">
          <Typography variant="body1" fontSize={12} textTransform="uppercase" minWidth={70}>
           {`${intl.formatMessage({ id: "server.cpu" })} ${cpu.toFixed(0)}%`}
          </Typography>
          <LinearProgress
           sx={{
            width: "100%",
            ml: 2,
            backgroundColor: "#F1F2F7",
            "& .MuiLinearProgress-bar": {
             backgroundColor: cpu < 50 ? "#41C58C" : cpu < 75 ? "#FF9800" : "#F44336"
            },
            height: 10,
            borderRadius: 0.8
           }}
           variant="determinate"
           value={cpu > 100 ? 100 : cpu}
          />
         </Stack>
         <Stack direction="row" alignItems="center">
          <Typography variant="body1" fontSize={12} textTransform="uppercase" minWidth={70}>
           {`${intl.formatMessage({ id: "server.ram" })} ${ram.toFixed(0)}%`}
          </Typography>
          <LinearProgress
           sx={{
            width: "100%",
            ml: 2,
            backgroundColor: "#F1F2F7",
            "& .MuiLinearProgress-bar": {
             backgroundColor: ram < 50 ? "#41C58C" : ram < 75 ? "#FF9800" : "#F44336"
            },
            height: 10,
            borderRadius: 0.8
           }}
           variant="determinate"
           value={ram > 100 ? 100 : ram}
          />
         </Stack>
         <Stack direction="row" alignItems="center">
          <Typography variant="body1" fontSize={12} textTransform="uppercase" minWidth={20}>
           {`MEM limit: ${db_mem_limit ? formatMemVisual(db_mem_limit) : "not available"}`}
          </Typography>
         </Stack>
         <Stack direction="row" alignItems="center">
          <Typography variant="body1" fontSize={12} textTransform="uppercase" minWidth={20}>
           {`CPU core limit: ${db_cpu_limit || "not available"}`}
          </Typography>
         </Stack>
         <Stack direction="row" alignItems="center">
          <Typography variant="body1" fontSize={12} textTransform="uppercase" minWidth={20}>
           {`NET I/O: ${netIoDb}`}
          </Typography>
         </Stack>
         <Stack direction="row" alignItems="center">
          <Typography variant="body1" fontSize={12} textTransform="uppercase" minWidth={20}>
           {`BLOCK I/O: ${blockIoDb}`}
          </Typography>
         </Stack>
        </Stack>
        <Stack>
         <Typography fontWeight="bold">
          <FormattedMessage id="docker.website.dbOperations" />
         </Typography>
         <Stack direction="row" spacing={1}>
          <RebootSite id={id} type="db" />
          <PowerOffSite id={id} type="db" />
          <EditSiteTuning
           id={id}
           type="db"
           defaultMem={formatMemLimit(db_mem_limit)}
           defaultCpu={db_cpu_limit}
          />
         </Stack>
        </Stack>
       </Stack>
      ) : dbOnline === "restarting" ? (
       <Stack direction="row" spacing={1}>
        <CircularProgress />
        <Typography>
         <FormattedMessage id="docker.website.dbRestarting" />
        </Typography>
       </Stack>
      ) : (
       <Stack spacing={2}>
        <Alert severity="warning">
         <Typography>
          <FormattedMessage id="docker.website.dbIsOffline" />
         </Typography>
        </Alert>
        <Stack>
         <Typography fontWeight="bold">
          <FormattedMessage id="docker.website.dbOperations" />
         </Typography>
         <Stack direction="row">
          <PowerOnSite id={id} type="db" />
         </Stack>
        </Stack>
       </Stack>
      )
     ) : (
      <Stack>
       <Alert severity="warning">
        <Typography>
         <FormattedMessage id="docker.website.noDbStatistics" />
        </Typography>
       </Alert>
      </Stack>
     )}
    </Stack>
   </Grid>
  </Grid>
 );
};

export default StatisticSection;
