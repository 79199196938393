import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const AppIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="white" />
  <path
   fillRule="evenodd"
   clipRule="evenodd"
   d="M0.76984 0.0198975C0.355627 0.0198975 0.0198402 0.355684 0.0198402 0.769897V8.40481C0.0198402 8.81903 0.355627 9.15481 0.76984 9.15481H8.40476C8.81897 9.15481 9.15476 8.81903 9.15476 8.40481V0.769897C9.15476 0.355684 8.81897 0.0198975 8.40476 0.0198975H0.76984ZM0.51984 0.769897C0.51984 0.631826 0.631769 0.519897 0.76984 0.519897H8.40476C8.54283 0.519897 8.65476 0.631826 8.65476 0.769897V8.40481C8.65476 8.54289 8.54283 8.65481 8.40476 8.65481H0.76984C0.631769 8.65481 0.51984 8.54289 0.51984 8.40481V0.769897ZM11.5952 0.0198975C11.181 0.0198975 10.8452 0.355684 10.8452 0.769897V8.40481C10.8452 8.81903 11.181 9.15481 11.5952 9.15481H19.2302C19.6444 9.15481 19.9802 8.81903 19.9802 8.40481V0.769897C19.9802 0.355684 19.6444 0.0198975 19.2302 0.0198975H11.5952ZM11.3452 0.769897C11.3452 0.631826 11.4572 0.519897 11.5952 0.519897H19.2302C19.3682 0.519897 19.4802 0.631826 19.4802 0.769897V8.40481C19.4802 8.54289 19.3682 8.65481 19.2302 8.65481H11.5952C11.4572 8.65481 11.3452 8.54289 11.3452 8.40481V0.769897ZM10.8452 11.5953C10.8452 11.1811 11.181 10.8453 11.5952 10.8453H19.2302C19.6444 10.8453 19.9802 11.1811 19.9802 11.5953V19.2302C19.9802 19.6444 19.6444 19.9802 19.2302 19.9802H11.5952C11.181 19.9802 10.8452 19.6444 10.8452 19.2302V11.5953ZM11.5952 11.3453C11.4572 11.3453 11.3452 11.4572 11.3452 11.5953V19.2302C11.3452 19.3683 11.4572 19.4802 11.5952 19.4802H19.2302C19.3682 19.4802 19.4802 19.3683 19.4802 19.2302V11.5953C19.4802 11.4572 19.3682 11.3453 19.2302 11.3453H11.5952ZM0.76984 10.8453C0.355627 10.8453 0.0198402 11.1811 0.0198402 11.5953V19.2302C0.0198402 19.6444 0.355627 19.9802 0.76984 19.9802H8.40476C8.81897 19.9802 9.15476 19.6444 9.15476 19.2302V11.5953C9.15476 11.1811 8.81897 10.8453 8.40476 10.8453H0.76984ZM0.51984 11.5953C0.51984 11.4572 0.631769 11.3453 0.76984 11.3453H8.40476C8.54283 11.3453 8.65476 11.4572 8.65476 11.5953V19.2302C8.65476 19.3683 8.54283 19.4802 8.40476 19.4802H0.76984C0.631769 19.4802 0.51984 19.3683 0.51984 19.2302V11.5953Z"
   fill="black"
  />
 </svg>,
 "App"
);
