/* eslint-disable react/style-prop-object */
import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { formatServerPrice } from "helpers/server";

import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { ILocations, IServerTypes } from "types/api/serversApiInterface";

const HetznerRecapBox = ({
 enableIpv4,
 enableIpv6,
 enableBackup,
 enableContinuity,
 osName,
 serverSizeData,
 locationData
}: {
 enableIpv4: boolean;
 enableIpv6: boolean;
 enableBackup: boolean;
 enableContinuity: boolean;
 osName: string;
 serverSizeData: IServerTypes | null;
 locationData: ILocations | null;
}) => {
 return (
  <>
   <Divider textAlign="left" sx={{ mt: 2 }}>
    <Typography variant="overline">
     <FormattedMessage id="server.add.serverSpecs" />
    </Typography>
   </Divider>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={150} textAlign="start" fontWeight="bold">
     <FormattedMessage id="server.os" />:
    </Typography>
    <Typography>{osName}</Typography>
   </Stack>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={150} textAlign="start" fontWeight="bold">
     <FormattedMessage id="server.type" />:
    </Typography>
    <Typography>{serverSizeData?.name || ""}</Typography>
   </Stack>
   <Stack direction="row" spacing={2} alignItems="center">
    <Typography minWidth={150} textAlign="start" fontWeight="bold">
     <FormattedMessage id="server.location" />:
    </Typography>
    <Typography noWrap>{locationData?.city || ""}</Typography>
    <Stack
     component="span"
     className={`fi fi-${locationData?.country.toLocaleLowerCase() || ""}`}
    />
   </Stack>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={150} textAlign="start" fontWeight="bold">
     <FormattedMessage id="server.cores" />:
    </Typography>
    <Typography>{serverSizeData?.cores || ""}</Typography>
   </Stack>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={150} textAlign="start" fontWeight="bold">
     <FormattedMessage id="server.add.ram" />:
    </Typography>
    <Typography>{`${serverSizeData?.memory || "0"} GB`}</Typography>
   </Stack>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={150} textAlign="start" fontWeight="bold">
     <FormattedMessage id="server.add.disk" />:
    </Typography>
    <Typography>{`${serverSizeData?.disk || "0"} GB`}</Typography>
   </Stack>
   <Divider textAlign="left" sx={{ mt: 2 }}>
    <Typography variant="overline">
     <FormattedMessage id="server.add.enabling" />
    </Typography>
   </Divider>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={150} textAlign="start" fontWeight="bold">
     <FormattedMessage id="server.enableIpv4" />:
    </Typography>
    <Typography>{enableIpv4 ? "Attivo" : "Disattivo"}</Typography>
   </Stack>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={150} textAlign="start" fontWeight="bold">
     <FormattedMessage id="server.enableIpv6" />:
    </Typography>
    <Typography>{enableIpv6 ? "Attivo" : "Disattivo"}</Typography>
   </Stack>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={150} textAlign="start" fontWeight="bold">
     <FormattedMessage id="server.enableBackup" />:
    </Typography>
    <Typography>{enableBackup ? "Attivo" : "Disattivo"}</Typography>
   </Stack>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={150} textAlign="start" fontWeight="bold">
     BCP:
    </Typography>
    <Typography>{enableContinuity ? "Attivo" : "Disattivo"}</Typography>
   </Stack>
   <Divider sx={{ my: 1 }} />
   <Stack>
    {enableIpv4 && (
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.add.ipCost" />
      </Typography>
      <Typography fontSize={"small"} fontStyle="italic">
       <FormattedNumber
        value={Number(serverSizeData?.ipv4_total) || 0}
        style={"currency"}
        currency="EUR"
       />
      </Typography>
     </Stack>
    )}
    {enableBackup && (
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.add.backupCost" />
      </Typography>
      <Typography fontSize={"small"} fontStyle="italic">
       <FormattedNumber
        value={Number(serverSizeData?.backup_total) || 0}
        style={"currency"}
        currency="EUR"
       />
      </Typography>
     </Stack>
    )}
    {enableContinuity && (
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.add.enableContinuity" />
      </Typography>
      <Typography fontSize={"small"} fontStyle="italic">
       <FormattedNumber value={5} style={"currency"} currency="EUR" />
      </Typography>
     </Stack>
    )}
    {(enableIpv4 || enableBackup) && (
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.add.serverCost" />
      </Typography>
      <Typography fontSize={"small"} fontStyle="italic">
       <FormattedNumber
        value={Number(serverSizeData?.server_type_price) || 0}
        style={"currency"}
        currency="EUR"
       />
      </Typography>
     </Stack>
    )}
    <Stack direction="row" spacing={2} alignItems="center">
     <Typography minWidth={150} textAlign="start" fontWeight="bold">
      <FormattedMessage id="server.add.totalPrice" />
     </Typography>
     <Typography fontSize={"x-large"} fontStyle="italic">
      <FormattedNumber
       value={
        formatServerPrice(
         serverSizeData ? [serverSizeData] : [],
         enableIpv4,
         enableBackup,
         enableContinuity,
         serverSizeData?.id || 0
        ) || 0
       }
       style={"currency"}
       currency="EUR"
      />
     </Typography>
    </Stack>
   </Stack>
  </>
 );
};

export default HetznerRecapBox;
