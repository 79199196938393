import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";

import { getAllServersNotPaged } from "redux/handlers/serverHandler";

import { getNetworksDataset } from "redux/selectors/networksSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IAllServers } from "types/api/serversApiInterface";

import CreateRoute from "../actions/CreateRoute";
import DeleteRoute from "../actions/DeleteRoute";

const NetworkRoute = (): ReactElement => {
 const dispatch = useAppDispatch();
 const params = useParams<{ id: string; section: string }>();

 const networkData = useSelector(getNetworksDataset).find(
  (element) => element.id === Number(params.id)
 );

 const [servers, setServers] = useState<Array<IAllServers>>([]);
 const [loading, setLoading] = useState<boolean>(true);

 useEffect(() => {
  (async () => {
   setLoading(true);
   setServers(await dispatch(getAllServersNotPaged()));
   setLoading(false);
  })();
 }, []);

 const checkForServers = (): boolean => {
  if (
   servers.find((element) =>
    element.private_net.find((net) => net.network === networkData?.network_id)
   )
  )
   return true;
  else return false;
 };

 return (
  <Paper elevation={0} sx={{ mt: 1, borderRadius: "10px", boxShadow: 0 }}>
   <Stack alignItems="center" justifyContent="flex-start" direction="row" px={2} py={1}>
    <IconSelector icon="KeyIcon" props={{ fontSize: "medium", color: "secondary" }} />
    <Typography variant="h6" component="h2" ml={1}>
     <FormattedMessage id="addons.network.route" />{" "}
    </Typography>
   </Stack>
   {loading ? (
    <CircularProgress />
   ) : networkData?.routes.length === 0 ? (
    <Stack p={2}>
     {checkForServers() ? (
      <Alert severity="info">
       <Stack alignItems="flex-start">
        <Typography>
         <FormattedMessage id="addons.network.noRouteFound" />
        </Typography>
        <CreateRoute
         id={networkData.id || 0}
         servers={servers}
         network_id={networkData.network_id}
        />
       </Stack>
      </Alert>
     ) : (
      <Alert severity="error">
       <Typography>
        <FormattedMessage id="addons.network.noServerAttachedForRoute" />
       </Typography>
      </Alert>
     )}
    </Stack>
   ) : (
    <Stack p={2}>
     {networkData?.routes.map((element, index) => {
      return (
       <Stack
        key={`route-index-${index}`}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        px={2}
        sx={{ borderRadius: 2, border: "2px solid lightgray" }}>
        <Typography fontWeight="bold">
         {
          servers.find(
           (serv) =>
            serv.private_net &&
            serv.private_net.length !== 0 &&
            serv.private_net.find(
             (net) => net.network === networkData.network_id && element.gateway === net.ip
            )
          )?.server_name
         }
        </Typography>
        <Typography>{element.destination}</Typography>
        <Typography>{element.gateway}</Typography>
        <DeleteRoute id={networkData.id || 0} route_id={element.id} />
       </Stack>
      );
     })}
    </Stack>
   )}
  </Paper>
 );
};

export default NetworkRoute;
