/* eslint-disable react/style-prop-object */
import React, { ReactElement } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const RenderPerformance = ({
 label,
 value,
 width,
 justify
}: {
 label: string;
 value: number;
 width?: string;
 justify?: string;
}): ReactElement => {
 const performanceColor = value < 50 ? "#41C58C" : value < 75 ? "#FF9800" : "#F44336";
 return (
  <Stack direction="row" alignItems="center" justifyContent={justify ? justify : "flex-end"} pr={5}>
   <Typography variant="body1" fontSize={12} textTransform="uppercase" minWidth={20}>
    <FormattedMessage id={label} />
   </Typography>
   <Typography variant="body1" fontSize={12} textTransform="uppercase" ml={1.2} width={20}>
    <FormattedNumber value={value} style="unit" unit="percent" />
   </Typography>
   <Stack justifyContent="flex-end" minWidth={width ? width : "60%"}>
    <LinearProgress
     sx={{
      width: "100%",
      ml: 2,
      backgroundColor: "#F1F2F7",
      "& .MuiLinearProgress-bar": {
       backgroundColor: performanceColor
      },
      height: 10,
      borderRadius: 0.8
     }}
     variant="determinate"
     value={value}
    />
   </Stack>
  </Stack>
 );
};

export default RenderPerformance;
