import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import ispReducer from "redux/reducers/ispIdReducers";
import notificationReducer from "redux/reducers/notificationReducer";
import WebsitesDetailsReducer from "redux/reducers/websiteDetailsReducer";
import websitesReducer from "redux/reducers/websiteReducer";

import { IspApi } from "types/api/IspIdApiInterface";
import { IAllWebsiteData, IWebsiteData, IWebsitesApi } from "types/api/websitesApiInterface";

import { ApiService } from "service/ApiService";

export const getAllWebsites =
 (page: number, limit: number, isp_id: number, q?: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IWebsitesApi>(
    `/site/getallsites?page=${page}&limit=${limit}&q=${q || ""}&isp_id=${isp_id}`
   );
   dispatch(websitesReducer.actions.setWebsites(data || []));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load sites - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getAllWebsitesNotPaged =
 (): AppAction<Promise<Array<IAllWebsiteData>>> => async (dispatch) => {
  try {
   const { data } = await ApiService<Array<IAllWebsiteData>>("/site/list");
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load sites - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const getWebsiteDetail =
 (domain_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService(`/site/getsitedetails/${domain_id}`);
   dispatch(WebsitesDetailsReducer.actions.setWebsitesDetails(data));
  } catch (error: any) {
   console.warn(error);
  }
 };

export const deleteSite =
 (isp_id: number, site: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/site/deletesite ", "POST", {
    site: site,
    isp_id: isp_id
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Operazione inviata, verrà eseguita nel giro di qualche minuto",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Error",
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const updateSiteStatus =
 (isp_id: number, domain_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/site/setsitestatus", "POST", {
    isp_id: isp_id,
    domain_id: domain_id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Operazione inviata, verrà eseguita nel giro di qualche minuto",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update site status - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const addNewSite =
 (formData: IWebsiteData): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/site/addnewsite", "POST", {
    isp_id: formData.isp_id,
    site: formData.site,
    hd_quota: formData.quota,
    php_mode: formData.php_mode,
    php_version: formData.php_version,
    lets_encrypt: formData.lets_encrypt ? "y" : "n",
    ip: formData.ip
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Operazione inviata, verrà eseguita nel giro di qualche minuto",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to add site - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getAllIsp =
 (type: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IspApi>(`/ispconfig?type=${type}`);
   dispatch(ispReducer.actions.setIsp(data || []));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load sites - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCheckSiteName =
 (site: string, isp_id: number): AppAction<Promise<{ check: boolean; site: string }>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<{ check: boolean; site: string }>("/site/checkname", "POST", {
    site: site,
    isp_id: isp_id
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load sites - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return { check: false, site: "" };
  }
 };

export const getAllIspNotPaged =
 (type: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IspApi>(`/ispconfig/list?type=${type}`);
   dispatch(ispReducer.actions.setIsp(data || []));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load sites - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const editSiteDetails =
 (action: string, isp_id: number, domain_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/site/updatesite", "POST", {
    action: action,
    isp_id: isp_id,
    domain_id: domain_id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Operazione inviata, verrà eseguita nel giro di qualche minuto",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update site option - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const editSiteName =
 (
  action: string,
  isp_id: number,
  domain_id: number,
  newSite: string,
  id: number
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/site/updatesite", "POST", {
    action: action,
    isp_id: isp_id,
    domain_id: domain_id,
    newSite: newSite
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Operazione inviata, verrà eseguita nel giro di qualche minuto",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update site name - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const editSiteVersion =
 (
  action: string,
  isp_id: number,
  domain_id: number,
  php_version: string,
  id: number
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/site/updatesite", "POST", {
    action: action,
    isp_id: isp_id,
    domain_id: domain_id,
    php_version: php_version
   });
   if (data) {
    dispatch(getWebsiteDetail(id));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Operazione inviata, verrà eseguita nel giro di qualche minuto",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update site PHP version - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const editSiteMode =
 (
  action: string,
  isp_id: number,
  domain_id: number,
  php_mode: string,
  id: number
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/site/updatesite", "POST", {
    action: action,
    isp_id: isp_id,
    domain_id: domain_id,
    php: php_mode
   });
   if (data) {
    dispatch(getWebsiteDetail(id));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Operazione inviata, verrà eseguita nel giro di qualche minuto",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update site PHP type - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const editSiteQuota =
 (
  action: string,
  isp_id: number,
  domain_id: number,
  hd_quota: number,
  id: number
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/site/updatesite", "POST", {
    action: action,
    isp_id: isp_id,
    domain_id: domain_id,
    hd_quota: hd_quota
   });
   if (data) {
    dispatch(getWebsiteDetail(id));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Operazione inviata, verrà eseguita nel giro di qualche minuto",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update site quota - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const editSiteTraffic =
 (
  action: string,
  isp_id: number,
  domain_id: number,
  traffic_quota: number,
  id: number
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/site/updatesite", "POST", {
    action: action,
    isp_id: isp_id,
    domain_id: domain_id,
    traffic_quota: traffic_quota
   });
   if (data) {
    dispatch(getWebsiteDetail(id));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Operazione inviata, verrà eseguita nel giro di qualche minuto",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update site traffic quota - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const editSiteDirectives =
 (
  action: string,
  isp_id: number,
  domain_id: number,
  directives: string,
  id: number
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const payload =
    action === "apache_directives"
     ? {
        action: action,
        isp_id: isp_id,
        domain_id: domain_id,
        apache_directives: directives
       }
     : action === "nginx_directives"
     ? {
        action: action,
        isp_id: isp_id,
        domain_id: domain_id,
        nginx_directives: directives
       }
     : {
        action: action,
        isp_id: isp_id,
        domain_id: domain_id,
        custom_php_ini: directives
       };
   const { data } = await ApiService("/site/updatesite", "POST", payload);
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Updating site directives ongoing",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update site directives - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postAddDatabase =
 (
  domain_id: number,
  isp_id: number,
  username: string,
  password: string,
  db_name: string,
  db_quota: number,
  id: number
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/sitesdb/adddb", "POST", {
    isp_id: isp_id,
    domain_id: domain_id,
    username: username,
    password: password,
    db_name: db_name,
    db_quota: db_quota
   });
   if (data) {
    dispatch(getWebsiteDetail(id));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Operazione inviata, verrà eseguita nel giro di qualche minuto",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create database - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateDatabaseUser =
 (
  domain_id: number,
  username: string,
  password: string,
  isp_id: number,
  domain_db_id: number
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/sitesdb/updatedbuser", "POST", {
    isp_id: isp_id,
    username: username,
    password: password,
    id: domain_db_id
   });
   if (data) {
    dispatch(getWebsiteDetail(domain_id));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Operazione inviata, verrà eseguita nel giro di qualche minuto",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update database user - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateDatabase =
 (
  id: number,
  isp_id: number,
  domain_id: number,
  database_name: string,
  database_quota: number,
  remote_access: string,
  domain_db_id: number,
  remote_ips: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/sitesdb/updatedb", "POST", {
    isp_id: isp_id,
    database_name: database_name,
    database_quota: database_quota,
    remote_access: remote_access,
    id: id,
    remote_ips
   });
   if (data) {
    dispatch(getWebsiteDetail(domain_db_id));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Operazione inviata, verrà eseguita nel giro di qualche minuto",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update database - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteDatabase =
 (
  db_user_id: number,
  site_db_id: number,
  isp_id: number,
  domain_id: number
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/sitesdb/deletedb", "POST", {
    db_user_id: db_user_id,
    site_db_id: site_db_id,
    isp_id: isp_id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Operazione inviata, verrà eseguita nel giro di qualche minuto",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete database - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postAddAlias =
 (alias: string, isp_id: number, domain_id: number, id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/alias/addalias", "POST", {
    alias: alias,
    isp_id: isp_id,
    domain_id: domain_id
   });
   if (data) {
    dispatch(getWebsiteDetail(id));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Operazione inviata, verrà eseguita nel giro di qualche minuto",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create alias - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateAlias =
 (
  id: number,
  isp_id: number,
  domain_id: number,
  alias: string,
  ssl: string,
  active: string,
  domain_db_id: number
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/alias/updatealias", "POST", {
    alias: alias,
    isp_id: isp_id,
    ssl: ssl,
    active: active,
    id: id
   });
   if (data) {
    dispatch(getWebsiteDetail(domain_db_id));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Operazione inviata, verrà eseguita nel giro di qualche minuto",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update alias - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteAlias =
 (id: number, isp_id: number, domain_id: number, domain_db_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/alias/deletealias", "POST", {
    isp_id: isp_id,
    id: id
   });
   if (data) {
    dispatch(getWebsiteDetail(domain_db_id));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Operazione inviata, verrà eseguita nel giro di qualche minuto",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete alias - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postAddFtpUser =
 (
  username: string,
  password: string,
  isp_id: number,
  domain_id: number,
  domain_db_id: number
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/sitesftp/addnewftp", "POST", {
    username: username,
    password: password,
    isp_id: isp_id,
    domain_id: domain_id
   });
   if (data) {
    dispatch(getWebsiteDetail(domain_db_id));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Operazione inviata, verrà eseguita nel giro di qualche minuto",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create ftp user - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateFtpUser =
 (
  username: string,
  password: string,
  isp_id: number,
  domain_id: number,
  id: number,
  domain_db_id: number
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/sitesftp/updateftp", "POST", {
    username: username,
    password: password,
    isp_id: isp_id,
    id: id
   });
   if (data) {
    dispatch(getWebsiteDetail(domain_db_id));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Operazione inviata, verrà eseguita nel giro di qualche minuto",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update ftp user - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteFtpUser =
 (isp_id: number, domain_id: number, id: number, domain_db_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/sitesftp/deleteftp", "POST", {
    isp_id: isp_id,
    id: id
   });
   if (data) {
    dispatch(getWebsiteDetail(domain_db_id));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Operazione inviata, verrà eseguita nel giro di qualche minuto",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete ftp user - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postAddCronjob =
 (
  isp_id: number,
  command: string,
  type: string,
  domain_id: number,
  run_min: string,
  run_hour: string,
  run_mday: string,
  run_month: string,
  run_wday: string,
  domain_db_id: number
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/sitescron/addsitecron", "POST", {
    isp_id: isp_id,
    command: command,
    type: type,
    domain_id: domain_id,
    run_min: run_min,
    run_hour: run_hour,
    run_mday: run_mday,
    run_month: run_month,
    run_wday: run_wday
   });
   if (data) {
    dispatch(getWebsiteDetail(domain_db_id));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Operazione inviata, verrà eseguita nel giro di qualche minuto",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create cronjob - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateCronjob =
 (
  id: number,
  isp_id: number,
  command: string,
  type: string,
  domain_id: number,
  run_min: string,
  run_hour: string,
  run_mday: string,
  run_month: string,
  run_wday: string,
  domain_db_id: number
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/sitescron/updatecron", "POST", {
    id: id,
    isp_id: isp_id,
    command: command,
    type: type,
    domain_id: domain_id,
    run_min: run_min,
    run_hour: run_hour,
    run_mday: run_mday,
    run_month: run_month,
    run_wday: run_wday
   });
   if (data) {
    dispatch(getWebsiteDetail(domain_db_id));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Operazione inviata, verrà eseguita nel giro di qualche minuto",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update cronjob - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postChangeCronjobStatus =
 (
  id: number,
  isp_id: number,
  command: string,
  type: string,
  domain_id: number,
  run_min: string,
  run_hour: string,
  run_mday: string,
  run_month: string,
  run_wday: string,
  domain_db_id: number,
  status: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/sitescron/updatecron", "POST", {
    id: id,
    isp_id: isp_id,
    command: command,
    type: type,
    domain_id: domain_id,
    run_min: run_min,
    run_hour: run_hour,
    run_mday: run_mday,
    run_month: run_month,
    run_wday: run_wday,
    active: status
   });
   if (data) {
    dispatch(getWebsiteDetail(domain_db_id));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Operazione inviata, verrà eseguita nel giro di qualche minuto",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to change cronjob status - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteCronjob =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/sitescron/deletecron", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Operazione inviata, verrà eseguita nel giro di qualche minuto",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete cronjob - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postEnableBackup =
 (
  domain_id: number,
  isp_id: number,
  backup_interval: string,
  backup_copy: number
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/site/updatesite", "POST", {
    action: "update_backup",
    domain_id: domain_id,
    isp_id: isp_id,
    backup_interval: backup_interval,
    backup_copies: backup_copy
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Operazione inviata, verrà eseguita nel giro di qualche minuto",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to enable backup - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDisableBackups =
 (domain_id: number, isp_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/site/updatesite", "POST", {
    action: "update_backup",
    domain_id: domain_id,
    isp_id: isp_id,
    backup_interval: "none",
    backup_copies: 1
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Operazione inviata, verrà eseguita nel giro di qualche minuto",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to disable backup - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateBackup =
 (
  domain_id: number,
  isp_id: number,
  backup_interval: string,
  backup_copy: number
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/site/updatesite", "POST", {
    action: "update_backup",
    domain_id: domain_id,
    isp_id: isp_id,
    backup_interval: backup_interval,
    backup_copies: backup_copy
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Operazione inviata, verrà eseguita nel giro di qualche minuto",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update backup - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postRestoreBackup =
 (id: number, domain_db_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/sitebackup/restore", "POST", {
    id: id
   });
   if (data) {
    dispatch(getWebsiteDetail(domain_db_id));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Operazione inviata, verrà eseguita nel giro di qualche minuto",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to restore from backup - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateFtpDirectory =
 (id: number, dir: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/sitesftp/updatedir", "POST", {
    id: id,
    dir: dir
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Operazione inviata, verrà eseguita nel giro di qualche minuto",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update ftp directory - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };
