import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { INetworkApi } from "types/api/addonsApiInterface";

const initialState: INetworkApi = { dataset: [], totalCount: 0 };

const NetworksReducer = createSlice({
 name: "networks",
 initialState,
 reducers: {
  setNetworks: (state, { payload }: PayloadAction<INetworkApi>) => {
   return payload;
  },
  resetNetworks: () => {
   return initialState;
  }
 }
});

export default NetworksReducer;
