import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postDeleteWhitelist } from "redux/handlers/gatewayHandler";

import { useAppDispatch } from "hooks/reduxHook";

const DeleteWhitelist = ({ id, whitelist }: { id: number; whitelist: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setIsLoading(true);
  await dispatch(postDeleteWhitelist(id, whitelist));
  handleClose();
  setIsLoading(false);
 };

 return (
  <>
   <Tooltip title={<FormattedMessage id="gateway.deleteWhitelist" />}>
    <IconButton onClick={() => handleOpen()}>
     <IconSelector icon="DeleteIcon" />
    </IconButton>
   </Tooltip>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    handleConfirm={handleConfirm}
    title={intl.formatMessage({ id: "gateway.deleteWhitelist" })}>
    <Typography>
     <FormattedMessage id="gateway.deleteWhitelistMessage" />
    </Typography>
   </AppModal>
  </>
 );
};

export default DeleteWhitelist;
