import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { ILoadBalancerAPI } from "types/api/addonsApiInterface";

const initialState: ILoadBalancerAPI = { dataset: [], totalCount: 0 };

const LoadBalancerReducer = createSlice({
 name: "loadBalancer",
 initialState,
 reducers: {
  setLoadBalancer: (state, { payload }: PayloadAction<ILoadBalancerAPI>) => {
   return payload;
  },
  resetLoadBalancer: () => {
   return initialState;
  }
 }
});

export default LoadBalancerReducer;
