/* eslint-disable react/style-prop-object */
import React, { ReactElement, useState } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Dropdown } from "@mui/base/Dropdown";

import { isNil } from "ramda";

import { AccountCircle } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LogoutIcon from "@mui/icons-material/Logout";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { profileMenuLinks } from "constants/staticLinks";

import IconSelector from "components/shared/images/IconSelector";

import { logoutAction, postRestoreAsClient } from "redux/handlers/userHandler";

import { getUserBalance, getUserPersonalData } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import AdminWrapper from "../shared/admin/AdminWrapper";

const HeaderDropdown = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const userPersonalData = useSelector(getUserPersonalData);
 const balance = useSelector(getUserBalance);

 const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
 const open = Boolean(anchorEl);

 const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
  setAnchorEl(null);
 };

 const handleLogout = (): void => {
  dispatch(logoutAction());
 };

 const handleRestore = async () => {
  await dispatch(postRestoreAsClient(localStorage.getItem("oldId") || ""));
  window.location.reload();
 };

 const linkSection = (name: string): number => {
  switch (name) {
   case "profile.host":
    return 1;
   case "profile.teams":
    return 2;
   default:
    return 0;
  }
 };

 const list = () =>
  profileMenuLinks.map((link, index) => (
   <MenuItem key={`profile-menu-link-${index}`} sx={{ p: 0 }}>
    <NavLink
     to={`/profile/${link.url}`}
     state={{ section: linkSection(link.name) }}
     style={{
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
      color: "#3b3838",
      width: "100%",
      paddingLeft: "15px",
      paddingRight: "15px",
      paddingBottom: "10px",
      paddingTop: "10px"
     }}>
     <Stack sx={{ pl: 1, pr: 2 }}>{<IconSelector icon={link?.icon} />}</Stack>
     <Typography>{intl.formatMessage({ id: link.name })}</Typography>
    </NavLink>
   </MenuItem>
  ));

 return (
  <Dropdown>
   <Button variant="text" disableElevation onClick={handleClick}>
    <Stack direction="row" alignItems="center">
     <Stack>
      <Typography sx={{ color: "#000" }} fontSize={14} textAlign="left">
       {userPersonalData.company || "Company"}
      </Typography>
      <Typography
       variant="subtitle2"
       fontStyle="italic"
       sx={{ textTransform: "lowercase", color: "black" }}
       textAlign="left">
       {`${userPersonalData.firstname} ${userPersonalData.lastname}`}
      </Typography>
     </Stack>
     <ArrowDropDownIcon color="disabled" />
     {isNil(userPersonalData.avatar) ? (
      <AccountCircle sx={{ height: 36, width: 36, color: "#d3d3d3" }} />
     ) : (
      <Avatar
       alt={`${userPersonalData.firstname} ${userPersonalData.lastname}`}
       src={`${userPersonalData.avatar}`}
       sx={{ height: 36, width: 36 }}
      />
     )}
    </Stack>
   </Button>
   <Menu anchorEl={anchorEl} open={open} onClick={handleClose} onClose={handleClose}>
    {list()}
    <MenuItem sx={{ py: 1.3 }}>
     <AdminWrapper>
      <NavLink
       to="/admin/configurator"
       style={{
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        color: "#e36666",
        width: "100%",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingBottom: "2px",
        paddingTop: "2px"
       }}>
       <SettingsSuggestOutlinedIcon sx={{ pl: 1, pr: 2 }} />
       <Typography>{intl.formatMessage({ id: "app.configurator" })}</Typography>
      </NavLink>
     </AdminWrapper>
    </MenuItem>
    <Stack direction="row" spacing={1} justifyContent="center">
     <Typography variant="caption" fontStyle="italic" fontWeight="bold">
      <FormattedMessage id="app.personalCredit" />:
     </Typography>
     <Typography variant="caption" fontStyle="italic" fontWeight="bold">
      <FormattedNumber style="currency" currency="EUR" value={balance} />
     </Typography>
    </Stack>
    <MenuItem onClick={handleLogout} sx={{ py: 1.3, borderTop: "1px solid gray" }}>
     <LogoutIcon sx={{ pl: 1, pr: 2 }} />
     <Typography>{intl.formatMessage({ id: "app.logout" })}</Typography>
    </MenuItem>
    {localStorage.getItem("oldId") && (
     <MenuItem onClick={handleRestore} sx={{ py: 1.3 }}>
      <RestartAltIcon sx={{ pl: 1, pr: 2 }} />
      <Typography>{intl.formatMessage({ id: "app.restore" })}</Typography>
     </MenuItem>
    )}
   </Menu>
  </Dropdown>
 );
};

export default HeaderDropdown;
