import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const BellIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <path
   fillRule="evenodd"
   clipRule="evenodd"
   d="M17 7.5V12.0146C18.2264 13.6247 19 15 19 15H10H1C1 15 1.77358 13.6247 3 12.0146V7.5V7H3.01531C3.25545 3.09098 6.29082 0 10 0C13.7092 0 16.7446 3.09098 16.9847 7H17V7.5ZM10 20C7.79086 20 6 18.2091 6 16H14C14 18.2091 12.2091 20 10 20Z"
   fill="currentColor"
  />
 </svg>,
 "Bell"
);
