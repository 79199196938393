import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postChangePartnerStatus } from "redux/handlers/userHandler";

import { useAppDispatch } from "hooks/reduxHook";

const DisablePartner = ({ id, reload }: { id: number; reload: () => void }) => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postChangePartnerStatus(id));
  setLoading(false);
  reload();
 };

 return (
  <>
   <Tooltip
    title={intl.formatMessage({ id: "configurator.partner.disablePartner" })}
    placement="top">
    <IconButton onClick={handleOpen}>
     <IconSelector icon="PersonOffIcon" props={{ fontSize: "small" }} />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "configurator.partner.disablePartner" })}
    handleClose={handleClose}
    disabled={loading}
    handleConfirm={handleConfirm}>
    <Typography>
     <FormattedMessage id="configurator.partner.disablePartnerMessage" />
    </Typography>
   </AppModal>
  </>
 );
};

export default DisablePartner;
