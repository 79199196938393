import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { IVolumesApi } from "types/api/addonsApiInterface";

const initialState: IVolumesApi = { dataset: [], totalCount: 0 };

const VolumesReducer = createSlice({
 name: "volumes",
 initialState,
 reducers: {
  setVolumes: (state, { payload }: PayloadAction<IVolumesApi>) => {
   return payload;
  },
  resetVolumes: () => {
   return initialState;
  }
 }
});

export default VolumesReducer;
