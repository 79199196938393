export const formatMonth = (month: number) => {
 const formattedMonth = Number(month.toFixed(0));
 switch (formattedMonth) {
  case 1:
   return "app.january";
  case 2:
   return "app.february";
  case 3:
   return "app.march";
  case 4:
   return "app.april";
  case 5:
   return "app.may";
  case 6:
   return "app.june";
  case 7:
   return "app.july";
  case 8:
   return "app.august";
  case 9:
   return "app.september";
  case 10:
   return "app.october";
  case 11:
   return "app.november";
  case 12:
   return "app.december";
  default:
   return "app.notAvailable";
 }
};
