import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { isNil } from "ramda";

import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postAttachServerToFirewall } from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

import { IAllServers } from "types/api/serversApiInterface";

const AttachServer = ({
 id,
 servers
}: {
 id: number;
 servers: Array<IAllServers>;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [selectedServer, setSelectedServer] = useState<number>(0);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postAttachServerToFirewall(id, selectedServer));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Button variant="kxActionButton" onClick={() => handleOpen()}>
    <FormattedMessage id="addons.firewall.attachServer" />
   </Button>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "addons.firewall.attachServer" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading || selectedServer === 0}>
    <Stack>
     <Typography>
      <FormattedMessage id="addons.firewall.attachServerMessage" />
     </Typography>
     <Autocomplete
      fullWidth={true}
      autoHighlight
      sx={{ my: 2 }}
      onChange={(e, value) =>
       isNil(value) ? setSelectedServer(0) : setSelectedServer(value.server_id)
      }
      options={servers.map((element) => {
       return {
        server_id: element.id,
        label: element.server_name
       };
      })}
      renderInput={(params) => (
       <TextField
        {...params}
        label={<FormattedMessage id="addons.firewall.server" />}
        InputLabelProps={{ shrink: true }}
       />
      )}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default AttachServer;
