import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postRenewService } from "redux/handlers/servicesHandler";

import { getUserBalance } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { serviceTableData } from "types/global/table";
import { EServiceEndpoints } from "types/redux/userInterfaces";

const ManualRenew = ({
 rowData,
 status
}: {
 rowData: serviceTableData;
 status: string;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const userBalance = useSelector(getUserBalance);

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleRenew = async () => {
  setLoading(true);
  switch (rowData.type) {
   case "floating_ip":
    await dispatch(postRenewService(rowData.id, EServiceEndpoints.FLOATINGIP));
    break;
   case "server":
    await dispatch(postRenewService(rowData.id, EServiceEndpoints.SERVER));
    break;
   case "domain":
    await dispatch(postRenewService(rowData.id, EServiceEndpoints.DOMAIN));
    break;
   case "volume":
    await dispatch(postRenewService(rowData.id, EServiceEndpoints.VOLUMES));
    break;
   case "snapshot":
    await dispatch(postRenewService(rowData.id, EServiceEndpoints.SNAPSHOT));
    break;
   case "cloudbox":
    await dispatch(postRenewService(rowData.id, EServiceEndpoints.CLOUDBOX));
    break;
   case "network":
    await dispatch(postRenewService(rowData.id, EServiceEndpoints.NETWORK));
    break;
   case "firewall":
    await dispatch(postRenewService(rowData.id, EServiceEndpoints.FIREWALL));
    break;
   case "baremetal":
    await dispatch(postRenewService(rowData.id, EServiceEndpoints.BAREMETAL));
    break;
   case "load_balancer":
    await dispatch(postRenewService(rowData.id, EServiceEndpoints.BALANCER));
    break;
   default:
    await dispatch(postRenewService(rowData.id, "/services/renewextraservice"));
    break;
  }
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Button variant="kxActionButton" onClick={handleOpen}>
    <FormattedMessage
     id={status === "expired" ? "profile.services.restore" : "profile.services.renew"}
    />
   </Button>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({
     id: status === "expired" ? "profile.services.restore" : "profile.services.renew"
    })}
    handleClose={handleClose}
    handleConfirm={handleRenew}
    disabled={
     loading ||
     userBalance < (status === "expired" ? 15 + Number(rowData.price) : Number(rowData.price))
    }>
    <Stack spacing={2}>
     {userBalance < (status === "expired" ? 15 + Number(rowData.price) : Number(rowData.price)) && (
      <Alert severity="warning">
       <FormattedMessage id="app.balanceInsufficient" />
      </Alert>
     )}
     <Typography>
      <FormattedMessage
       id={
        status === "expired"
         ? "profile.services.restoreMessage"
         : "profile.services.manualRenewMessage"
       }
      />
     </Typography>
     <Typography fontWeight="bold">{`${intl.formatMessage({
      id: "app.balance"
     })}: ${userBalance}€`}</Typography>
     <Typography fontWeight="bold">{`${intl.formatMessage({ id: "app.price" })}: ${
      status === "expired" ? 15 + Number(rowData.price) : rowData.price
     }€`}</Typography>
    </Stack>
   </AppModal>
  </>
 );
};

export default ManualRenew;
