import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";

import IconSelector from "components/shared/images/IconSelector";

import DeleteBackup from "./DeleteBackup";
import RebuildBackup from "./RebuildBackup";
import SnapshotConversion from "./SnapshotConversion";

const BackupActions = ({
 id,
 reloadData
}: {
 id: number;
 reloadData: () => void;
}): ReactElement => {
 const navigate = useNavigate();

 const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
 const open = Boolean(anchorEl);

 const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
  setAnchorEl(null);
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <IconSelector icon="MenuIcon" />
   </Button>
   <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
     "aria-labelledby": "basic-button"
    }}>
    <Stack>
     <SnapshotConversion id={id} reloadData={() => reloadData()} closeMenu={handleClose} />
     <RebuildBackup id={id} reloadData={() => reloadData()} closeMenu={handleClose} />
     <MenuItem onClick={() => navigate(`/servers/add/frombackup/${id}`)}>
      <FormattedMessage id="server.backups.createServer" />
     </MenuItem>
     <DeleteBackup id={id} reloadData={() => reloadData()} closeMenu={handleClose} />
    </Stack>
   </Menu>
  </>
 );
};

export default BackupActions;
