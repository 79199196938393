import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const FtpIcon = createSvgIcon(
 <svg
  fill="transparent"
  version="1.1"
  id="Capa_1"
  xmlns="http://www.w3.org/2000/svg"
  width="800px"
  height="800px"
  viewBox="0 0 98.385 98.385"
  xmlSpace="preserve">
  <g>
   <g>
    <path
     fill="currentColor"
     d="M61.838,54.803c-0.793,0-1.33,0.053-1.611,0.129v5.085c0.332,0.075,0.742,0.103,1.304,0.103
           c2.069,0,3.349-1.047,3.349-2.785C64.879,55.75,63.805,54.803,61.838,54.803z"
    />
    <path
     fill="currentColor"
     d="M53.155,31.677c-2.188-2.187-5.734-2.187-7.922,0L20.356,56.555c-2.188,2.188-2.188,5.734,0,7.923l24.876,24.875
           c2.188,2.188,5.734,2.188,7.922,0l24.877-24.877c1.051-1.05,1.641-2.476,1.641-3.961s-0.59-2.91-1.641-3.962L53.155,31.677z
            M40.153,55.161h-6.618v3.937h6.184v3.168h-6.184v6.925h-3.884V51.967h10.502V55.161z M55.026,55.238h-4.703v13.951H46.44V55.238
           h-4.65v-3.271h13.236V55.238z M67.178,61.293c-1.33,1.229-3.322,1.815-5.621,1.815c-0.512,0-0.971-0.024-1.33-0.103v6.184h-3.857
           V52.198c1.201-0.205,2.889-0.358,5.264-0.358c2.401,0,4.139,0.461,5.289,1.405c1.1,0.845,1.814,2.274,1.814,3.962
           C68.736,58.918,68.2,60.349,67.178,61.293z"
    />
    <path
     fill="currentColor"
     d="M78.445,22.433c-0.545-0.039-1.046-0.318-1.366-0.762c-3.998-5.545-10.51-8.976-17.444-8.976
           c-0.502,0-1.004,0.018-1.506,0.053c-0.451,0.032-0.896-0.103-1.255-0.378c-4.198-3.229-9.314-4.979-14.675-4.979
           c-9.579,0-18.069,5.614-21.936,14.088c-0.266,0.583-0.816,0.985-1.452,1.065C8.221,23.867,0,32.926,0,43.869
           c0,9.697,6.46,17.908,15.301,20.574c-0.534-1.225-0.82-2.553-0.82-3.928c0-1.766,0.472-3.455,1.338-4.94
           c-4.343-2.114-7.351-6.559-7.351-11.706c0-7.182,5.843-13.024,13.025-13.024c0.363,0,0.719,0.029,1.074,0.059
           c2.069,0.159,3.943-1.183,4.447-3.19c1.752-6.979,7.996-11.854,15.184-11.854c4.107,0,7.994,1.586,10.944,4.466
           c1.009,0.984,2.439,1.401,3.82,1.114c0.879-0.182,1.777-0.275,2.672-0.275c5.027,0,9.519,2.826,11.719,7.377
           c0.772,1.6,2.464,2.553,4.232,2.371c0.44-0.045,0.879-0.066,1.307-0.066c7.183,0,13.025,5.843,13.025,13.024
           c0,5.147-3.008,9.591-7.351,11.706c0.866,1.484,1.338,3.173,1.338,4.938c0,1.376-0.287,2.705-0.821,3.931
           c8.842-2.666,15.301-10.877,15.301-20.575C98.387,32.542,89.575,23.229,78.445,22.433z"
    />
   </g>
  </g>
 </svg>,
 "Ftp"
);
