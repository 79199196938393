import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";

import { isNil } from "ramda";

import WebIcon from "@mui/icons-material/Web";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { useTheme } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { steps } from "constants/mails";
import { siteValidation } from "constants/regexp";

import AppModal from "components/shared/modal/AppModal";

import { getSpamfilterData, postAddMailDomain } from "redux/handlers/mailsHandler";
import { getAllIspNotPaged } from "redux/handlers/websitesHandler";

import { getIspList } from "redux/selectors/ispSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { ISpamSelector } from "types/api/mailApiInterface";

const MailsWizard = () => {
 const intl = useIntl();
 const navigate = useNavigate();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const ispList = useSelector(getIspList);

 const [activeStep, setActiveStep] = useState<number>(0);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [spamData, setSpamData] = useState<Array<ISpamSelector>>([]);
 const [loading, setLoading] = useState<boolean>(false);

 useEffect(() => {
  (async () => {
   await dispatch(getAllIspNotPaged("mailserver"));
   const spamArray = await getSpamfilterData();
   spamArray.push({ id: 0, policy_id: 0, policy_name: "-disable spamfilter-" });
   setSpamData(spamArray);
   setIsLoading(false);
  })();
 }, []);

 const { control, formState, reset, watch, getValues, setValue } = useForm({
  defaultValues: {
   mail: "",
   spam: 0,
   host: 0,
   dkim: false
  }
 });

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);
 const handleReset = () => {
  reset();
  setActiveStep(0);
 };

 const handleNext = () => {
  setActiveStep((prevActiveStep) => prevActiveStep + 1);
 };

 const handleBack = () => {
  setActiveStep((prevActiveStep) => prevActiveStep - 1);
 };

 const handleConfirm = async () => {
  setLoading(true);
  const dataToSend = getValues();
  await dispatch(
   postAddMailDomain(dataToSend.mail, dataToSend.host, dataToSend.dkim, dataToSend.spam)
  );

  setLoading(false);
  handleClose();
  handleReset();
  navigate("/app/mail");
 };

 const renderStepContent = (index: number) => {
  switch (index) {
   case 0:
    return (
     <Controller
      name="mail"
      control={control}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "mails.name" })}
        error={formState.isDirty && !!formState?.errors?.mail}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="new-password"
       />
      )}
     />
    );
   case 1:
    return (
     <Controller
      name="host"
      control={control}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) => (isNil(value) ? setValue("host", 0) : setValue("host", value.id))}
        options={ispList.map((isp) => {
         return { label: isp.hostname, id: isp.id };
        })}
        value={ispList
         .map((element) => {
          return { label: element.hostname, id: element.id };
         })
         .find((element) => element.id === getValues("host"))}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          label={<FormattedMessage id="mails.add.mailHost" />}
          error={formState.isDirty && !!formState?.errors?.host}
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
      )}
     />
    );
   case 2:
    return (
     <Controller
      name="spam"
      control={control}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) => (isNil(value) ? setValue("spam", 0) : setValue("spam", value.id))}
        options={spamData.map((spam) => {
         return { label: spam.policy_name, id: spam.policy_id };
        })}
        value={spamData
         .map((spam) => {
          return { label: spam.policy_name, id: spam.policy_id };
         })
         .find((element) => element.id === getValues("spam"))}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          label={<FormattedMessage id="mails.add.spamfilter" />}
          error={formState.isDirty && !!formState?.errors?.spam}
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
      )}
     />
    );
   case 3:
    return (
     <Controller
      name="dkim"
      control={control}
      render={({ field }) => (
       <FormControlLabel
        control={
         <Switch
          {...field}
          checked={getValues("dkim")}
          onChange={(e, checked) => {
           setValue("dkim", checked);
          }}
         />
        }
        label={intl.formatMessage({ id: "mails.add.dkim" })}
       />
      )}
     />
    );
  }
 };

 const disableNextButton = (index: number) => {
  switch (index) {
   case 0:
    return watch("mail").length === 0 || !siteValidation.test(watch("mail"));
   case 1:
    return watch("host") === 0;
   default:
    return false;
  }
 };

 const checkDisableConfirm = () => {
  return watch("mail") === "" || watch("host") === 0;
 };

 return !isLoading ? (
  <Stack spacing={2}>
   <Stack alignItems="center" mt={5} p={3}>
    <WebIcon />
    <Typography>
     <FormattedMessage id={"mails.newMail"} />
    </Typography>
   </Stack>
   <Stack direction={desktopViewPort ? "row" : "column"} justifyContent="center">
    <Box
     sx={{ maxWidth: desktopViewPort ? 1000 : "auto", minWidth: desktopViewPort ? 500 : "auto" }}
     m={10}>
     <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map((step, index) => (
       <Step key={step.label}>
        <StepLabel>
         <FormattedMessage id={step.label} />
        </StepLabel>
        <StepContent>
         <Stack spacing={2}>
          <Alert severity="info">
           <Typography>
            <FormattedMessage id={step.description} />
           </Typography>
          </Alert>
          {renderStepContent(index)}
         </Stack>
         <Box sx={{ mb: 2 }}>
          <div>
           {index !== steps.length - 1 ? (
            <Button
             disabled={disableNextButton(index)}
             variant="contained"
             onClick={handleNext}
             sx={{ mt: 1, mr: 1 }}>
             <FormattedMessage id="mails.continue" />
            </Button>
           ) : (
            <></>
           )}
           {index === 3 && (
            <>
             <Button
              sx={{ mt: 1, mr: 1 }}
              variant="contained"
              disabled={checkDisableConfirm()}
              onClick={handleOpen}>
              <FormattedMessage id="mails.confirmMail" />
             </Button>
             <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              <FormattedMessage id="mails.mailReset" />
             </Button>
            </>
           )}
           <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
            <FormattedMessage id="mails.back" />
           </Button>
          </div>
         </Box>
        </StepContent>
       </Step>
      ))}
     </Stepper>
    </Box>
    <Paper
     elevation={0}
     sx={{
      m: 5,
      p: 2,
      maxHeight: 300,
      position: "sticky",
      top: 30,
      minWidth: desktopViewPort ? 400 : "auto",
      borderRadius: "10px",
      boxShadow: 0
     }}>
     <Stack mb={2}>
      <Typography variant="h5" fontWeight="bold">
       <FormattedMessage id="mails.add.recap" />
      </Typography>
     </Stack>
     <Divider textAlign="left">
      <Typography variant="overline">
       <FormattedMessage id="mails.mailData" />
      </Typography>
     </Divider>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={120} textAlign="start" fontWeight="bold">
       <FormattedMessage id="mails.name" />:
      </Typography>
      <Typography noWrap>{watch("mail")}</Typography>
     </Stack>
     <Divider textAlign="left" sx={{ mt: 2 }}>
      <Typography variant="overline">
       <FormattedMessage id="mails.mailServer" />
      </Typography>
     </Divider>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={120} textAlign="start" fontWeight="bold">
       <FormattedMessage id="mails.host" />:
      </Typography>
      <Typography noWrap>
       {ispList.find((element) => element.id === watch("host"))?.hostname || ""}
      </Typography>
     </Stack>
     <Divider textAlign="left" sx={{ mt: 2 }}>
      <Typography variant="overline">
       <FormattedMessage id="mails.mailSpamFilter" />
      </Typography>
     </Divider>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={120} textAlign="start" fontWeight="bold">
       <FormattedMessage id="mails.spamFilter" />:
      </Typography>
      <Typography>
       {spamData.find((element) => element.id === watch("spam"))?.policy_name || ""}
      </Typography>
     </Stack>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={120} textAlign="start" fontWeight="bold">
       <FormattedMessage id="mails.dkim" />:
      </Typography>
      <Typography>{watch("dkim") ? "Abilitato" : "Disabilitato"}</Typography>
     </Stack>
    </Paper>
   </Stack>
   <AppModal
    open={openModal}
    close={handleClose}
    disabled={loading}
    title={intl.formatMessage({ id: "mails.createNewMail" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}>
    <FormattedMessage id="mails.confirmMailCreation" />
   </AppModal>
  </Stack>
 ) : (
  <></>
 );
};

export default MailsWizard;
