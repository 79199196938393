import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

import { ILoadBalancerDataset } from "types/api/addonsApiInterface";

export const getLoadBalancers = (state: AppState) => state.loadBalancer;

export const getLoadBalancerDataset = (state: AppState) => state.loadBalancerDetails;

export const getLoadBalancerNumber = createSelector(getLoadBalancers, (balancer) => {
 return balancer?.totalCount || 0;
});

export const getLoadBalancerList = createSelector(getLoadBalancers, (loadBalancer) => {
 return loadBalancer?.dataset.map((balancer: ILoadBalancerDataset) => {
  return {
   id: balancer.id,
   expire: balancer.expire,
   algorithm: balancer.algorithm,
   location_name: balancer.location_name,
   name: balancer.name,
   service_status: balancer.service_status,
   protection_delete: balancer.protection_delete,
   location_city: balancer.location_city,
   private_net: balancer.private_net,
   public_net_ipv4_dns_ptr: balancer.public_net_ipv4_dns_ptr,
   public_net_ipv4_ip: balancer.public_net_ipv4_ip,
   public_net_ipv6_dns_ptr: balancer.public_net_ipv6_dns_ptr,
   public_net_ipv6_ip: balancer.public_net_ipv6_ip,
   location_country: balancer.location_country,
   userid: balancer.userid,
   personal_name: balancer.personal_name
  };
 });
});

export const getLoadBalancerDetails = createSelector(getLoadBalancerDataset, (loadBalancer) => {
 return loadBalancer;
});

export const getLoadBalancerServices = createSelector(getLoadBalancerDataset, (loadBalancer) => {
 return loadBalancer?.services || [];
});

export const getLoadBalancerTargets = createSelector(getLoadBalancerDataset, (loadBalancer) => {
 return loadBalancer?.targets || [];
});
