export const domainDnsRecord = ["A", "NS", "TXT", "CNAME", "MX"];
export const steps = [
 {
  label: "domain.add.selectName",
  description: "domain.add.selectNameMessage"
 },
 {
  label: "domain.add.verifyAuthinfo",
  description: "domain.add.verifyAuthinfoMessage"
 },
 {
  label: "domain.add.selectProvider",
  description: "domain.add.selectProviderMessage"
 },
 {
  label: "domain.add.selectYear",
  description: "domain.add.selectYear"
 },
 {
  label: "domain.add.selectClients",
  description: "domain.add.selectClientsMessage"
 },
 {
  label: "domain.add.selectHost",
  description: "domain.add.selectHostMessage"
 }
];

export const hosts: Array<{ label: string; type: "web" | "mail" | "ns" }> = [
 {
  label: "domain.webserver",
  type: "web"
 },
 {
  label: "domain.mailserver",
  type: "mail"
 },
 {
  label: "domain.nameserver",
  type: "ns"
 }
];

export const contacts: Array<{
 label: string;
 name: "registrant" | "admin" | "billing" | "technical";
}> = [
 {
  label: "domain.add.registrant",
  name: "registrant"
 },
 {
  label: "domain.add.admin",
  name: "admin"
 },
 {
  label: "domain.add.billing",
  name: "billing"
 },
 {
  label: "domain.add.technical",
  name: "technical"
 }
];

export const recap: Array<{
 label: string;
 name: "registrant" | "admin" | "billing" | "technical";
}> = [
 {
  label: "domain.registrantContact",
  name: "registrant"
 },
 {
  label: "domain.adminContact",
  name: "admin"
 },
 {
  label: "domain.billingContact",
  name: "billing"
 },
 {
  label: "domain.technicalContact",
  name: "technical"
 }
];
