import React, { ReactElement, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import useDebounce from "react-use/lib/useDebounce";

import SearchIcon from "@mui/icons-material/Search";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import EmptyList from "components/shared/emptyList/EmptyList";

import { getDockerNetworks } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IDockerNetworkData } from "types/api/dockerApiInterface";

const DockerNetworks = ({
 id,
 reloadData,
 resetReload
}: {
 id: number;
 reloadData: boolean;
 resetReload: () => void;
}): ReactElement => {
 const dispatch = useAppDispatch();

 const searchRef = useRef<HTMLInputElement>(null);

 const [network, setNetwork] = useState<Array<IDockerNetworkData>>([]);
 const [networkNumber, setNetworkNumber] = useState<number>(0);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [listWasEmpty, setListWasEmpty] = useState<boolean>(false);
 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);
 const [searchValue, setSearchValue] = useState<string>("");

 useEffect(() => {
  (async () => {
   const containerList = await dispatch(getDockerNetworks(id, 0, 10));
   if (containerList.dataset.length === 0) setListWasEmpty(true);
   setNetwork(containerList.dataset);
   setNetworkNumber(containerList.totalCount);
   setIsLoading(false);
  })();
 }, []);

 useEffect(() => {
  (async () => {
   if (reloadData) {
    const containerList = await dispatch(
     getDockerNetworks(id, currentPage, rowPerPage, searchRef?.current?.value)
    );
    setNetwork(containerList.dataset);
    setNetworkNumber(containerList.totalCount);
    resetReload();
   }
  })();
 }, [reloadData]);

 useDebounce(
  async () => {
   if (!isLoading) {
    setCurrentPage(0);
    const containerList = await dispatch(
     getDockerNetworks(id, currentPage, rowPerPage, searchRef?.current?.value)
    );
    setNetwork(containerList.dataset);
    setNetworkNumber(containerList.totalCount);
   }
  },
  1000,
  [searchValue]
 );

 const handleOnRowsPerPageChange = async (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const newRowsPerPage = parseInt(event.target.value, 10);
  setRowPerPage(newRowsPerPage);
  setCurrentPage(0);
  const containerList = await dispatch(
   getDockerNetworks(id, 0, newRowsPerPage, searchRef?.current?.value)
  );
  setNetwork(containerList.dataset);
  setNetworkNumber(containerList.totalCount);
 };

 const handleOnPageChange = async (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  const startIndex = newPage * rowPerPage;
  setCurrentPage(newPage);
  const containerList = await dispatch(
   getDockerNetworks(id, startIndex, rowPerPage, searchRef?.current?.value)
  );
  setNetwork(containerList.dataset);
  setNetworkNumber(containerList.totalCount);
 };

 return isLoading ? (
  <></>
 ) : network.length > 0 || !listWasEmpty ? (
  <Stack>
   <Stack direction="row" justifyContent="space-between" p={2}>
    <Stack direction="row" alignItems="center">
     <Typography fontWeight="bold" variant="caption">
      {networkNumber} <FormattedMessage id="docker.network.totalNetwork" />
     </Typography>
     <TextField
      size="small"
      autoComplete="new-password"
      label={<FormattedMessage id="docker.network.searchNetwork" />}
      inputRef={searchRef}
      sx={{ ml: 2 }}
      InputLabelProps={{ shrink: true }}
      InputProps={{ startAdornment: <SearchIcon color="disabled" />, autoComplete: "off" }}
      onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
     />
    </Stack>
    <Stack>add</Stack>
   </Stack>
   <Stack>
    <TableContainer component="div">
     <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
       <TableRow>
        <TableCell>
         <FormattedMessage id="docker.network.name" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="docker.network.driver" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="docker.network.attachable" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="docker.network.ipv4Subnet" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="docker.network.ipv4Gateway" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="docker.network.ipv6Subnet" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="docker.network.ipv6Gateway" />
        </TableCell>
        <TableCell align="right">
         <FormattedMessage id="app.actions" />
        </TableCell>
       </TableRow>
      </TableHead>
      <TableBody>
       {network.map((row, index) => (
        <TableRow key={`isp-config-list-${index}`}>
         <TableCell component="th" scope="row">
          <Typography variant="caption">{row.name}</Typography>
         </TableCell>
         <TableCell component="th" scope="row">
          <Typography variant="caption">{row.driver}</Typography>
         </TableCell>
         <TableCell component="th" scope="row">
          <Chip
           size="small"
           label={row.attachable ? "true" : "false"}
           color={row.attachable ? "success" : "error"}
          />
         </TableCell>
         <TableCell component="th" scope="row">
          <Typography variant="caption">{row.ipv4_ipam_subnet || "-"}</Typography>
         </TableCell>
         <TableCell component="th" scope="row">
          <Typography variant="caption">{row.ipv4_ipam_gateway || "-"}</Typography>
         </TableCell>
         <TableCell component="th" scope="row">
          <Typography variant="caption">{row.ipv6_ipam_subnet || "-"}</Typography>
         </TableCell>
         <TableCell component="th" scope="row">
          <Typography variant="caption">{row.ipv6_ipam_gateway || "-"}</Typography>
         </TableCell>
         <TableCell align="right">
          <Stack direction="row" justifyContent="flex-end">
           insert actions
          </Stack>
         </TableCell>
        </TableRow>
       ))}
      </TableBody>
     </Table>
    </TableContainer>
    <TablePagination
     component="div"
     count={networkNumber}
     page={currentPage}
     rowsPerPage={rowPerPage}
     onPageChange={handleOnPageChange}
     onRowsPerPageChange={handleOnRowsPerPageChange}
    />
   </Stack>
  </Stack>
 ) : (
  <Stack alignItems="center">
   <EmptyList />
  </Stack>
 );
};

export default DockerNetworks;
