import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import AppModal from "components/shared/modal/AppModal";

import { postRetryDomainDotitregcode } from "redux/handlers/domainsHandler";

import { useAppDispatch } from "hooks/reduxHook";

const RetryFiscalCode = ({ id, action }: { id: number; action: string }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [name, setName] = useState<string>("");
 const [lastname, setLastname] = useState<string>("");
 const [fiscalCode, setFiscalCode] = useState<string>("");
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => {
  resetData();
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);
 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(
   postRetryDomainDotitregcode(id, action, {
    firstname: name,
    lastname: lastname,
    regcode: fiscalCode
   })
  );
  setLoading(false);
 };

 const resetData = () => {
  setName("");
  setLastname("");
  setFiscalCode("");
 };

 const checkData = (): boolean => {
  return name === "" || lastname === "" || fiscalCode === "";
 };

 return (
  <>
   <Button variant="kxActionButton" onClick={handleOpen}>
    <FormattedMessage id="domain.retry" />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "domain.retry" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading || checkData()}>
    <Stack spacing={2}>
     <Typography>
      <FormattedMessage id="domain.error.confirmRetry" />
     </Typography>
     <TextField
      value={name}
      label={intl.formatMessage({ id: "profile.name" })}
      InputLabelProps={{ shrink: true }}
      onChange={(e) => setName(e.target.value)}
     />
     <TextField
      value={lastname}
      label={intl.formatMessage({ id: "profile.lastName" })}
      InputLabelProps={{ shrink: true }}
      onChange={(e) => setLastname(e.target.value)}
     />
     <TextField
      value={fiscalCode}
      label={intl.formatMessage({ id: "profile.fiscalCode" })}
      InputLabelProps={{ shrink: true }}
      onChange={(e) => setFiscalCode(e.target.value)}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default RetryFiscalCode;
