import React, { ReactElement, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import ContainersList from "../containers/ContainersList";
import DockerProxy from "../proxy/DockerProxy";

import ContainerWebsites from "./sections/ContainerWebsites";

const ContainerWebsitePage = (): ReactElement => {
 const intl = useIntl();
 const params = useParams<{ id: string; section: string }>();

 const [section, setSection] = useState<number>(0);
 const [reloadData, setReloadData] = useState<boolean>(true);

 const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
  setSection(newValue);
 };

 const RenderWebsiteSection = (): ReactElement => {
  switch (section) {
   case 0:
    return <ContainerWebsites id={parseInt(params?.id || "0")} />;
   case 1:
    return <DockerProxy id={parseInt(params?.id || "0")} />;
   case 2:
    return (
     <ContainersList
      id={parseInt(params?.id || "0")}
      reloadData={reloadData}
      resetReload={() => setReloadData(false)}
     />
    );
   default:
    return <></>;
  }
 };

 return (
  <Paper elevation={0} sx={{ mt: 3, pb: 0.7, borderRadius: "10px", boxShadow: 0 }}>
   <Tabs
    value={section}
    onChange={handleChangeTab}
    variant={"scrollable"}
    scrollButtons={true}
    allowScrollButtonsMobile>
    <Tab label={intl.formatMessage({ id: "docker.sites" })} />
    <Tab label={intl.formatMessage({ id: "docker.proxy" })} />
    <Tab label={intl.formatMessage({ id: "docker.containers" })} />
   </Tabs>
   <RenderWebsiteSection />
  </Paper>
 );
};

export default ContainerWebsitePage;
