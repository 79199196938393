import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const UnlockedIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <path
   d="M11.2857 9.07143V5.85714C11.2857 4.83416 11.6921 3.85309 12.4154 3.12973C13.1388 2.40638 14.1199 2 15.1429 2C16.1658 2 17.1469 2.40638 17.8703 3.12973C18.5936 3.85309 19 4.83416 19 5.85714V9.07143M2.92857 18.7143H11.9286C12.4401 18.7143 12.9306 18.5111 13.2923 18.1494C13.654 17.7877 13.8571 17.2972 13.8571 16.7857V11C13.8571 10.4885 13.654 9.99797 13.2923 9.63629C12.9306 9.27462 12.4401 9.07143 11.9286 9.07143H2.92857C2.41708 9.07143 1.92654 9.27462 1.56487 9.63629C1.20319 9.99797 1 10.4885 1 11V16.7857C1 17.2972 1.20319 17.7877 1.56487 18.1494C1.92654 18.5111 2.41708 18.7143 2.92857 18.7143Z"
   stroke="currentColor"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
 </svg>,
 "Unlocked"
);
