import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IDomainsOrdersApi } from "types/api/domainsApiInterface";

const initialState: IDomainsOrdersApi = { dataset: [], totalCount: 0 };

const DomainsOrdersReducer = createSlice({
 name: "domainsOrders",
 initialState,
 reducers: {
  setDomainsOrders: (state, { payload }: PayloadAction<IDomainsOrdersApi>) => {
   return payload;
  },
  resetDomainsOrders: () => {
   return initialState;
  }
 }
});

export default DomainsOrdersReducer;
