import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

import { IGatewayOrderData } from "types/api/gatewayApiInterface";

export const jobqueueApi = (state: AppState) => state.Gatewayorder;

export const getGatewayOrderNumber = createSelector(jobqueueApi, (job) => {
 return job?.totalCount || 0;
});

export const getGatewayOrderList = createSelector(jobqueueApi, (job) => {
 return job?.dataset.map((jobData: IGatewayOrderData) => ({
  action: jobData.action,
  status: jobData.status,
  id: jobData.id,
  params: jobData.params,
  response: jobData.response,
  createdAt: jobData.createdAt
 }));
});
