import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { IServiceApi } from "types/api/servicesApiInterface";

const initialState: IServiceApi = { services: [], count: 0 };

const ServicesReducer = createSlice({
 name: "services",
 initialState,
 reducers: {
  setServices: (state, { payload }: PayloadAction<IServiceApi>) => {
   return payload;
  },
  resetServices: () => {
   return initialState;
  }
 }
});

export default ServicesReducer;
