import React, { ReactElement } from "react";
import { useSelector } from "react-redux";

import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

import { SxProps } from "@mui/material";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";

import { getIsAdmin } from "redux/selectors/userSelector";

type Props = {
 children: ReactElement;
 forceRender?: boolean;
 showIcon?: boolean;
 sx?: SxProps;
};

const AdminWrapper = ({
 children,
 forceRender,
 showIcon = true,
 sx
}: Props): ReactElement | null => {
 const isAdmin = useSelector(getIsAdmin);

 return isAdmin || forceRender ? (
  <Stack direction="row" alignItems="center" sx={{ ...sx }}>
   {isAdmin && showIcon && (
    <Tooltip title="ADMIN" placement="top">
     <AdminPanelSettingsIcon color="warning" />
    </Tooltip>
   )}
   {children}
  </Stack>
 ) : null;
};

export default AdminWrapper;
