import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postFetchIso, postMountIso, postUnmountIso } from "redux/handlers/serverHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IIso } from "types/redux/serverInterface";

const ServerIso = ({ id, iso_id }: { id: number; iso_id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(true);
 const [isoList, setIsoList] = useState<Array<IIso>>([]);
 const [loadingIso, setLoadingIso] = useState<number>(0);

 const fetchIso = async () => {
  setLoading(true);
  setIsoList(await dispatch(postFetchIso(id)));
  setLoading(false);
 };

 const handleOpen = () => {
  setOpen(true);
  fetchIso();
 };
 const handleClose = () => setOpen(false);

 const handleMountIso = async (selected_iso_id: number) => {
  setLoadingIso(selected_iso_id);
  await dispatch(postMountIso(id, selected_iso_id));
 };

 const handleUnmountIso = async (selected_iso_id: number) => {
  setLoadingIso(selected_iso_id);
  await dispatch(postUnmountIso(id));
 };

 useEffect(() => {
  setLoadingIso(0);
 }, [iso_id]);

 return (
  <>
   <Tooltip title={intl.formatMessage({ id: "server.iso" })}>
    <IconButton color="primary" onClick={handleOpen}>
     <IconSelector icon="IsoIcon" props={{ fontSize: "large" }} />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    handleClose={handleClose}
    showConfirmButton={false}
    handleConfirm={handleClose}
    title={intl.formatMessage({ id: "server.manage.changeIso" })}>
    <Stack spacing={2} sx={{ maxHeight: 400 }}>
     <Alert severity="info">
      <FormattedMessage id={"server.iso.isoMessage"} />
     </Alert>
     {loading ? (
      <Stack direction="row" spacing={2}>
       <CircularProgress size={20} />
       <Typography>
        <FormattedMessage id="server.iso.loadingIso" />
       </Typography>
      </Stack>
     ) : (
      <TableContainer component="div">
       <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
         <TableRow>
          <TableCell>
           <FormattedMessage id="server.iso.name" />
          </TableCell>
          <TableCell>
           <FormattedMessage id="server.iso.type" />
          </TableCell>
          <TableCell>
           <FormattedMessage id="server.iso.architecture" />
          </TableCell>
          <TableCell>
           <FormattedMessage id="server.iso.actions" />
          </TableCell>
         </TableRow>
        </TableHead>
        <TableBody>
         {isoList.map((element, index) => {
          return (
           <TableRow key={`iso-table-${index}`}>
            <TableCell>{element.type === "private" ? element.description : element.name}</TableCell>
            <TableCell>{element.type}</TableCell>
            <TableCell>{element.architecture}</TableCell>
            {loadingIso === element.id ? (
             <TableCell>
              <CircularProgress />
             </TableCell>
            ) : (
             <TableCell>
              {element.id === iso_id ? (
               <Button variant="kxActionButton" onClick={() => handleUnmountIso(element.id)}>
                Smonta
               </Button>
              ) : (
               <Button variant="kxActionButton" onClick={() => handleMountIso(element.id)}>
                Monta
               </Button>
              )}
             </TableCell>
            )}
           </TableRow>
          );
         })}
        </TableBody>
       </Table>
      </TableContainer>
     )}
    </Stack>
   </AppModal>
  </>
 );
};

export default ServerIso;
