import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import useIntl from "react-intl/src/components/useIntl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postDeleteMailbox } from "redux/handlers/mailsHandler";

import { getMailsList } from "redux/selectors/mailsSelector";

import { useAppDispatch } from "hooks/reduxHook";

const DeleteMailbox = ({ id }: { id: number }) => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const params = useParams<{ mail_id: string; section: string }>();
 const mailDetails = useSelector(getMailsList).find(
  (element) => element.id === Number(params.mail_id)
 );

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setIsLoading(true);
  await dispatch(postDeleteMailbox(id, mailDetails?.isp_id || 0, mailDetails?.domain || ""));
  setIsLoading(false);
  handleClose();
 };

 return (
  <>
   <Stack>
    <Tooltip title={<FormattedMessage id="mails.delete.mailbox" />}>
     <Button variant="kxActionButton" onClick={handleOpen}>
      <IconSelector icon="DeleteIcon" />
     </Button>
    </Tooltip>
   </Stack>
   <AppModal
    open={openModal}
    close={handleClose}
    loading={isLoading}
    title={intl.formatMessage({ id: "mails.mailboxOperations" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}>
    <Typography>
     <FormattedMessage id="mails.deleteMailboxMessage" />
    </Typography>
   </AppModal>
  </>
 );
};

export default DeleteMailbox;
