import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const StatusIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <path
   d="M1.42852 10.2865C1.4283 8.90724 1.79118 7.55223 2.48067 6.35767C3.17016 5.16312 4.16196 4.17112 5.35638 3.4814C6.5508 2.79168 7.90574 2.42854 9.285 2.42848C10.6643 2.42843 12.0192 2.79146 13.2137 3.48109C13.3777 3.57431 13.5718 3.5989 13.7539 3.54948C13.9359 3.50006 14.091 3.38066 14.1853 3.21732C14.2796 3.05397 14.3055 2.85996 14.2572 2.67762C14.209 2.49527 14.0907 2.3394 13.928 2.24401C12.1578 1.22203 10.1 0.812673 8.07348 1.07943C6.047 1.34619 4.16517 2.27415 2.71982 3.7194C1.27448 5.16465 0.346396 7.04643 0.0795085 9.07289C-0.187379 11.0993 0.221842 13.1572 1.24371 14.9274C2.26557 16.6976 3.84298 18.0812 5.73129 18.8635C7.6196 19.6459 9.71329 19.7833 11.6877 19.2544C13.662 18.7256 15.4067 17.5601 16.6512 15.9387C17.8957 14.3172 18.5703 12.3305 18.5706 10.2865C18.5706 10.0971 18.4953 9.91539 18.3614 9.78144C18.2274 9.6475 18.0458 9.57224 17.8563 9.57224C17.6669 9.57224 17.4852 9.6475 17.3513 9.78144C17.2173 9.91539 17.1421 10.0971 17.1421 10.2865C17.1421 12.3702 16.3143 14.3687 14.8409 15.8421C13.3675 17.3155 11.3691 18.1433 9.2853 18.1433C7.20156 18.1433 5.20315 17.3155 3.72972 15.8421C2.25629 14.3687 1.42852 12.3702 1.42852 10.2865Z"
   fill="currentColor"
  />
  <path
   d="M19.7905 3.64968C19.8569 3.58327 19.9096 3.50443 19.9456 3.41767C19.9815 3.3309 20 3.2379 20 3.14399C20 3.05007 19.9815 2.95708 19.9456 2.87031C19.9096 2.78354 19.8569 2.70471 19.7905 2.6383C19.7241 2.57189 19.6453 2.51921 19.5585 2.48327C19.4718 2.44733 19.3788 2.42883 19.2848 2.42883C19.1909 2.42883 19.0979 2.44733 19.0112 2.48327C18.9244 2.51921 18.8456 2.57189 18.7792 2.6383L9.2853 12.1336L5.50547 8.35232C5.43906 8.28591 5.36023 8.23324 5.27346 8.1973C5.18669 8.16136 5.0937 8.14286 4.99978 8.14286C4.90587 8.14286 4.81287 8.16136 4.7261 8.1973C4.63934 8.23324 4.5605 8.28591 4.49409 8.35232C4.42768 8.41873 4.375 8.49757 4.33906 8.58434C4.30312 8.6711 4.28463 8.7641 4.28463 8.85801C4.28463 8.95193 4.30312 9.04493 4.33906 9.13169C4.375 9.21846 4.42768 9.2973 4.49409 9.36371L8.77961 13.6492C8.84596 13.7157 8.92478 13.7685 9.01155 13.8045C9.09833 13.8405 9.19135 13.8591 9.2853 13.8591C9.37925 13.8591 9.47228 13.8405 9.55905 13.8045C9.64582 13.7685 9.72464 13.7157 9.79099 13.6492L19.7905 3.64968Z"
   fill="currentColor"
  />
 </svg>,
 "Status"
);
