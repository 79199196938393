import React, { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { getAllMessages } from "redux/handlers/chatHandler";

import { getUserIdString } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { ChatMessage } from "types/api/chatApiInterface";

import UserReply from "./actions/UserReply";

const UserVisual = ({ open }: { open: boolean }): ReactElement => {
 const dispatch = useAppDispatch();
 const messagesEndRef = useRef<HTMLDivElement>(null);

 const userId = useSelector(getUserIdString);
 const { socket } = useContext(AppContext);

 const [allMessages, setAllMessages] = useState<Array<ChatMessage>>([]);
 const [toReloadData, setToReloadData] = useState<boolean>(true);

 useEffect(() => {
  (async () => {
   if (toReloadData) {
    setAllMessages((await dispatch(getAllMessages(userId))).allMessages);
    setToReloadData(false);
   }
  })();
 }, [userId, toReloadData]);

 useEffect(() => {
  !isNil(socket) &&
   socket.on("chat_ai", (value): void => {
    if (value?.completed && value?.section === "firewall") {
     setToReloadData(true);
    }
   });
  return () => {
   !isNil(socket) && socket.off("chat_ai");
  };
 }, [socket]);

 const scrollToBottom = () => {
  messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
 };

 useEffect(() => {
  scrollToBottom();
 }, [allMessages, open]);

 const reloadData = async () => {
  setAllMessages((await dispatch(getAllMessages(userId))).allMessages);
 };

 return (
  <>
   <Stack height={500} p={2} spacing={1} sx={{ overflowY: "scroll" }}>
    {allMessages.map((element, index) => {
     return (
      <Stack
       key={`chat-message-${index}`}
       alignItems={element.role === "GPT" || element.role === "Admin" ? "flex-start" : "flex-end"}>
       <Stack
        sx={{
         backgroundColor:
          element.role === "GPT" || element.role === "Admin" ? "#D4E6F1" : "#7FB3D5",
         borderRadius: 4
        }}
        px={2}
        py={1}>
        <Typography maxWidth={350} textAlign={"start"} sx={{ wordBreak: "break-word" }}>
         {element.text}
        </Typography>
       </Stack>
      </Stack>
     );
    })}
    <div ref={messagesEndRef} />
   </Stack>
   <Divider />
   <UserReply reloadData={reloadData} />
  </>
 );
};

export default UserVisual;
