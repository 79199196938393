import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";

import CancelIcon from "@mui/icons-material/Cancel";

import Autocomplete, { AutocompleteRenderInputParams } from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { domainDnsRecord } from "constants/domains";

import IconSelector from "components/shared/images/IconSelector";

import { addRecords } from "redux/handlers/zoneHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { Record } from "types/api/appsApiInterface";

const AddRecord = ({
 zoneId,
 ispId,
 domain,
 resetIndex,
 updateData
}: {
 zoneId: number;
 ispId: number;
 domain: string;
 resetIndex: () => void;
 updateData: () => void;
}): ReactElement => {
 const dispatch = useAppDispatch();
 const defaultRecord: Record = {
  aux: 0,
  data: "",
  id: -1,
  isp_id: ispId,
  name: "test",
  status: "active",
  ttl: 3600,
  type: "A",
  zone_id: zoneId
 };

 const [updateRow, setUpdateRow] = useState<Record>(defaultRecord);
 const [loading, setLoading] = useState<boolean>(false);

 const handleUpdateRecord = (type: string, value: string | number): void => {
  if (type === "type" && value !== "A") {
   setUpdateRow({ ...updateRow, name: domain, type: value.toString() });
  } else {
   setUpdateRow({ ...updateRow, [type]: value });
  }
 };

 const handleSaveRecord = async () => {
  setLoading(true);
  await dispatch(addRecords(updateRow));
  setLoading(false);
  updateData();
 };

 return (
  <>
   <TableCell component="th" scope="row" align="left">
    <Autocomplete
     size="small"
     value={updateRow.type}
     options={domainDnsRecord}
     autoSelect
     onChange={(event, value) => {
      if (!value) return;
      handleUpdateRecord("type", value);
     }}
     clearIcon={null}
     renderInput={(params: AutocompleteRenderInputParams) => {
      return (
       <TextField
        {...params}
        label="Type"
        InputLabelProps={{ shrink: true }}
        type="text"
        sx={{ minWidth: 150 }}
       />
      );
     }}
    />
   </TableCell>
   <TableCell component="th" scope="row" align="center">
    <TextField
     label="Nome"
     size="small"
     value={updateRow.name}
     onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
      e.stopPropagation();
     }}
     InputLabelProps={{ shrink: true }}
     sx={{ minWidth: 150 }}
     onChange={(event) => {
      handleUpdateRecord("name", event.target.value);
     }}
    />
   </TableCell>
   <TableCell component="th" scope="row" align="center">
    <TextField
     label="Data"
     value={updateRow.data}
     size="small"
     multiline={updateRow.type === "TXT"}
     InputLabelProps={{ shrink: true }}
     sx={{ minWidth: 150 }}
     onChange={(event) => {
      handleUpdateRecord("data", event.target.value);
     }}
    />
   </TableCell>
   <TableCell component="th" scope="row" align="center">
    <TextField
     label="Aux"
     value={updateRow.aux}
     type="number"
     size="small"
     InputLabelProps={{ shrink: true }}
     sx={{ minWidth: 60 }}
     onChange={(event) => {
      handleUpdateRecord("aux", Number(event.target.value));
     }}
    />
   </TableCell>
   <TableCell component="th" scope="row" align="center">
    <TextField
     label="TTL"
     value={updateRow.ttl}
     type="number"
     size="small"
     InputLabelProps={{ shrink: true }}
     sx={{ minWidth: 80 }}
     onChange={(event) => {
      handleUpdateRecord("ttl", Number(event.target.value));
     }}
    />
   </TableCell>
   <TableCell component="th" scope="row" align="center" width="10%">
    <Stack direction="row" justifyContent="center">
     {!loading ? (
      <Stack justifyContent="center">
       <Typography variant="subtitle2" color="error">
        <FormattedMessage id="domains.record.update" />
       </Typography>
       <Stack direction="row" justifyContent="center">
        <IconButton onClick={handleSaveRecord}>
         <IconSelector icon="StatusIcon" props={{ style: { color: "green" }, fontSize: "small" }} />
        </IconButton>
        <IconButton onClick={resetIndex}>
         <CancelIcon color="action" />
        </IconButton>
       </Stack>
      </Stack>
     ) : (
      <CircularProgress />
     )}
    </Stack>
   </TableCell>
  </>
 );
};

export default AddRecord;
