import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import notificationReducer from "redux/reducers/notificationReducer";

import { IBackupData, IServerFromBackupData } from "types/api/backupApiInterface";

import { ApiService } from "service/ApiService";

export const getAllBackups = (): AppAction<Promise<void>> => async (dispatch) => {
 try {
  await ApiService("/backup/allbackup");
 } catch (error) {
  console.warn("FAILED TO GET BACKUP DATA");
 }
};

export const getServerBackups =
 (server_id: number): AppAction<Promise<Array<IBackupData>>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<Array<IBackupData>>("/backup/serverbackup", "POST", {
    server_id: server_id
   });
   return data;
  } catch (error: any) {
   console.warn("FAILED TO GET BACKUP DATA");
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get backup for server - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const postDisableBackup =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/server/disablebackup", "POST", {
    id: id
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Disabling backup for server",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to disable backup for server - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postEnableBackup =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/server/enablebackup", "POST", {
    id: id
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Enable backup for server",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to enable backup for server - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCreateBackup =
 (id: number, description?: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/backup/addbackup", "POST", {
    id: id,
    description: description
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Creating backup for server",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create backup for server - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteBackup =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/backup/deletebackup", "POST", {
    id: id
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Deleting backup for server",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete backup for server - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postConvertBackup =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/backup/converttosnapshot", "POST", {
    id: id
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Conversion backup to snapshot",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to convert backup for server - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postRebuildBackup =
 (id: number, server_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/backup/rebuildserver", "POST", {
    id: server_id,
    backup_id_db: id
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Conversion backup to snapshot",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to rebuild server from backup - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getServerBackupsType =
 (id: number): AppAction<Promise<IServerFromBackupData | null>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IServerFromBackupData>("/backup/datafromserver", "POST", {
    id: id
   });
   return data;
  } catch (error) {
   console.warn("FAILED TO GET BACKUP DATA");
   return null;
  }
 };

export const postCreateServerFromBackup =
 (
  datacenter_location_name: string,
  image_id: number,
  name: string,
  server_type_name: string,
  type: string,
  provider: string,
  amount: number,
  from_snapshot: boolean
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/server/addserverfrombackup", "POST", {
    datacenter_location_name: datacenter_location_name,
    image_id: image_id,
    name: name,
    server_type_name: server_type_name,
    type: type,
    provider: provider,
    amount: amount,
    from_snapshot: from_snapshot
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Server from backup in creation",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create server from backuo - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };
