import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

import { IBaremetalDataset } from "types/api/baremetalApiInterface";

export const getBaremetals = (state: AppState) => state.baremetal;

export const getBaremetalNumber = createSelector(getBaremetals, (baremetal) => {
 return baremetal?.totalCount || 0;
});

export const getBaremetalConfigurator = createSelector(getBaremetals, (baremetal) => {
 return baremetal?.dataset.map((baremetal: IBaremetalDataset) => {
  return {
   id: baremetal.id,
   name: baremetal.server_name,
   status: baremetal.status,
   userid: baremetal.userid,
   server_id: baremetal.server_number,
   dim_disk: baremetal.dim_disk || 0,
   dim_ram: baremetal.dim_ram || 0,
   disk_type: baremetal.disk_type || "Not available",
   amount: baremetal.amount || 0,
   product: baremetal.product || ""
  };
 });
});

export const getBaremetalDataset = createSelector(getBaremetals, (baremetal) => {
 return (
  baremetal?.dataset.map((baremetal: IBaremetalDataset) => {
   return {
    id: baremetal.id,
    product: baremetal.product,
    server_ip: baremetal.server_ip,
    server_ipv6_net: baremetal.server_ipv6_net,
    server_name: baremetal.server_name,
    server_number: baremetal.server_number,
    status: baremetal.status,
    userid: baremetal.userid || "",
    expire: baremetal.expire,
    autorenew: baremetal.autorenew,
    service_status: baremetal.service_status,
    owner: baremetal.personal_name || "",
    price: baremetal.amount || "0",
    dim_disk: baremetal.dim_disk || 0,
    dim_ram: baremetal.dim_ram || 0,
    disk_type: baremetal.disk_type || "Not available"
   };
  }) || []
 );
});
