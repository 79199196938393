import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Link from "components/shared/link/Link";

import { postChangeLanguage } from "redux/handlers/userHandler";

import { getUserAuthenticated } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

const externalLink = [
 {
  path: `${process.env.PUBLIC_URL}/documents/DocumentoGenerale_OnTheCloud.pdf`,
  text: "footer.privacyPolicy"
 },
 {
  path: `${process.env.PUBLIC_URL}/documents/Additional_TLD.pdf`,
  text: "footer.tldConditions"
 }
];

const AppFooter = (): ReactElement => {
 const dispatch = useAppDispatch();

 const isAuthenticated: boolean = useSelector(getUserAuthenticated);

 const handleChangeLanguage = async (language: string): Promise<void> => {
  if (isAuthenticated) await dispatch(postChangeLanguage(language));
  else localStorage.setItem("language", language);
 };

 return (
  <Box
   sx={
    isAuthenticated
     ? { py: 2, backgroundColor: "#fff", mt: 2, boxShadow: "0 3px 6px #00000029" }
     : {}
   }>
   <Stack
    direction={isAuthenticated ? "row" : "column"}
    justifyContent={"center"}
    alignItems={"center"}>
    <Stack
     direction={"row"}
     spacing={2}
     sx={isAuthenticated ? { marginRight: "40px" } : { marginBottom: "40px" }}>
     <Stack
      sx={{ cursor: "pointer" }}
      component="span"
      className={"fi fi-it"}
      onClick={() => handleChangeLanguage("it")}
     />
     <Stack
      sx={{ cursor: "pointer" }}
      component="span"
      className={"fi fi-gb"}
      onClick={() => handleChangeLanguage("en")}
     />
    </Stack>
    <Stack>
     <Typography sx={{ color: "#A3A3A3", fontSize: "x-small" }}>
      © 2024 On The Cloud S.R.L. - IT12550480961 | Powered by On The Cloud S.R.L. | On The Cloud
      S.R.L. All Rights Reserved.
     </Typography>
     <Stack direction="row" spacing={2} justifyContent="center">
      {externalLink.map((link, index) => (
       <Typography key={`footer-link-${index}`} sx={{ fontWeight: "bold", fontSize: "x-small" }}>
        <Link linkTo={link.path} externalLink={true}>
         {<FormattedMessage id={link.text} />}
        </Link>
       </Typography>
      ))}
     </Stack>
    </Stack>
   </Stack>
  </Box>
 );
};

export default AppFooter;
