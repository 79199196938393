import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";

import { IProviders } from "types/api/serversApiInterface";

const ProviderBox = ({
 providers,
 selectProvider,
 selectedProvider
}: {
 providers: Array<IProviders>;
 selectProvider: (id: number) => void;
 selectedProvider: number;
}): ReactElement => {
 const searchProvider = (provId: number): string => {
  switch (provId) {
   case 1:
    return `${process.env.PUBLIC_URL}/svg/providers/konsolex.svg`;
   case 2:
    return `${process.env.PUBLIC_URL}/svg/providers/contabo.svg`;
   case 3:
    return `${process.env.PUBLIC_URL}/svg/providers/ovh.svg`;
   default:
    return "";
  }
 };

 return (
  <Stack direction="row" spacing={2}>
   {providers.map((element, index) => {
    return selectedProvider === element.id ? (
     <Paper
      sx={{
       backgroundColor: "#c8e6c9",
       px: 4,
       py: 2
      }}
      onClick={() => selectProvider(element.id)}>
      <Stack direction="row" alignItems="center" justifyContent="flex-end" minHeight={24}>
       {element.id !== 2 && (
        <IconSelector
         icon="AllInclusiveIcon"
         props={{
          color: element.id === 2 ? "error" : "success",
          sx: { position: "relative", top: -10, right: -20 }
         }}
        />
       )}
      </Stack>
      <img src={searchProvider(element.id)} alt="provider-logo" height={40} />
      <Stack direction="row" justifyContent="center" alignItems="center" mt={1}>
       <Typography fontWeight="bold" textTransform="uppercase">
        {element.name}
       </Typography>
      </Stack>
     </Paper>
    ) : (
     <Tooltip
      key={`provider-paper-${index}`}
      title={<FormattedMessage id="server.add.selectProviderTitle" />}>
      <Paper
       sx={{
        px: 4,
        py: 2,
        cursor: "pointer"
       }}
       onClick={() => selectProvider(element.id)}>
       <Stack direction="row" alignItems="center" justifyContent="flex-end" minHeight={24}>
        {element.id !== 2 && (
         <IconSelector
          icon="AllInclusiveIcon"
          props={{
           color: element.id === 2 ? "error" : "success",
           sx: { position: "relative", top: -10, right: -20 }
          }}
         />
        )}
       </Stack>
       <img src={searchProvider(element.id)} alt="provider-logo" height={40} />
       <Stack direction="row" justifyContent="center" alignItems="center" mt={1}>
        <Typography fontWeight="bold" textTransform="uppercase">
         {element.name}
        </Typography>
       </Stack>
      </Paper>
     </Tooltip>
    );
   })}
  </Stack>
 );
};

export default ProviderBox;
