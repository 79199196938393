import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const LogsIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <path
   d="M15.4091 13.3846V11.6538C15.4091 10.8276 15.0858 10.0352 14.5104 9.45094C13.935 8.86669 13.1546 8.53846 12.3409 8.53846H10.9773C10.706 8.53846 10.4459 8.42905 10.2541 8.2343C10.0623 8.03956 9.95455 7.77542 9.95455 7.5V6.11539C9.95455 5.70627 9.87518 5.30116 9.72099 4.92318C9.5668 4.5452 9.3408 4.20177 9.0559 3.91248C8.77099 3.62319 8.43275 3.39371 8.06051 3.23715C7.68826 3.08058 7.28928 3 6.88636 3H5.86364M6 7H9M6.02273 9H9.09091M6 11H13.3409M6 13H13.3409M6 15H13.3409M11.3182 16.8462H14.3864C14.9509 16.8462 15.4091 16.3809 15.4091 15.8077V11.3077C15.4091 7.19077 12.4609 3.77446 8.59091 3.11446C8.14028 3.03797 7.68416 2.99969 7.22727 3H5.52273C4.95818 3 4.5 3.46523 4.5 4.03846V7.26831V15.8077C4.5 16.0831 4.60775 16.3472 4.79955 16.542C4.99135 16.7367 5.25148 16.8462 5.52273 16.8462H11.3182Z"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
 </svg>,
 "Logs"
);
