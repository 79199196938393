import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const DiscIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <circle cx="10" cy="10" r="6.75" stroke="currentColor" strokeWidth="1" />
  <path
   d="M15 10C15 12.7614 12.7614 15 10 15M5 10C5 7.23858 7.23858 5 10 5"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
  />
  <circle cx="10" cy="10" r="2" fill="#848484" />
 </svg>,
 "Disc"
);
