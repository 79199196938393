import React, { ReactElement } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { Link as MuiLink, SxProps } from "@mui/material";

type Props = {
 children: ReactElement;
 linkTo: string;
 externalLink?: boolean;
 sx?: SxProps;
};

const Link = ({ children, sx, linkTo, externalLink }: Props): ReactElement => {
 const location = useLocation();

 return (
  <MuiLink
   underline="none"
   component="button"
   sx={{
    textDecoration: "none",
    color: location.pathname.slice(1).includes(linkTo.slice(1)) ? "black" : "#3b3838",
    "&:hover": {
     color: "#c97b22"
    },
    ...sx
   }}>
   <NavLink
    to={linkTo}
    style={{ textDecoration: "none", color: "inherit" }}
    target={externalLink ? "_blank" : "_self"}>
    {children}
   </NavLink>
  </MuiLink>
 );
};

export default Link;
