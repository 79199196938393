/* eslint-disable react/style-prop-object */
import React, { ReactElement, useEffect, useState } from "react";
import { FormattedDate, FormattedMessage, FormattedNumber } from "react-intl";
import { useSelector } from "react-redux";

import Alert from "@mui/material/Alert";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import IconSelector from "components/shared/images/IconSelector";
import SkeletonTable from "components/shared/skeletons/SkeletonTable";

import { getServerBackups } from "redux/handlers/backupsHandler";

import { getServerDetails } from "redux/selectors/serversSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IBackupData } from "types/api/backupApiInterface";

import AddBackup from "./backupActions/AddBackup";
import BackupActions from "./backupActions/BackupActions";
import DisableBackup from "./backupActions/DisableBackup";
import EnableBackup from "./backupActions/EnableBackup";

const ServerBackups = ({
 dataToUpgrade,
 resetData
}: {
 dataToUpgrade: boolean;
 resetData: () => void;
}): ReactElement => {
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const { server_id } = useSelector(getServerDetails);
 const { id } = useSelector(getServerDetails);
 const { backup_enabled } = useSelector(getServerDetails);

 const [loading, setLoading] = useState<boolean>(true);
 const [backupList, setBackupList] = useState<Array<IBackupData>>([]);

 useEffect(() => {
  (async () => {
   setLoading(true);
   setBackupList(await dispatch(getServerBackups(server_id)));
   setLoading(false);
  })();
 }, [server_id]);

 useEffect(() => {
  (async () => {
   if (dataToUpgrade) {
    setBackupList(await dispatch(getServerBackups(server_id)));
    resetData();
   }
  })();
 }, [dataToUpgrade, server_id]);

 const SkeletonBackups = () => {
  return (
   <Paper elevation={0} sx={{ mt: 2, borderRadius: "10px", boxShadow: 0 }}>
    <Stack p={2}>
     <Stack direction="row" spacing={1}>
      <IconSelector icon="BackupIcon" props={{ fontSize: "medium", color: "secondary" }} />
      <Typography fontWeight="bold" fontSize={16} textTransform="uppercase">
       <FormattedMessage id="server.backups" />
      </Typography>
     </Stack>
     <Skeleton variant="rectangular" width="100%" height={60} />
     <SkeletonTable numberOfRows={8} tableHeight={60}></SkeletonTable>
    </Stack>
   </Paper>
  );
 };

 return (
  <Stack>
   {loading ? (
    <SkeletonBackups />
   ) : (
    <Paper elevation={0} sx={{ mt: 2, borderRadius: "10px", boxShadow: 0 }}>
     <Stack p={2}>
      <Stack
       justifyContent="space-between"
       alignItems={desktopViewPort ? "flex-end" : "none"}
       direction={desktopViewPort ? "row" : "column"}>
       <Stack direction="row" spacing={1}>
        <IconSelector icon="BackupIcon" props={{ fontSize: "medium", color: "secondary" }} />
        <Typography fontWeight="bold" fontSize={16} textTransform="uppercase">
         <FormattedMessage id="server.backups" />
        </Typography>
       </Stack>
       {backup_enabled ? (
        <Stack direction={desktopViewPort ? "row" : "column"} spacing={2}>
         <AddBackup
          id={id || 0}
          reloadData={async () => setBackupList(await dispatch(getServerBackups(server_id)))}
         />
         <DisableBackup id={id || 0} />
        </Stack>
       ) : (
        <EnableBackup id={id || 0} />
       )}
      </Stack>
      <Stack p={1}>
       <Alert color="info" variant="outlined">
        {backup_enabled ? (
         <FormattedMessage id="server.backups.enabledMessage" />
        ) : (
         <FormattedMessage id="server.backups.disabledMessage" />
        )}
       </Alert>
      </Stack>
      <Divider />
      <Stack>
       {backup_enabled && backupList && (
        <TableContainer component="div">
         <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
           <TableRow>
            <TableCell>
             <FormattedMessage id="server.backups.status" />
            </TableCell>
            <TableCell>
             <FormattedMessage id="server.backups.name" />
            </TableCell>
            <TableCell>
             <FormattedMessage id="server.backups.size" />
            </TableCell>
            <TableCell>
             <FormattedMessage id="server.backups.creation" />
            </TableCell>
            <TableCell>
             <FormattedMessage id="server.backups.actions" />
            </TableCell>
           </TableRow>
          </TableHead>
          <TableBody>
           {backupList.map((row, index) =>
            row.status === "creating" ? (
             <TableRow key={`backup-list-${index}`}>
              <TableCell component="th" scope="row">
               <Stack direction="column" justifyContent="center">
                <CircularProgress size={20} />
               </Stack>
              </TableCell>
              <TableCell component="th" scope="row">
               <Typography variant="inherit" fontWeight="bold">
                {row.description}
               </Typography>
              </TableCell>
              <TableCell></TableCell>
              <TableCell>
               <FormattedDate value={row.created} />
              </TableCell>
              <TableCell></TableCell>
             </TableRow>
            ) : (
             <TableRow key={`backup-list-${index}`}>
              <TableCell component="th" scope="row">
               <Stack direction="column">
                <Chip
                 size="small"
                 label={
                  row.status === "available" ? (
                   <FormattedMessage id="server.backups.on" />
                  ) : (
                   <FormattedMessage id="server.backups.off" />
                  )
                 }
                 color={row.status === "available" ? "success" : "error"}
                />
               </Stack>
              </TableCell>
              <TableCell component="th" scope="row">
               <Typography variant="inherit" fontWeight="bold">
                {row.description}
               </Typography>
              </TableCell>
              <TableCell>
               <FormattedNumber value={Number(row.image_size)} style="unit" unit="gigabyte" />
              </TableCell>
              <TableCell>
               <FormattedDate value={row.created} />
              </TableCell>
              <TableCell>
               <BackupActions
                id={row.id}
                reloadData={async () => setBackupList(await dispatch(getServerBackups(server_id)))}
               />
              </TableCell>
             </TableRow>
            )
           )}
          </TableBody>
         </Table>
        </TableContainer>
       )}
      </Stack>
     </Stack>
    </Paper>
   )}
  </Stack>
 );
};

export default ServerBackups;
