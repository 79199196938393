import React, { ReactElement, useState } from "react";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";

import IconSelector from "components/shared/images/IconSelector";

import { IGatewayApi } from "types/api/gatewayApiInterface";
import { IUsersList } from "types/api/userApiInterface";

import DeleteGateway from "./actions/DeleteGateway";
import EditGateway from "./actions/EditGateway";

const GatewayMenu = ({
 contacts,
 servers,
 projectData,
 id,
 reloadData
}: {
 contacts: Array<IUsersList>;
 servers: Array<{ id: number; server_name: string }>;
 projectData: IGatewayApi;
 id: number;
 reloadData?: () => void;
}): ReactElement => {
 const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
 const open = Boolean(anchorEl);

 const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => setAnchorEl(null);

 return (
  <>
   <Button onClick={handleOpen}>
    <IconSelector icon="MenuIcon" />
   </Button>
   <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
     "aria-labelledby": "basic-button"
    }}>
    <Stack>
     <EditGateway
      contacts={contacts}
      servers={servers}
      projectData={projectData}
      id={id}
      reloadData={reloadData}
      closeMenu={handleClose}
     />
     <DeleteGateway id={id} reloadData={reloadData} closeMenu={handleClose} />
    </Stack>
   </Menu>
  </>
 );
};

export default GatewayMenu;
