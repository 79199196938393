import React, { ReactElement, useEffect, useState } from "react";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

import Credits from "components/profile/movements/Credits";
import SkeletonProfile from "components/shared/skeletons/SkeletonProfile";

import { getAllCredits } from "redux/handlers/creditsHandler";

import { useAppDispatch } from "hooks/reduxHook";

const CreditsPage = (): ReactElement => {
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const dispatch = useAppDispatch();

 useEffect(() => {
  (async () => {
   await dispatch(getAllCredits(0, 10, "", ""));
   setIsLoading(false);
  })();
 }, []);
 return (
  <Container maxWidth="xl">
   <Stack>{isLoading ? <SkeletonProfile id="app.credits" /> : <Credits />}</Stack>
  </Container>
 );
};

export default CreditsPage;
