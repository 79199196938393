import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { ISnapshotsApi } from "types/api/addonsApiInterface";

const initialState: ISnapshotsApi = { dataset: [], totalCount: 0 };

const SnapshotsReducer = createSlice({
 name: "snapshot",
 initialState,
 reducers: {
  setSnapshots: (state, { payload }: PayloadAction<ISnapshotsApi>) => {
   return payload;
  },
  resetSnapshots: () => {
   return initialState;
  }
 }
});

export default SnapshotsReducer;
