import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import AppModal from "components/shared/modal/AppModal";

import { postRectifyWebmail } from "redux/handlers/serverHandler";

import { getServerName } from "redux/selectors/serversSelector";

const RectifyWebmail = ({ service }: { service: string }): ReactElement => {
 const intl = useIntl();
 const serverName = useSelector(getServerName);

 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [openModal, setOpenModal] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const { control, handleSubmit, formState, getValues } = useForm({
  defaultValues: {
   webmail: ""
  }
 });

 const onSubmit: SubmitHandler<{ webmail: string }> = async (data: { webmail: string }) => {
  setIsLoading(true);
  postRectifyWebmail(data.webmail);
  setIsLoading(false);
  setOpenModal(false);
 };

 const verifyWebmail = () => {
  const serverDomain = serverName?.substring(serverName?.indexOf("."));
  return serverDomain && getValues("webmail").includes(serverDomain);
 };

 return (
  <>
   <Button variant="kxActionButton" onClick={handleOpen}>
    {intl.formatMessage({ id: "server.service.correct" })} {service}
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    handleConfirm={handleSubmit(onSubmit)}
    title={intl.formatMessage({ id: "server.service.rectifyService" })}>
    <form onSubmit={handleSubmit(onSubmit)}>
     <Stack>
      <Controller
       name="webmail"
       control={control}
       rules={{
        required: true,
        validate: verifyWebmail
       }}
       render={({ field }) => (
        <TextField
         {...field}
         label={intl.formatMessage({ id: "server.service.webmail" })}
         error={formState.isDirty && !!formState?.errors?.webmail}
         InputLabelProps={{ shrink: true }}
         helperText={
          formState.isDirty &&
          !!formState?.errors?.webmail &&
          intl.formatMessage({ id: "server.service.wrongWebmail" })
         }
        />
       )}
      />
     </Stack>
    </form>
   </AppModal>
  </>
 );
};

export default RectifyWebmail;
