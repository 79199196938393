import React, { ReactElement, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import useDebounce from "react-use/lib/useDebounce";

import SearchIcon from "@mui/icons-material/Search";

import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { fetchProjectsList } from "redux/handlers/hetznerHandler";
import { getUsersList } from "redux/handlers/userHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IHetznerApi } from "types/api/hetznerApi";
import { IUsersList } from "types/api/userApiInterface";

import AddProject from "./actions/AddProject";
import HetznerMenu from "./HetznerMenu";

const Hetzner = (): ReactElement => {
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const searchRef = useRef<HTMLInputElement>(null);

 const [loading, setLoading] = useState(true);
 const [contactsList, setContactsList] = useState<Array<IUsersList>>([]);
 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);
 const [searchValue, setSearchValue] = useState<string>("");
 const [hetznerList, setHetznerList] = useState<IHetznerApi>({ dataset: [], totalCount: 0 });

 useEffect(() => {
  (async () => {
   setLoading(true);
   setContactsList(await dispatch(getUsersList()));
   setHetznerList(await dispatch(fetchProjectsList(0, 10)));
   setLoading(false);
  })();
 }, []);

 useDebounce(
  async () => {
   if (!loading) {
    setCurrentPage(0);
    setHetznerList(
     await dispatch(fetchProjectsList(currentPage, rowPerPage, searchRef?.current?.value))
    );
   }
  },
  1000,
  [searchValue]
 );

 const handleOnRowsPerPageChange = async (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const newRowsPerPage = parseInt(event.target.value, 10);
  setRowPerPage(newRowsPerPage);
  setCurrentPage(0);
  setHetznerList(await dispatch(fetchProjectsList(0, newRowsPerPage, searchRef?.current?.value)));
 };

 const handleOnPageChange = async (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  const startIndex = newPage * rowPerPage;
  setCurrentPage(newPage);
  setHetznerList(
   await dispatch(fetchProjectsList(startIndex, rowPerPage, searchRef?.current?.value))
  );
 };

 return !loading ? (
  <Stack spacing={2}>
   <Stack justifyContent="space-between" direction="row">
    <Stack direction={desktopViewPort ? "row" : "column"} spacing={2} alignItems="center">
     <Typography>
      {hetznerList.totalCount} <FormattedMessage id="configurator.hetzner.total" />
     </Typography>
     <TextField
      size="small"
      autoComplete="new-password"
      label={<FormattedMessage id="configurator.search.hetznerProjects" />}
      inputRef={searchRef}
      sx={{ ml: 4 }}
      InputLabelProps={{ shrink: true }}
      InputProps={{ startAdornment: <SearchIcon color="disabled" />, autoComplete: "off" }}
      onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
     />
    </Stack>
    <Stack justifyContent={desktopViewPort ? "flex-start" : "center"}>
     <AddProject
      contacts={contactsList}
      reloadData={async () => setHetznerList(await dispatch(fetchProjectsList(0, 10)))}
     />
    </Stack>
   </Stack>
   <Stack>
    <TableContainer component="div">
     <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
       <TableRow>
        <TableCell>
         <FormattedMessage id="configurator.hetzner.partner" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="configurator.hetzner.name" />
        </TableCell>
        <TableCell align="center">
         <FormattedMessage id="configurator.hetzner.actions" />
        </TableCell>
       </TableRow>
      </TableHead>
      <TableBody>
       {hetznerList.dataset.map((row, index) => (
        <TableRow key={`isp-config-list-${index}`}>
         <TableCell component="th" scope="row">
          <Typography variant="subtitle2" fontWeight="bold">
           {`${contactsList.find((element) => element.userid === row.userid)
            ?.firstname} ${contactsList.find((element) => element.userid === row.userid)
            ?.lastname}`}
          </Typography>
         </TableCell>
         <TableCell component="th" scope="row">
          <Typography variant="subtitle2">{row.name}</Typography>
         </TableCell>
         <TableCell align="center">
          <HetznerMenu
           contacts={contactsList}
           projectData={row}
           id={row.id}
           reloadData={async () => setHetznerList(await dispatch(fetchProjectsList(0, 10)))}
          />
         </TableCell>
        </TableRow>
       ))}
      </TableBody>
     </Table>
    </TableContainer>
    <TablePagination
     component="div"
     count={hetznerList.totalCount || 0}
     page={currentPage}
     rowsPerPage={rowPerPage}
     onPageChange={handleOnPageChange}
     onRowsPerPageChange={handleOnRowsPerPageChange}
    />
   </Stack>
  </Stack>
 ) : (
  <></>
 );
};

export default Hetzner;
