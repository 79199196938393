import React, { ReactElement, useState } from "react";
import { NavLink } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";

import { headerLinks } from "constants/staticLinks";

import IconSelector from "components/shared/images/IconSelector";

const MobileSideMenu = (): ReactElement => {
 const [open, setOpen] = useState<boolean>(false);

 const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
  if (
   event.type === "keydown" &&
   ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
  ) {
   return;
  }

  setOpen(open);
 };

 const list = () => (
  <Box
   sx={{ width: 250 }}
   role="presentation"
   onClick={toggleDrawer(false)}
   onKeyDown={toggleDrawer(false)}>
   <List>
    {headerLinks.map((link, index) => (
     <ListItem key={`side-menu-link-${index}`} disablePadding>
      <NavLink
       to={link.url}
       style={{
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        color: "#3b3838",
        width: "100%"
       }}>
       <ListItemButton>
        <ListItemIcon>{<IconSelector icon={link?.icon} />}</ListItemIcon>
        <ListItemText primary={link.name} />
       </ListItemButton>
      </NavLink>
     </ListItem>
    ))}
   </List>
  </Box>
 );

 return (
  <Stack>
   <IconButton
    size="large"
    edge="start"
    color="inherit"
    aria-label="menu"
    sx={{ mr: 2 }}
    onClick={toggleDrawer(true)}>
    <MenuIcon />
   </IconButton>
   <Drawer open={open} onClose={toggleDrawer(false)}>
    {list()}
   </Drawer>
  </Stack>
 );
};

export default MobileSideMenu;
