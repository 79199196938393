import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import notificationReducer from "redux/reducers/notificationReducer";

import { IHetznerApi, ILocationAPI } from "types/api/hetznerApi";

import { ApiService } from "service/ApiService";

export const fetchProjectsList =
 (currentIndex: number, sizePerPage: number, q?: string): AppAction<Promise<IHetznerApi>> =>
 async (dispatch): Promise<IHetznerApi> => {
  try {
   const { data } = await ApiService<IHetznerApi>(
    `/hetznerproject/allprojects?page=${currentIndex}&limit=${sizePerPage}&q=${q || ""}`
   );
   return data;
  } catch (error) {
   console.error("Error getting projects list:", error);
   return { dataset: [], totalCount: 0 };
  }
 };

export const postAddProject =
 (name: string, apiKey: string, contact_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/hetznerproject/addproject", "POST", {
    name: name,
    apiKey: apiKey,
    contact_id: contact_id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "New project created",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create project - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateProject =
 (id: number, name: string, apiKey: string, contact_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/hetznerproject/updateproject", "POST", {
    id: id,
    name: name,
    apiKey: apiKey,
    contact_id: contact_id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Project updated",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update project - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteProject =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/hetznerproject/deleteproject", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Project deleted",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete project - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const fetchLocationsList =
 (): AppAction<Promise<Array<ILocationAPI>>> =>
 async (dispatch): Promise<Array<ILocationAPI>> => {
  try {
   const { data } = await ApiService<Array<ILocationAPI>>("/location/alllocations", "POST", {
    provider_id: 1
   });
   return data;
  } catch (error) {
   console.error("Error getting locations list:", error);
   return [];
  }
 };
