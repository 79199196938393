import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import ServersOrders from "components/servers/ServersOrders";
import IconSelector from "components/shared/images/IconSelector";

import { getAllServerOrders } from "redux/handlers/serverHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { OrdersAPI } from "types/api/serversApiInterface";

const ServerBox = (): ReactElement => {
 const intl = useIntl();
 const navigate = useNavigate();
 const dispatch = useAppDispatch();

 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [currentList, setCurrentList] = useState<OrdersAPI>({ dataset: [], totalCount: 0 });

 useEffect(() => {
  (async () => {
   setCurrentList(await dispatch(getAllServerOrders(0, 10)));
   setIsLoading(false);
  })();
 }, []);

 const handleGoTo = () => {
  navigate("/servers");
 };

 return (
  <Paper elevation={0} sx={{ mt: 3, p: 2, borderRadius: "10px", boxShadow: 0 }}>
   <Stack direction="row" pb={1} justifyContent="space-between">
    <Stack direction="row">
     <Typography fontWeight="bold" variant="h5" textTransform="uppercase">
      <FormattedMessage id="app.domainsOrders" />
     </Typography>
     <Typography fontWeight="bold" variant="h5" textTransform="uppercase">
      {`(${intl.formatMessage({ id: "app.servers" })})`}
     </Typography>
    </Stack>
    <Tooltip title={intl.formatMessage({ id: "app.goToSection" })} placement="top">
     <IconButton onClick={handleGoTo}>
      <IconSelector icon="ArrowOutwardIcon" props={{ fontSize: "small" }} />
     </IconButton>
    </Tooltip>
   </Stack>
   {isLoading ? (
    <Stack>
     <CircularProgress />
    </Stack>
   ) : currentList.dataset.length > 0 ? (
    <ServersOrders />
   ) : (
    <Alert severity="info">
     <Typography>
      <FormattedMessage id="dashboard.orderNotFound" />
     </Typography>
    </Alert>
   )}
  </Paper>
 );
};

export default ServerBox;
