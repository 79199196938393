import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import PasswordIcon from "@mui/icons-material/Password";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import { passwordValidation } from "constants/regexp";

import AppModal from "components/shared/modal/AppModal";

import { postEditMailbox } from "redux/handlers/mailsHandler";
import { generatePassword } from "redux/handlers/userHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IMailbox } from "types/api/mailApiInterface";

const ChangePassword = ({
 mailboxData,
 mailDomain
}: {
 mailboxData: IMailbox;
 mailDomain: string;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [showPassword, setShowPassword] = useState<string>("password");

 const handleOpen = () => {
  reset();
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const { control, handleSubmit, watch, formState, reset, setValue } = useForm({
  defaultValues: {
   password: mailboxData.password
  }
 });

 const onSubmit: SubmitHandler<{ password: string }> = async (data: { password: string }) => {
  setIsLoading(true);
  await dispatch(
   postEditMailbox(
    mailboxData.id,
    mailboxData.isp_id,
    mailboxData.email,
    mailboxData.name,
    data.password,
    mailboxData.quota,
    mailboxData.disabledeliver,
    mailboxData.disableimap,
    mailboxData.disablesmtp,
    mailboxData.cc,
    mailDomain
   )
  );
  setIsLoading(false);
  setOpenModal(false);
 };

 const handleGeneratePassword = async () => {
  setValue("password", await generatePassword());
 };

 return (
  <>
   <Stack>
    <Tooltip title={<FormattedMessage id="mails.edit.password" />}>
     <Button variant="kxActionButton" onClick={handleOpen}>
      <PasswordIcon />
     </Button>
    </Tooltip>
   </Stack>
   <AppModal
    open={openModal}
    close={handleClose}
    loading={isLoading}
    title={intl.formatMessage({ id: "mails.mailboxChangePassword" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={watch("password") === ""}>
    <Stack direction="row" spacing={2}>
     <Controller
      name="password"
      control={control}
      rules={{
       required: true,
       minLength: 8,
       pattern: passwordValidation
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "mails.mailboxPassword" })}
        error={formState.isDirty && !!formState?.errors?.password}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        type={showPassword}
        autoComplete="new-password"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.password &&
         intl.formatMessage({ id: "mails.mailboxPasswordError" })
        }
        InputProps={{
         endAdornment: (
          <InputAdornment position="end">
           <IconButton
            aria-label="toggle password visibility"
            onClick={() => {
             setShowPassword(showPassword === "text" ? "password" : "text");
            }}
            edge="end">
            {showPassword ? <VisibilityOff /> : <Visibility />}
           </IconButton>
          </InputAdornment>
         )
        }}
       />
      )}
     />
     <Button variant="kxActionButton" onClick={handleGeneratePassword} sx={{ width: "40%" }}>
      <FormattedMessage id="app.generatePassword" />
     </Button>
    </Stack>
   </AppModal>
  </>
 );
};

export default ChangePassword;
