import React, { ChangeEvent, ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { getMe, postConfirmContract } from "redux/handlers/userHandler";

import { useAppDispatch } from "hooks/reduxHook";

const AcceptContract = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(true);
 const [contractSeen, setContractSeen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleConfirmContract = async () => {
  setLoading(true);
  await dispatch(postConfirmContract());
  await dispatch(getMe());
  setLoading(false);
  setOpen(false);
 };

 return (
  <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
   <Drawer variant="persistent" anchor="bottom" open={open}>
    <Stack>
     <Typography variant="h5" textAlign="left" sx={{ pl: 2 }}>
      <FormattedMessage id="app.updatedContract" />
     </Typography>
    </Stack>
    <Divider sx={{ borderColor: "#EF711A" }} />
    <Stack>
     <Typography textAlign="left" sx={{ pl: 2, pt: 2 }}>
      <FormattedMessage id="app.contractMessage1" />{" "}
      <Link
       rel="noopener"
       target="_blank"
       href={`${process.env.PUBLIC_URL}/documents/Contratto_Onthecloud.pdf`}>
       <FormattedMessage id="app.userContract" />
      </Link>
      <FormattedMessage id="app.contractMessage2" />
     </Typography>
    </Stack>
    <Stack direction="row" justifyContent="space-between" p={2}>
     <FormGroup>
      <FormControlLabel
       control={
        <Checkbox
         checked={contractSeen}
         onChange={(event: ChangeEvent<HTMLInputElement>) => setContractSeen(event.target.checked)}
        />
       }
       label={intl.formatMessage({ id: "app.iHaveSeen" })}
      />
     </FormGroup>
     <Button
      variant="kxActionButton"
      disabled={!contractSeen || loading}
      onClick={handleConfirmContract}>
      <FormattedMessage id="app.accept" />
     </Button>
    </Stack>
   </Drawer>
  </Backdrop>
 );
};

export default AcceptContract;
