import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { useMediaQuery, useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { locationList } from "constants/locations";

import { getLoadBalancerDetails } from "redux/selectors/loadBalancerSelector";

const LoadBalancerUpperPart = (): ReactElement => {
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const balancerData = useSelector(getLoadBalancerDetails);

 const RenderLocation = (location: string) => {
  const filteredLocation = locationList.filter((data) => location === data.tag);

  return filteredLocation.map((element, index) => (
   <Stack key={`location-item-${index}`} direction="row" spacing={1}>
    <Stack component="span" className={`fi fi-${element.flag}`} />
    <Typography variant="caption">
     <FormattedMessage id={element.location} />
    </Typography>
   </Stack>
  ));
 };

 return (
  <Stack
   mt={desktopViewPort ? 5 : 0}
   justifyContent="space-between"
   direction={desktopViewPort ? "row" : "column"}>
   <Stack>
    <Typography
     fontWeight="bold"
     fontSize={16}
     textAlign="left"
     textTransform="uppercase">{`${balancerData?.name} (${balancerData?.load_balancer_type_name})`}</Typography>
    {RenderLocation(balancerData?.location_name || "")}
   </Stack>
  </Stack>
 );
};

export default LoadBalancerUpperPart;
