import React, { ReactElement, useEffect, useState } from "react";

import Stack from "@mui/material/Stack";

import SkeletonAddons from "components/shared/skeletons/SkeletonAddons";
import ZonesList from "components/zone/ZoneList";

import { getAllIspNotPaged } from "redux/handlers/websitesHandler";
import { getAllZone } from "redux/handlers/zoneHandler";

import { useAppDispatch } from "hooks/reduxHook";

const ZonePage = (): ReactElement => {
 const dispatch = useAppDispatch();

 const [isLoading, setIsLoading] = useState<boolean>(true);

 useEffect(() => {
  (async () => {
   await dispatch(getAllIspNotPaged("nameserver"));
   await dispatch(getAllZone(0, 10));
   setIsLoading(false);
  })();
 }, []);

 return <Stack>{isLoading ? <SkeletonAddons id="app.zone" /> : <ZonesList />}</Stack>;
};

export default ZonePage;
