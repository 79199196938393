import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const KeyIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <path
   d="M13.0769 4.4573C13.7298 4.4573 14.3559 4.71664 14.8175 5.17827C15.2791 5.63989 15.5385 6.266 15.5385 6.91884M18 6.91884C18.0001 7.63688 17.8431 8.34625 17.5401 8.99722C17.2371 9.64819 16.7953 10.225 16.2458 10.6872C15.6963 11.1494 15.0523 11.4858 14.3591 11.6728C13.6658 11.8598 12.94 11.8929 12.2326 11.7697C11.7707 11.6901 11.2816 11.791 10.9502 12.1225L8.76923 14.3035H6.92308V16.1496H5.07692V17.9958H2V15.6836C2 15.1937 2.19446 14.7236 2.54072 14.3781L7.87323 9.04561C8.20472 8.71412 8.30564 8.22509 8.22605 7.76314C8.1097 7.09128 8.13418 6.40257 8.29795 5.74067C8.46172 5.07876 8.7612 4.45809 9.17742 3.918C9.59363 3.3779 10.1175 2.93015 10.7158 2.60313C11.3142 2.27611 11.9739 2.07695 12.6532 2.01827C13.3326 1.95958 14.0167 2.04266 14.6622 2.26223C15.3078 2.4818 15.9007 2.83308 16.4033 3.2938C16.906 3.75452 17.3075 4.31463 17.5823 4.93865C17.8572 5.56266 17.9994 6.23697 18 6.91884Z"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
 </svg>,
 "Key"
);
