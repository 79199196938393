import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

import { IServiceDataset } from "types/api/servicesApiInterface";

export const getServices = (state: AppState) => state.services;

export const getServicesNumber = createSelector(getServices, (services) => {
 return services?.count;
});

export const getServicesList = createSelector(getServices, (services) => {
 return (
  services?.services?.map((service: IServiceDataset) => {
   return {
    id: service.id,
    userid: service.userid,
    details: service.details,
    type: service.type,
    payment_type: service.payment_type,
    last_renew: service.last_renew,
    expire: service.expire,
    status: service.status,
    price: service.amount,
    name: service.name,
    autorenew: service.autorenew || false,
    domain_id_db: service.domain_id_db,
    automigration: service.automigration || null,
    personal_name: service.personal_name
   };
  }) || []
 );
});
