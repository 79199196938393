import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postAddNetwork } from "redux/handlers/gatewayHandler";

import { useAppDispatch } from "hooks/reduxHook";

const AddNetwork = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [networkName, setNetworkName] = useState<string>("");

 const handleOpen = () => {
  setNetworkName("");
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setIsLoading(true);
  await dispatch(postAddNetwork(id, networkName));
  handleClose();
  setIsLoading(false);
 };

 return (
  <Stack direction="row">
   <Button onClick={handleOpen} variant="kxActionButton">
    <FormattedMessage id="gateway.addNetwork" />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    disabled={networkName === ""}
    handleConfirm={handleConfirm}
    title={intl.formatMessage({ id: "gateway.addNetwork" })}>
    <Stack spacing={2}>
     <Alert severity="info">
      <Typography>
       <FormattedMessage id="gateway.addNetwork.domainMessage" />
      </Typography>
     </Alert>
     <TextField
      autoComplete="off"
      label={intl.formatMessage({ id: "gateway.addNetwork.domainName" })}
      InputLabelProps={{ shrink: true }}
      onChange={({ currentTarget }) => setNetworkName(currentTarget.value)}
     />
    </Stack>
   </AppModal>
  </Stack>
 );
};

export default AddNetwork;
