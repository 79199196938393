import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IDnsData } from "types/api/dnsApiInterface";

const initialState: IDnsData[] = [];

const DnsReducer = createSlice({
 name: "Dns",
 initialState,
 reducers: {
  setDns: (state, { payload }: PayloadAction<IDnsData[]>) => {
   return payload;
  },
  resetDns: () => {
   return initialState;
  }
 }
});

export default DnsReducer;
