import React, { ReactElement } from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { postLogAsClient } from "redux/handlers/userHandler";

import { useAppDispatch } from "hooks/reduxHook";

import AdminWrapper from "./AdminWrapper";

const AdminLogAsUser = ({ userid, owner }: { userid: string; owner: string }): ReactElement => {
 const dispatch = useAppDispatch();

 const logAsUser = async () => {
  await dispatch(postLogAsClient(userid));
  window.location.reload();
 };

 return (
  <Stack onClick={logAsUser}>
   <AdminWrapper>
    <Typography variant="small" sx={{ color: "#ff9800" }}>
     {owner}
    </Typography>
   </AdminWrapper>
  </Stack>
 );
};

export default AdminLogAsUser;
