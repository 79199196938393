import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import AddIcon from "@mui/icons-material/Add";

import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";

import { hostTypeList } from "constants/server";

import AppModal from "components/shared/modal/AppModal";

import { postAddHost } from "redux/handlers/hostHandler";

import { useAppDispatch } from "hooks/reduxHook";

type hostData = {
 type: string;
 name: string;
 data: string;
 ip: string;
 visible: boolean;
};

const AddHost = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [checked, setChecked] = useState<boolean>(false);

 const { control, handleSubmit, formState, setValue, reset } = useForm({
  defaultValues: {
   type: "",
   name: "",
   data: "",
   ip: "",
   visible: false
  }
 });

 const onSubmit: SubmitHandler<hostData> = async (data: hostData) => {
  setIsLoading(true);
  await dispatch(postAddHost(data.type, data.name, data.data, data.ip, data.visible));
  setIsLoading(false);
  setOpenModal(false);
 };

 const handleOpen = () => {
  reset();
  setChecked(false);
  setOpenModal(true);
 };

 const handleClose = () => {
  setOpenModal(false);
 };

 return (
  <>
   <Button onClick={handleOpen} variant="kxActionButton" endIcon={<AddIcon />}>
    <FormattedMessage id="host.action.add" />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    handleConfirm={handleSubmit(onSubmit)}
    title={intl.formatMessage({ id: "host.action.add" })}>
    <form onSubmit={handleSubmit(onSubmit)}>
     <Controller
      name="type"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) => {
         setValue("type", value?.type || "");
        }}
        options={hostTypeList}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          InputLabelProps={{ shrink: true }}
          label={intl.formatMessage({ id: "host.hostType" })}
         />
        )}
       />
      )}
     />
     <Controller
      name="name"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "host.name" })}
        error={formState.isDirty && !!formState?.errors?.name}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.name &&
         intl.formatMessage({ id: "host.nameError" })
        }
       />
      )}
     />
     <Controller
      name="data"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "host.data" })}
        error={formState.isDirty && !!formState?.errors?.data}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.data &&
         intl.formatMessage({ id: "host.dataError" })
        }
       />
      )}
     />
     <Controller
      name="ip"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "host.ip" })}
        error={formState.isDirty && !!formState?.errors?.ip}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         formState.isDirty && !!formState?.errors?.ip && intl.formatMessage({ id: "host.ipError" })
        }
       />
      )}
     />
     <Controller
      name="name"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <FormControlLabel
        control={
         <Checkbox
          checked={checked}
          onChange={(e) => {
           setValue("visible", e.target.checked);
           setChecked(e.target.checked);
          }}
         />
        }
        label={intl.formatMessage({ id: "host.hostVisible" })}
       />
      )}
     />
    </form>
   </AppModal>
  </>
 );
};

export default AddHost;
