/* eslint-disable react/style-prop-object */
import React, { ReactElement, useState } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";

import Chip from "@mui/material/Chip";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postResizeVolume } from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

const ResizeVolume = ({
 id,
 size,
 closeMenu
}: {
 id: number;
 size: number;
 closeMenu: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [volumeSize, setVolumeSize] = useState<number>(size + 1);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postResizeVolume(id, volumeSize));
  handleClose();
  setLoading(false);
  closeMenu();
 };

 const handleSliderChange = (event: Event, newValue: number | number[]) => {
  setVolumeSize(newValue as number);
 };

 const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setVolumeSize(event.target.value === "" ? size : Number(event.target.value));
 };

 const handleBlur = () => {
  if (volumeSize <= size) {
   setVolumeSize(size + 1);
  } else if (volumeSize > 1000) {
   setVolumeSize(1000);
  }
 };

 return (
  <>
   <MenuItem onClick={handleOpen}>
    <FormattedMessage id="volumes.resizeVolume" />
   </MenuItem>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "volumes.resizeVolume" })}
    handleClose={handleClose}
    disabled={loading}
    handleConfirm={handleConfirm}>
    <Stack>
     <Stack>
      <Stack>
       <Typography>
        <FormattedMessage id="addons.volumes.sizeInGb" />
       </Typography>
      </Stack>
      <Stack direction="row" spacing={2} p={2}>
       <Slider
        value={volumeSize || size}
        onChange={handleSliderChange}
        onChangeCommitted={(e, value) => setVolumeSize(typeof value === "number" ? value : size)}
        aria-labelledby="input-slider"
        min={size + 1}
        max={1000}
       />
       <Input
        value={volumeSize}
        size="small"
        onChange={handleInputChange}
        onBlur={handleBlur}
        inputProps={{
         step: 1,
         min: size,
         max: 1000,
         type: "number",
         "aria-labelledby": "input-slider"
        }}
       />
      </Stack>
     </Stack>
     <Stack direction="row" alignItems="center" spacing={1}>
      <Typography>
       <FormattedMessage id="addons.volumes.monthlyCost" />
      </Typography>
      <Chip
       label={<FormattedNumber style="currency" currency="EUR" value={(volumeSize / 10) * 1.2} />}
      />
     </Stack>
    </Stack>
   </AppModal>
  </>
 );
};

export default ResizeVolume;
