/* eslint-disable react/style-prop-object */
import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { formatMegaBytes } from "helpers/numberFormatting";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { getCloudboxPricing, postUpgradeCloudbox } from "redux/handlers/addonsHandle";

import { getCloudboxDataset } from "redux/selectors/cloudboxSelector";
import { getUserBalance } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { ICloudboxPrice } from "types/api/addonsApiInterface";

const CloudboxUpgrade = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const navigate = useNavigate();
 const params = useParams<{ id: string; section: string }>();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const userBalance = useSelector(getUserBalance);
 const cloudboxData = useSelector(getCloudboxDataset).find(
  (element) => element.id === Number(params.id)
 );

 const [open, setOpen] = useState<boolean>(false);
 const [cloudList, setCloudList] = useState<Array<ICloudboxPrice>>([]);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [selectedType, setSelectedType] = useState<string>("");
 const [loading, setLoading] = useState<boolean>(false);

 useEffect(() => {
  (async () => {
   setCloudList(
    (await dispatch(getCloudboxPricing())).filter(
     (element) => element.disk_quota > formatMegaBytes(cloudboxData?.disk_quota || 0, "TB")
    )
   );
   setIsLoading(false);
  })();
 }, []);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postUpgradeCloudbox(cloudboxData?.id || 0, selectedType));
  handleClose();
  setLoading(false);
  navigate("/addons/cloudbox");
 };

 const selectImage = (product: string): string => {
  switch (product) {
   case "BX21":
    return `${process.env.PUBLIC_URL}/images/smallCloudbox.jpg`;
   case "BX31":
    return `${process.env.PUBLIC_URL}/images/mediumCloudbox.jpg`;
   case "BX41":
    return `${process.env.PUBLIC_URL}/images/bigCloudbox.jpg`;
   default:
    return `${process.env.PUBLIC_URL}/images/smallCloudbox.jpg`;
  }
 };

 const RenderText = ({ product }: { product: string }): ReactElement => {
  switch (product) {
   case "BX21":
    return (
     <>
      <Typography textAlign="left" variant="body2" color="text.secondary">
       <FormattedMessage id="addons.cloudbox.protocolsSupported" />
      </Typography>
      <Typography textAlign="left" variant="body2" color="text.secondary">
       10 <FormattedMessage id="addons.cloudbox.connections" />
      </Typography>
      <Typography textAlign="left" variant="body2" color="text.secondary">
       100 <FormattedMessage id="addons.cloudbox.subusers" />
      </Typography>
      <Typography textAlign="left" variant="body2" color="text.secondary">
       20 snapshot
      </Typography>
      <Typography textAlign="left" variant="body2" color="text.secondary">
       20 <FormattedMessage id="addons.cloudbox.automaticSnapshots" />
      </Typography>
     </>
    );
   case "BX31":
    return (
     <>
      <Typography textAlign="left" variant="body2" color="text.secondary">
       <FormattedMessage id="addons.cloudbox.protocolsSupported" />
      </Typography>
      <Typography textAlign="left" variant="body2" color="text.secondary">
       10 <FormattedMessage id="addons.cloudbox.connections" />
      </Typography>
      <Typography textAlign="left" variant="body2" color="text.secondary">
       100 <FormattedMessage id="addons.cloudbox.subusers" />
      </Typography>
      <Typography textAlign="left" variant="body2" color="text.secondary">
       30 snapshot
      </Typography>
      <Typography textAlign="left" variant="body2" color="text.secondary">
       30 <FormattedMessage id="addons.cloudbox.automaticSnapshots" />
      </Typography>
     </>
    );
   case "BX41":
    return (
     <>
      <Typography textAlign="left" variant="body2" color="text.secondary">
       <FormattedMessage id="addons.cloudbox.protocolsSupported" />
      </Typography>
      <Typography textAlign="left" variant="body2" color="text.secondary">
       10 <FormattedMessage id="addons.cloudbox.connections" />
      </Typography>
      <Typography textAlign="left" variant="body2" color="text.secondary">
       100 <FormattedMessage id="addons.cloudbox.subusers" />
      </Typography>
      <Typography textAlign="left" variant="body2" color="text.secondary">
       40 snapshot
      </Typography>
      <Typography textAlign="left" variant="body2" color="text.secondary">
       40 <FormattedMessage id="addons.cloudbox.automaticSnapshots" />
      </Typography>
     </>
    );
   default:
    return (
     <>
      <Typography textAlign="left" variant="body2" color="text.secondary">
       <FormattedMessage id="addons.cloudbox.protocolsSupported" />
      </Typography>
      <Typography textAlign="left" variant="body2" color="text.secondary">
       10 <FormattedMessage id="addons.cloudbox.connections" />
      </Typography>
      <Typography textAlign="left" variant="body2" color="text.secondary">
       100 <FormattedMessage id="addons.cloudbox.subusers" />
      </Typography>
      <Typography textAlign="left" variant="body2" color="text.secondary">
       10 snapshot
      </Typography>
      <Typography textAlign="left" variant="body2" color="text.secondary">
       10 <FormattedMessage id="addons.cloudbox.automaticSnapshots" />
      </Typography>
     </>
    );
  }
 };

 return !isLoading ? (
  <Paper elevation={0} sx={{ mt: 1, borderRadius: "10px", boxShadow: 0 }}>
   <Stack direction="row" justifyContent="space-between" mb={1} pt={1} px={2}>
    <Stack alignItems="center" justifyContent="flex-start" direction="row">
     <IconSelector icon="KeyIcon" props={{ fontSize: "medium", color: "secondary" }} />
     <Typography variant="h6" component="h2" ml={1}>
      <FormattedMessage id="addons.cloudbox.upgrade" />
     </Typography>
    </Stack>
   </Stack>

   <Stack spacing={2} padding={2}>
    <Stack direction={desktopViewPort ? "row" : "column"} spacing={1} justifyContent="center">
     {cloudList.length > 0 ? (
      cloudList.map((element, index) => {
       return (
        <Card
         key={`cloud-upgrade-${index}`}
         sx={{
          minWidth: cloudList.length === 4 ? 200 : 275,
          backgroundColor: selectedType === element.product ? "orange" : "white"
         }}>
         <CardMedia
          component="img"
          height="140"
          image={selectImage(element.product)}
          alt="cloudbox"
         />
         <CardContent>
          <Typography textAlign="left" gutterBottom variant="h5" component="div">
           {element.product}
          </Typography>
          <Typography textAlign="left" variant="body2" color="text.secondary">
           <FormattedMessage
            id="addons.cloudbox.upgradeSize"
            values={{ size: element.disk_quota }}
           />
          </Typography>
          <RenderText product={element.product} />
         </CardContent>
         <CardActions>
          <Button
           variant="kxActionButton"
           size="small"
           onClick={() => setSelectedType(element.product)}>
           <FormattedMessage id="app.select" />
          </Button>
         </CardActions>
        </Card>
       );
      })
     ) : (
      <Alert></Alert>
     )}
    </Stack>
    <Stack direction="row">
     <Button variant="kxActionButton" disabled={selectedType === ""} onClick={() => handleOpen()}>
      <FormattedMessage id="addons.cloudbox.upgradeCloudbox" />
     </Button>
     <AppModal
      open={open}
      close={handleClose}
      title={intl.formatMessage({ id: "addons.cloudbox.upgradeCloudbox" })}
      handleClose={handleClose}
      disabled={loading}
      handleConfirm={handleConfirm}>
      <Stack>
       <FormattedMessage id="addons.cloudbox.upgradeCloudboxMessage" />
       <Stack direction="row" spacing={2}>
        <Typography minWidth={150} textAlign="start" fontWeight="bold">
         <FormattedMessage id="server.add.balance" />
        </Typography>
        <Typography>
         <FormattedNumber value={userBalance} style={"currency"} currency="EUR" />
        </Typography>
       </Stack>
       <Stack direction="row" spacing={2} alignItems="center">
        <Typography minWidth={150} textAlign="start" fontWeight="bold">
         <FormattedMessage id="server.add.totalPrice" />
        </Typography>
        <Typography>
         <FormattedNumber
          value={
           selectedType !== ""
            ? cloudList.find((element) => element.product === selectedType)?.amount || 0
            : 0
          }
          style={"currency"}
          currency="EUR"
         />
        </Typography>
       </Stack>
      </Stack>
     </AppModal>
    </Stack>
   </Stack>
  </Paper>
 ) : (
  <Paper elevation={0} sx={{ mt: 1, borderRadius: "10px", boxShadow: 0, p: 2 }}>
   <CircularProgress />
  </Paper>
 );
};

export default CloudboxUpgrade;
