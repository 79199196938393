/* eslint-disable react/style-prop-object */
import React, { ReactElement, useState } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postDomainRestorePrice, postRestoreDomain } from "redux/handlers/domainsHandler";

import { getUserBalance } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

const RestoreDomain = ({ domain_id }: { domain_id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const userBalance = useSelector(getUserBalance);

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [restorePrice, setRestorePrice] = useState<number>(0);

 const handleOpen = async () => {
  setRestorePrice(await dispatch(postDomainRestorePrice(domain_id)));
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postRestoreDomain(domain_id));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Button variant="kxActionButton" onClick={handleOpen}>
    <FormattedMessage id="profile.services.restore" />
   </Button>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "profile.services.restoreDomain" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading || restorePrice > userBalance}>
    <Stack>
     <Typography>
      <FormattedMessage id="profile.services.restoreDomainMessage" />
     </Typography>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.add.balance" />
      </Typography>
      <Typography>
       <FormattedNumber value={userBalance} style={"currency"} currency="EUR" />
      </Typography>
     </Stack>
     <Stack direction="row" spacing={2} alignItems="center">
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.add.totalPrice" />
      </Typography>
      <Typography>
       <FormattedNumber value={restorePrice} style={"currency"} currency="EUR" />
      </Typography>
     </Stack>
    </Stack>
   </AppModal>
  </>
 );
};

export default RestoreDomain;
