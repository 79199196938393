import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import FilemanagerFrame from "components/shared/externalApps/FilemanagerFrame";

import { postInstallFilemanager, saveFilemanagerSettings } from "redux/handlers/dockerHandler";

import { getServerDetails } from "redux/selectors/serversSelector";
import { getFilemanagerSession } from "redux/selectors/sessionSelector";

import { useAppDispatch } from "hooks/reduxHook";

const FilemanagerSection = ({
 id,
 active,
 file_port,
 file_password
}: {
 id: number;
 active: boolean;
 file_port: number | null;
 file_password: string | null;
}): ReactElement => {
 const dispatch = useAppDispatch();

 const server = useSelector(getServerDetails);
 const sessionActive = useSelector(getFilemanagerSession);

 const [loading, setLoading] = useState<boolean>(false);
 const [password, setPassword] = useState<string>(file_password || "");
 const [port, setPort] = useState<number>(file_port || 0);

 const handleLoadData = async () => {
  setLoading(true);
  const result = await dispatch(postInstallFilemanager(id));
  if (result) {
   setPassword(result.password);
   setPort(result.port);
   await dispatch(saveFilemanagerSettings(id, true, result?.password, result?.port));
  }
  setLoading(false);
 };

 return loading ? (
  <Stack direction="row" alignItems="center" spacing={2}>
   <CircularProgress />
   <Typography>
    <FormattedMessage id="docker.website.loadingFilemanagerData" />
   </Typography>
  </Stack>
 ) : active || sessionActive === id ? (
  <FilemanagerFrame
   id={id}
   password={password}
   link={`http://${server.ipv4}:${port}`}
   setLoad={(value) => setLoading(value)}
  />
 ) : (
  <Stack>
   <Alert severity="warning">
    <FormattedMessage id="docker.website.filemanagerOff" />
   </Alert>
   <Stack direction="row">
    <Button onClick={handleLoadData}>
     <FormattedMessage id="docker.website.activateFilemanager" />
    </Button>
   </Stack>
  </Stack>
 );
};

export default FilemanagerSection;
