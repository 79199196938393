import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { ipRegex } from "constants/regexp";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postUpdateDatabase } from "redux/handlers/websitesHandler";

import { getWebsitesDetails } from "redux/selectors/websitesSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IDbData } from "types/api/websitesApiInterface";

const EditDbRemoteIP = ({
 selectedDb,
 domain_id
}: {
 selectedDb: IDbData;
 domain_id: number;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const websitesDetails = useSelector(getWebsitesDetails);

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [newIp, setNewIp] = useState<string>("");
 const [error, setError] = useState<string>("");
 const [ipList, setIpList] = useState<Array<string>>(
  selectedDb.remote_ips ? selectedDb.remote_ips.split(",")?.map((element) => element) : []
 );

 const renderError = () => {
  switch (error) {
   case "length":
    return "addons.firewall.ipLengthError";
   case "format":
    return "addons.firewall.ipFormatError";
   case "void":
    return "addons.firewall.ipVoidError";
   case "existing":
    return "addons.firewall.ipExistingError";
   default:
    return "";
  }
 };

 const handleOpen = () => {
  setIpList(
   selectedDb.remote_ips ? selectedDb.remote_ips.split(",")?.map((element) => element) : []
  );
  setNewIp("");
  setError("");
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setIsLoading(true);
  await dispatch(
   postUpdateDatabase(
    selectedDb.id,
    websitesDetails.isp_id,
    websitesDetails.domain_id,
    selectedDb.database_name,
    selectedDb.database_quota,
    selectedDb.remote_access,
    domain_id,
    ipList.toString()
   )
  );
  setIsLoading(false);
  setOpenModal(false);
 };

 const handleDeleteIp = (ipToDelete: string) => {
  if (ipList.length > 1) {
   const updatedList = ipList.filter((item) => item !== ipToDelete);
   setError("");
   setIpList(updatedList);
  } else {
   setError("length");
  }
 };

 const handleKeyDown = (event: any) => {
  if (event.key === "Enter") {
   if (newIp !== "" && !ipList.includes(newIp)) {
    if (ipRegex.test(newIp)) {
     let newData = ipList;
     newData.push(newIp);
     setIpList(newData);
     setNewIp("");
     setError("");
    } else {
     setError("format");
    }
   } else {
    if (newIp === "") {
     setError("void");
    } else {
     setError("existing");
    }
   }
  }
 };

 return (
  <>
   <Stack>
    <Tooltip title={<FormattedMessage id="sites.edit.dbRemoteIp" />}>
     <IconButton
      onClick={handleOpen}
      disabled={selectedDb.id === 0 || selectedDb.remote_access === "n"}>
      <IconSelector icon="EditIcon" />
     </IconButton>
    </Tooltip>
   </Stack>
   <AppModal
    open={openModal}
    close={handleClose}
    loading={isLoading}
    title={intl.formatMessage({ id: "sites.edit.dbRemoteIp" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={isLoading}>
    <Stack>
     <Stack alignItems="flex-start" width="50%">
      <Typography variant="caption" fontWeight="bold">
       IP
      </Typography>
      <Stack direction="row" flexWrap="wrap" mb={1}>
       {ipList.map((ip, key) => {
        return (
         <Chip
          key={`ip-rule-${key}`}
          size="small"
          label={ip === "0.0.0.0/0" ? "AnyIPv4" : ip === "::/0" ? "AnyIPv6" : ip}
          sx={{ mr: 0.5, mt: 0.5 }}
          onClick={() => handleDeleteIp(ip)}
          onDelete={() => handleDeleteIp(ip)}
         />
        );
       })}
      </Stack>
      <TextField
       label={intl.formatMessage({ id: "addons.firewall.insertIps" })}
       variant="standard"
       fullWidth
       value={newIp}
       onChange={(e) => setNewIp(e.target.value)}
       onKeyDown={handleKeyDown}
       error={error !== ""}
       helperText={error !== "" ? <FormattedMessage id={renderError()} /> : ""}
      />
     </Stack>
    </Stack>
   </AppModal>
  </>
 );
};

export default EditDbRemoteIP;
