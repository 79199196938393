import React, { ReactElement } from "react";
import useIntl from "react-intl/src/components/useIntl";

import Button from "@mui/material/Button";

const ServiceConfig = ({ service }: { service: string }): ReactElement => {
 const intl = useIntl();

 const RenderConfig = (): ReactElement => {
  switch (service) {
   default:
    return (
     <Button variant="kxActionButton">
      {intl.formatMessage({ id: "server.service.config" })} {service}
     </Button>
    );
  }
 };
 return <RenderConfig />;
};

export default ServiceConfig;
