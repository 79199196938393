import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import InsertCommentIcon from "@mui/icons-material/InsertComment";

import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { getUsersList } from "redux/handlers/userHandler";

import { getIsAdmin, getTelegramId } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IUsersList } from "types/api/userApiInterface";

import AdminVisual from "./AdminVisual";
import UserNotRegistered from "./UserNotRegistered";
import UserVisual from "./UserVisual";

const AppChat = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const isAdmin = useSelector(getIsAdmin);
 const tgId = useSelector(getTelegramId);

 const [open, setOpen] = useState<boolean>(false);
 const [usersList, setUsersList] = useState<Array<IUsersList>>([]);

 const handleOpen = async () => {
  setOpen(true);
  isAdmin && setUsersList(await dispatch(getUsersList()));
 };
 const handleClose = () => setOpen(false);

 return (
  <>
   <Stack
    sx={{
     position: "fixed",
     bottom: 12,
     right: 4,
     borderRadius: 2,
     alignItems: "flex-end",
     zIndex: "2000",
     boxShadow: 5
    }}
    spacing={2}
    mr={1}>
    {open && (
     <Box sx={{ width: 400, backgroundColor: "white", borderRadius: 2 }}>
      <Stack
       direction={"row"}
       justifyContent="space-between"
       sx={{
        backgroundColor: "#537EF2",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8
       }}>
       <Typography variant="h4" textAlign={"start"} pl={2} p={1} color="white">
        <FormattedMessage id="chat.support" />
       </Typography>
       <IconButton color="tertiary" className="close-button" onClick={handleClose}>
        <CloseIcon />
       </IconButton>
      </Stack>
      {!tgId ? (
       <UserNotRegistered />
      ) : isAdmin ? (
       <AdminVisual users={usersList} />
      ) : (
       <UserVisual open={open} />
      )}
     </Box>
    )}
   </Stack>
   <Tooltip title={intl.formatMessage({ id: "app.support" })}>
    <Fab
     color="primary"
     sx={{
      position: "fixed",
      right: 0,
      top: "50%",
      borderRadius: 0,
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
      px: 4,
      py: 1,
      height: 5
     }}
     aria-label="add"
     onClick={() => (open ? handleClose() : handleOpen())}>
     <InsertCommentIcon />
    </Fab>
   </Tooltip>
  </>
 );
};

export default AppChat;
