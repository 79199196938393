import React, { ReactElement, useEffect, useState } from "react";

import Stack from "@mui/material/Stack";

import SkeletonAddons from "components/shared/skeletons/SkeletonAddons";
import WebsitesList from "components/websites/WebsitesList";

import { getAllIspNotPaged, getAllWebsites } from "redux/handlers/websitesHandler";

import { useAppDispatch } from "hooks/reduxHook";

const ServerWebsitePage = (): ReactElement => {
 const dispatch = useAppDispatch();

 const [isLoading, setIsLoading] = useState<boolean>(true);

 useEffect(() => {
  (async () => {
   await dispatch(getAllIspNotPaged("webserver"));
   await dispatch(getAllWebsites(0, 10, 0));
   setIsLoading(false);
  })();
 }, []);

 return <Stack>{isLoading ? <SkeletonAddons id="app.sitesTitle" /> : <WebsitesList />}</Stack>;
};

export default ServerWebsitePage;
