import React, { ReactElement, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import AddIcon from "@mui/icons-material/Add";

import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";

import AppModal from "components/shared/modal/AppModal";

import { postAddCredit } from "redux/handlers/creditsHandler";
import { getUsersList } from "redux/handlers/userHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IUsersList } from "types/api/userApiInterface";

type movementData = {
 product: string;
 total: number;
 type: string;
 details: string;
 partner: string;
};

const AddMovement = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [contacts, setContacts] = useState<Array<IUsersList>>([]);

 useEffect(() => {
  (async () => {
   setContacts(await dispatch(getUsersList()));
  })();
 }, []);

 const { control, handleSubmit, formState, watch, setValue, reset } = useForm({
  defaultValues: {
   product: "",
   total: 0,
   type: "",
   details: "",
   partner: ""
  }
 });

 const onSubmit: SubmitHandler<movementData> = async (data: movementData) => {
  setIsLoading(true);
  await dispatch(
   postAddCredit(data.product, Number(data.total), data.details, data.partner, data.type)
  );
  setIsLoading(false);
  setOpenModal(false);
 };

 const handleOpen = () => {
  reset();
  setOpenModal(true);
 };

 const handleClose = () => {
  setOpenModal(false);
 };

 const dataCheck = (): boolean => {
  return (
   watch("product") === "" ||
   watch("total") === 0 ||
   watch("type") === "" ||
   watch("partner") === "" ||
   watch("details") === ""
  );
 };

 return (
  <>
   {desktopViewPort ? (
    <Button onClick={handleOpen} variant="kxActionButton" endIcon={<AddIcon />}>
     <FormattedMessage id="credit.action.add" />
    </Button>
   ) : (
    <IconButton onClick={handleOpen}>
     <AddIcon />
    </IconButton>
   )}
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    handleConfirm={handleSubmit(onSubmit)}
    title={intl.formatMessage({ id: "credit.action.add" })}
    disabled={dataCheck()}>
    <form onSubmit={handleSubmit(onSubmit)}>
     <Controller
      name="product"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "credit.product" })}
        error={formState.isDirty && !!formState?.errors?.total}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.total &&
         intl.formatMessage({ id: "credit.productError" })
        }
       />
      )}
     />
     <Controller
      name="total"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "credit.total" })}
        error={formState.isDirty && !!formState?.errors?.total}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        type="number"
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.total &&
         intl.formatMessage({ id: "credit.totalError" })
        }
       />
      )}
     />
     <Controller
      name="type"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) => {
         setValue("type", value || "");
        }}
        options={["cash", "bonus"]}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          InputLabelProps={{ shrink: true }}
          label={intl.formatMessage({ id: "credit.balanceType" })}
         />
        )}
       />
      )}
     />
     <Controller
      name="details"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "credit.details" })}
        error={formState.isDirty && !!formState?.errors?.type}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.type &&
         intl.formatMessage({ id: "credit.detailsError" })
        }
       />
      )}
     />
     <Controller
      name="partner"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) => {
         setValue("partner", value?.userid || "");
        }}
        options={contacts.map((element) => {
         return {
          label: `${element.firstname} ${element.lastname} - ${element.companyName}`,
          userid: element.userid
         };
        })}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          InputLabelProps={{ shrink: true }}
          label={intl.formatMessage({ id: "credit.partner" })}
         />
        )}
       />
      )}
     />
    </form>
   </AppModal>
  </>
 );
};

export default AddMovement;
