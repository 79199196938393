import React, { ReactElement, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { fqdnRegex } from "constants/regexp";
import { contaboServerCreationSteps, hetznerServerCreationSteps } from "constants/server";

import IconSelector from "components/shared/images/IconSelector";
import ProviderImage from "components/shared/images/ProviderImage";

import {
 getRebuildOs,
 getServerProvider,
 getServersLocations,
 getServersSize,
 postCheckServerName
} from "redux/handlers/serverHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { ILocations, IOsDetails, IProviders, IServerTypes } from "types/api/serversApiInterface";

import BuyContaboServer from "./serverWizardComponents/BuyContaboServer";
import BuyHetznerServer from "./serverWizardComponents/BuyHetznerServer";
import ContaboRecapBox from "./serverWizardComponents/ContaboRecapBox";
import ContaboServerCreation from "./serverWizardComponents/ContaboServerCreation";
import HetznerRecapBox from "./serverWizardComponents/HetznerRecapBox";
import HetznerServerCreation from "./serverWizardComponents/HetznerServerCreation";
import ProviderBox from "./serverWizardComponents/ProviderBox";

const ServerWizard = (): ReactElement => {
 const dispatch = useAppDispatch();
 const intl = useIntl();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const [activeStep, setActiveStep] = useState<number>(0);
 const [nameChecked, setNameChecked] = useState<string>("");
 const [stepLoading, setStepLoading] = useState<boolean>(false);
 const [providerList, setProviderList] = useState<Array<IProviders>>([]);
 const [osName, setOsName] = useState<string>("");
 const [serverSizeData, setServerSizeData] = useState<IServerTypes | null>(null);
 const [serverType, setServerType] = useState<string>("");
 const [open, setOpen] = useState<boolean>(false);

 const [locationList, setLocationList] = useState<Array<ILocations>>([]);
 const [typesList, setTypesList] = useState<Array<IServerTypes>>([]);
 const [osList, setOsList] = useState<Array<IOsDetails>>([]);

 useEffect(() => {
  (async () => {
   setProviderList(await dispatch(getServerProvider()));
  })();
 }, []);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleReset = () => {
  reset();
  setActiveStep(0);
 };

 const checkServerName = async () => {
  setStepLoading(true);
  setNameChecked(await dispatch(postCheckServerName(watch("name"))));
  setStepLoading(false);
 };

 const { control, formState, reset, watch, getValues, setValue } = useForm({
  defaultValues: {
   name: "",
   provider: 0,
   os: 0,
   location: 0,
   serverSize: 0,
   enableIpv4: false,
   enableIpv6: false,
   enableBackup: false,
   enableContinuity: false
  }
 });

 const renderServerChecked = () => {
  switch (nameChecked) {
   case "name available":
    return (
     <Alert severity="success">
      <FormattedMessage id="server.add.nameAvailable" />
     </Alert>
    );
   default:
    return (
     <Alert severity="error">
      <FormattedMessage id="server.add.nameNotAvailable" />
     </Alert>
    );
  }
 };

 const getLocationData = async () => {
  setStepLoading(true);
  setLocationList(await dispatch(getServersLocations(watch("provider"))));
  setStepLoading(false);
 };

 const getServersTypeData = async () => {
  setStepLoading(true);
  setTypesList(
   await dispatch(
    getServersSize(
     locationList.find((element) => element.id === watch("location"))?.name || "",
     watch("provider")
    )
   )
  );
  setStepLoading(false);
 };

 const handleNext = async () => {
  if (activeStep === 3) getLocationData();
  if (activeStep === 4) getServersTypeData();
  if (activeStep === 2) {
   setStepLoading(true);
   const data = await dispatch(getRebuildOs(watch("provider")));
   setOsList(data);
   setStepLoading(false);
  }
  if (activeStep === 2 && serverType !== "custom") {
   getLocationData();
   setActiveStep((prevActiveStep) => prevActiveStep + 2);
  } else {
   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }
 };

 const handleBack = () => {
  if (activeStep === 4 && serverType !== "custom") {
   setActiveStep((prevActiveStep) => prevActiveStep - 2);
  } else {
   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }
 };

 const setReceivedData = (
  section: string,
  value: string | number | boolean | IServerTypes | ILocations
 ) => {
  switch (section) {
   case "serverType":
    setServerType(value as string);
    break;
   case "os":
    setValue("os", value as number);
    break;
   case "location":
    setValue("location", value as number);
    break;
   case "serverSize":
    if (value === 0) {
     setServerSizeData(null);
    }
    setValue("serverSize", value as number);
    break;
   case "enableBackup":
    setValue("enableBackup", value as boolean);
    break;
   case "enableIpv4":
    setValue("enableIpv4", value as boolean);
    break;
   case "enableIpv6":
    setValue("enableIpv6", value as boolean);
    break;
   case "osName":
    setOsName(value as string);
    break;
   case "serverSizeData":
    setServerSizeData(value as IServerTypes);
    break;
   case "enableContinuity":
    setValue("enableContinuity", value as boolean);
    break;
   default:
    break;
  }
 };

 const RenderRecapBox = (): ReactElement => {
  switch (watch("provider")) {
   case 1:
    return (
     <HetznerRecapBox
      enableIpv4={watch("enableIpv4")}
      enableBackup={watch("enableBackup")}
      enableIpv6={watch("enableIpv6")}
      enableContinuity={watch("enableContinuity")}
      locationData={locationList.find((element) => element.id === watch("location")) || null}
      serverSizeData={serverSizeData}
      osName={osName}
     />
    );
   case 2:
    return (
     <ContaboRecapBox
      locationData={locationList.find((element) => element.id === watch("location")) || null}
      serverSizeData={serverSizeData}
      osName={osName}
     />
    );
   default:
    return <></>;
  }
 };

 const RenderCreationSections = ({
  description,
  index
 }: {
  description: string;
  index: number;
 }): ReactElement => {
  switch (watch("provider")) {
   case 1:
    return (
     <HetznerServerCreation
      stepLoading={stepLoading}
      description={description}
      index={index}
      serverType={serverType}
      locationList={locationList}
      osList={osList}
      typesList={typesList}
      os={watch("os")}
      location={watch("location")}
      serverSize={watch("serverSize")}
      enableBackup={watch("enableBackup")}
      enableIpv4={watch("enableIpv4")}
      enableIpv6={watch("enableIpv6")}
      enableContinuity={watch("enableContinuity")}
      nextStep={handleNext}
      previousStep={handleBack}
      resetData={handleReset}
      handleOpen={handleOpen}
      setData={(section, value) => setReceivedData(section, value)}
     />
    );
   case 2:
    return (
     <ContaboServerCreation
      stepLoading={stepLoading}
      description={description}
      index={index}
      serverType={serverType}
      locationList={locationList}
      osList={osList}
      typesList={typesList}
      os={watch("os")}
      location={watch("location")}
      serverSize={watch("serverSize")}
      nextStep={handleNext}
      previousStep={handleBack}
      resetData={handleReset}
      handleOpen={handleOpen}
      setData={(section, value) => setReceivedData(section, value)}
     />
    );
   default:
    return <></>;
  }
 };

 const RenderStepInside = () => {
  switch (watch("provider")) {
   case 1:
    return (
     <>
      {hetznerServerCreationSteps.map((step, index) => {
       return (
        <Step index={index + 2} key={step.label}>
         <StepLabel>
          <FormattedMessage id={step.label} />
         </StepLabel>
         <RenderCreationSections description={step.description} index={index} />
        </Step>
       );
      })}
     </>
    );
   case 2:
    return (
     <>
      {contaboServerCreationSteps.map((step, index) => {
       return (
        <Step index={index + 2} key={step.label}>
         <StepLabel>
          <FormattedMessage id={step.label} />
         </StepLabel>
         <RenderCreationSections description={step.description} index={index} />
        </Step>
       );
      })}
     </>
    );
   case 3:
    return (
     <Stack>
      <Typography fontStyle="italic">Work in progress</Typography>
     </Stack>
    );
   default:
    return <></>;
  }
 };

 const RenderBuyModal = (): ReactElement => {
  switch (watch("provider")) {
   case 1:
    return (
     <BuyHetznerServer
      open={open}
      dataToSend={{
       name: watch("name"),
       os: watch("os"),
       location: watch("location"),
       serverSize: typesList.find((element) => element.id === getValues("serverSize"))?.name || "",
       serverType: serverType,
       provider: watch("provider"),
       enableBackup: watch("enableBackup"),
       enableIpv4: watch("enableIpv4"),
       enableIpv6: watch("enableIpv6"),
       enableContinuity: watch("enableContinuity")
      }}
      serverSizeData={serverSizeData}
      handleClose={handleClose}
      handleReset={handleReset}
     />
    );
   case 2:
    return (
     <BuyContaboServer
      open={open}
      dataToSend={{
       name: watch("name"),
       os: watch("os"),
       location: watch("location"),
       serverSize: typesList.find((element) => element.id === getValues("serverSize"))?.name || "",
       serverType: serverType,
       provider: watch("provider"),
       productId: serverSizeData?.productId || ""
      }}
      serverSizeData={serverSizeData}
      handleClose={handleClose}
      handleReset={handleReset}
     />
    );
   default:
    return <></>;
  }
 };

 return (
  <Stack spacing={2}>
   <Stack p={4} alignItems="center">
    <IconSelector icon="ServerIcon" props={{ fontSize: "large" }} />
    <Typography variant="h5">
     <FormattedMessage id="server.newserver" />
    </Typography>
   </Stack>
   <Stack direction={desktopViewPort ? "row" : "column"} justifyContent="center">
    <Box
     sx={{ maxWidth: desktopViewPort ? 1200 : "auto", minWidth: desktopViewPort ? 500 : "auto" }}
     m={10}>
     <Stepper activeStep={activeStep} orientation="vertical">
      <Step>
       <StepLabel>
        <FormattedMessage id="server.add.selectName" />
       </StepLabel>
       {!stepLoading ? (
        <StepContent>
         <Stack spacing={2}>
          <Alert severity="info">
           <Typography>
            <FormattedMessage id="server.add.nameDescription" />
           </Typography>
          </Alert>
          <Controller
           name="name"
           control={control}
           rules={{
            required: true,
            pattern: fqdnRegex
           }}
           render={({ field }) => (
            <TextField
             {...field}
             fullWidth={true}
             onChange={(event) => {
              setValue("name", event.target.value);
              setNameChecked("");
             }}
             label={intl.formatMessage({ id: "server.name" })}
             error={formState.isDirty && !!formState?.errors?.name}
             InputLabelProps={{ shrink: true }}
             sx={{ my: 2 }}
             autoComplete="on"
            />
           )}
          />
          {nameChecked !== "" && renderServerChecked()}
         </Stack>
         <Box sx={{ mb: 2 }}>
          <Button
           disabled={
            watch("name").length === 0 ||
            !fqdnRegex.test(watch("name")) ||
            nameChecked === "name not available"
           }
           variant="contained"
           onClick={() => (nameChecked === "" ? checkServerName() : handleNext())}
           sx={{ mt: 1, mr: 1 }}>
           {nameChecked === "" ? (
            <FormattedMessage id="server.add.serverCheck" />
           ) : (
            <FormattedMessage id="server.add.continue" />
           )}
          </Button>
          <Button disabled onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
           <FormattedMessage id="server.add.back" />
          </Button>
         </Box>
        </StepContent>
       ) : (
        <StepContent>
         <Stack spacing={2}>
          <Alert severity="info">
           <Typography>
            <FormattedMessage id="server.checkExecution" />
           </Typography>
          </Alert>
          <CircularProgress />
         </Stack>
        </StepContent>
       )}
      </Step>
      <Step>
       <StepLabel>
        <FormattedMessage id="server.add.selectProvider" />
       </StepLabel>
       {!stepLoading ? (
        <StepContent>
         <Stack spacing={2}>
          <Alert severity="info">
           <Typography>
            <FormattedMessage id="server.add.selectProviderMessage" />
           </Typography>
          </Alert>
          <ProviderBox
           providers={providerList}
           selectProvider={(id) => setValue("provider", id)}
           selectedProvider={watch("provider")}
          />
          <Alert severity="warning" icon={<IconSelector icon="AllInclusiveIcon" />}>
           <FormattedMessage id="server.add.continuityMessage" />
          </Alert>
         </Stack>
         <Box sx={{ mb: 2 }}>
          <Button
           disabled={watch("provider") === 3 || watch("provider") === 0}
           variant="contained"
           onClick={() => (nameChecked === "" ? checkServerName() : handleNext())}
           sx={{ mt: 1, mr: 1 }}>
           <FormattedMessage id="server.add.continue" />
          </Button>
          <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
           <FormattedMessage id="server.add.back" />
          </Button>
         </Box>
        </StepContent>
       ) : (
        <StepContent>
         <Stack spacing={2}>
          <Alert severity="info">
           <Typography>
            <FormattedMessage id="server.checkExecution" />
           </Typography>
          </Alert>
          <CircularProgress />
         </Stack>
        </StepContent>
       )}
      </Step>
      <RenderStepInside />
     </Stepper>
    </Box>
    <Paper
     elevation={0}
     sx={{
      m: 5,
      p: 2,
      maxHeight: 600,
      position: "sticky",
      top: 30,
      minWidth: desktopViewPort ? 400 : "auto",
      borderRadius: "10px",
      boxShadow: 0
     }}>
     <Stack mb={2}>
      <Typography variant="h5" fontWeight="bold">
       <FormattedMessage id="server.add.recap" />
      </Typography>
     </Stack>
     <Divider textAlign="left">
      <Typography variant="overline">
       <FormattedMessage id="server.add.serverData" />
      </Typography>
     </Divider>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.name" />:
      </Typography>
      <Typography noWrap>{watch("name")}</Typography>
     </Stack>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.provider" />:
      </Typography>
      <Stack direction="row" spacing={1} alignItems="center">
       <ProviderImage
        provider={providerList.find((element) => element.id === watch("provider"))?.name || ""}
       />
       <Typography>
        {providerList.find((element) => element.id === watch("provider"))?.name || ""}
       </Typography>
      </Stack>
     </Stack>
     <RenderRecapBox />
    </Paper>
   </Stack>
   <RenderBuyModal />
  </Stack>
 );
};

export default ServerWizard;
