import React, { ReactElement, useState } from "react";
import { useIntl } from "react-intl";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import useMediaQuery from "@mui/material/useMediaQuery";

import LoadBalancerGraph from "./LoadBalancerGraph";
import LoadBalancerSettings from "./LoadBalancerSettings";

const LoadBalancerManage = (): ReactElement => {
 const intl = useIntl();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const [section, setSection] = useState<number>(0);

 const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
  setSection(newValue);
 };

 const RenderTabContent = (): ReactElement => {
  switch (section) {
   case 0:
    return <LoadBalancerSettings />;
   case 1:
    return <LoadBalancerGraph />;
   default:
    return <LoadBalancerSettings />;
  }
 };

 return (
  <Stack>
   <Paper elevation={0} sx={{ mt: 3, pb: 0.7, borderRadius: "10px", boxShadow: 0 }}>
    <Tabs
     value={section}
     onChange={handleChangeTab}
     variant={desktopViewPort ? "standard" : "scrollable"}
     scrollButtons={true}
     allowScrollButtonsMobile>
     <Tab label={intl.formatMessage({ id: "addons.loadBalancer.settings" })} />
     <Tab label={intl.formatMessage({ id: "addons.loadBalancer.graph" })} />
    </Tabs>
    <RenderTabContent />
   </Paper>
  </Stack>
 );
};

export default LoadBalancerManage;
