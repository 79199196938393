/* eslint-disable react/style-prop-object */
import React, { ReactElement } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { formatCountryCode, formatServerPrice } from "helpers/server";

import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { ILocations, IServerTypes } from "types/api/serversApiInterface";

const ContaboRecapBox = ({
 osName,
 serverSizeData,
 locationData
}: {
 osName: string;
 serverSizeData: IServerTypes | null;
 locationData: ILocations | null;
}): ReactElement => {
 return (
  <>
   <Divider textAlign="left" sx={{ mt: 2 }}>
    <Typography variant="overline">
     <FormattedMessage id="server.add.serverSpecs" />
    </Typography>
   </Divider>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={150} textAlign="start" fontWeight="bold">
     <FormattedMessage id="server.os" />:
    </Typography>
    <Typography>{osName}</Typography>
   </Stack>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={150} textAlign="start" fontWeight="bold">
     <FormattedMessage id="server.type" />:
    </Typography>
    <Typography>{serverSizeData?.name || ""}</Typography>
   </Stack>
   <Stack direction="row" spacing={2} alignItems="center">
    <Typography minWidth={150} textAlign="start" fontWeight="bold">
     <FormattedMessage id="server.location" />:
    </Typography>
    <Typography noWrap>{locationData?.city || ""}</Typography>
    <Stack component="span" className={`fi fi-${formatCountryCode(locationData?.country || "")}`} />
   </Stack>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={150} textAlign="start" fontWeight="bold">
     <FormattedMessage id="server.cores" />:
    </Typography>
    <Typography>{serverSizeData?.cores || ""}</Typography>
   </Stack>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={150} textAlign="start" fontWeight="bold">
     <FormattedMessage id="server.add.ram" />:
    </Typography>
    <Typography>{`${serverSizeData?.memory || "0"} GB`}</Typography>
   </Stack>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={150} textAlign="start" fontWeight="bold">
     <FormattedMessage id="server.add.disk" />:
    </Typography>
    <Typography>{`${serverSizeData?.disk || "0"} GB`}</Typography>
   </Stack>
   <Divider sx={{ my: 1 }} />
   <Stack>
    <Stack direction="row" spacing={2} alignItems="center">
     <Typography minWidth={150} textAlign="start" fontWeight="bold">
      <FormattedMessage id="server.add.totalPrice" />
     </Typography>
     <Typography fontSize={"x-large"} fontStyle="italic">
      <FormattedNumber
       value={
        formatServerPrice(
         serverSizeData ? [serverSizeData] : [],
         false,
         false,
         false,
         serverSizeData?.id || 0
        ) || 0
       }
       style={"currency"}
       currency="EUR"
      />
     </Typography>
    </Stack>
   </Stack>
  </>
 );
};

export default ContaboRecapBox;
