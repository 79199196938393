import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";

import AppModal from "components/shared/modal/AppModal";

import { postRetryDomainOperation } from "redux/handlers/domainsHandler";

import { useAppDispatch } from "hooks/reduxHook";

const RetryNs = ({ id, action }: { id: number; action: string }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);
 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postRetryDomainOperation(id, action));
  setLoading(false);
 };

 return (
  <>
   <Button variant="kxActionButton" onClick={handleOpen}>
    <FormattedMessage id="domain.retry" />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "domain.retry" })}
    handleClose={handleClose}
    disabled={loading}
    handleConfirm={handleConfirm}>
    <FormattedMessage id="domain.error.confirmRetry" />
   </AppModal>
  </>
 );
};

export default RetryNs;
