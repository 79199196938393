/* eslint-disable react/style-prop-object */
import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { isNil } from "ramda";

import AddIcon from "@mui/icons-material/Add";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postAddSnapshot, postCheckSnapshotPrice } from "redux/handlers/addonsHandle";
import { getAllServersNotPaged } from "redux/handlers/serverHandler";

import { getUserBalance } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IAllServers } from "types/api/serversApiInterface";

const AddSnapshot = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const userBalance = useSelector(getUserBalance);

 const [open, setOpen] = useState<boolean>(false);
 const [servers, setServers] = useState<Array<IAllServers>>([]);
 const [price, setPrice] = useState<number>(0);
 const [isLoading, setIsLoading] = useState<boolean>(false);

 const handleOpen = async () => {
  setServers(await dispatch(getAllServersNotPaged()));
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const handleGetPrice = async (id: number) => {
  setIsLoading(true);
  setPrice(await dispatch(postCheckSnapshotPrice(id)));
  setIsLoading(false);
 };

 const { control, formState, handleSubmit, watch, setValue } = useForm({
  defaultValues: {
   name: "",
   server: 0
  }
 });

 const onSubmit: SubmitHandler<{ name: string; server: number }> = async (data: {
  name: string;
  server: number;
 }) => {
  await dispatch(postAddSnapshot(data.server, price, data.name));
  handleClose();
 };

 const checkDisabledModal = (): boolean => {
  return watch("server") === 0 || isLoading || price === 0 || userBalance < price;
 };

 return (
  <>
   <Button variant="kxActionButton" endIcon={<AddIcon />} onClick={() => handleOpen()}>
    <FormattedMessage id="addons.snapshot.addSnapshot" />
   </Button>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "addons.snapshot.addSnapshot" })}
    handleClose={handleClose}
    disabled={checkDisabledModal()}
    handleConfirm={handleSubmit(onSubmit)}>
    <Stack>
     <Alert severity="info">
      <Typography>
       <FormattedMessage id="addons.snapshot.addSnapshotMessage" />
      </Typography>
     </Alert>
     <Controller
      name="server"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) => {
         isNil(value) ? setValue("server", 0) : setValue("server", value.id);
         !isNil(value) && handleGetPrice(value.id);
        }}
        options={servers.map((element) => {
         return { label: element.server_name, id: element.id };
        })}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          label={intl.formatMessage({ id: "app.servers" })}
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
      )}
     />
     <Controller
      name="name"
      control={control}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "server.snapshot.snapshotName" })}
        error={formState.isDirty && !!formState?.errors?.name}
        helperText={<FormattedMessage id="addons.snapshot.descriptionOptional" />}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
       />
      )}
     />
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.add.balance" />
      </Typography>
      <Typography>
       <FormattedNumber value={userBalance} style={"currency"} currency="EUR" />
      </Typography>
     </Stack>
     <Stack direction="row" spacing={2} alignItems="center">
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.add.totalPrice" />
      </Typography>
      <Typography>
       <FormattedNumber value={price} style={"currency"} currency="EUR" />
      </Typography>
     </Stack>
    </Stack>
   </AppModal>
  </>
 );
};

export default AddSnapshot;
