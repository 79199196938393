import React, { ReactElement } from "react";

import Tooltip from "@mui/material/Tooltip";

import { EDomainProvider } from "types/redux/serverInterface";

type Props = {
 provider: string | null;
 width?: number;
 height?: number;
};

const RegistarImage = ({ provider, width = 25, height = 25 }: Props): ReactElement => {
 const image = () => {
  switch (provider) {
   case EDomainProvider.INTERNETBS:
    return (
     <img
      width={width}
      height={height}
      src={`${process.env.PUBLIC_URL}/svg/providers/ibs.svg`}
      alt="provider"
     />
    );
   default:
    return (
     <img
      width={width + 10}
      height={height + 5}
      src={`${process.env.PUBLIC_URL}/svg/providers/ascio.svg`}
      alt="provider"
     />
    );
  }
 };

 return (
  <Tooltip title={provider || ""} placement="top">
   {image()}
  </Tooltip>
 );
};

export default RegistarImage;
