import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postRebuildBackup } from "redux/handlers/backupsHandler";

import { getServerDetails } from "redux/selectors/serversSelector";

import { useAppDispatch } from "hooks/reduxHook";

const RebuildBackup = ({
 id,
 reloadData,
 closeMenu
}: {
 id: number;
 reloadData: () => void;
 closeMenu: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const server_id = useSelector(getServerDetails);

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postRebuildBackup(id, server_id.id || 0));
  setLoading(false);
  reloadData();

  handleClose();
  closeMenu();
 };

 return (
  <>
   <MenuItem onClick={handleOpen} disabled={server_id.locked}>
    <FormattedMessage id="server.backups.rebuild" />
   </MenuItem>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "server.backups.rebuildTitle" })}
    handleClose={handleClose}
    disabled={loading}
    handleConfirm={handleConfirm}>
    <Typography>
     <FormattedMessage id="server.backups.rebuildModalMessage" />
    </Typography>
   </AppModal>
  </>
 );
};

export default RebuildBackup;
