import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import GatewayOrdersReducer from "redux/reducers/gatewayOrderReducer";
import notificationReducer from "redux/reducers/notificationReducer";

import {
 IBlacklistApi,
 IGateway,
 IGatewayOrders,
 INetworkApi,
 IRelayDomains,
 ISpamApi,
 ITrackcenterApi,
 ITransportApi,
 IVirusApi,
 IWhitelistApi
} from "types/api/gatewayApiInterface";

import { ApiService } from "service/ApiService";

export const getGatewayOrders =
 (page: number, limit: number, q?: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IGatewayOrders>(
    `/getorders?page=${page}&limit=${limit}&q=${q || ""}`
   );
   dispatch(GatewayOrdersReducer.actions.setGatewayOrders(data || []));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load gateway orders - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const fetchGatewayList =
 (
  currentIndex: number,
  sizePerPage: number,
  q?: string,
  partner?: string
 ): AppAction<Promise<IGateway>> =>
 async (dispatch): Promise<IGateway> => {
  try {
   const { data } = await ApiService<IGateway>(
    `/getGatewayList?page=${currentIndex}&limit=${sizePerPage}&q=${q || ""}&partner=${
     partner || ""
    }`
   );
   return data;
  } catch (error) {
   console.error("Error getting ISP list:", error);
   return { dataset: [], totalCount: 0 };
  }
 };

export const postAddGateway =
 (
  userid: string,
  url: string,
  hostname: string,
  server: number,
  password: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/addGateway", "POST", {
    userid: userid,
    url: url,
    serverId: server,
    hostname: hostname,
    password: password
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Adding new gateway...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to add new gateway - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateGateway =
 (
  id: number,
  userid: string,
  url: string,
  hostname: string,
  server: number,
  password: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/modifyGateway", "POST", {
    id: id,
    userid: userid,
    url: url,
    hostname: hostname,
    serverId: server,
    password: password
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Updating gateway...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update gateway - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteGateway =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/deleteGateway", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Updating gateway...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update gateway - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const fetchRelayDomainsList =
 (
  id: number,
  currentIndex: number,
  sizePerPage: number,
  q?: string
 ): AppAction<Promise<IRelayDomains>> =>
 async (dispatch): Promise<IRelayDomains> => {
  try {
   const { data } = await ApiService<IRelayDomains>(
    `/getDomainsList?serverId=${id}&page=${currentIndex}&limit=${sizePerPage}&q=${q || ""}`
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get relay domains - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return { dataset: [], totalCount: 0 };
  }
 };

export const fetchNetworkList =
 (
  id: number,
  currentIndex: number,
  sizePerPage: number,
  q?: string
 ): AppAction<Promise<INetworkApi>> =>
 async (dispatch): Promise<INetworkApi> => {
  try {
   const { data } = await ApiService<INetworkApi>(
    `/getNetworksList?serverId=${id}&page=${currentIndex}&limit=${sizePerPage}&q=${q || ""}`
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get network - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return { dataset: [], totalCount: 0 };
  }
 };

export const fetchTransportList =
 (
  id: number,
  currentIndex: number,
  sizePerPage: number,
  q?: string
 ): AppAction<Promise<ITransportApi>> =>
 async (dispatch): Promise<ITransportApi> => {
  try {
   const { data } = await ApiService<ITransportApi>(
    `/getTransportsList?serverId=${id}&page=${currentIndex}&limit=${sizePerPage}&q=${q || ""}`
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get transport - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return { dataset: [], totalCount: 0 };
  }
 };

export const fetchWhiteList =
 (
  id: number,
  currentIndex: number,
  sizePerPage: number,
  q?: string
 ): AppAction<Promise<IWhitelistApi>> =>
 async (dispatch): Promise<IWhitelistApi> => {
  try {
   const { data } = await ApiService<IWhitelistApi>(
    `/getWhitelistList?serverId=${id}&page=${currentIndex}&limit=${sizePerPage}&q=${q || ""}`
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get whitelist - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return { dataset: [], totalCount: 0 };
  }
 };

export const fetchBlackList =
 (
  id: number,
  currentIndex: number,
  sizePerPage: number,
  q?: string
 ): AppAction<Promise<IBlacklistApi>> =>
 async (dispatch): Promise<IBlacklistApi> => {
  try {
   const { data } = await ApiService<IBlacklistApi>(
    `/getBlacklistList?serverId=${id}&page=${currentIndex}&limit=${sizePerPage}&q=${q || ""}`
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get blacklist - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return { dataset: [], totalCount: 0 };
  }
 };

export const fetchSpamQuarantine =
 (
  id: number,
  currentIndex: number,
  sizePerPage: number,
  q?: string
 ): AppAction<Promise<ISpamApi>> =>
 async (dispatch): Promise<ISpamApi> => {
  try {
   const { data } = await ApiService<ISpamApi>(
    `/getSpamList?serverId=${id}&page=${currentIndex}&limit=${sizePerPage}&q=${q || ""}`
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get spam quarantine - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return { dataset: [], totalCount: 0 };
  }
 };

export const fetchVirusQuarantine =
 (
  id: number,
  currentIndex: number,
  sizePerPage: number,
  q?: string
 ): AppAction<Promise<IVirusApi>> =>
 async (dispatch): Promise<IVirusApi> => {
  try {
   const { data } = await ApiService<IVirusApi>(
    `/getVirusList?serverId=${id}&page=${currentIndex}&limit=${sizePerPage}&q=${q || ""}`
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get spam quarantine - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return { dataset: [], totalCount: 0 };
  }
 };

export const fetchTrackcenter =
 (
  id: number,
  currentIndex: number,
  sizePerPage: number,
  q: string,
  greylist: boolean,
  ndr: boolean,
  from: string,
  target: string,
  starttime: number,
  endtime: number
 ): AppAction<Promise<ITrackcenterApi>> =>
 async (dispatch): Promise<ITrackcenterApi> => {
  try {
   const { data } = await ApiService<ITrackcenterApi>("/tracking", "POST", {
    page: currentIndex,
    limit: sizePerPage,
    serverId: id,
    node: "",
    greylist: greylist ? "1" : "0",
    sender: from,
    receiver: target,
    ndr: ndr ? "1" : "0",
    xfilter: q,
    starttime: starttime,
    endtime: endtime
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get spam quarantine - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return { dataset: [], totalCount: 0 };
  }
 };

export const postAddRelayDomains =
 (id: number, name: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/addDomains", "POST", {
    serverId: id,
    domain: {
     domain: name
    }
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Adding new relay domain...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to add new relay domain - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteRelayDomains =
 (id: number, domainId: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/deleteDomains", "POST", {
    serverId: id,
    domain: {
     id: domainId
    }
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting relay domain...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete relay domain - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postAddNetwork =
 (id: number, name: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/addNetworks", "POST", {
    serverId: id,
    network: {
     trusted_networks: name
    }
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Adding new network...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to add new network - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postEditNetwork =
 (id: number, networkId: number, name: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/modifyNetworks", "POST", {
    serverId: id,
    network: {
     id: networkId,
     name: name
    }
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Editing network...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to edit network - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteNetwork =
 (id: number, networkId: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/deleteNetworks", "POST", {
    serverId: id,
    network: {
     id: networkId
    }
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting network...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete network - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postAddTransport =
 (
  id: number,
  relay: string,
  host: string,
  protocol: string,
  port: number,
  use_mx: boolean
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/addTransports", "POST", {
    serverId: id,
    transport: {
     name: relay,
     host: host,
     protocol: protocol.toLocaleLowerCase(),
     port: `${port}`,
     use_mx: use_mx ? 1 : 0
    }
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Adding new transport...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to add new transport - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postEditTransport =
 (
  id: number,
  transId: number,
  relay: string,
  host: string,
  protocol: string,
  port: number,
  use_mx: boolean
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/modifyTransports", "POST", {
    serverId: id,
    transport: {
     id: transId,
     name: relay,
     host: host,
     protocol: protocol,
     port: port,
     use_mx: use_mx
    }
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Editing transport...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to edit transport - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteTransport =
 (id: number, transportId: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/deleteTransports", "POST", {
    serverId: id,
    transport: {
     id: transportId
    }
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting transport...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete transport - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postAddWhitelist =
 (id: number, type: string, address: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/addWhitelist", "POST", {
    serverId: `${id}`,
    whitelist: {
     type: type,
     address: address
    }
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Adding new rule...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to add new rule - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postEditWhitelist =
 (id: number, whiteId: number, type: string, address: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/modifyWhitelist", "POST", {
    serverId: id,
    whitelist: {
     id: whiteId,
     type: type,
     address: address
    }
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Editing rule...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to edit rule - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteWhitelist =
 (id: number, whiteId: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/deleteWhitelist", "POST", {
    serverId: id,
    whitelist: {
     id: whiteId
    }
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting rule...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete rule - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postAddBlacklist =
 (id: number, type: string, address: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/addBlacklist", "POST", {
    serverId: `${id}`,
    blacklist: {
     type: type,
     address: address
    }
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Adding new rule...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to add new rule - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postEditBlacklist =
 (id: number, blackId: number, type: string, address: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/modifyBlacklist", "POST", {
    serverId: id,
    blacklist: {
     id: blackId,
     type: type,
     address: address
    }
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Editing rule...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to edit rule - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteBlacklist =
 (id: number, blackId: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/deleteBlacklist", "POST", {
    serverId: id,
    blacklist: {
     id: blackId
    }
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting rule...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete rule - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postSpamActions =
 (id: number, action: string, spamId: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/actionToSpam", "POST", {
    serverId: id,
    action: action,
    spamId: spamId
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Executing spam operation...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to execute spam operation - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postVirusActions =
 (id: number, action: string, virusId: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/actionToVirus", "POST", {
    serverId: id,
    action: action,
    virusId: virusId
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Executing virus operation...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to execute virus operation - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postRetriveBody =
 (id: number, mailId: string): AppAction<Promise<Array<string>>> =>
 async (dispatch): Promise<Array<string>> => {
  try {
   const { data } = await ApiService<Array<string>>("/trackingLogs", "POST", {
    serverId: id,
    mailId: mailId
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to retrive tracking body - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };
