import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

import { IFloatingIpDataset } from "types/api/addonsApiInterface";

export const getFloatingIp = (state: AppState) => state.floatingip;

export const getFloatingNumber = createSelector(getFloatingIp, (floatingip) => {
 return floatingip?.totalCount;
});

export const getFloatingIpDataset = createSelector(getFloatingIp, (floatingips) => {
 return (
  floatingips?.dataset?.map((floatingips: IFloatingIpDataset) => {
   return {
    blocked: floatingips.blocked,
    city: floatingips.city,
    country: floatingips.country,
    created: floatingips.created,
    description: floatingips.description,
    dns_ptr: floatingips.dns_ptr,
    floatingIp_id: floatingips.floatingIp_id,
    id: floatingips.id,
    ip: floatingips.ip,
    loacation_name: floatingips.loacation_name,
    name: floatingips.name,
    project_id: floatingips.project_id,
    protection: floatingips.protection,
    server: floatingips.server,
    type: floatingips.type,
    userid: floatingips.userid,
    expire: floatingips.expire,
    personal_name: floatingips.personal_name
   };
  }) || []
 );
});
