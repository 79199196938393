import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import Chip from "@mui/material/Chip";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import CronjobActions from "components/websites/cronjob/CronjobActions";

import { cronjobTableData } from "types/global/table";

const CronjobTable = ({
 rowData,
 index
}: {
 rowData: cronjobTableData;
 index: number;
}): ReactElement => {
 return (
  <TableRow key={`customers-list-${index}`}>
   <TableCell component="th" scope="row">
    <Chip
     size="small"
     label={
      rowData.active === "y" ? (
       <FormattedMessage id="sites.cronjob.on" />
      ) : (
       <FormattedMessage id="sites.cronjob.off" />
      )
     }
     color={rowData.active === "y" ? "success" : "error"}
    />
   </TableCell>
   <TableCell component="th" scope="row">
    {rowData.command}
   </TableCell>
   <TableCell component="th" align="right">
    <CronjobActions rowData={rowData} />
   </TableCell>
  </TableRow>
 );
};

export default CronjobTable;
