import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IMailsApi } from "types/api/mailApiInterface";

const initialState: IMailsApi = { dataset: [], totalCount: 0 };

const MailsReducer = createSlice({
 name: "mails",
 initialState,
 reducers: {
  setMails: (state, { payload }: PayloadAction<IMailsApi>) => {
   return payload;
  },
  resetMails: () => {
   return initialState;
  }
 }
});

export default MailsReducer;
