import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import { mailsLinks } from "constants/staticLinks";

import SectionsBreadCrumbs from "components/shared/breadCrumbs/SectionsBreadCrumbs";
import IconSelector from "components/shared/images/IconSelector";
import MobileSectionMenu from "components/shared/sideMenu/MobileSectionMenu";
import SectionMenu from "components/shared/sideMenu/SectionMenu";

import { getAllMailsDomain } from "redux/handlers/mailsHandler";
import { postSyncronizeSocket } from "redux/handlers/socketHandler";
import { getAllIspNotPaged } from "redux/handlers/websitesHandler";

import { getIspList } from "redux/selectors/ispSelector";
import { getMailsList } from "redux/selectors/mailsSelector";

import { useAppDispatch } from "hooks/reduxHook";

import MailBackup from "./manage/MailBackup";
import MailDetailsPage from "./manage/MailDetailsPage";
import MailMonitoring from "./MailMonitoring";

const MailsManage = (): ReactElement => {
 const intl = useIntl();
 const theme = useTheme();
 const dispatch = useAppDispatch();
 const { socket } = useContext(AppContext);
 const location = useLocation();

 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));
 const params = useParams<{ mail_id: string; section: string }>();

 const maildetails = useSelector(getMailsList).find(
  (element) => element.id === Number(params.mail_id)
 );
 const ispList = useSelector(getIspList);

 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [reloadData, setReloadData] = useState<boolean>(false);

 useEffect(() => {
  (async () => {
   await dispatch(getAllIspNotPaged("mailserver"));
   await dispatch(getAllMailsDomain(0, 10, 0, "", params.mail_id));
   setIsLoading(false);
   await dispatch(postSyncronizeSocket("maildomain"));
  })();
 }, []);

 useEffect(() => {
  !isNil(socket) &&
   socket.on("app_data", (value): void => {
    if (value?.completed && value?.section === "mail") {
     setReloadData(true);
    }
   });
  return () => {
   !isNil(socket) && socket.off("app_data");
  };
 }, [socket]);

 useEffect(() => {
  (async () => {
   if (reloadData) {
    dispatch(getAllMailsDomain(0, 10, 0, "", params.mail_id));
    setReloadData(false);
   }
  })();
 }, [reloadData]);

 const RenderMailManageSection = (): ReactElement => {
  switch (params.section) {
   case "details":
    return <MailDetailsPage />;
   case "backup":
    return <MailBackup />;
   default:
    return <></>;
  }
 };

 return !isLoading ? (
  <Container maxWidth="xl">
   <Stack pt={1}>
    <SectionsBreadCrumbs
     links={[
      { name: "home", url: "/" },
      { name: "apps", url: "/app/sites" },
      { name: "mail", url: "/app/mail" },
      { name: params?.section || "", url: location.pathname }
     ]}
    />
   </Stack>
   <Grid container>
    {desktopViewPort ? (
     <Grid xs={desktopViewPort ? 3 : 0}>
      <Stack mt={6}>
       <SectionMenu
        menuList={mailsLinks.map((item) => ({
         name: item.name,
         url: `/app/mail/${params?.mail_id}/${item.url}`,
         icon: item.icon
        }))}
       />
      </Stack>
     </Grid>
    ) : (
     <MobileSectionMenu links={mailsLinks} disabled={[]} url={`/app/mail/${params?.mail_id}`} />
    )}
    <Grid xs={desktopViewPort ? 9 : 12} mt={3}>
     <Stack mt={5} justifyContent="space-between" direction={desktopViewPort ? "row" : "column"}>
      <Stack>
       <Stack spacing={1} alignItems="flex-start">
        <Typography fontWeight="bold" fontSize={16} ml={1} textTransform="uppercase">
         {maildetails?.domain}
        </Typography>
        <Stack direction="row" spacing={1}>
         <Tooltip title={intl.formatMessage({ id: "sites.details.goToServer" })}>
          <NavLink
           to={`/servers/manage/${ispList.find((element) => element.id === maildetails?.isp_id)
            ?.server_id}/specification`}
           style={{
            textDecoration: "none"
           }}>
           <Stack direction="row" spacing={1} sx={{ cursor: "pointer" }}>
            <IconSelector icon="ServerIcon" props={{ fontSize: "small", color: "primary" }} />
            <Typography variant="subtitle2" color="primary">
             {ispList.find((element) => element.id === maildetails?.isp_id)?.hostname}
            </Typography>
           </Stack>
          </NavLink>
         </Tooltip>
         <Typography>
          {ispList.find((element) => element.id === maildetails?.isp_id)?.ip1}
         </Typography>
        </Stack>
       </Stack>
      </Stack>
     </Stack>
     <MailMonitoring />
     <RenderMailManageSection />
    </Grid>
   </Grid>
  </Container>
 ) : (
  <></>
 );
};

export default MailsManage;
