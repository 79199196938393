import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";

import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";

import { getFirewallsDataset } from "redux/selectors/firewallSelector";

import CreateRule from "../actions/CreateRule";
import DeleteRule from "../actions/DeleteRule";
import UpdateRule from "../actions/UpdateRule";

const InboundRules = (): ReactElement => {
 const intl = useIntl();
 const params = useParams<{ id: string; section: string }>();
 const firewallData = useSelector(getFirewallsDataset).find(
  (element) => element.id === Number(params.id)
 );

 const [creationOngoing, setCreationOngoing] = useState<boolean>(false);
 const [creationInProgress, setCreationInProgress] = useState<boolean>(false);
 const [updatingRow, setUpdatingRow] = useState<number>(-1);

 useEffect(() => {
  setCreationInProgress(false);
  setUpdatingRow(-1);
 }, [firewallData]);

 return (
  <Paper elevation={0} sx={{ mt: 1, borderRadius: "10px", boxShadow: 0 }}>
   <Stack alignItems="center" justifyContent="flex-start" direction="row" px={2} pt={1}>
    <IconSelector icon="KeyIcon" props={{ fontSize: "medium", color: "secondary" }} />
    <Typography variant="h6" component="h2" ml={1}>
     <FormattedMessage id="addons.firewall.inboundRules" />{" "}
    </Typography>
   </Stack>

   <Stack spacing={2} padding={2}>
    {firewallData?.rules
     .filter((element) => element.direction === "in")
     .map((rule, index) => {
      return updatingRow === index ? (
       <UpdateRule
        id={firewallData?.id || 0}
        rule_id={rule.id}
        oldPort={rule.port}
        oldIp={rule.source_ips}
        oldProtocol={rule.protocol}
        direction="in"
        closeUpdate={() => setUpdatingRow(-1)}
       />
      ) : (
       <Stack
        key={`inbound-rule-${index}`}
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        sx={{ borderRadius: 2, border: "2px solid lightgrey", p: 2 }}>
        <Stack alignItems="flex-start" width="50%">
         <Typography variant="kxColoredSmall" fontWeight="bold">
          IP
         </Typography>
         <Stack direction="row" flexWrap="wrap">
          {rule.source_ips.map((ip, key) => {
           return (
            <Chip
             key={`ip-rule-${key}`}
             size="small"
             label={ip === "0.0.0.0/0" ? "AnyIPv4" : ip === "::/0" ? "AnyIPv6" : ip}
             sx={{ mr: 0.5, mt: 0.5 }}
            />
           );
          })}
         </Stack>
        </Stack>
        <Stack alignItems="flex-start" width="20%">
         <Typography variant="kxColoredSmall" fontWeight="bold">
          <FormattedMessage id="addons.firewall.protocol" />
         </Typography>
         <Typography>{rule.protocol.toUpperCase()}</Typography>
        </Stack>
        <Stack alignItems="flex-start" width="20%">
         <Typography variant="kxColoredSmall" fontWeight="bold">
          <FormattedMessage id="addons.firewall.port" />
         </Typography>
         <Typography>{rule.port || "No port"}</Typography>
        </Stack>
        <Stack alignItems="flex-start" width="10%" direction={"row"}>
         <Tooltip title={intl.formatMessage({ id: "app.update" })}>
          <IconButton onClick={() => setUpdatingRow(index)}>
           <ModeEditOutlineRoundedIcon />
          </IconButton>
         </Tooltip>
         <DeleteRule id={firewallData?.id || 0} rule_id={rule.id} />
        </Stack>
       </Stack>
      );
     })}
    {creationInProgress && (
     <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
       borderRadius: 2,
       border: "2px dotted lightgrey",
       p: 2
      }}>
      <CircularProgress />
     </Stack>
    )}
    {creationOngoing ? (
     <CreateRule
      id={firewallData?.id || 0}
      direction="in"
      closeCreation={() => setCreationOngoing(false)}
      confirmCreation={() => {
       setCreationInProgress(true);
       setCreationOngoing(false);
      }}
     />
    ) : (
     <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      onClick={() => setCreationOngoing(true)}
      sx={{
       borderRadius: 2,
       border: "2px dotted lightgrey",
       p: 2,
       cursor: "pointer",
       "&:hover": {
        backgroundColor: "lightgray",
        border: "2px solid lightgray",
        transition: "500ms"
       }
      }}>
      <IconSelector icon="AddIcon" />
      <FormattedMessage id="addons.firewall.addRule" />
     </Stack>
    )}
   </Stack>
  </Paper>
 );
};

export default InboundRules;
