import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const BackupIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <path
   d="M10.0049 14.1058V7.87471M10.0049 7.87471L12.7743 10.6441M10.0049 7.87471L7.23558 10.6441M5.15856 16.8751C4.1703 16.8762 3.21402 16.5249 2.46148 15.8843C1.70893 15.2438 1.20941 14.3559 1.05262 13.3801C0.895833 12.4044 1.09205 11.4047 1.60604 10.5606C2.12003 9.71652 2.91811 9.08333 3.85696 8.77475C3.61575 7.53885 3.86504 6.25759 4.55205 5.20229C5.23906 4.14699 6.30984 3.40052 7.53764 3.12096C8.76543 2.8414 10.0538 3.05069 11.13 3.70452C12.2062 4.35836 12.9856 5.40538 13.3032 6.62388C13.7944 6.46416 14.3203 6.44494 14.8218 6.5684C15.3233 6.69185 15.7802 6.95307 16.141 7.32253C16.5018 7.692 16.7522 8.155 16.8637 8.65923C16.9753 9.16347 16.9436 9.68885 16.7723 10.176C17.528 10.4647 18.1589 11.0088 18.5554 11.7139C18.9519 12.419 19.0891 13.2407 18.9432 14.0364C18.7972 14.8321 18.3774 15.5516 17.7564 16.0701C17.1355 16.5886 16.3526 16.8734 15.5437 16.8751H5.15856Z"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
 </svg>,
 "Backup"
);
