import React, { ReactElement } from "react";
import { NavLink } from "react-router-dom";

import Breadcrumbs from "@mui/material/Breadcrumbs";

type Props = {
 links: { name: string; url: string }[];
};

const SectionsBreadCrumbs = ({ links }: Props): ReactElement => {
 return (
  <Breadcrumbs aria-label="servers">
   {links.map((link, index) => (
    <NavLink
     key={`breadcrumbs-link-${index}`}
     to={link.url}
     style={{
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
      color: "#3b3838"
     }}>
     {link.name}
    </NavLink>
   ))}
  </Breadcrumbs>
 );
};

export default SectionsBreadCrumbs;
