import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";

import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";

import IconSelector from "components/shared/images/IconSelector";

import { postServiceChangeAutorenew } from "redux/handlers/servicesHandler";

import { useAppDispatch } from "hooks/reduxHook";

const AutoRenew = ({
 id,
 startCheck,
 type
}: {
 id: number;
 startCheck: boolean;
 type: string;
}): ReactElement => {
 const dispatch = useAppDispatch();

 const [check, setCheck] = useState<boolean>(startCheck);

 const handleChangeAutorenew = async () => {
  setCheck(!check);
  await dispatch(postServiceChangeAutorenew(id));
 };

 return type === "domain" ? (
  <Stack direction="row" spacing={1} alignItems="center" sx={{ cursor: "pointer" }}>
   <FormControlLabel
    control={<Switch checked={check} onChange={() => handleChangeAutorenew()} />}
    label={<FormattedMessage id="app.autorenew" />}
   />
   <Tooltip title={<FormattedMessage id="domain.autorenewMessage" />} placement="top">
    <Stack>
     <IconSelector icon="InfoIcon" props={{ color: "info" }} />
    </Stack>
   </Tooltip>
  </Stack>
 ) : (
  <FormControlLabel
   control={<Switch checked={check} onChange={() => handleChangeAutorenew()} />}
   label={<FormattedMessage id="app.autorenew" />}
  />
 );
};

export default AutoRenew;
