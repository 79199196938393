import React, { ReactElement, useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { Appearance, loadStripe, StripeElementsOptions } from "@stripe/stripe-js";

import LinearProgress from "@mui/material/LinearProgress";

import { postSetupStripePayment } from "redux/handlers/rechargeHandler";

import { useAppDispatch } from "hooks/reduxHook";

import CheckoutForm from "./CheckoutForm";
import PaymentConfirm from "./PaymentConfirm";

const stripePromise = loadStripe(
 "pk_live_51MEWQUKrjdNay1vwz2fW1P40jYqzGAeiGQHm2ToIamZqX3dxSGfV86YlfIqzL77JsIxPDkAG0tTbEbWb5oCLwh8x00gCdh5sIg"
);

const Stripe = ({
 amount,
 request_amount
}: {
 amount: number;
 request_amount: number;
}): ReactElement => {
 const dispatch = useAppDispatch();

 const [clientSecret, setClientSecret] = useState<string>("");
 const [redirected, setRedirected] = useState<boolean>(false);

 useEffect(() => {
  (async () => {
   const redirectStatus = new URLSearchParams(window.location.search).get("redirect_status");

   if (!redirectStatus) {
    setClientSecret(await dispatch(postSetupStripePayment(amount * 100)));
   } else {
    setRedirected(true);
   }
  })();
 }, []);

 const appearance: Appearance = {
  theme: "stripe"
 };

 const options: StripeElementsOptions = {
  clientSecret,
  appearance
 };

 return clientSecret ? (
  redirected ? (
   <Elements options={options} stripe={stripePromise}>
    <PaymentConfirm />
   </Elements>
  ) : (
   <Elements options={options} stripe={stripePromise}>
    <CheckoutForm amount={amount} request_amount={request_amount} />
   </Elements>
  )
 ) : (
  <LinearProgress />
 );
};

export default Stripe;
