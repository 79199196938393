import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { isNil } from "ramda";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import {
 editSiteMode,
 editSiteName,
 editSiteQuota,
 editSiteTraffic,
 editSiteVersion
} from "redux/handlers/websitesHandler";

import { getWebsitesDetails } from "redux/selectors/websitesSelector";

import { useAppDispatch } from "hooks/reduxHook";

type siteData = {
 site: string;
 php_version: string;
 php_mode: string;
 hd_quota: number;
 traffic_quota: number;
};

const EditSiteDetails = ({
 section,
 title,
 tooltip,
 phpVers,
 phpTypes
}: {
 section: string;
 title: string;
 tooltip: string;
 phpVers?: Array<string>;
 phpTypes?: Array<string>;
}): ReactElement => {
 const websiteDetails = useSelector(getWebsitesDetails);
 const dispatch = useAppDispatch();
 const intl = useIntl();
 const params = useParams<{ domain_id: string; section: string }>();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const ispId = websiteDetails.isp_id;
 const domainId = websiteDetails.domain_id;

 const UpdateValues = async (data: siteData) => {
  switch (section) {
   case "site":
    dispatch(editSiteName("rename_site", ispId, domainId, data.site, Number(params.domain_id)));
    break;
   case "php_version":
    dispatch(
     editSiteVersion(
      "php_update_version",
      ispId,
      domainId,
      data.php_version,
      Number(params.domain_id)
     )
    );
    break;
   case "php_mode":
    dispatch(editSiteMode("php_update", ispId, domainId, data.php_mode, Number(params.domain_id)));
    break;
   case "hd_quota":
    dispatch(editSiteQuota("hd_quota", ispId, domainId, data.hd_quota, Number(params.domain_id)));
    break;
   case "traffic_quota":
    dispatch(
     editSiteTraffic("traffic_quota", ispId, domainId, data.traffic_quota, Number(params.domain_id))
    );
    break;
   default:
    dispatch(editSiteName("rename_site", ispId, domainId, data.site, Number(params.domain_id)));
    break;
  }
 };

 const RenderSiteMessage = () => {
  switch (section) {
   case "site":
    return (
     <Alert severity="info">
      <FormattedMessage id="sites.editNameMessage" />
     </Alert>
    );
   case "hd_quota":
    return (
     <Alert severity="info">
      <FormattedMessage id="sites.editHdQuotaMessage" />
     </Alert>
    );
   case "traffic_quota":
    return (
     <Alert severity="info">
      <FormattedMessage id="sites.editTrafficQuotaMessage" />
     </Alert>
    );
   default:
    return <></>;
  }
 };

 const onSubmit: SubmitHandler<siteData> = async (data: siteData) => {
  setIsLoading(true);
  UpdateValues(data);
  setIsLoading(false);
  handleClose();
 };

 const { control, formState, getValues, setValue, watch, handleSubmit } = useForm({
  defaultValues: {
   site: websiteDetails?.site,
   php_version: websiteDetails?.php_version,
   php_mode: websiteDetails?.php,
   hd_quota: websiteDetails?.hd_quota,
   traffic_quota: websiteDetails?.traffic_quota
  }
 });

 const renderData = () => {
  switch (section) {
   case "site":
    return websiteDetails.site;
   case "php_version":
    return websiteDetails.php_version;
   case "php_mode":
    return websiteDetails.php;
   case "hd_quota":
    return websiteDetails.hd_quota === -1 ? (
     <FormattedMessage id="sites.quotaUnlimited" />
    ) : (
     `${websiteDetails.hd_quota} MB`
    );
   case "traffic_quota":
    return websiteDetails.traffic_quota === -1 ? (
     <FormattedMessage id="sites.quotaUnlimited" />
    ) : (
     `${websiteDetails.traffic_quota} MB`
    );
   default:
    return websiteDetails.site;
  }
 };

 const RenderModal = (): ReactElement => {
  switch (section) {
   case "site":
    return (
     <Stack justifyContent="center" alignContent="center" direction="column">
      <Controller
       name={"site"}
       control={control}
       rules={{ required: true }}
       render={({ field }) => (
        <TextField
         {...field}
         autoFocus
         label={<FormattedMessage id="sites.site" />}
         error={formState.isDirty && !!formState?.errors?.site}
         autoComplete="off"
        />
       )}
      />
     </Stack>
    );
   case "php_version":
    return (
     <Stack justifyContent="center" alignContent="center" direction="column">
      <Controller
       name="php_version"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <Autocomplete
         fullWidth={true}
         autoHighlight
         sx={{ my: 2 }}
         onChange={(e, value) =>
          isNil(value) ? setValue("php_version", "") : setValue("php_version", value)
         }
         options={phpVers || []}
         value={phpVers?.find((version) => getValues("php_version").includes(version))}
         renderInput={(params) => (
          <TextField
           {...params}
           {...field}
           label={<FormattedMessage id="sites.phpVersion" />}
           error={formState.isDirty && !!formState?.errors?.php_version}
           InputLabelProps={{ shrink: true }}
          />
         )}
        />
       )}
      />
     </Stack>
    );
   case "php_mode":
    return (
     <Stack justifyContent="center" alignContent="center" direction="column">
      <Controller
       name="php_mode"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <Autocomplete
         fullWidth={true}
         autoHighlight
         sx={{ my: 2 }}
         onChange={(e, value) =>
          isNil(value) ? setValue("php_mode", "") : setValue("php_mode", value)
         }
         options={phpTypes || []}
         value={phpTypes?.find(
          (version) => getValues("php_mode").toLowerCase() === version.toLowerCase()
         )}
         renderInput={(params) => (
          <TextField
           {...params}
           {...field}
           label={<FormattedMessage id="sites.phpMode" />}
           error={formState.isDirty && !!formState?.errors?.php_mode}
           InputLabelProps={{ shrink: true }}
          />
         )}
        />
       )}
      />
     </Stack>
    );
   case "hd_quota":
    return (
     <Stack justifyContent="center" alignContent="center" direction="column">
      <Controller
       name={"hd_quota"}
       control={control}
       rules={{
        required: true,
        validate: () => Number(watch("hd_quota")) !== 0 && Number(watch("hd_quota")) >= -1
       }}
       render={({ field }) => (
        <TextField
         {...field}
         autoFocus
         label={<FormattedMessage id="sites.hdQuota" />}
         error={formState.isDirty && !!formState?.errors?.hd_quota}
         autoComplete="off"
         type="number"
        />
       )}
      />
     </Stack>
    );
   default:
    return (
     <Stack justifyContent="center" alignContent="center" direction="column" spacing={2}>
      <Controller
       name={"traffic_quota"}
       control={control}
       rules={{ required: true }}
       render={({ field }) => (
        <TextField
         {...field}
         autoFocus
         label={<FormattedMessage id="sites.trafficQuota" />}
         error={formState.isDirty && !!formState?.errors?.traffic_quota}
         autoComplete="off"
         type="number"
        />
       )}
      />
     </Stack>
    );
  }
 };

 return (
  <Stack
   direction="row"
   alignItems="center"
   justifyContent="space-between"
   spacing={2}
   py={0.5}
   pr={2}
   textAlign="start">
   <Stack direction="row" alignItems="center">
    <Typography fontWeight="bold" textAlign="start" variant="subtitle1" px={2} width={100}>
     <FormattedMessage id={title} />
    </Typography>
    <Typography variant="body1" px={2} minWidth={100} noWrap>
     {renderData()}
    </Typography>
   </Stack>
   <Tooltip title={<FormattedMessage id={tooltip} />}>
    <IconButton onClick={handleOpen}>
     <IconSelector icon="EditIcon" />
    </IconButton>
   </Tooltip>
   <AppModal
    open={openModal}
    close={handleClose}
    loading={isLoading}
    title={intl.formatMessage({ id: "sites.operations" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={Number(watch("hd_quota")) === 0 || Number(watch("hd_quota")) < -1}>
    <Stack spacing={2}>
     <RenderSiteMessage />
     <RenderModal />
    </Stack>
   </AppModal>
  </Stack>
 );
};

export default EditSiteDetails;
