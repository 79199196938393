import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const DetailsIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <path
   d="M2 15.5H18C18 16.0523 17.5523 16.5 17 16.5H3C2.44772 16.5 2 16.0523 2 15.5Z"
   fill="currentColor"
  />
  <path
   d="M3 5.5C3 4.94772 3.44772 4.5 4 4.5H16C16.5523 4.5 17 4.94772 17 5.5V14.5H3V5.5Z"
   stroke="currentColor"
   strokeWidth="1"
  />
 </svg>,
 "Details"
);
