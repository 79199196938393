import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postDeleteHost } from "redux/handlers/hostHandler";

import { useAppDispatch } from "hooks/reduxHook";

const DeleteHost = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleSubmit = async () => {
  setIsLoading(true);
  await dispatch(postDeleteHost(id));
  setIsLoading(false);
  setOpenModal(false);
 };

 return (
  <>
   <Typography onClick={handleOpen}>
    <FormattedMessage id="host.deleteHost" />
   </Typography>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    handleConfirm={handleSubmit}
    title={intl.formatMessage({ id: "host.deleteHost" })}>
    <FormattedMessage id="host.deleteHostMessage" />
   </AppModal>
  </>
 );
};

export default DeleteHost;
