import { formatNetworkGateway } from "helpers/addons";
import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import CloudboxReducer from "redux/reducers/cloudboxReducer";
import FirewallReducer from "redux/reducers/firewallReducer";
import FloatingIpReducer from "redux/reducers/floatingIpReducer";
import LoadBalancerDetailsReducer from "redux/reducers/loadBalancerDetailsReducer";
import LoadBalancerReducer from "redux/reducers/loadBalancerReducer";
import NetworksReducer from "redux/reducers/networksReducer";
import notificationReducer from "redux/reducers/notificationReducer";
import SnapshotsReducer from "redux/reducers/snapshotsReducer";
import VolumesReducer from "redux/reducers/volumesReducer";

import {
 IAllAddonsAPI,
 IAllBalancers,
 ICloudboxApi,
 ICloudboxPrice,
 ICloudDirectoryAPI,
 IFirewallApi,
 IFloatingIpApi,
 ILoadBalancerAPI,
 ILoadBalancerDataset,
 ILoadBalancerType,
 IMetricsAPI,
 INetworkApi,
 InternalTimeSeries,
 ISnapshotDataset,
 ISnapshotsApi,
 IUserNetwork,
 IVolumesApi
} from "types/api/addonsApiInterface";
import { IServerFromBackupData } from "types/api/backupApiInterface";
import { ICertificateAPI } from "types/api/certificatesApiInterface";

import { ApiService } from "service/ApiService";

export const getAllAddons = (): AppAction<Promise<IAllAddonsAPI | null>> => async (dispatch) => {
 try {
  const { data } = await ApiService<IAllAddonsAPI>("/server/addonslist");
  return data;
 } catch (error) {
  console.warn("FAILED TO GET ADDONS");
  return null;
 }
};

export const getAllCloudboxes =
 (
  currentIndex: number,
  sizePerPage: number,
  q?: string,
  userid?: string,
  id?: number
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<ICloudboxApi>(
    `/cloudbox/getcloudboxes?page=${currentIndex}&limit=${sizePerPage}&q=${q || ""}&partner=${
     userid || ""
    }&id=${id || 0}`
   );
   dispatch(CloudboxReducer.actions.setCloudboxes(data || {}));
  } catch (error) {
   console.warn("FAILED TO GET CLOUDBOXES");
  }
 };

export const getCloudboxPricing =
 (): AppAction<Promise<Array<ICloudboxPrice>>> => async (dispatch) => {
  try {
   const { data } = await ApiService<Array<ICloudboxPrice>>("/cloudbox/getprices");
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get prices - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const postChangeCloudboxPartner =
 (id: number, userid: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/cloudbox/assignuser", "POST", {
    id: id,
    userid: userid
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Changing cloudbox partner",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to change cloudbox partner - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdatecloudbox =
 (
  id: number,
  name: string,
  samba: boolean,
  webdav: boolean,
  ssh: boolean,
  extern: boolean,
  zfs: boolean
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/cloudbox/updatecloudbox", "POST", {
    id: id,
    name: name,
    samba: samba,
    webdav: webdav,
    ssh: ssh,
    external_rechability: extern,
    zfs: zfs
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Changing cloudbox data",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to change cloudbox data - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpgradeCloudbox =
 (id: number, product: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/cloudbox/upgrade", "POST", {
    id: id,
    product: product
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Upgrading cloudbox",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to upgrade cloudbox - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateCloudboxPassword =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/cloudbox/updatepassword", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Changing cloudbox password",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to change cloudbox password - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCompleteCloudboxCreation =
 (id: number, cloud_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/cloudbox/completecreation", "POST", {
    id: id,
    cloudbox_id: cloud_id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Updating cloudbox status",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update cloudbox status - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCompleteCloudboxUpgrade =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/cloudbox/completeupgrade", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Setting upgrade succesfull",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to set succesfull upgrade - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postAddCloudbox =
 (name: string, product: string, location: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/cloudbox/create", "POST", {
    name: name,
    product: product,
    location: location
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating new cloudbox",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create new cloudbox - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postRebuildCloudboxFromSnapshot =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/cloudbox/rebuild", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Rebuilding cloudbox from snapshot",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to rebuild cloudbox from snapshot - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteCloudboxSnapshot =
 (id: number, snap_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/snapshotcloudbox/delete", "POST", {
    id: id,
    snapshot_id: snap_id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting cloudbox snapshot",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete cloudbox snapshot - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postAddCloudboxSnapshot =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/snapshotcloudbox/create", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating cloudbox snapshot",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create cloudbox snapshot - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateCloudboxSnapshotSchedulation =
 (
  id: number,
  snap_id: number,
  status: string,
  day_of_week: number | null,
  day_of_month: number | null
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/snapshotcloudbox/updateplan", "POST", {
    id: id,
    snapshot_id: snap_id,
    status: status,
    minute: 0,
    hour: 0,
    day_of_week: day_of_week,
    day_of_month: day_of_month
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Updating cloudbox snapshot plan",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update cloudbox snapshot plan - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateCloudboxSettings = (
 id: number,
 webdav: boolean,
 samba: boolean,
 ssh: boolean,
 extReach: boolean
) => {
 console.log(id, webdav, samba, ssh, extReach);
};

export const getAllFirewall =
 (
  currentIndex: number,
  sizePerPage: number,
  q?: string,
  userid?: string,
  id?: number
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IFirewallApi>(
    `/firewall/getfirewalls?page=${currentIndex}&limit=${sizePerPage}&q=${q || ""}&partner=${
     userid || ""
    }&id=${id || 0}`
   );
   dispatch(FirewallReducer.actions.setFirewalls(data || {}));
  } catch (error) {
   console.warn("FAILED TO GET CLOUDBOXES");
  }
 };

export const getFirewallPrice = (): AppAction<Promise<number>> => async (dispatch) => {
 try {
  const { data } = await ApiService<number>("/firewall/price");
  return data;
 } catch (error: any) {
  dispatch(
   notificationReducer.actions.addAlert({
    id: generateUniqueId(),
    message: `Failed to get firewalls price - ${error?.message || "unknown error"}`,
    timestamp: Date.now(),
    type: "error"
   })
  );
  return 0.5;
 }
};

export const postAddFirewall =
 (name: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/firewall/create", "POST", {
    name: name
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating firewall...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create firewall - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateFirewallName =
 (id: number, name: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/firewall/update", "POST", {
    id: id,
    name: name
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Updating firewall...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update firewall - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteFirewall =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/firewall/delete", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting firewall...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete firewall - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateFirewallRule =
 (
  id: number,
  rule_id: number,
  direction: string,
  port: string,
  protocol: string,
  source_ips: Array<string>
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/firewall/updaterule", "POST", {
    id: id,
    rule_id: rule_id,
    direction: direction,
    port: port,
    protocol: protocol,
    source_ips: source_ips
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Updating rule in firewall...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update rule in firewall - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteFirewallRule =
 (id: number, rule_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/firewall/removerule", "POST", {
    id: id,
    rule_id: rule_id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting firewall rule...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete firewall rule - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCreateFirewallRule =
 (
  id: number,
  direction: string,
  port: string,
  protocol: string,
  source_ips: Array<string>
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/firewall/addrule", "POST", {
    id: id,
    direction: direction,
    port: port,
    protocol: protocol,
    source_ips: source_ips
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating rule in firewall...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create rule in firewall - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDetachServerToFirewall =
 (id: number, applied_to_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/firewall/removefromserver", "POST", {
    id: id,
    applied_to_id: applied_to_id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Detaching server to firewall...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to detach server to firewall - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postAttachServerToFirewall =
 (id: number, server_id_db: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/firewall/applytoserver", "POST", {
    id: id,
    server_id_db: server_id_db
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Attaching server to firewall...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to attach server to firewall - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCheckFirewallName =
 (name: string): AppAction<Promise<boolean>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/firewall/checkname", "POST", {
    name: name
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to check firewall name - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return false;
  }
 };

export const getAllFloatingIp =
 (
  currentIndex: number,
  sizePerPage: number,
  q?: string,
  userid?: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IFloatingIpApi>(
    `/floatingip/getfloatingips?page=${currentIndex}&limit=${sizePerPage}&q=${q || ""}&partner=${
     userid || ""
    }`
   );
   dispatch(FloatingIpReducer.actions.setFloatingIp(data || {}));
  } catch (error) {
   console.warn("FAILED TO GET FLOATING IP");
  }
 };

export const postDeleteFloatingIp =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/floatingip/delete", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting Floating IP",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete Floating IP - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getFloatingIpPrice = (): AppAction<Promise<number>> => async (dispatch) => {
 try {
  const { data } = await ApiService<number>("/floatingip/price");
  return data;
 } catch (error: any) {
  dispatch(
   notificationReducer.actions.addAlert({
    id: generateUniqueId(),
    message: `Failed to check Floating IP price - ${error?.message || "unknown error"}`,
    timestamp: Date.now(),
    type: "error"
   })
  );
  return 0;
 }
};

export const postChangeFloatingIpAutorenew = (id: number, autorenew: boolean) => {
 console.log(id, autorenew);
};

export const postChangeFloatingIpProtection =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/floatingip/changeprotection", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Changing Floating IP protection",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed change Floating IP protection - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postAddFloatingIp =
 (name: string, type: string, location: string, server_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/floatingip/create", "POST", {
    name: name,
    type: type,
    home_location: location,
    description: "No description",
    server_id: server_id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating Floating IP",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create Floating IP - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUnlinkFloatingIp =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/floatingip/unassign", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Detaching Floating IP",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to detach Floating IP - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateFloatingIp =
 (id: number, name: string, description: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/floatingip/update", "POST", {
    id: id,
    name: name,
    description: description
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Changing Floating IP",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed change Floating IP - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateFloatingRdns =
 (id: number, dns: string, ip: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/floatingip/chandednsptr", "POST", {
    id: id,
    dns_ptr: dns,
    ip: ip
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Changing Floating IP rdns",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed change Floating IP rdns - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postLinkServerToFloating =
 (id: number, server_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/floatingip/assign", "POST", {
    id: id,
    server_id: server_id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Linking Floating IP",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to link Floating IP - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getAllNetworks =
 (
  currentIndex: number,
  sizePerPage: number,
  q?: string,
  userid?: string,
  id?: number
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<INetworkApi>(
    `/network/getnetworks?page=${currentIndex}&limit=${sizePerPage}&q=${q || ""}&partner=${
     userid || ""
    }&id=${id || 0}`
   );
   dispatch(NetworksReducer.actions.setNetworks(data || {}));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get networks - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getAllUserNetworks =
 (): AppAction<Promise<Array<IUserNetwork>>> => async (dispatch) => {
  try {
   const { data } = await ApiService<Array<IUserNetwork>>("/network/getusernetworks");
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get networks - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const getNetworkPrice = (): AppAction<Promise<number>> => async (dispatch) => {
 try {
  const { data } = await ApiService<number>("/network/price");
  return data;
 } catch (error: any) {
  dispatch(
   notificationReducer.actions.addAlert({
    id: generateUniqueId(),
    message: `Failed to get networks price - ${error?.message || "unknown error"}`,
    timestamp: Date.now(),
    type: "error"
   })
  );
  return 0.5;
 }
};

export const postChangeNetworkAutorenew = (id: number, autorenew: boolean) => {
 console.log(id, autorenew);
};

export const postChangeNetworkProtection =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/network/changeprotection", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Changing network protection",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to change network protection - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateNetwork =
 (id: number, name: string, expose: boolean): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/network/update", "POST", {
    id: id,
    name: name,
    expose_routes_to_vswitch: expose
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Updating network",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update network - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteRouteFromNetwork =
 (id: number, route_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/network/deleteroute", "POST", {
    id: id,
    route_id: route_id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting route...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete route - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteSubnetFromNetwork =
 (id: number, subnet_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/network/deletesubnet", "POST", {
    id: id,
    subnet_id: subnet_id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting subnet...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete subnet - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCreateSubnetForNetwork =
 (id: number, ip: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/network/addsubnet", "POST", {
    id: id,
    ip_range: `${ip}/24`,
    network_zone: "eu-central",
    type: "cloud",
    gateway: formatNetworkGateway(ip)
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating subnet...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create subnet - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteNetwork =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/network/delete", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting network",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete network - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCreateRouteForNetwork =
 (id: number, server: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/network/addroute", "POST", {
    id: id,
    gateway: server,
    destination: "0.0.0.0/0"
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating route...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create route - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postLinkServerToNewtork =
 (id: number, server: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/server/attachtonetwork", "POST", {
    id: server,
    network_id_db: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Linking server to subnet...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to link server to subnet - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postLinkBalancerToNewtork =
 (id: number, balancer: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/loadbalancer/attachtonetwork", "POST", {
    id: balancer,
    network_id_db: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Linking balancer to subnet...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to link balancer to subnet - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUnlinkServerFromNewtork =
 (id: number, network: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/server/detachfromnetwork", "POST", {
    id: id,
    network: network
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Detaching server from subnet...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to detach server from subnet - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUnlinkBalancerFromNewtork =
 (id: number, network: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/loadbalancer/detachfromnetwork", "POST", {
    id: id,
    private_net_network: network
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Detaching balancer from subnet...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to detach balancer from subnet - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCheckNetworkName =
 (name: string): AppAction<Promise<boolean>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/network/checkname", "POST", {
    name: name
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to check network name - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return false;
  }
 };

export const postCheckNetworkIp =
 (name: string): AppAction<Promise<boolean>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/network/checkiprange", "POST", {
    ip_range: name
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to check network ip - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return false;
  }
 };

export const postAddNetwork =
 (name: string, ip_range: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/network/create", "POST", {
    name: name,
    ip_range: ip_range
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Adding network",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to add network - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getAllVolumes =
 (
  currentIndex: number,
  sizePerPage: number,
  q?: string,
  userid?: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IVolumesApi>(
    `/volume/getvolumes?page=${currentIndex}&limit=${sizePerPage}&q=${q || ""}&partner=${
     userid || ""
    }`
   );
   dispatch(VolumesReducer.actions.setVolumes(data || {}));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load volumes - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCheckVolumeName =
 (volume_name: string): AppAction<Promise<boolean>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/volume/checkvolumename", "POST", {
    volume_name: volume_name
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to check volume name - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return false;
  }
 };

export const postCheckVolumePrice =
 (size: number): AppAction<Promise<number>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<number>("/volume/calculateprice", "POST", {
    size: size
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to check volume price - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return 0;
  }
 };

export const postChangeVolumeAutorenew = (id: number, autorenew: boolean) => {
 console.log(id, autorenew);
};

export const postChangeVolumeProtection =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/volume/changeprotection", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Changing volume protection",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed change volume protection - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDetachVolume =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/volume/detachvolume", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Detaching volume",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to detach volume - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateVolumeName =
 (id: number, volume_name: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/volume/updatevolume", "POST", {
    id: id,
    name: volume_name
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Changing volume name",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to check volume name - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteVolume =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/volume/deletevolume", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting volume",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete volume - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postResizeVolume =
 (id: number, size: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/volume/resize", "POST", {
    id: id,
    size: size
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Changing volume size",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed change volume size - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postAddVolume =
 (
  name: string,
  format: string,
  location: string,
  size: number,
  server_id?: number
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } =
    server_id !== 0
     ? await ApiService<boolean>("/volume/addvolume", "POST", {
        name: name,
        format: format,
        location: location,
        size: size,
        server_id: server_id
       })
     : await ApiService<boolean>("/volume/addvolume", "POST", {
        name: name,
        format: format,
        location: location,
        size: size
       });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating volume",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create volume - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postLinkServerToVolume =
 (id: number, server_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/volume/attachvolume", "POST", {
    id: id,
    server_id: server_id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Linking volume",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to link volume - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postMountVolume =
 (id: number, directory: string, flag: boolean): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/volume/mount", "POST", {
    id: id,
    directory,
    flag
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Mounting volume on server",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to mount volume - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUnmountVolume =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/volume/unmount", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Unmounting volume from server",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to unmount volume - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getAllSnapshots =
 (
  currentIndex: number,
  sizePerPage: number,
  q?: string,
  userid?: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<ISnapshotsApi>(
    `/snapshot/allsnapshot?page=${currentIndex}&limit=${sizePerPage}&q=${q || ""}&partner=${
     userid || ""
    }`
   );
   dispatch(SnapshotsReducer.actions.setSnapshots(data || {}));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get snapshots - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getAllSnapshotsForServer =
 (server_id: number): AppAction<Promise<Array<ISnapshotDataset>>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<Array<ISnapshotDataset>>("/snapshot/serversnapshot", "POST", {
    server_id: server_id
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get snapshots - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const postCheckSnapshotPrice =
 (id: number): AppAction<Promise<number>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<number>("/server/snapshotprice", "POST", {
    id: id
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to check snapshot price - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return 0;
  }
 };

export const getServerSnapshotType =
 (id: number): AppAction<Promise<IServerFromBackupData | null>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IServerFromBackupData>("/snapshot/datafromserver", "POST", {
    id: id
   });
   return data;
  } catch (error) {
   console.warn("FAILED TO GET SNAPSHOT DATA");
   return null;
  }
 };

export const postChangeSnapshotAutorenew = (id: number, autorenew: boolean) => {
 console.log(id, autorenew);
};

export const postChangeSnapshotProtection =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/snapshot/changeprotection", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Changing snapshot protection",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to change snapshot protection - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postRebuildServerFromSnapshot =
 (id: number, snapshot_id_db: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/snapshot/rebuildserver", "POST", {
    id: id,
    snapshot_id_db: snapshot_id_db
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Changing snapshot protection",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to change snapshot protection - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateSnapshotName =
 (id: number, description: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/snapshot/changedescription", "POST", {
    id: id,
    description: description
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Changing snapshot name",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to change snapshot name - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteSnapshot =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/snapshot/deletesnapshot", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting snapshot",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete snapshot - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postAddSnapshot =
 (id: number, price: number, description?: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/snapshot/addsnapshot", "POST", {
    id: id,
    price: price,
    description: description || ""
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating snapshot",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create snapshot - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getAllLoadBalancers =
 (
  currentIndex: number,
  sizePerPage: number,
  q?: string,
  userid?: string,
  id?: number
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<ILoadBalancerAPI>(
    `/loadbalancer/getloadbalancers?page=${currentIndex}&limit=${sizePerPage}&q=${
     q || ""
    }&partner=${userid || ""}&id=${id || 0}`
   );
   dispatch(LoadBalancerReducer.actions.setLoadBalancer(data || {}));
  } catch (error) {
   console.warn("FAILED TO GET LOAD BALANCER");
  }
 };

export const getBalancersNotPages =
 (): AppAction<Promise<Array<IAllBalancers>>> => async (dispatch) => {
  try {
   const { data } = await ApiService<Array<IAllBalancers>>("/loadbalancer/list");
   return data;
  } catch (error) {
   console.warn("FAILED TO GET LOAD BALANCER");
   return [];
  }
 };

export const getSpecificLoadBalancer =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<ILoadBalancerDataset>(`/loadbalancer/${id}`);
   dispatch(LoadBalancerDetailsReducer.actions.setLoadBalancerDetails(data || {}));
  } catch (error) {
   console.warn("FAILED TO GET LOAD BALANCER");
  }
 };

export const getAllLoadBalancersPrices =
 (): AppAction<Promise<Array<ILoadBalancerType>>> => async (dispatch) => {
  try {
   const { data } = await ApiService<Array<ILoadBalancerType>>("/loadbalancertype/gettypes");
   return data;
  } catch (error) {
   console.warn("FAILED TO GET LOAD BALANCER");
   return [];
  }
 };

export const postDeleteLoadBalancer =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/loadbalancer/deleteloadbalancer", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting load balancer",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete load balancer - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCreateLoadBalancer =
 (
  name: string,
  algorithm: string,
  amount: number,
  selectedType: string,
  location_id: number
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/loadbalancer/createloadbalancer", "POST", {
    name: name,
    type: selectedType,
    amount: amount,
    algorithm: algorithm,
    location_id: location_id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating load balancer",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create load balancer - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCheckBalancerName =
 (name: string): AppAction<Promise<boolean>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/loadbalancer/checkname", "POST", {
    name: name
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to check load balancer name - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return false;
  }
 };

export const postUpdateLoadBalancerName =
 (id: number, name: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/loadbalancer/updateloadbalancer", "POST", {
    id: id,
    name: name
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Updating load balancer",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update load balancer - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateLoadBalancerAlgorithm =
 (id: number, type: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/loadbalancer/changealgorithm", "POST", {
    id: id,
    type: type
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Updating load balancer",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update load balancer - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postChangeLoadBalancerProtection =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/loadbalancer/changedeleteprotection", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Changing load balancer protection",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed change load balancer protection - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getCertificates = (): AppAction<Promise<ICertificateAPI>> => async (dispatch) => {
 try {
  const { data } = await ApiService<ICertificateAPI>("/certificate/getcertificates");
  return data;
 } catch (error) {
  console.warn("FAILED TO GET CERTIFICATES");
  return { dataset: [], totalCount: 0 };
 }
};

export const postCreateCertificate =
 (certificate: string, name: string, private_key: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/certificate/createcertificate", "POST", {
    certificate: certificate,
    name: name,
    private_key: private_key
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating new certificate",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed create certificate - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteCertificate =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/certificate/deletecertificate", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting certificate",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed delete certificate - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateCertificate =
 (id: number, name: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/certificate/updatecertificate", "POST", {
    id: id,
    name: name
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Updating certificate",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed update certificate - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateDnsPtr =
 (id: number, ip: string, newDns: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/loadbalancer/changednsptr", "POST", {
    id: id,
    ip: ip,
    dns_ptr: newDns
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Updating DNS PTR",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed update DNS PTR - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postAttachNetwork =
 (id: number, netId: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/loadbalancer/attachtonetwork", "POST", {
    id: id,
    network_id: netId
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Attaching load balancer to network",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed attach load balancer to network - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDetachNetwork =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/loadbalancer/detachfromnetwork", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Detaching load balancer from network",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed detach load balancer from network - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postChangePrivateNetwork =
 (id: number, value: boolean): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const endpoint = value
    ? "/loadbalancer/enablepublicinterface"
    : "/loadbalancer/disablepublicinterface";
   const { data } = await ApiService<boolean>(endpoint, "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Changing public network status",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to change public network status - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postRescaleBalancer =
 (id: number, rescale_id: number, price: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/loadbalancer/rescale", "POST", {
    id: id,
    rescale_id: rescale_id,
    amount: price
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Rescaling load balancer...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to rescale load balancer - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getBalancerMetrics =
 (
  id: number,
  type: keyof InternalTimeSeries,
  start: string,
  end: string
 ): AppAction<Promise<Array<Array<number | string>>>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IMetricsAPI>("/loadbalancer/getmetrics", "POST", {
    id: id,
    type: type === "bandwidth.in" || type === "bandwidth.out" ? "bandwidth" : type,
    start: start,
    end: end
   });
   return data.time_series[type]?.values || [];
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get metrics - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const postCreateService =
 (
  id: number,
  destination_port: number,
  listen_port: number,
  protocol: string,
  proxyprotocol: boolean,
  certificate_id?: number,
  redirect_http?: boolean
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/loadbalancer/addservice", "POST", {
    id: id,
    destination_port: destination_port,
    listen_port: listen_port,
    protocol: protocol,
    proxyprotocol: proxyprotocol,
    certificate_id_db: certificate_id,
    redirect_http: redirect_http
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating new service for load balancer",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create new service for load balancer - ${
      error?.message || "unknown error"
     }`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteService =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/loadbalancer/deleteloadbalancerservice", "POST", {
    load_balancer_service_id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting service from load balancer",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete service for load balancer - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCreateServerTarget =
 (id: number, server: number, private_ip: boolean): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/loadbalancer/addtarget", "POST", {
    id: id,
    type: "server",
    server_id: server,
    use_private_ip: private_ip
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating new target for load balancer",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create new target for load balancer - ${
      error?.message || "unknown error"
     }`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCreateIpTarget =
 (id: number, ip: string, private_ip: boolean): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/loadbalancer/addtarget", "POST", {
    id: id,
    type: "ip",
    ip: ip,
    use_private_ip: private_ip
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating new target for load balancer",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create new target for load balancer - ${
      error?.message || "unknown error"
     }`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteTarget =
 (id: number, type: string, server_id?: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/loadbalancer/removetarget", "POST", {
    target_id: id,
    type: type,
    server_id: server_id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting target from load balancer",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete target for load balancer - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getCloudboxConnection =
 (cloudbox_id_db: number): AppAction<Promise<Array<ICloudDirectoryAPI>>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<Array<ICloudDirectoryAPI>>(
    "/cloudbox/getdirectories",
    "POST",
    {
     cloudbox_id_db
    }
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get metrics - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const postLinkServerToCloudbox =
 (
  id: number,
  cloudbox_id_db: number,
  folder: string,
  delete_folder: boolean
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/server/installcloudbox", "POST", {
    id,
    cloudbox_id_db,
    folder,
    delete_folder
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Linking server to cloudbox",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to link server to cloudbox - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postRemoveServerFromCloudbox =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/server/removecloudbox", "POST", {
    id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Removing server from cloudbox",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to remove server from cloudbox - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };
