import React, { ReactElement, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate, useNavigation } from "react-router-dom";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { emailValidation } from "constants/regexp";

import { postLogin } from "redux/handlers/userHandler";

import { getUserAuthenticated, getUserLanguage } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

interface IFormInput {
 email: string;
 password: string;
}

const Login = (): ReactElement => {
 const intl = useIntl();
 const navigate = useNavigate();
 const navigation = useNavigation();
 const dispatch = useAppDispatch();

 const isAuthenticated: boolean = useSelector(getUserAuthenticated);
 const language: string = useSelector(getUserLanguage);

 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [loginError, setLoginError] = useState<boolean>(false);
 const [showPassword, setShowPassword] = useState<string>("password");

 const { control, handleSubmit, formState } = useForm({
  defaultValues: {
   email: "",
   password: ""
  }
 });

 const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
  setIsLoading(true);
  navigation.state = "loading";
  const isLogged = await dispatch(postLogin(data.email, data.password));
  if (!isLogged) {
   setLoginError(true);
   setIsLoading(false);
  } else {
   localStorage.setItem("language", language);
   setIsLoading(false);
   setLoginError(false);
  }
 };

 useEffect(() => {
  if (isAuthenticated) {
   navigate("/domains");
  }
 }, [isAuthenticated]);

 return (
  <Stack>
   <form
    onSubmit={handleSubmit(onSubmit)}
    autoComplete="off"
    style={{
     display: "flex",
     flexDirection: "column",
     alignItems: "center",
     justifyContent: "center"
    }}>
    <Controller
     name="email"
     control={control}
     rules={{
      required: true,
      pattern: emailValidation
     }}
     render={({ field }) => (
      <TextField
       {...field}
       fullWidth={true}
       label="Email"
       error={formState.isDirty && !!formState?.errors?.email}
       InputLabelProps={{ shrink: true }}
       sx={{ my: 2 }}
       autoComplete="off"
       helperText={
        formState.isDirty &&
        !!formState?.errors?.email &&
        intl.formatMessage({ id: "login.invalidMail" })
       }
      />
     )}
    />
    <Controller
     name="password"
     control={control}
     rules={{ required: true }}
     render={({ field }) => (
      <TextField
       {...field}
       fullWidth={true}
       type={showPassword}
       label="Password"
       autoComplete="off"
       InputLabelProps={{ shrink: true }}
       error={formState.isDirty && !!formState?.errors?.password}
       helperText={
        formState.isDirty &&
        !!formState?.errors?.password &&
        intl.formatMessage({ id: "login.invalidPassword" })
       }
       InputProps={{
        endAdornment: (
         <InputAdornment position="end">
          <IconButton
           aria-label="toggle password visibility"
           onClick={() => {
            setShowPassword(showPassword === "text" ? "password" : "text");
           }}
           edge="end">
           {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
         </InputAdornment>
        )
       }}
      />
     )}
    />
    {isLoading && <div>Loading...</div>}
    {loginError && !isLoading && (
     <Typography color="error" mt={2}>
      <FormattedMessage id="login.wrongLogin" />
     </Typography>
    )}
    <Button
     disabled={isLoading}
     variant="kxActionButton"
     onClick={handleSubmit(onSubmit)}
     sx={{ mt: 2 }}>
     Login
    </Button>
   </form>
  </Stack>
 );
};

export default Login;
