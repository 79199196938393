import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import { isNil } from "ramda";

import FileDownloadIcon from "@mui/icons-material/FileDownload";

import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import AdminWrapper from "components/shared/admin/AdminWrapper";
import AppModal from "components/shared/modal/AppModal";

import { postDownloadCredits } from "redux/handlers/creditsHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IUsersList } from "types/api/userApiInterface";

const DownloadMovements = ({ contactList }: { contactList: Array<IUsersList> }): ReactElement => {
 const intl = useIntl();
 const theme = useTheme();
 const dispatch = useAppDispatch();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [firstDate, setFirstDate] = useState(dayjs().subtract(1, "day"));
 const [secondDate, setSecondDate] = useState(dayjs());
 const [partnerId, setPartnerId] = useState<string>("");

 const handleOpen = () => {
  setOpenModal(true);
 };

 const handleClose = () => {
  setOpenModal(false);
 };

 const handleConfirm = async () => {
  setIsLoading(true);
  const data = await dispatch(postDownloadCredits(firstDate, secondDate, partnerId));
  if (data) {
   // Create a URL for the Blob
   const url = URL.createObjectURL(data);

   // Create an anchor tag for downloading
   const a = document.createElement("a");

   // Set the URL and download attribute of the anchor tag
   a.href = url;
   a.download = "movements.csv";

   // Trigger the download by clicking the anchor tag
   a.click();
  }

  setIsLoading(false);
  setOpenModal(false);
 };

 return (
  <>
   {desktopViewPort ? (
    <Button
     onClick={handleOpen}
     variant={desktopViewPort ? "kxActionButton" : "text"}
     endIcon={<FileDownloadIcon />}>
     {desktopViewPort && <FormattedMessage id="credit.action.download" />}
    </Button>
   ) : (
    <IconButton onClick={handleOpen}>
     <FileDownloadIcon />
    </IconButton>
   )}
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    handleConfirm={handleConfirm}
    title={intl.formatMessage({ id: "credit.action.download" })}>
    <Stack spacing={2}>
     <AdminWrapper sx={{ width: "100%" }}>
      <Autocomplete
       autoHighlight
       fullWidth
       onChange={(e, value) => (isNil(value) ? setPartnerId("") : setPartnerId(value.userid))}
       options={contactList.map((element) => {
        return {
         label: `${element.firstname} ${element.lastname} - ${element.companyName}`,
         userid: element.userid
        };
       })}
       renderInput={(params) => (
        <TextField
         {...params}
         label={intl.formatMessage({ id: "app.search.partner" })}
         InputLabelProps={{ shrink: true }}
        />
       )}
      />
     </AdminWrapper>
     <Stack>
      <Typography>Data iniziale</Typography>
      <DatePicker
       value={firstDate}
       maxDate={dayjs()}
       disableFuture
       onChange={(newValue) => setFirstDate(newValue || dayjs())}
      />
     </Stack>
     <Stack>
      <Typography>Data finale</Typography>
      <DatePicker
       value={secondDate}
       maxDate={dayjs()}
       minDate={firstDate}
       disableFuture
       onChange={(newValue) => setSecondDate(newValue || dayjs())}
      />
     </Stack>
    </Stack>
   </AppModal>
  </>
 );
};

export default DownloadMovements;
