import React, { ReactElement, useState } from "react";
import { useIntl } from "react-intl";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

const ShowHealth = ({
 status
}: {
 status: Array<{ status: string; listen_port: number }>;
}): ReactElement => {
 const intl = useIntl();

 const [openModal, setOpenModal] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 return (
  <>
   <Chip
    size="small"
    label={`${status.filter((element) => element.status === "healthy").length}/${status.length}`}
    color={
     status.filter((element) => element.status === "healthy").length === status.length
      ? "success"
      : "error"
    }
    onClick={handleOpen}
   />
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "addons.loadBalancer.targetHealth" })}
    handleClose={handleClose}
    handleConfirm={handleClose}
    showConfirmButton={false}>
    <Stack spacing={2}>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={120} fontWeight="bold">
       Status
      </Typography>
      <Typography minWidth={120} fontWeight="bold">
       Port
      </Typography>
     </Stack>
     {status.map((element, index) => {
      return (
       <Stack key={`health-${index}`} direction="row" alignItems="center" spacing={2}>
        <Stack minWidth={120}>
         <Chip
          size="small"
          label={element.status}
          color={element.status === "healthy" ? "success" : "error"}
         />
        </Stack>
        <Typography minWidth={120}>{element.listen_port}</Typography>
       </Stack>
      );
     })}
    </Stack>
   </AppModal>
  </>
 );
};

export default ShowHealth;
