import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const CronjobIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <path
   d="M14.7273 12.4091V14.591H16.3636M18 14.591C18 15.0207 17.9153 15.4463 17.7509 15.8434C17.5864 16.2404 17.3453 16.6012 17.0414 16.9051C16.7375 17.209 16.3768 17.4501 15.9797 17.6146C15.5826 17.779 15.1571 17.8637 14.7273 17.8637C14.2975 17.8637 13.8719 17.779 13.4749 17.6146C13.0778 17.4501 12.717 17.209 12.4131 16.9051C12.1092 16.6012 11.8681 16.2404 11.7037 15.8434C11.5392 15.4463 11.4545 15.0207 11.4545 14.591C11.4545 13.723 11.7993 12.8906 12.4131 12.2768C13.0269 11.663 13.8593 11.3182 14.7273 11.3182C15.5953 11.3182 16.4277 11.663 17.0414 12.2768C17.6552 12.8906 18 13.723 18 14.591Z"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
  <path
   d="M4.91667 3V4.625M13.0833 3V4.625M2 14.375V6.25C2 5.81902 2.18437 5.4057 2.51256 5.10095C2.84075 4.79621 3.28587 4.625 3.75 4.625H14.25C14.7141 4.625 15.1592 4.79621 15.4874 5.10095C15.8156 5.4057 16 5.81902 16 6.25V11.5V8.95833C16 8.52736 15.8156 8.11403 15.4874 7.80928C15.1592 7.50454 14.7141 7.33333 14.25 7.33333H3.75C3.28587 7.33333 2.84075 7.50454 2.51256 7.80928C2.18437 8.11403 2 8.52736 2 8.95833V14.375ZM2 14.375C2 14.806 2.18437 15.2193 2.51256 15.524C2.84075 15.8288 3.28587 16 3.75 16H11.5"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
 </svg>,
 "Cronjob"
);
