import React, { ReactElement, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { isNil } from "ramda";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { getHostList, postChangeDomainNs } from "redux/handlers/domainsHandler";

import { getDomainsList } from "redux/selectors/domainsSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IHostData } from "types/api/domainsApiInterface";

const DomainChangeNs = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const [loadingData, setLoadingData] = useState<boolean>(true);
 const [hostList, setHostList] = useState<Array<IHostData>>([]);
 const [section, setSection] = useState<number>(0);
 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const domainData = useSelector(getDomainsList)[0];
 const ns = domainData.ns1 ? [domainData.ns1, domainData.ns2] : ["Nessun name server"];

 const handleOpen = async () => setOpen(true);
 const handleClose = () => setOpen(false);

 useEffect(() => {
  (async () => {
   setHostList((await getHostList()).filter((element) => element.type === "ns"));
   setLoadingData(false);
  })();
 }, []);

 const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
  reset();
  setSection(newValue);
 };

 const { control, formState, handleSubmit, watch, reset, setValue } = useForm({
  defaultValues: {
   ns1: "",
   ns2: "",
   ns3: "",
   ns4: ""
  }
 });

 const onSubmit: SubmitHandler<{
  ns1: string;
  ns2: string;
  ns3: string;
  ns4: string;
 }> = async (data: { ns1: string; ns2: string; ns3: string; ns4: string }) => {
  setLoading(true);
  switch (section) {
   case 0:
    await dispatch(postChangeDomainNs(domainData.id, data.ns1, data.ns2));
    break;
   default:
    await dispatch(postChangeDomainNs(domainData.id, data.ns1, data.ns2, data.ns3, data.ns4));
    break;
  }
  setLoading(false);
  handleClose();
 };

 const checkDisabled = (): boolean => {
  return watch("ns1") === "" || watch("ns2") === "";
 };

 return (
  <Paper elevation={0} sx={{ borderRadius: "10px", boxShadow: 0 }}>
   <Stack>
    <Stack alignItems="center" justifyContent="flex-start" direction="row" px={2} pt={1}>
     <IconSelector icon="KeyIcon" props={{ fontSize: "medium", color: "secondary" }} />
     <Typography variant="h6" component="h2" ml={1}>
      <FormattedMessage id="domains.changeNs" />{" "}
     </Typography>
    </Stack>
    <Stack p={2}>
     {loadingData ? (
      <Stack spacing={2}>
       <Alert severity="info">
        <FormattedMessage id="domain.loading" />
       </Alert>
       <CircularProgress />
      </Stack>
     ) : (
      <Stack>
       <Stack textAlign="start" mb={1}>
        <Typography>
         <FormattedMessage id="domains.settedNs" />
        </Typography>
        <Stack direction={"row"} spacing={1}>
         {ns.map((element, index) => {
          return <Chip key={`ns-list-${index}`} size="small" label={element} />;
         })}
        </Stack>
       </Stack>
       <Stack>
        <Box sx={{ width: "100%" }}>
         <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={section} onChange={handleChangeTab} aria-label="basic tabs example">
           <Tab label="NS Host" />
           <Tab label="NS Custom" />
          </Tabs>
         </Box>
         <Stack
          role="tabpanel"
          hidden={section !== 0}
          id={`simple-tabpanel-${0}`}
          aria-labelledby={`simple-tab-${0}`}>
          {section === 0 && (
           <Box sx={{ p: 3 }}>
            <Stack direction={desktopViewPort ? "row" : "column"} spacing={1}>
             <Controller
              name="ns1"
              control={control}
              rules={{
               required: true
              }}
              render={({ field }) => (
               <Autocomplete
                fullWidth={true}
                autoHighlight
                sx={{ my: 2 }}
                onChange={(e, value) =>
                 isNil(value) ? setValue("ns1", "") : setValue("ns1", value)
                }
                options={hostList.map((element) => (element.data1 ? element.data1 : element.name))}
                renderOption={(props, option) => {
                 return (
                  <li {...props} key={`first-ns-${option}`}>
                   {option}
                  </li>
                 );
                }}
                renderInput={(params) => (
                 <TextField
                  {...params}
                  {...field}
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                   e.stopPropagation();
                  }}
                  error={formState.isDirty && !!formState?.errors?.ns1}
                  label={<FormattedMessage id="domains.nsHost" />}
                  InputLabelProps={{ shrink: true }}
                 />
                )}
               />
              )}
             />
             <Controller
              name="ns2"
              control={control}
              rules={{
               required: true
              }}
              render={({ field }) => (
               <Autocomplete
                fullWidth={true}
                autoHighlight
                sx={{ my: 2 }}
                onChange={(e, value) =>
                 isNil(value) ? setValue("ns2", "") : setValue("ns2", value)
                }
                options={hostList.map((element) => (element.data2 ? element.data2 : element.name))}
                renderOption={(props, option) => {
                 return (
                  <li {...props} key={`second-ns-${option}`}>
                   {option}
                  </li>
                 );
                }}
                renderInput={(params) => (
                 <TextField
                  {...params}
                  {...field}
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                   e.stopPropagation();
                  }}
                  error={formState.isDirty && !!formState?.errors?.ns2}
                  label={<FormattedMessage id="domains.nsHost" />}
                  InputLabelProps={{ shrink: true }}
                 />
                )}
               />
              )}
             />
            </Stack>
           </Box>
          )}
         </Stack>
         <Stack
          role="tabpanel"
          hidden={section !== 1}
          id={`simple-tabpanel-${1}`}
          aria-labelledby={`simple-tab-${1}`}>
          {section === 1 && (
           <Box sx={{ p: 3 }}>
            <Alert severity="info" sx={{ mb: 2 }}>
             <FormattedMessage id="domain.nameserverMessage" />
            </Alert>
            <Stack direction={desktopViewPort ? "row" : "column"} spacing={1}>
             <Controller
              name="ns1"
              control={control}
              rules={{
               required: true
              }}
              render={({ field }) => (
               <TextField
                {...field}
                label="NS1"
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                 e.stopPropagation();
                }}
                error={formState.isDirty && !!formState?.errors?.ns1}
                autoComplete="off"
               />
              )}
             />
             <Controller
              name="ns2"
              control={control}
              rules={{
               required: true
              }}
              render={({ field }) => (
               <TextField
                {...field}
                label="NS2"
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                 e.stopPropagation();
                }}
                error={formState.isDirty && !!formState?.errors?.ns2}
                autoComplete="off"
               />
              )}
             />
             <Controller
              name="ns3"
              control={control}
              render={({ field }) => (
               <TextField
                {...field}
                label="NS3"
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                 e.stopPropagation();
                }}
                error={formState.isDirty && !!formState?.errors?.ns3}
                autoComplete="off"
               />
              )}
             />
             <Controller
              name="ns4"
              control={control}
              render={({ field }) => (
               <TextField
                {...field}
                label="NS4"
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                 e.stopPropagation();
                }}
                error={formState.isDirty && !!formState?.errors?.ns4}
                autoComplete="off"
               />
              )}
             />
            </Stack>
           </Box>
          )}
         </Stack>
        </Box>
       </Stack>
       <Stack direction="row" justifyContent="flex-start">
        <Button onClick={handleOpen} disabled={loading}>
         Conferma
        </Button>
        <AppModal
         open={open}
         close={handleClose}
         title={intl.formatMessage({ id: "domains.changeNs" })}
         handleClose={handleClose}
         disabled={checkDisabled() || loading}
         handleConfirm={handleSubmit(onSubmit)}>
         <Typography>
          <FormattedMessage id="domain.ns.changeNsMessage" />
         </Typography>
        </AppModal>
       </Stack>
      </Stack>
     )}
    </Stack>
   </Stack>
  </Paper>
 );
};

export default DomainChangeNs;
