import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postRemoveDomainOrder } from "redux/handlers/domainsHandler";

import { useAppDispatch } from "hooks/reduxHook";

const RemoveTransfer = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setIsLoading(true);
  await dispatch(postRemoveDomainOrder(id));
  handleClose();
  setIsLoading(false);
 };

 return (
  <>
   <Button variant="kxActionButton" onClick={handleOpen}>
    <FormattedMessage id="domain.order.removeTransfer" />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "domain.order.removeTransfer" })}
    handleClose={handleClose}
    disabled={isLoading}
    handleConfirm={handleConfirm}>
    <Stack spacing={2}>
     <Alert severity="warning">
      <FormattedMessage id="domain.order.removeTransferWarning" />
     </Alert>
     <Typography>
      <FormattedMessage id="domain.order.removeTransferMessage" />
     </Typography>
    </Stack>
   </AppModal>
  </>
 );
};

export default RemoveTransfer;
