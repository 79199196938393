import React, { ReactElement } from "react";

import Tooltip from "@mui/material/Tooltip";

import { EServerOS } from "types/redux/serverInterface";

type Props = {
 osType: string | null;
 width?: number;
 height?: number;
};

const OsImage = ({ osType, width = 20, height = 20 }: Props): ReactElement => {
 const image = () => {
  switch (osType) {
   case EServerOS.CENTOS:
    return (
     <img
      width={width}
      height={height}
      src={`${process.env.PUBLIC_URL}/svg/os/CentOS.svg`}
      alt="centos"
     />
    );
   case EServerOS.DEBIAN:
    return (
     <img
      width={width}
      height={height}
      src={`${process.env.PUBLIC_URL}/svg/os/Debian.svg`}
      alt="debian"
     />
    );
   case EServerOS.FEDORA:
    return (
     <img
      width={width}
      height={height}
      src={`${process.env.PUBLIC_URL}/svg/os/Fedora.svg`}
      alt="fedora"
     />
    );
   case EServerOS.ROCKY:
    return (
     <img
      width={width}
      height={height}
      src={`${process.env.PUBLIC_URL}/svg/os/Rocky.svg`}
      alt="rocky"
     />
    );
   case EServerOS.UBUNTU:
    return (
     <img
      width={width}
      height={height}
      src={`${process.env.PUBLIC_URL}/svg/os/Ubuntu.svg`}
      alt="ubuntu"
     />
    );
   case EServerOS.PFSENSE:
    return (
     <img
      width={width}
      height={height}
      src={`${process.env.PUBLIC_URL}/svg/os/PfSense.svg`}
      alt="pfsense"
     />
    );
   case EServerOS.WINDOWS:
    return (
     <img
      width={width}
      height={height}
      src={`${process.env.PUBLIC_URL}/svg/os/Winzoz.svg`}
      alt="windows"
     />
    );
   default:
    return (
     <img
      width={width}
      height={height}
      src={`${process.env.PUBLIC_URL}/svg/os/Debian.svg`}
      alt="debian"
     />
    );
  }
 };

 return (
  <Tooltip title={osType || ""} placement="top">
   {image()}
  </Tooltip>
 );
};

export default OsImage;
