import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { ILoadBalancerDataset } from "types/api/addonsApiInterface";

const initialState: ILoadBalancerDataset = {
 name: "no_balancer_selected",
 id: 0
} as ILoadBalancerDataset;

const LoadBalancerDetailsReducer = createSlice({
 name: "loadBalancerDetails",
 initialState,
 reducers: {
  setLoadBalancerDetails: (state, { payload }: PayloadAction<ILoadBalancerDataset>) => {
   return payload;
  },
  resetLoadBalancerDetails: () => {
   return initialState;
  }
 }
});

export default LoadBalancerDetailsReducer;
