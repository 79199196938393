import React, { ReactElement, useEffect, useState } from "react";

import Stack from "@mui/material/Stack";

import Baremetal from "components/baremetal/Baremetal";
import SkeletonAddons from "components/shared/skeletons/SkeletonAddons";

import { getAllBaremetals } from "redux/handlers/baremetalHandle";

import { useAppDispatch } from "hooks/reduxHook";

const BaremetalPage = (): ReactElement => {
 const dispatch = useAppDispatch();

 const [isLoading, setIsLoading] = useState<boolean>(true);

 useEffect(() => {
  (async () => {
   await dispatch(getAllBaremetals(0, 10));
   setIsLoading(false);
  })();
 }, []);

 return <Stack>{isLoading ? <SkeletonAddons id="app.baremetal" /> : <Baremetal />}</Stack>;
};

export default BaremetalPage;
