import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const HostIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <circle cx="10" cy="10" r="9.75" fill="transparent" />
  <rect
   x="4.25"
   y="6.25"
   width="11.5"
   height="3.5"
   rx="0.25"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
  />
  <rect
   x="4.25"
   y="11.25"
   width="11.5"
   height="3.5"
   rx="0.25"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
  />
  <path d="M6 10V11" stroke="currentColor" strokeWidth="1" strokeLinecap="round" />
  <path d="M14 10V11" stroke="currentColor" strokeWidth="1" strokeLinecap="round" />
  <path d="M6 7V9" stroke="currentColor" strokeWidth="0.25" strokeLinecap="round" />
  <path d="M6 12V14" stroke="currentColor" strokeWidth="0.25" strokeLinecap="round" />
  <path d="M7 7V9" stroke="currentColor" strokeWidth="0.25" strokeLinecap="round" />
  <path d="M7 12V14" stroke="currentColor" strokeWidth="0.25" strokeLinecap="round" />
  <path d="M8 7V9" stroke="currentColor" strokeWidth="0.25" strokeLinecap="round" />
  <path d="M8 12V14" stroke="currentColor" strokeWidth="0.25" strokeLinecap="round" />
  <path d="M9 7V9" stroke="currentColor" strokeWidth="0.25" strokeLinecap="round" />
  <path d="M9 12V14" stroke="currentColor" strokeWidth="0.25" strokeLinecap="round" />
  <path d="M14 8L12 8" stroke="currentColor" strokeWidth="0.25" strokeLinecap="round" />
  <path d="M14 13L12 13" stroke="currentColor" strokeWidth="0.25" strokeLinecap="round" />
 </svg>,
 "Host"
);
