import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { isNil } from "ramda";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { getWebsitesDetails } from "redux/selectors/websitesSelector";

import { IFtpData } from "types/api/websitesApiInterface";

import AddFtpUser from "../actions/AddFtpUser";
import DeleteFtpUser from "../actions/DeleteFtpUser";
import EditDirectory from "../actions/EditDirectory";
import EditFtpUser from "../actions/EditFtpUser";

const defaultFtpValues = {
 password: "",
 id: 0,
 username: "",
 dir: "",
 iv: "",
 parent_domain_id: 0
};

const DetailsCredentials = (): ReactElement => {
 const websitesDetails = useSelector(getWebsitesDetails);
 const credentials = websitesDetails.ftp;

 const [selectedFtp, setSelectedFtp] = useState<IFtpData>(credentials[0] || defaultFtpValues);
 const [showPassword, setShowPassword] = useState<string>("password");
 const [ftpLength, setFtpLength] = useState<number>(credentials.length || 0);

 const handleClickShowPassword = () =>
  setShowPassword(showPassword === "password" ? "text" : "password");

 const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
  event.preventDefault();
 };

 useEffect(() => {
  if (ftpLength !== credentials.length) {
   setSelectedFtp(credentials[0]);
   setFtpLength(credentials.length);
  }
 }, [credentials]);

 return (
  <>
   <Stack>
    {credentials.length !== 0 ? (
     <>
      <Stack
       direction="row"
       alignItems="center"
       justifyContent="space-between"
       spacing={2}
       py={0.5}
       pr={2}
       pt={2}>
       <Stack direction="row" alignItems="center" textAlign="start" spacing={2} width={"100%"}>
        <Typography fontWeight="bold" variant="subtitle1" px={2} minWidth={150}>
         <FormattedMessage id="sites.user" />
        </Typography>
        <Autocomplete
         autoHighlight
         fullWidth
         size="small"
         sx={{ my: 2 }}
         onChange={(e, value) =>
          !isNil(value)
           ? setSelectedFtp(credentials.find((ftp) => ftp.id === value?.id) || defaultFtpValues)
           : setSelectedFtp(defaultFtpValues)
         }
         options={credentials.map((ftp) => {
          return { label: ftp.username, id: ftp.id };
         })}
         value={{
          label: credentials.find((ftp) => ftp.id === selectedFtp?.id)?.username || "",
          id: credentials.find((ftp) => ftp.id === selectedFtp?.id)?.id || 0
         }}
         renderInput={(params) => (
          <TextField {...params} fullWidth={true} InputLabelProps={{ shrink: true }} />
         )}
        />
       </Stack>
      </Stack>
      <Divider />
      <Stack
       direction="row"
       alignItems="center"
       justifyContent="space-between"
       textAlign="start"
       spacing={2}
       py={0.5}
       pr={2}>
       <Stack direction="row" spacing={2} alignItems="center" overflow="hidden">
        <Typography fontWeight="bold" variant="subtitle1" px={2} minWidth={150}>
         <FormattedMessage id="sites.directory" />
        </Typography>
        <Typography noWrap>{selectedFtp.dir}</Typography>
       </Stack>
       <Stack direction="row" alignItems="center" spacing={1}>
        <EditDirectory ftpUser={selectedFtp} />
       </Stack>
      </Stack>
      <Divider />
      <Stack direction="row" alignItems="center" textAlign="start" spacing={2} py={0.5} pr={2}>
       <Typography fontWeight="bold" variant="subtitle1" px={2} minWidth={150}>
        <FormattedMessage id="sites.password" />
       </Typography>
       <Stack direction="row">
        {selectedFtp.password ? (
         <TextField
          fullWidth
          type={showPassword}
          autoComplete="current-password"
          value={selectedFtp.password}
          InputProps={{
           readOnly: true,
           endAdornment: (
            <InputAdornment position="end">
             <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end">
              {showPassword === "text" ? <VisibilityOff /> : <Visibility />}
             </IconButton>
            </InputAdornment>
           )
          }}
          variant="standard"
         />
        ) : (
         <Typography noWrap>{"Not provided"}</Typography>
        )}
       </Stack>
      </Stack>
      <Divider />
      <Stack direction="row" alignItems="center" p={1} justifyContent="center">
       <Stack direction={"row"} alignItems="center" spacing={3} maxWidth={"100%"}>
        <AddFtpUser type="icon" />
        <EditFtpUser ftpUser={selectedFtp} />
        <DeleteFtpUser ftpUser={selectedFtp} />
       </Stack>
      </Stack>
     </>
    ) : (
     <>
      <Divider />
      <Stack p={2}>
       <Alert severity="info">
        <FormattedMessage id="sites.noFtpUserMessage" />
       </Alert>
      </Stack>
      <Divider />
      <AddFtpUser type="text" />
     </>
    )}
   </Stack>
  </>
 );
};

export default DetailsCredentials;
