import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import customersReducer from "redux/reducers/customersReducer";
import notificationReducer from "redux/reducers/notificationReducer";

import { ICustomersApi } from "types/api/customersApiInterface";
import { userType } from "types/global/user";

import { ApiService } from "service/ApiService";

export const getAllCustomers =
 (currentIndex: number, sizePerPage: number, q?: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<ICustomersApi>(
    `/contact/allcontacts?currentIndex=${currentIndex}&sizePerPage=${sizePerPage}&q=${q || ""}`
   );
   dispatch(customersReducer.actions.setCustomers(data || {}));
  } catch (error) {
   console.warn("FAILED TO GET CUSTOMERS");
  }
 };

export const postAddCustomer =
 (customerData: userType): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/contact/addcontact", "POST", customerData);
   if (data) {
    dispatch(getAllCustomers(0, 10));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "New client created",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create client - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const putUpdateCustomer =
 (id: number, customerData: userType): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<userType>(`/contact/updatecontact/${id}`, "PUT", customerData);
   if (data) {
    dispatch(getAllCustomers(0, 10));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Client successfully updated",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update client - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteCustomer =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<userType>("/contact/deletecontact", "POST", { id: id });
   if (data) {
    dispatch(getAllCustomers(0, 10));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Client successfully deleted",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete client - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };
