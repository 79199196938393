import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postResetAuthinfo } from "redux/handlers/domainsHandler";

import { getDomainsList } from "redux/selectors/domainsSelector";

import { useAppDispatch } from "hooks/reduxHook";

const ResetAuthinfo = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const domainData = useSelector(getDomainsList)[0];

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postResetAuthinfo(domainData.id));
  setLoading(false);
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <FormattedMessage id="domain.generateAuthinfo" />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "domain.generateAuthinfo" })}
    handleClose={handleClose}
    disabled={loading}
    handleConfirm={handleConfirm}>
    <Typography>
     <FormattedMessage id="domain.generateNewAuthinfo" />
    </Typography>
   </AppModal>
  </>
 );
};

export default ResetAuthinfo;
