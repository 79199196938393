import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postDeleteDatabase } from "redux/handlers/websitesHandler";

import { getWebsitesDetails } from "redux/selectors/websitesSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IDbData } from "types/api/websitesApiInterface";

const DeleteDatabase = ({ dbData }: { dbData: IDbData }): ReactElement => {
 const intl = useIntl();
 const websitesDetails = useSelector(getWebsitesDetails);
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleSubmit = async () => {
  setIsLoading(true);
  await dispatch(
   postDeleteDatabase(
    dbData.db_user?.id || 0,
    dbData.id,
    websitesDetails.isp_id,
    websitesDetails.domain_id
   )
  );
  setIsLoading(false);
  setOpenModal(false);
 };

 return (
  <Stack maxWidth={600}>
   <Tooltip title={<FormattedMessage id="delete.site.db" />}>
    <Button variant="kxActionButton" onClick={handleOpen} disabled={dbData.id === 0}>
     <IconSelector icon="DeleteIcon" />
    </Button>
   </Tooltip>
   <AppModal
    open={openModal}
    close={handleClose}
    loading={isLoading}
    title={intl.formatMessage({ id: "sites.dbOperations" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit}>
    <FormattedMessage id="sites.deleteDatabase" />
   </AppModal>
  </Stack>
 );
};

export default DeleteDatabase;
