import React, { ReactElement, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useDebounce } from "react-use";
import { formatGigaBytes } from "helpers/numberFormatting";

import { isNil } from "ramda";

import SearchIcon from "@mui/icons-material/Search";

import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { getAllBaremetals } from "redux/handlers/baremetalHandle";
import { getUsersList } from "redux/handlers/userHandler";

import { getBaremetalConfigurator, getBaremetalNumber } from "redux/selectors/baremetalSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IUsersList } from "types/api/userApiInterface";

import BaremetalMenu from "./BaremetalMenu";

const Baremetal = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const baremetals = useSelector(getBaremetalConfigurator);
 const baremetalNumber = useSelector(getBaremetalNumber);

 const searchRef = useRef<HTMLInputElement>(null);

 const [loading, setLoading] = useState(true);
 const [contactsList, setContactsList] = useState<Array<IUsersList>>([]);
 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);
 const [searchValue, setSearchValue] = useState<string>("");
 const [selectedPartner, setSelectedPartner] = useState<string>("");

 useEffect(() => {
  (async () => {
   setLoading(true);
   setContactsList(await dispatch(getUsersList()));
   await dispatch(getAllBaremetals(0, 10));
   setLoading(false);
  })();
 }, []);

 useDebounce(
  async () => {
   if (!loading) {
    setCurrentPage(0);
    await dispatch(
     getAllBaremetals(currentPage, rowPerPage, searchRef?.current?.value, selectedPartner)
    );
   }
  },
  1000,
  [searchValue]
 );

 const reloadData = async () => {
  await dispatch(
   getAllBaremetals(currentPage, rowPerPage, searchRef?.current?.value, selectedPartner)
  );
 };

 const handleOnRowsPerPageChange = async (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const newRowsPerPage = parseInt(event.target.value, 10);
  setRowPerPage(newRowsPerPage);
  setCurrentPage(0);
  await dispatch(getAllBaremetals(0, newRowsPerPage, searchRef?.current?.value, selectedPartner));
 };

 const handleOnPageChange = async (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  const startIndex = newPage * rowPerPage;
  setCurrentPage(newPage);
  await dispatch(
   getAllBaremetals(startIndex, rowPerPage, searchRef?.current?.value, selectedPartner)
  );
 };

 const handleSearchPartner = async (partnerIdValue: string) => {
  setCurrentPage(0);
  setSelectedPartner(partnerIdValue);
  await dispatch(
   getAllBaremetals(currentPage, rowPerPage, searchRef?.current?.value, partnerIdValue)
  );
 };

 return !loading ? (
  <Stack spacing={2}>
   <Stack justifyContent="space-between" direction="row">
    <Stack direction={desktopViewPort ? "row" : "column"} spacing={2} alignItems="center">
     <Typography>
      {baremetalNumber} <FormattedMessage id="configurator.baremetal.total" />
     </Typography>
     <TextField
      size="small"
      autoComplete="new-password"
      label={<FormattedMessage id="configurator.search.baremetals" />}
      inputRef={searchRef}
      sx={{ ml: 4 }}
      InputLabelProps={{ shrink: true }}
      InputProps={{ startAdornment: <SearchIcon color="disabled" />, autoComplete: "off" }}
      onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
     />
     <Autocomplete
      autoHighlight
      size="small"
      sx={{ mx: 1, width: desktopViewPort ? 300 : 240 }}
      onChange={(e, value) =>
       isNil(value) ? handleSearchPartner("") : handleSearchPartner(value.userid)
      }
      options={contactsList.map((element) => {
       return {
        label: `${element.firstname} ${element.lastname} - ${element.companyName}`,
        userid: element.userid
       };
      })}
      renderInput={(params) => (
       <TextField
        {...params}
        label={intl.formatMessage({ id: "app.search.partner" })}
        InputLabelProps={{ shrink: true }}
       />
      )}
     />
    </Stack>
   </Stack>
   <Stack>
    <TableContainer component="div">
     <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
       <TableRow>
        <TableCell>
         <FormattedMessage id="configurator.baremetal.partner" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="configurator.baremetal.name" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="configurator.baremetal.size" />
        </TableCell>
        <TableCell align="center">
         <FormattedMessage id="configurator.baremetal.actions" />
        </TableCell>
       </TableRow>
      </TableHead>
      <TableBody>
       {baremetals?.map((row, index) => (
        <TableRow key={`isp-config-list-${index}`}>
         <TableCell component="th" scope="row">
          <Typography variant="subtitle2" fontWeight="bold">
           {`${contactsList.find((element) => element.userid === row.userid)?.companyName}`}
          </Typography>
         </TableCell>
         <TableCell component="th" scope="row">
          <Typography variant="subtitle2">{row.name}</Typography>
          {row.status === "creating" && <Typography>Richiesta di creazione bare metal</Typography>}
         </TableCell>
         <TableCell>
          <Stack>
           <Stack direction="row" spacing={1}>
            <Typography variant="caption" fontWeight="bold">
             Tipo disco:
            </Typography>
            <Typography variant="caption">{row.disk_type}</Typography>
           </Stack>
           <Stack direction="row" spacing={1}>
            <Typography variant="caption" fontWeight="bold">
             Disco:
            </Typography>
            <Typography variant="caption">
             {row.dim_disk >= 1024
              ? `${formatGigaBytes(row.dim_disk, "TB")} TB`
              : `${formatGigaBytes(row.dim_disk)} GB`}
            </Typography>
           </Stack>
           <Stack direction="row" spacing={1}>
            <Typography variant="caption" fontWeight="bold">
             RAM:
            </Typography>
            <Typography variant="caption">{`${row.dim_ram} GB`}</Typography>
           </Stack>
          </Stack>
         </TableCell>
         <TableCell align="center">
          <BaremetalMenu
           id={row.id}
           partner={row.userid || ""}
           product={row.product}
           amount={Number(row.amount)}
           server_number={row.server_id}
           status={row.status}
           partnerList={contactsList}
           reloadData={reloadData}
          />
         </TableCell>
        </TableRow>
       ))}
      </TableBody>
     </Table>
    </TableContainer>
    <TablePagination
     component="div"
     count={baremetalNumber || 0}
     page={currentPage}
     rowsPerPage={rowPerPage}
     onPageChange={handleOnPageChange}
     onRowsPerPageChange={handleOnRowsPerPageChange}
    />
   </Stack>
  </Stack>
 ) : (
  <></>
 );
};

export default Baremetal;
