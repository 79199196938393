import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";

import { websiteContainerSteps } from "constants/server";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { getPhpVersions, getRepositorys, postCreateWebsite } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IDockerPhp, IDockerRepo } from "types/api/dockerApiInterface";

import SiteName from "./siteStepComponents/SiteName";
import SitePhp from "./siteStepComponents/SitePhp";
import SiteRecap from "./siteStepComponents/SiteRecap";

const CreateNewSite = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [phpVers, setPhpVers] = useState<Array<IDockerPhp>>([]);
 const [repository, setRepository] = useState<Array<IDockerRepo>>([]);
 const [website, setWebsite] = useState<string>("");
 const [selectedVers, setSelectedVers] = useState<string>("");
 const [goToStepper, setGoToStepper] = useState<boolean>(false);
 const [activeStep, setActiveStep] = useState<number>(0);

 const handleOpen = async () => {
  setPhpVers(await dispatch(getPhpVersions()));
  setRepository(await dispatch(getRepositorys()));
  setGoToStepper(false);
  handleReset();
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postCreateWebsite(id, website, selectedVers, repository[0].name));
  setLoading(false);
  handleClose();
 };

 const handleNext = () => {
  setActiveStep((prevActiveStep) => prevActiveStep + 1);
 };

 const handleBack = () => {
  setActiveStep((prevActiveStep) => prevActiveStep - 1);
 };

 const handleReset = () => {
  setWebsite("");
  setSelectedVers("");
  setActiveStep(0);
 };

 const RenderStepContent = (): ReactElement => {
  switch (activeStep) {
   case 0:
    return (
     <SiteName
      activeStep={activeStep}
      firstValue={website}
      handleNext={handleNext}
      handleBack={handleBack}
      handleReset={handleReset}
      setPropsValue={(value) => setWebsite(value)}
     />
    );
   case 1:
    return (
     <SitePhp
      activeStep={activeStep}
      firstValue={selectedVers}
      phpVers={phpVers}
      handleNext={handleNext}
      handleBack={handleBack}
      handleReset={handleReset}
      setPropsValue={(value) => setSelectedVers(value)}
     />
    );
   case 2:
    return (
     <SiteRecap
      website={website}
      selectedVers={selectedVers}
      handleBack={handleBack}
      handleReset={handleReset}
     />
    );
   default:
    return <></>;
  }
 };

 return (
  <>
   <Button onClick={handleOpen} variant="kxActionButton">
    <FormattedMessage id="docker.website.addSite" />
   </Button>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.addSite" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}
    showConfirmButton={activeStep === websiteContainerSteps.length - 1}>
    {!goToStepper ? (
     <Stack>
      <Stack direction="row">
       <Stack justifyContent="center" spacing={2}>
        <Typography>
         <FormattedMessage id="docker.website.firstExplain" />
        </Typography>
        <Typography>
         <FormattedMessage id="docker.website.secondExplain" />
        </Typography>
        <Typography>
         <FormattedMessage id="docker.website.thirdExplain" />
        </Typography>
       </Stack>
       <Stack alignItems="center" justifyContent="center">
        <IconSelector icon="WebIcon" props={{ sx: { fontSize: "200px", color: "lightgray" } }} />
       </Stack>
      </Stack>
      <Button onClick={() => setGoToStepper(true)}>
       <FormattedMessage id="docker.website.startNow" />
      </Button>
     </Stack>
    ) : (
     <Stack>
      <Stepper activeStep={activeStep} alternativeLabel>
       {websiteContainerSteps.map((label) => (
        <Step key={label.label}>
         <StepLabel optional={<FormattedMessage id={label.description} />}>
          {<FormattedMessage id={label.label} />}
         </StepLabel>
        </Step>
       ))}
      </Stepper>
      <RenderStepContent />
     </Stack>
    )}
   </AppModal>
  </>
 );
};

export default CreateNewSite;
