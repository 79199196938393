import React, { ReactElement } from "react";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

import Recharge from "components/profile/recharge/Recharge";

const RechargePage = (): ReactElement => {
 return (
  <Container>
   <Stack>
    <Recharge />
   </Stack>
  </Container>
 );
};

export default RechargePage;
