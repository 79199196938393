import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import SearchIcon from "@mui/icons-material/Search";

import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { getUsersList, postLogAsClient } from "redux/handlers/userHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IUsersList } from "types/api/userApiInterface";

import DisablePartner from "./actions/DisablePartner";
import EnablePartner from "./actions/EnablePartner";
import UpdateTax from "./actions/UpdateTax";

const Partner = (): ReactElement => {
 const dispatch = useAppDispatch();
 const navigate = useNavigate();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const [loading, setLoading] = useState<boolean>(false);
 const [partnerList, setPartnerList] = useState<Array<IUsersList>>([]);
 const [partnersToShow, setPartnersToShow] = useState<Array<IUsersList>>([]);
 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);
 const [searchValue, setSearchValue] = useState<string>("");

 useEffect(() => {
  (async () => {
   setLoading(true);
   const listedP = await dispatch(getUsersList());
   setPartnerList(listedP);
   setPartnersToShow(listedP.filter((element, index) => index < 10));
   setLoading(false);
  })();
 }, []);

 const checkExistingCompany = (
  company: string,
  firstname: string,
  lastname: string,
  search: string
 ) => {
  if (company) {
   return company.toLowerCase().includes(search);
  } else {
   return firstname.toLowerCase().includes(search) || lastname.toLowerCase().includes(search);
  }
 };

 const reloadData = async () => {
  setLoading(true);
  const listedP = await dispatch(getUsersList());
  setPartnerList(listedP);
  handleSearch(searchValue, currentPage, rowPerPage, listedP);
  setLoading(false);
 };

 const handleSearch = (search: string, page: number, rows: number, newList?: Array<IUsersList>) => {
  if (newList) {
   if (search === "") {
    setPartnersToShow(
     newList.filter((element, index) => index < page * rows + rows && index >= page * rows)
    );
   } else {
    setPartnersToShow(
     newList.filter((element, index) =>
      checkExistingCompany(element.companyName, element.firstname, element.lastname, search)
     )
    );
   }
  } else {
   if (search === "") {
    setPartnersToShow(
     partnerList.filter((element, index) => index < page * rows + rows && index >= page * rows)
    );
   } else {
    setPartnersToShow(
     partnerList.filter((element, index) =>
      checkExistingCompany(element.companyName, element.firstname, element.lastname, search)
     )
    );
   }
  }
 };

 const handleOnRowsPerPageChange = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const newRowsPerPage = parseInt(event.target.value, 10);
  setRowPerPage(newRowsPerPage);
  setCurrentPage(0);
  handleSearch(searchValue, 0, newRowsPerPage);
 };

 const handleOnPageChange = (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  setCurrentPage(newPage);
  handleSearch(searchValue, newPage, rowPerPage);
 };

 const logAsUser = async (userid: string) => {
  await dispatch(postLogAsClient(userid));
  navigate("/");
  window.location.reload();
 };

 return loading ? (
  <Stack direction="row" alignItems="center" justifyContent="center">
   <CircularProgress />
  </Stack>
 ) : (
  <Stack spacing={2}>
   <Stack justifyContent="space-between" direction="row">
    <Stack direction={desktopViewPort ? "row" : "column"} spacing={2} alignItems="center">
     <Typography>
      {partnerList.length} <FormattedMessage id="configurator.partner.total" />
     </Typography>
     <TextField
      size="small"
      autoComplete="new-password"
      label={<FormattedMessage id="configurator.search.partner" />}
      sx={{ ml: 4 }}
      InputLabelProps={{ shrink: true }}
      InputProps={{ startAdornment: <SearchIcon color="disabled" />, autoComplete: "off" }}
      onChange={({ currentTarget }) => {
       setSearchValue(currentTarget.value);
       handleSearch(currentTarget.value, currentPage, rowPerPage);
      }}
     />
    </Stack>
   </Stack>
   <Stack>
    <TableContainer component="div">
     <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
       <TableRow>
        <TableCell>
         <FormattedMessage id="configurator.partner.status" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="configurator.partner.company" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="configurator.partner.name" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="configurator.partner.balance" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="configurator.partner.additionalData" />
        </TableCell>
        <TableCell align="right">
         <FormattedMessage id="app.actions" />
        </TableCell>
       </TableRow>
      </TableHead>
      <TableBody>
       {partnersToShow.map((element, index) => {
        return (
         <TableRow key={`partner-config-list-${index}`}>
          <TableCell component="th" scope="row">
           <Chip
            label={element.active ? "online" : "disabled"}
            color={element.active ? "success" : "error"}
            size="small"
           />
          </TableCell>
          <TableCell component="th" scope="row">
           {element.companyName ? (
            <Stack spacing={1}>
             <Typography fontWeight="bold" variant="kxColored">
              {element.companyName}
             </Typography>
             <Stack direction="row">
              <Button onClick={() => logAsUser(element.userid)}>Login as user</Button>
             </Stack>
            </Stack>
           ) : (
            <Stack spacing={1}>
             <Typography fontWeight="bold" color="error" variant="subtitle2">
              Company not available
             </Typography>
             <Stack direction="row">
              <Button onClick={() => logAsUser(element.userid)}>Login as user</Button>
             </Stack>
            </Stack>
           )}
          </TableCell>
          <TableCell component="th" scope="row">
           <Typography variant="caption">{`${element.lastname} ${element.firstname}`}</Typography>
          </TableCell>
          <TableCell component="th" scope="row">
           <Stack>
            <Typography variant="caption">{`Balance: ${element.balance}€`}</Typography>
            <Typography variant="caption">{`Cash: ${element.cash}€`}</Typography>
            <Typography variant="caption">{`Bonus: ${element.bonus}€`}</Typography>
           </Stack>
          </TableCell>
          <TableCell component="th" scope="row">
           <Stack>
            <Typography variant="caption">{`Email: ${element.email}`}</Typography>
            <Typography variant="caption">{`Telefono: ${
             element.telephone || "Not available"
            }`}</Typography>
            <Typography variant="caption">{`IVA: ${element.tax}%`}</Typography>
           </Stack>
          </TableCell>
          <TableCell component="th" scope="row" align="right">
           <UpdateTax id={element.id} tax={element.tax} reload={reloadData} />
           {element.active ? (
            <DisablePartner id={element.id} reload={reloadData} />
           ) : (
            <EnablePartner id={element.id} reload={reloadData} />
           )}
          </TableCell>
         </TableRow>
        );
       })}
      </TableBody>
     </Table>
    </TableContainer>
    <TablePagination
     component="div"
     count={partnerList.length}
     page={currentPage}
     rowsPerPage={rowPerPage}
     onPageChange={handleOnPageChange}
     onRowsPerPageChange={handleOnRowsPerPageChange}
    />
   </Stack>
  </Stack>
 );
};

export default Partner;
