import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

export const getSessionState = (state: AppState) => state.sessions;

export const getPhpSession = createSelector(getSessionState, (sessions) => {
 return sessions?.phpActiveSession;
});

export const getFilemanagerSession = createSelector(getSessionState, (sessions) => {
 return sessions?.filemanagerActiveSession;
});
