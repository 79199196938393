import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { IHostApi } from "types/api/hostApiInterface";

const initialState: IHostApi = { dataset: [], totalCount: 0 };

const HostReducer = createSlice({
 name: "host",
 initialState,
 reducers: {
  setHost: (state, { payload }: PayloadAction<IHostApi>) => {
   return payload;
  },
  resetHost: () => {
   return initialState;
  }
 }
});

export default HostReducer;
