import React, { ReactElement, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { isNil } from "ramda";

import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";

import { countryCodes } from "constants/countryCode";
import { emailValidation } from "constants/regexp";

import PhoneInput from "components/shared/phoneInput/PhoneInput";

import { postChangeProfile } from "redux/handlers/userHandler";

import { getUserProfileData } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IProfile } from "types/redux/userInterfaces";

const ChangeProfile = ({ changeUpdate }: { changeUpdate: () => void }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));
 const profileData: IProfile = useSelector(getUserProfileData);

 const [isLoading, setIsLoading] = useState<boolean>(false);

 useEffect(() => {
  reset();
 }, []);

 const { control, handleSubmit, reset, formState, watch, getValues, setValue } = useForm({
  defaultValues: {
   firstname: profileData.firstname,
   lastname: profileData.lastname,
   companyName: profileData.companyName,
   email: profileData.email,
   phone: profileData.phone,
   street: profileData.street,
   city: profileData.city,
   country: profileData.country,
   zipCode: profileData.zipCode,
   vatCode: profileData.vatCode,
   sdi: profileData.sdi,
   pec: profileData.pec
  }
 });

 const onSubmit: SubmitHandler<IProfile> = async (data: IProfile) => {
  setIsLoading(true);
  await dispatch(postChangeProfile(data));
  setIsLoading(false);
 };

 const checkDisabled = (): boolean => {
  return (
   isNil(watch("firstname")) ||
   watch("firstname") === "" ||
   isNil(watch("lastname")) ||
   watch("lastname") === "" ||
   isNil(watch("companyName")) ||
   watch("companyName") === "" ||
   isNil(watch("email")) ||
   watch("email") === "" ||
   isNil(watch("phone")) ||
   watch("phone") === "" ||
   isNil(watch("street")) ||
   watch("street") === "" ||
   isNil(watch("city")) ||
   watch("city") === "" ||
   isNil(watch("country")) ||
   watch("country") === "" ||
   isNil(watch("zipCode")) ||
   watch("zipCode") === "" ||
   isNil(watch("vatCode")) ||
   watch("vatCode") === "" ||
   (isNil(watch("sdi")) && isNil(watch("pec"))) ||
   (watch("sdi") === "" && watch("pec") === "")
  );
 };

 return (
  <>
   <form onSubmit={handleSubmit(onSubmit)}>
    <Stack direction={desktopViewPort ? "row" : "column"} spacing={2} mb={3}>
     <Controller
      name="firstname"
      control={control}
      rules={{
       required: true,
       validate: () =>
        formState.dirtyFields.firstname &&
        getValues("firstname") !== "" &&
        getValues("firstname") !== intl.formatMessage({ id: "profile.dataNotFinalized" })
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "login.nameField" })}
        error={
         (formState.isDirty && !!formState?.errors?.firstname) ||
         isNil(watch("firstname")) ||
         watch("firstname") === ""
        }
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.firstname &&
         intl.formatMessage({ id: "profile.nameNotValid" })
        }
       />
      )}
     />
     <Controller
      name="lastname"
      control={control}
      rules={{
       required: true,
       validate: () =>
        formState.dirtyFields.lastname &&
        getValues("lastname") !== "" &&
        getValues("lastname") !== intl.formatMessage({ id: "profile.dataNotFinalized" })
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "login.surnameField" })}
        error={
         (formState.isDirty && !!formState?.errors?.lastname) ||
         isNil(watch("lastname")) ||
         watch("lastname") === ""
        }
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.lastname &&
         intl.formatMessage({ id: "profile.nameNotValid" })
        }
       />
      )}
     />
     <Controller
      name="email"
      control={control}
      rules={{
       required: true,
       pattern: emailValidation,
       validate: () =>
        formState.dirtyFields.email &&
        getValues("email") !== "" &&
        getValues("email") !== intl.formatMessage({ id: "profile.dataNotFinalized" })
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label="Email"
        error={
         (formState.isDirty && !!formState?.errors?.email) ||
         isNil(watch("email")) ||
         watch("email") === ""
        }
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.email &&
         intl.formatMessage({ id: "login.invalidMail" })
        }
       />
      )}
     />
    </Stack>
    <Stack direction={desktopViewPort ? "row" : "column"} spacing={2} mb={3}>
     <Controller
      name="phone"
      control={control}
      rules={{
       required: true,
       validate: () =>
        formState.dirtyFields.phone &&
        getValues("phone") !== "" &&
        getValues("phone") !== intl.formatMessage({ id: "profile.dataNotFinalized" })
      }}
      render={({ field }) => (
       <PhoneInput
        defaultValue={getValues("phone") || ""}
        placeholder={intl.formatMessage({ id: "profile.phoneNumber" })}
        onChange={(value) => {
         setValue("phone", value);
        }}
       />
      )}
     />
     <Controller
      name="street"
      control={control}
      rules={{
       required: true,
       validate: () =>
        formState.dirtyFields.street &&
        getValues("street") !== "" &&
        getValues("street") !== intl.formatMessage({ id: "profile.dataNotFinalized" })
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "profile.street" })}
        error={
         (formState.isDirty && !!formState?.errors?.street) ||
         isNil(watch("street")) ||
         watch("street") === ""
        }
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.street &&
         intl.formatMessage({ id: "profile.streetNotValid" })
        }
       />
      )}
     />
    </Stack>
    <Stack direction={desktopViewPort ? "row" : "column"} spacing={2} mb={3}>
     <Controller
      name="companyName"
      control={control}
      rules={{
       required: true,
       validate: () =>
        formState.dirtyFields.companyName &&
        getValues("companyName") !== "" &&
        getValues("companyName") !== intl.formatMessage({ id: "profile.dataNotFinalized" })
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "login.companyField" })}
        error={
         (formState.isDirty && !!formState?.errors?.companyName) ||
         isNil(watch("companyName")) ||
         watch("companyName") === ""
        }
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.companyName &&
         intl.formatMessage({ id: "profile.nameNotValid" })
        }
       />
      )}
     />
     <Controller
      name="city"
      control={control}
      rules={{
       required: true,
       validate: () =>
        formState.dirtyFields.city &&
        getValues("city") !== "" &&
        getValues("city") !== intl.formatMessage({ id: "profile.dataNotFinalized" })
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "profile.city" })}
        error={
         (formState.isDirty && !!formState?.errors?.city) ||
         isNil(watch("city")) ||
         watch("city") === ""
        }
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.city &&
         intl.formatMessage({ id: "profile.cityNotValid" })
        }
       />
      )}
     />
     <Controller
      /*TODO=> change field with dropdown with country */
      name="country"
      control={control}
      rules={{
       required: true,
       validate: () =>
        formState.dirtyFields.country &&
        getValues("country") !== "" &&
        getValues("country") !== intl.formatMessage({ id: "profile.dataNotFinalized" })
      }}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        defaultValue={countryCodes.find(
         (element) => element.code === getValues("country")?.toUpperCase()
        )}
        sx={{ my: 2 }}
        options={countryCodes}
        onChange={(e, value) => {
         setValue("country", isNil(value) ? "" : value.code);
        }}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          label={intl.formatMessage({ id: "profile.country" })}
          error={
           (formState.isDirty && !!formState?.errors?.country) ||
           isNil(watch("country")) ||
           watch("country") === ""
          }
          InputLabelProps={{ shrink: true }}
          helperText={
           formState.isDirty &&
           !!formState?.errors?.country &&
           intl.formatMessage({ id: "profile.countryNotValid" })
          }
         />
        )}
       />
      )}
     />
    </Stack>
    <Stack direction={desktopViewPort ? "row" : "column"} spacing={2}>
     <Controller
      name="zipCode"
      control={control}
      rules={{
       required: true,
       validate: () =>
        formState.dirtyFields.zipCode &&
        getValues("zipCode") !== "" &&
        getValues("zipCode") !== intl.formatMessage({ id: "profile.dataNotFinalized" })
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "profile.zipCode" })}
        error={
         (formState.isDirty && !!formState?.errors?.zipCode) ||
         isNil(watch("zipCode")) ||
         watch("zipCode") === ""
        }
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.zipCode &&
         intl.formatMessage({ id: "profile.zipCodeNotValid" })
        }
       />
      )}
     />
     <Controller
      name="vatCode"
      control={control}
      rules={{
       required: true,
       validate: () =>
        formState.dirtyFields.vatCode &&
        getValues("vatCode") !== "" &&
        getValues("vatCode") !== intl.formatMessage({ id: "profile.dataNotFinalized" })
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "profile.vatCode" })}
        error={
         (formState.isDirty && !!formState?.errors?.vatCode) ||
         isNil(watch("vatCode")) ||
         watch("vatCode") === ""
        }
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.vatCode &&
         intl.formatMessage({ id: "profile.vatCodeNotValid" })
        }
       />
      )}
     />
    </Stack>
    <Stack direction={desktopViewPort ? "row" : "column"} spacing={2} mt={3}>
     <Controller
      name="sdi"
      control={control}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        disabled={watch("pec") !== "" && !isNil(watch("pec"))}
        label={intl.formatMessage({ id: "profile.sdi" })}
        error={
         (formState.isDirty && !!formState?.errors?.sdi) ||
         (isNil(watch("sdi")) && isNil(watch("pec"))) ||
         (watch("sdi") === "" && watch("pec") === "")
        }
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.sdi &&
         intl.formatMessage({ id: "profile.sdiNotValid" })
        }
       />
      )}
     />
     <Controller
      name="pec"
      control={control}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        disabled={watch("sdi") !== "" && !isNil(watch("sdi"))}
        label={intl.formatMessage({ id: "profile.pec" })}
        error={
         (formState.isDirty && !!formState?.errors?.pec) ||
         (isNil(watch("sdi")) && isNil(watch("pec"))) ||
         (watch("sdi") === "" && watch("pec") === "")
        }
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.pec &&
         intl.formatMessage({ id: "profile.pecNotValid" })
        }
       />
      )}
     />
    </Stack>
    <Stack direction="row">
     <Button
      variant="kxActionButton"
      disabled={isLoading || checkDisabled()}
      onClick={handleSubmit(onSubmit)}>
      <FormattedMessage id="profile.updateProfile" />
     </Button>
     <Button variant="kxActionButton" onClick={changeUpdate}>
      <FormattedMessage id="app.back" />
     </Button>
    </Stack>
   </form>
  </>
 );
};

export default ChangeProfile;
