import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import notificationReducer from "redux/reducers/notificationReducer";

import { IProfileOrders } from "types/api/profileOrdersApiInterface";

import { ApiService } from "service/ApiService";

export const getProfileOrders =
 (
  currentIndex: number,
  sizePerPage: number,
  q?: string,
  partnerId?: string
 ): AppAction<Promise<IProfileOrders>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IProfileOrders>(
    `/order/allorders?page=${currentIndex}&limit=${sizePerPage}&q=${q || ""}&partnerId=${
     partnerId || ""
    }`
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load orders - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return { dataset: [], totalCount: 0 };
  }
 };

export const postCompleteTransaction =
 (
  id: number,
  status: string,
  payment_date: string,
  transaction: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/order/completemoneytransfer", "POST", {
    id: id,
    status: status,
    payment_date: payment_date,
    transaction: transaction || "Not provided"
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Order updated",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update order - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };
