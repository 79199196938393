import React, { ReactElement } from "react";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import ActionsMenu from "components/shared/listActions/ActionsMenu";

import { customerTableData } from "types/global/table";

const CustomersTable = ({
 rowData,
 index
}: {
 rowData: customerTableData;
 index: number;
}): ReactElement => {
 return (
  <TableRow key={`customers-list-${index}`}>
   <TableCell component="th" scope="row">
    {`${rowData.firstname} ${rowData.lastname}`}
   </TableCell>
   <TableCell component="th" scope="row">
    {rowData.company || "n/a"}
   </TableCell>
   <TableCell component="th" scope="row" align="right">
    {rowData.email}
   </TableCell>
   <TableCell component="th" scope="row" align="right">
    {rowData.phonenumber || "n/a"}
   </TableCell>
   <TableCell>
    <ActionsMenu section="customers" id={rowData.id} customer={rowData} />
   </TableCell>
  </TableRow>
 );
};

export default CustomersTable;
