/* eslint-disable react/style-prop-object */
import React, { ReactElement, useState } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Alert from "@mui/material/Alert";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postConvertBackup } from "redux/handlers/backupsHandler";

import { getUserBalance } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

const SnapshotConversion = ({
 id,
 reloadData,
 closeMenu
}: {
 id: number;
 reloadData: () => void;
 closeMenu: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const userBalance = useSelector(getUserBalance);

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postConvertBackup(id));
  setLoading(false);
  reloadData();

  handleClose();
  closeMenu();
 };

 return (
  <>
   <MenuItem onClick={handleOpen}>
    <FormattedMessage id="server.backups.convert" />
   </MenuItem>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "server.backups.conversionTitle" })}
    handleClose={handleClose}
    disabled={loading || userBalance < 2}
    handleConfirm={handleConfirm}>
    <Stack spacing={2}>
     <Typography>
      <FormattedMessage id="server.backups.conversionModalMessage" />
     </Typography>
     <Alert severity="info">ATTENZIONE! L'operazione ha il costo di 2€.</Alert>
     <Stack direction="row" spacing={2} alignItems="center">
      <Typography>
       <FormattedMessage id="app.balance" />
      </Typography>
      <Chip
       size="small"
       label={<FormattedNumber value={userBalance} style="currency" currency="EUR" />}
      />
     </Stack>
    </Stack>
   </AppModal>
  </>
 );
};

export default SnapshotConversion;
