import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";

import IconSelector from "components/shared/images/IconSelector";

import { IProjectApi } from "types/api/hetznerApi";
import { IUsersList } from "types/api/userApiInterface";

import DeleteProject from "./actions/DeleteProject";
import EditProject from "./actions/EditProject";

const HetznerMenu = ({
 contacts,
 projectData,
 id,
 reloadData
}: {
 contacts: Array<IUsersList>;
 projectData: IProjectApi;
 id: number;
 reloadData?: () => void;
}): ReactElement => {
 const [section, setSection] = useState<string>("");
 const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
 const open = Boolean(anchorEl);

 const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
  setSection("");
  setAnchorEl(null);
 };

 const RenderSection = (): ReactElement => {
  switch (section) {
   case "updateProject":
    return (
     <EditProject
      contacts={contacts}
      projectData={projectData}
      id={id}
      reloadData={reloadData}
      closeMenu={handleClose}
     />
    );
   case "deleteProject":
    return <DeleteProject id={id} reloadData={reloadData} closeMenu={handleClose} />;
   default:
    return <></>;
  }
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <IconSelector icon="MenuIcon" />
   </Button>
   <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
     "aria-labelledby": "basic-button"
    }}>
    <Stack>
     <MenuItem onClick={() => setSection("updateProject")}>
      <FormattedMessage id="configurator.edit.project" />
     </MenuItem>
     <MenuItem onClick={() => setSection("deleteProject")}>
      <FormattedMessage id="configurator.delete.project" />
     </MenuItem>
    </Stack>
   </Menu>
   <RenderSection />
  </>
 );
};

export default HetznerMenu;
