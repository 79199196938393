import React, { ReactElement } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { getServerNameFromId } from "helpers/addons";

import DvrIcon from "@mui/icons-material/Dvr";

import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { locationList } from "constants/locations";

import ChangeProtection from "components/addons/actions/ChangeProtection";
import FloatingIpActions from "components/addons/floaingIp/FloatingIpActions";
import AdminLogAsUser from "components/shared/admin/AdminLogAsUser";
import ClickToCopy from "components/shared/clipboardCopy/ClickToCopy";

import { IAllServers } from "types/api/serversApiInterface";
import { floatingIpTableData } from "types/global/table";

const FloatingIpTable = ({
 rowData,
 index,
 servers
}: {
 rowData: floatingIpTableData;
 index: number;
 servers: Array<IAllServers>;
}): ReactElement => {
 const RenderLocation = (location: string) => {
  const filteredLocation = locationList.filter((data) => location === data.tag);

  return filteredLocation.map((element, index) => (
   <Stack key={`location-item-${index}`} direction="row" spacing={1}>
    <Stack component="span" className={`fi fi-${element.flag}`} />
    <Typography variant="inherit">
     <FormattedMessage id={element.location} />
    </Typography>
   </Stack>
  ));
 };

 const colorDateFilter = (data: string | null) => {
  const newDate = new Date(data || "");
  if (newDate.getTime() > new Date().getTime()) {
   return "black";
  } else {
   return "#f44336";
  }
 };

 return (
  <TableRow key={`floatingip-list-${index}`}>
   <TableCell component="th" scope="row">
    <Stack direction="column">
     <Typography variant="inherit" fontWeight="bold">
      {rowData.name}
     </Typography>
     {RenderLocation(rowData.loacation_name)}
     <Stack direction="row" alignItems="center">
      <DvrIcon fontSize="small" sx={{ marginRight: 1 }} />
      <Typography variant="inherit">
       {!rowData.server ? (
        <FormattedMessage id="addons.floatingIp.notAssigned" />
       ) : (
        getServerNameFromId(rowData.server, servers)
       )}
      </Typography>
     </Stack>
     <Stack onClick={(event) => event.stopPropagation()} sx={{ cursor: "pointer" }}>
      <AdminLogAsUser userid={rowData.userid || ""} owner={rowData.personal_name || ""} />
     </Stack>
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    <Stack direction="row" spacing={1}>
     <Typography variant="inherit" fontWeight="bold" sx={{ display: "flex", flexDirection: "row" }}>
      {rowData.ip}
     </Typography>
     <ClickToCopy text={rowData.ip} />
    </Stack>
    <Typography variant="inherit">{rowData.dns_ptr}</Typography>
   </TableCell>
   <TableCell>
    <Typography fontSize={"small"} variant="caption" color={colorDateFilter(rowData.expire)}>
     {rowData.expire ? <FormattedDate value={rowData.expire} /> : "Not available"}
    </Typography>
   </TableCell>
   <TableCell>
    <ChangeProtection id={rowData.id} protection={rowData.protection} type="floatingip" />
   </TableCell>
   <TableCell>
    <FloatingIpActions rowData={rowData} />
   </TableCell>
  </TableRow>
 );
};

export default FloatingIpTable;
