import React, { ReactElement, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { AppContext } from "AppContext";
import { getJobInProgress } from "helpers/server";

import { isNil } from "ramda";

import Badge from "@mui/material/Badge";

import { getServerOrders } from "redux/handlers/hetznerOrders";

import { getServerJobsList } from "redux/selectors/serverJobQueueSelector";

import { useAppDispatch } from "hooks/reduxHook";

const JobQueueNotification = ({ children }: { children: ReactElement }): ReactElement => {
 const dispatch = useAppDispatch();
 const { socket } = useContext(AppContext);

 const serverJobList = useSelector(getServerJobsList);

 useEffect(() => {
  !isNil(socket) &&
   socket.on("server_jobs", async (value): Promise<void> => {
    if (value?.completed) {
     await dispatch(getServerOrders(0, 10));
    }
   });
  return () => {
   !isNil(socket) && socket.off("server_jobs");
  };
 }, [socket]);

 return getJobInProgress(serverJobList || []) !== 0 ? (
  <Badge badgeContent={getJobInProgress(serverJobList || [])} color="primary">
   {children}
  </Badge>
 ) : (
  <>{children}</>
 );
};

export default JobQueueNotification;
