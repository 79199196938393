import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const SubnetIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <path
   d="M9.38881 12.6122C9.43451 12.6483 9.4812 12.6826 9.52883 12.715C9.47005 12.7054 9.4119 12.6939 9.35444 12.6805L9.38881 12.6122ZM10.5715 12.6805C10.514 12.6939 10.4559 12.7054 10.3971 12.715C10.4447 12.6826 10.4914 12.6483 10.5371 12.6122L10.5715 12.6805ZM10.3971 7.28502C10.4559 7.2946 10.514 7.3061 10.5715 7.31946L10.5371 7.38779C10.4914 7.35171 10.4447 7.31743 10.3971 7.28502ZM9.35444 7.31946C9.4119 7.30611 9.47005 7.2946 9.52883 7.28502C9.4812 7.31743 9.43451 7.35171 9.38881 7.38779L9.35444 7.31946Z"
   stroke="currentColor"
   strokeWidth="1"
  />
  <path d="M7 5L2 10L7 15" stroke="currentColor" strokeWidth="1" />
  <path d="M13 5L18 10L13 15" stroke="currentColor" strokeWidth="1" />
 </svg>,
 "Subnet"
);
