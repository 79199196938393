import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import { domainValidation } from "constants/regexp";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postUpdateAlias } from "redux/handlers/websitesHandler";

import { getWebsitesDetails } from "redux/selectors/websitesSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IAliasData } from "types/api/websitesApiInterface";

const EditAlias = ({ aliasData }: { aliasData: IAliasData }): ReactElement => {
 const intl = useIntl();
 const websitesDetails = useSelector(getWebsitesDetails);
 const params = useParams<{ domain_id: string; section: string }>();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const { control, handleSubmit, formState } = useForm({
  defaultValues: {
   alias: aliasData.alias
  }
 });

 const onSubmit: SubmitHandler<{
  alias: string;
 }> = async (data: { alias: string }) => {
  setIsLoading(true);
  await dispatch(
   postUpdateAlias(
    aliasData.id,
    websitesDetails.isp_id,
    websitesDetails.domain_id,
    data.alias,
    aliasData.ssl,
    aliasData.active,
    Number(params?.domain_id)
   )
  );

  setIsLoading(false);
  setOpenModal(false);
 };

 return (
  <Stack maxWidth={600}>
   <Tooltip title={<FormattedMessage id="edit.site.alias" />}>
    <Button variant="kxActionButton" onClick={handleOpen} disabled={aliasData.alias_id === 0}>
     <IconSelector icon="EditIcon" />
    </Button>
   </Tooltip>
   <AppModal
    open={openModal}
    close={handleClose}
    loading={isLoading}
    title={intl.formatMessage({ id: "sites.aliasOperations" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}>
    <Stack justifyContent="center" alignContent="center" direction="column">
     <Controller
      name="alias"
      control={control}
      rules={{
       required: true,
       pattern: domainValidation
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "sites.aliasName" })}
        error={formState.isDirty && !!formState?.errors?.alias}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.alias &&
         intl.formatMessage({ id: "sites.aliasNameError" })
        }
       />
      )}
     />
    </Stack>
   </AppModal>
  </Stack>
 );
};

export default EditAlias;
