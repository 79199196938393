/* eslint-disable react/style-prop-object */
import React, { ReactElement, useState } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import AddIcon from "@mui/icons-material/Add";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postAddSnapshot, postCheckSnapshotPrice } from "redux/handlers/addonsHandle";

import { getUserBalance } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

const AddSnapshotForServer = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const userBalance = useSelector(getUserBalance);

 const [open, setOpen] = useState<boolean>(false);
 const [price, setPrice] = useState<number>(0);
 const [isLoading, setIsLoading] = useState<boolean>(false);

 const handleOpen = async () => {
  handleGetPrice();
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const handleGetPrice = async () => {
  setIsLoading(true);
  setPrice(await dispatch(postCheckSnapshotPrice(id)));
  setIsLoading(false);
 };

 const checkDisabledModal = (): boolean => {
  return price === 0 || userBalance < price || isLoading;
 };

 const handleSubmit = async () => {
  await dispatch(postAddSnapshot(id, price));
  handleClose();
 };

 return (
  <>
   <Button variant="kxActionButton" endIcon={<AddIcon />} onClick={() => handleOpen()}>
    <FormattedMessage id="addons.snapshot.addSnapshot" />
   </Button>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "addons.snapshot.addSnapshot" })}
    handleClose={handleClose}
    disabled={checkDisabledModal()}
    handleConfirm={handleSubmit}>
    {isLoading ? (
     <Stack direction="row" spacing={1}>
      <CircularProgress />
      <Alert severity="info">
       <FormattedMessage id="app.loadingData" />
      </Alert>
     </Stack>
    ) : (
     <Stack spacing={1}>
      <Typography>
       <FormattedMessage id="addons.snapshot.createSnapshotForServer" />
      </Typography>
      <Stack direction="row" spacing={2}>
       <Typography minWidth={150} textAlign="start" fontWeight="bold">
        <FormattedMessage id="server.add.balance" />
       </Typography>
       <Typography>
        <FormattedNumber value={userBalance} style={"currency"} currency="EUR" />
       </Typography>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
       <Typography minWidth={150} textAlign="start" fontWeight="bold">
        <FormattedMessage id="server.add.totalPrice" />
       </Typography>
       <Typography>
        <FormattedNumber value={price} style={"currency"} currency="EUR" />
       </Typography>
      </Stack>
     </Stack>
    )}
   </AppModal>
  </>
 );
};

export default AddSnapshotForServer;
