import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { generateUniqueId } from "helpers/generic";

import { Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

import AppModal from "components/shared/modal/AppModal";

import { deleteZone } from "redux/handlers/zoneHandler";

import notificationReducer from "redux/reducers/notificationReducer";

import { Record } from "types/api/appsApiInterface";

import Records from "./ZoneRecords";

const ZoneAction = ({
 zoneId,
 data,
 ispId,
 name
}: {
 zoneId: number;
 ispId: number;
 data: Record[];
 name: string;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [section, setSection] = useState<string>("authInfo");
 const [showConfirmButton, setShowConfirmButton] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = (currentSection: string) => {
  setSection(currentSection);
  setShowConfirmButton(currentSection === "delete");
  setOpenModal(true);
 };

 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  try {
   await deleteZone(ispId, zoneId);
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     timestamp: Date.now(),
     type: "success",
     message: "Zona eliminata con successo"
    })
   );
   handleClose();
  } catch (error) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     timestamp: Date.now(),
     type: "error",
     message: ""
    })
   );
  }
  setLoading(false);
 };

 const RenderModal = (): ReactElement => {
  switch (section) {
   case "delete":
    return (
     <Typography>
      <FormattedMessage id="zone.delete.message" />
     </Typography>
    );
   case "records":
    return <Records zoneId={zoneId} ispId={ispId} data={data} domain={name} />;
   default:
    return <></>;
  }
 };

 const RenderSection = (): ReactElement => {
  switch (section) {
   default:
    return (
     <AppModal
      open={openModal}
      close={handleClose}
      title={intl.formatMessage({ id: "record.operations" })}
      handleClose={handleClose}
      handleConfirm={handleConfirm}
      disabled={loading}
      customSize={{ maxWidth: 1500, width: 900, minWidth: 350, maxHeight: "100vh" }}
      showConfirmButton={showConfirmButton}
      cancelButtonText={intl.formatMessage({ id: "record.close" })}>
      <RenderModal />
     </AppModal>
    );
  }
 };

 return (
  <>
   <MenuItem onClick={() => handleOpen("records")}>
    <FormattedMessage id="zone.records" />
   </MenuItem>
   <MenuItem onClick={() => handleOpen("delete")}>
    <FormattedMessage id="zone.delete" />
   </MenuItem>
   <RenderSection />
  </>
 );
};

export default ZoneAction;
