import React, { ReactElement, useState } from "react";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";

import IconSelector from "components/shared/images/IconSelector";

import { ISpamData } from "types/api/gatewayApiInterface";

import SpamOperations from "./actions/SpamOperations";

const spamOp = ["delete", "deliver", "whitelist", "blacklist"];

const SpamActions = ({ id, rowData }: { id: number; rowData: ISpamData }): ReactElement => {
 const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
 const open = Boolean(anchorEl);

 const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
  setAnchorEl(null);
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <IconSelector icon="MenuIcon" />
   </Button>
   <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
     "aria-labelledby": "basic-button"
    }}>
    <Stack>
     {spamOp.map((action, index) => {
      return (
       <SpamOperations
        key={`spam-menu-${index}`}
        id={id}
        spamId={rowData.spam_id}
        action={action}
        closeMenu={handleClose}
       />
      );
     })}
    </Stack>
   </Menu>
  </>
 );
};

export default SpamActions;
