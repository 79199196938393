export const sxStyle = {
 pl: 2,
 py: 1,
 mr: 4,
 cursor: "pointer",
 borderTopRightRadius: 20,
 borderBottomRightRadius: 20,
 "&:hover": {
  backgroundColor: "rgba(231,231,231,0.58)"
 }
};
