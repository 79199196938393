import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { checkPort } from "helpers/addons";

import { isNil } from "ramda";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";

import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { firewallRuleProtocols } from "constants/addons";
import { ipRegex } from "constants/regexp";

import { postCreateFirewallRule } from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

const CreateRule = ({
 id,
 direction,
 closeCreation,
 confirmCreation
}: {
 id: number;
 direction: string;
 closeCreation: () => void;
 confirmCreation: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [ipList, setIpList] = useState<Array<string>>(["0.0.0.0/0"]);
 const [newIp, setNewIp] = useState<string>("");
 const [protocol, setProtocol] = useState<string>("");
 const [port, setPort] = useState<string>("");
 const [error, setError] = useState<string>("");

 const renderError = () => {
  switch (error) {
   case "length":
    return "addons.firewall.ipLengthError";
   case "format":
    return "addons.firewall.ipFormatError";
   case "void":
    return "addons.firewall.ipVoidError";
   case "existing":
    return "addons.firewall.ipExistingError";
   default:
    return "";
  }
 };

 const handleDeleteIp = (ipToDelete: string) => {
  if (ipList.length > 1) {
   const updatedList = ipList.filter((item) => item !== ipToDelete);
   setError("");
   setIpList(updatedList);
  } else {
   setError("length");
  }
 };

 const handleKeyDown = (event: any) => {
  if (event.key === "Enter") {
   if (
    newIp !== "" &&
    !ipList.includes(`${newIp}/${newIp === "0.0.0.0" || newIp === "::" ? "0" : "32"}`)
   ) {
    if (ipRegex.test(newIp)) {
     let newData = ipList;
     newData.push(`${newIp}/${newIp === "0.0.0.0" || newIp === "::" ? "0" : "32"}`);
     setIpList(newData);
     setNewIp("");
     setError("");
    } else if (newIp === "AnyIPv4") {
     let newData = ipList;
     newData.push("0.0.0.0/0");
     setIpList(newData);
     setNewIp("");
     setError("");
    } else if (newIp === "AnyIPv6") {
     let newData = ipList;
     newData.push("::/0");
     setIpList(newData);
     setNewIp("");
     setError("");
    } else {
     setError("format");
    }
   } else {
    if (newIp === "") {
     setError("void");
    } else {
     setError("existing");
    }
   }
  }
 };

 const disableConfirm = (): boolean => {
  return (
   ipList.length === 0 ||
   protocol === "" ||
   ((protocol === "TCP" || protocol === "UDP") && checkPort(port))
  );
 };

 const handleConfirm = async () => {
  await dispatch(postCreateFirewallRule(id, direction, port, protocol.toLowerCase(), ipList));
  confirmCreation();
 };

 return (
  <Stack
   direction="row"
   spacing={2}
   justifyContent="space-between"
   alignItems="center"
   sx={{ borderRadius: 2, border: "2px solid lightgrey", p: 2 }}>
   <Stack alignItems="flex-start" width="50%">
    <Typography variant="caption" fontWeight="bold">
     IP
    </Typography>
    <Stack direction="row" flexWrap="wrap" mb={1}>
     {ipList.map((ip, key) => {
      return (
       <Chip
        key={`ip-rule-${key}`}
        size="small"
        label={ip === "0.0.0.0/0" ? "AnyIPv4" : ip === "::/0" ? "AnyIPv6" : ip}
        sx={{ mr: 0.5, mt: 0.5 }}
        onClick={() => handleDeleteIp(ip)}
        onDelete={() => handleDeleteIp(ip)}
       />
      );
     })}
    </Stack>
    <TextField
     label={intl.formatMessage({ id: "addons.firewall.insertIps" })}
     variant="standard"
     fullWidth
     value={newIp}
     onChange={(e) => setNewIp(e.target.value)}
     onKeyDown={handleKeyDown}
     error={error !== ""}
     helperText={error !== "" ? <FormattedMessage id={renderError()} /> : ""}
    />
   </Stack>
   <Stack alignItems="flex-start" width="20%">
    <Autocomplete
     autoHighlight
     size="small"
     fullWidth
     value={protocol}
     onChange={(e, value) => {
      isNil(value) ? setProtocol("") : setProtocol(value);
      setPort("");
     }}
     options={firewallRuleProtocols}
     renderInput={(params) => (
      <TextField
       {...params}
       label={intl.formatMessage({ id: "addons.firewall.protocol" })}
       InputLabelProps={{ shrink: true }}
      />
     )}
    />
   </Stack>
   <Stack alignItems="flex-start" width="20%">
    <TextField
     value={port}
     disabled={protocol !== "TCP" && protocol !== "UDP"}
     onChange={(event) => setPort(event.target.value)}
     fullWidth={true}
     size="small"
     error={port !== "" && checkPort(port)}
     helperText={
      port !== "" && checkPort(port) ? (
       <FormattedMessage id="addons.firewall.portFormatError" />
      ) : (
       ""
      )
     }
     label={intl.formatMessage({ id: "addons.firewall.port" })}
    />
   </Stack>
   <Stack alignItems="flex-start" width="10%" direction={"row"}>
    <Tooltip title={intl.formatMessage({ id: "app.undo" })}>
     <IconButton color="error" onClick={closeCreation}>
      <DoDisturbOnIcon />
     </IconButton>
    </Tooltip>
    <Tooltip title={intl.formatMessage({ id: "app.confirm" })}>
     <Stack>
      <IconButton disabled={disableConfirm()} color="success" onClick={handleConfirm}>
       <CheckCircleIcon />
      </IconButton>
     </Stack>
    </Tooltip>
   </Stack>
  </Stack>
 );
};

export default CreateRule;
