import { isNil } from "ramda";

const byteOperator = 1024;

//It gets the value in bytes and return the formatted value, default value is in GB
export const formatBytes = (bytes: number, specific?: string): number => {
 if (isNil(specific)) {
  return bytes / Math.pow(byteOperator, 3);
 } else {
  switch (specific) {
   case "MB":
    return bytes / Math.pow(byteOperator, 2);
   case "GB":
    return bytes / Math.pow(byteOperator, 3);
   case "TB":
    return bytes / Math.pow(byteOperator, 4);
   default:
    return bytes;
  }
 }
};

//It gets the value in mb and returns the formatted value, default value is in GB
export const formatMegaBytes = (mb: number, specific?: string): number => {
 if (isNil(specific)) {
  return mb / Math.pow(byteOperator, 1);
 } else {
  switch (specific) {
   case "KB":
    return mb * byteOperator;
   case "GB":
    return mb / Math.pow(byteOperator, 1);
   case "TB":
    return mb / Math.pow(byteOperator, 2);
   default:
    return mb / Math.pow(byteOperator, 1);
  }
 }
};

//It gets the value of gigabites and return the formatting data. The default is the gb value
export const formatGigaBytes = (gb: number, specific?: string): number => {
 if (isNil(specific)) {
  return gb;
 } else {
  switch (specific) {
   case "KB":
    return gb * Math.pow(byteOperator, 2);
   case "MB":
    return gb * Math.pow(byteOperator, 1);
   case "TB":
    return gb / Math.pow(byteOperator, 1);
   default:
    return gb;
  }
 }
};

export const formatDate = (date: string): string => {
 const formattedDate = new Date(date);
 return formattedDate.toLocaleDateString();
};

export const formatExpirationDate = (date: string): string => {
 const formattedDate = new Date(date);
 if (formattedDate.getMonth() === 12) formattedDate.setMonth(0);
 else formattedDate.setMonth(formattedDate.getMonth() + 1);

 return formattedDate.toLocaleDateString();
};

export const formatExpireColor = (expDate: string): boolean => {
 const formattedDate = new Date(expDate);
 if (formattedDate.getMonth() === 12) formattedDate.setMonth(0);
 else formattedDate.setMonth(formattedDate.getMonth() + 1);

 return formattedDate > new Date();
};
