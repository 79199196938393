import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const FirewallIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <path
   d="M3.4812 9.44074C3.69552 8.26111 4.22555 7.16363 5.01239 6.26387C5.65134 7.0805 6.46097 7.74953 7.38603 8.22333C7.46292 8.26271 7.55468 8.25961 7.62873 8.21513C7.70278 8.17065 7.74862 8.09109 7.74997 8.00472C7.76657 6.94035 8.01782 5.89275 8.48578 4.93663C8.91645 4.05672 9.5209 3.27424 10.2617 2.63592C10.9182 3.45137 11.7478 4.11226 12.6918 4.56994C12.698 4.57295 12.7043 4.57569 12.7107 4.57817C14.0908 5.1979 15.2174 6.27128 15.9032 7.62008C16.5907 8.97246 16.7935 10.5198 16.4774 12.0037C16.1614 13.4875 15.3458 14.818 14.1668 15.7729C12.9879 16.7277 11.517 17.2491 9.99986 17.25C8.70206 17.2501 7.43281 16.8689 6.34971 16.154C5.26657 15.439 4.4173 14.4216 3.90734 13.2281C3.39739 12.0347 3.24921 10.7177 3.4812 9.44074Z"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
  <path
   d="M10.0004 14.75H9.99994C9.54528 14.7501 9.09708 14.6424 8.69213 14.4357C8.28719 14.2289 7.93704 13.9291 7.67044 13.5608C7.40383 13.1926 7.22838 12.7663 7.15847 12.317C7.10606 11.9801 7.11411 11.6375 7.18134 11.3051C7.66297 11.6123 8.1924 11.839 8.74883 11.9753C8.81688 11.992 8.88881 11.9794 8.94709 11.9405C9.00538 11.9016 9.04466 11.84 9.05539 11.7708C9.21731 10.7252 9.72406 9.76495 10.4933 9.04176C11.1729 9.15882 11.7892 9.51663 12.2281 10.0518C12.6913 10.6165 12.9222 11.3364 12.8739 12.0652C12.8256 12.794 12.5017 13.4771 11.968 13.9757C11.4343 14.4744 10.7308 14.7512 10.0004 14.75Z"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
 </svg>,
 "Firewall"
);
