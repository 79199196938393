import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const TeamsIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <circle cx="10" cy="10" r="10" fill="transparent" />
  <path
   d="M11.0581 3.658C11.1961 3.03805 10.8073 2.4156 10.1896 2.26772C9.57195 2.11984 8.95935 2.50252 8.82133 3.12248C8.68331 3.74243 9.07215 4.36488 9.68982 4.51276C10.3075 4.66064 10.9201 4.27795 11.0581 3.658Z"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
  <path
   d="M17.0581 10.658C17.1961 10.0381 16.8073 9.4156 16.1896 9.26772C15.572 9.11984 14.9593 9.50252 14.8213 10.1225C14.6833 10.7424 15.0722 11.3649 15.6898 11.5128C16.3075 11.6606 16.9201 11.278 17.0581 10.658Z"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
  <path
   d="M5.05812 10.658C5.19613 10.0381 4.80729 9.4156 4.18962 9.26772C3.57195 9.11984 2.95935 9.50252 2.82133 10.1225C2.68331 10.7424 3.07215 11.3649 3.68982 11.5128C4.30749 11.6606 4.9201 11.278 5.05812 10.658Z"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
  <path
   d="M8 6.43552C8 6.43552 8.22857 5.54663 10.0286 5.54663C11.8286 5.54663 12 6.43552 12 6.43552"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
  <path
   d="M14 13.4355C14 13.4355 14.2286 12.5466 16.0286 12.5466C17.8286 12.5466 18 13.4355 18 13.4355"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
  <path
   d="M2 13.4355C2 13.4355 2.22857 12.5466 4.02857 12.5466C5.82857 12.5466 6 13.4355 6 13.4355"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
  <path
   d="M6.57143 5C5.38993 5.77528 4.47531 6.93953 4 8.31621M5.258 14.8291C6.42292 16.1615 8.11581 17 10 17C11.8842 17 13.5771 16.1615 14.742 14.8291M16 8.31621C15.5247 6.93953 14.6101 5.77528 13.4286 5"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
 </svg>,
 "Teams"
);
