import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { websiteContainerCronjobSteps } from "constants/server";

type cronType = {
 seconds: string;
 minutes: string;
 hours: string;
 days: string;
 month: string;
};

const CronTiming = ({
 activeStep,
 firstValue,
 handleNext,
 handleBack,
 handleReset,
 setPropsValue
}: {
 activeStep: number;
 firstValue: cronType;
 handleNext: () => void;
 handleBack: () => void;
 handleReset: () => void;
 setPropsValue: (value: cronType) => void;
}): ReactElement => {
 const [timingSec, setTimingSec] = useState<string>(firstValue.seconds);
 const [timingMin, setTimingMin] = useState<string>(firstValue.minutes);
 const [timingHour, setTimingHour] = useState<string>(firstValue.hours);
 const [timingDay, setTimingDay] = useState<string>(firstValue.days);
 const [timingMonth, setTimingMonth] = useState<string>(firstValue.month);

 const goNext = () => {
  setPropsValue({
   minutes: timingMin,
   seconds: timingSec,
   hours: timingHour,
   days: timingDay,
   month: timingMonth
  });
  handleNext();
 };

 return (
  <Stack p={2} spacing={2}>
   <Stack spacing={1}>
    <TextField
     size="small"
     fullWidth={true}
     value={timingSec}
     label={<FormattedMessage id="sites.cronjob.run_sec" />}
     helperText={<FormattedMessage id="sites.cronjob.run_minHelper" />}
     InputLabelProps={{ shrink: true }}
     onChange={({ currentTarget }) => setTimingSec(currentTarget.value)}
     autoComplete="off"
    />
    <TextField
     size="small"
     fullWidth={true}
     value={timingMin}
     label={<FormattedMessage id="sites.cronjob.run_min" />}
     helperText={<FormattedMessage id="sites.cronjob.run_minHelper" />}
     onChange={({ currentTarget }) => setTimingMin(currentTarget.value)}
     InputLabelProps={{ shrink: true }}
     autoComplete="off"
    />
    <TextField
     size="small"
     fullWidth={true}
     value={timingHour}
     label={<FormattedMessage id="sites.cronjob.run_hour" />}
     helperText={<FormattedMessage id="sites.cronjob.run_hourHelper" />}
     onChange={({ currentTarget }) => setTimingHour(currentTarget.value)}
     InputLabelProps={{ shrink: true }}
     autoComplete="off"
    />
    <TextField
     size="small"
     fullWidth={true}
     value={timingDay}
     label={<FormattedMessage id="sites.cronjob.run_mday" />}
     helperText={<FormattedMessage id="sites.cronjob.run_mdayHelper" />}
     onChange={({ currentTarget }) => setTimingDay(currentTarget.value)}
     InputLabelProps={{ shrink: true }}
     autoComplete="off"
    />
    <TextField
     size="small"
     fullWidth={true}
     value={timingMonth}
     label={<FormattedMessage id="sites.cronjob.run_month" />}
     helperText={<FormattedMessage id="sites.cronjob.run_monthHelper" />}
     onChange={({ currentTarget }) => setTimingMonth(currentTarget.value)}
     InputLabelProps={{ shrink: true }}
     autoComplete="off"
    />
   </Stack>
   <Stack direction="row" spacing={2} justifyContent="center">
    <Button disabled={activeStep === 0} onClick={handleBack}>
     <FormattedMessage id="app.back" />
    </Button>
    <Button onClick={handleReset}>Reset</Button>
    <Button
     disabled={
      activeStep === websiteContainerCronjobSteps.length - 1 ||
      timingSec === "" ||
      timingMin === "" ||
      timingHour === "" ||
      timingDay === "" ||
      timingMonth === ""
     }
     onClick={goNext}>
     <FormattedMessage id="app.next" />
    </Button>
   </Stack>
  </Stack>
 );
};

export default CronTiming;
