import React, { ReactElement } from "react";
import useIntl from "react-intl/src/components/useIntl";

import Button from "@mui/material/Button";

import AdminWrapper from "components/shared/admin/AdminWrapper";

import RectifyWebmail from "./RectifyWebmail";

const ServiceRectify = ({ service }: { service: string }): ReactElement => {
 const intl = useIntl();

 const RenderConfig = (): ReactElement => {
  switch (service) {
   case "Webmail":
    return (
     <AdminWrapper>
      <RectifyWebmail service={service} />
     </AdminWrapper>
    );
   default:
    return (
     <Button variant="kxActionButton">
      {intl.formatMessage({ id: "server.service.correct" })} {service}
     </Button>
    );
  }
 };
 return <RenderConfig />;
};

export default ServiceRectify;
