import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import { isNil } from "ramda";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import AppModal from "components/shared/modal/AppModal";

import { getMailAutoresponder, postEditMailAutoresponder } from "redux/handlers/mailsHandler";

import { getMailsList } from "redux/selectors/mailsSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IMailbox } from "types/api/mailApiInterface";

const MailAutoresponder = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const params = useParams<{ mail_id: string; section: string }>();
 const mailList = useSelector(getMailsList);
 const maildetails = mailList.find((element) => element.id === Number(params.mail_id));

 const [object, setObject] = useState<string>("");
 const [textBody, setTextBody] = useState<string>("");
 const [enable, setEnable] = useState<boolean>(false);
 const [startTime, setStartTime] = useState(dayjs().subtract(1, "day"));
 const [endTime, setEndTime] = useState(dayjs());
 const [loading, setLoading] = useState<boolean>(true);
 const [buttonClicked, setButtonClicked] = useState<boolean>(false);
 const [mailboxes, setMailboxes] = useState<Array<IMailbox>>(maildetails?.mailbox || []);
 const [selectedMailboxId, setSelectedMailboxId] = useState<number>(0);
 const [loadingResponse, setLoadingResponse] = useState<boolean>(false);
 const [open, setOpen] = useState<boolean>(false);

 useEffect(() => {
  if (maildetails?.mailbox) {
   setMailboxes(maildetails?.mailbox || []);
  }
 }, [maildetails]);

 useEffect(() => {
  (async () => {
   if (mailboxes.length > 0) {
    setSelectedMailboxId(mailboxes[0].id);
    const autoresData = await dispatch(getMailAutoresponder(mailboxes[0].id));
    setTextBody(autoresData?.autoresponder_text || "");
    setObject(autoresData?.autoresponder_subject || "");
    setEnable(autoresData?.autoresponder || false);
    setEndTime(dayjs(autoresData?.autoresponder_end_date));
    setStartTime(dayjs(autoresData?.autoresponder_start_date));
   }
   setLoading(false);
  })();
 }, []);

 const handleChangeMailbox = async (id: number) => {
  setSelectedMailboxId(id);
  const autoresData = await dispatch(getMailAutoresponder(id));
  setTextBody(autoresData?.autoresponder_text || "");
  setObject(autoresData?.autoresponder_subject || "");
  setEnable(autoresData?.autoresponder || false);
  setEndTime(dayjs(autoresData?.autoresponder_end_date));
  setStartTime(dayjs(autoresData?.autoresponder_start_date));
 };

 const handleOpen = () => {
  setButtonClicked(true);
  if (!checkDisabled()) setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoadingResponse(true);
  await dispatch(
   postEditMailAutoresponder(
    selectedMailboxId,
    enable,
    object,
    textBody,
    startTime.format("YYYY-MM-DD HH:mm:ss"),
    endTime.format("YYYY-MM-DD HH:mm:ss")
   )
  );
  setLoadingResponse(false);
  handleClose();
 };

 const checkDisabled = (): boolean => {
  return object === "" || textBody === "" || !startTime || !endTime;
 };

 return loading ? (
  <CircularProgress />
 ) : (
  <Stack p={2} spacing={2}>
   <Stack>
    <Alert severity="info">
     <FormattedMessage id="mails.autoresponder.instruction" />
    </Alert>
   </Stack>
   <Stack spacing={2}>
    <Autocomplete
     autoHighlight
     sx={{ width: "100%" }}
     onChange={(e, value) =>
      !isNil(value)
       ? handleChangeMailbox(mailboxes.find((mailbox) => mailbox.id === value?.id)?.id || 0)
       : handleChangeMailbox(mailboxes[0].id)
     }
     options={mailboxes.map((mailbox) => {
      return { label: mailbox.email, id: mailbox.id };
     })}
     value={{
      label: mailboxes.find((mailbox) => mailbox.id === selectedMailboxId)?.email,
      id: mailboxes.find((mailbox) => mailbox.id === selectedMailboxId)?.id
     }}
     renderInput={(params) => (
      <TextField
       {...params}
       label={<FormattedMessage id="mails.mailboxName" />}
       fullWidth={true}
       InputLabelProps={{ shrink: true }}
      />
     )}
    />
    <TextField
     label={intl.formatMessage({ id: "mails.autoresponder.object" })}
     fullWidth
     value={object}
     onChange={(e) => setObject(e.target.value)}
     error={buttonClicked && object === ""}
     helperText={<FormattedMessage id="mails.autoresponder.objectInstruction" />}
    />
    <TextField
     label={intl.formatMessage({ id: "mails.autoresponder.textBody" })}
     fullWidth
     multiline
     minRows={4}
     value={textBody}
     onChange={(e) => setTextBody(e.target.value)}
     error={buttonClicked && textBody === ""}
     helperText={<FormattedMessage id="mails.autoresponder.textBodyInstruction" />}
    />
    <FormControlLabel
     control={
      <Checkbox
       checked={enable}
       onChange={(e) => {
        setEnable(e.target.checked);
       }}
      />
     }
     label={intl.formatMessage({ id: "mails.autoresponder.enableAutoresponse" })}
    />
    <Stack direction="row" spacing={1}>
     <Stack direction="row" alignItems="center">
      <DatePicker
       label={<FormattedMessage id="mails.autoresponder.startDate" />}
       value={startTime}
       disablePast
       onChange={(newValue) => setStartTime(newValue || dayjs())}
      />
     </Stack>
     <Stack direction="row" alignItems="center">
      <DatePicker
       label={<FormattedMessage id="mails.autoresponder.endDate" />}
       value={endTime}
       minDate={startTime}
       disablePast
       onChange={(newValue) => setEndTime(newValue || dayjs())}
      />
     </Stack>
    </Stack>
    <Stack direction="row">
     <Button disabled={loadingResponse} onClick={handleOpen}>
      <FormattedMessage id="app.confirm" />
     </Button>
     <AppModal
      open={open}
      close={handleClose}
      disabled={loadingResponse}
      title={intl.formatMessage({ id: "mails.autoresponder.confirmChanges" })}
      handleClose={handleClose}
      handleConfirm={handleConfirm}>
      <FormattedMessage id="mails.autoresponder.confirmChangesMessage" />
     </AppModal>
    </Stack>
   </Stack>
  </Stack>
 );
};

export default MailAutoresponder;
