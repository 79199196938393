import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const ServiceIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <circle cx="10" cy="10" r="10" fill="transparent" />
  <path
   d="M17.75 9.65714V10.3429C17.75 10.8905 17.2905 11.35 16.7429 11.35C15.4811 11.35 14.9389 12.8746 15.7555 13.7089C16.1408 14.1996 16.1139 14.8469 15.7661 15.1947L15.2048 15.7559C14.717 16.1385 14.0748 16.114 13.7257 15.7721C13.3038 15.2868 12.7014 15.2192 12.2158 15.4274C11.7323 15.6346 11.35 16.1197 11.35 16.7429C11.35 17.2905 10.8905 17.75 10.3429 17.75H9.65714C9.1095 17.75 8.65 17.2905 8.65 16.7429C8.65 15.4811 7.12536 14.9389 6.29109 15.7555C5.80038 16.1408 5.15312 16.1139 4.80535 15.7661L4.24412 15.2048C3.86146 14.717 3.88597 14.0748 4.22791 13.7257C4.71316 13.3038 4.78077 12.7014 4.57264 12.2158C4.36543 11.7323 3.8803 11.35 3.25714 11.35C2.7095 11.35 2.25 10.8905 2.25 10.3429V9.65714C2.25 9.1095 2.7095 8.65 3.25714 8.65C4.51895 8.65 5.06112 7.12536 4.24453 6.29109C3.85921 5.80038 3.88614 5.15312 4.23392 4.80535L4.79515 4.24412C5.28296 3.86146 5.92523 3.88597 6.2743 4.22791C6.69618 4.71316 7.29857 4.78077 7.78419 4.57264C8.26768 4.36543 8.65 3.8803 8.65 3.25714C8.65 2.7095 9.1095 2.25 9.65714 2.25H10.3429C10.8905 2.25 11.35 2.7095 11.35 3.25714C11.35 4.51895 12.8746 5.06112 13.7089 4.24453C14.1996 3.85921 14.8469 3.88614 15.1947 4.23392L15.7559 4.79515C16.1385 5.28296 16.114 5.92523 15.7721 6.2743C15.2868 6.69618 15.2192 7.29857 15.4274 7.78419C15.6346 8.26768 16.1197 8.65 16.7429 8.65C17.2905 8.65 17.75 9.1095 17.75 9.65714Z"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
  <path
   d="M8.65451 12.75L7.27951 10L8.65451 7.25H11.3455L12.7205 10L11.3455 12.75H8.65451Z"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
  <path
   d="M10.75 10C10.75 10.4334 10.4334 10.75 10 10.75C9.56664 10.75 9.25 10.4334 9.25 10C9.25 9.56664 9.56664 9.25 10 9.25C10.4334 9.25 10.75 9.56664 10.75 10Z"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
 </svg>,
 "Service"
);
