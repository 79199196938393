import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const DbRecap = ({
 name,
 username,
 password,
 type,
 handleBack,
 handleReset
}: {
 name: string;
 username: string;
 password: string;
 type: string;
 handleBack: () => void;
 handleReset: () => void;
}): ReactElement => {
 return (
  <Stack p={2} spacing={2}>
   <Stack spacing={1}>
    <Typography>
     <FormattedMessage id="docker.website.checkForData" />
    </Typography>
    <Stack direction="row" spacing={1}>
     <Typography>
      <FormattedMessage id="docker.website.databaseName" />:
     </Typography>
     <Typography>{name}</Typography>
    </Stack>
    <Stack direction="row" spacing={1}>
     <Typography>
      <FormattedMessage id="docker.website.databaseUser" />:
     </Typography>
     <Typography>{username}</Typography>
    </Stack>
    <Stack direction="row" spacing={1}>
     <Typography>
      <FormattedMessage id="docker.website.databasePassword" />:
     </Typography>
     <Typography>{password}</Typography>
    </Stack>
    <Stack direction="row" spacing={1}>
     <Typography>
      <FormattedMessage id="docker.website.databaseType" />:
     </Typography>
     <Typography>{type}</Typography>
    </Stack>
   </Stack>
   <Stack direction="row" spacing={2} justifyContent="center">
    <Button onClick={handleBack}>
     <FormattedMessage id="app.back" />
    </Button>
    <Button onClick={handleReset}>Reset</Button>
    <Button disabled>
     <FormattedMessage id="app.next" />
    </Button>
   </Stack>
  </Stack>
 );
};

export default DbRecap;
