import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import { domainValidation } from "constants/regexp";

import AppModal from "components/shared/modal/AppModal";

import { postAddAlias } from "redux/handlers/websitesHandler";

import { getWebsitesDetails } from "redux/selectors/websitesSelector";

import { useAppDispatch } from "hooks/reduxHook";

const AddAlias = ({ type }: { type: string }): ReactElement => {
 const intl = useIntl();
 const websitesDetails = useSelector(getWebsitesDetails);
 const params = useParams<{ domain_id: string; section: string }>();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);

 const handleOpen = () => {
  reset();
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const { control, handleSubmit, formState, reset } = useForm({
  defaultValues: {
   name: ""
  }
 });

 const onSubmit: SubmitHandler<{
  name: string;
 }> = async (data: { name: string }) => {
  setIsLoading(true);
  await dispatch(
   postAddAlias(
    data.name,
    websitesDetails.isp_id,
    websitesDetails.domain_id,
    Number(params.domain_id)
   )
  );

  setIsLoading(false);
  setOpenModal(false);
 };

 return (
  <>
   {type === "icon" ? (
    <Stack maxWidth={600}>
     <Tooltip title={<FormattedMessage id="sites.create.alias" />}>
      <Button variant="kxActionButton" onClick={handleOpen}>
       <AddIcon />
      </Button>
     </Tooltip>
    </Stack>
   ) : (
    <Stack alignItems="center" p={2} justifyContent="center">
     <Button variant="kxActionButton" onClick={handleOpen}>
      <FormattedMessage id="sites.create.alias" />
      <AddIcon />
     </Button>
    </Stack>
   )}
   <AppModal
    open={openModal}
    close={handleClose}
    loading={isLoading}
    title={intl.formatMessage({ id: "sites.aliasOperations" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}>
    <Stack justifyContent="center" alignContent="center" direction="column">
     <Alert severity="info">
      <FormattedMessage id="sites.aliasNameFormatted" />
     </Alert>
     <Controller
      name="name"
      control={control}
      rules={{
       required: true,
       pattern: domainValidation
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "sites.alias.aliasName" })}
        error={formState.isDirty && !!formState?.errors?.name}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="new-password"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.name &&
         intl.formatMessage({ id: "sites.alias.aliasNameError" })
        }
       />
      )}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default AddAlias;
