import React, { ReactElement, useEffect, useRef, useState } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useSelector } from "react-redux";
import useDebounce from "react-use/lib/useDebounce";
import { handleChangePageNumber } from "helpers/pagination";

import SearchIcon from "@mui/icons-material/Search";

import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import AddTeamsUser from "components/profile/teams/actions/AddTeamsUser";
import ActionsMenu from "components/shared/listActions/ActionsMenu";

import { getAllSecondLevelUsers } from "redux/handlers/teamsHandler";

import { getTeamsDataSet, getTeamsNumber } from "redux/selectors/teamsSelector";
import { getUserId } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import SetTeamsPreferences from "./actions/SetTeamsPreferences";

const Teams = (): ReactElement => {
 const searchRef = useRef<HTMLInputElement>(null);
 const dispatch = useAppDispatch();

 const teamsList = useSelector(getTeamsDataSet);
 const teamsNumber = useSelector(getTeamsNumber);
 const userId: number = useSelector(getUserId);

 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);
 const [searchValue, setSearchValue] = useState<string>("");
 const [isLoading, setIsLoading] = useState<boolean>(true);

 useEffect(() => {
  (async () => {
   setIsLoading(false);
  })();
 }, []);

 // eslint-disable-next-line no-unused-vars
 useDebounce(
  () => {
   if (!isLoading) {
    setCurrentPage(0);
    dispatch(getAllSecondLevelUsers(currentPage, rowPerPage, userId, searchValue));
   }
  },
  1000,
  [searchValue]
 );

 // TODO: da verificare
 const handleOnRowsPerPageChange = async (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const data = handleChangePageNumber(parseInt(event.target.value, 10), currentPage, rowPerPage);
  setRowPerPage(data.newRow);
  setCurrentPage(Number(data.newPage.toFixed(0)));
  await dispatch(
   getAllSecondLevelUsers(
    Number(data.newPage.toFixed(0)),
    parseInt(event.target.value, 10),
    userId,
    searchRef?.current?.value
   )
  );
 };

 const handleOnPageChange = async (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  setCurrentPage(newPage);
  await dispatch(getAllSecondLevelUsers(newPage, rowPerPage, userId, searchRef?.current?.value));
 };

 return (
  <Stack>
   <Paper elevation={0} sx={{ borderRadius: "10px", boxShadow: 0 }}>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
     <Stack justifyContent="flex-start" alignItems="center" p={2} direction="row">
      <Typography variant="subtitle2">
       <FormattedNumber value={teamsNumber || 0} /> <FormattedMessage id="app.totalTeams" />
      </Typography>
      <TextField
       size="small"
       autoComplete="off"
       inputRef={searchRef}
       label={<FormattedMessage id="search.teams" />}
       sx={{ ml: 4 }}
       value={searchValue}
       InputLabelProps={{ shrink: true }}
       InputProps={{ startAdornment: <SearchIcon color="disabled" /> }}
       onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
      />
     </Stack>
     <Stack direction="row" spacing={2}>
      <SetTeamsPreferences />
      <AddTeamsUser />
     </Stack>
    </Stack>
    <Divider />
    <Stack>
     <TableContainer component="div">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
       <TableHead>
        <TableRow sx={{ backgroundColor: "transparent" }}>
         <TableCell>
          <Typography variant="kxColoredSmall" fontWeight="bold">
           <FormattedMessage id="login.emailField" />
          </Typography>
         </TableCell>
         <TableCell align="right">
          <Typography variant="kxColoredSmall" fontWeight="bold">
           <FormattedMessage id="table.actions" />
          </Typography>
         </TableCell>
        </TableRow>
       </TableHead>
       <TableBody>
        {teamsList.map((row, index) => (
         <TableRow key={`teams-list-${index}`}>
          <TableCell component="th" scope="row">
           {row.email || "n/a"}
          </TableCell>
          <TableCell component="th" scope="row" align="right">
           <ActionsMenu section="teams" id={row.id} teamsUser={row.email || ""} />
          </TableCell>
         </TableRow>
        ))}
       </TableBody>
      </Table>
     </TableContainer>
     <TablePagination
      component="div"
      count={teamsNumber || 0}
      page={currentPage}
      rowsPerPage={rowPerPage}
      onPageChange={handleOnPageChange}
      onRowsPerPageChange={handleOnRowsPerPageChange}
     />
    </Stack>
   </Paper>
  </Stack>
 );
};

export default Teams;
