import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import AppModal from "components/shared/modal/AppModal";

import { postUpdateDatabaseUser } from "redux/handlers/websitesHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IDbData } from "types/api/websitesApiInterface";

const EditDbUser = ({
 selectedDb,
 domain_id
}: {
 selectedDb: IDbData;
 domain_id: number;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);

 const handleOpen = () => {
  reset();
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const { control, handleSubmit, formState, reset } = useForm({
  defaultValues: {
   username: selectedDb.db_user?.database_user || ""
  }
 });

 const onSubmit: SubmitHandler<{ username: string }> = async (data: { username: string }) => {
  setIsLoading(true);
  await dispatch(
   postUpdateDatabaseUser(
    domain_id,
    data.username,
    selectedDb.db_user?.database_password || "",
    selectedDb.isp_id,
    selectedDb.db_user?.id || 0
   )
  );
  setIsLoading(false);
  setOpenModal(false);
 };

 return (
  <>
   <Stack>
    <Tooltip title={<FormattedMessage id="sites.edit.dbUsername" />}>
     <Button
      variant="kxActionButton"
      onClick={handleOpen}
      disabled={selectedDb.id === 0 || !selectedDb.db_user || selectedDb.db_user?.id === 0}>
      <ManageAccountsIcon />
     </Button>
    </Tooltip>
   </Stack>
   <AppModal
    open={openModal}
    close={handleClose}
    loading={isLoading}
    title={intl.formatMessage({ id: "sites.edit.dbUsername" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}>
    <Stack>
     <Controller
      name="username"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "sites.dbUser" })}
        error={formState.isDirty && !!formState?.errors?.username}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="new-password"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.username &&
         intl.formatMessage({ id: "sites.dbUserError" })
        }
       />
      )}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default EditDbUser;
