import React, { ReactElement } from "react";

import MenuItem from "@mui/material/MenuItem";

import { hostData } from "types/global/user";

import DeleteHost from "./actions/DeleteHost";
import UpdateHost from "./actions/UpdateHost";

const HostActions = ({ host }: { host?: hostData }): ReactElement => {
 return (
  <>
   <MenuItem>
    <UpdateHost hostData={host} />
   </MenuItem>
   <MenuItem>
    <DeleteHost id={host?.id || 0} />
   </MenuItem>
  </>
 );
};

export default HostActions;
