import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import {
 getUserChannels,
 getUserSingleType,
 getUserTypes,
 postUpdateChannel,
 postUpdateSettings
} from "redux/handlers/notificationHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { ITypesRenew } from "types/api/notificationApiInterface";

import EmailSettings from "./emailSection/EmailSettings";
import ExpiresSettings from "./emailSection/ExpiresSettings";

const EmailChannel = ({
 defaultPreferences
}: {
 defaultPreferences: ITypesRenew | null;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [updatingData, setUpdatingData] = useState<boolean>(false);
 const [channelActive, setChannelActive] = useState<boolean>(true);

 const [selectedTime, setSelectedTime] = useState<number>(1);

 // data for the service (types)
 const [selectedService, setSelectedService] = useState<string>("server");
 const [typeActive, setTypeActive] = useState<boolean>(true);

 // data for email update
 const [mailList, setMailList] = useState<Array<string>>([]);

 // data for initialization
 const [userTypes, setUserTypes] = useState<Array<string>>([]);
 const [typePreferences, setTypePreferences] = useState<ITypesRenew | null>(defaultPreferences);

 const handleOpenChanges = () => setUpdatingData(true);
 const handleCloseChanges = () => setUpdatingData(false);

 const handleConfirm = async () => {
  await dispatch(postUpdateChannel(mailList, channelActive));
  await dispatch(postUpdateSettings(typePreferences?.id || 0, typeActive, selectedTime));
  handleCloseChanges();
 };

 useEffect(() => {
  (async () => {
   let emailData = await getUserSingleType("email");
   setMailList(emailData.email_list);
   setChannelActive(emailData.send_by_email);
   setUserTypes(await getUserTypes());
  })();
 }, []);

 useEffect(() => {
  (async () => {
   let preferences = await dispatch(getUserChannels(selectedService));
   setTypePreferences(preferences);
   setTypeActive(preferences?.type_active || false);
   setSelectedTime(preferences?.frequency || 1);
  })();
 }, [selectedService]);

 const handleChange = (event: SelectChangeEvent) => {
  setSelectedService(event.target.value);
 };

 return (
  <Stack spacing={1}>
   {updatingData ? (
    <Stack spacing={1}>
     <EmailSettings
      mails={mailList}
      channel={channelActive}
      updateMails={(mails) => setMailList(mails)}
      updateChannel={(channel) => setChannelActive(channel)}
     />
     <ExpiresSettings
      time={selectedTime}
      active={typeActive}
      service={selectedService}
      userTypes={userTypes}
      channelActive={channelActive}
      setActive={(active) => setTypeActive(active)}
      setTime={(time) => setSelectedTime(time)}
      setService={(service) => setSelectedService(service)}
     />
     <Stack direction="row" spacing={2}>
      <Button variant="kxActionButton" disabled={mailList.length === 0} onClick={handleConfirm}>
       Conferma
      </Button>
      <Button variant="kxActionButton" onClick={handleCloseChanges}>
       Indietro
      </Button>
     </Stack>
    </Stack>
   ) : (
    <Stack spacing={2}>
     <Stack direction="row" spacing={1}>
      <Chip
       label={
        channelActive ? intl.formatMessage({ id: "app.on" }) : intl.formatMessage({ id: "app.off" })
       }
       size="small"
       color={channelActive ? "success" : "error"}
      />
      <Typography>
       {channelActive ? (
        <FormattedMessage id="profile.settings.channelActive" />
       ) : (
        <FormattedMessage id="profile.settings.channelOff" />
       )}
      </Typography>
     </Stack>
     <FormControl variant="standard" fullWidth>
      <InputLabel>{intl.formatMessage({ id: "profile.settings.channelService" })}</InputLabel>
      <Select
       value={selectedService}
       onChange={handleChange}
       label="Service"
       sx={{ textAlign: "left" }}>
       {userTypes.map((element, index) => {
        return (
         <MenuItem key={`type-index-${index}`} value={element}>
          {element}
         </MenuItem>
        );
       })}
      </Select>
     </FormControl>
     <TextField
      label={intl.formatMessage({ id: "profile.settings.channelTime" })}
      variant="standard"
      value={selectedTime}
      InputProps={{
       readOnly: true
      }}
     />
     <Stack direction="row" spacing={1}>
      <Typography>Mail di invio:</Typography>
      {mailList.map((element, index) => {
       return <Typography key={`mail-data-${index}`}>{element}</Typography>;
      })}
     </Stack>
     <Stack direction="row" spacing={2}>
      <Button variant="kxActionButton" onClick={handleOpenChanges}>
       Modifica
      </Button>
     </Stack>
    </Stack>
   )}
  </Stack>
 );
};

export default EmailChannel;
