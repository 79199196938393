import React, { ReactElement, useState } from "react";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";

import IconSelector from "components/shared/images/IconSelector";

import { cronjobTableData } from "types/global/table";

import ActivateCronjob from "./actions/ActivateCronjob";
import DeactivateCronjob from "./actions/DeactivateCronjob";
import DeleteCronjob from "./actions/DeleteCronjob";
import UpdateCronjob from "./actions/UpdateCronjob";

const CronjobActions = ({ rowData }: { rowData: cronjobTableData }): ReactElement => {
 const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
 const open = Boolean(anchorEl);

 const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
  setAnchorEl(null);
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <IconSelector icon="MenuIcon" />
   </Button>
   <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
     "aria-labelledby": "basic-button"
    }}>
    <Stack>
     {rowData.active === "y" ? (
      <DeactivateCronjob rowData={rowData} closeMenu={handleClose} />
     ) : (
      <ActivateCronjob rowData={rowData} closeMenu={handleClose} />
     )}
     <UpdateCronjob rowData={rowData} closeMenu={handleClose} />
     <DeleteCronjob id={rowData.id} closeMenu={handleClose} />
    </Stack>
   </Menu>
  </>
 );
};

export default CronjobActions;
