import React, { ReactElement, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import AppModal from "components/shared/modal/AppModal";

import { postUpdateProject } from "redux/handlers/hetznerHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { HetznerData, IProjectApi } from "types/api/hetznerApi";
import { IUsersList } from "types/api/userApiInterface";

const AddProject = ({
 contacts,
 projectData,
 id,
 reloadData,
 closeMenu
}: {
 contacts: Array<IUsersList>;
 projectData: IProjectApi;
 id: number;
 reloadData?: () => void;
 closeMenu: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);

 useEffect(() => {
  handleOpen();
 }, []);

 const handleOpen = () => {
  reset();
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const { control, handleSubmit, setValue, reset } = useForm({
  defaultValues: {
   partner: projectData.contact_id,
   name: projectData.name,
   apiKey: projectData.apiKey
  }
 });

 const onSubmit: SubmitHandler<HetznerData> = async (data: HetznerData) => {
  setIsLoading(true);
  await dispatch(postUpdateProject(projectData.id, data.name, data.apiKey, data.partner));
  setIsLoading(false);
  reloadData && reloadData();
  setOpenModal(false);
  closeMenu();
 };

 return (
  <Stack>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    handleConfirm={handleSubmit(onSubmit)}
    title={intl.formatMessage({ id: "configurator.edit.project" })}>
    <form onSubmit={handleSubmit(onSubmit)}>
     <Stack>
      <Controller
       name="partner"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <Autocomplete
         fullWidth={true}
         autoHighlight
         sx={{ my: 2 }}
         onChange={(e, value) => {
          setValue("partner", value?.id || 0);
         }}
         defaultValue={{
          label: `${contacts.find((element) => element.id === projectData.contact_id)
           ?.firstname} ${contacts.find((element) => element.id === projectData.contact_id)
           ?.lastname} - ${contacts.find((element) => element.id === projectData.contact_id)
           ?.companyName}`,
          id: contacts.find((element) => element.id === projectData.contact_id)?.id,
          key: contacts.find((element) => element.id === projectData.contact_id)?.userid
         }}
         options={contacts.map((element) => {
          return {
           label:
            `${element.firstname} ${element.lastname} - ${element.companyName}` || "Not provided",
           id: element.id,
           key: element.userid
          };
         })}
         renderOption={(props, option) => {
          return (
           <li {...props} key={`client-edit-${option.id}`}>
            {option.label}
           </li>
          );
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           {...field}
           size="small"
           InputLabelProps={{ shrink: true }}
           label={intl.formatMessage({ id: "configurator.hetzner.partner" })}
          />
         )}
        />
       )}
      />
      <Controller
       name={"name"}
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         size="small"
         label={intl.formatMessage({ id: "configurator.hetzner.name" })}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         autoComplete="off"
        />
       )}
      />
      <Controller
       name={"apiKey"}
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         size="small"
         label={intl.formatMessage({ id: "configurator.hetzner.apiKey" })}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         autoComplete="off"
        />
       )}
      />
     </Stack>
    </form>
   </AppModal>
  </Stack>
 );
};

export default AddProject;
