import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import AppModal from "components/shared/modal/AppModal";

import { postUpdateSnapshotName } from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

const ChangeName = ({
 oldName,
 id,
 closeMenu
}: {
 oldName: string;
 id: number;
 closeMenu: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => {
  reset();
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const { control, reset, handleSubmit, formState, watch } = useForm({
  defaultValues: {
   name: oldName
  }
 });

 const onSubmit: SubmitHandler<{ name: string }> = async (data: { name: string }) => {
  setLoading(true);
  await dispatch(postUpdateSnapshotName(id, data.name));
  handleClose();
  setLoading(false);
  closeMenu();
 };

 return (
  <>
   <MenuItem onClick={() => handleOpen()}>
    <FormattedMessage id="addons.snapshot.changeName" />
   </MenuItem>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "addons.snapshot.changeName" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={loading || oldName === watch("name")}>
    <form onSubmit={handleSubmit(onSubmit)}>
     <Controller
      name="name"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
         e.stopPropagation();
        }}
        label={intl.formatMessage({ id: "server.snapshot.snapshotName" })}
        error={formState.isDirty && !!formState?.errors?.name}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
       />
      )}
     />
    </form>
   </AppModal>
  </>
 );
};

export default ChangeName;
