import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postUpdateCloudboxPassword } from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

const ChangePassword = ({ id, password }: { id: number; password: string }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postUpdateCloudboxPassword(id));
  handleClose();
  setLoading(false);
 };

 return (
  <>
   <Tooltip title={intl.formatMessage({ id: "app.edit" })} placement="top">
    <IconButton onClick={handleOpen}>
     <IconSelector icon="EditIcon" props={{ fontSize: "small" }} />
    </IconButton>
   </Tooltip>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "addons.cloudbox.changePassword" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}>
    <Typography>
     <FormattedMessage id="addons.cloudbox.resetPasswordMessage" />
    </Typography>
   </AppModal>
  </>
 );
};

export default ChangePassword;
