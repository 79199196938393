import React, { ReactElement, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import { isNil } from "ramda";

import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";

import { Record } from "types/api/appsApiInterface";

import RecordTableEntry from "./recordsTableSections/RecordTableEntry";

const TableHeadList: { align: "left" | "center" | "right"; name: string; width?: string }[] = [
 {
  align: "left",
  name: "zone.type"
 },
 {
  align: "center",
  name: "zone.name",
  width: "30%"
 },
 {
  align: "center",
  name: "zone.data",
  width: "15%"
 },
 {
  align: "center",
  name: "zone.aux"
 },
 {
  align: "center",
  name: "zone.ttl"
 },
 {
  align: "center",
  name: "zone.actions",
  width: "10%"
 }
];

type Props = {
 zoneId: number;
 ispId: number;
 data: Record[];
 domain: string;
 reloadData?: () => void;
};

const ZoneRecords = ({ zoneId, ispId, data, domain, reloadData }: Props): ReactElement => {
 const searchRef = useRef<HTMLInputElement>(null);

 const defaultRecord: Record = {
  aux: 0,
  data: "",
  id: -1,
  isp_id: ispId,
  name: "test",
  status: "active",
  ttl: 3600,
  type: "A",
  zone_id: zoneId
 };

 const [currentList, setCurrentList] = useState<Record[]>(data.slice(0, 10));
 const [editIndex, setEditIndex] = useState<number>(-1);
 const [currentPage, setCurrentPage] = useState<number>(0);
 const [resetIndex, setResetIndex] = useState<boolean>(false);

 const handleSearchZone = (zone: string): void => {
  setResetIndex(true);
  if (zone === "") {
   setCurrentList(data.slice(0, 10));
  } else {
   setCurrentList(
    data.filter((row) => !isNil(row.name) && row.name.toLowerCase().includes(zone)).slice(0, 5)
   );
  }
  setResetIndex(false);
 };

 const handleOnPageChange = (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  setCurrentPage(newPage);
  setCurrentList(data.filter((row, index) => index >= 10 * newPage && index < 10 * newPage + 10));
 };

 const AddNewRecord = (): void => {
  setCurrentList([defaultRecord, ...currentList]);
  setEditIndex(0);
 };

 const RenderEmptyState = (): ReactElement => (
  <Stack sx={{ mt: 2 }}>
   <Alert severity="warning" variant="outlined">
    <FormattedMessage id="record.notFound" />
   </Alert>
  </Stack>
 );

 const handleResetList = () => {
  setEditIndex(-1);
  setCurrentList(data.filter((row, index) => index < 10));
 };

 const handleUpdateList = () => {
  reloadData && reloadData();
 };

 const RenderRecordList = (): ReactElement => {
  return (
   <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
    <Table sx={{ minWidth: 800 }} aria-label="record table">
     <TableHead>
      <TableRow>
       {TableHeadList.map((item, index) => (
        <TableCell key={index} align={item.align} width={item?.width || "auto"}>
         <FormattedMessage id={item.name} />
        </TableCell>
       ))}
      </TableRow>
     </TableHead>
     <TableBody>
      {currentList.map((row, index) => (
       <RecordTableEntry
        key={`row-dns-${index}`}
        index={index}
        domain={domain}
        ispId={ispId}
        zoneId={zoneId}
        rowData={row}
        resetList={handleResetList}
        updateList={handleUpdateList}
        resetIndex={resetIndex}
       />
      ))}
     </TableBody>
    </Table>
   </TableContainer>
  );
 };

 return (
  <Stack>
   <Alert severity="info" sx={{ mb: 2, textAlign: "left" }} variant="outlined">
    <FormattedMessage id="zone.record.info" values={{ br: <br /> }} />
   </Alert>
   <Stack justifyContent="space-between" direction="row">
    <Stack>
     <Button
      variant="kxActionButton"
      color="success"
      disabled={editIndex !== -1}
      startIcon={<AddIcon />}
      onClick={() => AddNewRecord()}>
      <FormattedMessage id="domains.record.addRecord" />
     </Button>
    </Stack>
    <Stack>
     <TextField
      size="small"
      label="Search"
      inputRef={searchRef}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
       e.stopPropagation();
      }}
      InputLabelProps={{ shrink: true }}
      InputProps={{ endAdornment: <SearchIcon color="disabled" /> }}
      onChange={({ currentTarget }) => handleSearchZone(currentTarget.value)}
     />
    </Stack>
   </Stack>
   <Divider sx={{ my: 2 }} />
   {currentList?.length ? <RenderRecordList /> : <RenderEmptyState />}
   <TablePagination
    component="div"
    count={data.length}
    page={currentPage}
    rowsPerPage={10}
    onPageChange={handleOnPageChange}
    rowsPerPageOptions={[10]}
   />
  </Stack>
 );
};

export default ZoneRecords;
