import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IWebsitesDetailsApi, IWebsitesDetailsDataset } from "types/api/websitesApiInterface";

const initialState: IWebsitesDetailsApi = { details: null, site_id: 0 };

const WebsitesDetailsReducer = createSlice({
 name: "websitesDetails",
 initialState,
 reducers: {
  setWebsitesDetails: (state, { payload }: PayloadAction<IWebsitesDetailsDataset>) => {
   state.details = payload;
  },
  resetWebsitesDetails: (state) => {
   state.details = null;
  }
 }
});

export default WebsitesDetailsReducer;
