import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postRemoveOrder } from "redux/handlers/serverHandler";

import { useAppDispatch } from "hooks/reduxHook";

const RemoveOrder = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postRemoveOrder(id));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Button variant="kxActionButton" onClick={handleOpen}>
    <Typography>
     <FormattedMessage id={"server.removeOrder"} />
    </Typography>
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "server.removeOrder" })}
    handleClose={handleClose}
    disabled={loading}
    handleConfirm={handleConfirm}>
    <Alert severity="info">
     <FormattedMessage id="server.removeOrderMessage" />
    </Alert>
   </AppModal>
  </>
 );
};

export default RemoveOrder;
