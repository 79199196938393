import { Dayjs } from "dayjs";
import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import creditsReducer from "redux/reducers/creditsReducer";
import notificationReducer from "redux/reducers/notificationReducer";

import { ICreditsApi } from "types/api/creditsApiInterface";

import { ApiService } from "service/ApiService";

export const getAllCredits =
 (
  currentIndex: number,
  sizePerPage: number,
  q: string,
  partnerId: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<ICreditsApi>(
    `/movement/allmovements?currentIndex=${currentIndex}&sizePerPage=${sizePerPage}&q=${q}&partnerId=${partnerId}`
   );
   dispatch(creditsReducer.actions.setCredits(data || {}));
  } catch (error) {
   console.warn("FAILED TO GET CREDITS");
  }
 };

export const postAddCredit =
 (
  product: string,
  amount: number,
  details: string,
  partner: string,
  type: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/movement/addmovement", "POST", {
    product: product,
    amount: amount,
    details: details,
    partner: partner,
    type: type
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Movement created",
      timestamp: Date.now(),
      type: "success"
     })
    );
    await dispatch(getAllCredits(0, 10, "", ""));
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create movement - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDownloadCredits =
 (firstDate: Dayjs, secondDate: Dayjs, partner?: string): AppAction<Promise<Blob | null>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService(
    `/movement/exportmovements?from=${firstDate.format("YYYY-MM-DD")}&to=${secondDate.format(
     "YYYY-MM-DD"
    )}&partner=${partner || ""}`
   );
   const blob = new Blob([data], { type: "text/csv" });
   return blob;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get movements - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return null;
  }
 };
