import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDebounce } from "react-use";

import { isNil } from "ramda";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import StepContent from "@mui/material/StepContent";
import Switch from "@mui/material/Switch";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

import { hetznerServerCreationSteps } from "constants/server";

import { ILocations, IOsDetails, IServerTypes } from "types/api/serversApiInterface";

import CountryBox from "./CountryBox";
import ServerOsBox from "./ServerOsBox";
import ServerSizeBox from "./ServerSizeBox";
import ServerTypeBox from "./ServerTypeBox";

const HetznerServerCreation = ({
 stepLoading,
 description,
 index,
 locationList,
 osList,
 typesList,
 serverType,
 os,
 location,
 serverSize,
 enableIpv4,
 enableIpv6,
 enableBackup,
 enableContinuity,
 nextStep,
 previousStep,
 resetData,
 handleOpen,
 setData
}: {
 stepLoading: boolean;
 description: string;
 index: number;
 locationList: Array<ILocations>;
 osList: Array<IOsDetails>;
 typesList: Array<IServerTypes>;
 serverType: string;
 os: number;
 location: number;
 serverSize: number;
 enableIpv4: boolean;
 enableIpv6: boolean;
 enableBackup: boolean;
 enableContinuity: boolean;
 nextStep: () => void;
 previousStep: () => void;
 resetData: () => void;
 handleOpen: () => void;
 setData: (section: string, value: string | number | boolean | IServerTypes | ILocations) => void;
}): ReactElement => {
 const [tabValue, setTabValue] = useState<number>(0);
 const [loading, setLoading] = useState<boolean>(false);

 useDebounce(
  () => {
   setLoading(true);
   if (serverSize !== 0) {
    if (typesList.find((element) => element.id === serverSize)?.name.includes("VFC")) {
     setTabValue(1);
    } else {
     setTabValue(0);
    }
   }
   setLoading(false);
  },
  400,
  [serverSize]
 );

 useEffect(() => {
  setLoading(true);
 }, [serverSize]);

 const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  setTabValue(newValue);
 };

 const handleNext = () => {
  nextStep();
 };

 const handleBack = () => {
  previousStep();
 };

 const handleSetServer = (type: string) => {
  setData("serverType", type);
  if (type !== "custom") {
   setData("os", 6);
   setData("osName", "debian-12");
  }
 };

 const RenderHetznerServers = () => {
  return (
   <Box sx={{ width: "100%", typography: "body1" }}>
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
     <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
      <Tab label="Standard" />
      <Tab label="Dedicated" />
     </Tabs>
    </Box>
    <Stack
     role="tabpanel"
     hidden={tabValue !== 0}
     id={`simple-tabpanel-${0}`}
     aria-labelledby={`simple-tab-${0}`}>
     {tabValue === 0 && (
      <ServerSizeBox
       selectedSize={serverSize}
       sizesList={typesList.filter((element) => !element.name.includes("VFC"))}
       selectSize={(size) => {
        setData("serverSize", Number(size));
        const findedServer = typesList.find((element) => element.id === Number(size));
        if (!isNil(findedServer)) {
         setData("serverSizeData", findedServer);
        }
        setTabValue(0);
       }}
      />
     )}
    </Stack>
    <Stack
     role="tabpanel"
     hidden={tabValue !== 1}
     id={`simple-tabpanel-${1}`}
     aria-labelledby={`simple-tab-${1}`}>
     {tabValue === 1 && (
      <ServerSizeBox
       selectedSize={serverSize}
       sizesList={typesList.filter((element) => element.name.includes("VFC"))}
       selectSize={(size) => {
        setData("serverSize", Number(size));
        const findedServer = typesList.find((element) => element.id === Number(size));
        if (!isNil(findedServer)) {
         setData("serverSizeData", findedServer);
        }
        setTabValue(1);
       }}
      />
     )}
    </Stack>
   </Box>
  );
 };

 const renderStepContent = (index: number) => {
  switch (index) {
   case 0:
    return (
     <ServerTypeBox selectedType={serverType} selectType={(type) => handleSetServer(type || "")} />
    );
   case 1:
    return (
     <ServerOsBox
      selectedOs={os}
      osList={osList}
      selectOs={(value) => {
       setData("os", value?.id || 0);
       setData("osName", value?.name || "");
      }}
     />
    );
   case 2:
    return (
     <CountryBox
      locationList={locationList}
      selectLocation={(location) => {
       setData("location", location);
       setData("serverSize", 0);
      }}
      selectedLocation={location}
     />
    );
   case 3:
    return loading ? (
     <Stack>
      <CircularProgress />
     </Stack>
    ) : (
     <RenderHetznerServers />
    );
   case 4:
    return (
     <Stack>
      <Stack justifyContent="center" alignContent="center" direction="column">
       <FormControlLabel
        control={
         <Switch checked={enableIpv4} onChange={(e) => setData("enableIpv4", e.target.checked)} />
        }
        label={<FormattedMessage id="server.add.enableIpv4" />}
       />
      </Stack>
      <Stack justifyContent="center" alignContent="center" direction="column">
       <FormControlLabel
        control={
         <Switch checked={enableIpv6} onChange={(e) => setData("enableIpv6", e.target.checked)} />
        }
        label={<FormattedMessage id="server.add.enableIpv6" />}
       />
      </Stack>
     </Stack>
    );
   case 5:
    return (
     <Stack>
      <Stack justifyContent="center" alignContent="center" direction="column">
       <FormControlLabel
        control={
         <Switch
          checked={enableBackup}
          onChange={(e) => setData("enableBackup", e.target.checked)}
         />
        }
        label={<FormattedMessage id="server.add.enableBackup" />}
       />
      </Stack>
     </Stack>
    );
   case 6:
    return (
     <Stack>
      <Stack justifyContent="center" alignContent="center" direction="column">
       <FormControlLabel
        control={
         <Switch
          checked={enableContinuity}
          onChange={(e) => setData("enableContinuity", e.target.checked)}
         />
        }
        label={<FormattedMessage id="server.add.enableContinuity" />}
       />
      </Stack>
     </Stack>
    );
  }
 };

 const disableNextButton = (index: number) => {
  switch (index) {
   case 0:
    return serverType === "";
   case 1:
    return os === 0;
   case 2:
    return location === 0;
   case 3:
    return serverSize === 0;
   case 4:
    return !enableIpv4 && !enableIpv6;
   default:
    return false;
  }
 };

 const checkDisableConfirm = () => {
  return os === 0;
 };

 return (
  <>
   {!stepLoading ? (
    <StepContent>
     <Stack spacing={2}>
      <Alert severity="info">
       <Typography textAlign="left">
        {description === "server.add.enableContinuityMessage" ? (
         <FormattedMessage id={description} values={{ br: <br /> }} />
        ) : (
         <FormattedMessage id={description} />
        )}
       </Typography>
      </Alert>
      {renderStepContent(index)}
     </Stack>
     <Box sx={{ mb: 2 }}>
      <div>
       {index !== hetznerServerCreationSteps.length - 1 ? (
        <Button
         disabled={disableNextButton(index)}
         variant="contained"
         onClick={handleNext}
         sx={{ mt: 1, mr: 1 }}>
         <FormattedMessage id="server.add.continue" />
        </Button>
       ) : (
        <></>
       )}
       {index === 6 && (
        <>
         <Button
          sx={{ mt: 1, mr: 1 }}
          variant="contained"
          disabled={checkDisableConfirm()}
          onClick={handleOpen}>
          <FormattedMessage id="server.add.confirm" />
         </Button>
         <Button onClick={resetData} sx={{ mt: 1, mr: 1 }}>
          <FormattedMessage id="server.add.reset" />
         </Button>
        </>
       )}
       <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
        <FormattedMessage id="server.add.back" />
       </Button>
      </div>
     </Box>
    </StepContent>
   ) : (
    <StepContent>
     <Stack spacing={2}>
      <Alert severity="info">
       <Typography>
        <FormattedMessage id="server.checkExecution" />
       </Typography>
      </Alert>
      <CircularProgress />
     </Stack>
    </StepContent>
   )}
  </>
 );
};

export default HetznerServerCreation;
