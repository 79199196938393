import React, { ReactElement } from "react";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";

import IconSelector from "components/shared/images/IconSelector";

import { profileOrdersTableData } from "types/global/table";

import CompleteTransfer from "./actions/CompleteTransfer";

const OrdersMenu = ({
 rowData,
 reloadData
}: {
 rowData: profileOrdersTableData;
 reloadData?: () => void;
}): ReactElement => {
 const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
 const open = Boolean(anchorEl);

 const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
  setAnchorEl(null);
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <IconSelector icon="MenuIcon" />
   </Button>
   <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
     "aria-labelledby": "basic-button"
    }}>
    <Stack>
     <CompleteTransfer id={rowData.id} reloadData={reloadData} closeMenu={handleClose} />
    </Stack>
   </Menu>
  </>
 );
};

export default OrdersMenu;
