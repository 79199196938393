import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import useIntl from "react-intl/src/components/useIntl";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postRestoreMailboxBackup } from "redux/handlers/mailsHandler";

import { useAppDispatch } from "hooks/reduxHook";

const RestoreFromBackup = ({ id }: { id: number }) => {
 const dispatch = useAppDispatch();
 const intl = useIntl();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setIsLoading(true);
  await dispatch(postRestoreMailboxBackup(id));
  setIsLoading(false);
  handleClose();
 };

 return (
  <>
   <Tooltip title={<FormattedMessage id="mails.backup.restore" />}>
    <IconButton onClick={handleOpen}>
     <IconSelector icon="RestoreIcon" />
    </IconButton>
   </Tooltip>
   <AppModal
    open={openModal}
    close={handleClose}
    loading={isLoading}
    title={intl.formatMessage({ id: "mails.backup.restore" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}>
    <Typography>
     <FormattedMessage id="mails.backup.restoreMessage" />
    </Typography>
   </AppModal>
  </>
 );
};

export default RestoreFromBackup;
