import { AppAction } from "redux/store";

import serverJobQueueReducer from "redux/reducers/serverJobQueueReducer";

import { IJobsList } from "types/api/hetznerJobs";

import { ApiService } from "service/ApiService";

export const getServerOrders =
 (currentIndex: number, sizePerPage: number, q?: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IJobsList>(
    `/hetznerorder/allorders?page=${currentIndex}&limit=${sizePerPage}&q=${q || ""}`
   );
   dispatch(serverJobQueueReducer.actions.setServerJobs(data || {}));
  } catch (error) {
   console.warn("FAILED TO POST ORDERS");
  }
 };
