import React, { ReactElement, useState } from "react";

//import { FormattedMessage } from "react-intl";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
//import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";

import IconSelector from "components/shared/images/IconSelector";

import { volumeTableData } from "types/global/table";

import AttachVolume from "./actions/AttachVolume";
import ChangeVolumeName from "./actions/ChangeVolumeName";
import DetachVolume from "./actions/DetachVolume";
import MountVolume from "./actions/MountVolume";
import ResizeVolume from "./actions/ResizeVolume";
import UnmountVolume from "./actions/UnmountVolume";

const VolumesActions = ({ rowData }: { rowData: volumeTableData }): ReactElement => {
 const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
 const open = Boolean(anchorEl);

 const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
  setAnchorEl(null);
 };

 /*
  *   IF NEED TO DELETE VOLUME:
  *   {rowData.protection ? (
  *     <MenuItem disabled>
  *      <FormattedMessage id="volumes.deleteVolume" />
  *     </MenuItem>
  *    ) : (
  *     <DeleteVolume id={rowData.id} closeMenu={handleClose} />
  *    )}
  *   INSERT INTO THE MENU
  */

 return (
  <>
   <Button onClick={handleOpen}>
    <IconSelector icon="MenuIcon" />
   </Button>
   <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
     "aria-labelledby": "basic-button"
    }}>
    <Stack>
     <ChangeVolumeName id={rowData.id} oldName={rowData.name} closeMenu={handleClose} />
     {rowData.server ? (
      <DetachVolume id={rowData.id} closeMenu={handleClose} />
     ) : (
      <AttachVolume id={rowData.id} datacenter={rowData.datacenter} closeMenu={handleClose} />
     )}
     {rowData.server &&
      (!rowData.directory ? (
       <MountVolume id={rowData.id} closeMenu={handleClose} />
      ) : (
       <UnmountVolume id={rowData.id} closeMenu={handleClose} />
      ))}
     <ResizeVolume id={rowData.id} size={rowData.size} closeMenu={handleClose} />
    </Stack>
   </Menu>
  </>
 );
};

export default VolumesActions;
