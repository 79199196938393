import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";

import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

const AppChannel = (): ReactElement => {
 const intl = useIntl();

 const [updatingData, setUpdatingData] = useState<boolean>(false);
 const [channelActive, setChannelActive] = useState<boolean>(true);
 const [selectedService, setSelectedService] = useState<string>("Server");
 const [selectedTime, setSelectedTime] = useState<string>("Giornaliero");
 const [selectedSection, setSelectedSection] = useState<string>("Server Performance");
 const [sectionActive, setSectionActive] = useState<boolean>(true);

 const handleOpenChanges = () => setUpdatingData(true);
 const handleCloseChanges = () => setUpdatingData(false);

 const handleConfirm = () => {
  handleCloseChanges();
 };

 return (
  <Stack spacing={1}>
   {updatingData ? (
    <Stack spacing={2}>
     <FormControlLabel
      control={
       <Checkbox
        checked={channelActive}
        onChange={(e) => {
         setChannelActive(!channelActive);
        }}
       />
      }
      label={intl.formatMessage({ id: "profile.settings.setChannelActive" })}
     />
     <Divider />
     <Autocomplete
      fullWidth={true}
      autoHighlight
      sx={{ my: 2 }}
      options={["Server", "Domini"]}
      value={selectedService}
      onChange={(e, value) => setSelectedService(value || "Server")}
      renderInput={(params) => (
       <TextField
        {...params}
        label={intl.formatMessage({ id: "profile.settings.channelService" })}
        variant="standard"
        InputLabelProps={{ shrink: true }}
       />
      )}
     />
     <Stack direction="row" spacing={2}>
      <Autocomplete
       autoHighlight
       fullWidth
       sx={{ my: 2 }}
       options={["Server Performance", "Server Scadenze"]}
       value={selectedSection}
       onChange={(e, value) => setSelectedSection(value || "Server Performace")}
       renderInput={(params) => (
        <TextField
         {...params}
         label={intl.formatMessage({ id: "profile.settings.serviceSection" })}
         variant="standard"
         InputLabelProps={{ shrink: true }}
        />
       )}
      />
      <FormControlLabel
       control={
        <Checkbox
         checked={sectionActive}
         onChange={(e) => {
          setSectionActive(!sectionActive);
         }}
        />
       }
       label={intl.formatMessage({ id: "profile.settings.setSectionActive" })}
      />
     </Stack>
     <Divider />
     <Autocomplete
      fullWidth={true}
      autoHighlight
      sx={{ my: 2 }}
      options={["Giornaliero", "Orario", "Minuti"]}
      value={selectedTime}
      onChange={(e, value) => setSelectedTime(value || "Giornaliero")}
      renderInput={(params) => (
       <TextField
        {...params}
        label={intl.formatMessage({ id: "profile.settings.channelTime" })}
        variant="standard"
        InputLabelProps={{ shrink: true }}
       />
      )}
     />
     <Stack direction="row" spacing={2}>
      <Button variant="kxActionButton" onClick={handleConfirm}>
       Conferma
      </Button>
      <Button variant="kxActionButton" onClick={handleCloseChanges}>
       Indietro
      </Button>
     </Stack>
    </Stack>
   ) : (
    <Stack spacing={2}>
     <Stack direction="row" spacing={1}>
      <Chip
       label={
        channelActive ? intl.formatMessage({ id: "app.on" }) : intl.formatMessage({ id: "app.off" })
       }
       size="small"
       color={channelActive ? "success" : "error"}
      />
      <Typography>
       {channelActive ? (
        <FormattedMessage id="profile.settings.channelActive" />
       ) : (
        <FormattedMessage id="profile.settings.channelOff" />
       )}
      </Typography>
     </Stack>
     <TextField
      label={intl.formatMessage({ id: "profile.settings.channelService" })}
      variant="standard"
      value={selectedService}
      InputProps={{
       readOnly: true,
       startAdornment: (
        <InputAdornment position="start">
         <MiscellaneousServicesIcon />
        </InputAdornment>
       )
      }}
     />
     <TextField
      label={intl.formatMessage({ id: "profile.settings.channelTime" })}
      variant="standard"
      value={selectedTime}
      InputProps={{
       readOnly: true
      }}
     />
     <Stack direction="row" spacing={2}>
      <Button variant="kxActionButton" onClick={handleOpenChanges}>
       Modifica
      </Button>
     </Stack>
    </Stack>
   )}
  </Stack>
 );
};

export default AppChannel;
