import React, { ReactElement, useContext, useEffect, useState } from "react";
import useIntl from "react-intl/src/components/useIntl";
import { useParams } from "react-router-dom";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import GlobalBlacklist from "./globalBlacklist/GlobalBlacklist";
import GlobalWhitelist from "./globalWhitelist/GlobalWhitelist";
import GatewayNetworks from "./network/GatewayNetworks";
import RelayDomains from "./relayDomains/RelayDomains";
import SpamQuarantine from "./spamQuarantine/SpamQuarantine";
import Trackcenter from "./trackcenter/Trackcenter";
import GatewayTransport from "./transport/GatewayTransport";
import VirusQuarantine from "./virusQuarantine/VirusQuarantine";

const GatewayContainer = (): ReactElement => {
 const intl = useIntl();
 const params = useParams<{ id: string; section: string }>();
 const { socket } = useContext(AppContext);

 const [section, setSection] = useState<number>(0);
 const [reloadData, setReloadData] = useState<boolean>(true);

 const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
  setSection(newValue);
 };

 useEffect(() => {
  if (!isNil(socket)) {
   socket.on("manage_gateway", (value): void => {
    if (value?.completed) {
     setReloadData(true);
    }
   });
  }
  return () => {
   !isNil(socket) && socket.off("manage_gateway");
  };
 }, [socket]);

 const RenderTabContent = (): ReactElement => {
  switch (section) {
   case 0:
    return (
     <RelayDomains
      id={parseInt(params?.id || "0")}
      reloadData={reloadData}
      resetReload={() => setReloadData(false)}
     />
    );
   case 1:
    return (
     <GatewayNetworks
      id={parseInt(params?.id || "0")}
      reloadData={reloadData}
      resetReload={() => setReloadData(false)}
     />
    );
   case 2:
    return (
     <GatewayTransport
      id={parseInt(params?.id || "0")}
      reloadData={reloadData}
      resetReload={() => setReloadData(false)}
     />
    );
   case 3:
    return (
     <GlobalWhitelist
      id={parseInt(params?.id || "0")}
      reloadData={reloadData}
      resetReload={() => setReloadData(false)}
     />
    );
   case 4:
    return (
     <GlobalBlacklist
      id={parseInt(params?.id || "0")}
      reloadData={reloadData}
      resetReload={() => setReloadData(false)}
     />
    );
   case 5:
    return (
     <SpamQuarantine
      id={parseInt(params?.id || "0")}
      reloadData={reloadData}
      resetReload={() => setReloadData(false)}
     />
    );
   case 6:
    return (
     <VirusQuarantine
      id={parseInt(params?.id || "0")}
      reloadData={reloadData}
      resetReload={() => setReloadData(false)}
     />
    );
   case 7:
    return <Trackcenter id={parseInt(params?.id || "0")} />;
   default:
    return (
     <RelayDomains
      id={parseInt(params?.id || "0")}
      reloadData={reloadData}
      resetReload={() => setReloadData(false)}
     />
    );
  }
 };

 return (
  <Paper elevation={0} sx={{ mt: 3, pb: 0.7, borderRadius: "10px", boxShadow: 0 }}>
   <Tabs
    value={section}
    onChange={handleChangeTab}
    variant={"scrollable"}
    scrollButtons={true}
    allowScrollButtonsMobile>
    <Tab label={intl.formatMessage({ id: "gateway.relayDomains" })} />
    <Tab label={intl.formatMessage({ id: "gateway.network" })} />
    <Tab label={intl.formatMessage({ id: "gateway.transport" })} />
    <Tab label={intl.formatMessage({ id: "gateway.globalWhitelist" })} />
    <Tab label={intl.formatMessage({ id: "gateway.globalBlacklist" })} />
    <Tab label={intl.formatMessage({ id: "gateway.spamQuarantine" })} />
    <Tab label={intl.formatMessage({ id: "gateway.virusQuarantine" })} />
    <Tab label={intl.formatMessage({ id: "gateway.trackcenter" })} />
   </Tabs>
   <RenderTabContent />
  </Paper>
 );
};

export default GatewayContainer;
