import React, { ReactElement } from "react";
import { FormattedDate, FormattedNumber } from "react-intl";
import { formatBytes } from "helpers/numberFormatting";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import WebsitesBackupActions from "components/websites/backup/WebsitesBackupActions";

import { websitesBackupTableData } from "types/global/table";

const WebsitesBackupTable = ({
 rowData,
 index
}: {
 rowData: websitesBackupTableData;
 index: number;
}): ReactElement => {
 return (
  <TableRow key={`customers-list-${index}`}>
   <TableCell component="th" scope="row">
    {rowData.filename}
   </TableCell>
   <TableCell component="th" scope="row">
    <Typography variant="caption">
     <FormattedNumber value={formatBytes(Number(rowData.filesize), "MB")} />
     MB
    </Typography>
   </TableCell>
   <TableCell component="th" scope="row">
    <FormattedDate value={new Date(Number(rowData.tstamp) * 1000)} />
   </TableCell>
   <TableCell component="th" align="right">
    <WebsitesBackupActions id={rowData.id} />
   </TableCell>
  </TableRow>
 );
};

export default WebsitesBackupTable;
