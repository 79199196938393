import React, { ReactElement, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import IconSelector from "components/shared/images/IconSelector";

import { getAllIspNotPaged } from "redux/handlers/websitesHandler";

import { getIspList } from "redux/selectors/ispSelector";
import { getWebsitesDetails } from "redux/selectors/websitesSelector";

import { useAppDispatch } from "hooks/reduxHook";

import WebsitesMonitoring from "./WebsitesMonitoring";

const WebsiteUpperPage = (): ReactElement => {
 const dispatch = useAppDispatch();
 const intl = useIntl();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const websitedetails = useSelector(getWebsitesDetails);
 const ispList = useSelector(getIspList);

 const [loading, setLoading] = useState<boolean>(true);

 useEffect(() => {
  (async () => {
   await dispatch(getAllIspNotPaged("webserver"));
   setLoading(false);
  })();
 }, []);

 return (
  <Stack>
   <Stack
    mt={desktopViewPort ? 5 : 0}
    justifyContent="space-between"
    direction={desktopViewPort ? "row" : "column"}>
    <Stack>
     <Stack spacing={1}>
      <Typography fontWeight="bold" fontSize={16} textAlign="left" textTransform="uppercase">
       {websitedetails.site}
      </Typography>
      {!loading && (
       <Tooltip title={intl.formatMessage({ id: "sites.details.goToServer" })}>
        <NavLink
         to={`/servers/manage/${ispList.find((element) => element.id === websitedetails.isp_id)
          ?.server_id}/specification`}
         style={{
          textDecoration: "none"
         }}>
         <Stack direction="row" spacing={1} sx={{ cursor: "pointer" }}>
          <IconSelector icon="ServerIcon" props={{ fontSize: "small", color: "primary" }} />
          <Typography variant="subtitle2" color="primary">
           {ispList.find((element) => element.id === websitedetails.isp_id)?.hostname}
          </Typography>
         </Stack>
        </NavLink>
       </Tooltip>
      )}
     </Stack>
    </Stack>
   </Stack>
   <WebsitesMonitoring />
  </Stack>
 );
};

export default WebsiteUpperPage;
