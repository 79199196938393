import React, { ReactElement } from "react";
import { useIntl } from "react-intl";

import { isNil } from "ramda";

import Autocomplete from "@mui/material/Autocomplete";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";

import { IUsersList } from "types/api/userApiInterface";

const SearchForPartner = ({
 contactList,
 handleSearchPartner
}: {
 contactList: Array<IUsersList>;
 handleSearchPartner: (partner: string) => void;
}): ReactElement => {
 const intl = useIntl();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 return (
  <Autocomplete
   autoHighlight
   size="small"
   sx={{ mx: 1, width: desktopViewPort ? 300 : 240 }}
   onChange={(e, value) =>
    isNil(value) ? handleSearchPartner("") : handleSearchPartner(value.userid)
   }
   options={contactList.map((element) => {
    return {
     label: `${element.firstname} ${element.lastname} - ${element.companyName}`,
     userid: element.userid
    };
   })}
   renderInput={(params) => (
    <TextField
     {...params}
     label={intl.formatMessage({ id: "app.search.partner" })}
     InputLabelProps={{ shrink: true }}
    />
   )}
  />
 );
};

export default SearchForPartner;
