import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { isNil } from "ramda";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { postUpdateAlias } from "redux/handlers/websitesHandler";

import { getWebsitesDetails } from "redux/selectors/websitesSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IAliasData } from "types/api/websitesApiInterface";

import AddAlias from "../actions/AddAlias";
import DeleteAlias from "../actions/DeleteAlias";
import EditAlias from "../actions/EditAlias";

const defaultAlias = {
 alias: "",
 isp_id: 0,
 site_id: 0,
 alias_id: 0,
 ssl: "",
 active: "",
 id: 0
};

const DetailsAlias = (): ReactElement => {
 const dispatch = useAppDispatch();
 const websitesDetails = useSelector(getWebsitesDetails);
 const aliases = websitesDetails.alias;
 const params = useParams<{ domain_id: string; section: string }>();

 const [selectedAlias, setSelectedAlias] = useState<IAliasData>(aliases[0] || defaultAlias);
 const [aliasLength, setAliasLength] = useState<number>(aliases.length || 0);

 const handleSwitchToggle = async (type: string, access: boolean) => {
  await dispatch(
   postUpdateAlias(
    selectedAlias.id,
    websitesDetails.isp_id,
    websitesDetails.domain_id,
    selectedAlias.alias,
    type === "ssl" ? (access ? "y" : "n") : selectedAlias.ssl,
    type === "active" ? (access ? "y" : "n") : selectedAlias.active,
    Number(params.domain_id)
   )
  );
 };

 useEffect(() => {
  if (aliasLength !== aliases.length) {
   setSelectedAlias(aliases[0]);
   setAliasLength(aliases.length);
  }
 }, [aliases]);

 return (
  <>
   <Stack>
    {aliases.length !== 0 ? (
     <>
      <Stack
       direction="row"
       alignItems="center"
       justifyContent="space-between"
       spacing={2}
       pt={2}
       py={0.5}
       pr={2}>
       <Stack direction="row" alignItems="center" textAlign="start" spacing={2} width={"100%"}>
        <Typography fontWeight="bold" variant="subtitle1" px={2} minWidth={150}>
         <FormattedMessage id="sites.aliasName" />
        </Typography>
        <Autocomplete
         autoHighlight
         size="small"
         fullWidth
         sx={{ my: 2 }}
         onChange={(e, value) =>
          !isNil(value)
           ? setSelectedAlias(aliases.find((alias) => alias.alias_id === value?.id) || defaultAlias)
           : setSelectedAlias(defaultAlias)
         }
         options={aliases.map((alias) => {
          return { label: alias.alias, id: alias.alias_id };
         })}
         value={{
          label: aliases.find((alias) => alias.alias_id === selectedAlias?.alias_id)?.alias || "",
          id: aliases.find((alias) => alias.alias_id === selectedAlias?.alias_id)?.alias_id || 0
         }}
         renderInput={(params) => (
          <TextField {...params} fullWidth={true} InputLabelProps={{ shrink: true }} />
         )}
        />
       </Stack>
      </Stack>
      <Divider />
      <Stack direction="row" alignItems="center" textAlign="start" py={0.5} pr={2}>
       <Typography fontWeight="bold" variant="subtitle1" minWidth={150} px={2}>
        <FormattedMessage id="sites.alias.ssl" />
       </Typography>
       <Switch
        defaultChecked={selectedAlias.ssl === "y" ? true : false}
        onChange={(event) => handleSwitchToggle("ssl", event.target.checked)}
       />
      </Stack>
      <Divider />
      <Stack direction="row" alignItems="center" textAlign="start" py={0.5} pr={2}>
       <Typography fontWeight="bold" variant="subtitle1" minWidth={150} px={2}>
        <FormattedMessage id="sites.alias.active" />
       </Typography>
       <Switch
        defaultChecked={selectedAlias.active === "y" ? true : false}
        onChange={(event) => handleSwitchToggle("active", event.target.checked)}
       />
      </Stack>
      <Divider />
      <Stack direction="row" alignItems="center" p={1} justifyContent="center">
       <Stack direction={"row"} alignItems="center" spacing={3} maxWidth={"100%"}>
        <AddAlias type="icon" />
        <EditAlias aliasData={selectedAlias} />
        <DeleteAlias aliasData={selectedAlias} />
       </Stack>
      </Stack>
     </>
    ) : (
     <>
      <Divider />
      <Stack p={2}>
       <Alert severity="info">
        <FormattedMessage id="sites.noAliasMessage" />
       </Alert>
      </Stack>
      <Divider />
      <AddAlias type="text" />
     </>
    )}
   </Stack>
  </>
 );
};

export default DetailsAlias;
