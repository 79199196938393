import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import IconSelector from "components/shared/images/IconSelector";

import { fetchTrackcenter, postRetriveBody } from "redux/handlers/gatewayHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { ITrackcenterData } from "types/api/gatewayApiInterface";

const Trackcenter = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const [trackList, setTrackList] = useState<Array<ITrackcenterData>>([]);
 const [trackNumber, setTrackNumber] = useState<number>(0);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);
 const [searchValue, setSearchValue] = useState<string>("");
 const [ndr, setNdr] = useState<boolean>(false);
 const [from, setFrom] = useState<string>("");
 const [to, setTo] = useState<string>("");
 const [greylist, setGreylist] = useState<boolean>(false);
 const [startTime, setStartTime] = useState(dayjs().subtract(1, "day"));
 const [endTime, setEndTime] = useState(dayjs());
 const [open, setOpen] = useState<number>(-1);
 const [trackingBody, setTrackingBody] = useState<Array<string>>([]);
 const [dataLoading, setDataLoading] = useState<boolean>(false);

 useEffect(() => {
  (async () => {
   const track = await dispatch(
    fetchTrackcenter(
     id,
     0,
     10,
     searchValue,
     greylist,
     ndr,
     from,
     to,
     startTime.unix(),
     endTime.unix()
    )
   );
   setTrackList(track.dataset);
   setTrackNumber(track.totalCount);
   setIsLoading(false);
  })();
 }, []);

 const handleOnRowsPerPageChange = async (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const newRowsPerPage = parseInt(event.target.value, 10);
  setRowPerPage(newRowsPerPage);
  setCurrentPage(0);
  const gateData = await dispatch(
   fetchTrackcenter(
    id,
    0,
    newRowsPerPage,
    searchValue,
    greylist,
    ndr,
    from,
    to,
    startTime.unix(),
    endTime.unix()
   )
  );
  setTrackList(gateData.dataset);
  setTrackNumber(gateData.totalCount);
  setOpen(-1);
 };

 const handleOnPageChange = async (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  const startIndex = newPage * rowPerPage;
  setCurrentPage(newPage);
  const gateData = await dispatch(
   fetchTrackcenter(
    id,
    startIndex,
    rowPerPage,
    searchValue,
    greylist,
    ndr,
    from,
    to,
    startTime.unix(),
    endTime.unix()
   )
  );
  setTrackList(gateData.dataset);
  setTrackNumber(gateData.totalCount);
  setOpen(-1);
 };

 const filterData = async () => {
  const track = await dispatch(
   fetchTrackcenter(
    id,
    0,
    10,
    searchValue,
    greylist,
    ndr,
    from,
    to,
    startTime.unix(),
    endTime.unix()
   )
  );
  setTrackList(track.dataset);
  setTrackNumber(track.totalCount);
 };

 const handleReset = () => {
  setSearchValue("");
  setNdr(false);
  setGreylist(false);
  setFrom("");
  setTo("");
  setStartTime(dayjs().subtract(1, "day"));
  setEndTime(dayjs());
  filterData();
 };

 const handleRetriveBody = async (index: number, mailId: string) => {
  setDataLoading(true);
  setOpen(open === index ? -1 : index);
  if (open !== index) setTrackingBody(await dispatch(postRetriveBody(id, mailId)));
  setDataLoading(false);
 };

 const RenderStatus = ({ status }: { status: string }): ReactElement => {
  switch (status) {
   case "A":
    return (
     <Stack direction="row" alignItems="center" spacing={1} justifyContent="flex-end">
      <IconSelector icon="StatusIcon" props={{ color: "success", fontSize: "small" }} />
      <Typography variant="caption">Accepted</Typography>
     </Stack>
    );
   case "Q":
    return (
     <Stack direction="row" alignItems="center" spacing={1} justifyContent="flex-end">
      <IconSelector icon="WarningIcon" props={{ color: "warning" }} />
      <Typography variant="caption">Quarantine</Typography>
     </Stack>
    );
   case "2":
    return (
     <Stack direction="row" alignItems="center" spacing={1} justifyContent="flex-end">
      <IconSelector icon="StatusIcon" props={{ color: "success", fontSize: "small" }} />
      <Typography variant="caption">Delivered</Typography>
     </Stack>
    );
   case "4":
    return (
     <Stack direction="row" alignItems="center" spacing={1} justifyContent="flex-end">
      <IconSelector icon="ReplyIcon" props={{ color: "disabled" }} />
      <Typography variant="caption">Deferred</Typography>
     </Stack>
    );
   case "5":
    return (
     <Stack direction="row" alignItems="center" spacing={1} justifyContent="flex-end">
      <IconSelector icon="ReplyIcon" props={{ color: "disabled" }} />
      <Typography variant="caption">Bounced</Typography>
     </Stack>
    );
   case "N":
    return (
     <Stack direction="row" alignItems="center" spacing={1} justifyContent="flex-end">
      <IconSelector icon="HighlightOffIcon" props={{ color: "error" }} />
      <Typography variant="caption">Rejected</Typography>
     </Stack>
    );
   case "G":
    return (
     <Stack direction="row" alignItems="center" spacing={1} justifyContent="flex-end">
      <IconSelector icon="HighlightOffIcon" props={{ color: "disabled" }} />
      <Typography variant="caption">Greylisted</Typography>
     </Stack>
    );
   case "B":
    return (
     <Stack direction="row" alignItems="center" spacing={1} justifyContent="flex-end">
      <IconSelector icon="DoNotDisturbIcon" props={{ color: "error" }} />
      <Typography variant="caption">Blocked</Typography>
     </Stack>
    );
   default:
    return (
     <Stack direction="row" alignItems="center" spacing={1} justifyContent="flex-end">
      <IconSelector icon="HelpOutlineIcon" props={{ color: "disabled" }} />
      <Typography variant="caption">Unknown</Typography>
     </Stack>
    );
  }
 };

 return isLoading ? (
  <></>
 ) : (
  <Stack>
   <Stack direction="row" justifyContent="space-between" p={2}>
    <Stack spacing={2}>
     <Stack direction="column" spacing={2}>
      <Stack spacing={1} direction={desktopViewPort ? "row" : "column"}>
       <TextField
        autoComplete="new-password"
        label={<FormattedMessage id="gateway.trackcenter.searchSpecific" />}
        value={searchValue}
        sx={{ ml: 4 }}
        InputLabelProps={{ shrink: true }}
        onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
       />
       <TextField
        autoComplete="new-password"
        label={<FormattedMessage id="gateway.trackcenter.searchFrom" />}
        value={from}
        sx={{ ml: 4 }}
        InputLabelProps={{ shrink: true }}
        onChange={({ currentTarget }) => setFrom(currentTarget.value)}
       />
       <TextField
        autoComplete="new-password"
        label={<FormattedMessage id="gateway.trackcenter.searchTo" />}
        value={to}
        sx={{ ml: 4 }}
        InputLabelProps={{ shrink: true }}
        onChange={({ currentTarget }) => setTo(currentTarget.value)}
       />
       <Stack direction="row" spacing={1}>
        <Stack direction="row" alignItems="center">
         <DatePicker
          label={<FormattedMessage id="gateway.trackcenter.startDate" />}
          value={startTime}
          maxDate={dayjs()}
          disableFuture
          onChange={(newValue) => setStartTime(newValue || dayjs())}
         />
        </Stack>
        <Stack direction="row" alignItems="center">
         <DatePicker
          label={<FormattedMessage id="gateway.trackcenter.endDate" />}
          value={endTime}
          maxDate={dayjs()}
          minDate={startTime}
          disableFuture
          onChange={(newValue) => setEndTime(newValue || dayjs())}
         />
        </Stack>
       </Stack>
      </Stack>
      <Stack direction="row" spacing={2}>
       <Stack direction="row">
        <FormControlLabel
         control={
          <Checkbox
           checked={greylist}
           onChange={(e) => {
            setGreylist(e.target.checked);
           }}
          />
         }
         label={intl.formatMessage({ id: "gateway.trackcenter.greylist" })}
        />
        <FormControlLabel
         control={
          <Checkbox
           checked={ndr}
           onChange={(e) => {
            setNdr(e.target.checked);
           }}
          />
         }
         label={intl.formatMessage({ id: "gateway.trackcenter.ndr" })}
        />
       </Stack>
      </Stack>
     </Stack>
     <Stack direction="row" alignItems="center" spacing={2}>
      <Typography fontWeight="bold" variant="caption">
       {trackNumber} <FormattedMessage id="gateway.trackcenter.totalTrack" />
      </Typography>
      <Button onClick={filterData}>
       <FormattedMessage id="gateway.trackcenter.filter" />
      </Button>
      <Button onClick={handleReset}>
       <FormattedMessage id="gateway.trackcenter.reset" />
      </Button>
     </Stack>
    </Stack>
   </Stack>
   <Stack>
    <TableContainer component="div">
     <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
       <TableRow>
        <TableCell></TableCell>
        <TableCell>
         <FormattedMessage id="gateway.trackcenter.from" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="gateway.trackcenter.receiver" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="gateway.trackcenter.date" />
        </TableCell>
        <TableCell align="right">
         <FormattedMessage id="app.status" />
        </TableCell>
       </TableRow>
      </TableHead>
      <TableBody>
       {trackList.map((row, index) => (
        <>
         <TableRow key={`isp-config-list-${index}`}>
          <TableCell>
           <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleRetriveBody(index, row.id)}>
            {open === index ? (
             <IconSelector icon="KeyboardArrowUpIcon" />
            ) : (
             <IconSelector icon="KeyboardArrowDownIcon" />
            )}
           </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
           <Typography variant="caption">{row.from}</Typography>
          </TableCell>
          <TableCell component="th" scope="row">
           <Typography variant="caption">{row.to}</Typography>
          </TableCell>
          <TableCell component="th" scope="row">
           <Typography variant="caption">{dayjs.unix(row.time).format("DD/MM/YYYY")}</Typography>
          </TableCell>
          <TableCell align="right">
           <RenderStatus status={row.dstatus} />
          </TableCell>
         </TableRow>
         <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
           <Collapse in={open === index} timeout="auto" unmountOnExit>
            {dataLoading ? (
             <Stack direction="row" justifyContent="space-between" p={2}>
              <CircularProgress />
             </Stack>
            ) : (
             <Stack>
              {trackingBody.map((element, index) => {
               return (
                <Typography variant="caption" key={`tracking-body-${index}`}>
                 {element}
                </Typography>
               );
              })}
             </Stack>
            )}
           </Collapse>
          </TableCell>
         </TableRow>
        </>
       ))}
      </TableBody>
     </Table>
    </TableContainer>
    <TablePagination
     component="div"
     count={trackNumber}
     page={currentPage}
     rowsPerPage={rowPerPage}
     onPageChange={handleOnPageChange}
     onRowsPerPageChange={handleOnRowsPerPageChange}
    />
   </Stack>
  </Stack>
 );
};

export default Trackcenter;
