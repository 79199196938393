import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postManualChangePassword } from "redux/handlers/serverHandler";
import { generatePassword } from "redux/handlers/userHandler";

import { useAppDispatch } from "hooks/reduxHook";

const ServerUpdatePassword = ({ id, password }: { id: number; password: string }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [serverPassword, setServerPassword] = useState<string>(password);
 const [loading, setLoading] = useState<boolean>(false);

 useEffect(() => {
  setServerPassword(password);
 }, [password]);

 const handleOpen = () => {
  setServerPassword(password);
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postManualChangePassword(id, serverPassword));
  setLoading(false);
  handleClose();
 };

 const handleGeneratePassword = async () => {
  setServerPassword(await generatePassword());
 };

 return (
  <>
   <Tooltip title={intl.formatMessage({ id: "app.edit" })} placement="top">
    <IconButton onClick={handleOpen}>
     <IconSelector icon="EditIcon" props={{ fontSize: "small" }} />
    </IconButton>
   </Tooltip>
   <AppModal
    open={openModal}
    close={handleClose}
    disabled={loading || serverPassword.length === 0 || serverPassword === password}
    title={intl.formatMessage({ id: "server.changePassword.manual" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}>
    <Stack direction="row" spacing={1} alignItems="center">
     <TextField
      fullWidth
      label={intl.formatMessage({ id: "server.password" })}
      placeholder={intl.formatMessage({ id: "server.password" })}
      value={serverPassword}
      autoComplete="off"
      onChange={(event) => setServerPassword(event.target.value)}
      error={serverPassword.length === 0}
     />
     <Button variant="kxActionButton" onClick={handleGeneratePassword} sx={{ width: "40%" }}>
      <FormattedMessage id="app.generatePassword" />
     </Button>
    </Stack>
   </AppModal>
  </>
 );
};

export default ServerUpdatePassword;
