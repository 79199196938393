import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const PowerIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_53_278)">
   <rect width="20" height="20" fill="transparent" />
   <path
    d="M3.636 3.636C2.37734 4.89468 1.52019 6.49832 1.17293 8.24415C0.82567 9.98998 1.00391 11.7996 1.6851 13.4441C2.36629 15.0886 3.51984 16.4942 4.99988 17.4832C6.47992 18.4721 8.21998 18.9999 10 18.9999C11.78 18.9999 13.5201 18.4721 15.0001 17.4832C16.4802 16.4942 17.6337 15.0886 18.3149 13.4441C18.9961 11.7996 19.1743 9.98998 18.8271 8.24415C18.4798 6.49832 17.6227 4.89468 16.364 3.636M10 1V10"
    stroke="currentColor"
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
   />
  </g>
  <defs>
   <clipPath id="clip0_53_278">
    <rect width="20" height="20" fill="transparent" />
   </clipPath>
  </defs>
 </svg>,
 "Power"
);
