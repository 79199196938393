import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { postChangePrivateNetwork } from "redux/handlers/addonsHandle";

import { getLoadBalancerDetails } from "redux/selectors/loadBalancerSelector";

import { useAppDispatch } from "hooks/reduxHook";

import ManageUpdateAlgorithm from "../actions/ManageUpdateAlgorithm";
import ManageUpdateName from "../actions/ManageUpdateName";

const LoadBalancerSettings = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const balancerData = useSelector(getLoadBalancerDetails);

 const [checkedNetwork, setCheckedNetwork] = useState<boolean>(false);

 useEffect(() => {
  setCheckedNetwork(balancerData?.public_net_enabled || false);
 }, [balancerData?.public_net_enabled]);

 const handleSwitchToggle = async (value: boolean) => {
  setCheckedNetwork(value);
  await dispatch(postChangePrivateNetwork(balancerData?.id || 0, value));
 };

 return (
  <>
   <Stack direction="row" justifyContent="space-between" alignItems="center" px={1}>
    <Stack>
     <Typography fontWeight="bold">
      <FormattedMessage id="addons.loadBalancer.name" />
     </Typography>
    </Stack>
    <Stack direction="row" alignItems="center">
     <Typography>{balancerData?.name || "Not Available"}</Typography>
     <ManageUpdateName id={balancerData?.id || 0} oldName={balancerData?.name || ""} />
    </Stack>
   </Stack>
   <Divider />
   <Stack direction="row" justifyContent="space-between" alignItems="center" px={1}>
    <Stack>
     <Typography fontWeight="bold">
      <FormattedMessage id="addons.loadBalancer.algorithm" />
     </Typography>
    </Stack>
    <Stack direction="row" alignItems="center">
     <Typography>{balancerData?.algorithm || "Not Available"}</Typography>
     <ManageUpdateAlgorithm id={balancerData?.id || 0} oldType={balancerData?.algorithm || ""} />
    </Stack>
   </Stack>
   <Divider />
   <Stack direction="row" justifyContent="space-between" alignItems="center" px={1}>
    <Stack>
     <Typography fontWeight="bold">
      <FormattedMessage id="addons.loadBalancer.publicNetwork" />
     </Typography>
    </Stack>
    <Stack direction="row" alignItems="center">
     {balancerData?.private_net && balancerData?.private_net.length > 0 ? (
      <Switch
       checked={checkedNetwork}
       onChange={(event) => handleSwitchToggle(event.target.checked)}
      />
     ) : (
      <Tooltip title={intl.formatMessage({ id: "addons.loadBalancer.publicNetMessage" })}>
       <Switch checked={checkedNetwork} disabled />
      </Tooltip>
     )}
    </Stack>
   </Stack>
  </>
 );
};

export default LoadBalancerSettings;
