/* eslint-disable react/style-prop-object */
import React, { ReactElement, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { isNil } from "ramda";

import SettingsInputAntennaIcon from "@mui/icons-material/SettingsInputAntenna";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { floatingIpSteps } from "constants/addons";

import SectionsBreadCrumbs from "components/shared/breadCrumbs/SectionsBreadCrumbs";
import AppModal from "components/shared/modal/AppModal";

import { getFloatingIpPrice, postAddFloatingIp } from "redux/handlers/addonsHandle";
import { getAllServersNotPaged, getServersLocations } from "redux/handlers/serverHandler";

import { getUserBalance } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IAllServers, ILocations } from "types/api/serversApiInterface";

const FloatingIpWizard = (): ReactElement => {
 const intl = useIntl();
 const navigate = useNavigate();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const userBalance = useSelector(getUserBalance);

 const [activeStep, setActiveStep] = useState<number>(0);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [stepLoading, setStepLoading] = useState<boolean>(false);
 const [locationList, setLocationList] = useState<Array<ILocations>>([]);
 const [servers, setServers] = useState<Array<IAllServers>>([]);
 const [price, setPrice] = useState<number>(0);
 const [loading, setLoading] = useState<boolean>(false);

 useEffect(() => {
  (async () => {
   setLocationList(await dispatch(getServersLocations(1)));
   setServers(await dispatch(getAllServersNotPaged()));
   setIsLoading(false);
  })();
 }, []);

 const { control, formState, reset, watch, getValues, setValue } = useForm({
  defaultValues: {
   name: "",
   datacenter: "",
   type: "",
   server_id: 0
  }
 });

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);
 const handleReset = () => {
  reset();
  setActiveStep(0);
 };

 const handleNext = () => {
  if (activeStep === 0) handleCheckPrice();
  setActiveStep((prevActiveStep) => prevActiveStep + 1);
 };

 const handleBack = () => {
  setActiveStep((prevActiveStep) => prevActiveStep - 1);
 };

 const handleCheckPrice = async () => {
  setStepLoading(true);
  setPrice(await dispatch(getFloatingIpPrice()));
  setStepLoading(false);
 };

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(
   postAddFloatingIp(
    getValues("name"),
    getValues("type").toLowerCase(),
    getValues("datacenter"),
    getValues("server_id")
   )
  );
  setLoading(false);
  handleClose();
  handleReset();
  navigate("/addons/floatingip");
 };

 const renderStepContent = (index: number) => {
  switch (index) {
   case 0:
    return (
     <Controller
      name="name"
      control={control}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        onChange={(e) => {
         setValue("name", e.target.value);
        }}
        label={intl.formatMessage({ id: "addons.floatingip.add.name" })}
        error={formState.isDirty && !!formState?.errors?.name}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="new-password"
       />
      )}
     />
    );
   case 1:
    return (
     <Controller
      name="datacenter"
      control={control}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) => {
         setValue("datacenter", value?.id || "");
        }}
        options={locationList.map((element) => {
         return { label: element.city, id: element.name };
        })}
        value={locationList
         .map((element) => {
          return { label: element.city, id: element.name };
         })
         .find((element) => element.id === getValues("datacenter"))}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          label={<FormattedMessage id="addons.floatingip.add.datacenter" />}
          error={formState.isDirty && !!formState?.errors?.datacenter}
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
      )}
     />
    );
   case 2:
    return (
     <Controller
      name="type"
      control={control}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) => (isNil(value) ? setValue("type", "") : setValue("type", value))}
        options={["IPv4", "IPv6"]}
        value={watch("type")}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          label={<FormattedMessage id="addons.floatingip.add.type" />}
          error={formState.isDirty && !!formState?.errors?.type}
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
      )}
     />
    );
   case 3:
    return (
     <Controller
      name="server_id"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) =>
         isNil(value) ? setValue("server_id", 0) : setValue("server_id", value.server_id)
        }
        options={servers.map((element) => {
         return {
          server_id: element.server_id,
          label: element.server_name
         };
        })}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          label={<FormattedMessage id="addons.floatingip.add.server" />}
          error={formState.isDirty && !!formState?.errors?.server_id}
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
      )}
     />
    );
  }
 };

 const disableNextButton = (index: number) => {
  switch (index) {
   case 0:
    return watch("name").length === 0;
   case 1:
    return watch("datacenter") === "";
   case 2:
    return watch("type") === "";
   case 3:
    return watch("server_id") === 0;
   default:
    return false;
  }
 };

 const checkDisableConfirm = () => {
  return (
   watch("name") === "" ||
   watch("datacenter") === "" ||
   watch("type") === "" ||
   watch("server_id") === 0
  );
 };

 return !isLoading ? (
  <Container maxWidth="xl">
   <Stack mt={2}>
    <SectionsBreadCrumbs
     links={[
      { name: "home", url: "/" },
      { name: "addons", url: "/addons/volumes" },
      { name: "Floating IP", url: "/addons/floatingip" },
      { name: "add", url: "/addons/floatingip/add" }
     ]}
    />
   </Stack>
   <Stack spacing={2}>
    <Stack alignItems="center" mt={5} p={3}>
     <SettingsInputAntennaIcon />
     <Typography>
      <FormattedMessage id={"addons.floatingip.addNewFloatingIp"} />
     </Typography>
    </Stack>
    <Stack direction={desktopViewPort ? "row" : "column"} justifyContent="center">
     <Box
      sx={{ maxWidth: desktopViewPort ? 1000 : "auto", minWidth: desktopViewPort ? 500 : "auto" }}
      m={10}>
      <Stepper activeStep={activeStep} orientation="vertical">
       {floatingIpSteps.map((step, index) => (
        <Step key={step.label}>
         <StepLabel>
          <FormattedMessage id={step.label} />
         </StepLabel>
         {!stepLoading ? (
          <StepContent>
           <Stack spacing={2}>
            <Alert severity="info">
             <Typography>
              <FormattedMessage id={step.description} />
             </Typography>
            </Alert>
            {renderStepContent(index)}
           </Stack>
           <Box sx={{ mb: 2 }}>
            <div>
             {index !== floatingIpSteps.length - 1 ? (
              <Button
               disabled={disableNextButton(index)}
               variant="contained"
               onClick={handleNext}
               sx={{ mt: 1, mr: 1 }}>
               <FormattedMessage id="addons.floatingip.continue" />
              </Button>
             ) : (
              <></>
             )}
             {index === 3 && (
              <>
               <Button
                sx={{ mt: 1, mr: 1 }}
                variant="contained"
                disabled={checkDisableConfirm()}
                onClick={handleOpen}>
                <FormattedMessage id="addons.floatingip.confirmFloating" />
               </Button>
               <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                <FormattedMessage id="addons.floatingip.floatingReset" />
               </Button>
              </>
             )}
             <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
              <FormattedMessage id="addons.floatingip.back" />
             </Button>
            </div>
           </Box>
          </StepContent>
         ) : (
          <StepContent>
           <Stack spacing={2}>
            <Alert severity="info">
             <Typography>
              <FormattedMessage id="server.checkExecution" />
             </Typography>
            </Alert>
            {renderStepContent(index)}
            <CircularProgress />
           </Stack>
          </StepContent>
         )}
        </Step>
       ))}
      </Stepper>
     </Box>
     <Paper
      elevation={0}
      sx={{
       m: 5,
       p: 2,
       maxHeight: 330,
       position: "sticky",
       top: 30,
       minWidth: desktopViewPort ? 400 : "auto",
       borderRadius: "10px",
       boxShadow: 0
      }}>
      <Stack mb={2}>
       <Typography variant="h5" fontWeight="bold">
        <FormattedMessage id="addons.floatingip.add.recap" />
       </Typography>
      </Stack>
      <Divider textAlign="left">
       <Typography variant="overline">
        <FormattedMessage id="addons.floatingip.add.floatingData" />
       </Typography>
      </Divider>
      <Stack direction="row" spacing={2}>
       <Typography minWidth={120} textAlign="start" fontWeight="bold">
        <FormattedMessage id="addons.floatingip.add.name" />:
       </Typography>
       <Typography noWrap>{watch("name")}</Typography>
      </Stack>
      <Divider textAlign="left" sx={{ mt: 2 }}>
       <Typography variant="overline">
        <FormattedMessage id="addons.floatingip.add.floatingSpecs" />
       </Typography>
      </Divider>
      <Stack direction="row" spacing={2}>
       <Typography minWidth={120} textAlign="start" fontWeight="bold">
        <FormattedMessage id="addons.floatingip.add.datacenterName" />:
       </Typography>
       <Typography noWrap>
        {locationList.find((element) => element.name === watch("datacenter"))?.city || ""}
       </Typography>
       <Stack
        component="span"
        className={`fi fi-${
         locationList
          .find((element) => element.name === watch("datacenter"))
          ?.country.toLocaleLowerCase() || ""
        }`}
       />
      </Stack>
      <Stack direction="row" spacing={2}>
       <Typography minWidth={120} textAlign="start" fontWeight="bold">
        <FormattedMessage id="addons.floatingip.add.typeName" />:
       </Typography>
       <Typography noWrap>{watch("type")}</Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
       <Typography minWidth={120} textAlign="start" fontWeight="bold">
        <FormattedMessage id="addons.floatingip.add.linkedServer" />:
       </Typography>
       <Typography noWrap>
        {watch("server_id") === 0 ? (
         <FormattedMessage id="addons.floatingip.add.noServerLinked" />
        ) : (
         servers.find((element) => element.server_id === watch("server_id"))?.server_name
        )}
       </Typography>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
       <Typography minWidth={150} textAlign="start" fontWeight="bold">
        <FormattedMessage id="addons.floatingip.add.price" />
       </Typography>
       <Typography fontSize={"x-large"} fontStyle="italic">
        <FormattedNumber value={price} style={"currency"} currency="EUR" />
       </Typography>
      </Stack>
     </Paper>
    </Stack>
    <AppModal
     open={openModal}
     close={handleClose}
     title={intl.formatMessage({ id: "addons.floatingip.add.createNewFloatingIp" })}
     handleClose={handleClose}
     disabled={loading}
     handleConfirm={handleConfirm}>
     <Stack>
      <FormattedMessage id="addons.floatingip.add.confirmCreation" />
      <Stack direction="row" spacing={2}>
       <Typography minWidth={150} textAlign="start" fontWeight="bold">
        <FormattedMessage id="server.add.balance" />
       </Typography>
       <Typography>
        <FormattedNumber value={userBalance} style={"currency"} currency="EUR" />
       </Typography>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
       <Typography minWidth={150} textAlign="start" fontWeight="bold">
        <FormattedMessage id="server.add.totalPrice" />
       </Typography>
       <Typography>
        <FormattedNumber value={price} style={"currency"} currency="EUR" />
       </Typography>
      </Stack>
     </Stack>
    </AppModal>
   </Stack>
  </Container>
 ) : (
  <></>
 );
};

export default FloatingIpWizard;
