import { AppAction } from "redux/store";

import notificationReducer from "redux/reducers/notificationReducer";

import { generateUniqueId } from "./generic";

export const showNotification =
 (text: string, result: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  if (result === "success") {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `${text}`,
     timestamp: Date.now(),
     type: "success"
    })
   );
  } else {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to execute action ${text}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };
