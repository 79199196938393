import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const SslIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_67_34)">
   <rect width="20" height="20" fill="transparent" />
   <path
    d="M7 10.536L9.25 12.786L13 7.536M10 0.5C7.73574 2.65023 4.72026 3.82958 1.598 3.786C1.20084 4.99598 0.998976 6.2615 1 7.535C1 13.127 4.824 17.825 10 19.158C15.176 17.826 19 13.128 19 7.536C19 6.226 18.79 4.965 18.402 3.785H18.25C15.054 3.785 12.15 2.537 10 0.5Z"
    stroke="currentColor"
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
   />
  </g>
  <defs>
   <clipPath id="clip0_67_34">
    <rect width="20" height="20" fill="transparent" />
   </clipPath>
  </defs>
 </svg>,
 "Ssl"
);
