import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { getLoadBalancerDetails } from "redux/selectors/loadBalancerSelector";

import ChangeDnsPtr from "./actions/ChangeDnsPtr";

const NetworkingPublicNetwork = (): ReactElement => {
 const balancerData = useSelector(getLoadBalancerDetails);

 return (
  <Stack p={2}>
   <Stack direction="row" justifyContent="space-between" alignItems="center">
    <Typography fontWeight="bold">
     <FormattedMessage id="addons.loadBalancer.networkIpv4" />
    </Typography>
    <Typography sx={{ minWidth: 180, textAlign: "left" }}>
     {balancerData?.public_net_ipv4_ip || "-"}
    </Typography>
    <Stack direction="row" alignItems="center" minWidth={350} justifyContent="flex-end">
     <Typography>{balancerData?.public_net_ipv4_dns_ptr || "-"}</Typography>
     <ChangeDnsPtr
      id={balancerData?.id || 0}
      ip={balancerData?.public_net_ipv4_ip || ""}
      oldDns={balancerData?.public_net_ipv4_dns_ptr || ""}
     />
    </Stack>
   </Stack>
   <Divider />
   <Stack direction="row" justifyContent="space-between" alignItems="center">
    <Typography fontWeight="bold">
     <FormattedMessage id="addons.loadBalancer.networkIpv6" />
    </Typography>
    <Typography sx={{ minWidth: 180, textAlign: "left" }}>
     {balancerData?.public_net_ipv6_ip || "-"}
    </Typography>
    <Stack direction="row" alignItems="center" minWidth={350} justifyContent="flex-end">
     <Typography>{balancerData?.public_net_ipv6_dns_ptr || "-"}</Typography>
     <ChangeDnsPtr
      id={balancerData?.id || 0}
      ip={balancerData?.public_net_ipv6_ip || ""}
      oldDns={balancerData?.public_net_ipv6_dns_ptr || ""}
     />
    </Stack>
   </Stack>
  </Stack>
 );
};

export default NetworkingPublicNetwork;
