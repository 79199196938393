import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";

import IconSelector from "components/shared/images/IconSelector";

import { networksTableData } from "types/global/table";

import DeleteNetwork from "./actions/DeleteNetwork";

const NetworkActions = ({ rowData }: { rowData: networksTableData }): ReactElement => {
 const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
 const open = Boolean(anchorEl);

 const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
  setAnchorEl(null);
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <IconSelector icon="MenuIcon" />
   </Button>
   <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
     "aria-labelledby": "basic-button"
    }}>
    <Stack>
     {rowData.protection ? (
      <MenuItem disabled>
       <FormattedMessage id="addons.network.deleteNetwork" />
      </MenuItem>
     ) : (
      <DeleteNetwork id={rowData.id} closeMenu={handleClose} />
     )}
    </Stack>
   </Menu>
  </>
 );
};

export default NetworkActions;
