import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const OutboundIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <path
   d="M8 8V5.5C8 5.10218 8.15804 4.72064 8.43934 4.43934C8.72064 4.15804 9.10218 4 9.5 4H13.5C13.8978 4 14.2794 4.15804 14.5607 4.43934C14.842 4.72064 15 5.10218 15 5.5V14.5C15 14.8978 14.842 15.2794 14.5607 15.5607C14.2794 15.842 13.8978 16 13.5 16H9.5C9.10218 16 8.72064 15.842 8.43934 15.5607C8.15804 15.2794 8 14.8978 8 14.5V12"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinejoin="round"
  />
  <path
   d="M6 12L4 10M4 10L6 8M4 10L12.5 10"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
 </svg>,
 "Outbound"
);
