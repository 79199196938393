import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import BalancerTypeTable from "components/wizard/section/BalancerTypeTable";

import { getAllLoadBalancersPrices } from "redux/handlers/addonsHandle";

import { getLoadBalancerDetails } from "redux/selectors/loadBalancerSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { ILoadBalancerType } from "types/api/addonsApiInterface";

import ConfirmRescale from "./actions/ConfirmRescale";

const LoadBalancerRescale = (): ReactElement => {
 const dispatch = useAppDispatch();

 const balancerData = useSelector(getLoadBalancerDetails);

 const [balancerTypes, setBalancerTypes] = useState<Array<ILoadBalancerType>>([]);
 const [selectedBalancer, setSelectedBalancer] = useState<number>(0);
 const [isLoading, setIsLoading] = useState<boolean>(true);

 useEffect(() => {
  (async () => {
   const balList = await dispatch(getAllLoadBalancersPrices());
   setBalancerTypes(balList.filter((element, index) => index > retriveBalancerIndex()));
   setIsLoading(false);
  })();
 }, []);

 const retriveBalancerIndex = (): number => {
  switch (balancerData?.load_balancer_type_name) {
   case "BC01":
    return 0;
   case "BC02":
    return 1;
   case "BC03":
    return 2;
   default:
    return 0;
  }
 };

 return isLoading ? (
  <Paper elevation={0} sx={{ mt: 3, pb: 0.7, borderRadius: "10px", boxShadow: 0 }}>
   <CircularProgress />
  </Paper>
 ) : (
  <Paper elevation={0} sx={{ mt: 3, pb: 0.7, borderRadius: "10px", boxShadow: 0 }}>
   <Stack direction="row" justifyContent="space-between" alignItems="center">
    <Stack alignItems="center" justifyContent="flex-start" direction="row" px={2} py={1}>
     <IconSelector icon="KeyIcon" props={{ fontSize: "medium", color: "secondary" }} />
     <Typography variant="h6" component="h2" ml={1}>
      <FormattedMessage id="addons.loadBalancer.rescale" />{" "}
     </Typography>
    </Stack>
   </Stack>
   <Stack spacing={2} p={1}>
    <Alert severity="info">
     <FormattedMessage id="addons.loadBalancer.rescaleInfo" />
    </Alert>
    <BalancerTypeTable
     balancerList={balancerTypes}
     selectBalancer={(id: number) => setSelectedBalancer(id)}
    />
    {selectedBalancer !== 0 && (
     <Stack direction="row">
      <ConfirmRescale
       id={selectedBalancer}
       rescaleId={selectedBalancer}
       price={balancerTypes.find((element) => element.id === selectedBalancer)?.amount || 0}
      />
     </Stack>
    )}
   </Stack>
  </Paper>
 );
};

export default LoadBalancerRescale;
