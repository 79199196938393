import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { ICustomersApi } from "types/api/customersApiInterface";

const initialState: ICustomersApi = { dataset: [], totalCount: 0 };

const customersReducer = createSlice({
 name: "customers",
 initialState,
 reducers: {
  setCustomers: (state, { payload }: PayloadAction<ICustomersApi>) => {
   return payload;
  },
  resetCustomers: () => {
   return initialState;
  }
 }
});

export default customersReducer;
