import React, { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useDebounce } from "react-use";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import SearchIcon from "@mui/icons-material/Search";

import Alert from "@mui/material/Alert";
import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import DockerDeleteWebsite from "components/docker/website/actions/DockerDeleteWebsite";
import DockerWebsiteSsl from "components/docker/website/actions/DockerWebsiteSsl";
import EmptyList from "components/shared/emptyList/EmptyList";
import IconSelector from "components/shared/images/IconSelector";

import { getDockerProxy, getServerSites } from "redux/handlers/dockerHandler";

import {
 getDockerWebsiteNumber,
 getDockerWebsitesList
} from "redux/selectors/dockerWebsiteSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IDockerSiteData } from "types/api/dockerApiInterface";

import CreateNewSite from "../actions/CreateNewSite";
import PowerOnSite from "../actions/PowerOnSite";
import RecreateSite from "../actions/RecreateSite";
import SitePerformance from "../SitePerformance";
import SiteVersion from "../SiteVersion";

import ContainerWebsiteSpecific from "./ContainerWebsiteSpecific";

const ContainerWebsites = ({ id }: { id: number }): ReactElement => {
 const dispatch = useAppDispatch();

 const { socket } = useContext(AppContext);

 const searchRef = useRef<HTMLInputElement>(null);
 const websites = useSelector(getDockerWebsitesList);
 const websiteNumber = useSelector(getDockerWebsiteNumber);

 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [listWasEmpty, setListWasEmpty] = useState<boolean>(false);
 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);
 const [searchValue, setSearchValue] = useState<string>("");
 const [openCollapse, setOpenCollapse] = useState<number>(-1);
 const [reloadData, setReloadData] = useState<boolean>(true);
 const [noProxyFound, setNoProxyFound] = useState<boolean>(false);

 useEffect(() => {
  if (!isNil(socket)) {
   socket.on("docker_data", (value): void => {
    if (value?.completed && value?.section === "websites") {
     setReloadData(true);
    }
   });
  }
  return () => {
   !isNil(socket) && socket.off("docker_data");
  };
 }, [socket]);

 useDebounce(
  async () => {
   if (!isLoading) {
    setCurrentPage(0);
    await dispatch(getServerSites(id, currentPage, rowPerPage, searchRef?.current?.value));
   }
  },
  1000,
  [searchValue]
 );

 useEffect(() => {
  (async () => {
   if (reloadData) {
    const containerList = await dispatch(getDockerProxy(id, 0, 10));
    if (containerList.totalCount === 0) setNoProxyFound(true);
    await dispatch(getServerSites(id, currentPage, rowPerPage, searchRef?.current?.value));
    if (websiteNumber === 0) setListWasEmpty(true);
    if (isLoading) setIsLoading(false);
    setReloadData(false);
   }
  })();
 }, [reloadData]);

 const handleOnRowsPerPageChange = async (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const newRowsPerPage = parseInt(event.target.value, 10);
  setRowPerPage(newRowsPerPage);
  setCurrentPage(0);
  await dispatch(getServerSites(id, 0, newRowsPerPage, searchRef?.current?.value));
 };

 const handleOnPageChange = async (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  const startIndex = newPage * rowPerPage;
  setCurrentPage(newPage);
  await dispatch(getServerSites(id, startIndex, rowPerPage, searchRef?.current?.value));
 };

 const handleCollapse = (val: number) => {
  setOpenCollapse(val);
 };

 const RenderActions = ({ rowData }: { rowData: IDockerSiteData }): ReactElement => {
  switch (rowData.status) {
   case "offline":
    return <RecreateSite id={id} siteName={rowData.site_name} />;
   case "stop":
    return <PowerOnSite id={rowData.id} type="site" />;
   default:
    return (
     <Typography variant="caption">
      <FormattedMessage id="docker.website.noAction" />
     </Typography>
    );
  }
 };

 const RenderTable = ({
  rowData,
  index
 }: {
  rowData: IDockerSiteData;
  index: number;
 }): ReactElement => {
  if (rowData.status === "online") {
   return (
    <>
     <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
      <TableCell component="th" scope="row">
       <IconButton onClick={() => handleCollapse(index === openCollapse ? -1 : index)}>
        <IconSelector
         icon={index === openCollapse ? "KeyboardArrowUpIcon" : "KeyboardArrowDownIcon"}
        />
       </IconButton>
      </TableCell>
      <TableCell component="th" scope="row">
       <Chip
        size="small"
        color={rowData.status === "online" ? "success" : "error"}
        label={<Typography variant="caption">{rowData.status}</Typography>}
       />
      </TableCell>
      <TableCell component="th" scope="row">
       <Typography variant="subtitle2" fontWeight="bold">
        {rowData.site_name}
       </Typography>
      </TableCell>
      <TableCell component="th" scope="row">
       <DockerWebsiteSsl id={rowData.id} check={rowData.ssl} />
      </TableCell>
      <TableCell component="th" scope="row">
       <SiteVersion version={rowData.version} />
      </TableCell>
      <TableCell component="th" scope="row">
       <SitePerformance name={rowData.site_name} />
      </TableCell>
      <TableCell align="right" onClick={(e) => e.stopPropagation()}>
       <Stack direction="row" justifyContent="flex-end">
        <DockerDeleteWebsite id={rowData.id} />
       </Stack>
      </TableCell>
     </TableRow>
     <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
       <Collapse in={openCollapse === index} timeout="auto" unmountOnExit>
        <ContainerWebsiteSpecific rowData={rowData} />
       </Collapse>
      </TableCell>
     </TableRow>
    </>
   );
  } else {
   return (
    <TableRow>
     <TableCell component="th" scope="row"></TableCell>
     <TableCell component="th" scope="row">
      <Stack direction="row" spacing={2} alignItems="center">
       <Chip
        size="small"
        color={rowData.status === "offline" || rowData.status === "stop" ? "error" : "warning"}
        label={<Typography variant="caption">{rowData.status}</Typography>}
       />
      </Stack>
     </TableCell>
     <TableCell component="th" scope="row">
      <Typography variant="subtitle2" fontWeight="bold">
       {rowData.site_name}
      </Typography>
     </TableCell>
     <TableCell component="th" scope="row">
      <DockerWebsiteSsl id={rowData.id} check={rowData.ssl} />
     </TableCell>
     <TableCell component="th" scope="row"></TableCell>
     <TableCell component="th" scope="row"></TableCell>
     <TableCell align="right">
      <RenderActions rowData={rowData} />
     </TableCell>
    </TableRow>
   );
  }
 };

 return isLoading ? (
  <></>
 ) : websites.length > 0 || !listWasEmpty ? (
  <Stack>
   <Stack direction="row" justifyContent="space-between" p={2}>
    <Stack direction="row" alignItems="center">
     <TextField
      size="small"
      autoComplete="new-password"
      label={<FormattedMessage id="docker.website.searchWebsite" />}
      inputRef={searchRef}
      InputLabelProps={{ shrink: true }}
      InputProps={{ startAdornment: <SearchIcon color="disabled" />, autoComplete: "off" }}
      onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
     />
    </Stack>
    <Stack>
     {noProxyFound ? (
      <Alert severity="warning">
       <FormattedMessage id="docker.website.noProxyFound" />
      </Alert>
     ) : (
      <CreateNewSite id={id} />
     )}
    </Stack>
   </Stack>
   <Stack>
    <TableContainer component="div">
     <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
       <TableRow>
        <TableCell component="th" scope="row"></TableCell>
        <TableCell>
         <FormattedMessage id="docker.website.status" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="docker.website.name" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="docker.website.ssl" />
        </TableCell>
        <TableCell align="center">
         <FormattedMessage id="docker.website.version" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="docker.website.performance" />
        </TableCell>
        <TableCell align="right">
         <FormattedMessage id="app.actions" />
        </TableCell>
       </TableRow>
      </TableHead>
      <TableBody>
       {websites.map((row, index) => (
        <RenderTable key={`docker-sites-list-${index}`} rowData={row} index={index} />
       ))}
      </TableBody>
     </Table>
    </TableContainer>
    <TablePagination
     component="div"
     count={websiteNumber}
     page={currentPage}
     rowsPerPage={rowPerPage}
     onPageChange={handleOnPageChange}
     onRowsPerPageChange={handleOnRowsPerPageChange}
    />
   </Stack>
  </Stack>
 ) : noProxyFound ? (
  <Stack p={2}>
   <Alert severity="warning">
    <FormattedMessage id="docker.website.noProxyFound" />
   </Alert>
  </Stack>
 ) : (
  <Stack alignItems="center">
   <EmptyList />
   <CreateNewSite id={id} />
  </Stack>
 );
};

export default ContainerWebsites;
