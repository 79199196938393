import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postUpdateDatabase } from "redux/handlers/websitesHandler";

import { getWebsitesDetails } from "redux/selectors/websitesSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IDbData } from "types/api/websitesApiInterface";

const EditDatabase = ({ dbData }: { dbData: IDbData }): ReactElement => {
 const intl = useIntl();
 const websitesDetails = useSelector(getWebsitesDetails);
 const params = useParams<{ domain_id: string; section: string }>();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const { control, handleSubmit, watch, formState } = useForm({
  defaultValues: {
   db_name: dbData.database_name,
   quota: dbData.database_quota
  }
 });

 const onSubmit: SubmitHandler<{
  db_name: string;
  quota: number;
 }> = async (data: { db_name: string; quota: number }) => {
  setIsLoading(true);
  await dispatch(
   postUpdateDatabase(
    dbData.id,
    websitesDetails.isp_id,
    websitesDetails.domain_id,
    data.db_name,
    data.quota,
    dbData.remote_access,
    Number(params.domain_id),
    dbData.remote_ips
   )
  );

  setIsLoading(false);
  setOpenModal(false);
 };

 const checkDisabled = (): boolean => {
  return watch("db_name") === "" || watch("quota") === 0 || watch("db_name").includes("-");
 };

 return (
  <Stack maxWidth={600}>
   <Tooltip title={<FormattedMessage id="edit.site.db" />}>
    <Button variant="kxActionButton" onClick={handleOpen} disabled={dbData.id === 0}>
     <IconSelector icon="EditIcon" />
    </Button>
   </Tooltip>
   <AppModal
    open={openModal}
    close={handleClose}
    loading={isLoading}
    title={intl.formatMessage({ id: "sites.dbOperations" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={checkDisabled()}>
    <Stack justifyContent="center" alignContent="center" direction="column">
     <Alert severity="info">
      <FormattedMessage id="sites.dbQuotaMessage" />
     </Alert>
     <Controller
      name="db_name"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "sites.dbName" })}
        error={
         watch("db_name").includes("-") ? true : formState.isDirty && !!formState?.errors?.db_name
        }
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         watch("db_name").includes("-")
          ? intl.formatMessage({ id: "sites.dbErrorLine" })
          : formState.isDirty &&
            !!formState?.errors?.db_name &&
            intl.formatMessage({ id: "sites.dbNameError" })
        }
       />
      )}
     />
     <Controller
      name="quota"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "sites.dbQuota" })}
        error={formState.isDirty && !!formState?.errors?.quota}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        type="number"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.quota &&
         intl.formatMessage({ id: "sites.dbQuotaError" })
        }
       />
      )}
     />
    </Stack>
   </AppModal>
  </Stack>
 );
};

export default EditDatabase;
