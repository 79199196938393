import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import AppModal from "components/shared/modal/AppModal";

import { postUpdateFloatingRdns } from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

const ChangeDnsPtr = ({
 id,
 oldRdns,
 ip,
 closeMenu
}: {
 id: number;
 oldRdns: string;
 ip: string;
 closeMenu: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpenModal = () => {
  reset();
  setOpenModal(true);
 };
 const handleCloseModal = () => setOpenModal(false);

 const { control, reset, handleSubmit, formState } = useForm({
  defaultValues: {
   dns: oldRdns
  }
 });

 const onSubmit: SubmitHandler<{ dns: string }> = async (data: { dns: string }) => {
  setLoading(true);
  await dispatch(postUpdateFloatingRdns(id, data.dns, ip));
  handleCloseModal();
  setLoading(false);
  closeMenu();
 };

 return (
  <>
   <MenuItem onClick={() => handleOpenModal()}>
    <FormattedMessage id="addons.floatingIp.updateRdns" />
   </MenuItem>
   <AppModal
    open={openModal}
    close={handleCloseModal}
    title={intl.formatMessage({ id: "addons.floatingIp.updateRdns" })}
    handleClose={handleCloseModal}
    disabled={loading}
    handleConfirm={handleSubmit(onSubmit)}>
    <Stack spacing={2}>
     <Controller
      name="dns"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
       <TextField
        {...field}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
         e.stopPropagation();
        }}
        label={intl.formatMessage({ id: "addons.floatingIp.rdns" })}
        InputLabelProps={{ shrink: true }}
        error={formState.isDirty && !!formState?.errors?.dns}
        helperText={
         formState.isDirty &&
         !!formState?.errors?.dns &&
         intl.formatMessage({ id: "addons.floatingIp.rdnsError" })
        }
       />
      )}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default ChangeDnsPtr;
