import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

import { IMailsDataset } from "types/api/mailApiInterface";

export const getMails = (state: AppState) => state.mails;

export const getMailNumber = createSelector(getMails, (mails) => {
 return mails?.totalCount;
});

export const getMailsList = createSelector(getMails, (mails) => {
 return (
  mails?.dataset?.map((mail: IMailsDataset) => {
   return {
    active: mail.active,
    dkim_public: mail.dkim_public,
    dkim_selector: mail.dkim_selector,
    dkim_private: mail.dkim_private,
    policy_id: mail.policy_id || 0,
    domain: mail.domain,
    domain_id: mail.domain_id,
    dkim: mail.dkim,
    id: mail.id,
    isp_id: mail.isp_id,
    mailbox: mail.mailbox,
    spam_filter: mail.spam_filter
   };
  }) || []
 );
});
