import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

import CreateDatabase from "../../actions/CreateDatabase";

const DatabaseNotFound = ({ id }: { id: number }): ReactElement => {
 return (
  <Stack spacing={2}>
   <Alert severity="warning">
    <FormattedMessage id="docker.website.noDatabaseFoundMessage" />
   </Alert>
   <Stack direction="row">
    <CreateDatabase id={id} />
   </Stack>
  </Stack>
 );
};

export default DatabaseNotFound;
