import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const AddonsIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="white" />
  <path
   fillRule="evenodd"
   clipRule="evenodd"
   d="M9.16892 0.770264C9.16892 0.35605 8.83313 0.0202637 8.41892 0.0202637H0.770269C0.356056 0.0202637 0.0202694 0.35605 0.0202694 0.770264V8.41891C0.0202694 8.83313 0.356056 9.16891 0.770269 9.16891H8.41892C8.83313 9.16891 9.16892 8.83313 9.16892 8.41891V0.770264ZM8.41892 0.520264C8.55699 0.520264 8.66892 0.632192 8.66892 0.770264V8.41891C8.66892 8.55698 8.55699 8.66891 8.41892 8.66891H0.770269C0.632198 8.66891 0.520269 8.55698 0.520269 8.41891V0.770264C0.520269 0.632193 0.632198 0.520264 0.770269 0.520264H8.41892ZM9.16892 11.5811C9.16892 11.1669 8.83313 10.8311 8.41892 10.8311H0.770269C0.356056 10.8311 0.0202694 11.1669 0.0202694 11.5811V19.2297C0.0202694 19.6439 0.356056 19.9797 0.770269 19.9797H8.41892C8.83313 19.9797 9.16892 19.6439 9.16892 19.2297V11.5811ZM8.41892 11.3311C8.55699 11.3311 8.66892 11.443 8.66892 11.5811V19.2297C8.66892 19.3678 8.55699 19.4797 8.41892 19.4797H0.770269C0.632198 19.4797 0.520269 19.3678 0.520269 19.2297V11.5811C0.520269 11.443 0.632198 11.3311 0.770269 11.3311H8.41892ZM19.2297 10.8311C19.6439 10.8311 19.9797 11.1669 19.9797 11.5811V19.2297C19.9797 19.6439 19.6439 19.9797 19.2297 19.9797H11.5811C11.1669 19.9797 10.8311 19.6439 10.8311 19.2297V11.5811C10.8311 11.1669 11.1669 10.8311 11.5811 10.8311H19.2297ZM19.4797 11.5811C19.4797 11.443 19.3678 11.3311 19.2297 11.3311H11.5811C11.443 11.3311 11.3311 11.443 11.3311 11.5811V19.2297C11.3311 19.3678 11.443 19.4797 11.5811 19.4797H19.2297C19.3678 19.4797 19.4797 19.3678 19.4797 19.2297V11.5811ZM15.4054 0.0202637C15.2673 0.0202637 15.1554 0.132192 15.1554 0.270264V4.34459H11.0811C10.943 4.34459 10.8311 4.45652 10.8311 4.59459C10.8311 4.73266 10.943 4.84459 11.0811 4.84459H15.1554V8.91891C15.1554 9.05698 15.2673 9.16891 15.4054 9.16891C15.5435 9.16891 15.6554 9.05698 15.6554 8.91891V4.84459H19.7297C19.8678 4.84459 19.9797 4.73266 19.9797 4.59459C19.9797 4.45652 19.8678 4.34459 19.7297 4.34459H15.6554V0.270264C15.6554 0.132192 15.5435 0.0202637 15.4054 0.0202637Z"
   fill="black"
  />
 </svg>,
 "Addons"
);
