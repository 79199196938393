import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";

import ChangeDnsPtr from "components/addons/floaingIp/actions/ChangeDnsPtr";
import DeleteFloatingIp from "components/addons/floaingIp/actions/DeleteFloatingIp";
import UpdateFloatingIp from "components/addons/floaingIp/actions/UpateFloatingIp";
import IconSelector from "components/shared/images/IconSelector";

import { IFloatingIpDataset } from "types/api/addonsApiInterface";

const FloatingIpMenu = ({ rowData }: { rowData: IFloatingIpDataset }) => {
 const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
 const open = Boolean(anchorEl);

 const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
  setAnchorEl(null);
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <IconSelector icon="MenuIcon" />
   </Button>
   <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
     "aria-labelledby": "basic-button"
    }}>
    <Stack>
     <UpdateFloatingIp
      id={rowData.id}
      oldName={rowData.name}
      oldDescription={rowData.description || ""}
      closeMenu={handleClose}
     />
     <ChangeDnsPtr
      id={rowData.id}
      ip={rowData.ip}
      oldRdns={rowData.dns_ptr}
      closeMenu={handleClose}
     />
     {rowData.protection ? (
      <MenuItem disabled>
       <FormattedMessage id="addons.floatingIp.deleteFloatingIp" />
      </MenuItem>
     ) : (
      <DeleteFloatingIp id={rowData.id} closeMenu={handleClose} />
     )}
    </Stack>
   </Menu>
  </>
 );
};

export default FloatingIpMenu;
