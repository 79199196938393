import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { isNil } from "ramda";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { getPhpVersions, postInstallModule } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

const InstallModule = ({
 id,
 version,
 installed_modules
}: {
 id: number;
 version: string;
 installed_modules: Array<string>;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [selectedModule, setSelectedModule] = useState<string>("");
 const [modules, setModules] = useState<Array<string>>([]);

 const handleOpen = async () => {
  const versions = await dispatch(getPhpVersions());
  setModules(versions.find((element) => element.name === version)?.modules || []);
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postInstallModule(id, selectedModule));
  setLoading(false);
 };

 const checkData = (): boolean => {
  return selectedModule === "";
 };

 return (
  <>
   <Tooltip title={<FormattedMessage id="docker.website.installModule" />}>
    <IconButton onClick={() => handleOpen()}>
     <IconSelector icon="LibraryBooksIcon" />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.installModule" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading || checkData()}>
    <Stack spacing={2}>
     <Stack>
      <Typography fontWeight="bold">
       <FormattedMessage id="docker.website.installedModules" />
      </Typography>
      <Stack direction="row" spacing={1}>
       {installed_modules.length === 0 ? (
        <Typography variant="caption" fontStyle="italic">
         <FormattedMessage id="docker.website.noModuleFound" />
        </Typography>
       ) : (
        installed_modules.map((element, index) => {
         return <Chip key={`module-chip-${index}`} color="info" size="small" label={element} />;
        })
       )}
      </Stack>
     </Stack>
     <Stack>
      <Alert severity="info">
       <FormattedMessage id="docker.website.modulesInfo" />
      </Alert>
      <Autocomplete
       fullWidth={true}
       autoHighlight
       sx={{ my: 2 }}
       onChange={(e, value) => (isNil(value) ? setSelectedModule("") : setSelectedModule(value))}
       options={modules.filter((element) => !installed_modules.includes(element))}
       value={selectedModule}
       renderInput={(params) => (
        <TextField
         {...params}
         onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          e.stopPropagation();
         }}
         label={<FormattedMessage id="docker.website.selectModule" />}
         InputLabelProps={{ shrink: true }}
        />
       )}
      />
     </Stack>
    </Stack>
   </AppModal>
  </>
 );
};

export default InstallModule;
