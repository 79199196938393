import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: number = 0;

const MailboxReducer = createSlice({
 name: "mailboxId",
 initialState,
 reducers: {
  setMailbox: (state, { payload }: PayloadAction<number>) => {
   return payload;
  },
  resetMailbox: () => {
   return initialState;
  }
 }
});

export default MailboxReducer;
