import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import JobqueueReducer from "redux/reducers/jobqueueReducer";
import notificationReducer from "redux/reducers/notificationReducer";

import { ApiService } from "service/ApiService";

export const getAllAppJobqueue =
 (page: number, limit: number, q?: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService(
    `/isporder/allisporders?page=${page}&limit=${limit}&q=${q || ""}`
   );
   dispatch(JobqueueReducer.actions.setJobqueue(data || []));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load isp orders - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };
