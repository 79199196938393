import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postDeleteMailDomain } from "redux/handlers/mailsHandler";

import { useAppDispatch } from "hooks/reduxHook";

type ActionsType = {
 isp_id: number;
 maildomain: string;
 id: number;
};

const MailsMenu = ({ isp_id, maildomain, id }: ActionsType): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [section, setSection] = useState<string>("delete");
 const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
 const [loading, setLoading] = useState<boolean>(false);

 const open = Boolean(anchorEl);

 const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
  setAnchorEl(null);
 };

 const handleOpenModal = (selectedSection: string) => {
  setSection(selectedSection);
  setOpenModal(true);
 };
 const handleCloseModal = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  switch (section) {
   case "delete":
    await dispatch(postDeleteMailDomain(id, isp_id));
    break;
   default:
    console.log("default");
    break;
  }

  setLoading(false);
  handleCloseModal();
  handleClose();
 };

 const renderMessage = () => {
  switch (section) {
   case "delete":
    return "mails.delete";
   default:
    return "mails.delete";
  }
 };

 const RenderModal = () => {
  switch (section) {
   case "delete":
    return <FormattedMessage id="mails.deleteMessage" />;
   default:
    return <FormattedMessage id="mails.deleteMessage" />;
  }
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <IconSelector icon="MenuIcon" />
   </Button>
   <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
     "aria-labelledby": "basic-button"
    }}>
    <Stack>
     <MenuItem onClick={() => handleOpenModal("delete")}>
      <FormattedMessage id="mails.delete" />
     </MenuItem>
    </Stack>
   </Menu>
   <AppModal
    open={openModal}
    close={handleCloseModal}
    title={intl.formatMessage({ id: renderMessage() })}
    handleClose={handleCloseModal}
    disabled={loading}
    handleConfirm={handleConfirm}
    confirmColor="error">
    <RenderModal />
   </AppModal>
  </>
 );
};

export default MailsMenu;
