import React, { ReactElement } from "react";

import MenuItem from "@mui/material/MenuItem";

import { customerData } from "types/global/user";

import DeleteCustomer from "./actions/DeleteCustomer";
import UpdateCustomer from "./actions/UpdateCustomer";

const CustomersActions = ({
 id,
 customer
}: {
 id: number;
 customer?: customerData;
}): ReactElement => {
 const formattedCustomer = {
  clientType: customer?.contacttype || "",
  fiscalCode: customer?.fiscalcode || "",
  piva: customer?.vat || "",
  socialR: customer?.company || "",
  name: customer?.firstname || "",
  lastName: customer?.lastname || "",
  email: customer?.email || "",
  telephone: customer?.phonenumber || "",
  nation: customer?.countrycode || "",
  region: customer?.countrystate || "",
  city: customer?.city || "",
  zipCode: customer?.postalcode || "",
  street: customer?.street || "",
  bornNation: customer?.placeofbirth || ""
 };
 return (
  <>
   <MenuItem>
    <UpdateCustomer id={id} customerData={formattedCustomer} />
   </MenuItem>
   <MenuItem>
    <DeleteCustomer id={id} />
   </MenuItem>
  </>
 );
};

export default CustomersActions;
