import React, { ReactElement } from "react";

// import { NavLink } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import DomainBox from "./sections/DomainBox";
import ExpensesBox from "./sections/ExpensesBox";
import LocationsMapBox from "./sections/LocationsMapBox";
import NotificationBox from "./sections/NotificationBox";
import ProfileBox from "./sections/ProfileBox";
import ServerBox from "./sections/ServerBox";

const Dashboard = (): ReactElement => {
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 return (
  <Stack p={5} spacing={2}>
   <Grid container spacing={2}>
    <Grid direction="column" xs={desktopViewPort ? 9 : 12}>
     <Grid xs={desktopViewPort ? 12 : 12}>
      <ProfileBox />
     </Grid>
     {desktopViewPort && (
      <Grid xs={desktopViewPort ? 12 : 12}>
       <LocationsMapBox />
      </Grid>
     )}
    </Grid>
    <Grid direction="column" xs={desktopViewPort ? 3 : 12}>
     <Grid xs={desktopViewPort ? 2 : 12}>
      <ExpensesBox />
     </Grid>
     <Grid xs={desktopViewPort ? 3 : 12}>
      <NotificationBox />
     </Grid>
    </Grid>
   </Grid>
   <Grid container spacing={2}>
    <Grid xs={desktopViewPort ? 6 : 12}>
     <DomainBox />
    </Grid>
    <Grid xs={desktopViewPort ? 6 : 12}>
     <ServerBox />
    </Grid>
   </Grid>
  </Stack>
 );
};

export default Dashboard;
