export const moneyList = [
 { label: "25€", value: 25 },
 { label: "50€", value: 50 },
 { label: "100€", value: 100 },
 { label: "150€", value: 150 },
 { label: "200€", value: 200 },
 { label: "300€", value: 300 },
 { label: "500€", value: 500 },
 { label: "1000€", value: 1000 },
 { label: "1500€", value: 1500 },
 { label: "2000€", value: 2000 },
 { label: "3000€", value: 3000 }
];

export const paymentMethod = [
 { label: "recharge.creditCard", code: "card" },
 { label: "recharge.bankTransfer", code: "bank" }
];
