import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

import { IDockerOrdersData } from "types/api/dockerApiInterface";

export const jobqueueApi = (state: AppState) => state.Dockerorder;

export const getDockerOrderNumber = createSelector(jobqueueApi, (job) => {
 return job?.totalCount || 0;
});

export const getDockerOrderList = createSelector(jobqueueApi, (job) => {
 return job?.dataset.map((jobData: IDockerOrdersData) => ({
  action: jobData.phase,
  status: jobData.status,
  id: jobData.id,
  params: "",
  response: "",
  createdAt: ""
 }));
});
