import React, { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "react-use";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { serverTable } from "constants/tables";

import BaremetalPage from "pages/server/BaremetalPage";

import AdminWrapper from "components/shared/admin/AdminWrapper";
import SearchForPartner from "components/shared/search/SearchForPartner";
import SortingTable from "components/shared/tables/SortingTable";

import { getAllServers } from "redux/handlers/serverHandler";
import { postSyncronizeSocket } from "redux/handlers/socketHandler";
import { getUsersList } from "redux/handlers/userHandler";

import { getServerNumber, getServersTableList } from "redux/selectors/serversSelector";
import { getIsAdmin } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IUsersList } from "types/api/userApiInterface";

import ServersOrders from "./ServersOrders";

const ServersList = (): ReactElement => {
 const { socket } = useContext(AppContext);
 const navigate = useNavigate();
 const dispatch = useAppDispatch();

 const serversList = useSelector(getServersTableList);
 const serverNumber = useSelector(getServerNumber);
 const isAdmin = useSelector(getIsAdmin);

 const searchRef = useRef<HTMLInputElement>(null);
 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);
 const [searchValue, setSearchValue] = useState<string>("");
 const [contactsList, setContactsList] = useState<Array<IUsersList>>([]);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [performanceData, setPerformanceData] = useState<
  Array<{ token: string; cpu: number; ram: number; disk: number }>
 >([{ token: "", cpu: 0, ram: 0, disk: 0 }]);
 const [selectedPartner, setSelectedPartner] = useState<string>("");

 useEffect(() => {
  (async () => {
   setCurrentPage(0);
   isAdmin && setContactsList(await dispatch(getUsersList()));
   setIsLoading(false);
   await dispatch(postSyncronizeSocket("server"));
  })();
 }, []);

 useEffect(() => {
  if (!isNil(socket)) {
   socket.on("server_logs", (value): void => {
    if (value?.length > 0) {
     let tempArr = [];
     for (let i = 0; i < value.length; i++) {
      tempArr.push({
       token: value[i]?.token,
       cpu: parseInt(value[i]?.cpu),
       ram: parseInt(value[i]?.ram),
       disk: parseInt(value[i]?.disk)
      });
     }
     setPerformanceData(tempArr);
    }
    setIsLoading(false);
   });
  }
  return () => {
   !isNil(socket) && socket.off("server_logs");
  };
 }, [socket]);

 useDebounce(
  () => {
   if (!isLoading) {
    setCurrentPage(0);
    dispatch(getAllServers(currentPage, rowPerPage, searchRef?.current?.value, selectedPartner));
   }
  },
  1000,
  [searchValue]
 );

 const handleOnRowsPerPageChange = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const newRowsPerPage = parseInt(event.target.value, 10);
  setRowPerPage(newRowsPerPage);
  setCurrentPage(0);
  dispatch(getAllServers(0, newRowsPerPage, searchRef?.current?.value, selectedPartner));
 };

 const handleOnPageChange = (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  const startIndex = newPage * rowPerPage;
  setCurrentPage(newPage);
  dispatch(getAllServers(startIndex, rowPerPage, searchRef?.current?.value, selectedPartner));
 };

 const handleSearchPartner = async (partnerIdValue: string) => {
  setCurrentPage(0);
  setSelectedPartner(partnerIdValue);
  await dispatch(
   getAllServers(currentPage, rowPerPage, searchRef.current?.value || "", partnerIdValue)
  );
 };

 return (
  <>
   <ServersOrders />
   <Stack mt={5}>
    <Stack justifyContent="space-between" alignItems="flex-end" direction="row" pb={1}>
     <Typography fontWeight="bold" fontSize={16} textTransform="uppercase">
      <FormattedMessage id="app.servers" />
      {` (${serverNumber})`}
     </Typography>
     <Button
      variant="kxActionButton"
      endIcon={<AddIcon />}
      onClick={() => navigate("/servers/add")}>
      <FormattedMessage id="server.action.add" />
     </Button>
    </Stack>
    <Paper elevation={0} sx={{ borderRadius: "10px", boxShadow: 0 }}>
     <Stack justifyContent="flex-start" alignItems="center" p={2} direction="row">
      <TextField
       size="small"
       autoComplete="off"
       inputRef={searchRef}
       label={<FormattedMessage id="search.servers" />}
       InputLabelProps={{ shrink: true }}
       InputProps={{ startAdornment: <SearchIcon color="disabled" /> }}
       onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
      />
      <AdminWrapper sx={{ ml: 2 }}>
       <SearchForPartner
        contactList={contactsList}
        handleSearchPartner={(partner) => handleSearchPartner(partner)}
       />
      </AdminWrapper>
     </Stack>
     <Divider sx={{ borderColor: "#EF711A" }} />
     <Stack>
      <SortingTable
       tableData={serverTable}
       maxWidth={650}
       caller="server"
       listToShow={serversList}
       performance={performanceData}
      />
      <TablePagination
       component="div"
       count={serverNumber || 0}
       page={currentPage}
       rowsPerPage={rowPerPage}
       onPageChange={handleOnPageChange}
       onRowsPerPageChange={handleOnRowsPerPageChange}
      />
     </Stack>
    </Paper>
   </Stack>
   <BaremetalPage />
  </>
 );
};

export default ServersList;
