import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

import { IHostDataset } from "types/api/hostApiInterface";

export const getHost = (state: AppState) => state.host;

export const getHostNumber = createSelector(getHost, (host) => {
 return host?.totalCount;
});

export const getHostDataSet = createSelector(getHost, (host) => {
 return (
  host?.dataset?.map((host: IHostDataset) => {
   return {
    id: host.id,
    userid: host.userid || null,
    name: host.name || null,
    type: host.type || null,
    ip: host.ip1 || null,
    public: host.public || null
   };
  }) || []
 );
});
