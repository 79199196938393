import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";

import AppModal from "components/shared/modal/AppModal";

import {
 postChangeFloatingIpProtection,
 postChangeLoadBalancerProtection,
 postChangeNetworkProtection,
 postChangeSnapshotProtection,
 postChangeVolumeProtection
} from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

const ChangeProtection = ({
 id,
 protection,
 type
}: {
 id: number;
 protection: boolean;
 type: string;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);

 const handleClose = () => setOpenModal(false);

 const handleChangeProtection = async (id: number, protection: boolean) => {
  setLoading(true);
  switch (type) {
   case "volumes":
    await dispatch(postChangeVolumeProtection(id));
    break;
   case "snapshot":
    await dispatch(postChangeSnapshotProtection(id));
    break;
   case "networks":
    await dispatch(postChangeNetworkProtection(id));
    break;
   case "loadBalancer":
    await dispatch(postChangeLoadBalancerProtection(id));
    break;
   default:
    await dispatch(postChangeFloatingIpProtection(id));
    break;
  }
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Tooltip
    title={
     protection
      ? intl.formatMessage({ id: "addons.disableProtection" })
      : intl.formatMessage({ id: "addons.enableProtection" })
    }>
    <Chip
     icon={protection ? <LockOutlinedIcon /> : <LockOpenOutlinedIcon />}
     sx={{ cursor: "pointer" }}
     color={protection ? "success" : "error"}
     size="small"
     label={
      protection
       ? intl.formatMessage({ id: "addons.enabled" })
       : intl.formatMessage({ id: "addons.disabled" })
     }
     onClick={() => handleOpen()}
    />
   </Tooltip>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "addons.changeProtection" })}
    handleClose={handleClose}
    disabled={loading}
    handleConfirm={() => handleChangeProtection(id, !protection)}>
    {protection ? (
     <FormattedMessage id="addons.disableProtMessage" />
    ) : (
     <FormattedMessage id="addons.enableProtMessage" />
    )}
   </AppModal>
  </>
 );
};

export default ChangeProtection;
