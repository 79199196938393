import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import Typography from "@mui/material/Typography";

import RenderPerformance from "components/shared/progressBar/RenderPerformance";

import { getAllServices } from "redux/selectors/serversSelector";

const ServiceCpu = ({ service }: { service: string }): ReactElement => {
 const services = useSelector(getAllServices);

 const [cpu, setCpu] = useState<number>(0);

 useEffect(() => {
  switch (service) {
   case "apache2":
    setCpu(services?.cpu_apache || 0);
    break;
   case "nginx":
    setCpu(services?.cpu_nginx || 0);
    break;
   case "mysql":
    setCpu(services?.cpu_mysql || 0);
    break;
   case "php":
    setCpu(services?.cpu_phpengine || 0);
    break;
   default:
    break;
  }
 }, [services]);

 return service === "apache2" || service === "nginx" || service === "mysql" || service === "php" ? (
  <RenderPerformance value={cpu} label="server.cpu" width="80%" justify="flex-start" />
 ) : (
  <Typography fontStyle="italic" variant="caption">
   <FormattedMessage id="server.services.cpuNotAvailable" />
  </Typography>
 );
};

export default ServiceCpu;
