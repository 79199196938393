import React, { ReactElement } from "react";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";

import IconSelector from "components/shared/images/IconSelector";

import { Record } from "types/api/appsApiInterface";

import ZoneAction from "./ZoneAction";

type ActionsType = {
 zone_id: number;
 isp_id: number;
 data: Record[];
 name: string;
};

const ZoneMenu = ({ zone_id, data, isp_id, name }: ActionsType): ReactElement => {
 const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
 const open = Boolean(anchorEl);

 const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
  setAnchorEl(null);
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <IconSelector icon="MenuIcon" />
   </Button>
   <Menu
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
     "aria-labelledby": "action-button"
    }}>
    <Stack>
     <ZoneAction zoneId={zone_id} data={data} ispId={isp_id} name={name} />
    </Stack>
   </Menu>
  </>
 );
};

export default ZoneMenu;
