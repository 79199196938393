import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { isNil } from "ramda";

import AddIcon from "@mui/icons-material/Add";

import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import AppModal from "components/shared/modal/AppModal";

import { postAttachNetwork } from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

import { IUserNetwork } from "types/api/addonsApiInterface";

const AttachNetwork = ({ id, networkList }: { id: number; networkList: Array<IUserNetwork> }) => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [selectedNetwork, setSelectedNetwork] = useState<number>(0);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postAttachNetwork(id, selectedNetwork));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Button onClick={handleOpen} variant="kxActionButton" endIcon={<AddIcon />}>
    <FormattedMessage id="addons.loadBalancer.attachNetwork" />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "addons.loadBalancer.attachNetwork" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading || selectedNetwork === 0}>
    <Autocomplete
     autoHighlight
     onChange={(e, value) => (isNil(value) ? setSelectedNetwork(0) : setSelectedNetwork(value.id))}
     options={networkList.map((element) => {
      return {
       label: element.name,
       id: element.network_id
      };
     })}
     renderInput={(params) => (
      <TextField
       {...params}
       label={intl.formatMessage({ id: "addons.network" })}
       InputLabelProps={{ shrink: true }}
      />
     )}
    />
   </AppModal>
  </>
 );
};

export default AttachNetwork;
