import { EServerRoles } from "types/redux/serverInterface";

export const servicesList: {
 name: string;
 product?: EServerRoles;
 serverKey: string;
 phpEngine?: "apache2" | "nginX";
 operation: Array<string>;
 os: string;
}[] = [
 {
  name: "SSH",
  serverKey: "ssh",
  operation: ["reboot", "permit"],
  os: "linux"
 },
 {
  name: "Postfix",
  product: EServerRoles.MAIL,
  serverKey: "postfix",
  operation: ["reboot", "logs", "config"],
  os: "linux"
 },
 {
  name: "Dovecot",
  product: EServerRoles.MAIL,
  serverKey: "dovecot",
  operation: ["reboot", "logs"],
  os: "linux"
 },
 {
  name: "Webmail",
  product: EServerRoles.MAIL,
  serverKey: "webmailcheck",
  operation: ["link", "rectify"],
  os: "linux"
 },
 {
  name: "PolicyD",
  product: EServerRoles.MAIL,
  serverKey: "policyd",
  operation: ["config"],
  os: "linux"
 },
 {
  name: "Apache2",
  product: EServerRoles.WEB,
  serverKey: "apache2check",
  phpEngine: "apache2",
  operation: ["reboot", "logs"],
  os: "linux"
 },
 {
  name: "NginX",
  product: EServerRoles.WEB,
  serverKey: "nginxcheck",
  phpEngine: "nginX",
  operation: ["reboot", "logs"],
  os: "linux"
 },
 {
  name: "MySQL",
  product: EServerRoles.WEB,
  serverKey: "sql",
  operation: ["reboot", "logs", "repair"],
  os: "linux"
 },
 {
  name: "Php Engine",
  product: EServerRoles.WEB,
  serverKey: "php",
  operation: ["config"],
  os: "linux"
 },
 {
  name: "PureFTP",
  product: EServerRoles.WEB,
  serverKey: "ftp",
  operation: ["logs"],
  os: "linux"
 },
 {
  name: "Memcache",
  product: EServerRoles.WEB,
  serverKey: "memcache",
  operation: [],
  os: "linux"
 },
 {
  name: "Redis-server",
  product: EServerRoles.WEB,
  serverKey: "redis",
  operation: ["logs"],
  os: "linux"
 },
 {
  name: "RDP",
  serverKey: "rdp",
  operation: ["reboot"],
  os: "windows"
 },
 {
  name: "IIS",
  serverKey: "iis",
  operation: ["reboot"],
  os: "windows"
 },
 {
  name: "SQL Server",
  serverKey: "sql",
  operation: ["reboot", "logs"],
  os: "windows"
 }
];

export const ListOfServices: Array<{
 name: string;
 operation: Array<string>;
 os: string;
 dataToPass: string;
}> = [
 {
  name: "SSH",
  operation: ["reboot", "permit"],
  os: "linux",
  dataToPass: "ssh"
 },
 {
  name: "Postfix",
  dataToPass: "postfix",
  operation: ["reboot", "logs"],
  os: "linux"
 },
 {
  name: "Dovecot",
  dataToPass: "dovecot",
  operation: ["reboot", "logs"],
  os: "linux"
 },
 {
  name: "Apache2",
  dataToPass: "apache2",
  operation: ["reboot", "logs"],
  os: "linux"
 },
 {
  name: "NginX",
  dataToPass: "nginx",
  operation: ["reboot", "logs"],
  os: "linux"
 },
 {
  name: "MySQL",
  dataToPass: "mysql",
  operation: ["reboot", "logs", "repair"],
  os: "linux"
 },
 {
  name: "Php Engine",
  dataToPass: "php",
  operation: ["phpConfig"],
  os: "linux"
 },
 {
  name: "PureFTP",
  dataToPass: "ftp",
  operation: ["logs"],
  os: "linux"
 },
 {
  name: "Memcache",
  dataToPass: "memcache",
  operation: [],
  os: "linux"
 },
 {
  name: "Redis-server",
  dataToPass: "redis",
  operation: ["logs"],
  os: "linux"
 },
 {
  name: "RDP",
  dataToPass: "rdp",
  operation: ["reboot"],
  os: "windows"
 },
 {
  name: "IIS",
  dataToPass: "iis",
  operation: ["reboot"],
  os: "windows"
 },
 {
  name: "SQL Server",
  dataToPass: "sql",
  operation: ["reboot", "logs"],
  os: "windows"
 }
];

export const defaultServiceDataType = {
 cpu_apache: 0,
 apache_ver: "",
 log_apache: "",
 ser_sshen: "",
 ser_post: "",
 log_post: "",
 ser_dove: "",
 cpu_nginx: 0,
 nginx_ver: "",
 cpu_mysql: 0,
 sql_ver: "",
 log_mysql: "",
 cpu_phpengine: 0,
 php_ver: "",
 ser_ftp: "",
 log_ftp: "",
 ser_memc: "",
 ser_redi: "",
 log_redi: "",
 lastCreatedAt: ""
};
