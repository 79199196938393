import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { websiteContainerCronjobSteps } from "constants/server";

const CronFile = ({
 activeStep,
 firstValue,
 handleNext,
 handleBack,
 handleReset,
 setPropsValue
}: {
 activeStep: number;
 firstValue: string;
 handleNext: () => void;
 handleBack: () => void;
 handleReset: () => void;
 setPropsValue: (value: string) => void;
}): ReactElement => {
 const intl = useIntl();

 const [file, setFile] = useState<string>(firstValue);

 const goNext = () => {
  setPropsValue(file);
  handleNext();
 };

 return (
  <Stack p={2} spacing={2}>
   <TextField
    autoComplete="off"
    value={file}
    autoFocus
    label={intl.formatMessage({ id: "docker.website.cronjobFile" })}
    InputLabelProps={{ shrink: true }}
    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
     e.stopPropagation();
    }}
    onChange={({ currentTarget }) => setFile(currentTarget.value)}
    helperText={"es: /var/www/html/cron.php, /var/www/html/cron.sh"}
   />
   <Stack direction="row" spacing={2} justifyContent="center">
    <Button disabled={activeStep === 0} onClick={handleBack}>
     <FormattedMessage id="app.back" />
    </Button>
    <Button onClick={handleReset}>Reset</Button>
    <Button
     disabled={activeStep === websiteContainerCronjobSteps.length - 1 || file === ""}
     onClick={goNext}>
     <FormattedMessage id="app.next" />
    </Button>
   </Stack>
  </Stack>
 );
};

export default CronFile;
