import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { IJobsList } from "types/api/hetznerJobs";

const initialState: IJobsList = { dataset: [], totalCount: 0 };

const serverJobQueueReducer = createSlice({
 name: "serversJobqueue",
 initialState,
 reducers: {
  setServerJobs: (state, { payload }: PayloadAction<IJobsList>) => {
   return payload;
  },
  resetServerJobs: () => {
   return initialState;
  }
 }
});

export default serverJobQueueReducer;
