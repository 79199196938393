import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postAddTransport } from "redux/handlers/gatewayHandler";

import { useAppDispatch } from "hooks/reduxHook";

const AddTransport = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [transportName, setTransportName] = useState<string>("");
 const [hostName, setHostName] = useState<string>("");
 const [port, setPort] = useState<number>(25);
 const [protocol, setProtocol] = useState<string>("SMTP");
 const [mx, setMx] = useState<boolean>(false);

 const handleOpen = () => {
  setTransportName("");
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setIsLoading(true);
  await dispatch(postAddTransport(id, transportName, hostName, protocol, port, mx));
  handleClose();
  setIsLoading(false);
 };

 const disableCheck = (): boolean => {
  return transportName === "" || protocol === "" || port <= 0 || hostName === "";
 };

 return (
  <Stack direction="row">
   <Button onClick={handleOpen} variant="kxActionButton">
    <FormattedMessage id="gateway.addTransport" />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    disabled={disableCheck()}
    handleConfirm={handleConfirm}
    title={intl.formatMessage({ id: "gateway.addTransport" })}>
    <Stack spacing={2}>
     <Alert severity="info">
      <Typography>
       <FormattedMessage id="gateway.addTransport.domainMessage" />
      </Typography>
     </Alert>
     <TextField
      autoComplete="off"
      label={intl.formatMessage({ id: "gateway.addTransport.domainName" })}
      InputLabelProps={{ shrink: true }}
      onChange={({ currentTarget }) => setTransportName(currentTarget.value)}
     />
     <TextField
      autoComplete="off"
      label={intl.formatMessage({ id: "gateway.addTransport.host" })}
      InputLabelProps={{ shrink: true }}
      onChange={({ currentTarget }) => setHostName(currentTarget.value)}
     />
     <Stack direction="row" spacing={2}>
      <FormControl fullWidth>
       <InputLabel>
        <FormattedMessage id="gateway.addTransport.protocol" />
       </InputLabel>
       <Select
        value={protocol || ""}
        label={<FormattedMessage id="gateway.addTransport.protocol" />}
        onChange={(e) => setProtocol(e.target.value)}>
        <MenuItem key="protocol-smtp" value={"SMTP"}>
         SMTP
        </MenuItem>
        <MenuItem key="protocol-lmtp" value={"LMTP"}>
         LMTP
        </MenuItem>
       </Select>
      </FormControl>
     </Stack>
     <TextField
      autoComplete="off"
      label={intl.formatMessage({ id: "gateway.addTransport.port" })}
      InputLabelProps={{ shrink: true }}
      type="number"
      onChange={({ currentTarget }) => setPort(Number(currentTarget.value))}
     />
     <FormControlLabel
      control={
       <Checkbox
        checked={mx}
        onChange={(e) => {
         setMx(e.target.checked);
        }}
       />
      }
      label={intl.formatMessage({ id: "gateway.addTransport.mx" })}
     />
    </Stack>
   </AppModal>
  </Stack>
 );
};

export default AddTransport;
