import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import ReplayIcon from "@mui/icons-material/Replay";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import AppModal from "components/shared/modal/AppModal";

import { postResetServer } from "redux/handlers/serverHandler";

import { useAppDispatch } from "hooks/reduxHook";

const RebootServer = ({
 id,
 service_status
}: {
 id: number;
 service_status: string;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postResetServer(id));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Tooltip title={intl.formatMessage({ id: "server.rebootTooltip" })}>
    <IconButton disabled={service_status === "expired"} onClick={handleOpen}>
     <ReplayIcon fontSize="large" color={service_status === "expired" ? "disabled" : "success"} />
    </IconButton>
   </Tooltip>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    disabled={loading}
    handleConfirm={handleConfirm}
    title={intl.formatMessage({ id: "server.rebootServer" })}>
    <FormattedMessage id={"server.rebootServerMessage"} />
   </AppModal>
  </>
 );
};

export default RebootServer;
