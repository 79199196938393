import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import { isNil } from "ramda";

import HistoryIcon from "@mui/icons-material/History";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import { backupCopies, backupIntervals } from "constants/sites";

import AppModal from "components/shared/modal/AppModal";

import { postUpdateBackup } from "redux/handlers/websitesHandler";

import { useAppDispatch } from "hooks/reduxHook";

const UpdateBackupRetention = ({
 domain_id,
 isp_id,
 interval,
 copy
}: {
 domain_id: number;
 isp_id: number;
 interval: string;
 copy: string;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const onSubmit: SubmitHandler<{ backup_interval: string; backup_copy: string }> = async (data: {
  backup_interval: string;
  backup_copy: string;
 }) => {
  setLoading(true);
  await dispatch(
   postUpdateBackup(domain_id, isp_id, data.backup_interval, Number(data.backup_copy))
  );
  setLoading(false);
  handleClose();
 };

 const { control, formState, handleSubmit, setValue, getValues } = useForm({
  defaultValues: {
   backup_interval: interval,
   backup_copy: copy
  }
 });

 return (
  <>
   <Button variant="kxActionButton" endIcon={<HistoryIcon />} onClick={handleOpen}>
    <FormattedMessage id="sites.backups.updateRetention" />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "sites.backups.updateRetention" })}
    handleClose={handleClose}
    disabled={loading}
    handleConfirm={handleSubmit(onSubmit)}>
    <form onSubmit={handleSubmit(onSubmit)}>
     <Alert severity="info">
      <FormattedMessage id="sites.backups.updateRetentionMessage" />
     </Alert>
     <Controller
      name="backup_interval"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        defaultValue={getValues("backup_interval")}
        onChange={(e, value) =>
         isNil(value) ? setValue("backup_interval", "") : setValue("backup_interval", value)
        }
        options={backupIntervals}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          label={intl.formatMessage({ id: "sites.backups.interval" })}
          error={formState.isDirty && !!formState?.errors?.backup_interval}
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
      )}
     />
     <Controller
      name="backup_copy"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        defaultValue={getValues("backup_copy")}
        onChange={(e, value) =>
         isNil(value) ? setValue("backup_copy", "0") : setValue("backup_copy", value)
        }
        options={backupCopies}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          label={intl.formatMessage({ id: "sites.backups.copy" })}
          error={formState.isDirty && !!formState?.errors?.backup_copy}
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
      )}
     />
    </form>
   </AppModal>
  </>
 );
};

export default UpdateBackupRetention;
