import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import AppModal from "components/shared/modal/AppModal";

import { postCheckVolumeName, postUpdateVolumeName } from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

const ChangeVolumeName = ({
 id,
 oldName,
 closeMenu
}: {
 id: number;
 oldName: string;
 closeMenu: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [nameAvailable, setNameAvailable] = useState<boolean>(false);
 const [firstCheck, setFirstCheck] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpenModal = () => {
  reset();
  setOpenModal(true);
 };
 const handleCloseModal = () => setOpenModal(false);

 const { control, reset, handleSubmit, formState, getValues } = useForm({
  defaultValues: {
   name: oldName
  }
 });

 const onSubmit: SubmitHandler<{ name: string }> = async (data: { name: string }) => {
  setLoading(true);
  await dispatch(postUpdateVolumeName(id, data.name));
  handleCloseModal();
  setLoading(false);
  closeMenu();
 };

 const handleCheckName = async () => {
  setNameAvailable(await dispatch(postCheckVolumeName(getValues("name"))));
  setFirstCheck(true);
 };

 return (
  <>
   <MenuItem onClick={() => handleOpenModal()}>
    <FormattedMessage id="volumes.changeName" />
   </MenuItem>
   <AppModal
    open={openModal}
    close={handleCloseModal}
    title={intl.formatMessage({ id: "volumes.changeName" })}
    handleClose={handleCloseModal}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={loading || !nameAvailable}>
    <Stack spacing={2}>
     <Stack direction="row" spacing={1}>
      <Controller
       name="name"
       control={control}
       rules={{ required: true }}
       render={({ field }) => (
        <TextField
         {...field}
         sx={{ my: 2, width: "70%" }}
         onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          e.stopPropagation();
         }}
         label={intl.formatMessage({ id: "volumes.name" })}
         InputLabelProps={{ shrink: true }}
         error={formState.isDirty && !!formState?.errors?.name}
         helperText={
          formState.isDirty &&
          !!formState?.errors?.name &&
          intl.formatMessage({ id: "volumes.invalidName" })
         }
        />
       )}
      />
      <Button variant="kxActionButton" onClick={handleCheckName}>
       <FormattedMessage id="volumes.checkName" />
      </Button>
     </Stack>
     {firstCheck && !nameAvailable && (
      <Alert severity="error">
       <FormattedMessage id="volumes.nameDuplicated" />
      </Alert>
     )}
    </Stack>
   </AppModal>
  </>
 );
};

export default ChangeVolumeName;
