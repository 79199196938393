import React, { ReactElement, useEffect, useState } from "react";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

import Customers from "components/profile/customers/Customers";
import SkeletonProfile from "components/shared/skeletons/SkeletonProfile";

import { getAllCustomers } from "redux/handlers/customersHandler";

import { useAppDispatch } from "hooks/reduxHook";

const CustomersPage = (): ReactElement => {
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const dispatch = useAppDispatch();

 useEffect(() => {
  (async () => {
   await dispatch(getAllCustomers(0, 10));
   setIsLoading(false);
  })();
 }, []);

 return (
  <Container maxWidth="xl">
   <Stack>{isLoading ? <SkeletonProfile id="app.customers" /> : <Customers />}</Stack>
  </Container>
 );
};

export default CustomersPage;
