import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { formatCountryCode } from "helpers/server";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { ILocations } from "types/api/serversApiInterface";

const CountryBox = ({
 selectedLocation,
 locationList,
 selectLocation
}: {
 selectedLocation: number;
 locationList: Array<ILocations>;
 selectLocation: (location: number) => void;
}): ReactElement => {
 return (
  <Stack direction="row" flexWrap="wrap">
   {locationList.map((element, index) => {
    return selectedLocation === element.id ? (
     <Paper
      sx={{
       backgroundColor: "#c8e6c9",
       px: 4,
       py: 2,
       m: 1
      }}
      onClick={() => selectLocation(element.id)}>
      <Stack direction="row" spacing={1}>
       <Stack component="span" className={`fi fi-${formatCountryCode(element.country)}`} />
       <Stack direction="row" justifyContent="center" alignItems="center">
        <Typography fontWeight="bold" textTransform="uppercase">
         {element.city}
        </Typography>
       </Stack>
      </Stack>
     </Paper>
    ) : (
     <Tooltip
      key={`country-paper-${index}`}
      title={<FormattedMessage id="server.add.selectCountryTitle" />}>
      <Paper
       sx={{
        px: 4,
        py: 2,
        cursor: "pointer",
        m: 1
       }}
       onClick={() => selectLocation(element.id)}>
       <Stack direction="row" spacing={1}>
        <Stack component="span" className={`fi fi-${formatCountryCode(element.country)}`} />
        <Stack direction="row" justifyContent="center" alignItems="center">
         <Typography fontWeight="bold" textTransform="uppercase">
          {element.city}
         </Typography>
        </Stack>
       </Stack>
      </Paper>
     </Tooltip>
    );
   })}
  </Stack>
 );
};

export default CountryBox;
