import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { getUserIdString } from "redux/selectors/userSelector";

const UserNotRegistered = (): ReactElement => {
 const userId = useSelector(getUserIdString);

 return (
  <Stack justifyContent="center" alignItems="center" p={3} py={4} spacing={2}>
   <Typography>
    <FormattedMessage
     id="chat.chatNotRegistered"
     values={{
      link: (
       <a href={`https://t.me/Konsolex_Bot?start=${userId}`} target="_blank" rel="noreferrer">
        <FormattedMessage id="chat.chatLink" />
       </a>
      )
     }}
    />
   </Typography>
   <QRCode value={`https://t.me/Konsolex_Bot?start=${userId}`} />
  </Stack>
 );
};

export default UserNotRegistered;
