import React, { ChangeEvent, ReactElement, useState } from "react";
import { useIntl } from "react-intl";
import { getCountryDataList, getEmojiFlag } from "countries-list";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { phoneValidation } from "constants/regexp";

const PhoneInput = ({
 defaultValue,
 placeholder,
 size,
 onChange
}: {
 defaultValue?: string;
 placeholder?: string;
 size?: "small" | "medium";
 onChange?: (value: string) => void;
}): ReactElement => {
 const intl = useIntl();
 const allCountry = getCountryDataList();

 const [selectedPrefix, setSelectedPrefix] = useState<string>("39");
 const [phone, setPhone] = useState<string>(defaultValue || "+39.");
 const [firstChange, setFirstChange] = useState<boolean>(false);

 const handleChangePhone = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  if (phoneValidation.test(e.target.value)) {
   setPhone(e.target.value);
   onChange && onChange(e.target.value);
   if (!firstChange) {
    setFirstChange(true);
   }
  }
 };

 return (
  <Stack direction={"row"} width="100%">
   <FormControl size={size ? size : "medium"}>
    <Select
     value={selectedPrefix}
     sx={{ maxHeight: "56px" }}
     onChange={(e) => {
      setSelectedPrefix(e.target.value);
      setPhone("+" + e.target.value + ".");
     }}>
     {allCountry.map((country, key) => {
      return (
       <MenuItem key={`phone-list-${key}`} value={country.phone[0]}>
        {`${getEmojiFlag(country.iso2)} ${country.name} +${country.phone[0]}`}
       </MenuItem>
      );
     })}
    </Select>
   </FormControl>
   <TextField
    fullWidth
    value={phone}
    size={size ? size : "medium"}
    error={phone.length <= selectedPrefix.length + 2 && firstChange}
    helperText={
     phone.length <= selectedPrefix.length + 2 &&
     firstChange &&
     intl.formatMessage({ id: "profile.phoneVoidMessage" })
    }
    onChange={(e) => handleChangePhone(e)}
    label={placeholder || ""}></TextField>
  </Stack>
 );
};

export default PhoneInput;
