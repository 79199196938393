export const phpType = ["Fast-CGI", "PHP-FPM"];
export const phpVersion = ["5.6", "7.0", "7.1", "7.2", "7.3", "7.4", "8.0", "8.1"];
export const phpPostMax = ["1", "8", "10", "100", "500", "1000", "2000"];
export const phpMaxVars = ["100", "200", "1000", "2000", "20000", "50000"];
export const phpMaxExec = ["30", "50", "500", "1000", "10000"];
export const phpMemoryLimit = ["128", "512", "1024", "2048", "4096"];
export const phpFileSize = ["1", "2", "10", "100", "500", "1000", "2000"];
export const phpTimezone = [
 "Rome",
 "Amsterdam",
 "Belgrado",
 "Bucharest",
 "Copenhagen",
 "Helsinki",
 "Kaliningrad",
 "Ljubljana",
 "Malta",
 "Moscow",
 "Prague",
 "San Marino",
 "Skopje",
 "Tirane",
 "Vatican",
 "Warsaw",
 "Andorra",
 "Berlin",
 "Budapest",
 "Dublin",
 "Isle of Man",
 "Kiev",
 "London",
 "Mariehamn",
 "Oslo",
 "Riga",
 "Sarajevo",
 "Sofia",
 "Ulyanovsk",
 "Vienna",
 "Zagreb",
 "Astrakhan",
 "Bratislava",
 "Busingen",
 "Gibraltar",
 "Istanbul",
 "Kirov",
 "Luxembourg",
 "Minsk",
 "Paris",
 "Saratov",
 "Stockholm",
 "Uzhgorod",
 "Vilnius",
 "Zaporozhye",
 "Athens",
 "Brussels",
 "Chisinau",
 "Guernsey",
 "Jersey",
 "Lisbon",
 "Madrid",
 "Monaco",
 "Podgorica",
 "Samara",
 "Simferopol",
 "Tallinn",
 "Vaduz",
 "Volgograd",
 "Zurich"
];
