import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postDisableBackup } from "redux/handlers/backupsHandler";

import { useAppDispatch } from "hooks/reduxHook";

const DisableBackup = ({ id }: { id: number }) => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postDisableBackup(id));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Button variant="kxActionButton" onClick={handleOpen}>
    <Typography>
     <FormattedMessage id={"server.backups.disableBackup"} />
    </Typography>
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "server.backups.disableBackup" })}
    handleClose={handleClose}
    disabled={loading}
    handleConfirm={handleConfirm}>
    <FormattedMessage id={"server.backups.disableModalMessage"} />
   </AppModal>
  </>
 );
};

export default DisableBackup;
