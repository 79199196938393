import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const LockedIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <path
   d="M13 8.1875V5.375C13 4.47989 12.6444 3.62145 12.0115 2.98851C11.3785 2.35558 10.5201 2 9.625 2C8.72989 2 7.87145 2.35558 7.23851 2.98851C6.60558 3.62145 6.25 4.47989 6.25 5.375V8.1875M5.6875 16.625H13.5625C14.0101 16.625 14.4393 16.4472 14.7557 16.1307C15.0722 15.8143 15.25 15.3851 15.25 14.9375V9.875C15.25 9.42745 15.0722 8.99823 14.7557 8.68176C14.4393 8.36529 14.0101 8.1875 13.5625 8.1875H5.6875C5.23995 8.1875 4.81073 8.36529 4.49426 8.68176C4.17779 8.99823 4 9.42745 4 9.875V14.9375C4 15.3851 4.17779 15.8143 4.49426 16.1307C4.81073 16.4472 5.23995 16.625 5.6875 16.625Z"
   stroke="currentColor"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
 </svg>,
 "Locked"
);
