import React, { ReactElement, useEffect, useState } from "react";

import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";

import RenderPerformance from "components/shared/progressBar/RenderPerformance";

type Performance = {
 cpu: number;
 disk: number;
 ram: number;
};

const ServerPerformance = ({
 agentoken,
 performance
}: {
 agentoken: string;
 performance: Array<{ token: string; cpu: number; ram: number; disk: number }>;
}): ReactElement => {
 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [performanceData, setPerformanceData] = useState<Performance>({ cpu: 0, disk: 0, ram: 0 });

 useEffect(() => {
  setPerformanceData({
   cpu: performance.find((element) => element.token === agentoken)?.cpu || 0,
   ram: performance.find((element) => element.token === agentoken)?.ram || 0,
   disk: performance.find((element) => element.token === agentoken)?.disk || 0
  });
  setIsLoading(false);
 }, [performance, agentoken]);

 return isLoading ? (
  <Stack direction="row" alignItems="center" justifyContent="space-around">
   <LinearProgress color="info" sx={{ width: "50%" }} />
  </Stack>
 ) : (
  <>
   <RenderPerformance value={performanceData.cpu} label="server.cpu" />
   <RenderPerformance value={performanceData.ram} label="server.ram" />
   <RenderPerformance value={performanceData.disk} label="server.nvme" />
  </>
 );
};

export default ServerPerformance;
