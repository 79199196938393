import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";

import AppModal from "components/shared/modal/AppModal";

import { postRebootAgent } from "redux/handlers/serverHandler";

import { useAppDispatch } from "hooks/reduxHook";

const AgentReboot = ({ id }: { id: number }) => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postRebootAgent(id));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Button variant="kxActionButton" onClick={handleOpen}>
    <FormattedMessage id="agent.rebootAgent" />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    disabled={loading}
    handleConfirm={handleConfirm}
    title={intl.formatMessage({ id: "agent.rebootAgent" })}>
    <FormattedMessage id="agent.rebootMessage" />
   </AppModal>
  </>
 );
};

export default AgentReboot;
