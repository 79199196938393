type nameType =
 | "description"
 | "username"
 | "passwordApi"
 | "passwordIsp"
 | "passwordSql"
 | "passwordServer"
 | "soapLocation"
 | "soapUrl";

export const ispFields: Array<{ name: nameType; label: string; required: boolean }> = [
 {
  name: "description",
  label: "configurator.isp.description",
  required: true
 },
 {
  name: "username",
  label: "configurator.isp.username",
  required: true
 },
 {
  name: "passwordApi",
  label: "configurator.isp.passwordApi",
  required: true
 },
 {
  name: "passwordIsp",
  label: "configurator.isp.passwordIsp",
  required: false
 },
 {
  name: "passwordSql",
  label: "configurator.isp.passwordSql",
  required: true
 },
 {
  name: "passwordServer",
  label: "configurator.isp.passwordServer",
  required: false
 },
 {
  name: "soapLocation",
  label: "configurator.isp.soapLocation",
  required: true
 },
 {
  name: "soapUrl",
  label: "configurator.isp.soapUrl",
  required: true
 }
];
