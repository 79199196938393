import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { deleteSite } from "redux/handlers/websitesHandler";

import { useAppDispatch } from "hooks/reduxHook";

type ActionsType = {
 section: string;
 isp_id: number;
 site: string;
};

const WebsitesMenu = ({ section, isp_id, site }: ActionsType): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const open = Boolean(anchorEl);

 const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
  setAnchorEl(null);
 };

 const handleOpenModal = () => setOpenModal(true);
 const handleCloseModal = () => setOpenModal(false);

 const handleConfirm = () => {
  setLoading(true);
  dispatch(deleteSite(isp_id, site));
  setLoading(false);

  handleCloseModal();
  handleClose();
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <IconSelector icon="MenuIcon" />
   </Button>
   <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
     "aria-labelledby": "basic-button"
    }}>
    <Stack>
     <MenuItem onClick={() => handleOpenModal()}>
      <FormattedMessage id="websites.delete" />
     </MenuItem>
    </Stack>
   </Menu>
   <AppModal
    open={openModal}
    close={handleCloseModal}
    title={intl.formatMessage({ id: "websites.delete" })}
    handleClose={handleCloseModal}
    disabled={loading}
    handleConfirm={handleConfirm}
    confirmColor="error">
    <Typography>
     <FormattedMessage id="websites.delete.message" />
    </Typography>
   </AppModal>
  </>
 );
};

export default WebsitesMenu;
