import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

import { IZoneDataset } from "types/api/appsApiInterface";

export const zoneApi = (state: AppState) => state.zone;

export const getZoneNumber = createSelector(zoneApi, (zone) => {
 return zone?.length || 0;
});

export const getZoneList = createSelector(zoneApi, (zone) => {
 return (
  zone?.zones?.map((zoneItem: IZoneDataset) => {
   return {
    active: zoneItem.active,
    dnsRr: zoneItem.dns_rr_count,
    id: zoneItem.id,
    isp_id: zoneItem.isp_id,
    mail: zoneItem.mail,
    ns: zoneItem.ns,
    ttl: zoneItem.ttl,
    userid: zoneItem.userid,
    zone: zoneItem.zone,
    zone_id: zoneItem.zone_id,
    records: zoneItem.records
   };
  }) || []
 );
});
