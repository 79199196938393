export const formatPort = (port: string): string => {
 const firstReplace = port.replace("/tcp", "");
 if (firstReplace.includes("0.0.0.0")) {
  return firstReplace.replace("0.0.0.0:", "");
 } else if (firstReplace.includes("::")) {
  return firstReplace.replace(":::", "");
 } else {
  return firstReplace.replace("*:", "");
 }
};

export const separatePorts = (ports: string): Array<string> => {
 const firstResult = ports.split(", ");
 let finalArray = [];
 for (let element of firstResult) {
  if (element.includes("/r")) {
   finalArray.push(element.replace("/r", ""));
  } else {
   finalArray.push(element);
  }
 }

 return finalArray;
};
