import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const ZoneIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <path
   fillRule="evenodd"
   clipRule="evenodd"
   d="M14.6 10.6C14.6 10.6 17.8 7.4 17.8 4.2C17.8 2.43269 16.3673 1 14.6 1C12.8327 1 11.4 2.43269 11.4 4.2C11.4 7.4 14.6 10.6 14.6 10.6ZM14.6 5.8C15.4837 5.8 16.2 5.08366 16.2 4.2C16.2 3.31634 15.4837 2.6 14.6 2.6C13.7163 2.6 13 3.31634 13 4.2C13 5.08366 13.7163 5.8 14.6 5.8Z"
   fill="currentColor"
  />
  <path
   fillRule="evenodd"
   clipRule="evenodd"
   d="M3 5.8C3 5.57909 3.17909 5.4 3.4 5.4H6.28V6.2H3.8V8.36H3V5.8ZM7.72 5.4H10.6V6.2H7.72V5.4ZM3 14.76V9.64H3.8V14.76H3ZM16.6 10.6V13.8H15.8V10.6H16.6ZM16.6 15.4V18.6C16.6 18.8209 16.4209 19 16.2 19H13.64V18.2H15.8V15.4H16.6ZM3 18.6V16.04H3.8V18.2H5.96V19H3.4C3.17909 19 3 18.8209 3 18.6ZM12.36 19H7.24V18.2H12.36V19Z"
   fill="currentColor"
  />
 </svg>,
 "Zone"
);
