import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postInstallProxy } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

const CreateProxy = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const proxyType = "proxy-site";

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = async () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postInstallProxy(id, "", proxyType));
  handleClose();
  setLoading(false);
 };

 return (
  <>
   <Button onClick={handleOpen} variant="kxActionButton">
    <FormattedMessage id="docker.proxy.addContainer" />
   </Button>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.proxy.addContainer" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}>
    <Typography>
     <FormattedMessage id="docker.website.createNewProxy" />
    </Typography>
   </AppModal>
  </>
 );
};

export default CreateProxy;
