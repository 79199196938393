import React, { ReactElement, useEffect, useState } from "react";

import Stack from "@mui/material/Stack";

import Firewall from "components/addons/firewall/Firewall";
import SkeletonAddons from "components/shared/skeletons/SkeletonAddons";

import { getAllFirewall } from "redux/handlers/addonsHandle";
import { getAllServers } from "redux/handlers/serverHandler";

import { useAppDispatch } from "hooks/reduxHook";

const FirewallPage = (): ReactElement => {
 const dispatch = useAppDispatch();

 const [isLoading, setIsLoading] = useState<boolean>(true);

 useEffect(() => {
  (async () => {
   await dispatch(getAllFirewall(0, 10));
   await dispatch(getAllServers(0, 10));
   setIsLoading(false);
  })();
 }, []);

 return <Stack>{isLoading ? <SkeletonAddons id="addons.firewall" /> : <Firewall />}</Stack>;
};

export default FirewallPage;
