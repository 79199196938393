import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

import { ITeamsDataset } from "types/api/teamsApiInterface";

export const getTeamsState = (state: AppState) => state.teams;

export const getTeamsNumber = createSelector(getTeamsState, (teams) => {
 return teams?.totalCount;
});

export const getTeamsDataSet = createSelector(getTeamsState, (teams) => {
 return (
  teams?.dataset?.map((teams: ITeamsDataset) => {
   return {
    id: teams.id,
    userid: teams.userid,
    parentid: teams.parentid,
    email: teams.email,
    domain_list: teams.domain_list,
    server_list: teams.server_list,
    can_reboot: teams.can_reboot
   };
  }) || []
 );
});
