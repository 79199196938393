/* eslint-disable react/style-prop-object */
import React, { ReactElement, useState } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

import Radio from "@mui/material/Radio";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { locationList } from "constants/locations";

import { ILoadBalancerType } from "types/api/addonsApiInterface";

const BalancerTypeTable = ({
 balancerList,
 selectBalancer
}: {
 balancerList: Array<ILoadBalancerType>;
 selectBalancer: (typeId: number) => void;
}): ReactElement => {
 const [selectedType, setSelectedType] = useState<number>(0);

 const RenderLocation = (location: string) => {
  const filteredLocation = locationList.filter((data) => location === data.tag);

  return filteredLocation.map((element, index) => (
   <Stack key={`location-item-${index}`} direction="row" spacing={1}>
    <Stack component="span" className={`fi fi-${element.flag}`} />
    <Typography variant="inherit">
     <FormattedMessage id={element.location} />
    </Typography>
   </Stack>
  ));
 };

 const handleClickTable = (id: number) => {
  setSelectedType(id);
  selectBalancer(id);
 };

 return (
  <TableContainer component="div">
   <Table sx={{ minWidth: 650 }} aria-label="simple table">
    <TableHead>
     <TableRow sx={{ backgroundColor: "transparent" }}>
      <TableCell></TableCell>
      <TableCell>
       <Typography variant="kxColoredSmall" fontWeight="bold">
        <FormattedMessage id="addons.loadBalancer.name" />
       </Typography>
      </TableCell>
      <TableCell>
       <Typography variant="kxColoredSmall" fontWeight="bold">
        <FormattedMessage id="addons.loadBalancer.maxServices" />
       </Typography>
      </TableCell>
      <TableCell>
       <Typography variant="kxColoredSmall" fontWeight="bold">
        <FormattedMessage id="addons.loadBalancer.maxTargets" />
       </Typography>
      </TableCell>
      <TableCell>
       <Typography variant="kxColoredSmall" fontWeight="bold">
        <FormattedMessage id="addons.loadBalancer.location" />
       </Typography>
      </TableCell>
      <TableCell>
       <Typography variant="kxColoredSmall" fontWeight="bold">
        <FormattedMessage id="addons.loadBalancer.price" />
       </Typography>
      </TableCell>
     </TableRow>
    </TableHead>
    <TableBody>
     {balancerList.map((row, index) => (
      <TableRow
       key={`cloudbox-list-${index}`}
       onClick={() => handleClickTable(row.id)}
       sx={{ cursor: "pointer" }}>
       <TableCell>
        <Radio checked={selectedType === row.id} />
       </TableCell>
       <TableCell component="th" scope="row">
        <Stack direction="column">
         <Typography variant="inherit" fontWeight="bold">
          {row.name}
         </Typography>
        </Stack>
       </TableCell>
       <TableCell component="th" scope="row">
        <Typography variant="inherit">{row.max_services}</Typography>
       </TableCell>
       <TableCell component="th" scope="row">
        <Typography variant="inherit">{row.max_targets}</Typography>
       </TableCell>
       <TableCell component="th" scope="row">
        {RenderLocation(row.prices_location)}
       </TableCell>
       <TableCell component="th" scope="row">
        <FormattedNumber value={row.amount} style={"currency"} currency="EUR" />
       </TableCell>
      </TableRow>
     ))}
    </TableBody>
   </Table>
  </TableContainer>
 );
};

export default BalancerTypeTable;
