import React, { ReactElement, useState } from "react";
import { useIntl } from "react-intl";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";

import DateRangeIcon from "@mui/icons-material/DateRange";

import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";

import AppModal from "components/shared/modal/AppModal";

import { postServiceChangeRenewDate } from "redux/handlers/servicesHandler";

import { useAppDispatch } from "hooks/reduxHook";

const ChangeServiceExpire = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [value, setValue] = useState<string>("");
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);
 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postServiceChangeRenewDate(id, value));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Tooltip title={intl.formatMessage({ id: "profile.services.changeExpireDate" })}>
    <IconButton onClick={handleOpen}>
     <DateRangeIcon />
    </IconButton>
   </Tooltip>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "profile.services.changeExpireDate" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading || value === ""}>
    {desktopViewPort ? (
     <DatePicker
      label={intl.formatMessage({ id: "profile.services.expireDate" })}
      value={value}
      disablePast
      views={["day", "month", "year"]}
      onChange={(newValue) => setValue(dayjs(newValue).toDate().toDateString() || "")}
     />
    ) : (
     <MobileDatePicker
      label={intl.formatMessage({ id: "profile.services.expireDate" })}
      value={value}
      disablePast
      views={["day", "month", "year"]}
      onChange={(newValue) => setValue(dayjs(newValue).toDate().toDateString() || "")}
     />
    )}
   </AppModal>
  </>
 );
};

export default ChangeServiceExpire;
