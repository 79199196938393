import React, { ReactElement, useState } from "react";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";

import IconSelector from "components/shared/images/IconSelector";

import { IUsersList } from "types/api/userApiInterface";

import ChangeCloudboxPartner from "./actions/ChangeCloudboxPartner";
import CompleteCreation from "./actions/CompleteCreation";
import CompleteUpgrade from "./actions/CompleteUpgrade";

const CloudboxMenu = ({
 id,
 partner,
 partnerList,
 cloud_id,
 status,
 reloadData
}: {
 id: number;
 partner: string;
 partnerList: Array<IUsersList>;
 cloud_id: number;
 status: string;
 reloadData: () => void;
}): ReactElement => {
 const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
 const open = Boolean(anchorEl);

 const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
  setAnchorEl(null);
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <IconSelector icon="MenuIcon" />
   </Button>
   <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
     "aria-labelledby": "basic-button"
    }}>
    <Stack>
     <ChangeCloudboxPartner
      id={id}
      partner={partner}
      partnerList={partnerList}
      closeMenu={handleClose}
      reloadData={reloadData}
     />
     {status === "upgrading" && <CompleteUpgrade id={id} closeMenu={handleClose} />}
     {status === "creating" && (
      <CompleteCreation id={id} cloud_id={cloud_id} closeMenu={handleClose} />
     )}
    </Stack>
   </Menu>
  </>
 );
};

export default CloudboxMenu;
