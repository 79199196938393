import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { IspApi } from "types/api/IspIdApiInterface";

const initialState: IspApi = { dataset: [], totalCount: 0 };

const IspReducer = createSlice({
 name: "Isp",
 initialState,
 reducers: {
  setIsp: (state, { payload }: PayloadAction<IspApi>) => {
   return payload;
  },
  resetIsp: () => {
   return initialState;
  }
 }
});

export default IspReducer;
