import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postUpdateMailboxBackup } from "redux/handlers/mailsHandler";

import { useAppDispatch } from "hooks/reduxHook";

const DisableBackup = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postUpdateMailboxBackup(id, 1, "none"));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Button variant="kxActionButton" onClick={handleOpen}>
    <FormattedMessage id="mails.backup.disable" />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    disabled={loading}
    title={intl.formatMessage({ id: "mails.backup.disable" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}>
    <Typography>
     <FormattedMessage id="mails.backup.disableMessage" />
    </Typography>
   </AppModal>
  </>
 );
};

export default DisableBackup;
