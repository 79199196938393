import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import AppModal from "components/shared/modal/AppModal";

import { postUpdateGateway } from "redux/handlers/gatewayHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { GatewayData, IGatewayApi } from "types/api/gatewayApiInterface";
import { IUsersList } from "types/api/userApiInterface";

const EditGateway = ({
 contacts,
 servers,
 projectData,
 id,
 reloadData,
 closeMenu
}: {
 contacts: Array<IUsersList>;
 servers: Array<{ id: number; server_name: string }>;
 projectData: IGatewayApi;
 id: number;
 reloadData?: () => void;
 closeMenu: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);

 const handleOpen = () => {
  reset();
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const { control, handleSubmit, watch, setValue, reset } = useForm({
  defaultValues: {
   userid: projectData.userid,
   url: projectData.url,
   server: projectData.serverId,
   password: projectData.password,
   hostname: projectData.hostname
  }
 });

 const onSubmit: SubmitHandler<GatewayData> = async (data: GatewayData) => {
  setIsLoading(true);
  await dispatch(
   postUpdateGateway(
    projectData.id,
    data.userid,
    data.url,
    data.hostname,
    data.server,
    data.password
   )
  );
  setIsLoading(false);
  reloadData && reloadData();
  setOpenModal(false);
  closeMenu();
 };

 return (
  <>
   <MenuItem onClick={handleOpen}>
    <FormattedMessage id="configurator.edit.gateway" />
   </MenuItem>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    handleConfirm={handleSubmit(onSubmit)}
    title={intl.formatMessage({ id: "configurator.edit.gateway" })}>
    <form onSubmit={handleSubmit(onSubmit)}>
     <Stack>
      <Controller
       name="userid"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <Autocomplete
         fullWidth={true}
         autoHighlight
         sx={{ my: 2 }}
         onChange={(e, value) => {
          setValue("userid", value?.id || "");
         }}
         defaultValue={{
          label: `${contacts.find((element) => element.userid === projectData.userid)
           ?.firstname} ${contacts.find((element) => element.userid === projectData.userid)
           ?.lastname} - ${contacts.find((element) => element.userid === projectData.userid)
           ?.companyName}`,
          id: contacts.find((element) => element.userid === projectData.userid)?.userid,
          key: contacts.find((element) => element.userid === projectData.userid)?.userid
         }}
         options={contacts.map((element) => {
          return {
           label:
            `${element.firstname} ${element.lastname} - ${element.companyName}` || "Not provided",
           id: element.userid,
           key: element.userid
          };
         })}
         renderOption={(props, option) => {
          return (
           <li {...props} key={`client-edit-${option.id}`}>
            {option.label}
           </li>
          );
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           {...field}
           size="small"
           InputLabelProps={{ shrink: true }}
           label={intl.formatMessage({ id: "configurator.gateway.partner" })}
          />
         )}
        />
       )}
      />
      <Controller
       name={"url"}
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         size="small"
         label={intl.formatMessage({ id: "configurator.gateway.url" })}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         autoComplete="off"
        />
       )}
      />
      <Controller
       name="server"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <Autocomplete
         fullWidth={true}
         autoHighlight
         sx={{ my: 2 }}
         onChange={(e, value) => {
          setValue("server", value?.id || 0);
          setValue("hostname", value?.label || "");
         }}
         defaultValue={{
          label: servers.find((element) => element.id === watch("server"))?.server_name,
          id: servers.find((element) => element.id === watch("server"))?.id
         }}
         options={servers.map((element) => {
          return { label: element.server_name, id: element.id };
         })}
         renderInput={(params) => (
          <TextField
           {...params}
           {...field}
           size="small"
           InputLabelProps={{ shrink: true }}
           label={`${intl.formatMessage({ id: "configurator.isp.server" })}`}
          />
         )}
        />
       )}
      />
      <Controller
       name={"password"}
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         size="small"
         label={intl.formatMessage({ id: "configurator.gateway.password" })}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         autoComplete="off"
        />
       )}
      />
     </Stack>
    </form>
   </AppModal>
  </>
 );
};

export default EditGateway;
