import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import AddIcon from "@mui/icons-material/Add";

import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";

import AppModal from "components/shared/modal/AppModal";

import { postAddGateway } from "redux/handlers/gatewayHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { GatewayData } from "types/api/gatewayApiInterface";
import { IUsersList } from "types/api/userApiInterface";

const AddGateway = ({
 contacts,
 servers,
 reloadData
}: {
 contacts: Array<IUsersList>;
 servers: Array<{ id: number; server_name: string }>;
 reloadData?: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);

 const handleOpen = () => {
  reset();
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const { control, handleSubmit, setValue, watch, reset } = useForm({
  defaultValues: {
   userid: "",
   url: "",
   server: 0,
   password: "",
   hostname: ""
  }
 });

 const onSubmit: SubmitHandler<GatewayData> = async (data: GatewayData) => {
  setIsLoading(true);
  await dispatch(postAddGateway(data.userid, data.url, data.hostname, data.server, data.password));
  setIsLoading(false);
  reloadData && reloadData();
  setOpenModal(false);
 };

 const checkDisabled = (): boolean => {
  return (
   watch("userid") === "" ||
   watch("url") === "" ||
   watch("hostname") === "" ||
   watch("server") === 0 ||
   watch("password") === ""
  );
 };

 return (
  <Stack>
   {desktopViewPort ? (
    <Button onClick={handleOpen} variant="kxActionButton" endIcon={<AddIcon />}>
     <FormattedMessage id="configurator.add.gateway" />
    </Button>
   ) : (
    <IconButton onClick={handleOpen}>
     <AddIcon />
    </IconButton>
   )}
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    disabled={checkDisabled()}
    handleConfirm={handleSubmit(onSubmit)}
    title={intl.formatMessage({ id: "configurator.add.gateway" })}>
    <form onSubmit={handleSubmit(onSubmit)}>
     <Stack>
      <Controller
       name="userid"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <Autocomplete
         fullWidth={true}
         autoHighlight
         sx={{ my: 2 }}
         onChange={(e, value) => {
          setValue("userid", value?.id || "");
         }}
         options={contacts.map((element) => {
          return {
           label:
            `${element.firstname} ${element.lastname} - ${element.companyName}` || "Not provided",
           id: element.userid,
           key: element.userid
          };
         })}
         renderOption={(props, option) => {
          return (
           <li {...props} key={`client-add-${option.id}`}>
            {option.label}
           </li>
          );
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           {...field}
           size="small"
           InputLabelProps={{ shrink: true }}
           label={intl.formatMessage({ id: "configurator.gateway.partner" })}
          />
         )}
        />
       )}
      />
      <Controller
       name={"url"}
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         size="small"
         label={intl.formatMessage({ id: "configurator.gateway.url" })}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         autoComplete="off"
        />
       )}
      />
      <Controller
       name="server"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <Autocomplete
         fullWidth={true}
         autoHighlight
         sx={{ my: 2 }}
         onChange={(e, value) => {
          setValue("server", value?.id || 0);
          setValue("hostname", value?.label || "");
         }}
         options={servers.map((element) => {
          return { label: element.server_name, id: element.id };
         })}
         renderInput={(params) => (
          <TextField
           {...params}
           {...field}
           size="small"
           InputLabelProps={{ shrink: true }}
           label={`${intl.formatMessage({ id: "configurator.isp.server" })}`}
          />
         )}
        />
       )}
      />
      <Controller
       name={"password"}
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         size="small"
         label={intl.formatMessage({ id: "configurator.gateway.password" })}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         autoComplete="off"
        />
       )}
      />
     </Stack>
    </form>
   </AppModal>
  </Stack>
 );
};

export default AddGateway;
