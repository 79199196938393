import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postUpdateFtpDirectory } from "redux/handlers/websitesHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IFtpData } from "types/api/websitesApiInterface";

const EditDirectory = ({ ftpUser }: { ftpUser: IFtpData }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);

 const handleOpen = () => {
  setOpenModal(true);
  setValue("directory", ftpUser.dir);
 };
 const handleClose = () => setOpenModal(false);

 const { control, handleSubmit, setValue, watch, formState } = useForm({
  defaultValues: {
   directory: ftpUser.dir
  }
 });

 const onSubmit: SubmitHandler<{ directory: string }> = async (data: { directory: string }) => {
  setIsLoading(true);
  await dispatch(postUpdateFtpDirectory(ftpUser.id, data.directory));
  setIsLoading(false);
  setOpenModal(false);
 };

 return (
  <Stack maxWidth={600}>
   <Tooltip title={<FormattedMessage id="edit.ftp.directory" />}>
    <Button variant="kxActionButton" onClick={handleOpen} disabled={watch("directory") === ""}>
     <IconSelector icon="EditIcon" />
    </Button>
   </Tooltip>
   <AppModal
    open={openModal}
    close={handleClose}
    loading={isLoading}
    title={intl.formatMessage({ id: "sites.ftpOperations" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}>
    <Stack justifyContent="center" alignContent="center" direction="column">
     <Controller
      name="directory"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "sites.directory" })}
        error={formState.isDirty && !!formState?.errors?.directory}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.directory &&
         intl.formatMessage({ id: "sites.directoryError" })
        }
       />
      )}
     />
    </Stack>
   </AppModal>
  </Stack>
 );
};

export default EditDirectory;
