import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";

import { isNil } from "ramda";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { postUpdateDatabase } from "redux/handlers/websitesHandler";

import { getIspList } from "redux/selectors/ispSelector";
import { getWebsitesDetails } from "redux/selectors/websitesSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IDbData } from "types/api/websitesApiInterface";

import AddDatabase from "../actions/AddDatabase";
import DeleteDatabase from "../actions/DeleteDatabase";
import EditDatabase from "../actions/EditDatabase";
import EditDbPassword from "../actions/EditDbPassword";
import EditDbRemoteIP from "../actions/EditDbRemoteIP";
import EditDbUser from "../actions/EditDbUser";

const defaultUser = {
 database_id: 0,
 database_password: "",
 database_user: "",
 database_user_id: 0,
 id: 0,
 isp_id: 0
};

const defaultDatabase = {
 id: 0,
 isp_id: 0,
 database_id: 0,
 type: "",
 password: "",
 database_name: "",
 database_quota: 0,
 remote_access: "n",
 active: "",
 parent_domain_id: 0,
 db_user: defaultUser,
 remote_ips: ""
};

const DetailsDatabase = (): ReactElement => {
 const dispatch = useAppDispatch();
 const websitesDetails = useSelector(getWebsitesDetails);
 const ispList = useSelector(getIspList);
 const databases = websitesDetails.db;
 const params = useParams<{ domain_id: string; section: string }>();

 const [selectedDb, setSelectedDb] = useState<IDbData>(databases[0] || defaultDatabase);
 const [databasesLength, setDatabasesLength] = useState<number>(databases.length || 0);
 const [showPassword, setShowPassword] = useState<string>("password");
 const [toggleCheck, setToggleCheck] = useState<boolean>(
  databases[0] ? databases[0].remote_access === "y" : false
 );

 const handleClickShowPassword = () =>
  setShowPassword(showPassword === "password" ? "text" : "password");

 const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
  event.preventDefault();
 };

 useEffect(() => {
  if (databasesLength !== databases.length && databases.length !== 0) {
   setSelectedDb(databases[0]);
   setDatabasesLength(databases.length);
   setToggleCheck(databases[0].remote_access === "y");
  }
 }, [databases]);

 const changeDatabase = (database: IDbData) => {
  setSelectedDb(database);
  setToggleCheck(database.remote_access === "y");
 };

 const handleSwitchToggle = async (access: boolean) => {
  setToggleCheck(access);
  await dispatch(
   postUpdateDatabase(
    selectedDb.id,
    websitesDetails.isp_id,
    websitesDetails.domain_id,
    selectedDb.database_name,
    selectedDb.database_quota,
    access ? "y" : "n",
    Number(params.domain_id),
    selectedDb.remote_ips
   )
  );
 };

 return (
  <>
   <Stack>
    {databases.length !== 0 ? (
     <>
      <Stack
       direction="row"
       alignItems="center"
       justifyContent="space-between"
       spacing={2}
       py={0.5}
       pr={2}
       pt={2}>
       <Stack direction="row" alignItems="center" textAlign="start" spacing={2} width="100%">
        <Typography fontWeight="bold" variant="subtitle1" px={2} minWidth={150}>
         <FormattedMessage id="sites.dbName" />
        </Typography>
        <Autocomplete
         autoHighlight
         fullWidth
         size="small"
         sx={{ my: 2 }}
         onChange={(e, value) =>
          !isNil(value)
           ? changeDatabase(databases.find((db) => db.id === value?.id) || defaultDatabase)
           : changeDatabase(defaultDatabase)
         }
         options={databases.map((db) => {
          return { label: db.database_name, id: db.id };
         })}
         value={{
          label: databases.find((db) => db.id === selectedDb?.id)?.database_name || "",
          id: databases.find((db) => db.id === selectedDb?.id)?.id || 0
         }}
         renderInput={(params) => (
          <TextField {...params} fullWidth={true} InputLabelProps={{ shrink: true }} />
         )}
        />
       </Stack>
      </Stack>
      <Divider />
      <Stack
       direction="row"
       alignItems="center"
       justifyContent="space-between"
       spacing={2}
       py={0.5}
       pr={2}>
       <Stack direction="row" alignItems="center" textAlign="start" spacing={2}>
        <Typography fontWeight="bold" variant="subtitle1" px={2} minWidth={150}>
         <FormattedMessage id="sites.dbQuota" />
        </Typography>
        <Typography variant="body1">
         {selectedDb.database_quota === -1 ? (
          <FormattedMessage id="sites.quotaUnlimited" />
         ) : (
          `${selectedDb.database_quota} MB`
         )}
        </Typography>
       </Stack>
      </Stack>
      <Divider />
      <Stack
       direction="row"
       alignItems="center"
       justifyContent="space-between"
       spacing={2}
       py={0.5}
       pr={2}>
       <Stack direction="row" alignItems="center" textAlign="start" spacing={2}>
        <Typography fontWeight="bold" variant="subtitle1" px={2} minWidth={150}>
         <FormattedMessage id="sites.nginxAccess" />
        </Typography>
        <NavLink
         to={`https://${ispList.find((element) => element.id === websitesDetails.isp_id)
          ?.ip1}:8080/phpmyadmin`}
         target={"_blank"}>
         <FormattedMessage id="sites.accessToDb" />
        </NavLink>
       </Stack>
      </Stack>
      <Divider />
      <Stack
       direction="row"
       alignItems="center"
       justifyContent="space-between"
       spacing={2}
       py={0.5}
       pr={2}>
       <Stack direction="row" alignItems="center" textAlign="start" spacing={2}>
        <Typography fontWeight="bold" variant="subtitle1" px={2} minWidth={150}>
         <FormattedMessage id="sites.apacheAccess" />
        </Typography>
        <NavLink
         to={`https://${ispList.find((element) => element.id === websitesDetails.isp_id)
          ?.ip1}:8081/phpmyadmin`}
         target={"_blank"}>
         <FormattedMessage id="sites.accessToDb" />
        </NavLink>
       </Stack>
      </Stack>
      <Divider />
      <Stack direction="row" alignItems="center" textAlign={"start"} pr={2}>
       <Typography fontWeight="bold" variant="subtitle1" px={2} minWidth={150}>
        <FormattedMessage id="sites.dbRemoteAccess" />
       </Typography>
       <Switch
        checked={toggleCheck}
        onChange={(event) => handleSwitchToggle(event.target.checked)}
       />
      </Stack>
      <Divider />
      <Stack direction="row" alignItems="center" textAlign={"start"} pr={2} spacing={2}>
       <Typography fontWeight="bold" variant="subtitle1" px={2} minWidth={150}>
        <FormattedMessage id="sites.dbRemoteIp" />
       </Typography>
       <Stack direction="row" alignItems="center" justifyContent="space-between" width="stretch">
        <Typography>{selectedDb.remote_ips || "Not available"}</Typography>
        <EditDbRemoteIP selectedDb={selectedDb} domain_id={Number(params.domain_id)} />
       </Stack>
      </Stack>
      <Divider />
      {selectedDb.db_user && (
       <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        py={0.5}
        pr={2}>
        <Stack direction="row" alignItems="center" textAlign="start" spacing={2}>
         <Typography fontWeight="bold" variant="subtitle1" px={2} minWidth={150}>
          <FormattedMessage id="sites.dbUser" />
         </Typography>
         <Typography variant="body1">{selectedDb.db_user?.database_user || ""}</Typography>
        </Stack>
       </Stack>
      )}
      <Divider />
      {selectedDb.db_user && (
       <Stack direction="row" alignItems="center" textAlign="start" spacing={2} py={0.5} pr={2}>
        <Typography fontWeight="bold" variant="subtitle1" px={2} minWidth={150}>
         <FormattedMessage id="sites.password" />
        </Typography>
        {selectedDb.db_user?.database_password ? (
         <TextField
          fullWidth
          type={showPassword}
          autoComplete="current-password"
          value={selectedDb.db_user?.database_password || ""}
          InputProps={{
           readOnly: true,
           endAdornment: (
            <InputAdornment position="end">
             <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end">
              {showPassword === "text" ? <VisibilityOff /> : <Visibility />}
             </IconButton>
            </InputAdornment>
           )
          }}
          variant="standard"
         />
        ) : (
         <Typography variant="body1" noWrap>
          {"Not provided"}
         </Typography>
        )}
       </Stack>
      )}
      <Divider />
      <Stack direction="row" alignItems="center" p={1} justifyContent="center">
       <Stack direction={"row"} alignItems="center" spacing={3} maxWidth={"100%"}>
        <AddDatabase type="icon" />
        <EditDatabase dbData={selectedDb} />
        <DeleteDatabase dbData={selectedDb} />
        <EditDbUser selectedDb={selectedDb} domain_id={Number(params.domain_id)} />
        <EditDbPassword selectedDb={selectedDb} domain_id={Number(params.domain_id)} />
       </Stack>
      </Stack>
     </>
    ) : (
     <>
      <Divider />
      <Stack p={2}>
       <Alert severity="info">
        <FormattedMessage id="sites.noDatabaseMessage" />
       </Alert>
      </Stack>
      <Divider />
      <AddDatabase type="text" />
     </>
    )}
   </Stack>
  </>
 );
};

export default DetailsDatabase;
