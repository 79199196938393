import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";

import AppModal from "components/shared/modal/AppModal";

import { postChangeDomainLock } from "redux/handlers/domainsHandler";

import { useAppDispatch } from "hooks/reduxHook";

const ChangeDomainLock = ({ id, lock }: { id: number; lock: string | null }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => {
  if (lock !== "NOTADMITTED" && lock !== null) {
   setOpenModal(true);
  }
 };

 const handleClose = () => setOpenModal(false);

 const handleChangelock = async (id: number) => {
  setLoading(true);
  await dispatch(postChangeDomainLock(id));
  setLoading(false);
  handleClose();
 };

 const getLockChip = (lock: string | null) => {
  if (lock === "ENABLED") {
   return (
    <Tooltip title={intl.formatMessage({ id: "domains.enabled" })}>
     <Chip
      icon={<LockOutlinedIcon />}
      sx={{ cursor: "pointer" }}
      color={"success"}
      size="small"
      label={intl.formatMessage({ id: "domains.enabled" })}
      onClick={() => handleOpen()}
     />
    </Tooltip>
   );
  } else if (lock === "NOTADMITTED") {
   return (
    <Tooltip title={intl.formatMessage({ id: "domains.notAdmittedProt" })}>
     <Chip
      icon={<LockOpenOutlinedIcon />}
      sx={{ cursor: "pointer" }}
      color={"default"}
      size="small"
      label={intl.formatMessage({ id: "domains.notAdmitted" })}
      onClick={() => handleOpen()}
     />
    </Tooltip>
   );
  } else if (lock === "DISABLED") {
   return (
    <Tooltip title={intl.formatMessage({ id: "domains.disabled" })}>
     <Chip
      icon={<LockOpenOutlinedIcon />}
      sx={{ cursor: "pointer" }}
      color={"error"}
      size="small"
      label={intl.formatMessage({ id: "domains.disabled" })}
      onClick={() => handleOpen()}
     />
    </Tooltip>
   );
  } else if (lock === null) {
   return (
    <Tooltip title={intl.formatMessage({ id: "domains.null" })}>
     <Chip
      icon={<LockOpenOutlinedIcon />}
      sx={{ cursor: "pointer" }}
      color={"default"}
      size="small"
      onClick={() => handleOpen()}
     />
    </Tooltip>
   );
  }
  return;
 };

 return (
  <>
   {getLockChip(lock)}
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "domains.changelock" })}
    handleClose={handleClose}
    disabled={loading}
    handleConfirm={() => handleChangelock(id)}>
    {lock === "ENABLED" ? (
     <FormattedMessage id="domains.disableProtMessage" />
    ) : lock === "DISABLED" ? (
     <FormattedMessage id="domains.enableProtMessage" />
    ) : (
     <FormattedMessage id="notadmitted.message" />
    )}
   </AppModal>
  </>
 );
};

export default ChangeDomainLock;
