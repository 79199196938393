import React, { ReactElement, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { SubmitHandler } from "react-hook-form/dist/types";
import { FormattedMessage, useIntl } from "react-intl";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import { isNil } from "ramda";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import AppModal from "components/shared/modal/AppModal";

import { postAddNewService } from "redux/handlers/servicesHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IUsersList } from "types/api/userApiInterface";

type serviceType = {
 name: string;
 partner: string;
 duration: number;
 expire: string;
 cost: number;
 type: string;
 autorenew: boolean;
 movimentation: boolean;
};

const AddService = ({ customerList }: { customerList: Array<IUsersList> }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => {
  reset();
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const { control, handleSubmit, watch, setValue, formState, reset } = useForm({
  defaultValues: {
   name: "",
   partner: "",
   duration: 1,
   expire: "",
   cost: 0,
   type: "",
   autorenew: false,
   movimentation: false
  }
 });

 const onSubmit: SubmitHandler<serviceType> = async (data: serviceType) => {
  setLoading(true);
  await dispatch(
   postAddNewService(
    data.name,
    data.partner,
    data.type,
    data.expire,
    data.duration,
    data.cost,
    data.autorenew,
    data.movimentation
   )
  );
  setLoading(false);
  handleClose();
 };

 const checkDisabled = (): boolean => {
  return (
   watch("name") === "" ||
   watch("duration") <= 0 ||
   watch("partner") === "" ||
   watch("type") === "" ||
   watch("expire") === ""
  );
 };

 return (
  <>
   <Button variant="kxActionButton" onClick={handleOpen}>
    <FormattedMessage id="profile.service.addService" />
   </Button>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "profile.service.addService" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={loading || checkDisabled()}>
    <Stack spacing={2}>
     <Alert severity="warning">
      <FormattedMessage id="profile.service.addServiceMessage" />
     </Alert>
     <Controller
      name="name"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "profile.service.name" })}
        error={formState.isDirty && !!formState?.errors?.name}
        InputLabelProps={{ shrink: true }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.name &&
         intl.formatMessage({ id: "profile.service.nameError" })
        }
       />
      )}
     />
     <Controller
      name="type"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "profile.service.type" })}
        error={formState.isDirty && !!formState?.errors?.type}
        InputLabelProps={{ shrink: true }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.type &&
         intl.formatMessage({ id: "profile.service.typeError" })
        }
       />
      )}
     />
     <Controller
      name="expire"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <DatePicker
        {...field}
        label={intl.formatMessage({ id: "profile.services.expireDate" })}
        disablePast
        views={["day", "month", "year"]}
        onChange={(newValue: string | null) =>
         !isNil(newValue)
          ? setValue("expire", dayjs(newValue).format("YYYY/MM/DD"))
          : setValue("expire", "")
        }
       />
      )}
     />
     <Controller
      name="duration"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "profile.service.duration" })}
        error={formState.isDirty && !!formState?.errors?.duration}
        InputLabelProps={{ shrink: true }}
        type="number"
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.duration &&
         intl.formatMessage({ id: "profile.service.durationError" })
        }
       />
      )}
     />
     <Controller
      name="cost"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "profile.service.cost" })}
        error={formState.isDirty && !!formState?.errors?.cost}
        InputLabelProps={{ shrink: true }}
        type="number"
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.cost &&
         intl.formatMessage({ id: "profile.service.costError" })
        }
       />
      )}
     />
     <Controller
      name="partner"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <Autocomplete
        autoHighlight
        onChange={(e, value) =>
         isNil(value) ? setValue("partner", "") : setValue("partner", value.userid)
        }
        options={customerList.map((element) => {
         return {
          label: `${element.firstname} ${element.lastname} - ${element.companyName}`,
          userid: element.userid
         };
        })}
        renderInput={(params) => (
         <TextField
          {...params}
          label={intl.formatMessage({ id: "profile.service.partner" })}
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
      )}
     />
     <Controller
      name="autorenew"
      control={control}
      render={({ field }) => (
       <FormControlLabel
        control={
         <Checkbox
          checked={watch("autorenew")}
          onChange={(e) => {
           setValue("autorenew", e.target.checked);
          }}
         />
        }
        label={intl.formatMessage({ id: "profile.service.autorenew" })}
       />
      )}
     />
     <Controller
      name="movimentation"
      control={control}
      render={({ field }) => (
       <FormControlLabel
        control={
         <Checkbox
          checked={watch("movimentation")}
          onChange={(e) => {
           setValue("movimentation", e.target.checked);
          }}
         />
        }
        label={intl.formatMessage({ id: "profile.service.movimentation" })}
       />
      )}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default AddService;
