import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postDisableBackups } from "redux/handlers/websitesHandler";

import { useAppDispatch } from "hooks/reduxHook";

const DisableBackups = ({
 domain_id,
 isp_id
}: {
 domain_id: number;
 isp_id: number;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postDisableBackups(domain_id, isp_id));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Button variant="kxActionButton" onClick={handleOpen}>
    <FormattedMessage id="sites.backups.disable" />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    disabled={loading}
    title={intl.formatMessage({ id: "sites.backups.disable" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}>
    <Typography>
     <FormattedMessage id="sites.backup.disableMessage" />
    </Typography>
   </AppModal>
  </>
 );
};

export default DisableBackups;
