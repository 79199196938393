import React, { ReactElement, useState } from "react";
import { useSelector } from "react-redux";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { postChangeRootLogin } from "redux/handlers/serverHandler";

import { getAllServices, getServerDetails } from "redux/selectors/serversSelector";

import { useAppDispatch } from "hooks/reduxHook";

const ServicePermit = (): ReactElement => {
 const dispatch = useAppDispatch();

 const server = useSelector(getServerDetails);
 const services = useSelector(getAllServices);

 const [checked, setChecked] = useState<boolean>(services?.sshOn || false);

 const handleChangePermit = async (value: boolean) => {
  setChecked(value);
  await dispatch(postChangeRootLogin(server?.id || 0, value));
 };

 return (
  <FormControlLabel
   disabled={services?.ssh === "no"}
   value="top"
   control={
    <Switch
     color="primary"
     defaultChecked={checked}
     onChange={(e, value) => handleChangePermit(value)}
    />
   }
   label="PermitRootLogin"
   labelPlacement="start"
  />
 );
};

export default ServicePermit;
