import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { IGatewayOrders } from "types/api/gatewayApiInterface";

const initialState: IGatewayOrders = { dataset: [], totalCount: 0 };

const GatewayOrdersReducer = createSlice({
 name: "Gatewayorder",
 initialState,
 reducers: {
  setGatewayOrders: (state, { payload }: PayloadAction<IGatewayOrders>) => {
   return payload;
  },
  resetGatewayOrders: () => {
   return initialState;
  }
 }
});

export default GatewayOrdersReducer;
