import React, { ReactElement, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux/es/exports";
import RFB from "@novnc/novnc/core/rfb";
import NoVncClient from "@novnc/novnc/core/rfb";

import TerminalIcon from "@mui/icons-material/Terminal";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import { postRequestVnc } from "redux/handlers/serverHandler";

import { getServerDetails } from "redux/selectors/serversSelector";

import { useAppDispatch } from "hooks/reduxHook";

const ServerVnc = (): ReactElement => {
 const intl = useIntl();
 const vncRef = useRef<Element>(null);
 const dispatch = useAppDispatch();

 const server = useSelector(getServerDetails);

 const [open, setOpen] = useState<boolean>(false);
 const [loadingVnc, setLoadingVnc] = useState<boolean>(true);
 const [vnc, setVnc] = useState<NoVncClient>();
 const [url, setUrl] = useState<string>("");
 const [password, setPassword] = useState<string>("");

 const handleOpen = async () => {
  setOpen(true);
  const data = await dispatch(postRequestVnc(server.id || 0));
  setUrl(data.wss_url);
  setPassword(data.password);
 };
 const handleClose = () => {
  handleDisconnect();
  setUrl("");
  setPassword("");
  setLoadingVnc(true);
  setVnc(undefined);
  setOpen(false);
 };

 useEffect(() => {
  if (url) {
   renderVnc();
  }
 }, [url]);

 const handleConnect = () => {
  setLoadingVnc(false);
  vnc?.sendCtrlAltDel();
  vnc?.focus();
 };

 const handleDisconnect = () => {
  vnc?.disconnect();
 };

 const handleSendCtr = () => {
  vnc?.sendCtrlAltDel();
 };

 const handlePaste = async () => {
  const text = await navigator.clipboard.readText();
  for (let a = 0; a < text.length; a++) {
   vnc?.sendKey(text.charCodeAt(a), null);
  }
  vnc?.focus();
 };

 const renderVnc = () => {
  if (vncRef.current) {
   const vnc = new RFB(vncRef.current, url, {
    credentials: { username: "root", password: password, target: "" }
   });
   vnc.showDotCursor = true;
   vnc.resizeSession = true;
   vnc.addEventListener("connect", handleConnect);
   setVnc(vnc);
  }
 };

 return (
  <>
   <Tooltip title={intl.formatMessage({ id: "server.vnc" })}>
    <IconButton color="primary" onClick={handleOpen}>
     <TerminalIcon fontSize="large" />
    </IconButton>
   </Tooltip>
   <Dialog open={open} onClose={handleClose} maxWidth="xl">
    <DialogTitle>
     <FormattedMessage id="server.vnc" />
    </DialogTitle>
    <DialogContent>
     <Box ref={vncRef} minWidth={700} maxHeight={750}>
      {loadingVnc && (
       <>
        <CircularProgress />
        <Alert severity="info">
         <FormattedMessage id="server.vnc.chargingVnc" />
        </Alert>
       </>
      )}
     </Box>
    </DialogContent>
    <DialogActions>
     <Button variant="kxActionButton" onClick={handlePaste}>
      <FormattedMessage id="server.vnc.paste" />
     </Button>
     <Button variant="kxActionButton" onClick={handleSendCtr}>
      <FormattedMessage id="server.vnc.ctrlaltdel" />
     </Button>
     <Button variant="kxActionButton" onClick={handleClose}>
      <FormattedMessage id="server.vnc.close" />
     </Button>
    </DialogActions>
   </Dialog>
  </>
 );
};

export default ServerVnc;
