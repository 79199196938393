import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

export const getPermissions = (state: AppState) => state.permissions;

export const getServerPermissions = createSelector(getPermissions, (permission) => {
 return permission?.server;
});

export const getSnapshotPermissions = createSelector(getPermissions, (permission) => {
 return permission?.snapshot;
});
