import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { AppContext } from "AppContext";
import { getProviderPermissions } from "helpers/server";

import { isNil } from "ramda";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import { serverLinks } from "constants/staticLinks";

import DockerContainer from "components/docker/DockerContainer";
import GatewayContainer from "components/gateway/GatewayContainer";
import ServerCloudboxes from "components/servers/managePages/addons/ServerCloudboxes";
import ServerFloatingIp from "components/servers/managePages/addons/ServerFloatingIp";
import ServerNetwork from "components/servers/managePages/addons/ServerNetwork";
import ServerSnapshots from "components/servers/managePages/addons/ServerSnapshots";
import ServerVolumes from "components/servers/managePages/addons/ServerVolumes";
import ServerBackups from "components/servers/managePages/backup/ServerBackups";
import ServerJobQueue from "components/servers/managePages/jobqueue/ServerJobQueue";
import ServerMonitoring from "components/servers/managePages/monitoring/ServerMonitoring";
import ServerDetails from "components/servers/managePages/ServerDetails";
import ServerRebuild from "components/servers/managePages/ServerRebuild";
import ServerUpgrade from "components/servers/managePages/ServerUpgrade";
import ServerServices from "components/servers/managePages/services/ServerServices";
import ServerManage from "components/servers/ServerManage";
import SectionsBreadCrumbs from "components/shared/breadCrumbs/SectionsBreadCrumbs";
import MobileSectionMenu from "components/shared/sideMenu/MobileSectionMenu";
import SectionMenu from "components/shared/sideMenu/SectionMenu";

import { getServerOrders } from "redux/handlers/hetznerOrders";
import { postServerDetails } from "redux/handlers/serverHandler";
import { postSyncronizeSocket } from "redux/handlers/socketHandler";

import { getServerPermissions } from "redux/selectors/permissionSelector";
import { getServerDetails } from "redux/selectors/serversSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IServerPermissionApi } from "types/api/userApiInterface";

import ServerManageAddons from "./ServerManageAddons";
import ServerManageTypes from "./ServerManageTypes";

const ServerManagePage = (): ReactElement => {
 const location = useLocation();
 const dispatch = useAppDispatch();
 const params = useParams<{ id: string; section: string }>();
 const theme = useTheme();
 const { socket } = useContext(AppContext);
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const server = useSelector(getServerDetails);
 const permissions = useSelector(getServerPermissions);

 const [providerPermission, setProviderPermission] = useState<IServerPermissionApi | null>(null);
 const [reloadBackup, setReloadBackup] = useState<boolean>(false);
 const [reloadSnapshot, setReloadSnapshot] = useState<boolean>(false);
 const [sqlLogs, setSqlLogs] = useState<string>("");

 useEffect(() => {
  if (server.provider) {
   setProviderPermission(getProviderPermissions(server.provider, permissions || []));
  }
 }, [server]);

 const RenderServerPages = (): ReactElement => {
  switch (params.section) {
   case "services":
    return <ServerServices sqlLogs={sqlLogs} />;
   case "backups":
    return <ServerBackups dataToUpgrade={reloadBackup} resetData={() => setReloadBackup(false)} />;
   case "upgrade":
    return <ServerUpgrade />;
   case "rebuild":
    return <ServerRebuild />;
   case "jobQueue":
    return <ServerJobQueue />;
   case "snapshot":
    return (
     <ServerSnapshots dataToUpgrade={reloadSnapshot} resetData={() => setReloadSnapshot(false)} />
    );
   case "volumes":
    return <ServerVolumes />;
   case "gateway":
    return <GatewayContainer />;
   case "floatingip":
    return <ServerFloatingIp />;
   case "container":
    return <DockerContainer />;
   case "network":
    return <ServerNetwork />;
   case "cloudbox":
    return <ServerCloudboxes />;
   default:
    return <ServerManage />;
  }
 };

 const handleGetServerDetails = async () => {
  dispatch(postServerDetails(Number(params.id)));
  getServerOrders(0, 10);
 };

 useEffect(() => {
  params.id && handleGetServerDetails();
 }, [params.id]);

 useEffect(() => {
  !isNil(socket) &&
   socket.on("server_data", (value): void => {
    if (Number(params.id) === value?.id) {
     dispatch(postServerDetails(Number(params.id)));
     if (value?.backup) {
      setReloadBackup(true);
     }
     if (value?.snapshot) {
      setReloadSnapshot(true);
     }
     if (value?.logMysqlTables) {
      setSqlLogs(value?.logMysqlTables);
     }
    }
   });
  return () => {
   !isNil(socket) && setReloadBackup(false);
   !isNil(socket) && setReloadSnapshot(false);
   !isNil(socket) && socket.off("server_data");
  };
 }, [socket]);

 useEffect(() => {
  (async () => {
   await dispatch(postSyncronizeSocket("server"));
  })();
 }, []);

 const filterPages = (): Array<{ name: string; url: string; icon?: string }> => {
  return serverLinks.filter(
   (element) =>
    (element.url === "backups" && providerPermission?.backup) ||
    (element.url === "rebuild" && providerPermission?.rebuild) ||
    (element.url === "upgrade" && providerPermission?.upgrade) ||
    element.url === "specification" ||
    element.url === "services"
  );
 };

 return (
  <Container maxWidth="xl">
   <Stack pt={1}>
    <SectionsBreadCrumbs
     links={[
      { name: "home", url: "/" },
      { name: "servers", url: "/servers" },
      { name: params?.section || "", url: location.pathname }
     ]}
    />
   </Stack>
   <Grid container>
    {desktopViewPort ? (
     <Grid xs={desktopViewPort ? 3 : 0}>
      <Stack mt={6}>
       <SectionMenu
        menuList={filterPages().map((item) => ({
         name: item.name,
         url: `/servers/manage/${params?.id}/${item.url}`,
         icon: item.icon
        }))}
       />
      </Stack>
      <ServerManageAddons id={Number(params?.id) || 0} />
      <ServerManageTypes />
     </Grid>
    ) : (
     <MobileSectionMenu links={serverLinks} disabled={[]} url={`/servers/manage/${params?.id}`} />
    )}
    <Grid xs={desktopViewPort ? 9 : 12} mt={3}>
     <ServerDetails />
     <ServerMonitoring />
     <RenderServerPages />
    </Grid>
   </Grid>
  </Container>
 );
};

export default ServerManagePage;
