import React, { ReactElement, useEffect, useState } from "react";

import Stack from "@mui/material/Stack";

import MailsList from "components/mails/MailsList";
import SkeletonAddons from "components/shared/skeletons/SkeletonAddons";

import { getAllMailsDomain } from "redux/handlers/mailsHandler";
import { getAllIspNotPaged } from "redux/handlers/websitesHandler";

import { useAppDispatch } from "hooks/reduxHook";

const MailsPage = (): ReactElement => {
 const dispatch = useAppDispatch();

 const [isLoading, setIsLoading] = useState<boolean>(true);

 useEffect(() => {
  (async () => {
   await dispatch(getAllIspNotPaged("mailserver"));
   await dispatch(getAllMailsDomain(0, 10, 0));
   setIsLoading(false);
  })();
 }, []);

 return <Stack>{isLoading ? <SkeletonAddons id="app.mails" /> : <MailsList />}</Stack>;
};

export default MailsPage;
