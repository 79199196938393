import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import hostReducer from "redux/reducers/hostReducer";
import notificationReducer from "redux/reducers/notificationReducer";

import { IHostApi } from "types/api/hostApiInterface";

import { ApiService } from "service/ApiService";

export const getAllHost =
 (currentIndex: number, sizePerPage: number, q?: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IHostApi>(
    `/host/allhosts?currentIndex=${currentIndex}&sizePerPage=${sizePerPage}&q=${q || ""}`
   );
   dispatch(hostReducer.actions.setHost(data || {}));
  } catch (error) {
   console.warn("FAILED TO GET HOST");
  }
 };

export const postAddHost =
 (
  type: string,
  name: string,
  data1: string,
  ip: string,
  visible: boolean
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/host/addhost", "POST", {
    type: type,
    name: name,
    ip1: ip,
    data1: data1,
    visible: visible
   });
   if (data) {
    dispatch(getAllHost(0, 10));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "New host created",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create host - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteHost =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/host/deletehost", "POST", { id });
   if (data) {
    dispatch(getAllHost(0, 10));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Host updated successfully",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete host - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const putUpdateHost =
 (
  id: number,
  type: string,
  name: string,
  ip1: string,
  visible: boolean
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService(`/host/updatehost/${id}`, "PUT", {
    type,
    name,
    ip1,
    visible
   });
   if (data) {
    dispatch(getAllHost(0, 10));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Host updated successfully",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update host - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };
