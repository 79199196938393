import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import notificationReducer from "redux/reducers/notificationReducer";
import ZoneReducer from "redux/reducers/zoneReducer";

import { IZoneApi, IZoneList, Record } from "types/api/appsApiInterface";

import { ApiService } from "service/ApiService";

export const getAllZone =
 (page: number, limit: number, q?: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IZoneApi>(
    `/dns/getzones?page=${page}&limit=${limit}&q=${q || ""}`
   );
   dispatch(ZoneReducer.actions.setZone(data || {}));
  } catch (error) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     timestamp: Date.now(),
     type: "error",
     message: ""
    })
   );
  }
 };

export const getAllZoneNotPaged = (): AppAction<Promise<Array<IZoneList>>> => async (dispatch) => {
 try {
  const { data } = await ApiService<Array<IZoneList>>("/dns/list");
  return data;
 } catch (error) {
  dispatch(
   notificationReducer.actions.addAlert({
    id: generateUniqueId(),
    timestamp: Date.now(),
    type: "error",
    message: ""
   })
  );
  return [];
 }
};

export const deleteZone = async (isp_id: number, zone_id: number) => {
 try {
  await ApiService("/dns/deletezone", "POST", {
   zone_id: zone_id,
   isp_id: isp_id
  });
 } catch (error) {
  console.error("Error deleting zone:", error);
 }
};

export const deleteRecord =
 (id: number, isp_id: number, type: string): AppAction<Promise<boolean>> =>
 async (dispatch): Promise<boolean> => {
  try {
   await ApiService("/dns/deletednsrr", "POST", {
    id: id,
    isp_id: isp_id,
    type: type
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Deleting record...",
     timestamp: Date.now(),
     type: "success"
    })
   );
   return true;
  } catch (error) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Error: ${error || "unknown error"} `,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return false;
  }
 };

export const addRecords =
 (data: Record): AppAction<Promise<void>> =>
 async (dispatch): Promise<void> => {
  try {
   await ApiService("/dns/adddnsrr", "POST", {
    zone_id: data.zone_id,
    isp_id: data.isp_id,
    type: data.type,
    name: data.name,
    data: data.data,
    ttl: data.ttl,
    priority: data.aux
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Records added successfully",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Failed to add record",
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const editRecord =
 (
  id: number,
  ispId: number,
  type: string,
  name: string,
  data: string,
  ttl: number,
  priority: number
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService(`/dns/updatednsrr/${id}`, "PUT", {
    isp_id: ispId,
    type: type,
    name: name,
    data: data,
    ttl: ttl,
    priority: priority
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Record updating...",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Error: ${error || "unknown error"} `,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const updateZoneStatus =
 (isp_id: number, zone_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/dns/setzonestatus", "POST", {
    isp_id: isp_id,
    zone_id: zone_id
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Status Updated",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Error: ${error || "unknown error"} `,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };
