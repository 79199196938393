import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import AppModal from "components/shared/modal/AppModal";

import { postUpdateCronjob } from "redux/handlers/websitesHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { cronjobTableData } from "types/global/table";

type cronData = {
 command: string;
 run_min: string;
 run_hour: string;
 run_mday: string;
 run_month: string;
 run_wday: string;
};

const UpdateCronjob = ({
 rowData,
 closeMenu
}: {
 rowData: cronjobTableData;
 closeMenu: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const params = useParams<{ domain_id: string; section: string }>();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const onSubmit: SubmitHandler<cronData> = async (data: cronData) => {
  setLoading(true);
  await dispatch(
   postUpdateCronjob(
    rowData.id,
    rowData.isp_id,
    data.command,
    "url",
    rowData.parent_domain_id,
    data.run_min,
    data.run_hour,
    data.run_mday,
    data.run_month,
    data.run_wday,
    Number(params.domain_id)
   )
  );
  setLoading(false);
  handleClose();
  closeMenu();
 };

 const { control, formState, handleSubmit } = useForm({
  defaultValues: {
   command: rowData.command,
   run_min: rowData.run_min,
   run_hour: rowData.run_hour,
   run_mday: rowData.run_mday,
   run_month: rowData.run_month,
   run_wday: rowData.run_wday || ""
  }
 });

 return (
  <>
   <MenuItem onClick={handleOpen}>
    <FormattedMessage id="sites.cronjob.update" />
   </MenuItem>
   <AppModal
    open={openModal}
    close={handleClose}
    disabled={loading}
    title={intl.formatMessage({ id: "sites.cronjob.update" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}>
    <form onSubmit={handleSubmit(onSubmit)}>
     <Controller
      name="command"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
         e.stopPropagation();
        }}
        fullWidth={true}
        label={<FormattedMessage id="sites.cronjob.command" />}
        error={formState.isDirty && !!formState?.errors?.command}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
       />
      )}
     />
     <Controller
      name="run_min"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={<FormattedMessage id="sites.cronjob.run_min" />}
        error={formState.isDirty && !!formState?.errors?.run_min}
        helperText={<FormattedMessage id="sites.cronjob.run_minHelper" />}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
       />
      )}
     />
     <Controller
      name="run_hour"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={<FormattedMessage id="sites.cronjob.run_hour" />}
        error={formState.isDirty && !!formState?.errors?.run_hour}
        helperText={<FormattedMessage id="sites.cronjob.run_hourHelper" />}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
       />
      )}
     />
     <Controller
      name="run_wday"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={<FormattedMessage id="sites.cronjob.run_wday" />}
        error={formState.isDirty && !!formState?.errors?.run_wday}
        helperText={<FormattedMessage id="sites.cronjob.run_wdayHelper" />}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
       />
      )}
     />
     <Controller
      name="run_mday"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={<FormattedMessage id="sites.cronjob.run_mday" />}
        error={formState.isDirty && !!formState?.errors?.run_mday}
        helperText={<FormattedMessage id="sites.cronjob.run_mdayHelper" />}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
       />
      )}
     />
     <Controller
      name="run_month"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={<FormattedMessage id="sites.cronjob.run_month" />}
        error={formState.isDirty && !!formState?.errors?.run_month}
        helperText={<FormattedMessage id="sites.cronjob.run_monthHelper" />}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
       />
      )}
     />
    </form>
   </AppModal>
  </>
 );
};

export default UpdateCronjob;
