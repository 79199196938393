import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";

import ClickToCopy from "components/shared/clipboardCopy/ClickToCopy";
import IconSelector from "components/shared/images/IconSelector";

import { postUpdateNetwork } from "redux/handlers/addonsHandle";

import { getNetworksDataset } from "redux/selectors/networksSelector";

import { useAppDispatch } from "hooks/reduxHook";

import UpdateNetworkName from "../actions/UpdateNetworkName";

const NetworkManage = (): ReactElement => {
 const dispatch = useAppDispatch();
 const params = useParams<{ id: string; section: string }>();
 const networkData = useSelector(getNetworksDataset).find(
  (element) => element.id === Number(params.id)
 );

 const [expose, setExpose] = useState<boolean>(false);

 useEffect(() => {
  setExpose(networkData?.expose || false);
 }, [networkData]);

 const handleSwitchToggle = async (value: boolean) => {
  setExpose(value);
  await dispatch(postUpdateNetwork(networkData?.id || 0, networkData?.name || "", expose));
 };

 return (
  <Stack>
   <Grid container spacing={2}>
    <Grid xs={12}>
     <Paper elevation={0} sx={{ mt: 1, borderRadius: "10px", boxShadow: 0 }}>
      <Stack alignItems="center" justifyContent="flex-start" direction="row" px={2} pt={1}>
       <IconSelector icon="KeyIcon" props={{ fontSize: "medium", color: "secondary" }} />
       <Typography variant="h6" component="h2" ml={1}>
        <FormattedMessage id="addons.network.details" />{" "}
       </Typography>
      </Stack>

      <Stack
       direction={"row"}
       alignItems="center"
       mt={2}
       p={1}
       justifyContent="space-between"
       width="100%">
       <Stack>
        <Typography noWrap variant="subtitle1" px={1} fontWeight="bold">
         <FormattedMessage id="addons.network.name" />
        </Typography>
       </Stack>

       <Stack direction="row" alignItems="center" pr={2}>
        <ClickToCopy text={networkData?.name || ""}>
         <Typography variant="subtitle2">{networkData?.name || "N/A"}</Typography>
        </ClickToCopy>
        {networkData && <UpdateNetworkName network={networkData} />}
       </Stack>
      </Stack>

      <Divider />
      <Stack
       direction={"row"}
       alignItems="center"
       p={1}
       justifyContent="space-between"
       width="100%">
       <Stack>
        <Typography noWrap variant="subtitle1" px={1} fontWeight="bold">
         <FormattedMessage id="addons.network.expose" />
        </Typography>
       </Stack>

       <Stack direction="row" alignItems="center" pr={2}>
        <Switch checked={expose} onChange={(event) => handleSwitchToggle(event.target.checked)} />
       </Stack>
      </Stack>
     </Paper>
    </Grid>
   </Grid>
  </Stack>
 );
};

export default NetworkManage;
