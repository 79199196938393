import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import ClickToCopy from "components/shared/clipboardCopy/ClickToCopy";
import IconSelector from "components/shared/images/IconSelector";

import { getAllServersNotPaged } from "redux/handlers/serverHandler";

import { getFirewallsDataset } from "redux/selectors/firewallSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IAllServers } from "types/api/serversApiInterface";

import AttachServer from "../actions/AttachServer";
import DetachServer from "../actions/DetachServer";
import UpdateFirewallName from "../actions/UpdateFirewallName";

const FirewallManage = (): ReactElement => {
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));
 const params = useParams<{ id: string; section: string }>();
 const firewallData = useSelector(getFirewallsDataset).find(
  (element) => element.id === Number(params.id)
 );

 const [servers, setServers] = useState<Array<IAllServers>>([]);
 const [loading, setLoading] = useState<boolean>(true);

 useEffect(() => {
  (async () => {
   setLoading(true);
   setServers(await dispatch(getAllServersNotPaged()));
   setLoading(false);
  })();
 }, []);

 return (
  <Stack>
   <Grid container spacing={2}>
    <Grid xs={12}>
     <Paper elevation={0} sx={{ mt: 1, borderRadius: "10px", boxShadow: 0 }}>
      <Stack alignItems="center" justifyContent="flex-start" direction="row" px={2} pt={1}>
       <IconSelector icon="KeyIcon" props={{ fontSize: "medium", color: "secondary" }} />
       <Typography variant="h6" component="h2" ml={1}>
        <FormattedMessage id="addons.firewall.details" />{" "}
       </Typography>
      </Stack>

      <Stack
       direction={"row"}
       alignItems="center"
       mt={2}
       p={1}
       justifyContent="space-between"
       width="100%">
       <Stack>
        <Typography noWrap variant="subtitle1" px={1} fontWeight="bold">
         <FormattedMessage id="addons.firewall.name" />
        </Typography>
       </Stack>

       <Stack direction="row" alignItems="center" pr={2}>
        <ClickToCopy text={firewallData?.name || ""}>
         <Typography variant="subtitle2">{firewallData?.name || "N/A"}</Typography>
        </ClickToCopy>
        {firewallData && <UpdateFirewallName firewall={firewallData} />}
       </Stack>
      </Stack>
     </Paper>
    </Grid>
    <Grid xs={12}>
     <Paper elevation={0} sx={{ mt: 1, borderRadius: "10px", boxShadow: 0 }}>
      <Stack alignItems="center" justifyContent="flex-start" direction="row" px={2} pt={1}>
       <IconSelector icon="KeyIcon" props={{ fontSize: "medium", color: "secondary" }} />
       <Typography variant="h6" component="h2" ml={1}>
        <FormattedMessage id="addons.firewall.servers" />{" "}
       </Typography>
      </Stack>
      {loading ? (
       <CircularProgress />
      ) : (
       <Stack p={2}>
        {firewallData?.applied_to?.length === 0 ? (
         <Alert severity="info">
          <Stack spacing={1} alignItems="flex-start">
           <Typography>
            <FormattedMessage id="addons.firewall.noServerFound" />
           </Typography>
           <AttachServer
            id={firewallData.id}
            servers={servers.filter((srv) => !srv.firewall_id.includes(firewallData.id))}
           />
          </Stack>
         </Alert>
        ) : (
         <Grid container>
          <Grid xs={desktopViewPort ? 6 : 12}>
           <Stack direction="row" alignItems="center">
            <Stack
             direction="row"
             alignItems="center"
             px={2}
             py={1}
             width="40%"
             sx={{
              borderRadius: 2,
              border: "2px solid lightgrey"
             }}>
             <IconSelector icon="FirewallIcon" />
             <Typography>{firewallData?.name}</Typography>
            </Stack>
            <Stack width="60%">
             <LinearProgress
              sx={{ textAlign: "start" }}
              color="success"
              variant="buffer"
              value={0}
              valueBuffer={0}
             />
            </Stack>
           </Stack>
          </Grid>
          <Grid xs={desktopViewPort ? 6 : 12}>
           <Stack p={1} sx={{ borderRadius: 2, border: "2px solid lightgrey" }}>
            {firewallData?.applied_to && firewallData?.applied_to.length > 0 ? (
             <Stack spacing={1}>
              {firewallData.applied_to.map((element, index) => {
               return (
                <Stack key={`subnet-index-${index}`} direction="row" alignItems="center">
                 <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  px={2}
                  width="100%"
                  sx={{
                   borderRadius: 2,
                   border: "2px solid lightgray",
                   px: 2,
                   py: 1
                  }}>
                  <Stack alignItems={"flex-start"} spacing={1}>
                   <Typography variant="subtitle2">
                    {servers.find((serv) => serv.server_id === element.server_id)?.server_name}
                   </Typography>
                  </Stack>
                  <DetachServer id={firewallData.id} server={element.id} />
                 </Stack>
                </Stack>
               );
              })}
              <AttachServer
               id={firewallData.id || 0}
               servers={servers.filter((srv) => !srv.firewall_id.includes(firewallData.id))}
              />
             </Stack>
            ) : (
             <Alert severity="info">
              <Stack>
               <Typography>
                <FormattedMessage id="addons.firewall.noServerAttached" />
               </Typography>
               <AttachServer id={firewallData?.id || 0} servers={servers} />
              </Stack>
             </Alert>
            )}
           </Stack>
          </Grid>
         </Grid>
        )}
       </Stack>
      )}
     </Paper>
    </Grid>
   </Grid>
  </Stack>
 );
};

export default FirewallManage;
