import React, { ReactElement } from "react";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

import DomainsList from "components/domains/DomainsList";
import DomainsOrders from "components/domains/DomainsOrders";
import SectionsBreadCrumbs from "components/shared/breadCrumbs/SectionsBreadCrumbs";

const DomainListPage = (): ReactElement => {
 return (
  <Container maxWidth="xl">
   <Stack mt={2}>
    <SectionsBreadCrumbs
     links={[
      { name: "home", url: "/" },
      { name: "domains", url: "/domains" }
     ]}
    />
   </Stack>
   <Stack spacing={2}>
    <DomainsOrders />
    <DomainsList />
   </Stack>
  </Container>
 );
};

export default DomainListPage;
