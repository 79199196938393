export const steps = [
 {
  label: "server.add.fromBackup",
  description: "server.add.fromBackupMessage"
 },
 {
  label: "server.add.name",
  description: "server.add.nameDescription"
 }
];
