import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { IJobqueueApi } from "types/api/jobqueueApiInterface";

const initialState: IJobqueueApi = { dataset: [], totalCount: 0 };

const JobqueueReducer = createSlice({
 name: "Jobqueue",
 initialState,
 reducers: {
  setJobqueue: (state, { payload }: PayloadAction<IJobqueueApi>) => {
   return payload;
  },
  resetJobqueue: () => {
   return initialState;
  }
 }
});

export default JobqueueReducer;
