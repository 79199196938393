import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { IDockerOrdersAPI } from "types/api/dockerApiInterface";

const initialState: IDockerOrdersAPI = { dataset: [], totalCount: 0 };

const DockerOrdersReducer = createSlice({
 name: "Dockerorder",
 initialState,
 reducers: {
  setDockerOrders: (state, { payload }: PayloadAction<IDockerOrdersAPI>) => {
   return payload;
  },
  resetDockerOrders: () => {
   return initialState;
  }
 }
});

export default DockerOrdersReducer;
