import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

import { Alert, Command, INotification } from "types/redux/notificationInterfaces";

export const getNotifications = (state: AppState) => state.notification;

// selector return the number of alerts on notification state
export const getAlertsNumber = createSelector(
 getNotifications,
 (notification: INotification | undefined): number => {
  return notification?.alerts?.length || 0;
 }
);

export const getLatestAlert = createSelector(
 getNotifications,
 (notification: INotification | undefined): Alert | undefined => {
  return notification?.alerts?.[0];
 }
);

export const getCommandsNumber = createSelector(
 getNotifications,
 (notification: INotification | undefined): number => {
  return notification?.commands?.length || 0;
 }
);

export const getCommands = createSelector(
 getNotifications,
 (notification: INotification | undefined): Command[] => {
  return notification?.commands || [];
 }
);
