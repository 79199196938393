import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { isNil } from "ramda";

import AddIcon from "@mui/icons-material/Add";

import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { ipRegex } from "constants/regexp";

import AppModal from "components/shared/modal/AppModal";

import { postCreateIpTarget, postCreateServerTarget } from "redux/handlers/addonsHandle";
import { getAllServersNotPaged } from "redux/handlers/serverHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IAllServers } from "types/api/serversApiInterface";

const CreateTarget = ({ id }: { id: number }): ReactElement => {
 const dispatch = useAppDispatch();
 const intl = useIntl();

 const [servers, setServers] = useState<Array<IAllServers>>([]);
 const [selectedServer, setSelectedServer] = useState<number>(0);
 const [selectedType, setSelectedType] = useState<string>("server");
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [ip, setIp] = useState<string>("");
 const [usePrivateIp, setUsePrivateIp] = useState<boolean>(false);

 useEffect(() => {
  (async () => {
   setServers(await dispatch(getAllServersNotPaged()));
  })();
 }, []);

 const handleOpen = async () => {
  setSelectedServer(0);
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const checkDisabled = (): boolean => {
  return (
   (selectedType === "server" && selectedServer === 0) ||
   (selectedType === "ip" && (ip === "" || !ipRegex.test(ip)))
  );
 };

 const handleConfirm = async () => {
  setLoading(true);
  if (selectedType === "server")
   await dispatch(postCreateServerTarget(id, selectedServer, usePrivateIp));
  if (selectedType === "ip") await dispatch(postCreateIpTarget(id, ip, usePrivateIp));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Button onClick={handleOpen} variant="kxActionButton" endIcon={<AddIcon />}>
    <FormattedMessage id="addons.loadBalancer.createTarget" />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "addons.loadBalancer.createTarget" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading || checkDisabled()}>
    <Stack spacing={2}>
     <FormControl>
      <InputLabel>
       <FormattedMessage id="addons.loadBalancer.graphType" />
      </InputLabel>
      <Select
       value={selectedType}
       label={<FormattedMessage id="addons.loadBalancer.graphType" />}
       onChange={(e) => setSelectedType(e.target.value)}>
       <MenuItem key="server-key" value="server">
        Server
       </MenuItem>
       <MenuItem key="ip-key" value="ip">
        IP
       </MenuItem>
      </Select>
     </FormControl>
     {selectedType === "server" && (
      <Autocomplete
       fullWidth={true}
       autoHighlight
       sx={{ my: 2 }}
       onChange={(e, value) =>
        isNil(value) ? setSelectedServer(0) : setSelectedServer(value.server_id)
       }
       options={servers.map((element) => {
        return {
         server_id: element.server_id,
         label: element.server_name
        };
       })}
       renderInput={(params) => (
        <TextField
         {...params}
         label={<FormattedMessage id="addons.loadBalancer.targetServer" />}
         InputLabelProps={{ shrink: true }}
        />
       )}
      />
     )}
     {selectedType === "ip" && (
      <TextField
       label={<FormattedMessage id="addons.loadBalancer.targetIp" />}
       value={ip}
       fullWidth
       onChange={(e) => setIp(e.target.value)}
       error={!ipRegex.test(ip)}
       helperText={!ipRegex.test(ip) && <FormattedMessage id="addons.loadBalancer.targetIsNotIp" />}
       InputLabelProps={{ shrink: true }}
      />
     )}
     <FormControlLabel
      control={
       <Checkbox
        checked={usePrivateIp}
        onChange={(e, checked) => {
         setUsePrivateIp(checked);
        }}
       />
      }
      label={intl.formatMessage({ id: "addons.loadBalancer.usePrivateIp" })}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default CreateTarget;
