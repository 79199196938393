import React, { ReactElement, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import { isNil } from "ramda";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postLinkServerToVolume } from "redux/handlers/addonsHandle";
import { getAllServersNotPaged } from "redux/handlers/serverHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IAllServers } from "types/api/serversApiInterface";

const AttachVolume = ({
 id,
 datacenter,
 closeMenu
}: {
 id: number;
 datacenter: string;
 closeMenu: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [servers, setServers] = useState<Array<IAllServers>>([]);
 const [loading, setLoading] = useState<boolean>(false);

 useEffect(() => {
  (async () => {
   setServers(await dispatch(getAllServersNotPaged()));
  })();
 }, []);

 const handleOpen = () => {
  reset();
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const { control, reset, handleSubmit, formState, watch, setValue } = useForm({
  defaultValues: {
   server_id: 0
  }
 });

 const onSubmit: SubmitHandler<{ server_id: number }> = async (data: { server_id: number }) => {
  setLoading(true);
  await dispatch(postLinkServerToVolume(id, data.server_id));
  handleClose();
  setLoading(false);
  closeMenu();
 };

 return (
  <>
   <MenuItem onClick={handleOpen}>
    <FormattedMessage id="volumes.attachVolume" />
   </MenuItem>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "volumes.attachVolume" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={loading || watch("server_id") === 0}>
    <Stack spacing={2}>
     <Alert severity="info">
      <Typography>
       <FormattedMessage id="volumes.selectServerMessage" />
      </Typography>
     </Alert>
     <Controller
      name="server_id"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) =>
         isNil(value) ? setValue("server_id", 0) : setValue("server_id", value.server_id)
        }
        options={servers
         .filter((element) => element.datacenter_location_name === datacenter)
         .map((element) => {
          return {
           server_id: element.server_id,
           label: element.server_name
          };
         })}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
           e.stopPropagation();
          }}
          label={<FormattedMessage id="volumes.selectServer" />}
          error={formState.isDirty && !!formState?.errors?.server_id}
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
      )}
     />
     {servers.filter((element) => element.datacenter_location_name === datacenter).length === 0 && (
      <Alert severity="error">
       <Typography>
        <FormattedMessage id="volumes.noServerAvailable" />
       </Typography>
      </Alert>
     )}
    </Stack>
   </AppModal>
  </>
 );
};

export default AttachVolume;
