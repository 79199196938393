import React, { ReactElement, useEffect, useState } from "react";

import Stack from "@mui/material/Stack";

import Volumes from "components/addons/volumes/Volumes";
import SkeletonAddons from "components/shared/skeletons/SkeletonAddons";

import { getAllVolumes } from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

const VolumePage = (): ReactElement => {
 const dispatch = useAppDispatch();

 const [isLoading, setIsLoading] = useState<boolean>(true);

 useEffect(() => {
  (async () => {
   await dispatch(getAllVolumes(0, 10));
   setIsLoading(false);
  })();
 }, []);

 return <Stack>{isLoading ? <SkeletonAddons id="addons.volumes" /> : <Volumes />}</Stack>;
};

export default VolumePage;
