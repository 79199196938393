import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

import { ICustomersDataset } from "types/api/customersApiInterface";

export const getCustomers = (state: AppState) => state.customers;

export const getCustomersNumber = createSelector(getCustomers, (customers) => {
 return customers?.totalCount;
});

export const getCustomersDataSet = createSelector(getCustomers, (customers) => {
 return (
  customers?.dataset?.map((customer: ICustomersDataset) => {
   return {
    id: customer.id,
    userid: customer.userid,
    contacttype: customer.contacttype,
    fiscalcode: customer.fiscalcode,
    vat: customer.vat,
    firstname: customer.firstname,
    lastname: customer.lastname,
    company: customer.company,
    email: customer.email,
    phonenumber: customer.phonenumber,
    street: customer.street,
    city: customer.city,
    countrycode: customer.countrycode,
    postalcode: customer.postalcode,
    countrystate: customer.countrystate,
    placeofbirth: customer.placeofbirth
   };
  }) || []
 );
});
