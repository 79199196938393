import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { deleteIsp } from "redux/handlers/ispHandler";

import { useAppDispatch } from "hooks/reduxHook";

const DeleteIsp = ({
 id,
 reloadData,
 closeMenu
}: {
 id: number;
 reloadData?: () => void;
 closeMenu: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 useEffect(() => {
  handleOpen();
 }, []);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(deleteIsp(id));
  setLoading(false);
  reloadData && reloadData();
  handleClose();
  closeMenu();
 };

 return (
  <AppModal
   open={openModal}
   close={handleClose}
   title={intl.formatMessage({ id: "configurator.delete.isp" })}
   handleClose={handleClose}
   disabled={loading}
   handleConfirm={handleConfirm}>
   <Typography>
    <FormattedMessage id="configurator.delete.isp.message" />
   </Typography>
  </AppModal>
 );
};

export default DeleteIsp;
