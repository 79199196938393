import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postRebootBaremetal } from "redux/handlers/baremetalHandle";

import { useAppDispatch } from "hooks/reduxHook";

const RebootBaremetal = ({
 id,
 closeMenu
}: {
 id: number;
 closeMenu: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpenModal = () => setOpenModal(true);
 const handleCloseModal = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postRebootBaremetal(id));
  setLoading(false);
  handleCloseModal();
  closeMenu();
 };

 return (
  <>
   <MenuItem onClick={() => handleOpenModal()}>
    <FormattedMessage id="baremetal.rebootBaremetal" />
   </MenuItem>
   <AppModal
    open={openModal}
    close={handleCloseModal}
    title={intl.formatMessage({ id: "baremetal.rebootBaremetal" })}
    handleClose={handleCloseModal}
    handleConfirm={handleConfirm}
    disabled={loading}>
    <Typography>
     <FormattedMessage id="baremetal.rebootBaremetalMessage" />
    </Typography>
   </AppModal>
  </>
 );
};

export default RebootBaremetal;
