import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { isNil } from "ramda";

import AddIcon from "@mui/icons-material/Add";

import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postAddAliasForward, postRetriveAllMailboxesList } from "redux/handlers/mailsHandler";

import { getMailsList } from "redux/selectors/mailsSelector";

import { useAppDispatch } from "hooks/reduxHook";

type aliasForm = {
 source: string;
 destination: string;
 active: boolean;
 allowSend: boolean;
 allowGreylist: boolean;
};

const AddAlias = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const params = useParams<{ mail_id: string; section: string }>();
 const mailList = useSelector(getMailsList);
 const maildetails = mailList.find((element) => element.id === Number(params.mail_id));

 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [mailboxes, setMailboxes] = useState<Array<string>>([]);

 const handleOpen = async () => {
  reset();
  setMailboxes(await dispatch(postRetriveAllMailboxesList(maildetails?.isp_id || 0)));
  setOpenModal(true);
  setIsLoading(false);
 };
 const handleClose = () => {
  setOpenModal(false);
 };

 const { control, formState, reset, handleSubmit, watch, setValue } = useForm({
  defaultValues: {
   source: "",
   destination: "",
   active: false,
   allowSend: false,
   allowGreylist: false
  }
 });

 const onSubmit: SubmitHandler<aliasForm> = async (data: aliasForm) => {
  setIsLoading(true);
  await dispatch(
   postAddAliasForward(
    maildetails?.id || 0,
    `${data.source}@${maildetails?.domain}`,
    data.destination,
    "alias",
    data.active,
    data.allowSend,
    data.allowGreylist
   )
  );
  setIsLoading(false);
  setOpenModal(false);
 };

 const checkData = (): boolean => {
  return watch("source") === "" || watch("destination") === "";
 };

 return (
  <>
   <Button variant="kxActionButton" endIcon={<AddIcon />} onClick={handleOpen}>
    <FormattedMessage id="mails.add.alias" />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    loading={isLoading}
    title={intl.formatMessage({ id: "mails.add.alias" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={isLoading || checkData()}>
    <form onSubmit={handleSubmit(onSubmit)}>
     <Controller
      name="source"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={<FormattedMessage id="mails.alias.source" />}
        error={formState.isDirty && !!formState?.errors?.source}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        InputProps={{
         endAdornment: (
          <InputAdornment position="end">
           <Typography>{`@${maildetails?.domain}`}</Typography>
          </InputAdornment>
         )
        }}
       />
      )}
     />
     <Controller
      name="destination"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) =>
         isNil(value) ? setValue("destination", "") : setValue("destination", value)
        }
        options={mailboxes}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
           e.stopPropagation();
          }}
          label={<FormattedMessage id="mails.alias.selectDestination" />}
          error={formState.isDirty && !!formState?.errors?.destination}
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
      )}
     />
     <Stack>
      <Controller
       name="active"
       control={control}
       render={({ field }) => (
        <FormControlLabel
         {...field}
         control={
          <Checkbox
           checked={watch("active")}
           onChange={(e) => {
            setValue("active", e.target.checked);
           }}
          />
         }
         label={intl.formatMessage({ id: "mails.alias.active" })}
        />
       )}
      />
      <Controller
       name="allowSend"
       control={control}
       render={({ field }) => (
        <FormControlLabel
         {...field}
         control={
          <Checkbox
           checked={watch("allowSend")}
           onChange={(e) => {
            setValue("allowSend", e.target.checked);
           }}
          />
         }
         label={intl.formatMessage({ id: "mails.alias.allowSend" })}
        />
       )}
      />
      <Controller
       name="allowGreylist"
       control={control}
       render={({ field }) => (
        <FormControlLabel
         {...field}
         control={
          <Checkbox
           checked={watch("allowGreylist")}
           onChange={(e) => {
            setValue("allowGreylist", e.target.checked);
           }}
          />
         }
         label={intl.formatMessage({ id: "mails.alias.allowGreylist" })}
        />
       )}
      />
     </Stack>
    </form>
   </AppModal>
  </>
 );
};

export default AddAlias;
