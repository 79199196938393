import React, { ReactElement, useEffect, useState } from "react";

import Stack from "@mui/material/Stack";

import Networks from "components/addons/networks/Networks";
import SkeletonAddons from "components/shared/skeletons/SkeletonAddons";

import { getAllNetworks } from "redux/handlers/addonsHandle";
import { getAllServers } from "redux/handlers/serverHandler";

import { useAppDispatch } from "hooks/reduxHook";

const NetworkPage = (): ReactElement => {
 const dispatch = useAppDispatch();

 const [isLoading, setIsLoading] = useState<boolean>(true);

 useEffect(() => {
  (async () => {
   await dispatch(getAllNetworks(0, 10));
   await dispatch(getAllServers(0, 10));
   setIsLoading(false);
  })();
 }, []);

 return <Stack>{isLoading ? <SkeletonAddons id="addons.network" /> : <Networks />}</Stack>;
};

export default NetworkPage;
