import { io, Socket } from "socket.io-client";

// "undefined" means the URL will be computed from the `window.location` object
const URL: string =
 // process.env.NODE_ENV === "production" ? "http://localhost:3004" : "http://localhost:3004";
 process.env.NODE_ENV === "production"
  ? "https://konsolex-websocket.onthecloud.srl"
  : "https://konsolex-websocket.onthecloud.srl";

export const createSocket = (): Socket =>
 io(URL, {
  extraHeaders: {
   // @ts-ignore
   jwt: localStorage.getItem("jwt")
  }
 });
