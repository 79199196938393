import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { getFirewallsDataset } from "redux/selectors/firewallSelector";

const FirewallUpperPage = (): ReactElement => {
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));
 const params = useParams<{ id: string; section: string }>();
 const firewallData = useSelector(getFirewallsDataset).find(
  (element) => element.id === Number(params.id)
 );

 return (
  <Stack
   mt={desktopViewPort ? 5 : 0}
   justifyContent="space-between"
   direction={desktopViewPort ? "row" : "column"}>
   <Stack>
    <Stack>
     <Typography fontWeight="bold" fontSize={16} textAlign="left" textTransform="uppercase">
      {firewallData?.name || "N/A"}
     </Typography>
    </Stack>
   </Stack>
  </Stack>
 );
};

export default FirewallUpperPage;
