import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const MailPerformance = ({
 domain,
 performance
}: {
 domain: string;
 performance: Array<{ domain: string; disk: string }>;
}) => {
 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [performanceData, setPerformanceData] = useState<string>("0 KB");

 useEffect(() => {
  setPerformanceData(performance.find((element) => element.domain === domain)?.disk || "0 KB");
  setIsLoading(false);
 }, [performance, domain]);

 return isLoading ? (
  <Stack direction="row" alignItems="center" justifyContent="space-around">
   <Typography variant="caption">Obtaining data...</Typography>
  </Stack>
 ) : (
  <>
   <Typography
    sx={{ ml: 2 }}
    variant="caption"
    fontWeight="bold"
    textTransform="uppercase"
    fontSize={11}>
    <FormattedMessage id="sites.ssd" />:{` ${performanceData}`}
   </Typography>
  </>
 );
};

export default MailPerformance;
