import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postDeleteSnapshot } from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

const DeleteSnapshot = ({
 id,
 disabled,
 closeMenu
}: {
 id: number;
 disabled: boolean;
 closeMenu: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postDeleteSnapshot(id));
  handleClose();
  setLoading(false);
  closeMenu();
 };

 return (
  <>
   <MenuItem disabled={disabled} onClick={() => handleOpen()}>
    <FormattedMessage id="addons.snapshot.delete" />
   </MenuItem>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "addons.snapshot.delete" })}
    handleClose={handleClose}
    disabled={loading}
    handleConfirm={handleConfirm}>
    <Typography>
     <FormattedMessage id="addons.snapshot.deleteMessage" />
    </Typography>
   </AppModal>
  </>
 );
};

export default DeleteSnapshot;
