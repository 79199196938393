import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { emailValidation } from "constants/regexp";

const EmailSettings = ({
 channel,
 mails,
 updateChannel,
 updateMails
}: {
 mails: Array<string>;
 channel: boolean;
 updateChannel: (channel: boolean) => void;
 updateMails: (mails: Array<string>) => void;
}): ReactElement => {
 const intl = useIntl();

 const [newMail, setNewMail] = useState<string>("");
 const [error, setError] = useState<string>("");

 const renderError = () => {
  switch (error) {
   case "length":
    return "profile.settings.lengthError";
   case "format":
    return "profile.settings.formatError";
   case "void":
    return "profile.settings.voidError";
   default:
    return "";
  }
 };

 const handleDeleteMail = (mailToDelete: string) => {
  if (mails.length > 1) {
   const updatedList = mails.filter((item) => item !== mailToDelete);
   setError("");
   updateMails(updatedList);
  } else {
   setError("length");
  }
 };

 const handleKeyDown = (event: any) => {
  if (event.key === "Enter") {
   if (newMail !== "" && !mails.includes(newMail)) {
    if (emailValidation.test(newMail)) {
     let newData = mails;
     newData.push(newMail);
     updateMails(newData);
     setNewMail("");
     setError("");
    } else {
     setError("format");
    }
   } else {
    setError("void");
   }
  }
 };

 return (
  <Accordion>
   <AccordionSummary expandIcon={<ExpandMoreIcon />}>Impostazioni canale Mail</AccordionSummary>
   <AccordionDetails>
    <Stack>
     <Stack alignItems="flex-start" spacing={1} width="100%">
      <Typography>Mail di invio:</Typography>
      <Stack direction="row" spacing={1}>
       {mails.map((element, index) => {
        return (
         <Chip
          key={`mail-chip-${index}`}
          disabled={!channel}
          label={element}
          size="small"
          onClick={() => handleDeleteMail(element)}
          onDelete={() => handleDeleteMail(element)}
         />
        );
       })}
      </Stack>
      <TextField
       label={intl.formatMessage({ id: "profile.settings.channelMail" })}
       variant="standard"
       fullWidth
       disabled={!channel}
       value={newMail}
       onChange={(e) => setNewMail(e.target.value)}
       onKeyDown={handleKeyDown}
       error={error !== ""}
       helperText={error !== "" ? <FormattedMessage id={renderError()} /> : ""}
      />
     </Stack>
     <Stack>
      <FormControlLabel
       control={
        <Checkbox
         checked={channel}
         onChange={(e) => {
          updateChannel(!channel);
         }}
        />
       }
       label={intl.formatMessage({ id: "profile.settings.setChannelActive" })}
      />
     </Stack>
    </Stack>
   </AccordionDetails>
  </Accordion>
 );
};

export default EmailSettings;
