import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

import { getMailsList } from "redux/selectors/mailsSelector";

import { IMailbox } from "types/api/mailApiInterface";

import AddMailbox from "../../actions/AddMailbox";

import MailboxesContent from "./MailboxesContent";

const MailMailboxes = (): ReactElement => {
 const mailList = useSelector(getMailsList);
 const params = useParams<{ mail_id: string; section: string }>();
 const maildetails = mailList.find((element) => element.id === Number(params.mail_id));

 const [mailboxes, setMailboxes] = useState<Array<IMailbox>>(maildetails?.mailbox || []);

 useEffect(() => {
  if (maildetails?.mailbox) {
   setMailboxes(maildetails?.mailbox || []);
  }
 }, [maildetails]);

 return (
  <>
   <Stack>
    {mailboxes.length !== 0 ? (
     <MailboxesContent domain={maildetails?.domain || ""} mailboxes={mailboxes} />
    ) : (
     <>
      <Divider />
      <Stack p={2}>
       <Alert severity="info">
        <FormattedMessage id="mails.noMailboxFound" />
       </Alert>
      </Stack>
      <Divider />
      <AddMailbox type="text" />
     </>
    )}
   </Stack>
  </>
 );
};

export default MailMailboxes;
