import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postAddBlacklist } from "redux/handlers/gatewayHandler";

import { useAppDispatch } from "hooks/reduxHook";

const AddBlacklist = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [blacklistType, setBlacklistType] = useState<string>("Email");
 const [blacklistName, setBlacklistName] = useState<string>("");

 const handleOpen = () => {
  setBlacklistType("Email");
  setBlacklistName("");
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setIsLoading(true);
  await dispatch(postAddBlacklist(id, blacklistType, blacklistName));
  handleClose();
  setIsLoading(false);
 };

 return (
  <Stack direction="row">
   <Button onClick={handleOpen} variant="kxActionButton">
    <FormattedMessage id="gateway.addBlacklist" />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    handleConfirm={handleConfirm}
    title={intl.formatMessage({ id: "gateway.addBlacklist" })}>
    <Stack spacing={2}>
     <Alert severity="info">
      <Typography>
       <FormattedMessage id="gateway.addBlacklist.blacklistMessage" />
      </Typography>
     </Alert>
     <Stack direction="row" spacing={2}>
      <FormControl fullWidth>
       <InputLabel>
        <FormattedMessage id="gateway.addBlacklist.type" />
       </InputLabel>
       <Select
        value={blacklistType || ""}
        label={<FormattedMessage id="gateway.addBlacklist.type" />}
        onChange={(e) => setBlacklistType(e.target.value)}>
        <MenuItem key="protocol-mail" value={"Mail address"}>
         Mail Address
        </MenuItem>
        <MenuItem key="protocol-dom" value={"Domain"}>
         Domain
        </MenuItem>
        <MenuItem key="protocol-add" value={"IP-Address"}>
         IP-Address
        </MenuItem>
        <MenuItem key="protocol-net" value={"IP-Network"}>
         IP-Network
        </MenuItem>
       </Select>
      </FormControl>
     </Stack>
     <TextField
      autoComplete="off"
      label={intl.formatMessage({ id: "gateway.addBlacklist.address" })}
      InputLabelProps={{ shrink: true }}
      onChange={({ currentTarget }) => setBlacklistName(currentTarget.value)}
     />
    </Stack>
   </AppModal>
  </Stack>
 );
};

export default AddBlacklist;
