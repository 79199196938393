import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { getProxySites, postDisableProxy } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

const DeleteProxyContainer = ({
 id,
 name,
 proxy_id,
 type
}: {
 id: number;
 name: string;
 proxy_id: string;
 type: string;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [siteNumber, setSiteNumber] = useState<number>(0);

 const handleOpen = async () => {
  if (type === "proxy_site") {
   const sites = await dispatch(getProxySites(proxy_id, 0, 10));
   setSiteNumber(sites.totalCount);
  }
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postDisableProxy(id, name));
  handleClose();
  setLoading(false);
 };

 return (
  <>
   <Tooltip title={<FormattedMessage id="docker.proxy.deleteProxyContainer" />}>
    <IconButton onClick={() => handleOpen()}>
     <IconSelector icon="DeleteIcon" />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.proxy.deleteProxyContainer" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading || siteNumber > 0}>
    {siteNumber > 0 ? (
     <Alert severity="warning">
      <FormattedMessage id="docker.proxy.cannotDeleteProxyContainerMessage" />
     </Alert>
    ) : (
     <Typography>
      <FormattedMessage id="docker.proxy.deleteProxyContainerMessage" />
     </Typography>
    )}
   </AppModal>
  </>
 );
};

export default DeleteProxyContainer;
