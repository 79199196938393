/* eslint-disable react/style-prop-object */
import React, { ReactElement, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import DvrIcon from "@mui/icons-material/Dvr";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { loadBalancerSteps } from "constants/addons";

import SectionsBreadCrumbs from "components/shared/breadCrumbs/SectionsBreadCrumbs";
import AppModal from "components/shared/modal/AppModal";

import {
 getAllLoadBalancersPrices,
 postCheckBalancerName,
 postCreateLoadBalancer
} from "redux/handlers/addonsHandle";
import { fetchLocationsList } from "redux/handlers/hetznerHandler";

import { getUserBalance } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { ILoadBalancerType } from "types/api/addonsApiInterface";
import { ILocationAPI } from "types/api/hetznerApi";

import BalancerTypeTable from "./section/BalancerTypeTable";

const LoadBalancerWizard = (): ReactElement => {
 const intl = useIntl();
 const navigate = useNavigate();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const userBalance = useSelector(getUserBalance);

 const [activeStep, setActiveStep] = useState<number>(0);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [nameChecked, setNameChecked] = useState<boolean>(false);
 const [stepLoading, setStepLoading] = useState<boolean>(false);
 const [firstCheck, setFirstCheck] = useState<boolean>(false);
 const [balancerTypes, setBalancerTypes] = useState<Array<ILoadBalancerType>>([]);
 const [loading, setLoading] = useState<boolean>(false);
 const [locationList, setLocationList] = useState<Array<ILocationAPI>>([]);

 useEffect(() => {
  (async () => {
   setLocationList(await dispatch(fetchLocationsList()));
   setBalancerTypes(await dispatch(getAllLoadBalancersPrices()));
   setIsLoading(false);
  })();
 }, []);

 const { control, formState, reset, watch, getValues, setValue } = useForm({
  defaultValues: {
   name: "",
   type: "",
   amountId: 0
  }
 });

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);
 const handleReset = () => {
  reset();
  setNameChecked(false);
  setFirstCheck(false);
  setActiveStep(0);
 };

 const handleNext = () => {
  setActiveStep((prevActiveStep) => prevActiveStep + 1);
 };

 const handleBack = () => {
  setActiveStep((prevActiveStep) => prevActiveStep - 1);
 };

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(
   postCreateLoadBalancer(
    getValues("name"),
    getValues("type"),
    balancerTypes.find((element) => element.id === getValues("amountId"))?.amount || 0,
    balancerTypes.find((element) => element.id === getValues("amountId"))?.name || "",
    locationList.find(
     (element) =>
      element.name ===
      (balancerTypes.find((element) => element.id === getValues("amountId"))?.prices_location || "")
    )?.id || 0
   )
  );
  setLoading(false);
  handleClose();
  handleReset();
  navigate("/addons/loadBalancer");
 };

 const handleCheckName = async () => {
  setStepLoading(true);
  setNameChecked(await dispatch(postCheckBalancerName(getValues("name"))));
  setFirstCheck(true);
  setStepLoading(false);
 };

 const renderServerChecked = () => {
  if (nameChecked) {
   return (
    <Alert severity="success">
     <FormattedMessage id="addons.loadBalancer.add.nameAvailable" />
    </Alert>
   );
  } else {
   return (
    <Alert severity="error">
     <FormattedMessage id="addons.loadBalancer.add.nameNotAvailable" />
    </Alert>
   );
  }
 };

 const renderStepContent = (index: number) => {
  switch (index) {
   case 0:
    return (
     <Controller
      name="name"
      control={control}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        onChange={(e) => {
         setValue("name", e.target.value);
         setNameChecked(false);
         setFirstCheck(false);
        }}
        label={intl.formatMessage({ id: "addons.loadBalancer.name" })}
        error={formState.isDirty && !!formState?.errors?.name}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="new-password"
       />
      )}
     />
    );
   case 1:
    return (
     <Controller
      name="type"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) => setValue("type", value || "")}
        options={["round_robin", "least_connections"]}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          error={formState.isDirty && !!formState?.errors?.type}
          label={<FormattedMessage id="addons.loadBalancer.algorithm" />}
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
      )}
     />
    );
   case 2:
    return (
     <BalancerTypeTable
      balancerList={balancerTypes}
      selectBalancer={(id: number) => setValue("amountId", id)}
     />
    );
  }
 };

 const disableNextButton = (index: number) => {
  switch (index) {
   case 0:
    return watch("name").length === 0;
   case 1:
    return watch("type").length === 0;
   default:
    return false;
  }
 };

 const checkDisableConfirm = () => {
  return watch("name") === "" || watch("type") === "" || watch("amountId") === 0;
 };

 const handleNextPart = (index: number) => {
  if (index === 0) {
   if (nameChecked) {
    handleNext();
   } else {
    handleCheckName();
   }
  } else {
   handleNext();
  }
 };

 return !isLoading ? (
  <Container maxWidth="xl">
   <Stack mt={2}>
    <SectionsBreadCrumbs
     links={[
      { name: "home", url: "/" },
      { name: "addons", url: "/addons/cloudbox" },
      { name: "loadBalancer", url: "/addons/loadBalancer" }
     ]}
    />
   </Stack>
   <Stack spacing={2}>
    <Stack alignItems="center" mt={5} p={3}>
     <DvrIcon fontSize="large" />
     <Typography>
      <FormattedMessage id={"addons.loadBalancer.add.addNewLoadBalancer"} />
     </Typography>
    </Stack>
    <Stack direction={desktopViewPort ? "row" : "column"} justifyContent="center">
     <Box
      sx={{ maxWidth: desktopViewPort ? 1000 : "auto", minWidth: desktopViewPort ? 500 : "auto" }}
      m={10}>
      <Stepper activeStep={activeStep} orientation="vertical">
       {loadBalancerSteps.map((step, index) => (
        <Step key={step.label}>
         <StepLabel>
          <FormattedMessage id={step.label} />
         </StepLabel>
         {!stepLoading ? (
          <StepContent>
           <Stack spacing={2}>
            <Alert severity="info">
             <Typography>
              <FormattedMessage id={step.description} />
             </Typography>
            </Alert>
            {renderStepContent(index)}
            {index === 0 && firstCheck && renderServerChecked()}
           </Stack>
           <Box sx={{ mb: 2 }}>
            <div>
             {index !== loadBalancerSteps.length - 1 ? (
              <Button
               disabled={disableNextButton(index)}
               variant="contained"
               onClick={() => handleNextPart(index)}
               sx={{ mt: 1, mr: 1 }}>
               {index === 0 && !nameChecked ? (
                <FormattedMessage id="app.wizard.checkName" />
               ) : (
                <FormattedMessage id="app.wizard.continue" />
               )}
              </Button>
             ) : (
              <></>
             )}
             {index === 2 && (
              <>
               <Button
                sx={{ mt: 1, mr: 1 }}
                variant="contained"
                disabled={nameChecked ? checkDisableConfirm() : nameChecked}
                onClick={() => (nameChecked ? handleOpen() : handleCheckName())}>
                <FormattedMessage id="app.wizard.confirm" />
               </Button>
               <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                <FormattedMessage id="app.wizard.reset" />
               </Button>
              </>
             )}
             <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
              <FormattedMessage id="app.wizard.back" />
             </Button>
            </div>
           </Box>
          </StepContent>
         ) : (
          <StepContent>
           <Stack spacing={2}>
            <Alert severity="info">
             <Typography>
              <FormattedMessage id="server.checkExecution" />
             </Typography>
            </Alert>
            {renderStepContent(index)}
            <CircularProgress />
           </Stack>
          </StepContent>
         )}
        </Step>
       ))}
      </Stepper>
     </Box>
     <Paper
      elevation={0}
      sx={{
       m: 5,
       p: 2,
       maxHeight: 330,
       position: "sticky",
       top: 30,
       minWidth: desktopViewPort ? 400 : "auto",
       borderRadius: "10px",
       boxShadow: 0
      }}>
      <Stack mb={2}>
       <Typography variant="h5" fontWeight="bold">
        <FormattedMessage id="addons.loadBalancer.add.recap" />
       </Typography>
      </Stack>
      <Divider textAlign="left">
       <Typography variant="overline">
        <FormattedMessage id="addons.loadBalancer.add.balancerData" />
       </Typography>
      </Divider>
      <Stack direction="row" spacing={2}>
       <Typography minWidth={120} textAlign="start" fontWeight="bold">
        <FormattedMessage id="addons.loadBalancer.name" />:
       </Typography>
       <Typography noWrap>{watch("name")}</Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
       <Typography minWidth={120} textAlign="start" fontWeight="bold">
        <FormattedMessage id="addons.loadBalancer.algorithm" />:
       </Typography>
       <Typography noWrap>{watch("type")}</Typography>
      </Stack>
      <Divider textAlign="left" sx={{ mt: 2 }}>
       <Typography variant="overline">
        <FormattedMessage id="addons.loadBalancer.specific" />
       </Typography>
      </Divider>
      <Stack direction="row" spacing={2}>
       <Typography minWidth={120} textAlign="start" fontWeight="bold">
        <FormattedMessage id="addons.loadBalancer.maxTargets" />:
       </Typography>
       <Typography noWrap>
        {balancerTypes.find((element) => element.id === watch("amountId"))?.max_targets || 0}
       </Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
       <Typography minWidth={120} textAlign="start" fontWeight="bold">
        <FormattedMessage id="addons.loadBalancer.maxServices" />:
       </Typography>
       <Typography noWrap>
        {balancerTypes.find((element) => element.id === watch("amountId"))?.max_services || 0}
       </Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
       <Typography minWidth={120} textAlign="start" fontWeight="bold">
        <FormattedMessage id="addons.loadBalancer.type" />:
       </Typography>
       <Typography noWrap>
        {balancerTypes.find((element) => element.id === watch("amountId"))?.name || ""}
       </Typography>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
       <Typography minWidth={120} textAlign="start" fontWeight="bold">
        <FormattedMessage id="addons.loadBalancer.add.totalPrice" />
       </Typography>
       <Typography fontSize={"x-large"} fontStyle="italic">
        <FormattedNumber
         value={balancerTypes.find((element) => element.id === watch("amountId"))?.amount || 0}
         style={"currency"}
         currency="EUR"
        />
       </Typography>
      </Stack>
     </Paper>
    </Stack>
    <AppModal
     open={openModal}
     close={handleClose}
     title={intl.formatMessage({ id: "addons.loadBalancer.add.createNewBalancer" })}
     handleClose={handleClose}
     disabled={loading}
     handleConfirm={handleConfirm}>
     <Stack spacing={2}>
      <Typography>
       <FormattedMessage id="addons.loadBalancer.add.confirmCreation" />
      </Typography>
      <Stack direction="row" spacing={2}>
       <Typography minWidth={150} textAlign="start" fontWeight="bold">
        <FormattedMessage id="server.add.balance" />
       </Typography>
       <Typography>
        <FormattedNumber value={userBalance} style={"currency"} currency="EUR" />
       </Typography>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
       <Typography minWidth={150} textAlign="start" fontWeight="bold">
        <FormattedMessage id="server.add.totalPrice" />
       </Typography>
       <Typography>
        <FormattedNumber
         value={balancerTypes.find((element) => element.id === watch("amountId"))?.amount || 0}
         style={"currency"}
         currency="EUR"
        />
       </Typography>
      </Stack>
     </Stack>
    </AppModal>
   </Stack>
  </Container>
 ) : (
  <></>
 );
};

export default LoadBalancerWizard;
