import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

import { ICreditsDataset } from "types/api/creditsApiInterface";

export const getCredits = (state: AppState) => state.credits;

export const getCreditsNumber = createSelector(getCredits, (credits) => {
 return credits?.totalCount;
});

export const getCreditsDataSet = createSelector(getCredits, (credits) => {
 return (
  credits?.dataset?.map((credit: ICreditsDataset) => {
   return {
    id: credit.id,
    userid: credit.userid,
    description: credit.details || null,
    amount: credit.amount || null,
    bonus: credit.bonus || null,
    product: credit.product || null,
    details: credit.details || null,
    createdAt: credit.createdAt || null,
    companyName: credit.companyName || "",
    type: credit.type
   };
  }) || []
 );
});
