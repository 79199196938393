import React, { ReactElement, useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postRepairTable } from "redux/handlers/serverHandler";

import { getServerDetails } from "redux/selectors/serversSelector";

import { useAppDispatch } from "hooks/reduxHook";

const ServiceRepair = ({ name, sqlLogs }: { name: string; sqlLogs: string }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const { socket } = useContext(AppContext);

 const server = useSelector(getServerDetails);

 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [repairActivated, setRepairActivated] = useState<boolean>(false);
 const [status, setStatus] = useState<string>("no");

 useEffect(() => {
  if (!isNil(socket)) {
   socket.on("server_logs", (value): void => {
    if (value?.length > 0) {
     for (let i = 0; i < value.length; i++) {
      if (server.agentoken === value[i]?.token) {
       switch (name) {
        case "apache2":
         setStatus(value[i]?.apache_ver || "no");
         break;
        case "postfix":
         setStatus(value[i]?.ser_post || "no");
         break;
        case "dovecot":
         setStatus(value[i]?.ser_dove || "no");
         break;
        case "nginx":
         setStatus(value[i]?.nginx_ver || "no");
         break;
        case "mysql":
         setStatus(value[i]?.sql_ver || "no");
         break;
        case "php":
         setStatus(value[i]?.php_ver || "no");
         break;
        case "ftp":
         setStatus(value[i]?.ser_ftp || "no");
         break;
        case "memcache":
         setStatus(value[i]?.ser_memc || "no");
         break;
        case "redis":
         setStatus(value[i]?.ser_redi || "no");
         break;
        default:
         setStatus("no");
         break;
       }
      }
     }
    }
   });
  }
  return () => {
   !isNil(socket) && socket.off("server_logs");
  };
 }, [socket, server]);

 const handleOpen = async () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setIsLoading(true);
  setIsLoading(false);
  setOpenModal(false);
 };

 const handleRepair = async () => {
  await dispatch(postRepairTable(server.id || 0));
  setRepairActivated(true);
 };

 return (
  <>
   {status === "no" ? (
    <IconButton disabled={status === "no"}>
     <IconSelector icon="RebuildIcon" props={{ fontSize: "small" }} />
    </IconButton>
   ) : (
    <Tooltip title={intl.formatMessage({ id: "server.service.repairTable" })} placement="top">
     <IconButton onClick={handleOpen}>
      <IconSelector icon="RebuildIcon" props={{ fontSize: "small" }} />
     </IconButton>
    </Tooltip>
   )}
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    handleConfirm={handleConfirm}
    showConfirmButton={false}
    title={intl.formatMessage({ id: "server.service.repairTable" })}>
    <Stack spacing={2}>
     <Stack direction="row" alignItems={"center"} spacing={2}>
      {!repairActivated && sqlLogs === "" ? (
       <Typography>
        <FormattedMessage id="server.services.oldLogsNotFound" />
       </Typography>
      ) : (
       <Stack maxHeight={200} sx={{ overflowY: "scroll" }}>
        <Typography>{sqlLogs}</Typography>
       </Stack>
      )}
      {repairActivated && sqlLogs === "" && (
       <>
        <CircularProgress />
        <Typography>
         <FormattedMessage id="server.service.repairOngoing" />
        </Typography>
       </>
      )}
     </Stack>
     {repairActivated && sqlLogs === "" && (
      <Alert severity="info">
       <FormattedMessage id="server.services.repairInfo" />
      </Alert>
     )}
     <Stack width={"20%"}>
      <Button variant="kxActionButton" onClick={handleRepair}>
       <FormattedMessage id="server.service.repairTable" />
      </Button>
     </Stack>
    </Stack>
   </AppModal>
  </>
 );
};

export default ServiceRepair;
