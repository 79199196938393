import React, { ReactElement } from "react";
import { useSelector } from "react-redux";

import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

import { getIspList } from "redux/selectors/ispSelector";
import { getWebsitesDetails } from "redux/selectors/websitesSelector";

import EditDirectives from "../actions/EditDirectives";
import EditSiteDetails from "../actions/EditSiteDetails";

const DetailsContent = (): ReactElement => {
 const websitedetails = useSelector(getWebsitesDetails);
 const ispList = useSelector(getIspList);

 const siteServer = ispList.find((element) => element.id === websitedetails.isp_id);

 const phpVers =
  siteServer && (siteServer.php_versions["Fast-CGI"] || siteServer.php_versions["PHP-FPM"])
   ? siteServer.php_versions["Fast-CGI"]?.length > 0
     ? siteServer.php_versions["Fast-CGI"]
     : siteServer.php_versions["PHP-FPM"]
   : [];
 const phpTypes = siteServer && siteServer.php_mode ? siteServer.php_mode : [];

 return (
  <>
   <Stack>
    <EditSiteDetails section="site" title="sites.url" tooltip="edit.url" />
    <Divider />
    <EditSiteDetails
     section="php_version"
     title="sites.php_version"
     tooltip="edit.phpVersion"
     phpVers={phpVers}
    />
    <Divider />
    <EditSiteDetails
     section="php_mode"
     title="sites.php_mode"
     tooltip="edit.phpMode"
     phpTypes={phpTypes}
    />
    <Divider />
    <EditSiteDetails section="hd_quota" title="sites.hd_quota" tooltip="edit.hdQuota" />
    <Divider />
    <EditSiteDetails
     section="traffic_quota"
     title="sites.trafficQuota"
     tooltip="edit.trafficQuota"
    />
    <Divider />
    <EditDirectives section="php" />
    <Divider />
    <EditDirectives
     section={
      ispList
       .find((element) => element.id === websitedetails.isp_id)
       ?.webserver_type?.includes("apache")
       ? "apache2"
       : "nginx"
     }
    />
   </Stack>
  </>
 );
};

export default DetailsContent;
