import React, { ReactElement } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";

import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { jobqueueTableData } from "types/global/table";

const JobqueueTable = ({
 rowData,
 index
}: {
 rowData: jobqueueTableData;
 index: number;
}): ReactElement => {
 const renderChip = (data: string) => {
  switch (data) {
   case "success":
    return <Chip size="small" label={data} color="success" />;
   case "failed":
    return <Chip size="small" label={data} color="error" />;
   default:
    return <Chip size="small" label={data} color="primary" />;
  }
 };

 const renderActions = (data: string) => {
  switch (data) {
   case "success":
    return (
     <Typography variant="subtitle2">
      <FormattedMessage id="app.jobqueue.noActionsAvailable" />
     </Typography>
    );
   case "failed":
    return (
     <Button variant="kxActionButton">
      <FormattedMessage id="app.retry" />
     </Button>
    );
   default:
    return (
     <Typography variant="subtitle2">
      <FormattedMessage id="app.jobqueue.noActionsAvailable" />
     </Typography>
    );
  }
 };

 return (
  <TableRow key={`job-list-${index}`}>
   <TableCell component="th" scope="row">
    {renderChip(rowData.status)}
   </TableCell>
   <TableCell component="th" scope="row">
    {rowData.type}
   </TableCell>
   <TableCell component="th" scope="row">
    {rowData.action}
   </TableCell>
   <TableCell component="th" scope="row">
    <FormattedDate value={rowData.createdAt} />
   </TableCell>
   <TableCell component="th" scope="row">
    {renderActions(rowData.status)}
   </TableCell>
  </TableRow>
 );
};

export default JobqueueTable;
