import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { ICreditsApi } from "types/api/creditsApiInterface";

const initialState: ICreditsApi = { dataset: [], totalCount: 0 };

const creditsReducer = createSlice({
 name: "credits",
 initialState,
 reducers: {
  setCredits: (state, { payload }: PayloadAction<ICreditsApi>) => {
   return payload;
  },
  resetCredits: () => {
   return initialState;
  }
 }
});

export default creditsReducer;
