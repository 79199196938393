import React, { ReactElement, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import useDebounce from "react-use/lib/useDebounce";

import { isNil } from "ramda";

import SearchIcon from "@mui/icons-material/Search";

import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { fetchGatewayList } from "redux/handlers/gatewayHandler";
import { getAllServersNotPaged } from "redux/handlers/serverHandler";
import { getUsersList } from "redux/handlers/userHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IGatewayApi } from "types/api/gatewayApiInterface";
import { IAllServers } from "types/api/serversApiInterface";
import { IUsersList } from "types/api/userApiInterface";

import AddGateway from "./actions/AddGateway";
import GatewayMenu from "./GatewayMenu";

const Gateway = (): ReactElement => {
 const dispatch = useAppDispatch();
 const intl = useIntl();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const searchRef = useRef<HTMLInputElement>(null);

 const [loading, setLoading] = useState(true);
 const [contactsList, setContactsList] = useState<Array<IUsersList>>([]);
 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);
 const [searchValue, setSearchValue] = useState<string>("");
 const [selectedPartner, setSelectedPartner] = useState<string>("");
 const [gatewayList, setGatewayList] = useState<Array<IGatewayApi>>([]);
 const [gatewayNumber, setGatewayNumber] = useState<number>(0);
 const [allServers, setAllServers] = useState<Array<IAllServers>>([]);

 useEffect(() => {
  (async () => {
   setLoading(true);
   setContactsList(await dispatch(getUsersList()));
   setAllServers(await dispatch(getAllServersNotPaged()));
   const gateData = await dispatch(fetchGatewayList(0, 10));
   setGatewayList(gateData.dataset);
   setGatewayNumber(gateData.totalCount);
   setLoading(false);
  })();
 }, []);

 useDebounce(
  async () => {
   if (!loading) {
    setCurrentPage(0);
    const gateData = await dispatch(
     fetchGatewayList(currentPage, rowPerPage, searchRef?.current?.value, selectedPartner)
    );
    setGatewayList(gateData.dataset);
    setGatewayNumber(gateData.totalCount);
   }
  },
  1000,
  [searchValue]
 );

 const handleOnRowsPerPageChange = async (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const newRowsPerPage = parseInt(event.target.value, 10);
  setRowPerPage(newRowsPerPage);
  setCurrentPage(0);
  const gateData = await dispatch(
   fetchGatewayList(0, newRowsPerPage, searchRef?.current?.value, selectedPartner)
  );
  setGatewayList(gateData.dataset);
  setGatewayNumber(gateData.totalCount);
 };

 const handleOnPageChange = async (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  const startIndex = newPage * rowPerPage;
  setCurrentPage(newPage);
  const gateData = await dispatch(
   fetchGatewayList(startIndex, rowPerPage, searchRef?.current?.value, selectedPartner)
  );
  setGatewayList(gateData.dataset);
  setGatewayNumber(gateData.totalCount);
 };

 const handleSearchPartner = async (partnerIdValue: string) => {
  setCurrentPage(0);
  setSelectedPartner(partnerIdValue);
  const gateData = await dispatch(
   fetchGatewayList(currentPage, rowPerPage, searchRef?.current?.value, partnerIdValue)
  );
  setGatewayList(gateData.dataset);
  setGatewayNumber(gateData.totalCount);
 };

 const handleReloadData = async () => {
  const gateData = await dispatch(
   fetchGatewayList(currentPage, rowPerPage, searchRef?.current?.value, selectedPartner)
  );
  setGatewayList(gateData.dataset);
  setGatewayNumber(gateData.totalCount);
 };

 return !loading ? (
  <Stack spacing={2}>
   <Stack justifyContent="space-between" direction="row">
    <Stack direction={desktopViewPort ? "row" : "column"} spacing={2} alignItems="center">
     <Typography>
      {gatewayNumber} <FormattedMessage id="configurator.gateway.totalGateway" />
     </Typography>
     <TextField
      size="small"
      autoComplete="new-password"
      label={<FormattedMessage id="configurator.search.gateway" />}
      inputRef={searchRef}
      sx={{ ml: 4 }}
      InputLabelProps={{ shrink: true }}
      InputProps={{ startAdornment: <SearchIcon color="disabled" />, autoComplete: "off" }}
      onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
     />
     <Autocomplete
      autoHighlight
      size="small"
      sx={{ mx: 1, width: desktopViewPort ? 300 : 240 }}
      onChange={(e, value) =>
       isNil(value) ? handleSearchPartner("") : handleSearchPartner(value.userid)
      }
      options={contactsList.map((element) => {
       return {
        label: `${element.firstname} ${element.lastname} - ${element.companyName}`,
        userid: element.userid
       };
      })}
      renderInput={(params) => (
       <TextField
        {...params}
        label={intl.formatMessage({ id: "app.search.partner" })}
        InputLabelProps={{ shrink: true }}
       />
      )}
     />
    </Stack>
    <Stack justifyContent={desktopViewPort ? "flex-start" : "center"}>
     <AddGateway
      contacts={contactsList}
      reloadData={handleReloadData}
      servers={allServers.filter((server) => server.type === "gatewayserver")}
     />
    </Stack>
   </Stack>
   <Stack>
    <TableContainer component="div">
     <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
       <TableRow>
        <TableCell>
         <FormattedMessage id="configurator.gateway.partner" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="configurator.gateway.name" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="configurator.gateway.url" />
        </TableCell>
        <TableCell align="center">
         <FormattedMessage id="configurator.gateway.actions" />
        </TableCell>
       </TableRow>
      </TableHead>
      <TableBody>
       {gatewayList.map((row, index) => (
        <TableRow key={`isp-config-list-${index}`}>
         <TableCell component="th" scope="row">
          <Typography variant="subtitle2" fontWeight="bold">
           {`${contactsList.find((element) => element.userid === row.userid)?.companyName}`}
          </Typography>
         </TableCell>
         <TableCell component="th" scope="row">
          <Typography variant="subtitle2">{row.hostname}</Typography>
         </TableCell>
         <TableCell>
          <Typography variant="subtitle2">{row.url}</Typography>
         </TableCell>
         <TableCell align="center">
          <GatewayMenu
           contacts={contactsList}
           servers={allServers.filter((server) => server.type === "gatewayserver")}
           projectData={row}
           reloadData={handleReloadData}
           id={row.id}
          />
         </TableCell>
        </TableRow>
       ))}
      </TableBody>
     </Table>
    </TableContainer>
    <TablePagination
     component="div"
     count={gatewayNumber}
     page={currentPage}
     rowsPerPage={rowPerPage}
     onPageChange={handleOnPageChange}
     onRowsPerPageChange={handleOnRowsPerPageChange}
    />
   </Stack>
  </Stack>
 ) : (
  <></>
 );
};

export default Gateway;
