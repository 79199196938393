import React, { ReactElement, useState } from "react";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";

import IconSelector from "components/shared/images/IconSelector";

import { IUsersList } from "types/api/userApiInterface";

import ChangeBaremetalPartner from "./actions/ChangeBaremetalPartner";
import CompleteCreation from "./actions/CompleteCreation";

const BaremetalMenu = ({
 id,
 partner,
 partnerList,
 product,
 server_number,
 amount,
 status,
 reloadData
}: {
 id: number;
 partner: string;
 partnerList: Array<IUsersList>;
 product: string;
 server_number: number;
 amount: number;
 status: string;
 reloadData: () => void;
}): ReactElement => {
 const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
 const open = Boolean(anchorEl);

 const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
  setAnchorEl(null);
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <IconSelector icon="MenuIcon" />
   </Button>
   <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
     "aria-labelledby": "basic-button"
    }}>
    <Stack>
     <ChangeBaremetalPartner
      id={id}
      partner={partner}
      partnerList={partnerList}
      closeMenu={handleClose}
      reloadData={reloadData}
     />
     {status === "creating" && (
      <CompleteCreation
       id={id}
       server_number={server_number}
       amount={amount}
       product={product}
       closeMenu={handleClose}
      />
     )}
    </Stack>
   </Menu>
  </>
 );
};

export default BaremetalMenu;
