/* eslint-disable react/style-prop-object */
import React, { ReactElement } from "react";
import { FormattedDate, FormattedMessage, FormattedNumber } from "react-intl";
import { formatGigaBytes } from "helpers/numberFormatting";

import { isNil } from "ramda";

import DvrIcon from "@mui/icons-material/Dvr";
import ReportIcon from "@mui/icons-material/Report";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import ManualRenewBaremetal from "components/baremetal/actions/ManualRenewBaremetal";
import BaremetalActions from "components/baremetal/BaremetalActions";
import AdminLogAsUser from "components/shared/admin/AdminLogAsUser";
import IconSelector from "components/shared/images/IconSelector";

import { baremetalTableData } from "types/global/table";

const BaremetalTable = ({
 rowData,
 index
}: {
 rowData: baremetalTableData;
 index: number;
}): ReactElement => {
 const formatStatus = (status: string) => {
  switch (status) {
   case "running":
    return "success";
   case "initializing":
    return "info";
   case "starting":
    return "warning";
   case "stopping":
    return "warning";
   case "off":
    return "error";
   case "deleting":
    return "error";
   case "migrating":
    return "info";
   case "rebuilding":
    return "warning";
   case "active":
    return "success";
   case "creating":
    return "warning";
   default:
    return "default";
  }
 };

 const RenderStatusMessage = ({ status }: { status: string }) => {
  switch (status) {
   case "running":
    return <FormattedMessage id="server.status.running" />;
   case "initializing":
    return <FormattedMessage id="server.status.initializing" />;
   case "starting":
    return <FormattedMessage id="server.status.starting" />;
   case "stopping":
    return <FormattedMessage id="server.status.stopping" />;
   case "off":
    return <FormattedMessage id="server.status.off" />;
   case "deleting":
    return <FormattedMessage id="server.status.deleting" />;
   case "migrating":
    return <FormattedMessage id="server.status.migrating" />;
   case "rebuilding":
    return <FormattedMessage id="server.status.rebuilding" />;
   case "active":
    return <FormattedMessage id="server.status.running" />;
   case "creating":
    return <FormattedMessage id="server.status.creating" />;
   default:
    return <FormattedMessage id="server.status.unknown" />;
  }
 };

 const RenderStatusIcon = ({ status }: { status: string }) => {
  switch (status) {
   case "active":
    return (
     <Stack direction="row" spacing={1}>
      <IconSelector icon="StatusIcon" props={{ style: { color: "green" }, fontSize: "small" }} />
      <Typography>{status}</Typography>
     </Stack>
    );
   case "expiring":
    return (
     <Stack direction="row" spacing={1}>
      <ReportProblemIcon sx={{ color: "orange" }} />
      <Typography>{status}</Typography>
     </Stack>
    );
   case "in renew":
    return (
     <Stack direction="row" spacing={1}>
      <ReportProblemIcon sx={{ color: "blue" }} />
      <Typography>{status}</Typography>
     </Stack>
    );
   case "expired":
    return (
     <Stack direction="row" spacing={1}>
      <ReportIcon sx={{ color: "red" }} />
      <Typography>{status}</Typography>
     </Stack>
    );
   default:
    return <></>;
  }
 };

 return (
  <TableRow key={`server-list-${index}`} sx={{ cursor: "pointer" }}>
   <TableCell component="th" scope="row">
    <Stack direction="row" alignItems="center" spacing={0.5}>
     <Typography variant="small">{rowData.server_name}</Typography>
    </Stack>
    <Stack spacing={0.5}>
     <Stack direction="row" spacing={1}>
      <Typography variant="extraSmall" fontWeight="bold">
       IPv4
      </Typography>
      <Typography variant="extraSmall">{rowData.server_ip || "Not available"}</Typography>
     </Stack>
     <Stack direction="row" spacing={1}>
      <Typography variant="extraSmall" fontWeight="bold">
       IPv6
      </Typography>
      <Typography variant="extraSmall">{rowData.server_ipv6_net || "Not available"}</Typography>
     </Stack>
    </Stack>
    <Stack onClick={(event) => event.stopPropagation()}>
     <AdminLogAsUser userid={rowData.userid} owner={rowData.owner || ""} />
    </Stack>
   </TableCell>
   <TableCell>
    <Stack spacing={2}>
     <RenderStatusIcon status={rowData.service_status} />
     <Chip
      color={formatStatus(rowData.status)}
      size="small"
      label={<RenderStatusMessage status={rowData.status} />}
     />
    </Stack>
   </TableCell>
   <TableCell>
    <Stack>
     <Stack direction="row" spacing={1}>
      <Typography variant="caption" fontWeight="bold">
       <FormattedMessage id="baremetal.diskType" />:
      </Typography>
      <Typography variant="caption">{rowData.disk_type}</Typography>
     </Stack>
     <Stack direction="row" spacing={1}>
      <Typography variant="caption" fontWeight="bold">
       <FormattedMessage id="baremetal.disk" />:
      </Typography>
      <Typography variant="caption">
       {rowData.dim_disk >= 1024
        ? `${formatGigaBytes(rowData.dim_disk, "TB")} TB`
        : `${formatGigaBytes(rowData.dim_disk)} GB`}
      </Typography>
     </Stack>
     <Stack direction="row" spacing={1}>
      <Typography variant="caption" fontWeight="bold">
       RAM:
      </Typography>
      <Typography variant="caption">{`${rowData.dim_ram} GB`}</Typography>
     </Stack>
    </Stack>
   </TableCell>
   <TableCell align="right">
    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={0.5}>
     <DvrIcon fontSize="small" />
     <Typography variant="overline" fontWeight="bold">
      [{rowData.product}]
     </Typography>
     <Typography variant="small">
      <FormattedNumber
       value={isNil(rowData.price) ? 0 : Number(rowData.price)}
       style="currency"
       currency="EUR"
      />
     </Typography>
    </Stack>
   </TableCell>
   <TableCell align="right">
    <Stack justifyContent="flex-end">
     {rowData.autorenew ? (
      <Stack direction="row" alignItems="center" justifyContent="flex-end">
       <Typography variant="small">
        <FormattedMessage id={"server.renewType.automatic"} />
       </Typography>
       <IconSelector
        icon="ScheduleIcon"
        props={{ fontSize: "small", color: "success", sx: { ml: 1 } }}
       />
      </Stack>
     ) : (
      <Stack direction="row" alignItems="center" justifyContent="flex-end">
       {rowData.service_status !== "active" ? (
        <Stack onClick={(event) => event.stopPropagation()}>
         <ManualRenewBaremetal server={rowData.server_name} />
        </Stack>
       ) : (
        <Typography variant="small">
         <FormattedMessage id={"server.renewType.manual"} />
        </Typography>
       )}
      </Stack>
     )}
     <Stack>
      <Typography variant="small">
       {rowData?.expire && (
        <FormattedDate value={new Date(rowData.expire)} year="numeric" month="long" day="2-digit" />
       )}
      </Typography>
     </Stack>
    </Stack>
   </TableCell>
   <TableCell align="center">
    {rowData.status !== "active" && rowData.status !== "running" ? (
     <Typography>
      <FormattedMessage id="baremetal.noActionAvailable" />
     </Typography>
    ) : (
     <BaremetalActions rowData={rowData} />
    )}
   </TableCell>
  </TableRow>
 );
};

export default BaremetalTable;
