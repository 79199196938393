import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";

import { websiteContainerDatabaseSteps } from "constants/server";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { getDockerDbTypes, postInstallDatabase } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

import DbName from "./databaseStepComponents/DbName";
import DbPassword from "./databaseStepComponents/DbPassword";
import DbRecap from "./databaseStepComponents/DbRecap";
import DbType from "./databaseStepComponents/DbType";
import DbUsername from "./databaseStepComponents/DbUsername";

const CreateDatabase = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [dbUser, setDbUser] = useState<string>("");
 const [dbName, setDbName] = useState<string>("");
 const [type, setType] = useState<string>("");
 const [dbPassword, setDbPassword] = useState<string>("");
 const [goToStepper, setGoToStepper] = useState<boolean>(false);
 const [activeStep, setActiveStep] = useState<number>(0);
 const [dbTypes, setDbTypes] = useState<Array<{ id: number; name: string }>>([]);

 const handleOpen = async () => {
  setGoToStepper(false);
  setDbTypes(await dispatch(getDockerDbTypes()));
  handleReset();
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postInstallDatabase(id, dbName, dbUser, dbPassword, type));
  setLoading(false);
  handleClose();
 };

 const handleNext = () => {
  setActiveStep((prevActiveStep) => prevActiveStep + 1);
 };

 const handleBack = () => {
  setActiveStep((prevActiveStep) => prevActiveStep - 1);
 };

 const handleReset = () => {
  setDbName("");
  setDbUser("");
  setDbPassword("");
  setType("");
  setActiveStep(0);
 };

 const RenderStepContent = (): ReactElement => {
  switch (activeStep) {
   case 0:
    return (
     <DbName
      activeStep={activeStep}
      firstValue={dbName}
      handleNext={handleNext}
      handleBack={handleBack}
      handleReset={handleReset}
      setPropUsername={(value) => setDbName(value)}
     />
    );
   case 1:
    return (
     <DbType
      activeStep={activeStep}
      firstValue={dbUser}
      dbTypes={dbTypes}
      handleNext={handleNext}
      handleBack={handleBack}
      handleReset={handleReset}
      setPropsValue={(value) => setType(value)}
     />
    );
   case 2:
    return (
     <DbUsername
      activeStep={activeStep}
      firstValue={dbUser}
      handleNext={handleNext}
      handleBack={handleBack}
      handleReset={handleReset}
      setPropUsername={(value) => setDbUser(value)}
     />
    );
   case 3:
    return (
     <DbPassword
      activeStep={activeStep}
      firstValue={dbPassword}
      handleNext={handleNext}
      handleBack={handleBack}
      handleReset={handleReset}
      setPropValue={(value) => setDbPassword(value)}
     />
    );
   case 4:
    return (
     <DbRecap
      name={dbName}
      username={dbUser}
      password={dbPassword}
      type={type}
      handleBack={handleBack}
      handleReset={handleReset}
     />
    );
   default:
    return <></>;
  }
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <FormattedMessage id="docker.website.createDatabase" />
   </Button>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.createDatabase" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}
    showConfirmButton={activeStep === websiteContainerDatabaseSteps.length - 1}>
    {!goToStepper ? (
     <Stack>
      <Stack direction="row">
       <Stack justifyContent="center" spacing={2}>
        <Typography>
         <FormattedMessage id="docker.website.firstExplainDatabase" />
        </Typography>
        <Typography>
         <FormattedMessage id="docker.website.secondExplainDatabase" />
        </Typography>
        <Typography>
         <FormattedMessage id="docker.website.thirdExplainDatabase" />
        </Typography>
       </Stack>
       <Stack alignItems="center" justifyContent="center">
        <IconSelector
         icon="DatabaseIcon"
         props={{ sx: { fontSize: "200px", color: "lightgray" } }}
        />
       </Stack>
      </Stack>
      <Button onClick={() => setGoToStepper(true)}>
       <FormattedMessage id="docker.website.startNow" />
      </Button>
     </Stack>
    ) : (
     <Stack>
      <Stepper activeStep={activeStep} alternativeLabel>
       {websiteContainerDatabaseSteps.map((label) => (
        <Step key={label.label}>
         <StepLabel optional={label.description ? <FormattedMessage id={label.description} /> : ""}>
          {<FormattedMessage id={label.label} />}
         </StepLabel>
        </Step>
       ))}
      </Stepper>
      <RenderStepContent />
     </Stack>
    )}
   </AppModal>
  </>
 );
};

export default CreateDatabase;
