import React, { ReactElement } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import IconSelector from "../images/IconSelector";

type linkType = {
 name: string;
 url: string;
 icon?: string;
};

const MobileSectionMenu = ({
 links,
 url,
 disabled
}: {
 links: Array<linkType>;
 url: string;
 disabled: Array<string>;
}): ReactElement => {
 const intl = useIntl();
 const navigate = useNavigate();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 return (
  <Stack mt={desktopViewPort ? 6 : 2} direction={"row"} spacing={2} sx={{ overflowX: "scroll" }}>
   {links.map((item, index) => (
    <Chip
     sx={{ px: 2 }}
     key={`mobile-menu-item-${index}`}
     icon={<IconSelector icon={item.icon} />}
     label={intl.formatMessage({ id: item.name })}
     onClick={() => navigate(`${url}/${item.url}`)}
     disabled={disabled.includes(item.url)}
    />
   ))}
  </Stack>
 );
};

export default MobileSectionMenu;
