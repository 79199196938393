import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import EmptyList from "components/shared/emptyList/EmptyList";
import IconSelector from "components/shared/images/IconSelector";

import { getAllServersNotPaged } from "redux/handlers/serverHandler";

import {
 getLoadBalancerDetails,
 getLoadBalancerServices,
 getLoadBalancerTargets
} from "redux/selectors/loadBalancerSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IAllServers } from "types/api/serversApiInterface";

import CreateService from "./actions/CreateService";
import DeleteService from "./actions/DeleteService";

const LoadBalancerService = (): ReactElement => {
 const dispatch = useAppDispatch();

 const balancerData = useSelector(getLoadBalancerDetails);
 const servicesData = useSelector(getLoadBalancerServices);
 const targetsData = useSelector(getLoadBalancerTargets);

 const [open, setOpen] = useState<number>(-1);
 const [servers, setServers] = useState<Array<IAllServers>>([]);

 useEffect(() => {
  (async () => {
   setServers(await dispatch(getAllServersNotPaged()));
  })();
 }, []);

 /**
  * Ultime cose da fare: inserire la lista di servizi, completare la sezione targets, inserire health check nel monitoring
  */
 const checkForList = (): boolean => {
  return servicesData.length > 0;
 };

 const handleRetriveBody = async (index: number) => {
  setOpen(open === index ? -1 : index);
 };

 const checkServiceStatus = (servicePort: number): number => {
  const filteredTargets = targetsData.filter(
   (element) =>
    element.health_status.find((stats) => stats.listen_port === servicePort)?.status === "healthy"
  );
  const allServiceTargets = targetsData.filter(
   (element) =>
    element.health_status.find((stats) => stats.listen_port === servicePort)?.listen_port ===
    servicePort
  );

  if (allServiceTargets.length === 0) return 3;

  switch (allServiceTargets.length - filteredTargets.length) {
   case 0:
    return 2;
   case allServiceTargets.length:
    return 0;
   default:
    return 1;
  }
 };

 const RenderIcon = ({ servicePort }: { servicePort: number }): ReactElement => {
  switch (checkServiceStatus(servicePort)) {
   case 0:
    return <IconSelector icon="GppBadIcon" props={{ fontSize: "medium", color: "error" }} />;
   case 2:
    return <IconSelector icon="DataSaverOnIcon" props={{ fontSize: "medium", color: "success" }} />;
   case 3:
    return (
     <IconSelector icon="DataSaverOnIcon" props={{ fontSize: "medium", color: "disabled" }} />
    );
   default:
    return <IconSelector icon="DataSaverOnIcon" props={{ fontSize: "medium", color: "warning" }} />;
  }
 };

 return (
  <Paper elevation={0} sx={{ mt: 3, pb: 0.7, borderRadius: "10px", boxShadow: 0 }}>
   <Stack direction="row" justifyContent="space-between" alignItems="center">
    <Stack alignItems="center" justifyContent="flex-start" direction="row" px={2} py={1}>
     <IconSelector icon="KeyIcon" props={{ fontSize: "medium", color: "secondary" }} />
     <Typography variant="h6" component="h2" ml={1}>
      <FormattedMessage id="addons.loadBalancer.services" />{" "}
     </Typography>
    </Stack>
    {checkForList() && (
     <CreateService
      id={balancerData?.id || 0}
      maxServices={balancerData?.load_balancer_type_max_services === servicesData.length}
     />
    )}
   </Stack>
   {checkForList() ? (
    <TableContainer component="div">
     <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
       <TableRow sx={{ backgroundColor: "transparent" }}>
        <TableCell></TableCell>
        <TableCell>
         <Typography variant="kxColoredSmall" fontWeight="bold">
          <FormattedMessage id="addons.loadBalancer.services.status" />
         </Typography>
        </TableCell>
        <TableCell>
         <Typography variant="kxColoredSmall" fontWeight="bold">
          <FormattedMessage id="addons.loadBalancer.services.data" />
         </Typography>
        </TableCell>
        <TableCell>
         <Typography variant="kxColoredSmall" fontWeight="bold">
          <FormattedMessage id="app.actions" />
         </Typography>
        </TableCell>
       </TableRow>
      </TableHead>
      <TableBody>
       {servicesData.map((row, index) => (
        <Fragment key={`balancer-service-list-${index}`}>
         <TableRow>
          <TableCell>
           <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleRetriveBody(index)}>
            {open === index ? (
             <IconSelector icon="KeyboardArrowUpIcon" />
            ) : (
             <IconSelector icon="KeyboardArrowDownIcon" />
            )}
           </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
           <Stack direction="row" alignItems="center" spacing={2}>
            <RenderIcon servicePort={row.listen_port} />
            <Typography
             fontWeight="bold"
             variant="caption">{`${row.protocol}:${row.listen_port} → ${row.destination_port}`}</Typography>
           </Stack>
          </TableCell>
          <TableCell component="th" scope="row">
           <Stack>
            <Typography variant="caption">{`Interval: ${row.health_check_interval}s`}</Typography>
            <Typography variant="caption">{`Timeout: ${row.health_check_timeout}s`}</Typography>
            <Typography variant="caption">{`Retries: ${row.health_check_retries}`}</Typography>
           </Stack>
          </TableCell>
          <TableCell>
           <DeleteService id={row.id || 0} />
          </TableCell>
         </TableRow>
         <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
           <Collapse in={open === index} timeout="auto" unmountOnExit>
            {targetsData.filter(
             (element) =>
              element.health_status.find((stats) => stats.listen_port === row.listen_port)
               ?.listen_port === row.listen_port
            ).length === 0 && (
             <Typography>
              <FormattedMessage id="addons.loadBalancer.noTargetsFound" />
             </Typography>
            )}
            {targetsData
             .filter(
              (element) =>
               element.health_status.find((stats) => stats.listen_port === row.listen_port)
                ?.listen_port === row.listen_port
             )
             .map((target, key) => {
              return (
               <Stack
                key={`target-key-${key}`}
                direction="row"
                alignItems="center"
                spacing={2}
                py={2}>
                <Chip
                 label={
                  target.health_status.find((stats) => stats.listen_port === row.listen_port)
                   ?.status
                 }
                 size="small"
                 color={
                  target.health_status.find((stats) => stats.listen_port === row.listen_port)
                   ?.status === "healthy"
                   ? "success"
                   : "error"
                 }
                />
                <Stack>
                 <Typography variant="caption" fontWeight="bold">
                  {target.type === "server"
                   ? servers.find((element) => element.server_id === target.server_id)?.server_name
                   : target.ip}
                 </Typography>
                </Stack>
                <Stack>
                 <Typography variant="caption">{target.type}</Typography>
                </Stack>
               </Stack>
              );
             })}
           </Collapse>
          </TableCell>
         </TableRow>
        </Fragment>
       ))}
      </TableBody>
     </Table>
    </TableContainer>
   ) : (
    <Stack>
     <EmptyList />
     <CreateService
      id={balancerData?.id || 0}
      maxServices={balancerData?.load_balancer_type_max_services === servicesData.length}
     />
    </Stack>
   )}
  </Paper>
 );
};

export default LoadBalancerService;
