import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
 AddressElement,
 LinkAuthenticationElement,
 PaymentElement,
 useElements,
 useStripe
} from "@stripe/react-stripe-js";
import { StripeAddressElementOptions, StripePaymentElementOptions } from "@stripe/stripe-js";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import { postCompleteStripePayment } from "redux/handlers/rechargeHandler";

import { getUserProfileData } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IProfile } from "types/redux/userInterfaces";

const CheckoutForm = ({
 amount,
 request_amount
}: {
 amount: number;
 request_amount: number;
}): ReactElement => {
 const dispatch = useAppDispatch();
 const stripe = useStripe();
 const elements = useElements();
 const navigate = useNavigate();

 const profileData: IProfile = useSelector(getUserProfileData);

 const [message, setMessage] = useState<string>("");
 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [severity, setSeverity] = useState<"info" | "success" | "error" | "warning">("info");

 useEffect(() => {
  if (!stripe) {
   return;
  }

  const clientSecret = new URLSearchParams(window.location.search).get(
   "payment_intent_client_secret"
  );

  if (!clientSecret) {
   return;
  }

  stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
   switch (paymentIntent?.status) {
    case "succeeded":
     setMessage("Payment succeeded!");
     setSeverity("success");
     dispatch(postCompleteStripePayment(amount, request_amount));
     break;
    case "processing":
     setMessage("Your payment is processing.");
     setSeverity("info");
     break;
    case "requires_payment_method":
     setSeverity("error");
     setMessage("Your payment was not successful, please try again.");
     break;
    default:
     setSeverity("error");
     setMessage("Something went wrong.");
     break;
   }
  });
 }, [stripe]);

 const handleSubmit = async (e: any) => {
  e.preventDefault();

  if (!stripe || !elements) {
   // Stripe.js hasn't yet loaded.
   // Make sure to disable form submission until Stripe.js has loaded.
   return;
  }

  setIsLoading(true);

  const { error } = await stripe.confirmPayment({
   elements,
   confirmParams: {
    // Make sure to change this to your payment completion page
    return_url: `http://konsolex.onthecloud.srl/profile/recharge/payment/success?amount=${
     amount * 100
    }`
   }
  });

  // This point will only be reached if there is an immediate error when
  // confirming the payment. Otherwise, your customer will be redirected to
  // your `return_url`. For some payment methods like iDEAL, your customer will
  // be redirected to an intermediate site first to authorize the payment, then
  // redirected to the `return_url`.
  if (error.type === "card_error" || error.type === "validation_error") {
   setSeverity("error");
   setMessage(error.message || "");
  } else {
   setSeverity("error");
   setMessage("An unexpected error occurred.");
  }

  setIsLoading(false);
 };

 const paymentElementOptions: StripePaymentElementOptions = {
  layout: {
   type: "accordion",
   defaultCollapsed: false,
   radios: true,
   spacedAccordionItems: false
  }
 };

 const addressElementOptions: StripeAddressElementOptions = {
  mode: "billing",
  defaultValues: { name: `${profileData.firstname} ${profileData.lastname}` }
 };

 const handleReturnToRecharge = () => {
  navigate("/profile/recharge");
 };

 return (
  <form id="payment-form" onSubmit={handleSubmit}>
   <AddressElement options={addressElementOptions} />
   <LinkAuthenticationElement options={{ defaultValues: { email: profileData.email } }} />
   <PaymentElement id="payment-element" options={paymentElementOptions} />
   <Stack direction="row" pt={2} justifyContent="center" spacing={2}>
    <Button variant="kxActionButton" onClick={handleReturnToRecharge}>
     <FormattedMessage id="app.back" />
    </Button>
    <Button
     variant="kxActionButton"
     disabled={isLoading || !stripe || !elements}
     id="submit"
     onClick={handleSubmit}>
     <span id="button-text">
      {isLoading ? (
       <div className="spinner" id="spinner"></div>
      ) : (
       <FormattedMessage id="recharge.payNow" />
      )}
     </span>
    </Button>
   </Stack>
   {/* Show any error or success messages */}
   {message && (
    <Alert severity={severity} sx={{ mt: 1 }}>
     <div id="payment-message">{message}</div>
    </Alert>
   )}
  </form>
 );
};

export default CheckoutForm;
