import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IDomainsApi } from "types/api/domainsApiInterface";

const initialState: IDomainsApi = { dataset: [], totalCount: 0 };

const DomainsReducer = createSlice({
 name: "domains",
 initialState,
 reducers: {
  setDomains: (state, { payload }: PayloadAction<IDomainsApi>) => {
   return payload;
  },
  resetDomains: () => {
   return initialState;
  }
 }
});

export default DomainsReducer;
