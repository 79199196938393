import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { IServerDetails } from "types/api/serversApiInterface";

const initialState: IServerDetails = {
 server_name: "no data",
 server_type_name: ""
} as IServerDetails;

const serverDetailsReducer = createSlice({
 name: "serverDetails",
 initialState,
 reducers: {
  setServerDetails: (state, { payload }: PayloadAction<IServerDetails>) => {
   return payload;
  },
  resetServerDetails: () => {
   return initialState;
  }
 }
});

export default serverDetailsReducer;
