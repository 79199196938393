import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "redux/store";

import ThemeProvider from "@mui/material/styles/ThemeProvider";

import theme from "theme/konsoleXTheme";

import "./index.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";

import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
 <Provider store={store({})}>
  <ThemeProvider theme={theme}>
   <App />
  </ThemeProvider>
 </Provider>
);
