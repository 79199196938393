import React, { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import useDebounce from "react-use/lib/useDebounce";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AppContext } from "AppContext";
import dayjs from "dayjs";
import { handleChangePageNumber } from "helpers/pagination";

import { isNil } from "ramda";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";

import { Button, Checkbox, FormControlLabel, useMediaQuery, useTheme } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Autocomplete from "@mui/material/Autocomplete";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { servicesTable } from "constants/tables";

import AdminWrapper from "components/shared/admin/AdminWrapper";
import SearchForPartner from "components/shared/search/SearchForPartner";
import SkeletonProfile from "components/shared/skeletons/SkeletonProfile";
import SortingTable from "components/shared/tables/SortingTable";

import {
 getAllServices,
 getAllServicesWithUserid,
 getUserTypes
} from "redux/handlers/servicesHandler";
import { getUsersList } from "redux/handlers/userHandler";

import { getServicesList, getServicesNumber } from "redux/selectors/servicesSelector";
import { getIsAdmin } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IUsersList } from "types/api/userApiInterface";

import AddService from "./actions/AddService";

const Services = (): ReactElement => {
 const intl = useIntl();
 const searchRef = useRef<HTMLInputElement>(null);
 const dispatch = useAppDispatch();
 const { socket } = useContext(AppContext);
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const servicesList = useSelector(getServicesList);
 const servicesNumber = useSelector(getServicesNumber);
 const isAdmin = useSelector(getIsAdmin);

 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);
 const [searchValue, setSearchValue] = useState<string>("");
 const [customerList, setCustomerList] = useState<Array<IUsersList>>([]);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [selectedPartner, setSelectedPartner] = useState<string>("");
 const [dataToReload, setDataToReload] = useState<boolean>(false);
 const [currentType, setCurrentType] = useState<string>("");
 const [typeList, setTypeList] = useState<Array<string>>([]);
 const [from, setFrom] = useState<string>("");
 const [to, setTo] = useState<string>("");
 const [status, setStatus] = useState<boolean>(false);

 useEffect(() => {
  !isNil(socket) &&
   socket.on("profile_data", (value): void => {
    if (value?.completed && value?.section === "service") {
     setDataToReload(true);
    }
   });
  return () => {
   !isNil(socket) && socket.off("profile_data");
  };
 }, [socket]);

 useDebounce(
  () => {
   if (!isLoading) {
    setCurrentPage(0);
    dispatch(
     getAllServices(
      currentPage,
      rowPerPage,
      searchRef?.current?.value,
      selectedPartner,
      currentType,
      from,
      to,
      status
     )
    );
   }
  },
  1000,
  [searchValue]
 );

 useEffect(() => {
  (async () => {
   isAdmin && setCustomerList(await dispatch(getUsersList()));
   setTypeList(await getUserTypes());
   await dispatch(
    getAllServices(
     currentPage,
     rowPerPage,
     searchRef?.current?.value,
     selectedPartner,
     currentType,
     from,
     to,
     status
    )
   );
   setIsLoading(false);
  })();
 }, []);

 useEffect(() => {
  (async () => {
   if (dataToReload) {
    await dispatch(
     getAllServices(
      currentPage,
      rowPerPage,
      searchRef?.current?.value,
      selectedPartner,
      currentType,
      from,
      to,
      status
     )
    );
    setDataToReload(false);
   }
  })();
 }, [dataToReload]);

 const handleOnRowsPerPageChange = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const data = handleChangePageNumber(parseInt(event.target.value, 10), currentPage, rowPerPage);
  setRowPerPage(data.newRow);
  setCurrentPage(Number(data.newPage.toFixed(0)));

  dispatch(
   getAllServices(
    Number(data.newPage.toFixed(0)),
    parseInt(event.target.value, 10),
    searchRef?.current?.value,
    selectedPartner,
    currentType,
    from,
    to,
    status
   )
  );
 };

 const handleOnPageChange = (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  setCurrentPage(newPage);
  dispatch(
   getAllServices(
    newPage,
    rowPerPage,
    searchRef?.current?.value,
    selectedPartner,
    currentType,
    from,
    to,
    status
   )
  );
 };

 const handleSearchPartner = (partnerIdValue: string) => {
  setCurrentPage(0);
  setSelectedPartner(partnerIdValue);
  dispatch(
   getAllServicesWithUserid(
    currentPage,
    rowPerPage,
    searchRef?.current?.value || "",
    partnerIdValue,
    currentType,
    from,
    to,
    status
   )
  );
 };

 const handleSearchType = (type: string) => {
  setCurrentPage(0);
  setCurrentType(type);
  dispatch(
   getAllServicesWithUserid(
    currentPage,
    rowPerPage,
    searchRef?.current?.value || "",
    selectedPartner,
    type,
    from,
    to,
    status
   )
  );
 };

 const handleSearchFrom = async (newFrom: string) => {
  setCurrentPage(0);
  let fromDate = dayjs(newFrom).format("YYYY/MM/DD");
  setFrom(fromDate);
  await dispatch(
   getAllServices(
    currentPage,
    rowPerPage,
    searchRef?.current?.value,
    selectedPartner,
    currentType,
    fromDate,
    to,
    status
   )
  );
 };

 const handleSearchTo = async (newTo: string) => {
  setCurrentPage(0);
  let toDate = dayjs(newTo).format("YYYY/MM/DD");
  setTo(toDate);
  await dispatch(
   getAllServices(
    currentPage,
    rowPerPage,
    searchRef?.current?.value,
    selectedPartner,
    currentType,
    from,
    toDate,
    status
   )
  );
 };

 const handleResetFilters = async () => {
  setCurrentPage(0);
  setTo("");
  setFrom("");
  setCurrentType("");
  await dispatch(
   getAllServices(
    currentPage,
    rowPerPage,
    searchRef?.current?.value,
    selectedPartner,
    "",
    "",
    "",
    false
   )
  );
 };

 return (
  <Container maxWidth="xl">
   {isLoading ? (
    <SkeletonProfile id="app.services" />
   ) : (
    <Stack>
     <Stack justifyContent="space-between" alignItems="flex-end" direction="row" pb={1}>
      <Typography fontWeight="bold" fontSize={16} textTransform="uppercase">
       <FormattedMessage id="app.services" />
       {` (${servicesNumber})`}
      </Typography>
      <AdminWrapper>
       <AddService customerList={customerList} />
      </AdminWrapper>
     </Stack>
     <Paper elevation={0} sx={{ borderRadius: "10px", boxShadow: 0 }}>
      <Stack justifyContent="flex-start" alignItems="center" p={2} direction="row">
       <TextField
        size="small"
        autoComplete="off"
        inputRef={searchRef}
        label={<FormattedMessage id="search.service" />}
        value={searchValue}
        InputLabelProps={{ shrink: true }}
        InputProps={{ startAdornment: <SearchIcon color="disabled" /> }}
        onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
       />
       <AdminWrapper sx={{ ml: 2 }}>
        <SearchForPartner
         contactList={customerList}
         handleSearchPartner={(partner) => handleSearchPartner(partner)}
        />
       </AdminWrapper>
      </Stack>
      <Accordion>
       <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <FormattedMessage id="app.search.moreFilters" />
       </AccordionSummary>
       <AccordionDetails>
        <Stack spacing={2}>
         <Autocomplete
          autoHighlight
          size="small"
          onChange={(e, value) => (isNil(value) ? handleSearchType("") : handleSearchType(value))}
          options={typeList}
          value={currentType}
          renderInput={(params) => (
           <TextField
            {...params}
            label={intl.formatMessage({ id: "app.search.type" })}
            InputLabelProps={{ shrink: true }}
           />
          )}
         />
         <Stack direction="row" spacing={2}>
          {desktopViewPort ? (
           <DatePicker
            label={intl.formatMessage({ id: "app.search.from" })}
            value={from}
            views={["day", "month", "year"]}
            onChange={(newValue) =>
             isNil(newValue) ? handleSearchFrom("") : handleSearchFrom(newValue)
            }
           />
          ) : (
           <MobileDatePicker
            label={intl.formatMessage({ id: "app.search.from" })}
            value={from}
            views={["day", "month", "year"]}
            onChange={(newValue) =>
             isNil(newValue) ? handleSearchFrom("") : handleSearchFrom(newValue)
            }
           />
          )}
          {desktopViewPort ? (
           <DatePicker
            label={intl.formatMessage({ id: "app.search.to" })}
            value={to}
            views={["day", "month", "year"]}
            onChange={(newValue) =>
             isNil(newValue) ? handleSearchTo("") : handleSearchTo(newValue)
            }
           />
          ) : (
           <MobileDatePicker
            label={intl.formatMessage({ id: "app.search.to" })}
            value={to}
            views={["day", "month", "year"]}
            onChange={(newValue) =>
             isNil(newValue) ? handleSearchTo("") : handleSearchTo(newValue)
            }
           />
          )}
          <FormControlLabel
           control={
            <Checkbox
             checked={status}
             onChange={(e) => {
              setStatus(e.target.checked);
              setCurrentPage(0);
              setDataToReload(true);
             }}
            />
           }
           label={intl.formatMessage({ id: "profile.service.statusFilter" })}
          />
          <Button variant="kxActionButton" onClick={handleResetFilters}>
           <FormattedMessage id="app.search.removeFilters" />
          </Button>
         </Stack>
        </Stack>
       </AccordionDetails>
      </Accordion>
      <Divider sx={{ borderColor: "#EF711A" }} />
      <Stack>
       <SortingTable
        tableData={servicesTable}
        maxWidth={650}
        caller="services"
        listToShow={servicesList}
       />
       <TablePagination
        component="div"
        count={servicesNumber || 0}
        page={currentPage}
        rowsPerPage={rowPerPage}
        onPageChange={handleOnPageChange}
        onRowsPerPageChange={handleOnRowsPerPageChange}
       />
      </Stack>
     </Paper>
    </Stack>
   )}
  </Container>
 );
};

export default Services;
