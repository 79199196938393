import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const DockerIcon = createSvgIcon(
 <svg
  width="20"
  height="20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  x="0px"
  y="0px"
  viewBox="0 0 122.88 78.5"
  xmlSpace="preserve">
  <g>
   <path
    fillRule="evenodd"
    clipRule="evenodd"
    fill="currentColor"
    d="M48.17,0.36l73.7,13.39c0.54,0.1,1,0.45,1,1V63.6c0,0.55-0.46,0.91-1,1l-73.7,13.47c-0.54,0.1-1-0.45-1-1V1.36 C47.17,0.81,47.63,0.26,48.17,0.36L48.17,0.36z M0.69,7.06l10.03-1.67v67.59L0.69,71.28C0.31,71.21,0,70.97,0,70.59V7.75 C0,7.37,0.31,7.13,0.69,7.06L0.69,7.06z M14.6,4.74l11.44-1.91v72.75L14.6,73.65V4.74L14.6,4.74z M29.93,2.19l13.11-2.18 c0.37-0.06,0.69,0.31,0.69,0.69V77.8c0,0.38-0.31,0.75-0.69,0.69l-13.11-2.23V2.45V2.19L29.93,2.19z M119.34,18.2v42.05h-1.3V18.2 H119.34L119.34,18.2z M57.63,9.83v60.06h-3.89V8.63L57.63,9.83L57.63,9.83z M68.28,10.82V68.3h-3.57V10.36L68.28,10.82L68.28,10.82 z M77.81,12.36v54.33h-3.24V11.75L77.81,12.36L77.81,12.36z M86.31,13.69v51.83h-2.92V13.01L86.31,13.69L86.31,13.69z M94.15,14.95 v49.13h-2.6V14.41L94.15,14.95L94.15,14.95z M101.38,15.86v47.11h-2.27V15.34L101.38,15.86L101.38,15.86z M107.86,17.04v44.92 h-1.95V16.43L107.86,17.04L107.86,17.04z M113.9,18.06v42.97h-1.62V17.31L113.9,18.06L113.9,18.06z"
   />
  </g>
 </svg>,
 "Docker"
);
