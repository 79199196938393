import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

import { ISnapshotDataset } from "types/api/addonsApiInterface";

export const getSnapshots = (state: AppState) => state.snapshot;

export const getSnapshotsNumber = createSelector(getSnapshots, (snapshots) => {
 return snapshots?.totalCount;
});

export const getSnapshotsDataset = createSelector(getSnapshots, (snapshots) => {
 return (
  snapshots?.dataset?.map((snapshots: ISnapshotDataset) => {
   return {
    autorenew: snapshots.autorenew,
    created: snapshots.created,
    description: snapshots.description,
    disk_size: snapshots.disk_size,
    expire: snapshots.expire,
    id: snapshots.id,
    image_size: snapshots.image_size,
    price: snapshots.price,
    project_id: snapshots.project_id,
    protection: snapshots.protection,
    server_id: snapshots.server_id || 0,
    service_status: snapshots.service_status,
    snapshot_id: snapshots.snapshot_id,
    status: snapshots.status,
    type: snapshots.type,
    userid: snapshots.userid,
    personal_name: snapshots.personal_name,
    provider: snapshots.provider
   };
  }) || []
 );
});
