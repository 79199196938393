import React, { ReactElement, useEffect, useState } from "react";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

import Host from "components/profile/hosts/Host";
import SkeletonProfile from "components/shared/skeletons/SkeletonProfile";

import { getAllHost } from "redux/handlers/hostHandler";

import { useAppDispatch } from "hooks/reduxHook";

const HostPage = (): ReactElement => {
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const dispatch = useAppDispatch();

 useEffect(() => {
  (async () => {
   await dispatch(getAllHost(0, 10));
   setIsLoading(false);
  })();
 }, []);

 return (
  <Container maxWidth="xl">
   <Stack>{isLoading ? <SkeletonProfile id="app.host" /> : <Host isLoading={isLoading} />}</Stack>
  </Container>
 );
};

export default HostPage;
