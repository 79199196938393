import React, { ReactElement, useContext, useEffect, useState } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

import Badge from "@mui/material/Badge";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { popoverClasses } from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";

import {
 getNotifications,
 postDeleteAllNotific,
 postDeleteNotification,
 postMarkAllAsRead,
 postMarkAsRead
} from "redux/handlers/notificationHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { INotification } from "types/api/notificationApiInterface";

const HeaderNotification = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const { socket } = useContext(AppContext);

 const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
 const [notificationList, setNotificationList] = useState<Array<INotification>>([]);
 const [notificationNumber, setNotificationNumber] = useState<number>(0);
 const [reloadData, setReloadData] = useState<boolean>(true);
 const open = Boolean(anchorEl);

 const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  setAnchorEl(event.currentTarget);
 };
 const handleClose = () => {
  setAnchorEl(null);
 };

 useEffect(() => {
  if (!isNil(socket)) {
   socket.on("app_notify", async (value): Promise<void> => {
    if (value?.completed) {
     setReloadData(true);
    }
   });
  }
  return () => {
   if (!isNil(socket)) socket.off("app_notify");
  };
 }, [socket]);

 useEffect(() => {
  (async () => {
   if (reloadData) {
    let notList = await getNotifications();
    setNotificationList(notList);
    setNotificationNumber(notList.filter((element) => !element.isSeen).length);
    setReloadData(false);
   }
  })();
 }, [reloadData]);

 const handleReadNotification = async (id: number) => {
  await dispatch(postMarkAsRead(id));
  setReloadData(true);
 };

 const handleDeleteNotification = async (id: number) => {
  await dispatch(postDeleteNotification(id));
  setReloadData(true);
 };

 const handleMarkAllAsRead = async () => {
  await dispatch(postMarkAllAsRead());
  setReloadData(true);
 };

 const handleDeleteAll = async () => {
  await dispatch(postDeleteAllNotific());
  setReloadData(true);
 };

 const RenderNotifications = ({ notification }: { notification: INotification }): ReactElement => {
  if (notification.isSeen) {
   return (
    <Stack sx={{ width: "100%" }}>
     <Stack direction="row" spacing={2} justifyContent="space-between">
      <Stack>
       <Typography>{notification.action}</Typography>
       <Typography variant="subtitle2">{notification.text}</Typography>
      </Stack>
      <Stack>
       <IconButton
        onClick={(e) => {
         e.stopPropagation();
         handleDeleteNotification(notification.id);
        }}>
        <IconSelector icon="DeleteIcon" props={{ color: "error", sx: { fontSize: 15 } }} />
       </IconButton>
      </Stack>
     </Stack>
     <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" spacing={1} alignItems="center">
       <NotificationsIcon color="disabled" sx={{ fontSize: 10 }} />
       <Typography variant="caption">Già letta</Typography>
      </Stack>
      <Typography variant="caption">
       <FormattedDate value={notification.createdAt} />
      </Typography>
     </Stack>
    </Stack>
   );
  } else {
   return (
    <Tooltip title="Segna come già letta">
     <Stack sx={{ width: "100%" }} onClick={() => handleReadNotification(notification.id)}>
      <Stack direction="row" spacing={2} justifyContent="space-between">
       <Stack>
        <Typography>{notification.action}</Typography>
        <Typography variant="subtitle2">{notification.text}</Typography>
       </Stack>
       <Stack>
        <IconButton
         onClick={(e) => {
          e.stopPropagation();
          handleDeleteNotification(notification.id);
         }}>
         <IconSelector icon="DeleteIcon" props={{ color: "error", sx: { fontSize: 15 } }} />
        </IconButton>
       </Stack>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
       <Stack direction="row" spacing={1} alignItems="center">
        <NotificationsActiveIcon color="success" sx={{ fontSize: 10 }} />
        <Typography variant="caption">Non letta</Typography>
       </Stack>
       <Typography variant="caption">
        <FormattedDate value={notification.createdAt} />
       </Typography>
      </Stack>
     </Stack>
    </Tooltip>
   );
  }
 };

 return (
  <>
   <IconButton
    size="large"
    aria-label="show 17 new notifications"
    color="inherit"
    onClick={handleClick}>
    <Badge badgeContent={notificationNumber} color="error">
     <IconSelector icon="BellIcon" props={{ fontSize: "small" }} />
    </Badge>
   </IconButton>
   <Menu
    open={open}
    anchorEl={anchorEl}
    onClose={handleClose}
    elevation={1}
    sx={{
     [`& .${popoverClasses.paper}`]: {
      borderRadius: 1
     }
    }}
    anchorOrigin={{
     vertical: "bottom",
     horizontal: "left"
    }}
    transformOrigin={{
     vertical: "top",
     horizontal: "left"
    }}>
    <Stack
     alignItems="center"
     direction="row"
     px={2}
     justifyContent={notificationNumber !== 0 ? "space-between" : "center"}>
     <Typography>{intl.formatMessage({ id: "app.notification" })}</Typography>
     <Stack direction="row" spacing={1}>
      {notificationNumber !== 0 && (
       <Tooltip title="Segna tutte come lette">
        <IconButton onClick={handleMarkAllAsRead}>
         <MarkChatReadIcon sx={{ fontSize: 15 }} />
        </IconButton>
       </Tooltip>
      )}
      {notificationNumber !== 0 && (
       <Tooltip title="Elimina tutte">
        <IconButton onClick={handleDeleteAll}>
         <DeleteSweepIcon sx={{ fontSize: 15 }} />
        </IconButton>
       </Tooltip>
      )}
     </Stack>
    </Stack>
    <Divider flexItem />
    <MenuList dense>
     {notificationList.length > 0 ? (
      notificationList.map((notification, index) => {
       return (
        <MenuItem
         key={`notification-dropdown-${index}`}
         divider
         sx={{ backgroundColor: notification.isSeen ? "#F0F0F0" : "white" }}>
         <RenderNotifications notification={notification} />
        </MenuItem>
       );
      })
     ) : (
      <Stack px={2}>
       <Typography variant="caption" fontStyle="italic" sx={{ opacity: "0.5" }}>
        <FormattedMessage id="notifications.noNotificationsFound" />
       </Typography>
      </Stack>
     )}
    </MenuList>
   </Menu>
  </>
 );
};

export default HeaderNotification;
