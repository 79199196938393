import React, { ReactElement } from "react";

import Skeleton from "@mui/material/Skeleton";

type Props = {
 numberOfRows?: number;
 tableHeight?: number;
};
const SkeletonTable = ({ numberOfRows = 4, tableHeight = 45 }: Props): ReactElement => {
 return (
  <>
   {Array(numberOfRows)
    .fill(0)
    .map((_, index) => (
     <Skeleton
      key={`skeleton-index-${index}`}
      variant="rectangular"
      width="100%"
      height={tableHeight}
      sx={{ mt: 1 }}
     />
    ))}
  </>
 );
};

export default SkeletonTable;
