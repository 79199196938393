import React, { ReactElement, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import useDebounce from "react-use/lib/useDebounce";
import { handleChangePageNumber } from "helpers/pagination";

import SearchIcon from "@mui/icons-material/Search";

import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { profileOrdersData } from "constants/tables";

import AdminWrapper from "components/shared/admin/AdminWrapper";
import SearchForPartner from "components/shared/search/SearchForPartner";
import SortingTable from "components/shared/tables/SortingTable";

import { getProfileOrders } from "redux/handlers/profileOrdersHandler";
import { getUsersList } from "redux/handlers/userHandler";

import { getIsAdmin } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IProfileOrders } from "types/api/profileOrdersApiInterface";
import { IUsersList } from "types/api/userApiInterface";

const Orders = ({ orders }: { orders: IProfileOrders }): ReactElement => {
 const searchRef = useRef<HTMLInputElement>(null);
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const isAdmin = useSelector(getIsAdmin);

 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);
 const [searchValue, setSearchValue] = useState<string>("");
 const [profileOrders, setProfileOrders] = useState<IProfileOrders>(orders);
 const [customerList, setCustomerList] = useState<Array<IUsersList>>([]);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [selectedPartner, setSelectedPartner] = useState<string>("");

 useEffect(() => {
  (async () => {
   isAdmin && setCustomerList(await dispatch(getUsersList()));
   setIsLoading(false);
  })();
 }, []);

 useDebounce(
  async () => {
   if (!isLoading) {
    setCurrentPage(0);
    setProfileOrders(
     await dispatch(
      getProfileOrders(currentPage, rowPerPage, searchRef?.current?.value, selectedPartner)
     )
    );
   }
  },
  1000,
  [searchValue]
 );

 const handleOnRowsPerPageChange = async (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const data = handleChangePageNumber(parseInt(event.target.value, 10), currentPage, rowPerPage);
  setRowPerPage(data.newRow);
  setCurrentPage(Number(data.newPage.toFixed(0)));

  setProfileOrders(
   await dispatch(
    getProfileOrders(
     Number(data.newPage.toFixed(0)),
     parseInt(event.target.value, 10),
     searchRef?.current?.value,
     selectedPartner
    )
   )
  );
 };

 const handleOnPageChange = async (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  setCurrentPage(newPage);
  setProfileOrders(
   await dispatch(getProfileOrders(newPage, rowPerPage, searchRef?.current?.value, selectedPartner))
  );
 };

 const handleSearchPartner = async (partnerIdValue: string) => {
  setCurrentPage(0);
  setSelectedPartner(partnerIdValue);
  setProfileOrders(
   await dispatch(
    getProfileOrders(currentPage, rowPerPage, searchRef?.current?.value || "", partnerIdValue)
   )
  );
 };

 const handleReload = async () => {
  setCurrentPage(0);
  setProfileOrders(
   await dispatch(
    getProfileOrders(currentPage, rowPerPage, searchRef?.current?.value || "", selectedPartner)
   )
  );
 };

 return (
  <Stack>
   <Stack justifyContent="space-between" alignItems="flex-end" direction="row" pb={1}>
    <Typography fontWeight="bold" fontSize={16} textTransform="uppercase">
     <FormattedMessage id="app.orders" />
     {` (${profileOrders.totalCount || 0})`}
    </Typography>
   </Stack>
   <Paper elevation={0} sx={{ borderRadius: "10px", boxShadow: 0 }}>
    <Stack
     justifyContent="flex-start"
     alignItems={desktopViewPort ? "center" : "flex-start"}
     spacing={desktopViewPort ? 0 : 2}
     p={2}
     direction={desktopViewPort ? "row" : "column"}>
     <TextField
      size="small"
      autoComplete="off"
      inputRef={searchRef}
      label={<FormattedMessage id="search.orders" />}
      value={searchValue}
      InputLabelProps={{ shrink: true }}
      InputProps={{ startAdornment: <SearchIcon color="disabled" /> }}
      onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
     />
     <AdminWrapper sx={{ pl: 2 }}>
      <SearchForPartner
       contactList={customerList}
       handleSearchPartner={(partner) => handleSearchPartner(partner)}
      />
     </AdminWrapper>
    </Stack>
    <Divider sx={{ borderColor: "#EF711A" }} />
    <Stack>
     <SortingTable
      tableData={profileOrdersData}
      maxWidth={650}
      caller="profileOrders"
      listToShow={profileOrders.dataset}
      reloadData={handleReload}
     />
     <TablePagination
      component="div"
      count={profileOrders.totalCount || 0}
      page={currentPage}
      rowsPerPage={rowPerPage}
      onPageChange={handleOnPageChange}
      onRowsPerPageChange={handleOnRowsPerPageChange}
     />
    </Stack>
   </Paper>
  </Stack>
 );
};

export default Orders;
