import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import AddIcon from "@mui/icons-material/Add";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import AppModal from "components/shared/modal/AppModal";

import { postCreateCertificate } from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

const CreateCertificate = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => {
  reset();
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const { control, reset, watch, handleSubmit, formState } = useForm({
  defaultValues: {
   name: "",
   certificate: "",
   privateKey: ""
  }
 });

 const onSubmit: SubmitHandler<{
  certificate: string;
  privateKey: string;
  name: string;
 }> = async (data: { certificate: string; privateKey: string; name: string }) => {
  setLoading(true);
  await dispatch(postCreateCertificate(data.certificate, data.name, data.privateKey));
  handleClose();
  setLoading(false);
 };

 const checkDisabled = (): boolean => {
  return watch("name") === "" || watch("certificate") === "" || watch("privateKey") === "";
 };

 return (
  <>
   <Button onClick={handleOpen} variant="kxActionButton" endIcon={<AddIcon />}>
    <FormattedMessage id="addons.certificates.createCertificate" />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "addons.certificates.createCertificate" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={loading || checkDisabled()}>
    <Stack spacing={2}>
     <Controller
      name="name"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
       <TextField
        {...field}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
         e.stopPropagation();
        }}
        label={intl.formatMessage({ id: "addons.certificates.name" })}
        InputLabelProps={{ shrink: true }}
        error={formState.isDirty && !!formState?.errors?.name}
        helperText={
         formState.isDirty &&
         !!formState?.errors?.name &&
         intl.formatMessage({ id: "addons.certificates.invalidName" })
        }
       />
      )}
     />
     <Controller
      name="certificate"
      control={control}
      rules={{
       required: true,
       validate: () => watch("certificate").includes("-----BEGIN CERTIFICATE-----")
      }}
      render={({ field }) => (
       <TextField
        {...field}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
         e.stopPropagation();
        }}
        multiline
        maxRows={6}
        minRows={6}
        label={intl.formatMessage({ id: "addons.certificates.certificate" })}
        InputLabelProps={{ shrink: true }}
        error={formState.isDirty && !!formState?.errors?.certificate}
        helperText={
         formState.isDirty &&
         !!formState?.errors?.certificate &&
         intl.formatMessage({ id: "addons.certificates.invalidCertificate" })
        }
       />
      )}
     />
     <Controller
      name="privateKey"
      control={control}
      rules={{
       required: true,
       validate: () => watch("privateKey").includes("-----BEGIN PRIVATE KEY-----")
      }}
      render={({ field }) => (
       <TextField
        {...field}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
         e.stopPropagation();
        }}
        multiline
        maxRows={6}
        minRows={6}
        label={intl.formatMessage({ id: "addons.certificates.privateKey" })}
        InputLabelProps={{ shrink: true }}
        error={formState.isDirty && !!formState?.errors?.privateKey}
        helperText={
         formState.isDirty &&
         !!formState?.errors?.privateKey &&
         intl.formatMessage({ id: "addons.certificates.invalidPrivateKey" })
        }
       />
      )}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default CreateCertificate;
