import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import AppModal from "components/shared/modal/AppModal";

import {
 getAllSites,
 getPhpVersions,
 getRepositorys,
 postCreateContainer
} from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IDockerPhp, IDockerRepo, IDockerSite } from "types/api/dockerApiInterface";

const CreateContainer = ({ id }: { id: number }): ReactElement => {
 const dispatch = useAppDispatch();
 const intl = useIntl();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [phpVers, setPhpVers] = useState<Array<IDockerPhp>>([]);
 const [repository, setRepository] = useState<Array<IDockerRepo>>([]);
 const [sites, setSites] = useState<Array<IDockerSite>>([]);

 const handleOpen = async () => {
  reset();
  setPhpVers(await dispatch(getPhpVersions()));
  setRepository(await dispatch(getRepositorys()));
  setSites(await dispatch(getAllSites(id)));
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const { control, reset, watch, setValue, handleSubmit } = useForm({
  defaultValues: {
   name: "",
   php: "",
   repository: "",
   site: ""
  }
 });

 const onSubmit: SubmitHandler<{
  name: string;
  php: string;
  repository: string;
  site: string;
 }> = async (data: { name: string; php: string; repository: string; site: string }) => {
  setLoading(true);
  await dispatch(postCreateContainer(id, data.name, data.repository, data.php, data.site));
  handleClose();
  setLoading(false);
 };

 const checkDisabled = (): boolean => {
  return watch("name") === "" || watch("php") === "" || watch("repository") === "";
 };

 return (
  <>
   <Button onClick={handleOpen} variant="kxActionButton">
    <FormattedMessage id="docker.container.addContainer" />
   </Button>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.container.addContainer" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={loading || checkDisabled() || sites.length === 0}>
    {sites.length === 0 ? (
     <Alert severity="error">
      <FormattedMessage id="docker.container.cannotCreateContainer" />
     </Alert>
    ) : (
     <Stack spacing={2}>
      <Controller
       name="name"
       control={control}
       rules={{ required: true }}
       render={({ field }) => (
        <TextField
         {...field}
         autoComplete="off"
         label={intl.formatMessage({ id: "docker.container.name" })}
         InputLabelProps={{ shrink: true }}
         onChange={({ currentTarget }) => setValue("name", currentTarget.value)}
        />
       )}
      />
      <Controller
       name="php"
       control={control}
       rules={{ required: true }}
       render={({ field }) => (
        <FormControl {...field} fullWidth>
         <InputLabel>
          <FormattedMessage id="docker.container.tech" />
         </InputLabel>
         <Select
          value={watch("php")}
          label={<FormattedMessage id="docker.container.tech" />}
          onChange={(e) => setValue("php", e.target.value)}>
          {phpVers.map((element, index) => {
           return (
            <MenuItem value={element.id} key={`php-vers-${index}`}>
             {element.name}
            </MenuItem>
           );
          })}
         </Select>
        </FormControl>
       )}
      />
      <Controller
       name="repository"
       control={control}
       rules={{ required: true }}
       render={({ field }) => (
        <FormControl {...field} fullWidth>
         <InputLabel>
          <FormattedMessage id="docker.container.repository" />
         </InputLabel>
         <Select
          value={watch("repository")}
          label={<FormattedMessage id="docker.container.repository" />}
          onChange={(e) => setValue("repository", e.target.value)}>
          {repository.map((element, index) => {
           return (
            <MenuItem value={element.name} key={`repository-${index}`}>
             {element.name}
            </MenuItem>
           );
          })}
         </Select>
        </FormControl>
       )}
      />
      <Controller
       name="site"
       control={control}
       rules={{ required: true }}
       render={({ field }) => (
        <FormControl {...field} fullWidth>
         <InputLabel>
          <FormattedMessage id="docker.container.site" />
         </InputLabel>
         <Select
          value={watch("site")}
          label={<FormattedMessage id="docker.container.site" />}
          onChange={(e) => setValue("site", e.target.value)}>
          {sites.map((element, index) => {
           return (
            <MenuItem value={element.site_name} key={`site-${index}`}>
             {element.site_name}
            </MenuItem>
           );
          })}
         </Select>
        </FormControl>
       )}
      />
     </Stack>
    )}
   </AppModal>
  </>
 );
};

export default CreateContainer;
