export interface IUser {
 authenticated: boolean;
 id: number;
 userid: string;
 isAdmin: boolean;
 name: string | null;
 email: string;
 personalName: string | null;
 firstname: string;
 lastname: string;
 companyName: string | null;
 address: string | null;
 city: string | null;
 state: string | null;
 zip: string | null;
 country: string | null;
 telephone: string | null;
 partitaIVA: string | null;
 language: string | null;
 balance: number;
 cash: number;
 bonus: number;
 welcome: number;
 telegramID: string | null;
 logo: string | null;
 logolink: string | null;
 footer: string | null;
 contract: boolean;
 mailtype: "pec" | "sdi";
 companymail: string | null;
 telcheck: string | null;
 propic: string | null;
 color1: string | null;
 color2: string | null;
 color3: string | null;
 border_radius: string | null;
 external_css: string | null;
 tax: number | null;
 sdi: string | null;
 pec: string | null;
 contract_accepted: boolean;
}

export interface IProfile {
 firstname: string;
 lastname: string;
 companyName: string;
 email: string;
 phone: string | null;
 street: string | null;
 city: string | null;
 country: string | null;
 zipCode: string | null;
 vatCode: string | null;
 sdi: string | null;
 pec: string | null;
}

/* eslint-disable no-unused-vars */
export enum EServiceEndpoints {
 FLOATINGIP = "/services/renewfloatingip",
 SERVER = "/services/manualserverrenew",
 DOMAIN = "/services/renewdomain",
 VOLUMES = "/services/renewvolume",
 SNAPSHOT = "/services/renewsnapshot",
 CLOUDBOX = "/services/renewcloudbox",
 BAREMETAL = "/services/renewbarmetal",
 NETWORK = "/services/renewnetwork",
 FIREWALL = "/services/renewfirewall",
 BALANCER = "/services/renewloadbalancer"
}
