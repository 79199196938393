import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { formatIpRange } from "helpers/addons";

import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";

import IconSelector from "components/shared/images/IconSelector";

import { getBalancersNotPages } from "redux/handlers/addonsHandle";
import { getAllServersNotPaged } from "redux/handlers/serverHandler";

import { getNetworksDataset } from "redux/selectors/networksSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IAllBalancers } from "types/api/addonsApiInterface";
import { IAllServers } from "types/api/serversApiInterface";

import AttachBalancerToSubnet from "../actions/AttachBalancerToSubnet";
import AttachServerToSubnet from "../actions/AttachServerToSubnet";
import CreateSubnet from "../actions/CreateSubnet";
import DeleteSubnet from "../actions/DeleteSubnet";
import DetachBalancerToSubnet from "../actions/DetachBalancerToSubnet";
import DetachServerToSubnet from "../actions/DetachServerToSubnet";

const NetworkSubnet = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const params = useParams<{ id: string; section: string }>();

 const networkData = useSelector(getNetworksDataset).find(
  (element) => element.id === Number(params.id)
 );

 const [servers, setServers] = useState<Array<IAllServers>>([]);
 const [balancers, setBalancers] = useState<Array<IAllBalancers>>([]);
 const [loading, setLoading] = useState<boolean>(true);

 useEffect(() => {
  (async () => {
   setLoading(true);
   const bal = await dispatch(getBalancersNotPages());
   if (bal) setBalancers(bal);
   setServers(await dispatch(getAllServersNotPaged()));
   setLoading(false);
  })();
 }, []);

 return (
  <Paper elevation={0} sx={{ mt: 1, borderRadius: "10px", boxShadow: 0 }}>
   <Stack alignItems="center" justifyContent="flex-start" direction="row" px={2} py={1}>
    <IconSelector icon="KeyIcon" props={{ fontSize: "medium", color: "secondary" }} />
    <Typography variant="h6" component="h2" ml={1}>
     <FormattedMessage id="addons.network.subnet" />{" "}
    </Typography>
   </Stack>
   {loading ? (
    <CircularProgress />
   ) : (
    <Stack p={2}>
     {networkData?.subnets?.length === 0 ? (
      <Alert severity="info">
       <Stack spacing={1} alignItems="flex-start">
        <Typography>
         <FormattedMessage id="addons.network.noSubnetFound" />
        </Typography>
        <CreateSubnet id={networkData?.id || 0} ip={formatIpRange(networkData?.ip_range || "")} />
       </Stack>
      </Alert>
     ) : (
      networkData?.subnets.map((element, index) => {
       return (
        <Stack key={`subnet-index-${index}`}>
         <Grid container>
          <Grid xs={12}>
           <Stack direction="row" alignItems="center">
            <Stack
             direction="row"
             justifyContent="space-between"
             alignItems="center"
             px={2}
             width="40%"
             sx={{
              borderRadius: 2,
              border: "2px solid lightgrey"
             }}>
             <Typography>{element.ip_range}</Typography>
             <DeleteSubnet id={networkData.id || 0} subnet_id={element.id} />
            </Stack>
           </Stack>
          </Grid>
          <Grid xs={12}>
           <Stack py={2}>
            {networkData.servers.length > 0 ? (
             <Card>
              <CardHeader
               avatar={<IconSelector icon="ServerIcon" />}
               title={intl.formatMessage({ id: "addons.network.serverSection" })}
               subheader={intl.formatMessage({ id: "addons.network.serverSelectionMessage" })}
              />
              <CardContent>
               {networkData.servers.map((element, index) => {
                return (
                 <Stack key={`subnet-index-${index}`}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                   <Stack alignItems={"flex-start"} spacing={1}>
                    <Typography variant="subtitle2">
                     {servers.find((serv) => serv.server_id === element)?.server_name}
                    </Typography>
                    <Typography variant="caption">
                     {
                      servers
                       .find((serv) => serv.server_id === element)
                       ?.private_net.find((net) => net.network === networkData.network_id)?.ip
                     }
                    </Typography>
                   </Stack>
                   <DetachServerToSubnet
                    id={servers.find((serv) => serv.server_id === element)?.id || 0}
                    network={networkData.network_id}
                   />
                  </Stack>
                  <Divider />
                 </Stack>
                );
               })}
              </CardContent>
              <CardActions disableSpacing>
               <AttachServerToSubnet id={networkData.id || 0} servers={servers} />
              </CardActions>
             </Card>
            ) : (
             <Alert severity="info">
              <Stack>
               <Typography>
                <FormattedMessage id="addons.network.noServerAttachedToSubnet" />
               </Typography>
               <AttachServerToSubnet id={networkData.id || 0} servers={servers} />
              </Stack>
             </Alert>
            )}
           </Stack>
           <Stack>
            {networkData.load_balancers.length > 0 ? (
             <Card>
              <CardHeader
               avatar={<IconSelector icon="BalanceIcon" />}
               title={intl.formatMessage({ id: "addons.network.balancerSection" })}
               subheader={intl.formatMessage({ id: "addons.network.balancerSelectionMessage" })}
              />
              <CardContent>
               {networkData.load_balancers.map((element, index) => {
                return (
                 <Stack key={`subnet-index-${index}`}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                   <Stack alignItems={"flex-start"} spacing={1}>
                    <Typography variant="subtitle2">
                     {balancers.find((bal) => bal.load_balancer_id === element)?.name}
                    </Typography>
                    <Typography variant="caption">
                     {
                      balancers
                       .find((bal) => bal.load_balancer_id === element)
                       ?.private_net.find((element) => element.network === networkData.network_id)
                       ?.ip
                     }
                    </Typography>
                   </Stack>
                   <DetachBalancerToSubnet
                    id={balancers.find((bal) => bal.load_balancer_id === element)?.id || 0}
                    network={networkData.network_id}
                   />
                  </Stack>
                  <Divider />
                 </Stack>
                );
               })}
              </CardContent>
              <CardActions disableSpacing>
               <AttachBalancerToSubnet
                id={networkData.id || 0}
                balancers={balancers}
                net_id={networkData.network_id}
               />
              </CardActions>
             </Card>
            ) : (
             <Alert severity="info">
              <Stack>
               <Typography>
                <FormattedMessage id="addons.network.noBalancerAttachedToSubnet" />
               </Typography>
               <AttachBalancerToSubnet
                id={networkData.id || 0}
                balancers={balancers}
                net_id={networkData.network_id}
               />
              </Stack>
             </Alert>
            )}
           </Stack>
          </Grid>
         </Grid>
        </Stack>
       );
      })
     )}
    </Stack>
   )}
  </Paper>
 );
};

export default NetworkSubnet;
