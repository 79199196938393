import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const ProfileIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <circle cx="10" cy="10" r="10" fill="transparent" />
  <path
   d="M13.9384 7.25329C14.4664 4.96624 13.0404 2.68418 10.7533 2.15617C8.46628 1.62817 6.18422 3.05415 5.65621 5.3412C5.1282 7.62826 6.55419 9.91031 8.84124 10.4383C11.1283 10.9663 13.4103 9.54034 13.9384 7.25329Z"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
  <path
   d="M3 15C3 15 3.8 12 10.1 12C16.4 12 17 15 17 15"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
 </svg>,
 "Profile"
);
