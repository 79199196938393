import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import ReportProblemIcon from "@mui/icons-material/ReportProblem";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const NoServerFound = ({ type }: { type: string }): ReactElement => {
 const handleMessage = (): string => {
  switch (type) {
   case "webserver":
    return "app.noWebServerFoundForApp";
   case "mailserver":
    return "app.noMailServerFoundForApp";
   case "nameserver":
    return "app.noNameServerFoundForApp";
   default:
    return "app.noServerFoundForApp";
  }
 };

 return (
  <Paper elevation={0} sx={{ minHeight: 100, borderRadius: "10px", boxShadow: 0 }}>
   <Stack justifyContent="center" alignItems="center" p={3} spacing={2}>
    <ReportProblemIcon sx={{ color: "#E94040", fontSize: 40 }} />
    <Typography variant={"h6"}>
     <FormattedMessage id={handleMessage()} />
    </Typography>
   </Stack>
  </Paper>
 );
};

export default NoServerFound;
