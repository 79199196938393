import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import EmptyList from "components/shared/emptyList/EmptyList";
import IconSelector from "components/shared/images/IconSelector";

import { getAllServersNotPaged } from "redux/handlers/serverHandler";

import {
 getLoadBalancerDetails,
 getLoadBalancerTargets
} from "redux/selectors/loadBalancerSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IAllServers } from "types/api/serversApiInterface";

import CreateTarget from "./actions/CreateTarget";
import DeleteTarget from "./actions/DeleteTarget";
import ShowHealth from "./ShowHealth";

const LoadBalancerTargets = (): ReactElement => {
 const dispatch = useAppDispatch();

 const balancerData = useSelector(getLoadBalancerDetails);
 const targetsData = useSelector(getLoadBalancerTargets);

 const [servers, setServers] = useState<Array<IAllServers>>([]);
 const [loading, setLoading] = useState<boolean>(true);

 useEffect(() => {
  (async () => {
   setServers(await dispatch(getAllServersNotPaged()));
   setLoading(false);
  })();
 }, []);

 const checkForList = (): boolean => {
  return targetsData.length > 0;
 };

 return (
  <Paper elevation={0} sx={{ mt: 3, pb: 0.7, borderRadius: "10px", boxShadow: 0 }}>
   <Stack direction="row" justifyContent="space-between" alignItems="center">
    <Stack alignItems="center" justifyContent="flex-start" direction="row" px={2} py={1}>
     <IconSelector icon="KeyIcon" props={{ fontSize: "medium", color: "secondary" }} />
     <Typography variant="h6" component="h2" ml={1}>
      <FormattedMessage id="addons.loadBalancer.targets" />{" "}
     </Typography>
    </Stack>
    {checkForList() && <CreateTarget id={balancerData?.id || 0} />}
   </Stack>
   {checkForList() ? (
    !loading ? (
     <TableContainer component="div">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
       <TableHead>
        <TableRow sx={{ backgroundColor: "transparent" }}>
         <TableCell>
          <Typography variant="kxColoredSmall" fontWeight="bold">
           <FormattedMessage id="addons.loadBalancer.targets.name" />
          </Typography>
         </TableCell>
         <TableCell>
          <Typography variant="kxColoredSmall" fontWeight="bold">
           <FormattedMessage id="addons.loadBalancer.targets.type" />
          </Typography>
         </TableCell>
         <TableCell>
          <Typography variant="kxColoredSmall" fontWeight="bold">
           <FormattedMessage id="addons.loadBalancer.targets.status" />
          </Typography>
         </TableCell>
         <TableCell>
          <Typography variant="kxColoredSmall" fontWeight="bold">
           <FormattedMessage id="app.actions" />
          </Typography>
         </TableCell>
        </TableRow>
       </TableHead>
       <TableBody>
        {targetsData.map((row, index) => (
         <TableRow key={`cloudbox-list-${index}`}>
          <TableCell component="th" scope="row">
           <Stack direction="row" alignItems="center" spacing={2}>
            <Typography fontWeight="bold">
             {row.type === "server"
              ? servers.find((element) => element.server_id === row.server_id)?.server_name
              : row.ip}
            </Typography>
           </Stack>
          </TableCell>
          <TableCell component="th" scope="row">
           <Typography>{row.type}</Typography>
          </TableCell>
          <TableCell component="th" scope="row">
           <ShowHealth status={row.health_status} />
          </TableCell>
          <TableCell>
           <DeleteTarget id={row.id || 0} type={row.type} server_id={row.server_id || 0} />
          </TableCell>
         </TableRow>
        ))}
       </TableBody>
      </Table>
     </TableContainer>
    ) : (
     <Stack direction="row" justifyContent="center">
      <CircularProgress />
     </Stack>
    )
   ) : (
    <Stack>
     <EmptyList />
     <CreateTarget id={balancerData?.id || 0} />
    </Stack>
   )}
  </Paper>
 );
};

export default LoadBalancerTargets;
