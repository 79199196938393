import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postUpdateCloudboxSnapshotSchedulation } from "redux/handlers/addonsHandle";

import { getCloudboxDataset } from "redux/selectors/cloudboxSelector";

import { useAppDispatch } from "hooks/reduxHook";

const ChangeSnapshotPlan = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const params = useParams<{ id: string; section: string }>();
 const cloudboxData = useSelector(getCloudboxDataset).find(
  (element) => element.id === Number(params.id)
 );
 const snapshotPlan = cloudboxData?.snapshot_plan[0];

 const [open, setOpen] = useState<boolean>(false);
 const [enableSnapshot, setEnableSnapshot] = useState<boolean>(false);
 const [selectedType, setSelectedType] = useState<string>("");
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => {
  if (snapshotPlan && snapshotPlan.status === "enabled") {
   setEnableSnapshot(true);
  }
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(
   postUpdateCloudboxSnapshotSchedulation(
    cloudboxData?.id || 0,
    cloudboxData?.cloudbox_id || 0,
    enableSnapshot ? "enabled" : "disabled",
    selectedType === "weekly" ? 1 : null,
    selectedType === "monthly" ? 1 : null
   )
  );
  handleClose();
  setLoading(false);
 };

 const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setSelectedType((event.target as HTMLInputElement).value);
 };

 const checkDisabled = (): boolean => {
  return loading || (enableSnapshot && selectedType === "");
 };

 const RenderMessage = (): ReactElement => {
  switch (selectedType) {
   case "daily":
    return (
     <Alert severity="info">
      <FormattedMessage id="addons.cloudbox.snapshotDailyMessage" />
     </Alert>
    );
   case "weekly":
    return (
     <Alert severity="info">
      <FormattedMessage id="addons.cloudbox.snapshotWeeklyMessage" />
     </Alert>
    );
   case "monthly":
    return (
     <Alert severity="info">
      <FormattedMessage id="addons.cloudbox.snapshotMonthlyMessage" />
     </Alert>
    );
   default:
    return <></>;
  }
 };

 return cloudboxData ? (
  <>
   <Button variant="kxActionButton" onClick={() => handleOpen()}>
    <FormattedMessage id="addons.cloudbox.planSnapshot" />
   </Button>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "addons.cloudbox.planSnapshot" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={checkDisabled()}>
    <Stack spacing={1}>
     <Alert severity="info">
      <FormattedMessage id="addons.cloudbox.planSnapshotMessage" />
     </Alert>
     <Stack direction="row" spacing={1} alignItems="center">
      <Typography>Status:</Typography>
      <Switch
       checked={enableSnapshot}
       onChange={(event) => {
        setEnableSnapshot(event.target.checked);
        setSelectedType("");
       }}
      />
     </Stack>
     {enableSnapshot && <Divider />}
     {enableSnapshot && (
      <FormControl>
       <FormLabel>Intervallo di tempo</FormLabel>
       <RadioGroup value={selectedType} onChange={handleChange} name="radio-buttons-group">
        <FormControlLabel value="daily" control={<Radio />} label="Giornaliero" />
        <FormControlLabel value="weekly" control={<Radio />} label="Settimanale" />
        <FormControlLabel value="monthly" control={<Radio />} label="Mensile" />
       </RadioGroup>
      </FormControl>
     )}
     <RenderMessage />
    </Stack>
   </AppModal>
  </>
 ) : (
  <></>
 );
};

export default ChangeSnapshotPlan;
