import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

import { ICloudboxDataset } from "types/api/addonsApiInterface";

export const getCloudbox = (state: AppState) => state.cloudboxes;

export const getCloudboxNumber = createSelector(getCloudbox, (cloudbox) => {
 return cloudbox?.totalCount || 0;
});

export const getCloudboxConfigurator = createSelector(getCloudbox, (cloudbox) => {
 return cloudbox?.dataset.map((cloudbox: ICloudboxDataset) => {
  return {
   id: cloudbox.id,
   name: cloudbox.name,
   userid: cloudbox.userid,
   cloudbox_id: cloudbox.cloudbox_id,
   location: cloudbox.location,
   product: cloudbox.product,
   status: cloudbox.status
  };
 });
});

export const getCloudboxDataset = createSelector(getCloudbox, (cloudbox) => {
 return (
  cloudbox?.dataset?.map((cloudbox: ICloudboxDataset) => {
   return {
    cancelled: cloudbox.cancelled,
    cloudbox_id: cloudbox.cloudbox_id,
    createdAt: cloudbox.createdAt,
    disk_quota: cloudbox.disk_quota,
    disk_usage: cloudbox.disk_usage,
    disk_usage_data: cloudbox.disk_usage_data,
    disk_usage_snapshots: cloudbox.disk_usage_snapshots,
    expire: cloudbox.expire,
    external_reachability: cloudbox.external_reachability,
    host_system: cloudbox.host_system,
    id: cloudbox.id,
    linked_server: cloudbox.linked_server || 0,
    location: cloudbox.location,
    locked: cloudbox.locked,
    login: cloudbox.login,
    name: cloudbox.name,
    password: cloudbox.password || "",
    product: cloudbox.product,
    status: cloudbox.status,
    samba: cloudbox.samba,
    server: cloudbox.server,
    snapshot: cloudbox.snapshot,
    snapshot_plan: cloudbox.snapshot_plan,
    ssh: cloudbox.ssh,
    updatedAt: cloudbox.updatedAt,
    userid: cloudbox.userid || "",
    webdav: cloudbox.webdav,
    zfs: cloudbox.zfs,
    service_status: cloudbox.service_status,
    personal_name: cloudbox.personal_name,
    server_id: cloudbox.server_id
   };
  }) || []
 );
});
