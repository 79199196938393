import React, { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

import Teams from "components/profile/teams/Teams";
import SkeletonProfile from "components/shared/skeletons/SkeletonProfile";

import { getAllSecondLevelUsers } from "redux/handlers/teamsHandler";

import { getUserId } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

const TeamsPage = (): ReactElement => {
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const dispatch = useAppDispatch();

 const userId: number = useSelector(getUserId);

 useEffect(() => {
  (async () => {
   await dispatch(getAllSecondLevelUsers(0, 10, userId));
   setIsLoading(false);
  })();
 }, []);

 return (
  <Container maxWidth="xl">
   <Stack>{isLoading ? <SkeletonProfile id="profile.teams" /> : <Teams />}</Stack>
  </Container>
 );
};

export default TeamsPage;
