// One time slot every 30 minutes.
export const timeSlots = Array.from(new Array(24 * 2)).map(
 (_, index) => `${index < 20 ? "0" : ""}${Math.floor(index / 2)}:${index % 2 === 0 ? "00" : "30"}`
);

export const weekDays = [
 {
  label: "lunedì",
  key: "1"
 },
 {
  label: "martedì",
  key: "2"
 },
 {
  label: "mercoledì",
  key: "3"
 },
 {
  label: "giovedì",
  key: "4"
 },
 {
  label: "venedì",
  key: "5"
 },
 {
  label: "sabato",
  key: "6"
 },
 {
  label: "domenica",
  key: "7"
 }
];

export const volumesSteps = [
 {
  label: "volumes.add.selectName",
  description: "volumes.add.selectNameMessage"
 },
 {
  label: "volumes.add.datacenter",
  description: "volumes.add.datacenterMessage"
 },
 {
  label: "volumes.add.format",
  description: "volumes.add.formatMessage"
 },
 {
  label: "volumes.add.size",
  description: "volumes.add.sizeMessage"
 },
 {
  label: "volumes.add.server",
  description: "volumes.add.serverMessage"
 }
];

export const floatingIpSteps = [
 {
  label: "addons.floatingip.add.selectName",
  description: "addons.floatingip.add.selectNameMessage"
 },
 {
  label: "addons.floatingip.add.datacenter",
  description: "addons.floatingip.add.datacenterMessage"
 },
 {
  label: "addons.floatingip.add.type",
  description: "addons.floatingip.add.typeMessage"
 },
 {
  label: "addons.floatingip.add.server",
  description: "addons.floatingip.add.serverMessage"
 }
];

export const cloudboxSteps = [
 {
  label: "addons.cloudbox.add.selectName",
  description: "addons.cloudbox.add.selectNameMessage"
 },
 {
  label: "addons.cloudbox.add.datacenter",
  description: "addons.cloudbox.add.datacenterMessage"
 },
 {
  label: "addons.cloudbox.add.type",
  description: "addons.cloudbox.add.typeMessage"
 }
];

export const snapshotSteps = [
 {
  label: "server.add.fromSnapshot",
  description: "server.add.fromSnapshotMessage"
 },
 {
  label: "server.add.name",
  description: "server.add.nameDescription"
 }
];

export const networkSteps = [
 {
  label: "addons.network.name",
  description: "addons.network.nameMessage"
 },
 {
  label: "addons.network.ipRange",
  description: "addons.network.ipRangeDescription"
 }
];

export const loadBalancerSteps = [
 {
  label: "addons.loadBalancer.name",
  description: "addons.loadBalancer.nameMessage"
 },
 {
  label: "addons.loadBalancer.algorithm",
  description: "addons.loadBalancer.algorithmDescription"
 },
 {
  label: "addons.loadBalancer.type",
  description: "addons.loadBalancer.typeDescription"
 }
];

export const firewallSteps = [
 {
  label: "addons.firewall.description",
  description: "addons.firewall.descriptionMessage"
 },
 {
  label: "addons.firewall.name",
  description: "addons.firewall.nameDescription"
 }
];

export const firewallRuleProtocols = ["TCP", "UDP", "ICMP", "ESP", "GRE"];

export const cloudboxDetailsElements = [
 {
  section: "name",
  text: "addons.cloudbox.name"
 },
 {
  section: "password",
  text: "addons.cloudbox.password"
 },
 {
  section: "login",
  text: "addons.cloudbox.login"
 },
 {
  section: "server",
  text: "addons.cloudbox.server"
 }
];

export const cloudboxSettingsElements = [
 {
  section: "samba",
  text: "addons.cloudbox.samba"
 },
 {
  section: "webdav",
  text: "addons.cloudbox.webdav"
 },
 {
  section: "ssh",
  text: "addons.cloudbox.ssh"
 },
 {
  section: "external",
  text: "addons.cloudbox.external"
 }
];
