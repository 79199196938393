import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import Alert from "@mui/material/Alert";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import RemoveServer from "components/addons/cloudboxes/actions/RemoveServer";
import IconSelector from "components/shared/images/IconSelector";

import { getAllAddons } from "redux/handlers/addonsHandle";
import { getServerConnection } from "redux/handlers/serverHandler";

import { getServerDbId } from "redux/selectors/serversSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IAllAddonsDataset, ICloudDirectoryAPI } from "types/api/addonsApiInterface";

const ServerCloudboxes = (): ReactElement => {
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const id = useSelector(getServerDbId);

 const [serverCloudboxes, setServerCloudboxes] = useState<Array<IAllAddonsDataset>>([]);
 const [directory, setDirectory] = useState<Array<ICloudDirectoryAPI>>([]);

 useEffect(() => {
  (async () => {
   const addons = await dispatch(getAllAddons());
   setDirectory(await dispatch(getServerConnection(id)));

   setServerCloudboxes(addons ? addons.cloudboxes : []);
  })();
 }, []);

 return (
  <>
   <Paper elevation={0} sx={{ mt: 2, borderRadius: "10px", boxShadow: 0 }}>
    <Stack p={2} spacing={2}>
     <Stack direction="row" justifyContent={"space-between"}>
      <Stack direction="row" spacing={1}>
       <IconSelector icon="CameraAltIcon" props={{ fontSize: "medium", color: "secondary" }} />
       <Typography fontWeight="bold" fontSize={16} textTransform="uppercase">
        <FormattedMessage id="server.addons.cloudboxes" />
       </Typography>
      </Stack>
     </Stack>
     {directory.length === 0 ? (
      <Alert severity="warning">
       <FormattedMessage id="server.addons.cloudboxNotFound" />
      </Alert>
     ) : (
      <TableContainer component="div">
       <Table sx={{ minWidth: desktopViewPort ? 650 : 450 }} aria-label="simple table">
        <TableHead>
         <TableRow>
          <TableCell>
           <FormattedMessage id="server.addons.cloudboxes" />
          </TableCell>
          <TableCell>
           <FormattedMessage id="addons.cloudbox.status" />
          </TableCell>
          <TableCell>
           <FormattedMessage id="addons.cloudbox.directory" />
          </TableCell>
          <TableCell align="right">
           <FormattedMessage id="app.actions" />
          </TableCell>
         </TableRow>
        </TableHead>
        <TableBody>
         {directory.map((element, index) => (
          <TableRow key={`server-cloud-${index}`}>
           <TableCell>
            {serverCloudboxes.find((cloud) => cloud.id === element.cloudbox_id_db)?.name}
           </TableCell>
           <TableCell>
            <Chip label="connected" color="success" size="small" />
           </TableCell>
           <TableCell>
            <Typography variant="caption">{element.folder}</Typography>
           </TableCell>
           <TableCell align="right">
            <RemoveServer id={element.id} />
           </TableCell>
          </TableRow>
         ))}
        </TableBody>
       </Table>
      </TableContainer>
     )}
    </Stack>
   </Paper>
  </>
 );
};

export default ServerCloudboxes;
