/* eslint-disable react/style-prop-object */
import React, { ReactElement, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import DvrIcon from "@mui/icons-material/Dvr";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { networkSteps } from "constants/addons";
import { ipRegex } from "constants/regexp";

import SectionsBreadCrumbs from "components/shared/breadCrumbs/SectionsBreadCrumbs";
import AppModal from "components/shared/modal/AppModal";

import {
 getNetworkPrice,
 postAddNetwork,
 postCheckNetworkIp,
 postCheckNetworkName
} from "redux/handlers/addonsHandle";

import { getUserBalance } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

const NetworkWizard = (): ReactElement => {
 const intl = useIntl();
 const navigate = useNavigate();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const userBalance = useSelector(getUserBalance);

 const [activeStep, setActiveStep] = useState<number>(0);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [nameChecked, setNameChecked] = useState<boolean>(false);
 const [stepLoading, setStepLoading] = useState<boolean>(false);
 const [firstCheck, setFirstCheck] = useState<boolean>(false);
 const [ipChecked, setIpChecked] = useState<boolean>(false);
 const [secondCheck, setSecondCheck] = useState<boolean>(false);
 const [price, setPrice] = useState<number>(0);
 const [loading, setLoading] = useState<boolean>(false);

 useEffect(() => {
  (async () => {
   setPrice(await dispatch(getNetworkPrice()));
   setIsLoading(false);
  })();
 }, []);

 const { control, formState, reset, watch, getValues, setValue } = useForm({
  defaultValues: {
   name: "",
   ip_range: ""
  }
 });

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);
 const handleReset = () => {
  reset();
  setNameChecked(false);
  setIpChecked(false);
  setFirstCheck(false);
  setSecondCheck(false);
  setActiveStep(0);
 };

 const handleNext = () => {
  setActiveStep((prevActiveStep) => prevActiveStep + 1);
 };

 const handleBack = () => {
  setActiveStep((prevActiveStep) => prevActiveStep - 1);
 };

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postAddNetwork(getValues("name"), getValues("ip_range") + "/16"));
  setLoading(false);
  handleClose();
  handleReset();
  navigate("/addons/network");
 };

 const handleCheckName = async () => {
  setStepLoading(true);
  setNameChecked(await dispatch(postCheckNetworkName(getValues("name"))));
  setFirstCheck(true);
  setStepLoading(false);
 };

 const handleCheckIp = async () => {
  setStepLoading(true);
  setIpChecked(await dispatch(postCheckNetworkIp(getValues("ip_range"))));
  setSecondCheck(true);
  setStepLoading(false);
 };

 const renderServerChecked = () => {
  if (nameChecked) {
   return (
    <Alert severity="success">
     <FormattedMessage id="addons.network.add.nameAvailable" />
    </Alert>
   );
  } else {
   return (
    <Alert severity="error">
     <FormattedMessage id="addons.network.add.nameNotAvailable" />
    </Alert>
   );
  }
 };

 const renderIpChecked = () => {
  if (ipChecked) {
   return (
    <Alert severity="success">
     <FormattedMessage id="addons.network.add.ipAvailable" />
    </Alert>
   );
  } else {
   return (
    <Alert severity="error">
     <FormattedMessage id="addons.network.add.ipNotAvailable" />
    </Alert>
   );
  }
 };

 const renderStepContent = (index: number) => {
  switch (index) {
   case 0:
    return (
     <Controller
      name="name"
      control={control}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        onChange={(e) => {
         setValue("name", e.target.value);
         setNameChecked(false);
         setFirstCheck(false);
        }}
        label={intl.formatMessage({ id: "addons.network.add.name" })}
        error={formState.isDirty && !!formState?.errors?.name}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="new-password"
       />
      )}
     />
    );
   case 1:
    return (
     <Controller
      name="ip_range"
      control={control}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        onChange={(e) => {
         setValue("ip_range", e.target.value);
         setIpChecked(false);
         setSecondCheck(false);
        }}
        label={intl.formatMessage({ id: "addons.network.add.ipRange" })}
        error={formState.isDirty && !ipRegex.test(watch("ip_range"))}
        helperText={
         !ipRegex.test(watch("ip_range")) && (
          <FormattedMessage id="addons.network.add.valueMustBeIp" />
         )
        }
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="new-password"
       />
      )}
     />
    );
  }
 };

 const disableNextButton = (index: number) => {
  switch (index) {
   case 0:
    return watch("name").length === 0;
   case 1:
    return watch("ip_range").length === 0 || !ipRegex.test(watch("ip_range"));
   default:
    return false;
  }
 };

 const checkDisableConfirm = () => {
  return watch("name") === "" || watch("ip_range") === "" || !ipRegex.test(watch("ip_range"));
 };

 const handleNextPart = (index: number) => {
  if (index === 0) {
   if (nameChecked) {
    handleNext();
   } else {
    handleCheckName();
   }
  } else {
   if (ipChecked) {
    handleNext();
   } else {
    handleCheckIp();
   }
  }
 };

 return !isLoading ? (
  <Container maxWidth="xl">
   <Stack mt={2}>
    <SectionsBreadCrumbs
     links={[
      { name: "home", url: "/" },
      { name: "addons", url: "/addons/cloudbox" },
      { name: "network", url: "/addons/network" }
     ]}
    />
   </Stack>
   <Stack spacing={2}>
    <Stack alignItems="center" mt={5} p={3}>
     <DvrIcon fontSize="large" />
     <Typography>
      <FormattedMessage id={"addons.network.add.addNewNetwork"} />
     </Typography>
    </Stack>
    <Stack direction={desktopViewPort ? "row" : "column"} justifyContent="center">
     <Box
      sx={{ maxWidth: desktopViewPort ? 1000 : "auto", minWidth: desktopViewPort ? 500 : "auto" }}
      m={10}>
      <Stepper activeStep={activeStep} orientation="vertical">
       {networkSteps.map((step, index) => (
        <Step key={step.label}>
         <StepLabel>
          <FormattedMessage id={step.label} />
         </StepLabel>
         {!stepLoading ? (
          <StepContent>
           <Stack spacing={2}>
            <Alert severity="info">
             <Typography>
              <FormattedMessage id={step.description} />
             </Typography>
            </Alert>
            {renderStepContent(index)}
            {index === 0 && firstCheck && renderServerChecked()}
            {index === 1 && secondCheck && renderIpChecked()}
           </Stack>
           <Box sx={{ mb: 2 }}>
            <div>
             {index !== networkSteps.length - 1 ? (
              <Button
               disabled={disableNextButton(index)}
               variant="contained"
               onClick={() => handleNextPart(index)}
               sx={{ mt: 1, mr: 1 }}>
               {index === 0 && !nameChecked ? (
                <FormattedMessage id="app.wizard.checkName" />
               ) : (
                <FormattedMessage id="app.wizard.continue" />
               )}
              </Button>
             ) : (
              <></>
             )}
             {index === 1 && (
              <>
               <Button
                sx={{ mt: 1, mr: 1 }}
                variant="contained"
                disabled={ipChecked ? checkDisableConfirm() : ipChecked}
                onClick={() => (ipChecked ? handleOpen() : handleCheckIp())}>
                {index === 1 && !ipChecked ? (
                 <FormattedMessage id="app.wizard.checkName" />
                ) : (
                 <FormattedMessage id="app.wizard.confirm" />
                )}
               </Button>
               <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                <FormattedMessage id="app.wizard.reset" />
               </Button>
              </>
             )}
             <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
              <FormattedMessage id="app.wizard.back" />
             </Button>
            </div>
           </Box>
          </StepContent>
         ) : (
          <StepContent>
           <Stack spacing={2}>
            <Alert severity="info">
             <Typography>
              <FormattedMessage id="server.checkExecution" />
             </Typography>
            </Alert>
            {renderStepContent(index)}
            <CircularProgress />
           </Stack>
          </StepContent>
         )}
        </Step>
       ))}
      </Stepper>
     </Box>
     <Paper
      elevation={0}
      sx={{
       m: 5,
       p: 2,
       maxHeight: 330,
       position: "sticky",
       top: 30,
       minWidth: desktopViewPort ? 400 : "auto",
       borderRadius: "10px",
       boxShadow: 0
      }}>
      <Stack mb={2}>
       <Typography variant="h5" fontWeight="bold">
        <FormattedMessage id="addons.network.add.recap" />
       </Typography>
      </Stack>
      <Divider textAlign="left">
       <Typography variant="overline">
        <FormattedMessage id="addons.network.add.networkData" />
       </Typography>
      </Divider>
      <Stack direction="row" spacing={2}>
       <Typography minWidth={120} textAlign="start" fontWeight="bold">
        <FormattedMessage id="addons.network.add.name" />:
       </Typography>
       <Typography noWrap>{watch("name")}</Typography>
      </Stack>
      <Divider textAlign="left" sx={{ mt: 2 }}>
       <Typography variant="overline">
        <FormattedMessage id="addons.network.add.networkSpecs" />
       </Typography>
      </Divider>
      <Stack direction="row" spacing={2}>
       <Typography minWidth={120} textAlign="start" fontWeight="bold">
        <FormattedMessage id="addons.network.add.ipRange" />:
       </Typography>
       <Typography noWrap>{`${watch("ip_range")}/16`}</Typography>
      </Stack>
     </Paper>
    </Stack>
    <AppModal
     open={openModal}
     close={handleClose}
     title={intl.formatMessage({ id: "addons.network.add.createNewNetwork" })}
     handleClose={handleClose}
     disabled={loading}
     handleConfirm={handleConfirm}>
     <Stack spacing={2}>
      <Typography>
       <FormattedMessage id="addons.network.add.confirmCreation" />
      </Typography>
      <Stack direction="row" spacing={2}>
       <Typography minWidth={150} textAlign="start" fontWeight="bold">
        <FormattedMessage id="server.add.balance" />
       </Typography>
       <Typography>
        <FormattedNumber value={userBalance} style={"currency"} currency="EUR" />
       </Typography>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
       <Typography minWidth={150} textAlign="start" fontWeight="bold">
        <FormattedMessage id="server.add.totalPrice" />
       </Typography>
       <Typography>
        <FormattedNumber value={price} style={"currency"} currency="EUR" />
       </Typography>
      </Stack>
     </Stack>
    </AppModal>
   </Stack>
  </Container>
 ) : (
  <></>
 );
};

export default NetworkWizard;
