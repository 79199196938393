import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

import { IFirewallDataset } from "types/api/addonsApiInterface";

export const getFirewalls = (state: AppState) => state.firewalls;

export const getFirewallsNumber = createSelector(getFirewalls, (firewalls) => {
 return firewalls?.totalCount;
});

export const getFirewallsDataset = createSelector(getFirewalls, (firewalls) => {
 return (
  firewalls?.dataset?.map((firewalls: IFirewallDataset) => {
   return {
    id: firewalls.id,
    userid: firewalls.userid,
    name: firewalls.name,
    autorenew: firewalls.autorenew,
    created: firewalls.created,
    firewall_id: firewalls.firewall_id,
    expire: firewalls.expire,
    status: firewalls.service_status,
    rules: firewalls.rules,
    applied_to: firewalls.applied_to,
    personal_name: firewalls.personal_name
   };
  }) || []
 );
});
