import React, { ReactElement, useEffect, useState } from "react";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

import Orders from "components/profile/orders/Orders";
import SkeletonProfile from "components/shared/skeletons/SkeletonProfile";

import { getProfileOrders } from "redux/handlers/profileOrdersHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IProfileOrders } from "types/api/profileOrdersApiInterface";

const ServicesPage = (): ReactElement => {
 const dispatch = useAppDispatch();

 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [profileOrders, setProfileOrders] = useState<IProfileOrders>({ dataset: [], totalCount: 0 });

 useEffect(() => {
  (async () => {
   setProfileOrders(await dispatch(getProfileOrders(0, 10)));
   setIsLoading(false);
  })();
 }, []);

 return (
  <Container maxWidth="xl">
   <Stack>
    {isLoading ? <SkeletonProfile id="app.orders" /> : <Orders orders={profileOrders} />}
   </Stack>
  </Container>
 );
};

export default ServicesPage;
