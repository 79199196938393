export const countryCodes = [
 {
  label: "Andorra",
  code: "AD"
 },
 {
  label: "Albania",
  code: "AL"
 },
 {
  label: "Austria",
  code: "AT"
 },
 {
  label: "Åland Islands",
  code: "AX"
 },
 {
  label: "Bosnia and Herzegovina",
  code: "BA"
 },
 {
  label: "Belgium",
  code: "BE"
 },
 {
  label: "Bulgaria",
  code: "BG"
 },
 {
  label: "Belarus",
  code: "BY"
 },
 {
  label: "Switzerland",
  code: "CH"
 },
 {
  label: "Cyprus",
  code: "CY"
 },
 {
  label: "Czech Republic",
  code: "CZ"
 },
 {
  label: "Germany",
  code: "DE"
 },
 {
  label: "Denmark",
  code: "DK"
 },
 {
  label: "Estonia",
  code: "EE"
 },
 {
  label: "Spain",
  code: "ES"
 },
 {
  label: "Finland",
  code: "FI"
 },
 {
  label: "Faroe Islands",
  code: "FO"
 },
 {
  label: "France",
  code: "FR"
 },
 {
  label: "United Kingdom",
  code: "GB"
 },
 {
  label: "Guernsey",
  code: "GG"
 },
 {
  label: "Greece",
  code: "GR"
 },
 {
  label: "Croatia",
  code: "HR"
 },
 {
  label: "Hungary",
  code: "HU"
 },
 {
  label: "Ireland",
  code: "IE"
 },
 {
  label: "Isle of Man",
  code: "IM"
 },
 {
  label: "Iceland",
  code: "IC"
 },
 {
  label: "Italy",
  code: "IT"
 },
 {
  label: "Jersey",
  code: "JE"
 },
 {
  label: "Liechtenstein",
  code: "LI"
 },
 {
  label: "Lithuania",
  code: "LT"
 },
 {
  label: "Luxembourg",
  code: "LU"
 },
 {
  label: "Latvia",
  code: "LV"
 },
 {
  label: "Monaco",
  code: "MC"
 },
 {
  label: "Moldova, Republic of",
  code: "MD"
 },
 {
  label: "Macedonia, The Former Yugoslav Republic of",
  code: "MK"
 },
 {
  label: "Malta",
  code: "MT"
 },
 {
  label: "Netherlands",
  code: "NL"
 },
 {
  label: "Norway",
  code: "NO"
 },
 {
  label: "Poland",
  code: "PL"
 },
 {
  label: "Portugal",
  code: "PT"
 },
 {
  label: "Romania",
  code: "RO"
 },
 {
  label: "Russian Federation",
  code: "RU"
 },
 {
  label: "Sweden",
  code: "SE"
 },
 {
  label: "Slovenia",
  code: "SI"
 },
 {
  label: "Svalbard and Jan Mayen",
  code: "SJ"
 },
 {
  label: "Slovakia",
  code: "SK"
 },
 {
  label: "San Marino",
  code: "SM"
 },
 {
  label: "Ukraine",
  code: "UA"
 },
 {
  label: "Holy See (Vatican City State)",
  code: "VA"
 }
];

export const itProvinces = [
 { label: "Agrigento", code: "AG" },
 { label: "Alessandria", code: "AL" },
 { label: "Ancona", code: "AN" },
 { label: "Aosta", code: "AO" },
 { label: "Arezzo", code: "AR" },
 { label: "Ascoli Piceno", code: "AP" },
 { label: "Asti", code: "AT" },
 { label: "Avellino", code: "AV" },
 { label: "Bari", code: "BA" },
 { label: "Barletta-Andria-Trani", code: "BT" },
 { label: "Belluno", code: "BL" },
 { label: "Benevento", code: "BN" },
 { label: "Bergamo", code: "BG" },
 { label: "Biella", code: "BI" },
 { label: "Bologna", code: "BO" },
 { label: "Bolzano", code: "BZ" },
 { label: "Brescia", code: "BS" },
 { label: "Brindisi", code: "BR" },
 { label: "Cagliari", code: "CA" },
 { label: "Caltanissetta", code: "CL" },
 { label: "Campobasso", code: "CB" },
 { label: "Carbonia-Iglesias", code: "CI" },
 { label: "Caserta", code: "CE" },
 { label: "Catania", code: "CT" },
 { label: "Catanzaro", code: "CZ" },
 { label: "Chieti", code: "CH" },
 { label: "Como", code: "CO" },
 { label: "Cosenza", code: "CS" },
 { label: "Cremona", code: "CR" },
 { label: "Crotone", code: "KR" },
 { label: "Cuneo", code: "CN" },
 { label: "Enna", code: "EN" },
 { label: "Fermo", code: "FM" },
 { label: "Ferrara", code: "FE" },
 { label: "Firenze", code: "FI" },
 { label: "Foggia", code: "FG" },
 { label: "Forlì-Cesena", code: "FC" },
 { label: "Frosinone", code: "FR" },
 { label: "Genova", code: "GE" },
 { label: "Gorizia", code: "GO" },
 { label: "Grosseto", code: "GR" },
 { label: "Imperia", code: "IM" },
 { label: "Isernia", code: "IS" },
 { label: "La Spezia", code: "SP" },
 { label: "L'Aquila", code: "AQ" },
 { label: "Latina", code: "LT" },
 { label: "Lecce", code: "LE" },
 { label: "Lecco", code: "LC" },
 { label: "Livorno", code: "LI" },
 { label: "Lodi", code: "LO" },
 { label: "Lucca", code: "LU" },
 { label: "Macerata", code: "MC" },
 { label: "Mantova", code: "MN" },
 { label: "Massa-Carrara", code: "MS" },
 { label: "Matera", code: "MT" },
 { label: "Messina", code: "ME" },
 { label: "Milano", code: "MI" },
 { label: "Modena", code: "MO" },
 { label: "Monza e della Brianza", code: "MB" },
 { label: "Napoli", code: "NA" },
 { label: "Novara", code: "NO" },
 { label: "Nuoro", code: "NU" },
 { label: "Olbia-Tempio", code: "OT" },
 { label: "Oristano", code: "OR" },
 { label: "Padova", code: "PD" },
 { label: "Palermo", code: "PA" },
 { label: "Parma", code: "PR" },
 { label: "Pavia", code: "PV" },
 { label: "Perugia", code: "PG" },
 { label: "Pesaro e Urbino", code: "PU" },
 { label: "Pescara", code: "PE" },
 { label: "Piacenza", code: "PC" },
 { label: "Pisa", code: "PI" },
 { label: "Pistoia", code: "PT" },
 { label: "Pordenone", code: "PN" },
 { label: "Potenza", code: "PZ" },
 { label: "Prato", code: "PO" },
 { label: "Ragusa", code: "RG" },
 { label: "Ravenna", code: "RA" },
 { label: "Reggio Calabria", code: "RC" },
 { label: "Reggio Emilia", code: "RE" },
 { label: "Rieti", code: "RI" },
 { label: "Rimini", code: "RN" },
 { label: "Roma", code: "RM" },
 { label: "Rovigo", code: "RO" },
 { label: "Salerno", code: "SA" },
 { label: "Medio Campidano", code: "VS" },
 { label: "Sassari", code: "SS" },
 { label: "Savona", code: "SV" },
 { label: "Siena", code: "SI" },
 { label: "Siracusa", code: "SR" },
 { label: "Sondrio", code: "SO" },
 { label: "Taranto", code: "TA" },
 { label: "Teramo", code: "TE" },
 { label: "Terni", code: "TR" },
 { label: "Torino", code: "TO" },
 { label: "Ogliastra", code: "OG" },
 { label: "Trapani", code: "TP" },
 { label: "Trento", code: "TN" },
 { label: "Treviso", code: "TV" },
 { label: "Trieste", code: "TS" },
 { label: "Udine", code: "UD" },
 { label: "Varese", code: "VA" },
 { label: "Venezia", code: "VE" },
 { label: "Verbano-Cusio-Ossola", code: "VB" },
 { label: "Vercelli", code: "VC" },
 { label: "Verona", code: "VR" },
 { label: "Vibo Valentia", code: "VV" },
 { label: "Vicenza", code: "VI" },
 { label: "Viterbo", code: "VT" }
];
