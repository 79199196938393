import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import Container from "@mui/material/Container";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import ServersList from "components/servers/ServersList";
import SectionsBreadCrumbs from "components/shared/breadCrumbs/SectionsBreadCrumbs";
import SkeletonTable from "components/shared/skeletons/SkeletonTable";

import { getAllServers } from "redux/handlers/serverHandler";

import { useAppDispatch } from "hooks/reduxHook";

const ServersPage = (): ReactElement => {
 const dispatch = useAppDispatch();

 const [isLoading, setIsLoading] = useState<boolean>(true);

 useEffect(() => {
  (async () => {
   await dispatch(getAllServers(0, 10));
   setIsLoading(false);
  })();
 }, []);

 const SkeletonServers = () => {
  return (
   <Stack mt={6}>
    <Stack alignItems="flex-start">
     <Typography color="disabled" fontSize={16} textTransform="uppercase">
      <FormattedMessage id="app.servers" />
     </Typography>
    </Stack>
    <Skeleton variant="rectangular" width="100%" height={60} />
    <SkeletonTable numberOfRows={8} tableHeight={60}></SkeletonTable>
   </Stack>
  );
 };

 return (
  <Container maxWidth="xl">
   <Stack mt={2}>
    <SectionsBreadCrumbs
     links={[
      { name: "home", url: "/" },
      { name: "servers", url: "/servers" }
     ]}
    />
   </Stack>
   <Stack>{isLoading ? <SkeletonServers /> : <ServersList />}</Stack>
  </Container>
 );
};

export default ServersPage;
