import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import {
 IPermissionsApi,
 IServerPermissionApi,
 ISnapshotPermissionApi
} from "types/api/userApiInterface";

const initialState: IPermissionsApi = { server: [], snapshot: [] };

const PermissionReducer = createSlice({
 name: "permissions",
 initialState,
 reducers: {
  setServerPermissions: (state, { payload }: PayloadAction<Array<IServerPermissionApi>>) => {
   return { ...state, server: payload };
  },
  resetServerPermissions: (state) => {
   return { ...state, server: [] };
  },
  setSnapshotPermissions: (state, { payload }: PayloadAction<Array<ISnapshotPermissionApi>>) => {
   return { ...state, snapshot: payload };
  },
  resetSnapshotPermissions: (state) => {
   return { ...state, snapshot: [] };
  },
  resetPermissions: () => {
   return initialState;
  }
 }
});

export default PermissionReducer;
