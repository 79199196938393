import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { getAllServices } from "redux/selectors/serversSelector";

const ServiceLogs = ({ service }: { service: string }): ReactElement => {
 const intl = useIntl();

 const services = useSelector(getAllServices);

 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [openModal, setOpenModal] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setIsLoading(true);
  setIsLoading(false);
  setOpenModal(false);
 };

 const Logs = () =>
  filterLogs()
   .split("<br>")
   .map((log, index) => (
    <Stack key={`logs-list-${index}`}>
     <Typography>{log}</Typography>
     <Divider />
    </Stack>
   ));

 const filterLogs = (): string => {
  switch (service) {
   case "apache2":
    return services?.apache_log || "";
   case "postfix":
    return services?.postfix_log || "";
   case "mysql":
    return services?.mysql_log || "";
   case "redis":
    return services?.redis_log || "";
   case "ftp":
    return services?.ftp_log || "";
   default:
    return "";
  }
 };

 return (
  <>
   <Tooltip title={intl.formatMessage({ id: "app.logs" })} placement="top">
    <IconButton onClick={handleOpen}>
     <IconSelector icon="LogsIcon" props={{ fontSize: "small" }} />
    </IconButton>
   </Tooltip>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    handleConfirm={handleConfirm}
    showConfirmButton={false}
    title={intl.formatMessage({ id: "server.service.logs" })}>
    <Stack>
     {filterLogs() === "no" || filterLogs() === "" || !filterLogs() ? (
      <FormattedMessage id="server.services.logsNotProvided" />
     ) : (
      Logs()
     )}
    </Stack>
   </AppModal>
  </>
 );
};

export default ServiceLogs;
