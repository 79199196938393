import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import AddIcon from "@mui/icons-material/Add";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import AppModal from "components/shared/modal/AppModal";

import { getCertificates, postCreateService } from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

import { ICertificateDataset } from "types/api/certificatesApiInterface";

const CreateService = ({ id, maxServices }: { id: number; maxServices: boolean }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [certificate, setCertificate] = useState<number>(0);
 const [redirectHttp, setRedirectHttp] = useState<boolean>(true);
 const [certificates, setCertificates] = useState<Array<ICertificateDataset>>([]);

 const checkStatus = (expireDate: string): boolean => {
  const today = new Date().getTime();
  const expire = new Date(expireDate).getTime();
  return today < expire;
 };

 const handleOpen = async () => {
  const certif = await dispatch(getCertificates());
  setCertificates(certif.dataset.filter((element) => checkStatus(element.not_valid_after)));
  reset();
  setCertificate(0);
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const { control, reset, watch, handleSubmit, setValue, getValues, formState } = useForm({
  defaultValues: {
   dPort: 0,
   lPort: 0,
   protocol: "http",
   proxyprotocol: false
  }
 });

 const onSubmit: SubmitHandler<{
  dPort: number;
  lPort: number;
  protocol: string;
  proxyprotocol: boolean;
 }> = async (data: { dPort: number; lPort: number; protocol: string; proxyprotocol: boolean }) => {
  setLoading(true);
  if (data.protocol === "https")
   await dispatch(
    postCreateService(
     id,
     data.dPort,
     data.lPort,
     data.protocol,
     data.proxyprotocol,
     certificate,
     redirectHttp
    )
   );
  else
   await dispatch(postCreateService(id, data.dPort, data.lPort, data.protocol, data.proxyprotocol));
  handleClose();
  setLoading(false);
 };

 const checkDisabled = (): boolean => {
  return (
   watch("dPort") === 0 ||
   watch("lPort") === 0 ||
   watch("protocol") === "" ||
   (watch("protocol") === "https" && certificate === 0)
  );
 };

 return (
  <>
   <Button onClick={handleOpen} variant="kxActionButton" endIcon={<AddIcon />}>
    <FormattedMessage id="addons.loadBalancer.createService" />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "addons.loadBalancer.createService" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}
    showConfirmButton={!maxServices}
    disabled={loading || checkDisabled() || maxServices}>
    {maxServices ? (
     <Alert severity="error">
      <FormattedMessage id="addons.loadBalancer.maxServicesReached" />
     </Alert>
    ) : (
     <Stack spacing={2}>
      <Controller
       name="dPort"
       control={control}
       rules={{ required: true }}
       render={({ field }) => (
        <TextField
         {...field}
         type="number"
         label={intl.formatMessage({ id: "addons.loadBalancer.dPort" })}
         InputLabelProps={{ shrink: true }}
         error={formState.isDirty && !!formState?.errors?.dPort}
         helperText={
          formState.isDirty &&
          !!formState?.errors?.dPort &&
          intl.formatMessage({ id: "addons.loadBalancer.invaliddPort" })
         }
        />
       )}
      />
      <Controller
       name="lPort"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <TextField
         {...field}
         type="number"
         label={intl.formatMessage({ id: "addons.loadBalancer.lPort" })}
         InputLabelProps={{ shrink: true }}
         error={formState.isDirty && !!formState?.errors?.lPort}
         helperText={
          formState.isDirty &&
          !!formState?.errors?.lPort &&
          intl.formatMessage({ id: "addons.loadBalancer.invalidlPort" })
         }
        />
       )}
      />
      <Controller
       name="protocol"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <FormControl>
         <InputLabel>
          <FormattedMessage id="addons.loadBalancer.graphType" />
         </InputLabel>
         <Select
          {...field}
          label={<FormattedMessage id="addons.loadBalancer.graphType" />}
          onChange={(e) => setValue("protocol", e.target.value)}>
          <MenuItem key="http-key" value="http">
           HTTP
          </MenuItem>
          <MenuItem key="https-key" value="https">
           HTTPS
          </MenuItem>
          <MenuItem key="tcp-key" value="tcp">
           TCP
          </MenuItem>
         </Select>
        </FormControl>
       )}
      />
      <Controller
       name="proxyprotocol"
       control={control}
       render={({ field }) => (
        <FormControlLabel
         control={
          <Checkbox
           {...field}
           checked={getValues("proxyprotocol")}
           onChange={(e, checked) => {
            setValue("proxyprotocol", checked);
           }}
          />
         }
         label={intl.formatMessage({ id: "addons.loadBalancer.proxyprotocol" })}
        />
       )}
      />
      {watch("protocol") === "https" && (
       <Stack>
        <FormControl>
         <InputLabel>
          <FormattedMessage id="addons.loadBalancer.certificate" />
         </InputLabel>
         <Select
          value={certificate}
          label={<FormattedMessage id="addons.loadBalancer.certificate" />}
          onChange={(e) => setCertificate(e.target.value as number)}>
          {certificates.map((element, index) => {
           return (
            <MenuItem key={`${element.name}-certificate-${index}`} value={element.id}>
             {element.name}
            </MenuItem>
           );
          })}
          {certificates.length === 0 && <MenuItem value={0}>No certificate available</MenuItem>}
         </Select>
        </FormControl>
        <FormControlLabel
         control={
          <Checkbox
           checked={redirectHttp}
           onChange={(e, checked) => {
            setRedirectHttp(checked);
           }}
          />
         }
         label={intl.formatMessage({ id: "addons.loadBalancer.redirectHttp" })}
        />
       </Stack>
      )}
     </Stack>
    )}
   </AppModal>
  </>
 );
};

export default CreateService;
