export const formatSizePercent = (size: number, totalSize: number) => {
 return Math.floor((size * 100) / totalSize);
};

export const formatDateColor = (status: string): string => {
 switch (status) {
  case "expiring":
   return "warning";
  case "expired":
   return "error";
  default:
   return "";
 }
};
