import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";

import IconSelector from "components/shared/images/IconSelector";

import { balancerTableData } from "types/global/table";

import DeleteLoadBalancer from "./actions/DeleteLoadBalancer";
import UpdateAlgorithm from "./actions/UpdateAlgorithm";
import UpdateLoadBalancer from "./actions/UpdateLoadBalancer";

const LoadBalancerActions = ({ rowData }: { rowData: balancerTableData }): ReactElement => {
 const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
 const open = Boolean(anchorEl);

 const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
  setAnchorEl(null);
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <IconSelector icon="MenuIcon" />
   </Button>
   <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
     "aria-labelledby": "basic-button"
    }}>
    <Stack>
     <UpdateLoadBalancer id={rowData.id} oldName={rowData.name} closeMenu={handleClose} />
     <UpdateAlgorithm id={rowData.id} oldType={rowData.algorithm} closeMenu={handleClose} />
     {rowData.protection_delete ? (
      <MenuItem disabled>
       <FormattedMessage id="addons.loadBalancer.deleteBalancer" />
      </MenuItem>
     ) : (
      <DeleteLoadBalancer id={rowData.id} closeMenu={handleClose} />
     )}
    </Stack>
   </Menu>
  </>
 );
};

export default LoadBalancerActions;
