import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import PasswordIcon from "@mui/icons-material/Password";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import { passwordValidation } from "constants/regexp";

import AppModal from "components/shared/modal/AppModal";

import { generatePassword } from "redux/handlers/userHandler";
import { postUpdateDatabaseUser } from "redux/handlers/websitesHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IDbData } from "types/api/websitesApiInterface";

const EditDbPassword = ({
 selectedDb,
 domain_id
}: {
 selectedDb: IDbData;
 domain_id: number;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [showPassword, setShowPassword] = useState<string>("password");

 const handleOpen = () => {
  reset();
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const { control, handleSubmit, formState, reset, setValue } = useForm({
  defaultValues: {
   password: selectedDb.db_user?.database_password || ""
  }
 });

 const onSubmit: SubmitHandler<{ password: string }> = async (data: { password: string }) => {
  setIsLoading(true);
  await dispatch(
   postUpdateDatabaseUser(
    domain_id,
    selectedDb.db_user?.database_user || "",
    data.password,
    selectedDb.isp_id,
    selectedDb.db_user?.id || 0
   )
  );
  setIsLoading(false);
  setOpenModal(false);
 };

 const handleGeneratePassword = async () => {
  setValue("password", await generatePassword());
 };

 return (
  <>
   <Stack>
    <Tooltip title={<FormattedMessage id="mails.edit.password" />}>
     <Button
      variant="kxActionButton"
      onClick={handleOpen}
      disabled={selectedDb.id === 0 || !selectedDb.db_user || selectedDb.db_user?.id === 0}>
      <PasswordIcon />
     </Button>
    </Tooltip>
   </Stack>
   <AppModal
    open={openModal}
    close={handleClose}
    loading={isLoading}
    title={intl.formatMessage({ id: "mails.mailboxChangePassword" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}>
    <Stack direction="row" spacing={2}>
     <Controller
      name="password"
      control={control}
      rules={{
       required: true,
       minLength: 8,
       pattern: passwordValidation
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "mails.mailboxPassword" })}
        error={formState.isDirty && !!formState?.errors?.password}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        type={showPassword}
        autoComplete="new-password"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.password &&
         intl.formatMessage({ id: "mails.mailboxPasswordError" })
        }
        InputProps={{
         endAdornment: (
          <InputAdornment position="end">
           <IconButton
            aria-label="toggle password visibility"
            onClick={() => {
             setShowPassword(showPassword === "text" ? "password" : "text");
            }}
            edge="end">
            {showPassword ? <VisibilityOff /> : <Visibility />}
           </IconButton>
          </InputAdornment>
         )
        }}
       />
      )}
     />
     <Button variant="kxActionButton" onClick={handleGeneratePassword} sx={{ width: "40%" }}>
      <FormattedMessage id="app.generatePassword" />
     </Button>
    </Stack>
   </AppModal>
  </>
 );
};

export default EditDbPassword;
