import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { IUserApi } from "types/api/userApiInterface";
import { IUser } from "types/redux/userInterfaces";

const initialState: IUser = {
 authenticated: false,
 language: localStorage.getItem("language") || "it"
} as IUser;

const userReducer = createSlice({
 name: "user",
 initialState,
 reducers: {
  setUser: (state, { payload }: PayloadAction<IUserApi>) => {
   return {
    ...payload,
    authenticated: true
   };
  },
  resetUser: () => {
   return initialState;
  }
 }
});

export default userReducer;
