import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const InboundIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <path
   d="M7.5 8V5.5C7.5 5.10218 7.65804 4.72064 7.93934 4.43934C8.22064 4.15804 8.60218 4 9 4H13C13.3978 4 13.7794 4.15804 14.0607 4.43934C14.342 4.72064 14.5 5.10218 14.5 5.5V14.5C14.5 14.8978 14.342 15.2794 14.0607 15.5607C13.7794 15.842 13.3978 16 13 16H9C8.60218 16 8.22064 15.842 7.93934 15.5607C7.65804 15.2794 7.5 14.8978 7.5 14.5V12M10 8L12 10M12 10L10 12M12 10H3.5"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
 </svg>,
 "Inbound"
);
