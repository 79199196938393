import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { getAllServices, postRenewService } from "redux/handlers/servicesHandler";

import { getServicesList } from "redux/selectors/servicesSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { EServiceEndpoints } from "types/redux/userInterfaces";

const ManualRenewBaremetal = ({ server }: { server: string }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const servicesList = useSelector(getServicesList);

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => {
  dispatch(getAllServices(0, 10, server));
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(
   postRenewService(
    servicesList?.find((element) => element.name === server)?.id || 0,
    EServiceEndpoints.BAREMETAL
   )
  );
  setLoading(false);

  handleClose();
 };

 return (
  <>
   <Button variant="kxActionButton" onClick={handleOpen}>
    <FormattedMessage id="app.renew" />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "profile.services.manualRenew" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}>
    <Typography>
     <FormattedMessage id="profile.services.manualRenewMessage" />
    </Typography>
   </AppModal>
  </>
 );
};

export default ManualRenewBaremetal;
