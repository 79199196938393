import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { headerLinks } from "constants/staticLinks";

import HeaderDropdown from "components/header/HeaderDropdown";
import HeaderNotification from "components/header/HeaderNotification";
import MobileSideMenu from "components/layout/MobileSideMenu";
import IconSelector from "components/shared/images/IconSelector";
import Link from "components/shared/link/Link";

import HeaderJobQueue from "./HeaderJobQueue";

const AppHeader = (): ReactElement => {
 const navigate = useNavigate();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 return (
  <Box sx={{ flexGrow: 1 }}>
   <AppBar position="static">
    <Toolbar>
     {!desktopViewPort && <MobileSideMenu />}
     <Stack
      direction="row"
      alignItems="center"
      sx={{ cursor: "pointer" }}
      flexGrow={desktopViewPort ? 0 : 1}
      onClick={() => navigate("/")}>
      <img
       src={`${process.env.PUBLIC_URL}/svg/loghi/kxLogo.svg`}
       alt="konsoleX logo"
       height={desktopViewPort ? 25 : 20}
       style={{ marginTop: -3.5 }}
      />
     </Stack>
     {desktopViewPort && (
      <Stack sx={{ flexGrow: 1, textAlign: "left" }} direction="row">
       {headerLinks.map((link, index) => {
        return (
         <Stack key={`url-link-${index}`} direction="row" alignItems="center" ml={6}>
          <Link linkTo={link.url}>
           <Stack direction="row" alignItems="center">
            {link?.icon && <IconSelector icon={link.icon} props={{ fontSize: "small" }} />}
            <Typography
             component="div"
             ml={0.5}
             fontSize={14}
             sx={{ display: "flex", alignItems: "center" }}>
             {link.name}
            </Typography>
           </Stack>
          </Link>
         </Stack>
        );
       })}
      </Stack>
     )}
     <Stack direction="row" justifyContent={"flex-end"}>
      <HeaderJobQueue />
      <HeaderNotification />
      <HeaderDropdown />
     </Stack>
    </Toolbar>
   </AppBar>
  </Box>
 );
};

export default AppHeader;
