import React, { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import AppModal from "components/shared/modal/AppModal";

import { postAddMailbox } from "redux/handlers/mailsHandler";
import { generatePassword } from "redux/handlers/userHandler";

import { getMailsList } from "redux/selectors/mailsSelector";

import { useAppDispatch } from "hooks/reduxHook";

const AddMailbox = ({ type }: { type: string }) => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const params = useParams<{ mail_id: string; section: string }>();
 const mailDetails = useSelector(getMailsList).find(
  (element) => element.id === Number(params.mail_id)
 );

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [showPassword, setShowPassword] = useState<string>("password");

 const handleOpen = () => {
  reset();
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const { control, handleSubmit, setValue, formState, reset } = useForm({
  defaultValues: {
   name: "",
   password: ""
  }
 });

 const onSubmit: SubmitHandler<{
  name: string;
  password: string;
 }> = async (data: { name: string; password: string }) => {
  setIsLoading(true);
  await dispatch(
   postAddMailbox(
    mailDetails?.id || 0,
    mailDetails?.isp_id || 0,
    data.name,
    data.password,
    mailDetails?.domain || ""
   )
  );
  setIsLoading(false);
  setOpenModal(false);
 };

 const handleGeneratePassword = async () => {
  setValue("password", await generatePassword());
 };

 return (
  <>
   {type === "icon" ? (
    <Stack maxWidth={600}>
     <Tooltip title={<FormattedMessage id="mails.create.mailbox" />}>
      <Button variant="kxActionButton" onClick={handleOpen}>
       <AddIcon />
      </Button>
     </Tooltip>
    </Stack>
   ) : (
    <Stack alignItems="center" p={2} justifyContent="center">
     <Button variant="kxActionButton" onClick={handleOpen}>
      <FormattedMessage id="mails.create.mailbox" />
      <AddIcon />
     </Button>
    </Stack>
   )}
   <AppModal
    open={openModal}
    close={handleClose}
    loading={isLoading}
    title={intl.formatMessage({ id: "mails.mailboxOperations" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}>
    <Stack>
     <Alert severity="info">
      <FormattedMessage id="mails.mailboxWarning" />
     </Alert>
     <Controller
      name="name"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "mails.mailboxName" })}
        error={formState.isDirty && !!formState?.errors?.name}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="new-password"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.name &&
         intl.formatMessage({ id: "mails.mailboxNameError" })
        }
       />
      )}
     />
     <Stack direction="row" spacing={2}>
      <Controller
       name="password"
       control={control}
       rules={{
        required: true,
        minLength: 8
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         label={intl.formatMessage({ id: "mails.mailboxPassword" })}
         error={formState.isDirty && !!formState?.errors?.password}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         type={showPassword}
         autoComplete="new-password"
         helperText={
          formState.isDirty &&
          !!formState?.errors?.password &&
          intl.formatMessage({ id: "mails.mailboxPasswordError" })
         }
         InputProps={{
          endAdornment: (
           <InputAdornment position="end">
            <IconButton
             aria-label="toggle password visibility"
             onClick={() => {
              setShowPassword(showPassword === "text" ? "password" : "text");
             }}
             edge="end">
             {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
           </InputAdornment>
          )
         }}
        />
       )}
      />
      <Button variant="kxActionButton" onClick={handleGeneratePassword} sx={{ width: "40%" }}>
       <FormattedMessage id="app.generatePassword" />
      </Button>
     </Stack>
    </Stack>
   </AppModal>
  </>
 );
};

export default AddMailbox;
