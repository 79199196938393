import React, { ReactElement, useEffect, useState } from "react";

import Stack from "@mui/material/Stack";

import LoadBalancer from "components/addons/loadBalancer/LoadBalancer";
import SkeletonAddons from "components/shared/skeletons/SkeletonAddons";

import { getAllLoadBalancers } from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

const LoadBalancerPage = (): ReactElement => {
 const dispatch = useAppDispatch();

 const [isLoading, setIsLoading] = useState<boolean>(true);

 useEffect(() => {
  (async () => {
   await dispatch(getAllLoadBalancers(0, 10));
   setIsLoading(false);
  })();
 }, []);

 return <Stack>{isLoading ? <SkeletonAddons id="addons.loadBalancer" /> : <LoadBalancer />}</Stack>;
};

export default LoadBalancerPage;
