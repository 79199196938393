import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation } from "react-use";
import { getPriceWithIva } from "helpers/generic";

import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";

import { getUserTax } from "redux/selectors/userSelector";

import Stripe from "./Stripe";

const PaymentPage = (): ReactElement => {
 const location = useLocation();
 const tax = useSelector(getUserTax);

 const [amount, setAmount] = useState<number>(0);

 useEffect(() => {
  if (location.state && location.state.usr && location.state.usr.amount) {
   setAmount(location.state.usr.amount);
  }
 }, []);

 return amount > 0 ? (
  <Grid container>
   <Grid xs={12} p={2} justifyContent="center" display="flex">
    <Alert severity="info">
     <FormattedMessage id="recharge.stripeMessage" />
    </Alert>
   </Grid>
   <Grid xs={12} p={2} justifyContent="center" display="flex">
    <Paper elevation={0} sx={{ p: 2, minWidth: "500px", borderRadius: "10px", boxShadow: 0 }}>
     <Stripe amount={getPriceWithIva(amount, tax)} request_amount={amount} />
    </Paper>
   </Grid>
  </Grid>
 ) : (
  <CircularProgress />
 );
};

export default PaymentPage;
