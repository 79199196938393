import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const WebsitePerformance = ({
 site,
 performance
}: {
 site: string;
 performance: Array<{ site: string; size: string; logs: string }>;
}): ReactElement => {
 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [logsData, setLogsData] = useState<string>("0 KB");
 const [sizeData, setSizeData] = useState<string>("0 KB");

 useEffect(() => {
  setLogsData(performance.find((element) => element.site === site)?.logs || "0 KB");
  setSizeData(performance.find((element) => element.site === site)?.size || "0 KB");
  setIsLoading(false);
 }, [performance, site]);

 return isLoading ? (
  <Stack direction="row" alignItems="center" justifyContent="space-around">
   <Typography variant="caption">Obtaining data...</Typography>
  </Stack>
 ) : (
  <Stack>
   <Typography
    sx={{ ml: 2 }}
    variant="caption"
    fontWeight="bold"
    textTransform="uppercase"
    fontSize={11}>
    <FormattedMessage id="sites.ssd" />:{` ${sizeData}`}
   </Typography>
   <Typography
    sx={{ ml: 2 }}
    variant="caption"
    fontWeight="bold"
    textTransform="uppercase"
    fontSize={11}>
    <FormattedMessage id="sites.logs" />:{` ${logsData}`}
   </Typography>
  </Stack>
 );
};

export default WebsitePerformance;
