import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postRebuildServerFromSnapshot } from "redux/handlers/addonsHandle";

import { getServerDbId } from "redux/selectors/serversSelector";

import { useAppDispatch } from "hooks/reduxHook";

const RebuildSnapshot = ({
 id,
 closeMenu
}: {
 id: number;
 closeMenu: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const navigate = useNavigate();

 const idServer = useSelector(getServerDbId);

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postRebuildServerFromSnapshot(idServer, id));
  setLoading(false);
  handleClose();
  closeMenu();
  navigate("/servers");
 };

 return (
  <>
   <MenuItem onClick={() => handleOpen()}>
    <FormattedMessage id="addons.snapshot.rebuild" />
   </MenuItem>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "addons.snapshot.rebuild" })}
    handleClose={handleClose}
    disabled={loading}
    handleConfirm={handleConfirm}>
    <Typography>
     <FormattedMessage id="addons.snapshot.rebuildMessage" />
    </Typography>
   </AppModal>
  </>
 );
};

export default RebuildSnapshot;
