import React, { ReactElement, useState } from "react";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";

import IconSelector from "components/shared/images/IconSelector";

import { firewallsTableData } from "types/global/table";

import DeleteFirewall from "./actions/DeleteFirewall";

const FirewallActions = ({ rowData }: { rowData: firewallsTableData }): ReactElement => {
 const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
 const open = Boolean(anchorEl);

 const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
  setAnchorEl(null);
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <IconSelector icon="MenuIcon" />
   </Button>
   <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
     "aria-labelledby": "basic-button"
    }}>
    <Stack>
     <DeleteFirewall id={rowData.id} closeMenu={handleClose} />
    </Stack>
   </Menu>
  </>
 );
};

export default FirewallActions;
