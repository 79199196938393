import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { hostTypeList } from "constants/server";

import AppModal from "components/shared/modal/AppModal";

import { putUpdateHost } from "redux/handlers/hostHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { hostData } from "types/global/user";

type hostDetails = {
 type: string;
 name: string;
 ip: string;
 visible: boolean;
};

const UpdateHost = ({ hostData }: { hostData?: hostData }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [checked, setChecked] = useState<boolean>(false);

 const { control, handleSubmit, formState, getValues, setValue, reset } = useForm({
  defaultValues: {
   type: hostData?.type || "",
   name: hostData?.name || "",
   ip: hostData?.ip || "",
   visible: hostData?.public || false
  }
 });

 const onSubmit: SubmitHandler<hostDetails> = async (data: hostDetails) => {
  setIsLoading(true);
  await dispatch(putUpdateHost(hostData?.id || 0, data.type, data.name, data.ip, data.visible));
  setIsLoading(false);
  setOpenModal(false);
 };

 const handleOpen = () => {
  reset();
  setOpenModal(true);
 };

 const handleClose = () => {
  setOpenModal(false);
 };

 return (
  <>
   <Typography onClick={handleOpen}>
    <FormattedMessage id="host.updateHost" />
   </Typography>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    handleConfirm={handleSubmit(onSubmit)}
    title={intl.formatMessage({ id: "host.updateHost" })}>
    <form onSubmit={handleSubmit(onSubmit)}>
     <Controller
      name="type"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) => {
         setValue("type", value?.type || "");
        }}
        value={hostTypeList.find((element) => element.type === getValues("type"))}
        options={hostTypeList}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
           e.stopPropagation();
          }}
          InputLabelProps={{ shrink: true }}
          label={intl.formatMessage({ id: "host.hostType" })}
         />
        )}
       />
      )}
     />
     <Controller
      name="name"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "host.name" })}
        error={formState.isDirty && !!formState?.errors?.name}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
         e.stopPropagation();
        }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.name &&
         intl.formatMessage({ id: "host.nameError" })
        }
       />
      )}
     />
     <Controller
      name="ip"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "host.ip" })}
        error={formState.isDirty && !!formState?.errors?.ip}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
         e.stopPropagation();
        }}
        autoComplete="off"
        helperText={
         formState.isDirty && !!formState?.errors?.ip && intl.formatMessage({ id: "host.ipError" })
        }
       />
      )}
     />
     <Controller
      name="name"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <FormControlLabel
        control={
         <Checkbox
          checked={checked}
          onChange={(e) => {
           setValue("visible", e.target.checked);
           setChecked(e.target.checked);
          }}
         />
        }
        label={intl.formatMessage({ id: "host.hostVisible" })}
       />
      )}
     />
    </form>
   </AppModal>
  </>
 );
};

export default UpdateHost;
