export const steps = [
 {
  label: "mails.add.selectName",
  description: "mails.add.selectNameMessage"
 },
 {
  label: "mails.add.host",
  description: "mails.add.hostMessage"
 },
 {
  label: "mails.add.spam",
  description: "mails.add.spamMessage"
 },
 {
  label: "mails.add.dkim",
  description: "mails.add.dkimMessage"
 }
];

export const priorityLevel = [
 {
  value: 0,
  text: "0 - minimum"
 },
 {
  value: 1,
  text: "1"
 },
 {
  value: 2,
  text: "2"
 },
 {
  value: 3,
  text: "3"
 },
 {
  value: 4,
  text: "4"
 },
 {
  value: 5,
  text: "5 - normal"
 },
 {
  value: 6,
  text: "6"
 },
 {
  value: 7,
  text: "7"
 },
 {
  value: 8,
  text: "8"
 },
 {
  value: 9,
  text: "9"
 },
 {
  value: 10,
  text: "10 - maximum"
 }
];
