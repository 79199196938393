import React, { ReactElement, useEffect, useState } from "react";

import Switch from "@mui/material/Switch";

import { postApplySsl } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

const DockerWebsiteSsl = ({ id, check }: { id: number; check: boolean }): ReactElement => {
 const dispatch = useAppDispatch();

 const [sslOn, setSslOn] = useState<boolean>(false);

 useEffect(() => {
  setSslOn(check);
 }, [check]);

 const handleChangeSsl = async (newCheck: boolean) => {
  setSslOn(newCheck);
  await dispatch(postApplySsl(id));
 };

 return (
  <Switch
   checked={sslOn}
   onChange={(event) => {
    handleChangeSsl(event.target.checked);
   }}
  />
 );
};

export default DockerWebsiteSsl;
