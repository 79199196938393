import React, { ReactElement, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import IconSelector from "../images/IconSelector";

const VncCommandProgress = (): ReactElement => {
 const { socket } = useContext(AppContext);

 const [open, setOpen] = useState<boolean>(false);
 const [messages, setMessages] = useState<Array<string>>([]);
 const [newMessage, setNewMessage] = useState<string>("");
 const [percent, setPercent] = useState<number>(0);

 useEffect(() => {
  if (!isNil(socket)) {
   socket.on("command_progress", (value): void => {
    if (value?.completed) {
     handleOpen();
     setNewMessage(value?.message);
     setPercent(Number(value?.percentage));
    }
   });
  }
  return () => {
   !isNil(socket) && socket.off("command_progress");
  };
 }, [socket]);

 const handleOpen = () => setOpen(true);
 const handleClose = () => {
  handleReset();
  setOpen(false);
 };
 const handleReset = () => {
  setMessages([]);
  setPercent(0);
 };

 useEffect(() => {
  setMessages((prevState) => {
   const lastMessages = prevState;
   lastMessages.push(newMessage);
   return lastMessages;
  });
 }, [newMessage]);

 return (
  <Stack>
   <Snackbar
    open={open}
    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    onClose={handleClose}>
    <Stack
     spacing={2}
     sx={{
      backgroundColor: "white",
      flexDirection: "column",
      py: 1,
      px: 2,
      borderRadius: "5px",
      boxShadow: "5px 5px 5px #00000029"
     }}>
     <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography fontWeight="bold">
       <FormattedMessage id="app.commandList" />
      </Typography>
      <IconButton onClick={handleClose}>
       <IconSelector icon="CloseIcon" />
      </IconButton>
     </Stack>
     <Stack spacing={2}>
      <Stack
       sx={{
        backgroundColor: "black",
        width: "400px",
        height: "200px",
        overflowY: "scroll",
        wordBreak: "break-word"
       }}>
       {messages.map((element, index) => {
        return (
         <Typography key={`command-index-${index}`} sx={{ color: "white" }}>
          {element}
         </Typography>
        );
       })}
      </Stack>
      <LinearProgress variant="determinate" value={percent} />
     </Stack>
    </Stack>
   </Snackbar>
  </Stack>
 );
};

export default VncCommandProgress;
