import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { isNil } from "ramda";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postLinkBalancerToNewtork } from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

import { IAllBalancers } from "types/api/addonsApiInterface";

const AttachBalancerToSubnet = ({
 id,
 balancers,
 net_id
}: {
 id: number;
 balancers: Array<IAllBalancers>;
 net_id: number;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [selectedBalancer, setSelectedBalancer] = useState<number>(0);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postLinkBalancerToNewtork(id, selectedBalancer));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Button variant="kxActionButton" onClick={() => handleOpen()}>
    <FormattedMessage id="addons.network.attachBalancer" />
   </Button>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "addons.network.attachBalancer" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={
     loading ||
     balancers.filter(
      (element) =>
       (element.private_net && element.private_net.length === 0) ||
       (element.private_net &&
        element.private_net.filter((nets) => nets.network === net_id).length === 0)
     ).length === 0
    }>
    <Stack>
     <Typography>
      {balancers.filter(
       (element) =>
        (element.private_net && element.private_net.length === 0) ||
        (element.private_net &&
         element.private_net.filter((nets) => nets.network === net_id).length === 0)
      ).length === 0 ? (
       <Alert severity="error">
        <FormattedMessage id="addons.network.noBalancerFound" />
       </Alert>
      ) : (
       <FormattedMessage id="addons.network.attachBalancerToSubnet" />
      )}
     </Typography>
     <Autocomplete
      fullWidth={true}
      autoHighlight
      disabled={
       balancers.filter(
        (element) =>
         (element.private_net && element.private_net.length === 0) ||
         (element.private_net &&
          element.private_net.filter((nets) => nets.network === net_id).length === 0)
       ).length === 0
      }
      sx={{ my: 2 }}
      onChange={(e, value) =>
       isNil(value) ? setSelectedBalancer(0) : setSelectedBalancer(value.balancer_id)
      }
      options={balancers
       .filter(
        (element) =>
         (element.private_net && element.private_net.length === 0) ||
         (element.private_net &&
          element.private_net.filter((nets) => nets.network === net_id).length === 0)
       )
       .map((element) => {
        return {
         balancer_id: element.id,
         label: element.name
        };
       })}
      renderInput={(params) => (
       <TextField
        {...params}
        label={<FormattedMessage id="addons.network.loadbalancer" />}
        InputLabelProps={{ shrink: true }}
       />
      )}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default AttachBalancerToSubnet;
