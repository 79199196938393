import React, { ReactElement, useEffect, useState } from "react";

import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

import "../../../theme/style/slider.css";

import IconSelector from "./IconSelector";

const ImageSlider = ({ imageList }: { imageList: Array<ReactElement> }): ReactElement => {
 const [activeIndex, setActiveIndex] = useState<number>(0);
 const [slideDone, setSlideDone] = useState<boolean>(true);

 useEffect(() => {
  if (slideDone) {
   setSlideDone(false);
  }
 }, [slideDone]);

 const slideNext = () => {
  setActiveIndex((val) => {
   if (val >= imageList.length - 1) {
    return 0;
   } else {
    return val + 1;
   }
  });
 };

 const slidePrev = () => {
  setActiveIndex((val) => {
   if (val <= 0) {
    return imageList.length - 1;
   } else {
    return val - 1;
   }
  });
 };

 return (
  <Stack className="container__slider" maxWidth={700} flexWrap="wrap">
   <Stack direction="row" overflow="hidden" maxWidth="inherit">
    {imageList.map((item, index) => {
     return (
      <Stack
       direction="row"
       className={"slider__item slider__item-active-" + (activeIndex + 1)}
       key={index}>
       {item}
      </Stack>
     );
    })}
   </Stack>

   <Stack
    direction="row"
    spacing={1}
    alignItems="center"
    justifyContent="center"
    maxWidth="inherit">
    <IconButton
     className="slider__btn-prev"
     onClick={(e) => {
      e.preventDefault();
      slidePrev();
     }}>
     <IconSelector icon="ArrowBackIosIcon" />
    </IconButton>
    <Stack direction="row">
     {imageList.map((item, index) => {
      return (
       <button
        key={index}
        className={
         activeIndex === index
          ? "container__slider__links-small container__slider__links-small-active"
          : "container__slider__links-small"
        }
        onClick={(e) => {
         e.preventDefault();
         setActiveIndex(index);
        }}></button>
      );
     })}
    </Stack>
    <IconButton
     className="slider__btn-next"
     onClick={(e) => {
      e.preventDefault();
      slideNext();
     }}>
     <IconSelector icon="ArrowForwardIosIcon" />
    </IconButton>
   </Stack>
  </Stack>
 );
};

export default ImageSlider;
