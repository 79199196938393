import React, { ReactElement } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";

import JobQueueNotification from "components/servers/managePages/jobqueue/jobqueueActions/JobQueueNotification";
import IconSelector from "components/shared/images/IconSelector";
import Link from "components/shared/link/Link";

import AdminWrapper from "../admin/AdminWrapper";

type Props = {
 menuList: {
  name: string;
  icon?: string;
  url: string;
  disabled?: boolean;
  admin?: boolean;
 }[];
};

const SectionMenu = ({ menuList }: Props): ReactElement => {
 const intl = useIntl();
 const location = useLocation();

 const RenderBadge = ({ name }: { name: string }): ReactElement => {
  if (name === "app.jobqueue") {
   return (
    <JobQueueNotification>
     <ListItemText primary={intl.formatMessage({ id: name })} />
    </JobQueueNotification>
   );
  } else {
   return <ListItemText sx={{ marginLeft: 4 }} primary={intl.formatMessage({ id: name })} />;
  }
 };

 return (
  <Box sx={{ width: "90%" }}>
   <nav aria-label="main mailbox folders">
    <List>
     {menuList.map((item, index) =>
      !item.disabled ? (
       !item.admin ? (
        <Stack key={`section-menu-${index}`}>
         <Link
          linkTo={item?.url || "/"}
          sx={{
           width: "100%",
           backgroundColor: "transparent"
          }}>
          <ListItem
           disablePadding
           sx={{
            "&:hover": {
             fontWeight: "bold"
            }
           }}>
           <ListItemButton>
            <ListItemIcon
             sx={{
              backgroundColor: location.pathname.slice(1).includes(item?.url.slice(1))
               ? "#EF711A"
               : "lightgray",
              justifyContent: "center",
              borderRadius: "50%",
              height: "35px",
              minWidth: "35px",
              alignItems: "center"
             }}>
             <IconSelector
              icon={item.icon}
              props={{
               color: location.pathname.slice(1).includes(item?.url.slice(1))
                ? "tertiary"
                : "disabled"
              }}
             />
            </ListItemIcon>
            <RenderBadge name={item.name} />
           </ListItemButton>
          </ListItem>
         </Link>
         {index !== menuList.length - 1 && (
          <Stack pl={4}>
           <Stack sx={{ borderLeft: "1px solid lightgray", minHeight: "15px" }} />
          </Stack>
         )}
        </Stack>
       ) : (
        <AdminWrapper key={`section-menu-${index}`}>
         <Link
          linkTo={item?.url || "/"}
          sx={{
           width: "100%",
           pl: 2,
           backgroundColor: "transparent",
           "&:hover": {
            color: "#7e67d0"
           }
          }}>
          <ListItem
           disablePadding
           sx={{
            mt: 1,
            backgroundColor: "rgba(231,231,231,0.58)",
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
            "&:hover": {
             backgroundColor: "rgba(194,193,193,0.58)",
             borderLeft: "3px solid #9e91cc"
            }
           }}>
           <ListItemButton>
            <ListItemIcon>
             <IconSelector icon={item.icon} />
            </ListItemIcon>
            <RenderBadge name={item.name} />
           </ListItemButton>
          </ListItem>
         </Link>
        </AdminWrapper>
       )
      ) : (
       <>
        <ListItem key={`section-menu-${index}`} disablePadding>
         <ListItemButton sx={{ cursor: "not-allowed" }}>
          <ListItemIcon
           sx={{
            backgroundColor: location.pathname.slice(1).includes(item?.url.slice(1))
             ? "#EF711A"
             : "lightgray",
            justifyContent: "center",
            borderRadius: "50%",
            height: "35px",
            minWidth: "35px",
            alignItems: "center"
           }}>
           <IconSelector icon={item.icon} />
          </ListItemIcon>
          <ListItemText sx={{ marginLeft: 4 }} primary={intl.formatMessage({ id: item.name })} />
         </ListItemButton>
        </ListItem>
        {index !== menuList.length - 1 && (
         <Stack pl={4}>
          <Stack sx={{ borderLeft: "1px solid lightgray", minHeight: "15px" }} />
         </Stack>
        )}
       </>
      )
     )}
    </List>
   </nav>
  </Box>
 );
};

export default SectionMenu;
