import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const RebuildIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <path
   d="M17 6.23083C17 6.67828 16.9071 7.12085 16.7271 7.53049C16.5471 7.94014 16.284 8.30792 15.9544 8.61053C15.6248 8.91313 15.2359 9.14396 14.8124 9.28838C14.3889 9.43279 13.9401 9.48765 13.4942 9.44947C12.7219 9.38415 11.8691 9.50043 11.3767 10.0984L6.24299 16.3318C6.08104 16.5292 5.87956 16.6905 5.65151 16.8053C5.42346 16.9201 5.1739 16.986 4.91888 16.9985C4.66386 17.0111 4.40903 16.9701 4.17081 16.8782C3.93258 16.7863 3.71624 16.6456 3.53569 16.465C3.35515 16.2845 3.2144 16.0681 3.12252 15.8299C3.03064 15.5917 2.98966 15.3369 3.00221 15.0818C3.01476 14.8268 3.08057 14.5773 3.1954 14.3492C3.31023 14.1212 3.47154 13.9197 3.66894 13.7577L9.90235 8.62399C10.5003 8.13158 10.6166 7.27883 10.5512 6.50647C10.5031 5.94527 10.6025 5.38123 10.8396 4.8703C11.0767 4.35938 11.4433 3.91935 11.903 3.59387C12.3627 3.26839 12.8995 3.06878 13.4602 3.01484C14.0209 2.96091 14.5859 3.05452 15.0993 3.2864L12.7477 5.63793C12.8387 6.03135 13.0383 6.39133 13.3239 6.67686C13.6094 6.96239 13.9694 7.16201 14.3628 7.25299L16.7143 4.90146C16.8981 5.30702 17 5.75708 17 6.23083Z"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
  <path
   d="M4.88127 15.1136H4.88783V15.1202H4.88127V15.1136Z"
   stroke="currentColor"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
 </svg>,
 "Rebuild"
);
