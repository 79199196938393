import React, { ReactElement, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { getProviderPermissions } from "helpers/server";

import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import ClickToCopy from "components/shared/clipboardCopy/ClickToCopy";
import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postChangeServerDnsPtr } from "redux/handlers/serverHandler";

import { getServerPermissions } from "redux/selectors/permissionSelector";
import { getServerDetails } from "redux/selectors/serversSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IServerPermissionApi } from "types/api/userApiInterface";

import Ipv6StatusChange from "../actions/Ipv6StatusChange";

const ServerDns = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const server = useSelector(getServerDetails);
 const permissions = useSelector(getServerPermissions);

 const [providerPermission, setProviderPermission] = useState<IServerPermissionApi | null>(null);

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 useEffect(() => {
  if (server.provider) {
   setProviderPermission(getProviderPermissions(server.provider, permissions || []));
  }
 }, [server]);

 const { control, handleSubmit, formState, setValue } = useForm({
  defaultValues: {
   dnsptr: "",
   section: "ipv4"
  }
 });

 const onSubmit: SubmitHandler<{ dnsptr: string; section: string }> = async (data: {
  dnsptr: string;
  section: string;
 }) => {
  setLoading(true);
  switch (data.section) {
   case "ipv4":
    await dispatch(postChangeServerDnsPtr(server.id || 0, server.ipv4 || "", data.dnsptr));
    break;
   case "ipv6":
    await dispatch(postChangeServerDnsPtr(server.id || 0, server.ipv6 || "", data.dnsptr));
    break;
   default:
    await dispatch(postChangeServerDnsPtr(server.id || 0, server.ipv4 || "", data.dnsptr));
    break;
  }
  setLoading(false);
  handleClose();
 };

 const handleOpen = (section: string) => {
  switch (section) {
   case "ipv4":
    setValue("dnsptr", server?.ipv4rDns);
    setValue("section", "ipv4");
    break;
   case "ipv6":
    setValue("dnsptr", server?.ipv6rDns);
    setValue("section", "ipv6");
    break;
   default:
    setValue("dnsptr", server?.ipv4rDns);
    setValue("section", "ipv4");
    break;
  }
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 return (
  <>
   <Paper elevation={0} sx={{ mt: 3, pb: 0.7, borderRadius: "10px", boxShadow: 0 }}>
    <Stack alignItems="center" justifyContent="flex-start" direction="row" px={2} pt={1}>
     <IconSelector icon="AlternateEmailIcon" props={{ fontSize: "medium", color: "secondary" }} />
     <Typography variant="h6" component="h2" ml={1}>
      <FormattedMessage id="server.ip.title" />{" "}
     </Typography>
    </Stack>
    <Stack direction="row" alignItems="center" p={1}>
     <Stack width="20%">
      <Typography variant="subtitle1" px={1} fontWeight="bold">
       <FormattedMessage id="server.ip.ipv4" />
      </Typography>
     </Stack>
     {server?.ipv4 ? (
      <Stack width="80%">
       <Stack direction="row" alignItems="center">
        <Typography noWrap variant="subtitle2" px={1}>
         {server?.ipv4 || "N/A"}
        </Typography>
        <ClickToCopy text={server?.ipv4} />
       </Stack>
       <Stack direction="row" alignItems="center">
        <Typography variant="subtitle2" px={1} noWrap maxWidth={"80%"}>
         {server?.ipv4rDns || "N/A"}
        </Typography>
        <ClickToCopy text={server?.ipv4rDns} />
        {providerPermission?.update_rdns_ipv4 && (
         <Tooltip title={intl.formatMessage({ id: "app.edit" })} placement="top">
          <IconButton onClick={() => handleOpen("ipv4")}>
           <IconSelector icon="EditIcon" props={{ fontSize: "small" }} />
          </IconButton>
         </Tooltip>
        )}
       </Stack>
      </Stack>
     ) : (
      <Stack width="80%" textAlign="left">
       <Typography variant="subtitle2" fontStyle="italic" px={1}>
        <FormattedMessage id="server.ip.ipv4NotAvailable" />
       </Typography>
      </Stack>
     )}
    </Stack>

    <Divider />

    <Stack direction="row" alignItems="center" p={1}>
     <Stack width="20%">
      <Typography variant="subtitle1" px={1} fontWeight="bold">
       <FormattedMessage id="server.ip.ipv6" />
      </Typography>
     </Stack>

     {server?.ipv6 ? (
      <Stack width="80%">
       <Stack direction="row" alignItems="center">
        <Typography noWrap variant="subtitle2" px={1}>
         {server?.ipv6 || "N/A"}
        </Typography>
        <ClickToCopy text={server?.ipv6} />
       </Stack>
       <Stack direction="row" alignItems="center">
        <Typography variant="subtitle2" px={1} noWrap maxWidth="80%">
         {server?.ipv6rDns || "N/A"}
        </Typography>
        <ClickToCopy text={server?.ipv6rDns} />
        {providerPermission?.update_rdns_ipv6 && (
         <Tooltip title={intl.formatMessage({ id: "app.edit" })} placement="top">
          <IconButton onClick={() => handleOpen("ipv6")}>
           <IconSelector icon="EditIcon" props={{ fontSize: "small" }} />
          </IconButton>
         </Tooltip>
        )}
       </Stack>
       {server?.ipv6_enabled ? (
        <Ipv6StatusChange id={server?.id || 0} action="disable" />
       ) : (
        <Ipv6StatusChange id={server?.id || 0} action="enable" />
       )}
      </Stack>
     ) : (
      <Stack width="80%" textAlign="left">
       <Typography variant="subtitle2" fontStyle="italic" px={1}>
        <FormattedMessage id="server.ip.ipv6NotAvailable" />
       </Typography>
      </Stack>
     )}
    </Stack>
   </Paper>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "server.updateIp" })}
    handleClose={handleClose}
    disabled={loading}
    handleConfirm={handleSubmit(onSubmit)}>
    <form onSubmit={handleSubmit(onSubmit)}>
     <Controller
      name="dnsptr"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth
        label="DNS"
        error={formState.isDirty && !!formState?.errors?.dnsptr}
        autoComplete="off"
       />
      )}
     />
    </form>
   </AppModal>
  </>
 );
};

export default ServerDns;
