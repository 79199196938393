import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

import { INetworkDataset } from "types/api/addonsApiInterface";

export const getNetworks = (state: AppState) => state.networks;

export const getNetworksNumber = createSelector(getNetworks, (networks) => {
 return networks?.totalCount;
});

export const getNetworksDataset = createSelector(getNetworks, (networks) => {
 return (
  networks?.dataset?.map((networks: INetworkDataset) => {
   return {
    id: networks.id,
    userid: networks.userid || "Not available",
    name: networks.name || "Not available",
    autorenew: networks.autorenew,
    created: networks.created,
    expire: networks.expire,
    protection: networks.protection,
    status: networks.service_status,
    ip_range: networks.ip_range,
    expose: networks.expose_routes_to_vswitch,
    subnets: networks.subnets,
    routes: networks.routes,
    servers: networks.servers,
    network_id: networks.network_id || 0,
    personal_name: networks.personal_name,
    load_balancers: networks.load_balancers
   };
  }) || []
 );
});
