import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { ITeamsApi } from "types/api/teamsApiInterface";

const initialState: ITeamsApi = { dataset: [], totalCount: 0 };

const teamsReducer = createSlice({
 name: "teams",
 initialState,
 reducers: {
  setTeams: (state, { payload }: PayloadAction<ITeamsApi>) => {
   return payload;
  },
  resetTeams: () => {
   return initialState;
  }
 }
});

export default teamsReducer;
