import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { LineChart } from "@mui/x-charts/LineChart";

import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";

import { getBalancerMetrics } from "redux/handlers/addonsHandle";

import { getLoadBalancerDetails } from "redux/selectors/loadBalancerSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { InternalTimeSeries } from "types/api/addonsApiInterface";

const LoadBalancerGraph = (): ReactElement => {
 const dispatch = useAppDispatch();

 const balancerData = useSelector(getLoadBalancerDetails);

 const [metrics, setMetrics] = useState<Array<Array<string | number>>>([]);
 const [selectedType, setSelectedType] =
  useState<keyof InternalTimeSeries>("connections_per_second");
 const [selectedMstime, setSelectedMstime] = useState<number>(3600000);
 const [startTime, setStartTime] = useState<string>(new Date(Date.now() - 3600000).toISOString());
 const endTime = new Date().toISOString();
 const listOfGraph: Array<keyof InternalTimeSeries> = [
  "open_connections",
  "requests_per_second",
  "connections_per_second",
  "bandwidth.in",
  "bandwidth.out"
 ];

 useEffect(() => {
  (async () => {
   if (balancerData?.id)
    setMetrics(
     await dispatch(getBalancerMetrics(balancerData?.id, selectedType, startTime, endTime))
    );
  })();
 }, [balancerData]);

 const handleSetNetTime = (millisec: number) => {
  setSelectedMstime(millisec);
  const newDate = new Date(Date.now() - millisec).toISOString();
  setStartTime(newDate);
 };

 const handleSearchGraph = async () => {
  if (balancerData?.id) {
   setMetrics(
    await dispatch(getBalancerMetrics(balancerData?.id, selectedType, startTime, endTime))
   );
  }
 };

 const separateGraph = (axis: number): Array<number> => {
  const resultedArray = [];
  for (let element of metrics) {
   resultedArray.push(Number(element[axis]));
  }
  return resultedArray;
 };

 const convertXAxis = (value: number): string => {
  if (selectedMstime === 3600000) {
   const convertedValue = new Date(value * 1000);
   return `${convertedValue.getHours()}:${convertedValue.getMinutes()}`;
  } else {
   const convertedValue = new Date(value * 1000);
   return convertedValue.toLocaleDateString();
  }
 };

 return (
  <>
   <Stack p={2}>
    <Stack direction="row" spacing={1}>
     <FormControl>
      <InputLabel>
       <FormattedMessage id="addons.loadBalancer.graphType" />
      </InputLabel>
      <Select
       value={selectedType}
       label={<FormattedMessage id="addons.loadBalancer.graphType" />}
       onChange={(e) => setSelectedType(e.target.value as keyof InternalTimeSeries)}>
       {listOfGraph.map((element, index) => {
        return (
         <MenuItem key={`graph-value-${index}`} value={element}>
          {element}
         </MenuItem>
        );
       })}
      </Select>
     </FormControl>
     <FormControl>
      <InputLabel>
       <FormattedMessage id="addons.loadBalancer.timeSpan" />
      </InputLabel>
      <Select
       value={selectedMstime}
       label={<FormattedMessage id="addons.loadBalancer.timeSpan" />}
       onChange={(e) => handleSetNetTime(e.target.value as number)}>
       <MenuItem key="1-hour" value={3600000}>
        1 Hour
       </MenuItem>
       <MenuItem key="12-hour" value={43200000}>
        12 Hour
       </MenuItem>
       <MenuItem key="24-hour" value={86400000}>
        24 Hour
       </MenuItem>
       <MenuItem key="1-week" value={604800000}>
        1 Week
       </MenuItem>
       <MenuItem key="30-days" value={2592000000}>
        30 Days
       </MenuItem>
      </Select>
     </FormControl>
     <Button onClick={handleSearchGraph}>Filter</Button>
    </Stack>
    <Stack>
     <LineChart
      xAxis={[{ data: separateGraph(0), valueFormatter: (value: number) => convertXAxis(value) }]}
      series={[
       {
        data: separateGraph(1)
       }
      ]}
      height={300}
      margin={{ left: 30, right: 30, top: 30, bottom: 30 }}
      grid={{ vertical: true, horizontal: true }}
     />
    </Stack>
   </Stack>
  </>
 );
};

export default LoadBalancerGraph;
