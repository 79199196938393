import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const EditIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <path
   d="M9.8576 5.66309H4V16H12.471V10.6469"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
  <path
   d="M14.03 4.24255L15.5517 6.06725L10.4808 10.5028C10.4123 10.5627 10.3438 10.6227 10.2267 10.4823L8.93914 8.93834C8.88061 8.86816 8.82209 8.79798 8.95914 8.6781L14.03 4.24255Z"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
  <path
   d="M16.2392 3.91235C16.6488 4.40361 16.5888 5.18433 16.1092 5.60391L15.4924 6.14337L13.9708 4.31868L14.5875 3.77922C15.0672 3.35964 15.8295 3.42108 16.2392 3.91235Z"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
  <path
   d="M7.96515 11.0161L8.68608 8.9856C8.68608 8.9856 8.7762 8.89331 8.7762 8.9856L10.128 10.6469V10.7392L8.05526 11.2006L7.96515 11.0161Z"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
 </svg>,
 "Edit"
);
