import React from "react";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

import AddDomain from "components/domains/actions/AddDomain";
import SectionsBreadCrumbs from "components/shared/breadCrumbs/SectionsBreadCrumbs";

const AddDomainsPage = () => {
 return (
  <Container maxWidth="xl">
   <Stack mt={2}>
    <SectionsBreadCrumbs
     links={[
      { name: "home", url: "/" },
      { name: "domains", url: "/domains" },
      { name: "add", url: "/domains/add" }
     ]}
    />
   </Stack>
   <AddDomain />
  </Container>
 );
};

export default AddDomainsPage;
