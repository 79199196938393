import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import BackupIcon from "@mui/icons-material/Backup";

import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { websiteBackupData } from "constants/tables";

import SortingTable from "components/shared/tables/SortingTable";

import { getWebsitesDetails } from "redux/selectors/websitesSelector";

import DisableBackups from "./actions/DisableBackups";
import EnableBackups from "./actions/EnableBackups";
import UpdateBackupRetention from "./actions/UpdateBackupRetention";

const WebsitesBackup = (): ReactElement => {
 const websitedetails = useSelector(getWebsitesDetails);

 return (
  <Paper elevation={0} sx={{ mt: 2, borderRadius: "10px", boxShadow: 0 }}>
   <Stack direction="row" alignItems="center" justifyContent="space-between" p={2} spacing={5}>
    <Stack direction={"row"} spacing={1}>
     <BackupIcon color="secondary" />
     <Typography variant="body1" fontWeight="bold" textTransform="uppercase">
      <FormattedMessage id="sites.backup" />
     </Typography>
    </Stack>
    {websitedetails.backup_interval === "none" && (
     <EnableBackups domain_id={websitedetails.domain_id} isp_id={websitedetails.isp_id} />
    )}
    {websitedetails.backup_interval !== "none" && (
     <Stack direction="row" spacing={1}>
      <UpdateBackupRetention
       domain_id={websitedetails.domain_id}
       isp_id={websitedetails.isp_id}
       interval={websitedetails.backup_interval || ""}
       copy={String(websitedetails.backup_copies) || "0"}
      />
      <DisableBackups domain_id={websitedetails.domain_id} isp_id={websitedetails.isp_id} />
     </Stack>
    )}
   </Stack>
   <Stack px={2}>
    <Alert severity="info">
     <FormattedMessage id="sites.backupMessage" />
    </Alert>
   </Stack>
   <Stack direction="column" alignItems="flex-start" p={2} spacing={3}>
    {websitedetails.backup_interval === "none" && (
     <Alert severity="warning">
      <Typography>
       <FormattedMessage id="sites.backups.backupDisableMessage" />
      </Typography>
     </Alert>
    )}
    {websitedetails.backup.length === 0 && websitedetails.backup_interval !== "none" ? (
     <Stack>
      <Alert severity="info">
       <Typography variant="small">
        <FormattedMessage id="sites.backups.message" />
       </Typography>
      </Alert>
     </Stack>
    ) : (
     <>
      {websitedetails.backup_restore && (
       <Alert severity="info">
        <FormattedMessage id="sites.backups.restoreOngoing" />
       </Alert>
      )}
      {websitedetails.backup_interval !== "none" && (
       <SortingTable
        tableData={websiteBackupData}
        maxWidth={650}
        caller="webBackup"
        listToShow={websitedetails.backup}
       />
      )}
     </>
    )}
   </Stack>
  </Paper>
 );
};

export default WebsitesBackup;
