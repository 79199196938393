import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import ContainersList from "./containers/ContainersList";
import DockerImages from "./images/DockerImages";
import DockerNetworks from "./network/DockerNetworks";
import DockerProxy from "./proxy/DockerProxy";
import DockerServices from "./services/DockerServices";
import DockerStacks from "./stacks/DockerStacks";
import DockerVolumes from "./volumes/DockerVolumes";

const BaseContainerPage = (): ReactElement => {
 const intl = useIntl();
 const params = useParams<{ id: string; section: string }>();
 const { socket } = useContext(AppContext);

 const [section, setSection] = useState<number>(0);
 const [reloadData, setReloadData] = useState<boolean>(true);

 const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
  setSection(newValue);
 };

 useEffect(() => {
  if (!isNil(socket)) {
   socket.on("docker_data", (value): void => {
    if (value?.completed) {
     setReloadData(true);
    }
   });
  }
  return () => {
   !isNil(socket) && socket.off("docker_data");
  };
 }, [socket]);

 const RenderTabContent = (): ReactElement => {
  switch (section) {
   case 0:
    return <DockerProxy id={parseInt(params?.id || "0")} />;
   case 1:
    return (
     <ContainersList
      id={parseInt(params?.id || "0")}
      reloadData={reloadData}
      resetReload={() => setReloadData(false)}
     />
    );
   case 2:
    return (
     <DockerImages
      id={parseInt(params?.id || "0")}
      reloadData={reloadData}
      resetReload={() => setReloadData(false)}
     />
    );
   case 3:
    return (
     <DockerNetworks
      id={parseInt(params?.id || "0")}
      reloadData={reloadData}
      resetReload={() => setReloadData(false)}
     />
    );
   case 4:
    return (
     <DockerVolumes
      id={parseInt(params?.id || "0")}
      reloadData={reloadData}
      resetReload={() => setReloadData(false)}
     />
    );
   case 5:
    return (
     <DockerStacks
      id={parseInt(params?.id || "0")}
      reloadData={reloadData}
      resetReload={() => setReloadData(false)}
     />
    );
   case 6:
    return (
     <DockerServices
      id={parseInt(params?.id || "0")}
      reloadData={reloadData}
      resetReload={() => setReloadData(false)}
     />
    );
   default:
    return <></>;
  }
 };

 return (
  <Paper elevation={0} sx={{ mt: 3, pb: 0.7, borderRadius: "10px", boxShadow: 0 }}>
   <Tabs
    value={section}
    onChange={handleChangeTab}
    variant={"scrollable"}
    scrollButtons={true}
    allowScrollButtonsMobile>
    <Tab label={intl.formatMessage({ id: "docker.proxy" })} />
    <Tab label={intl.formatMessage({ id: "docker.containers" })} />
    <Tab label={intl.formatMessage({ id: "docker.images" })} />
    <Tab label={intl.formatMessage({ id: "docker.network" })} />
    <Tab label={intl.formatMessage({ id: "docker.volumes" })} />
    <Tab label={intl.formatMessage({ id: "docker.stacks" })} />
    <Tab label={intl.formatMessage({ id: "docker.services" })} />
   </Tabs>
   <RenderTabContent />
  </Paper>
 );
};

export default BaseContainerPage;
