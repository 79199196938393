import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const RebootIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_53_207)">
   <rect width="20" height="20" fill="transparent" />
   <path
    d="M14.5 9.99994C14.5 11.1934 14.0259 12.338 13.182 13.1819C12.3381 14.0258 11.1935 14.4999 10 14.4999C8.80653 14.4999 7.66194 14.0258 6.81802 13.1819C5.97411 12.338 5.5 11.1934 5.5 9.99994C5.5 8.80646 5.97411 7.66187 6.81802 6.81796C7.66194 5.97404 8.80653 5.49994 10 5.49994C11.1935 5.49994 12.3381 5.97404 13.182 6.81796C14.0259 7.66187 14.5 8.80646 14.5 9.99994ZM14.5 9.99994C14.5 11.6569 15.507 12.9999 16.75 12.9999C17.993 12.9999 19 11.6569 19 9.99994C19 7.91774 18.278 5.89997 16.957 4.29042C15.6361 2.68087 13.7979 1.57913 11.7557 1.17293C9.71355 0.766733 7.5937 1.0812 5.75737 2.06276C3.92104 3.04431 2.48187 4.63222 1.68506 6.55592C0.888253 8.47963 0.783114 10.6201 1.38756 12.6126C1.992 14.6052 3.26863 16.3265 4.99992 17.4833C6.73121 18.6401 8.81004 19.1608 10.8822 18.9567C12.9544 18.7526 14.8917 17.8363 16.364 16.3639M14.5 9.99994V6.24994"
    stroke="currentColor"
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
   />
  </g>
  <defs>
   <clipPath id="clip0_53_207">
    <rect width="20" height="20" fill="transparent" />
   </clipPath>
  </defs>
 </svg>,
 "Reboot"
);
