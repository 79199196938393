/* eslint-disable no-unused-vars */
import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";

import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { getUserChannels, getUserTypes } from "redux/handlers/notificationHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { ITypesRenew } from "types/api/notificationApiInterface";

const TelegramChannel = ({
 defaultPreferences
}: {
 defaultPreferences: ITypesRenew | null;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [updatingData, setUpdatingData] = useState<boolean>(false);
 const [channelActive, setChannelActive] = useState<boolean>(true);

 // data for the service (types)
 const [selectedService, setSelectedService] = useState<string>("server");
 const [typeActive, setTypeActive] = useState<boolean>(true);

 const [selectedTime, setSelectedTime] = useState<number>(1);

 // data for initialization
 const [userTypes, setUserTypes] = useState<Array<string>>([]);
 const [typePreferences, setTypePreferences] = useState<ITypesRenew | null>(defaultPreferences);

 const handleOpenChanges = () => setUpdatingData(true);
 const handleCloseChanges = () => setUpdatingData(false);

 const handleConfirm = async () => {
  /*const newSubtypeActive = typePreferences?.sub_types_active.map((element, index) => {
   if (index === typePreferences.sub_types.indexOf(selectedSection)) {
    return sectionActive;
   } else {
    return element;
   }
  });
  await dispatch(
   postUpdateNotificationSettings(
    typePreferences?.id || 0,
    selectedService,
    typeActive,
    typePreferences?.send_by_mail || false,
    channelActive,
    typePreferences?.sub_types || [],
    newSubtypeActive || [],
    selectedTime,
    typePreferences?.email_list || []
   )
  );*/
  handleCloseChanges();
 };

 useEffect(() => {
  (async () => {
   setUserTypes(await getUserTypes());
  })();
 }, []);

 useEffect(() => {
  (async () => {
   let preferences = await dispatch(getUserChannels(selectedService));
   setTypePreferences(preferences);
   setTypeActive(preferences?.type_active || false);
   setSelectedTime(preferences?.frequency || 1);
  })();
 }, [selectedService]);

 return (
  <Stack spacing={1}>
   {updatingData ? (
    <Stack spacing={2}>
     <FormControlLabel
      control={
       <Checkbox
        checked={channelActive}
        onChange={(e) => {
         setChannelActive(!channelActive);
        }}
       />
      }
      label={intl.formatMessage({ id: "profile.settings.setChannelActive" })}
     />
     <Divider />
     <Stack direction="row" spacing={2}>
      <Autocomplete
       fullWidth={true}
       autoHighlight
       disabled={!channelActive}
       sx={{ my: 2 }}
       options={userTypes}
       value={selectedService}
       onChange={(e, value) => setSelectedService(value || "server")}
       renderInput={(params) => (
        <TextField
         {...params}
         label={intl.formatMessage({ id: "profile.settings.channelService" })}
         variant="standard"
         InputLabelProps={{ shrink: true }}
        />
       )}
      />
      <FormControlLabel
       control={
        <Checkbox
         disabled={!channelActive}
         checked={typeActive}
         onChange={(e) => {
          setTypeActive(!typeActive);
         }}
        />
       }
       label={intl.formatMessage({ id: "profile.settings.setSectionActive" })}
      />
     </Stack>
     <Divider />
     <TextField
      disabled={!channelActive}
      onChange={(event) =>
       Number(event.target.value) <= 0
        ? setSelectedTime(1)
        : setSelectedTime(Number(event.target.value))
      }
      value={selectedTime}
      type="number"
      label={intl.formatMessage({ id: "profile.settings.channelTime" })}
     />
     <Stack direction="row" spacing={2}>
      <Button variant="kxActionButton" onClick={handleConfirm}>
       Conferma
      </Button>
      <Button variant="kxActionButton" onClick={handleCloseChanges}>
       Indietro
      </Button>
     </Stack>
    </Stack>
   ) : (
    <Stack spacing={2}>
     <Stack direction="row" spacing={1}>
      <Chip
       label={
        channelActive ? intl.formatMessage({ id: "app.on" }) : intl.formatMessage({ id: "app.off" })
       }
       size="small"
       color={channelActive ? "success" : "error"}
      />
      <Typography>
       {channelActive ? (
        <FormattedMessage id="profile.settings.channelActive" />
       ) : (
        <FormattedMessage id="profile.settings.channelOff" />
       )}
      </Typography>
     </Stack>
     <TextField
      label={intl.formatMessage({ id: "profile.settings.channelService" })}
      variant="standard"
      value={selectedService}
      InputProps={{
       readOnly: true,
       startAdornment: (
        <InputAdornment position="start">
         <MiscellaneousServicesIcon />
        </InputAdornment>
       )
      }}
     />
     <TextField
      label={intl.formatMessage({ id: "profile.settings.channelTime" })}
      variant="standard"
      value={selectedTime}
      InputProps={{
       readOnly: true
      }}
     />
     <Stack direction="row" spacing={2}>
      <Button variant="kxActionButton" onClick={handleOpenChanges}>
       Modifica
      </Button>
     </Stack>
    </Stack>
   )}
  </Stack>
 );
};

export default TelegramChannel;
