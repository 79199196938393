import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import ImageSlider from "components/shared/images/ImageSlider";

import { getServersLocationNumber } from "redux/handlers/serverHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IServerLocationsAPI } from "types/api/serversApiInterface";

const imageList = [
 `${process.env.PUBLIC_URL}/svg/icons/europe.svg`,
 `${process.env.PUBLIC_URL}/svg/icons/usa.svg`,
 `${process.env.PUBLIC_URL}/svg/icons/asia.svg`
];

const LocationsMapBox = (): ReactElement => {
 const dispatch = useAppDispatch();

 const [locationData, setLocationData] = useState<IServerLocationsAPI | null>(null);

 useEffect(() => {
  (async () => {
   setLocationData(await dispatch(getServersLocationNumber()));
  })();
 }, []);

 return (
  <Paper elevation={0} sx={{ mt: 3, p: 2, borderRadius: "10px", boxShadow: 0 }}>
   <Stack spacing={2} justifyContent="center">
    <Stack>
     <Typography textAlign="left" variant="h5" fontWeight="bold" textTransform="uppercase">
      <FormattedMessage id="app.serverLocations" />
     </Typography>
    </Stack>
    <Stack direction="row" spacing={2}>
     <Stack spacing={1} minWidth="150px">
      <Stack direction="row" spacing={1}>
       <Stack component="span" className={"fi fi-de"} />
       <Typography>{`Falkenstein: ${locationData?.fsn1 || 0}`}</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
       <Stack component="span" className={"fi fi-de"} />
       <Typography>{`Nuremberg: ${locationData?.nbg1 || 0}`}</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
       <Stack component="span" className={"fi fi-fi"} />
       <Typography>{`Helsinki: ${locationData?.hel1 || 0}`}</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
       <Stack component="span" className={"fi fi-us"} />
       <Typography>{`Ashburn: ${locationData?.ash || 0}`}</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
       <Stack component="span" className={"fi fi-us"} />
       <Typography>{`Hillsboro: ${locationData?.hil || 0}`}</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
       <Stack component="span" className={"fi fi-sg"} />
       <Typography>{`Singapore: ${locationData?.sin || 0}`}</Typography>
      </Stack>
     </Stack>
     <Stack direction="row" width="100%" justifyContent="center">
      <ImageSlider
       imageList={imageList.map((url, index) => {
        return <img alt="logo" width={700} src={url} key={index} />;
       })}
      />
     </Stack>
    </Stack>
   </Stack>
  </Paper>
 );
};

export default LocationsMapBox;
