import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { locationList } from "constants/locations";

import { getCloudboxDataset } from "redux/selectors/cloudboxSelector";

const CloudboxUpperPage = (): ReactElement => {
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));
 const params = useParams<{ id: string; section: string }>();
 const cloudboxData = useSelector(getCloudboxDataset).find(
  (element) => element.id === Number(params.id)
 );

 const RenderLocation = (location: string) => {
  const filteredLocation = locationList.filter((data) => location === data.tag);
  return filteredLocation.map((element, index) => (
   <Stack key={`location-item-${index}`} direction="row" spacing={1}>
    <Stack component="span" className={`fi fi-${element.flag}`} />
    <Typography variant="inherit">
     <FormattedMessage id={element.location} />
    </Typography>
   </Stack>
  ));
 };

 return (
  <Stack
   mt={desktopViewPort ? 5 : 0}
   justifyContent="space-between"
   direction={desktopViewPort ? "row" : "column"}>
   <Stack>
    <Stack>
     <Typography fontWeight="bold" fontSize={16} textAlign="left" textTransform="uppercase">
      {cloudboxData?.name || "N/A"}
     </Typography>
     {cloudboxData?.location && RenderLocation(cloudboxData.location.toLowerCase())}
    </Stack>
   </Stack>
  </Stack>
 );
};

export default CloudboxUpperPage;
