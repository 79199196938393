import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import { domainsLinks } from "constants/staticLinks";

import DomainChangeNs from "components/domains/managePages/DomainChangeNs";
import DomainChangeOwner from "components/domains/managePages/DomainChangeOwner";
import DomainDns from "components/domains/managePages/DomainDns";
import DomainsDetails from "components/domains/managePages/DomainsDetails";
import DomainSpecifications from "components/domains/managePages/DomainSpecifications";
import SectionsBreadCrumbs from "components/shared/breadCrumbs/SectionsBreadCrumbs";
import MobileSectionMenu from "components/shared/sideMenu/MobileSectionMenu";
import SectionMenu from "components/shared/sideMenu/SectionMenu";

import { getDomainDetails } from "redux/handlers/domainsHandler";

import { useAppDispatch } from "hooks/reduxHook";

const DomainManagePage = (): ReactElement => {
 const location = useLocation();
 const dispatch = useAppDispatch();
 const params = useParams<{ id: string; section: string }>();
 const theme = useTheme();
 const { socket } = useContext(AppContext);
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const [reloadData, setReloadData] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(true);

 useEffect(() => {
  !isNil(socket) &&
   socket.on("domain_data", (value): void => {
    if (value?.completed) {
     setReloadData(true);
    }
   });
  return () => {
   !isNil(socket) && socket.off("domain_data");
  };
 }, [socket]);

 useEffect(() => {
  (async () => {
   if (reloadData) {
    dispatch(getDomainDetails(0, 100, Number(params.id)));
    setReloadData(false);
   }
  })();
 }, [reloadData]);

 useEffect(() => {
  (async () => {
   await dispatch(getDomainDetails(0, 100, Number(params.id)));
   setLoading(false);
  })();
 }, []);

 const RenderDomainSections = () => {
  switch (params.section) {
   case "details":
    return <DomainSpecifications />;
   case "dns":
    return <DomainDns />;
   case "owners":
    return <DomainChangeOwner />;
   case "nameservers":
    return <DomainChangeNs />;
   default:
    return <DomainSpecifications />;
  }
 };

 return loading ? (
  <></>
 ) : (
  <Container maxWidth="xl">
   <Stack pt={1}>
    <SectionsBreadCrumbs
     links={[
      { name: "home", url: "/" },
      { name: "domains", url: "/domains" },
      { name: params?.section || "", url: location.pathname }
     ]}
    />
   </Stack>
   <Grid container>
    {desktopViewPort ? (
     <Grid xs={desktopViewPort ? 3 : 0}>
      <Stack mt={6}>
       <SectionMenu
        menuList={domainsLinks.map((item) => ({
         name: item.name,
         url: `/domains/manage/${params?.id}/${item.url}`,
         icon: item.icon
        }))}
       />
      </Stack>
     </Grid>
    ) : (
     <MobileSectionMenu links={domainsLinks} disabled={[]} url={`/domains/manage/${params?.id}`} />
    )}
    <Grid xs={desktopViewPort ? 9 : 12} mt={3}>
     <DomainsDetails />
     <RenderDomainSections />
    </Grid>
   </Grid>
  </Container>
 );
};

export default DomainManagePage;
