import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const DeleteIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <path
   d="M13.0172 7.53874L12.7333 14.9232M8.80479 14.9232L8.52091 7.53874M16.6996 4.90497C16.9802 4.94763 17.2591 4.99276 17.5381 5.04117M16.6996 4.90497L15.8233 16.2958C15.7875 16.7596 15.578 17.1928 15.2366 17.5087C14.8953 17.8247 14.4472 18.0001 13.9821 18H7.55601C7.09087 18.0001 6.64282 17.8247 6.30146 17.5087C5.9601 17.1928 5.75059 16.7596 5.71483 16.2958L4.83854 4.90497M16.6996 4.90497C15.7526 4.7618 14.8007 4.65315 13.8459 4.57923M4.83854 4.90497C4.55793 4.94681 4.27897 4.99194 4 5.04035M4.83854 4.90497C5.7855 4.76181 6.73735 4.65316 7.69221 4.57923M13.8459 4.57923V3.82766C13.8459 2.85948 13.0992 2.05212 12.1311 2.02176C11.2233 1.99275 10.3148 1.99275 9.40704 2.02176C8.43886 2.05212 7.69221 2.8603 7.69221 3.82766V4.57923M13.8459 4.57923C11.7977 4.42094 9.74038 4.42094 7.69221 4.57923"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
 </svg>,
 "Delete"
);
