import React, { ReactElement, useState } from "react";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";

import { isNil } from "ramda";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";

import { IAllAddonsAPI, IAllAddonsDataset } from "types/api/addonsApiInterface";
import { addonsSelectedList } from "types/global/user";

type sectionType =
 | "cloudboxes"
 | "volumes"
 | "load_balancers"
 | "floating_ips"
 | "snapshots"
 | "networks"
 | "firewalls";

const AddonsSection = ({
 control,
 addons,
 selectedAddons,
 setAddons,
 addAddons
}: {
 control: any;
 addons: IAllAddonsAPI | null;
 selectedAddons: addonsSelectedList;
 setAddons: (section: sectionType, value: Array<number>) => void;
 addAddons: (section: sectionType, value: Array<{ id: number; label: string }>) => void;
}): ReactElement => {
 const intl = useIntl();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const [section, setSection] = useState<number>(0);

 const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
  setSection(newValue);
 };

 const RenderTabContent = (): ReactElement => {
  switch (section) {
   case 0:
    return (
     <Stack spacing={2}>
      <Controller
       name="cloudboxes"
       control={control}
       render={({ field }) => (
        <Autocomplete
         {...field}
         multiple
         id="tags-outlined"
         options={
          addons?.cloudboxes
           ? addons?.cloudboxes?.map((element: IAllAddonsDataset) => {
              return {
               id: element.id,
               label: element.name
              };
             })
           : []
         }
         filterSelectedOptions
         value={addons?.cloudboxes
          .filter((element) => selectedAddons.cloudboxes.includes(element.id))
          .map((element) => {
           return {
            id: element.id,
            label: element.name
           };
          })}
         getOptionLabel={(option) => option.label}
         onChange={(e, value) => {
          isNil(value)
           ? setAddons("cloudboxes", selectedAddons.cloudboxes)
           : addAddons("cloudboxes", value);
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           label={intl.formatMessage({ id: "teams.usersettings.selectCloudboxes" })}
           placeholder="Cloudbox..."
          />
         )}
        />
       )}
      />
     </Stack>
    );
   case 1:
    return (
     <Stack spacing={2}>
      <Controller
       name="volumes"
       control={control}
       render={({ field }) => (
        <Autocomplete
         {...field}
         multiple
         id="tags-outlined"
         options={
          addons?.volumes
           ? addons?.volumes?.map((element: IAllAddonsDataset) => {
              return {
               id: element.id,
               label: element.name
              };
             })
           : []
         }
         filterSelectedOptions
         value={addons?.volumes
          .filter((element) => selectedAddons.volumes.includes(element.id))
          .map((element) => {
           return {
            id: element.id,
            label: element.name
           };
          })}
         getOptionLabel={(option) => option.label}
         onChange={(e, value) => {
          isNil(value) ? setAddons("volumes", selectedAddons.volumes) : addAddons("volumes", value);
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           label={intl.formatMessage({ id: "teams.usersettings.selectVolumes" })}
           placeholder="Volume..."
          />
         )}
        />
       )}
      />
     </Stack>
    );
   case 2:
    return (
     <Stack spacing={2}>
      <Controller
       name="floating_ips"
       control={control}
       render={({ field }) => (
        <Autocomplete
         {...field}
         multiple
         id="tags-outlined"
         options={
          addons?.floating_ips
           ? addons?.floating_ips?.map((element: IAllAddonsDataset) => {
              return {
               id: element.id,
               label: element.name
              };
             })
           : []
         }
         filterSelectedOptions
         value={addons?.floating_ips
          .filter((element) => selectedAddons.floating_ips.includes(element.id))
          .map((element) => {
           return {
            id: element.id,
            label: element.name
           };
          })}
         getOptionLabel={(option) => option.label}
         onChange={(e, value) => {
          isNil(value)
           ? setAddons("floating_ips", selectedAddons.floating_ips)
           : addAddons("floating_ips", value);
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           label={intl.formatMessage({ id: "teams.usersettings.selectFloatingIps" })}
           placeholder="Floating IP..."
          />
         )}
        />
       )}
      />
     </Stack>
    );
   case 3:
    return (
     <Stack spacing={2}>
      <Controller
       name="networks"
       control={control}
       render={({ field }) => (
        <Autocomplete
         {...field}
         multiple
         id="tags-outlined"
         options={
          addons?.networks
           ? addons?.networks?.map((element: IAllAddonsDataset) => {
              return {
               id: element.id,
               label: element.name
              };
             })
           : []
         }
         filterSelectedOptions
         value={addons?.networks
          .filter((element) => selectedAddons.networks.includes(element.id))
          .map((element) => {
           return {
            id: element.id,
            label: element.name
           };
          })}
         getOptionLabel={(option) => option.label}
         onChange={(e, value) => {
          isNil(value)
           ? setAddons("networks", selectedAddons.networks)
           : addAddons("networks", value);
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           label={intl.formatMessage({ id: "teams.usersettings.selectNetworks" })}
           placeholder="Network..."
          />
         )}
        />
       )}
      />
     </Stack>
    );
   case 4:
    return (
     <Stack spacing={2}>
      <Controller
       name="snapshots"
       control={control}
       render={({ field }) => (
        <Autocomplete
         {...field}
         multiple
         id="tags-outlined"
         options={
          addons?.snapshots
           ? addons?.snapshots?.map((element: IAllAddonsDataset) => {
              return {
               id: element.id,
               label: element.name
              };
             })
           : []
         }
         filterSelectedOptions
         value={addons?.snapshots
          .filter((element) => selectedAddons.snapshots.includes(element.id))
          .map((element) => {
           return {
            id: element.id,
            label: element.name
           };
          })}
         getOptionLabel={(option) => option.label}
         onChange={(e, value) => {
          isNil(value)
           ? setAddons("snapshots", selectedAddons.snapshots)
           : addAddons("snapshots", value);
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           label={intl.formatMessage({ id: "teams.usersettings.selectSnapshots" })}
           placeholder="Snapshot..."
          />
         )}
        />
       )}
      />
     </Stack>
    );
   case 5:
    return (
     <Stack spacing={2}>
      <Controller
       name="firewalls"
       control={control}
       render={({ field }) => (
        <Autocomplete
         {...field}
         multiple
         id="tags-outlined"
         options={
          addons?.firewalls
           ? addons?.firewalls?.map((element: IAllAddonsDataset) => {
              return {
               id: element.id,
               label: element.name
              };
             })
           : []
         }
         filterSelectedOptions
         value={addons?.firewalls
          .filter((element) => selectedAddons.firewalls.includes(element.id))
          .map((element) => {
           return {
            id: element.id,
            label: element.name
           };
          })}
         getOptionLabel={(option) => option.label}
         onChange={(e, value) => {
          isNil(value)
           ? setAddons("firewalls", selectedAddons.firewalls)
           : addAddons("firewalls", value);
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           label={intl.formatMessage({ id: "teams.usersettings.selectFirewalls" })}
           placeholder="Site..."
          />
         )}
        />
       )}
      />
     </Stack>
    );
   case 6:
    return (
     <Stack spacing={2}>
      <Controller
       name="load_balancers"
       control={control}
       render={({ field }) => (
        <Autocomplete
         {...field}
         multiple
         id="tags-outlined"
         options={
          addons?.load_balancers
           ? addons?.load_balancers?.map((element: IAllAddonsDataset) => {
              return {
               id: element.id,
               label: element.name
              };
             })
           : []
         }
         filterSelectedOptions
         value={addons?.load_balancers
          .filter((element) => selectedAddons.load_balancers.includes(element.id))
          .map((element) => {
           return {
            id: element.id,
            label: element.name
           };
          })}
         getOptionLabel={(option) => option.label}
         onChange={(e, value) => {
          isNil(value)
           ? setAddons("load_balancers", selectedAddons.load_balancers)
           : addAddons("load_balancers", value);
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           label={intl.formatMessage({ id: "teams.usersettings.selectLoadBalancers" })}
           placeholder="Load balancer..."
          />
         )}
        />
       )}
      />
     </Stack>
    );
   default:
    return (
     <Stack spacing={2}>
      <Controller
       name="cloudboxes"
       control={control}
       render={({ field }) => (
        <Autocomplete
         {...field}
         multiple
         id="tags-outlined"
         options={
          addons?.cloudboxes
           ? addons?.cloudboxes?.map((element: IAllAddonsDataset) => {
              return {
               id: element.id,
               label: element.name
              };
             })
           : []
         }
         filterSelectedOptions
         value={addons?.cloudboxes
          .filter((element) => selectedAddons.cloudboxes.includes(element.id))
          .map((element) => {
           return {
            id: element.id,
            label: element.name
           };
          })}
         getOptionLabel={(option) => option.label}
         onChange={(e, value) => {
          isNil(value)
           ? setAddons("cloudboxes", selectedAddons.cloudboxes)
           : addAddons("cloudboxes", value);
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           label={intl.formatMessage({ id: "teams.usersettings.selectCloudboxes" })}
           placeholder="Site..."
          />
         )}
        />
       )}
      />
     </Stack>
    );
  }
 };

 return (
  <Accordion>
   <AccordionSummary expandIcon={<ExpandMoreIcon />}>Addons</AccordionSummary>
   <AccordionDetails>
    <Tabs
     value={section}
     onChange={handleChangeTab}
     variant={desktopViewPort ? "standard" : "scrollable"}
     scrollButtons={true}
     allowScrollButtonsMobile
     sx={{ mb: 2 }}>
     <Tab label={intl.formatMessage({ id: "app.cloudbox" })} />
     <Tab label={intl.formatMessage({ id: "app.volumes" })} />
     <Tab label={intl.formatMessage({ id: "app.floatingIp" })} />
     <Tab label={intl.formatMessage({ id: "app.networks" })} />
     <Tab label={intl.formatMessage({ id: "app.snapshots" })} />
     <Tab label={intl.formatMessage({ id: "app.firewall" })} />
     <Tab label={intl.formatMessage({ id: "app.loadBalancer" })} />
    </Tabs>
    <RenderTabContent />
   </AccordionDetails>
  </Accordion>
 );
};

export default AddonsSection;
