import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const DataIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <path
   fillRule="evenodd"
   clipRule="evenodd"
   d="M1.65901 3.65901C2.08097 3.23705 2.65326 3 3.25 3H8.0911C8.29804 3.00004 8.50299 3.04087 8.69415 3.12014C8.88526 3.1994 9.05888 3.31553 9.2051 3.4619C9.20513 3.46193 9.20507 3.46187 9.2051 3.4619L11.1131 5.3699C11.3238 5.58083 11.6107 5.69974 11.9091 5.7H16.75C17.3467 5.7 17.919 5.93705 18.341 6.35901C18.7629 6.78097 19 7.35326 19 7.95V16.05C19 16.6467 18.7629 17.219 18.341 17.641C17.919 18.0629 17.3467 18.3 16.75 18.3H3.25C2.65326 18.3 2.08097 18.0629 1.65901 17.641C1.23705 17.219 1 16.6467 1 16.05V5.25C1 4.65326 1.23705 4.08097 1.65901 3.65901ZM3.25 3.45C2.77261 3.45 2.31477 3.63964 1.97721 3.97721C1.63964 4.31477 1.45 4.77261 1.45 5.25V16.05C1.45 16.5274 1.63964 16.9852 1.97721 17.3228C2.31477 17.6604 2.77261 17.85 3.25 17.85H16.75C17.2274 17.85 17.6852 17.6604 18.0228 17.3228C18.3604 16.9852 18.55 16.5274 18.55 16.05V7.95C18.55 7.47261 18.3604 7.01477 18.0228 6.67721C17.6852 6.33964 17.2274 6.15 16.75 6.15H11.9089C11.4915 6.14963 11.0901 5.9836 10.7949 5.6881C10.7949 5.68807 10.7949 5.68813 10.7949 5.6881L8.8869 3.7801C8.78244 3.67551 8.6583 3.59244 8.52177 3.53582C8.38524 3.4792 8.2389 3.45004 8.0911 3.45C8.09108 3.45 8.09112 3.45 8.0911 3.45H3.25Z"
   fill="currentColor"
  />
 </svg>,
 "Data"
);
