import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import Alert from "@mui/material/Alert";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { postGetDatabaseData } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IDatabaseData } from "types/api/dockerApiInterface";

import DisableRemoteDb from "../../actions/DisableRemoteDb";
import EnableRemoteDb from "../../actions/EnableRemoteDb";
import PowerOnSite from "../../actions/PowerOnSite";
import RemoveDatabase from "../../actions/RemoveDatabase";

const DatabaseSection = ({
 id,
 container_id,
 dbOnline
}: {
 id: number;
 container_id: string;
 dbOnline: string;
}): ReactElement => {
 const dispatch = useAppDispatch();

 const [databaseList, setDatabaseList] = useState<Array<IDatabaseData>>([]);

 useEffect(() => {
  (async () => {
   if (container_id) {
    const dblist = await dispatch(postGetDatabaseData(container_id));
    setDatabaseList(dblist);
   }
  })();
 }, []);

 return dbOnline === "online" ? (
  <Stack spacing={2}>
   <Typography fontWeight="bold">
    <FormattedMessage id="docker.website.selectedDatabase" />
    {`: ${databaseList[0]?.db_name || ""}`}
   </Typography>
   <Typography fontWeight="bold">
    <FormattedMessage id="docker.website.selectedDatabaseAccess" />
   </Typography>
   <Stack>
    <Typography>
     <FormattedMessage id="docker.website.databaseName" />
     {`: ${databaseList[0]?.db_name}`}
    </Typography>
    <Typography>
     <FormattedMessage id="docker.website.databaseHost" />
     {`: ${databaseList[0]?.db_host}`}
    </Typography>
    <Typography>
     <FormattedMessage id="docker.website.databaseUser" />
     {`: ${databaseList[0]?.db_user}`}
    </Typography>
    <Typography>
     <FormattedMessage id="docker.website.databasePassword" />
     {`: ${databaseList[0]?.db_password}`}
    </Typography>
    <Typography>
     <FormattedMessage id="docker.website.databasePasswordRoot" />
     {`: ${databaseList[0]?.db_root_password}`}
    </Typography>
    <Typography>
     <FormattedMessage id="docker.website.databaseType" />
     {`: ${databaseList[0]?.db_type || "Not available"}`}
    </Typography>
    {databaseList[0]?.remote ? (
     <Stack direction="row" spacing={1}>
      <Typography>
       <FormattedMessage id="docker.website.remoteAccess" />
      </Typography>
      {": "}
      <Chip label="Online" color="success" size="small" />
     </Stack>
    ) : (
     <Stack direction="row" spacing={1}>
      <Typography>
       <FormattedMessage id="docker.website.remoteAccess" />
      </Typography>
      {": "}
      <Chip label="Offline" color="error" size="small" />
     </Stack>
    )}
    <Typography>
     <FormattedMessage id="docker.website.remoteAccessPort" />
     {`: ${databaseList[0]?.remote ? databaseList[0]?.remote_port : "Not available"}`}
    </Typography>
   </Stack>
   <Stack direction="row">
    <RemoveDatabase id={id} />
    {databaseList[0]?.remote ? <DisableRemoteDb id={id} /> : <EnableRemoteDb id={id} />}
   </Stack>
  </Stack>
 ) : dbOnline === "restarting" ? (
  <Stack direction="row" spacing={1}>
   <CircularProgress />
   <Typography>
    <FormattedMessage id="docker.website.dbRestarting" />
   </Typography>
  </Stack>
 ) : (
  <Stack spacing={2}>
   <Alert severity="warning">
    <Typography>
     <FormattedMessage id="docker.website.dbIsOffline" />
    </Typography>
   </Alert>
   <Stack>
    <Typography fontWeight="bold">
     <FormattedMessage id="docker.website.dbOperations" />
    </Typography>
    <Stack direction="row">
     <PowerOnSite id={id} type="db" />
    </Stack>
   </Stack>
  </Stack>
 );
};

export default DatabaseSection;
