import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import EmptyList from "components/shared/emptyList/EmptyList";
import IconSelector from "components/shared/images/IconSelector";

import { postRetriveAliasForward } from "redux/handlers/mailsHandler";

import { getMailsList } from "redux/selectors/mailsSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IMailAliasAPI } from "types/api/mailApiInterface";

import AddAlias from "../../actions/AddAlias";
import DeleteAliasForward from "../../actions/DeleteAliasForward";
import EditAlias from "../../actions/EditAlias";

const MailAlias = (): ReactElement => {
 const dispatch = useAppDispatch();
 const params = useParams<{ mail_id: string; section: string }>();
 const mailList = useSelector(getMailsList);
 const maildetails = mailList.find((element) => element.id === Number(params.mail_id));

 const [alias, setAlias] = useState<Array<IMailAliasAPI>>([]);
 const [loading, setLoading] = useState<boolean>(false);

 useEffect(() => {
  (async () => {
   setLoading(true);
   setAlias(await dispatch(postRetriveAliasForward(maildetails?.id || 0, "alias")));
   setLoading(false);
  })();
 }, [maildetails]);

 return loading ? (
  <Stack direction="row" alignItems="center" justifyContent="center">
   <CircularProgress />
  </Stack>
 ) : alias.length > 0 ? (
  <Stack spacing={2}>
   <Stack direction="row">
    <AddAlias />
   </Stack>
   <TableContainer component="div">
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
     <TableHead>
      <TableRow>
       <TableCell>
        <FormattedMessage id="mails.alias.status" />
       </TableCell>
       <TableCell>
        <FormattedMessage id="mails.alias.source" />
       </TableCell>
       <TableCell>
        <FormattedMessage id="mails.alias.destination" />
       </TableCell>
       <TableCell>
        <FormattedMessage id="mails.alias.permissions" />
       </TableCell>
       <TableCell align="right">
        <FormattedMessage id="app.actions" />
       </TableCell>
      </TableRow>
     </TableHead>
     <TableBody>
      {alias.map((rowData, index) => {
       return (
        <TableRow key={`mail-alias-${index}`}>
         <TableCell>
          <Chip
           label={rowData.active ? "online" : "offline"}
           color={rowData.active ? "success" : "error"}
           size="small"
          />
         </TableCell>
         <TableCell>
          <Typography variant="caption">{rowData.source}</Typography>
         </TableCell>
         <TableCell>
          <Typography variant="caption">{rowData.destination}</Typography>
         </TableCell>
         <TableCell>
          <Stack>
           <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="caption">
             <FormattedMessage id="mails.alias.allowsend" />
            </Typography>
            {rowData.allow_send_as ? (
             <IconSelector icon="CheckCircleIcon" props={{ color: "success" }} />
            ) : (
             <IconSelector icon="CancelIcon" props={{ color: "error" }} />
            )}
           </Stack>
           <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="caption">
             <FormattedMessage id="mails.alias.greylist" />
            </Typography>
            {rowData.greylisting ? (
             <IconSelector icon="CheckCircleIcon" props={{ color: "success" }} />
            ) : (
             <IconSelector icon="CancelIcon" props={{ color: "error" }} />
            )}
           </Stack>
          </Stack>
         </TableCell>
         <TableCell align="right">
          <EditAlias rowData={rowData} />
          <DeleteAliasForward id={rowData.id} />
         </TableCell>
        </TableRow>
       );
      })}
     </TableBody>
    </Table>
   </TableContainer>
  </Stack>
 ) : (
  <Stack>
   <EmptyList />
   <AddAlias />
  </Stack>
 );
};

export default MailAlias;
