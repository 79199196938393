import React, { ReactElement } from "react";
import { useLocation, useParams } from "react-router-dom";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import { addonsLinks } from "constants/staticLinks";

import FloatingIpPage from "pages/addons/FloatingIpPage";

import SectionsBreadCrumbs from "components/shared/breadCrumbs/SectionsBreadCrumbs";
import MobileSectionMenu from "components/shared/sideMenu/MobileSectionMenu";
import SectionMenu from "components/shared/sideMenu/SectionMenu";

import CloudboxPage from "./CloudboxPage";
import FirewallPage from "./FirewallPage";
import LoadBalancerPage from "./LoadBalancerPage";
import NetworkPage from "./NetworkPage";
import SnapshotPage from "./SnapshotPage";
import VolumePage from "./VolumePage";

const AddonsPage = (): ReactElement => {
 const location = useLocation();
 const params = useParams<{ section: string }>();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const RenderAddon = (): ReactElement => {
  switch (params.section) {
   case "floatingip":
    return <FloatingIpPage />;
   case "volumes":
    return <VolumePage />;
   case "snapshots":
    return <SnapshotPage />;
   case "firewall":
    return <FirewallPage />;
   case "network":
    return <NetworkPage />;
   case "loadBalancer":
    return <LoadBalancerPage />;
   default:
    return <CloudboxPage />;
  }
 };

 return (
  <Container maxWidth="xl">
   <Stack mt={2}>
    <SectionsBreadCrumbs
     links={[
      { name: "home", url: "/" },
      { name: "addons", url: "/addons/cloudbox" },
      { name: params?.section || "", url: location.pathname }
     ]}
    />
   </Stack>
   <Grid container>
    {desktopViewPort ? (
     <Grid xs={desktopViewPort ? 3 : 0}>
      <Stack mt={6}>
       <SectionMenu
        menuList={addonsLinks.map((item) => ({
         name: item.name,
         url: `/addons/${item.url}`,
         icon: item.icon
        }))}
       />
      </Stack>
     </Grid>
    ) : (
     <MobileSectionMenu links={addonsLinks} disabled={[]} url={"/addons"} />
    )}
    <Grid xs={desktopViewPort ? 9 : 12}>
     <RenderAddon />
    </Grid>
   </Grid>
  </Container>
 );
};

export default AddonsPage;
