import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import { profilePictureValidation } from "constants/regexp";

import AppModal from "components/shared/modal/AppModal";

import { postChangeProfilePicture } from "redux/handlers/userHandler";

import { getUserAvatar } from "redux/selectors/userSelector";

const ChangeAvatar = (): ReactElement => {
 const intl = useIntl();
 const profileAvatar: string = useSelector(getUserAvatar);

 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const { control, handleSubmit, formState } = useForm({
  defaultValues: {
   avatar: profileAvatar
  }
 });

 const onSubmit: SubmitHandler<{ avatar: string }> = async (data: { avatar: string }) => {
  setIsLoading(true);
  await postChangeProfilePicture(data.avatar);
  setIsLoading(false);
  setOpenModal(false);
 };

 return (
  <>
   <Button onClick={handleOpen} variant="kxActionButton" sx={{ mt: 2 }}>
    <FormattedMessage id="profile.updateAvatar" />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    handleConfirm={handleSubmit(onSubmit)}
    title={intl.formatMessage({ id: "profile.updateAvatar" })}>
    <form onSubmit={handleSubmit(onSubmit)}>
     <Controller
      name="avatar"
      control={control}
      rules={{
       required: true,
       pattern: profilePictureValidation
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "profile.avatarLink" })}
        error={formState.isDirty && !!formState?.errors?.avatar}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.avatar &&
         intl.formatMessage({ id: "profile.avatarLinkError" })
        }
       />
      )}
     />
    </form>
   </AppModal>
  </>
 );
};

export default ChangeAvatar;
