import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";

import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import ClickToCopy from "components/shared/clipboardCopy/ClickToCopy";

import {
 getLoadBalancerDetails,
 getLoadBalancerServices,
 getLoadBalancerTargets
} from "redux/selectors/loadBalancerSelector";

const LoadBalancerMonitoring = (): ReactElement => {
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const balancerData = useSelector(getLoadBalancerDetails);
 const targetsData = useSelector(getLoadBalancerTargets);
 const servicesData = useSelector(getLoadBalancerServices);

 const checkServiceStatus = (servicePort: number): number => {
  const filteredTargets = targetsData.filter(
   (element) =>
    element.health_status.find((stats) => stats.listen_port === servicePort)?.status === "healthy"
  );
  const allServiceTargets = targetsData.filter(
   (element) =>
    element.health_status.find((stats) => stats.listen_port === servicePort)?.listen_port ===
    servicePort
  );

  if (allServiceTargets.length === 0) return 0;

  switch (allServiceTargets.length - filteredTargets.length) {
   case 0:
    return filteredTargets.length;
   case allServiceTargets.length:
    return 0;
   default:
    return allServiceTargets.length - filteredTargets.length;
  }
 };

 const getSaneTargets = (): number => {
  let counter = 0;
  for (let element of servicesData) {
   counter += checkServiceStatus(element.listen_port);
  }

  return counter;
 };

 const getColor = (): string => {
  switch (targetsData.length - getSaneTargets()) {
   case 0:
    return "#00a152";
   case targetsData.length:
    return "#b2102f";
   default:
    return "#ffcd38";
  }
 };

 return (
  <Paper
   elevation={0}
   sx={{ pt: 2, mt: desktopViewPort ? 1 : 2, pb: 2, borderRadius: "10px", boxShadow: 0 }}>
   <Grid container>
    <Grid xs={desktopViewPort ? 6 : 12} sx={{ p: 2 }}>
     <Stack textAlign="left">
      <Stack direction="row">
       <Typography variant="caption" fontWeight="bold" sx={{ minWidth: 100 }}>
        <FormattedMessage id="addons.loadBalancer.ipv4" />
       </Typography>
       <Typography variant="caption">
        {balancerData?.public_net_ipv4_ip || "Not available"}
       </Typography>
       {balancerData?.public_net_ipv4_ip && <ClickToCopy text={balancerData?.public_net_ipv4_ip} />}
      </Stack>
      <Stack direction="row">
       <Typography variant="caption" fontWeight="bold" sx={{ minWidth: 100 }}>
        <FormattedMessage id="addons.loadBalancer.ipv4_rdns" />
       </Typography>
       <Typography variant="caption">
        {balancerData?.public_net_ipv4_dns_ptr || "Not available"}
       </Typography>
       {balancerData?.public_net_ipv4_dns_ptr && (
        <ClickToCopy text={balancerData?.public_net_ipv4_dns_ptr} />
       )}
      </Stack>
      <Stack direction="row">
       <Typography variant="caption" fontWeight="bold" sx={{ minWidth: 100 }}>
        <FormattedMessage id="addons.loadBalancer.ipv6" />
       </Typography>
       <Typography variant="caption">
        {balancerData?.public_net_ipv6_ip || "Not available"}
       </Typography>
       {balancerData?.public_net_ipv6_ip && <ClickToCopy text={balancerData?.public_net_ipv6_ip} />}
      </Stack>
      <Stack direction="row">
       <Typography variant="caption" fontWeight="bold" sx={{ minWidth: 100 }}>
        <FormattedMessage id="addons.loadBalancer.ipv6_rdns" />
       </Typography>
       <Typography variant="caption">
        {balancerData?.public_net_ipv6_dns_ptr || "Not available"}
       </Typography>
       {balancerData?.public_net_ipv6_dns_ptr && (
        <ClickToCopy text={balancerData?.public_net_ipv6_dns_ptr} />
       )}
      </Stack>
      <Stack direction="row">
       <Typography variant="caption" fontWeight="bold" sx={{ minWidth: 100 }}>
        <FormattedMessage id="addons.loadBalancer.private_ip" />
       </Typography>
       {balancerData?.private_net && balancerData?.private_net.length > 0 ? (
        balancerData?.private_net.map((element, index) => {
         return (
          <Stack key={`private-net-index-${index}`} direction="row" spacing={1}>
           <Typography variant="caption">{element.ip}</Typography>
           <ClickToCopy text={element.ip} />
          </Stack>
         );
        })
       ) : (
        <Typography variant="caption">Not available</Typography>
       )}
      </Stack>
     </Stack>
    </Grid>
    <Grid xs={desktopViewPort ? 6 : 12}>
     <Stack direction="row" minHeight="100%" px={2}>
      <Gauge
       value={getSaneTargets()}
       startAngle={-110}
       endAngle={110}
       valueMax={targetsData.length}
       height={120}
       sx={{
        [`& .${gaugeClasses.valueText}`]: {
         fontSize: 20,
         transform: "translate(0px, 0px)"
        },
        [`& .${gaugeClasses.valueArc}`]: {
         fill: getColor()
        }
       }}
       text={({ value, valueMax }) => `${value} / ${valueMax}`}
      />
      <Stack justifyContent="center">
       <Chip
        label={
         getSaneTargets() === 0
          ? "Unhealthy"
          : getSaneTargets() === targetsData.length
          ? "Healthy"
          : "Mixed"
        }
        color={
         getSaneTargets() === 0
          ? "error"
          : getSaneTargets() === targetsData.length
          ? "success"
          : "warning"
        }
       />
       <Typography variant="caption">
        <FormattedMessage
         id={
          getSaneTargets() === 0
           ? "addons.loadBalancer.allUnhealthy"
           : getSaneTargets() === targetsData.length
           ? "addons.loadBalancer.allHealthy"
           : "addons.loadBalancer.mixedHealth"
         }
        />
       </Typography>
      </Stack>
     </Stack>
    </Grid>
   </Grid>
  </Paper>
 );
};

export default LoadBalancerMonitoring;
