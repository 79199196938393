import React, { ReactElement } from "react";
import { useIntl } from "react-intl";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

const ExpiresSettings = ({
 channelActive,
 userTypes,
 service,
 active,
 time,
 setActive,
 setTime,
 setService
}: {
 channelActive: boolean;
 userTypes: Array<string>;
 service: string;
 active: boolean;
 time: number;
 setActive: (active: boolean) => void;
 setTime: (time: number) => void;
 setService: (service: string) => void;
}): ReactElement => {
 const intl = useIntl();

 return (
  <Accordion>
   <AccordionSummary expandIcon={<ExpandMoreIcon />}>
    Impostazioni notifiche per scadenze servizi
   </AccordionSummary>
   <AccordionDetails>
    <Stack spacing={2}>
     <Stack direction="row" spacing={2}>
      <Autocomplete
       fullWidth={true}
       autoHighlight
       sx={{ my: 2 }}
       disabled={!channelActive}
       options={userTypes}
       value={service}
       onChange={(e, value) => setService(value || "server")}
       renderInput={(params) => (
        <TextField
         {...params}
         label={intl.formatMessage({ id: "profile.settings.channelService" })}
         variant="standard"
         InputLabelProps={{ shrink: true }}
        />
       )}
      />
      <FormControlLabel
       control={
        <Checkbox
         checked={active}
         disabled={!channelActive}
         onChange={(e) => {
          setActive(!active);
         }}
        />
       }
       label={intl.formatMessage({ id: "profile.settings.setSectionActive" })}
      />
     </Stack>
     <Stack>
      <TextField
       disabled={!channelActive}
       onChange={(event) =>
        Number(event.target.value) <= 0 ? setTime(1) : setTime(Number(event.target.value))
       }
       value={time}
       type="number"
       label={intl.formatMessage({ id: "profile.settings.channelTime" })}
       helperText={intl.formatMessage({ id: "profile.settings.expireHelper" })}
      />
     </Stack>
    </Stack>
   </AccordionDetails>
  </Accordion>
 );
};

export default ExpiresSettings;
