import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";
import useIntl from "react-intl/src/components/useIntl";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postDeleteCertificate } from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

const DeleteCertificate = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postDeleteCertificate(id));
  handleClose();
  setLoading(false);
 };

 return (
  <>
   <Tooltip title={intl.formatMessage({ id: "app.delete" })} placement="top">
    <IconButton onClick={handleOpen}>
     <IconSelector icon="DeleteIcon" props={{ fontSize: "small" }} />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "addons.certificate.deleteCertificate" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}>
    <Typography>
     <FormattedMessage id="addons.certificate.deleteCertificateMessage" />
    </Typography>
   </AppModal>
  </>
 );
};

export default DeleteCertificate;
