import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { ispFields } from "constants/isp";

import AppModal from "components/shared/modal/AppModal";

import { putEditIsp } from "redux/handlers/ispHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IIspDatas, ispData } from "types/api/IspIdApiInterface";
import { IUsersList } from "types/api/userApiInterface";

const EditIsp = ({
 id,
 contacts,
 servers,
 ispData,
 reloadData,
 closeMenu
}: {
 id: number;
 contacts: Array<IUsersList>;
 servers: Array<{ id: number; server_name: string }>;
 ispData: IIspDatas;
 reloadData?: () => void;
 closeMenu: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);

 const handleOpen = () => {
  reset();
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const { control, handleSubmit, setValue, getValues, watch, reset } = useForm({
  defaultValues: {
   description: ispData.description || "",
   username: ispData.username || "",
   passwordApi: ispData.api_password || "",
   passwordIsp: ispData.isp_password || "",
   passwordSql: ispData.mysql_password || "",
   passwordServer: ispData.server_password || "",
   soapLocation: ispData.soap_location,
   soapUrl: ispData.soap_url,
   server: ispData.server_id,
   partner: contacts.find((element) => element.userid === ispData.userid)?.id || 0,
   webserver: ispData.webserver,
   mailserver: ispData.mailserver,
   nameserver: ispData.nameserver,
   visibleGlobally: false
  }
 });

 const onSubmit: SubmitHandler<ispData> = async (data: ispData) => {
  handleClose();
  setIsLoading(true);
  await dispatch(putEditIsp(id, data));
  setIsLoading(false);
  reloadData && reloadData();
  closeMenu();
 };

 return (
  <>
   <MenuItem onClick={handleOpen}>
    <FormattedMessage id="configurator.update.isp" />
   </MenuItem>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    handleConfirm={handleSubmit(onSubmit)}
    title={intl.formatMessage({ id: "configurator.edit.isp" })}>
    <form onSubmit={handleSubmit(onSubmit)}>
     <Stack spacing={2}>
      {ispFields.map((isp, index) => {
       return (
        <Controller
         key={`isp-field-${index}`}
         name={isp.name}
         control={control}
         rules={{
          required: isp.required
         }}
         render={({ field }) => (
          <TextField
           {...field}
           fullWidth={true}
           onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            e.stopPropagation();
           }}
           size="small"
           defaultValue={getValues(isp.name)}
           label={`${intl.formatMessage({ id: isp.label })} ${isp.required ? "*" : ""}`}
           InputLabelProps={{ shrink: true }}
           sx={{ my: 2 }}
           autoComplete="off"
          />
         )}
        />
       );
      })}
      <Controller
       name="server"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <Autocomplete
         fullWidth={true}
         autoHighlight
         sx={{ my: 2 }}
         onChange={(e, value) => {
          setValue("server", value?.id || 0);
         }}
         options={servers.map((element) => {
          return { label: element.server_name, id: element.id };
         })}
         defaultValue={{
          label: servers.find((element) => element.id === watch("server"))?.server_name,
          id: servers.find((element) => element.id === watch("server"))?.id
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           {...field}
           onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            e.stopPropagation();
           }}
           size="small"
           InputLabelProps={{ shrink: true }}
           label={`${intl.formatMessage({ id: "configurator.isp.server" })} *`}
          />
         )}
        />
       )}
      />
      <Controller
       name="partner"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <Autocomplete
         fullWidth={true}
         autoHighlight
         sx={{ my: 2 }}
         onChange={(e, value) => {
          setValue("partner", value?.id || 0);
         }}
         defaultValue={{
          label: `${contacts.find((element) => element.id === getValues("partner"))
           ?.firstname} ${contacts.find((element) => element.id === getValues("partner"))
           ?.lastname} - ${contacts.find((element) => element.id === getValues("partner"))
           ?.companyName}`,
          id: contacts.find((element) => element.id === getValues("partner"))?.id,
          key: contacts.find((element) => element.id === getValues("partner"))?.userid
         }}
         options={contacts.map((element) => {
          return {
           label:
            `${element.firstname} ${element.lastname} - ${element.companyName}` || "Not provided",
           id: element.id,
           key: element.userid
          };
         })}
         renderOption={(props, option) => {
          return (
           <li {...props} key={option.key}>
            {option.label}
           </li>
          );
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           {...field}
           size="small"
           onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            e.stopPropagation();
           }}
           InputLabelProps={{ shrink: true }}
           label={`${intl.formatMessage({ id: "configurator.isp.partner" })} *`}
          />
         )}
        />
       )}
      />
      <Stack direction={desktopViewPort ? "row" : "column"} spacing={2}>
       <Controller
        name="webserver"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            checked={watch("webserver")}
            onChange={(e) => {
             setValue("webserver", e.target.checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "configurator.isp.webserver" })}
         />
        )}
       />
       <Controller
        name="mailserver"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            checked={watch("mailserver")}
            onChange={(e) => {
             setValue("mailserver", e.target.checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "configurator.isp.mailserver" })}
         />
        )}
       />
       <Controller
        name="nameserver"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            checked={watch("nameserver")}
            onChange={(e) => {
             setValue("nameserver", e.target.checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "configurator.isp.nameserver" })}
         />
        )}
       />
      </Stack>
      <Controller
       name="visibleGlobally"
       control={control}
       render={({ field }) => (
        <FormControlLabel
         control={
          <Checkbox
           checked={watch("visibleGlobally")}
           onChange={(e) => {
            setValue("visibleGlobally", e.target.checked);
           }}
          />
         }
         label={intl.formatMessage({ id: "configurator.isp.visibleGlobally" })}
        />
       )}
      />
     </Stack>
     <Stack>
      <Typography variant="caption" fontStyle="italic">
       I campi con * sono obbligatori
      </Typography>
     </Stack>
    </form>
   </AppModal>
  </>
 );
};

export default EditIsp;
