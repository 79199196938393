import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const CloudboxIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <path
   d="M13.3846 9.53846L13.1282 13.9003C13.1144 14.1354 13.0112 14.3563 12.8399 14.5179C12.6685 14.6794 12.4419 14.7693 12.2064 14.7692H7.79364C7.55814 14.7693 7.33151 14.6794 7.16013 14.5179C6.98876 14.3563 6.88561 14.1354 6.87179 13.9003L6.61538 9.53846M9.17949 11.0769H10.8205M6.46154 9.53846H13.5385C13.7932 9.53846 14 9.33169 14 9.07692V8.46154C14 8.20677 13.7932 8 13.5385 8H6.46154C6.20677 8 6 8.20677 6 8.46154V9.07692C6 9.33169 6.20677 9.53846 6.46154 9.53846Z"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
  <path
   d="M15.5357 16.8929H15.5354H5.15298C4.11943 16.8929 3.1272 16.4743 2.39486 15.7276C1.66236 14.9808 1.25 13.9669 1.25 12.9087C1.25003 12.0689 1.50985 11.251 1.99183 10.5714C2.47376 9.89185 3.15302 9.38537 3.93237 9.12312C4.05086 9.08325 4.12168 8.96197 4.09818 8.83917C3.86897 7.64182 4.106 6.40084 4.75826 5.37933C5.41043 4.35796 6.42599 3.63679 7.58891 3.36682C8.75172 3.09688 9.97217 3.29882 10.9922 3.93065C12.0124 4.56258 12.7525 5.57542 13.0543 6.75573C13.0715 6.82291 13.1158 6.87993 13.1766 6.91318C13.2375 6.94643 13.3094 6.95292 13.3752 6.9311C13.8211 6.78323 14.2986 6.76546 14.7538 6.87973C15.2091 6.994 15.6245 7.23594 15.953 7.57887C16.2816 7.92185 16.5099 8.35217 16.6118 8.82145C16.7136 9.29074 16.6847 9.77973 16.5284 10.233C16.4845 10.3602 16.5492 10.4993 16.6747 10.5477C17.3768 10.8188 17.9646 11.3329 18.3347 12.0016C18.7048 12.6705 18.8332 13.451 18.6971 14.2071C18.561 14.9631 18.1692 15.6455 17.5913 16.1359C17.0134 16.6262 16.286 16.8937 15.5357 16.8929Z"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
 </svg>,
 "Cloudbox"
);
