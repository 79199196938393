import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postChangePartnerTax } from "redux/handlers/userHandler";

import { useAppDispatch } from "hooks/reduxHook";

const UpdateTax = ({
 id,
 tax,
 reload
}: {
 id: number;
 tax: number;
 reload: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = async () => {
  reset();
  setValue("tax", tax);
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const { control, reset, watch, handleSubmit, setValue, formState } = useForm({
  defaultValues: {
   tax: 0
  }
 });

 const onSubmit: SubmitHandler<{
  tax: number;
 }> = async (data: { tax: number }) => {
  setLoading(true);
  await dispatch(postChangePartnerTax(id, data.tax));
  handleClose();
  setLoading(false);
  reload();
 };

 const checkDisabled = (): boolean => {
  return watch("tax") < 0;
 };

 return (
  <>
   <Tooltip title={intl.formatMessage({ id: "configurator.partner.changeTax" })} placement="top">
    <IconButton onClick={handleOpen}>
     <IconSelector icon="CurrencyExchangeIcon" props={{ fontSize: "small" }} />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "configurator.partner.changeTax" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={loading || checkDisabled()}>
    <Stack>
     <Controller
      name="tax"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
       <TextField
        {...field}
        type="number"
        label={intl.formatMessage({ id: "configurator.partner.tax" })}
        InputLabelProps={{ shrink: true }}
        error={formState.isDirty && !!formState?.errors?.tax}
        helperText={
         formState.isDirty &&
         !!formState?.errors?.tax &&
         intl.formatMessage({ id: "configurator.partner.invalidtax" })
        }
       />
      )}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default UpdateTax;
