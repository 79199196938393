import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { ICloudboxApi } from "types/api/addonsApiInterface";

const initialState: ICloudboxApi = { dataset: [], totalCount: 0 };

const CloudboxReducer = createSlice({
 name: "cloudboxes",
 initialState,
 reducers: {
  setCloudboxes: (state, { payload }: PayloadAction<ICloudboxApi>) => {
   return payload;
  },
  resetCloudboxes: () => {
   return initialState;
  }
 }
});

export default CloudboxReducer;
