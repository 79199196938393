import React, { ReactElement } from "react";
import { useLocation, useParams } from "react-router-dom";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import { appLinks } from "constants/staticLinks";

import SectionsBreadCrumbs from "components/shared/breadCrumbs/SectionsBreadCrumbs";
import MobileSectionMenu from "components/shared/sideMenu/MobileSectionMenu";
import SectionMenu from "components/shared/sideMenu/SectionMenu";

import ServerWebsitePage from "./server/ServerWebsitePage";
import MailsPage from "./MailsPage";
import ZonePage from "./ZonePage";

const AppsPage = (): ReactElement => {
 const location = useLocation();
 const params = useParams<{ section: string }>();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const RenderAddon = (): ReactElement => {
  switch (params.section) {
   case "sites":
    return <ServerWebsitePage />;
   case "mail":
    return <MailsPage />;
   case "zone":
    return <ZonePage />;
   default:
    return <ServerWebsitePage />;
  }
 };
 return (
  <Container maxWidth="xl">
   <Stack mt={2}>
    <SectionsBreadCrumbs
     links={[
      { name: "home", url: "/" },
      { name: "apps", url: "/app/sites" },
      { name: params?.section || "", url: location.pathname }
     ]}
    />
   </Stack>
   <Grid container>
    {desktopViewPort ? (
     <Grid xs={desktopViewPort ? 3 : 0}>
      <Stack mt={6}>
       <SectionMenu
        menuList={appLinks.map((item) => ({
         name: item.name,
         url: `/app/${item.url}`,
         icon: item.icon
        }))}
       />
      </Stack>
     </Grid>
    ) : (
     <MobileSectionMenu links={appLinks} disabled={[]} url={"/app"} />
    )}
    <Grid xs={desktopViewPort ? 9 : 12}>
     <RenderAddon />
    </Grid>
   </Grid>
  </Container>
 );
};

export default AppsPage;
