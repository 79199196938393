import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const NetworkIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <path
   d="M9.75284 14.1164L9.99128 14.2499L9.8873 14.2448C9.61408 14.2313 9.34765 14.1921 9.09038 14.1294L9.26236 13.7837C9.41834 13.9095 9.58228 14.0208 9.75284 14.1164ZM9.8873 5.75522L9.99127 5.7501L9.75284 5.88365C9.58228 5.97917 9.41833 6.09055 9.26236 6.21635L9.09038 5.87062C9.34764 5.80794 9.61408 5.76868 9.8873 5.75522ZM10.3127 14.2448L10.2087 14.2499L10.4472 14.1164C10.6177 14.0208 10.7817 13.9095 10.9376 13.7837L11.1096 14.1294C10.8524 14.1921 10.5859 14.2313 10.3127 14.2448ZM10.3127 5.75522C10.5859 5.76868 10.8524 5.80794 11.1096 5.87062L10.9376 6.21635C10.7817 6.09055 10.6177 5.97917 10.4472 5.88365L10.2087 5.7501L10.3127 5.75522ZM10.75 1.9C10.75 2.25899 10.459 2.55 10.1 2.55C9.74101 2.55 9.45 2.25899 9.45 1.9C9.45 1.54101 9.74101 1.25 10.1 1.25C10.459 1.25 10.75 1.54101 10.75 1.9ZM17.95 6.4C17.95 6.75899 17.659 7.05 17.3 7.05C16.941 7.05 16.65 6.75899 16.65 6.4C16.65 6.04101 16.941 5.75 17.3 5.75C17.659 5.75 17.95 6.04101 17.95 6.4ZM17.95 13.6C17.95 13.959 17.659 14.25 17.3 14.25C16.941 14.25 16.65 13.959 16.65 13.6C16.65 13.241 16.941 12.95 17.3 12.95C17.659 12.95 17.95 13.241 17.95 13.6ZM10.75 18.1C10.75 18.459 10.459 18.75 10.1 18.75C9.74101 18.75 9.45 18.459 9.45 18.1C9.45 17.741 9.74101 17.45 10.1 17.45C10.459 17.45 10.75 17.741 10.75 18.1ZM3.55 13.6C3.55 13.959 3.25899 14.25 2.9 14.25C2.54101 14.25 2.25 13.959 2.25 13.6C2.25 13.241 2.54101 12.95 2.9 12.95C3.25899 12.95 3.55 13.241 3.55 13.6ZM3.55 6.4C3.55 6.75899 3.25899 7.05 2.9 7.05C2.54101 7.05 2.25 6.75899 2.25 6.4C2.25 6.04101 2.54101 5.75 2.9 5.75C3.25899 5.75 3.55 6.04101 3.55 6.4Z"
   stroke="currentColor"
   strokeWidth="1"
  />
 </svg>,
 "Network"
);
