/* eslint-disable react/style-prop-object */
import React, { ReactElement } from "react";
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import OrdersMenu from "components/profile/orders/OrdersMenu";
import AdminWrapper from "components/shared/admin/AdminWrapper";

import { getIsAdmin } from "redux/selectors/userSelector";

import { profileOrdersTableData } from "types/global/table";

const ProfileOrdersTable = ({
 rowData,
 index,
 reloadData
}: {
 rowData: profileOrdersTableData;
 index: number;
 reloadData?: () => void;
}): ReactElement => {
 const intl = useIntl();

 const isAdmin = useSelector(getIsAdmin);

 const renderChip = (data: string) => {
  switch (data) {
   case "paid":
    return (
     <Chip size="small" label={<FormattedMessage id="profile.orders.paid" />} color="success" />
    );
   case "pending":
    return (
     <Chip size="small" label={<FormattedMessage id="profile.orders.pending" />} color="primary" />
    );
   default:
    return <Chip size="small" label={data} color="error" />;
  }
 };

 return (
  <TableRow key={`host-list-${index}`}>
   <TableCell component="th" scope="row">
    {renderChip(rowData.status)}
   </TableCell>
   <TableCell component="th" scope="row">
    <Stack>
     <Typography variant="subtitle2">{rowData.order_nr}</Typography>
     <Typography variant="subtitle2">{`${intl.formatMessage({ id: "app.type" })}: ${
      rowData.type || "Not provided"
     }`}</Typography>
     <AdminWrapper>
      <Typography variant="subtitle2">{rowData.companyName || "Partner not available"}</Typography>
     </AdminWrapper>
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    <FormattedNumber style="currency" currency="EUR" value={Number(rowData.amount)} />
   </TableCell>
   <TableCell component="th" scope="row">
    {rowData.payment_date !== "Invalid date" ? (
     rowData.payment_date ? (
      <FormattedDate value={rowData.payment_date} />
     ) : (
      <FormattedMessage id="profile.orders.paymentOngoing" />
     )
    ) : (
     <FormattedMessage id="app.invalidDate" />
    )}
   </TableCell>
   <TableCell component="th" scope="row">
    {isAdmin ? (
     <AdminWrapper>
      <OrdersMenu rowData={rowData} reloadData={reloadData} />
     </AdminWrapper>
    ) : (
     <FormattedMessage id="profile.orders.noActions" />
    )}
   </TableCell>
  </TableRow>
 );
};

export default ProfileOrdersTable;
