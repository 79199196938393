import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postEditDbRemoteAccess } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

const EnableRemoteDb = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postEditDbRemoteAccess(id, true));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Tooltip title={<FormattedMessage id="docker.website.enableRemoteDb" />}>
    <IconButton onClick={() => handleOpen()}>
     <IconSelector icon="KeyIcon" />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.enableRemoteDb" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}>
    <Stack spacing={1}>
     <Alert severity="warning">
      <Typography>
       <FormattedMessage id="docker.website.useRemoteDbWarning" />
      </Typography>
     </Alert>
     <Typography>
      <FormattedMessage id="docker.website.remoteDbContinue" />
     </Typography>
    </Stack>
   </AppModal>
  </>
 );
};

export default EnableRemoteDb;
