import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { getAddonsPermissions } from "helpers/addons";

import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import Alert from "@mui/material/Alert";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import ChangeProtection from "components/addons/actions/ChangeProtection";
import IconSelector from "components/shared/images/IconSelector";

import { getAllSnapshotsForServer } from "redux/handlers/addonsHandle";

import { getSnapshotPermissions } from "redux/selectors/permissionSelector";
import { getServerDbId, getServerProviderId } from "redux/selectors/serversSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { ISnapshotDataset } from "types/api/addonsApiInterface";
import { ISnapshotPermissionApi } from "types/api/userApiInterface";

import AddSnapshotForServer from "./snapshotActions/AddSnapshotForServer";
import SnapshotMenu from "./snapshotActions/SnapshotMenu";

const ServerSnapshots = ({
 dataToUpgrade,
 resetData
}: {
 dataToUpgrade: boolean;
 resetData: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const server_id = useSelector(getServerProviderId);
 const id = useSelector(getServerDbId);
 const permissions = useSelector(getSnapshotPermissions);

 const [serverSnapshots, setServerSnapshots] = useState<Array<ISnapshotDataset>>([]);

 const findPermission = (snapshot: ISnapshotDataset): ISnapshotPermissionApi | null => {
  return getAddonsPermissions(snapshot.provider, permissions || []);
 };

 useEffect(() => {
  (async () => {
   setServerSnapshots(await dispatch(getAllSnapshotsForServer(server_id)));
  })();
 }, [server_id]);

 useEffect(() => {
  (async () => {
   if (dataToUpgrade) {
    setServerSnapshots(await dispatch(getAllSnapshotsForServer(server_id)));
    resetData();
   }
  })();
 }, [dataToUpgrade, server_id]);

 return (
  <>
   <Paper elevation={0} sx={{ mt: 2, borderRadius: "10px", boxShadow: 0 }}>
    <Stack p={2} spacing={2}>
     <Stack direction="row" justifyContent={"space-between"}>
      <Stack direction="row" spacing={1}>
       <IconSelector icon="CameraAltIcon" props={{ fontSize: "medium", color: "secondary" }} />
       <Typography fontWeight="bold" fontSize={16} textTransform="uppercase">
        <FormattedMessage id="server.addons.snapshot" />
       </Typography>
      </Stack>
      <AddSnapshotForServer id={id || 0} />
     </Stack>
     {serverSnapshots.length === 0 ? (
      <Alert severity="warning" variant="outlined">
       <FormattedMessage id="server.addons.snapshotNotFound" />
      </Alert>
     ) : (
      <TableContainer component="div">
       <Table sx={{ minWidth: desktopViewPort ? 650 : 450 }} aria-label="simple table">
        <TableHead>
         <TableRow>
          <TableCell>
           <FormattedMessage id="app.status" />
          </TableCell>
          <TableCell>
           <FormattedMessage id="app.name" />
          </TableCell>
          <TableCell>
           <FormattedMessage id="app.size" />
          </TableCell>
          <TableCell>
           <FormattedMessage id="app.protection" />
          </TableCell>
          <TableCell>
           <FormattedMessage id="table.actions" />
          </TableCell>
         </TableRow>
        </TableHead>
        <TableBody>
         {serverSnapshots.map((element, index) => (
          <TableRow key={`server-snapshot-${index}`}>
           <TableCell>
            <Chip
             label={element.status}
             color={element.status === "available" ? "success" : "error"}
             size="small"
            />
           </TableCell>
           <TableCell>{element.description}</TableCell>
           <TableCell>
            <Typography variant="inherit">{`${Number(element.image_size).toFixed(
             2
            )} ${intl.formatMessage({
             id: "server.manage.gb"
            })}`}</Typography>
           </TableCell>
           <TableCell>
            {findPermission(element)?.enable_protection ? (
             <ChangeProtection id={element.id} protection={element.protection} type="snapshot" />
            ) : (
             <Chip
              icon={element.protection ? <LockOutlinedIcon /> : <LockOpenOutlinedIcon />}
              sx={{ cursor: "pointer" }}
              color={element.protection ? "success" : "error"}
              size="small"
              label={
               element.protection
                ? intl.formatMessage({ id: "addons.enabled" })
                : intl.formatMessage({ id: "addons.disabled" })
              }
             />
            )}
           </TableCell>
           <TableCell>
            <SnapshotMenu rowData={element} findedPermission={findPermission(element) || null} />
           </TableCell>
          </TableRow>
         ))}
        </TableBody>
       </Table>
      </TableContainer>
     )}
    </Stack>
   </Paper>
  </>
 );
};

export default ServerSnapshots;
