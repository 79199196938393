import React, { ReactElement } from "react";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";

import IconSelector from "components/shared/images/IconSelector";
import ActionsMenu from "components/shared/listActions/ActionsMenu";

import { hostTableData } from "types/global/table";

const HostTable = ({ rowData, index }: { rowData: hostTableData; index: number }): ReactElement => {
 const iconFilter = (host: string | null) => {
  switch (host) {
   case "web":
    return "LanguageIcon";
   case "mail":
    return "MailIcon";
   default:
    return "ZoneIcon";
  }
 };

 return (
  <TableRow key={`host-list-${index}`}>
   <TableCell component="th" scope="row">
    <Tooltip title={rowData.type} placement="top">
     <IconSelector icon={iconFilter(rowData.type)} />
    </Tooltip>
   </TableCell>
   <TableCell component="th" scope="row">
    {rowData.name}
   </TableCell>
   <TableCell component="th" scope="row">
    {rowData.ip}
   </TableCell>
   <TableCell component="th" scope="row">
    <ActionsMenu section="host" id={rowData.id} host={rowData} />
   </TableCell>
  </TableRow>
 );
};

export default HostTable;
