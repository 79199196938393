import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

import { IJobsData } from "types/api/hetznerJobs";

export const getJobState = (state: AppState) => state.serversJobqueue;

export const getServerJobsNumber = createSelector(getJobState, (jobs) => {
 return jobs?.totalCount;
});

export const getServerJobsList = createSelector(getJobState, (jobs) => {
 return jobs?.dataset.map((job: IJobsData) => {
  return {
   action: job.action,
   id: job.id,
   response: job.response,
   retry: job.retry,
   status: job.status,
   userid: job.userid
  };
 });
});
