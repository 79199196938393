import React, { useState } from "react";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";

import DeleteSnapshot from "components/addons/snapshots/actions/DeleteSnapshot";
import IconSelector from "components/shared/images/IconSelector";

import { ISnapshotDataset } from "types/api/addonsApiInterface";
import { ISnapshotPermissionApi } from "types/api/userApiInterface";

import RebuildSnapshot from "./RebuildSnapshot";

const SnapshotMenu = ({
 rowData,
 findedPermission
}: {
 rowData: ISnapshotDataset;
 findedPermission: ISnapshotPermissionApi | null;
}) => {
 const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
 const open = Boolean(anchorEl);

 const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
  setAnchorEl(null);
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <IconSelector icon="MenuIcon" />
   </Button>
   <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
     "aria-labelledby": "basic-button"
    }}>
    <Stack>
     {findedPermission?.rebuild_server && (
      <RebuildSnapshot id={rowData.id} closeMenu={handleClose} />
     )}
     {findedPermission?.delete && (
      <DeleteSnapshot id={rowData.id} disabled={rowData.protection} closeMenu={handleClose} />
     )}
    </Stack>
   </Menu>
  </>
 );
};

export default SnapshotMenu;
