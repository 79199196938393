import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const MenuIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <circle cx="9" cy="9" r="2" fill="currentColor" />
  <circle cx="9" cy="4" r="2" fill="currentColor" />
  <circle cx="9" cy="14" r="2" fill="currentColor" />
 </svg>,
 "Menu"
);
