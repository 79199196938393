import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import notificationReducer from "redux/reducers/notificationReducer";

import { ApiService } from "service/ApiService";

export const postSyncronizeSocket =
 (section: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/socket/initiallogs", "POST", {
    section: section
   });
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to syncronize socket - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };
