import React, { ReactElement, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import PersonIcon from "@mui/icons-material/Person";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SmartToyIcon from "@mui/icons-material/SmartToy";

import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { getAllMessages, getAllUsers, postCloseTicket } from "redux/handlers/chatHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { ChatMessage, IUsersData } from "types/api/chatApiInterface";
import { IUsersList } from "types/api/userApiInterface";

import AdminReply from "./actions/AdminReply";

const AdminVisual = ({ users }: { users: Array<IUsersList> }): ReactElement => {
 const dispatch = useAppDispatch();

 const messagesEndRef = useRef<HTMLDivElement>(null);

 const [messageView, setMessageView] = useState<boolean>(false);
 const [allMessages, setAllMessages] = useState<Array<ChatMessage>>([]);
 const [connectedUsers, setConnectedUsers] = useState<Array<IUsersData>>([]);
 const [userId, setUserId] = useState<string>("");
 const [ticketOpen, setTicketOpen] = useState<boolean>(false);

 useEffect(() => {
  (async () => {
   //setTickets(await dispatch(getTicketList()));
   setConnectedUsers(await dispatch(getAllUsers()));
  })();
 }, []);

 const scrollToBottom = () => {
  messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
 };

 useEffect(() => {
  scrollToBottom();
 }, [allMessages, messageView]);

 const handleGetMessages = async (user_id: string, open: boolean) => {
  setAllMessages((await dispatch(getAllMessages(user_id))).allMessages);
  setUserId(user_id);
  setTicketOpen(open);
  setMessageView(true);
 };

 const handleCloseTicket = async (user_id: string) => {
  await dispatch(postCloseTicket(user_id));
 };

 const reloadData = async () => {
  setAllMessages((await dispatch(getAllMessages(userId))).allMessages);
 };

 const RenderIcon = ({ role }: { role: string }) => {
  switch (role) {
   case "GPT":
    return <SmartToyIcon />;
   case "User":
    return <PersonIcon />;
   default:
    return <></>;
  }
 };

 return connectedUsers.length === 0 ? (
  <Stack height={500} p={2} spacing={1} justifyContent="center" alignItems="center">
   <Stack justifyContent="center" alignItems="center" spacing={1}>
    <SentimentSatisfiedAltIcon sx={{ fontSize: "100px", color: "#3BB660" }} />
    <Typography>
     <FormattedMessage id="chat.noTicketOpen" />
    </Typography>
   </Stack>
  </Stack>
 ) : !messageView ? (
  <Stack height={500} p={2} spacing={1} sx={{ overflowY: "scroll" }}>
   {connectedUsers.map((element, index) => (
    <Stack key={`ticket-list-${index}`}>
     <Divider />
     <Stack
      direction="row"
      onClick={() => handleGetMessages(element.userId, element.ticketOpen)}
      justifyContent="space-between"
      p={1}
      sx={{
       cursor: "pointer",
       "&:hover": {
        backgroundColor: "#F0F0F0",
        color: "#74AAE3"
       }
      }}
      alignItems="center">
      <Typography>{users.find((user) => user.userid === element.userId)?.companyName}</Typography>
      {element.ticketOpen && (
       <Button onClick={() => handleCloseTicket(element.userId)}>
        <FormattedMessage id="chat.closeTicket" />
       </Button>
      )}
     </Stack>
     <Divider />
    </Stack>
   ))}
  </Stack>
 ) : (
  <>
   <Stack height={500} p={2} pt={0} spacing={1} sx={{ overflowY: "scroll" }}>
    <Stack sx={{ p: 1, pl: 0, top: 0, position: "sticky", backgroundColor: "white" }}>
     <Stack sx={{ cursor: "pointer" }} direction="row" onClick={() => setMessageView(false)}>
      <ArrowBackIosIcon fontSize="small" />
      <Typography>
       <FormattedMessage id="chat.goBack" />
      </Typography>
     </Stack>
    </Stack>
    {allMessages.map((element, index) => {
     return (
      <Stack
       key={`chat-message-${index}`}
       alignItems={element.role === "GPT" || element.role === "User" ? "flex-start" : "flex-end"}>
       {element.role !== "Admin" && (
        <Chip
         sx={{ mb: 1, pl: 1 }}
         size="small"
         avatar={<RenderIcon role={element.role} />}
         label={element.role}
        />
       )}
       <Stack
        sx={{
         backgroundColor: element.role === "GPT" || element.role === "User" ? "#D4E6F1" : "#7FB3D5",
         borderRadius: 4
        }}
        px={2}
        py={1}>
        <Typography maxWidth={350} textAlign={"start"} sx={{ wordBreak: "break-word" }}>
         {element.text}
        </Typography>
       </Stack>
      </Stack>
     );
    })}
    <div ref={messagesEndRef} />
   </Stack>
   <Divider />
   {ticketOpen && <AdminReply reloadData={reloadData} user_id={userId} />}
  </>
 );
};

export default AdminVisual;
