export const steps = [
 {
  label: "baremetal.add.selectName",
  description: "baremetal.add.nameDescription"
 },
 {
  label: "baremetal.add.selectRam",
  description: "baremetal.add.selectRamMessage"
 },
 {
  label: "baremetal.add.selectDisk",
  description: "baremetal.add.selectDiskMessage"
 }
];
