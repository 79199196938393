import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";

import AppModal from "components/shared/modal/AppModal";

import { postCompleteTransaction } from "redux/handlers/profileOrdersHandler";

import { useAppDispatch } from "hooks/reduxHook";

const CompleteTransfer = ({
 id,
 closeMenu,
 reloadData
}: {
 id: number;
 closeMenu: () => void;
 reloadData?: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => {
  reset();
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const { control, handleSubmit, setValue, reset, watch } = useForm({
  defaultValues: {
   date: dayjs("").toString(),
   transaction: "",
   paid: true
  }
 });

 const onSubmit: SubmitHandler<{
  date: string;
  transaction: string;
  paid: boolean;
 }> = async (data: { date: string; transaction: string; paid: boolean }) => {
  setLoading(true);
  await dispatch(
   postCompleteTransaction(id, data.paid ? "paid" : "pending", data.date, data.transaction)
  );
  setLoading(false);
  reloadData && reloadData();
  handleClose();
  closeMenu();
 };

 return (
  <>
   <MenuItem onClick={handleOpen}>
    <FormattedMessage id="profile.orders.completeTransfer" />
   </MenuItem>
   <AppModal
    open={open}
    close={handleClose}
    disabled={loading || watch("date") === ""}
    title={intl.formatMessage({ id: "profile.orders.completeTransfer" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}>
    <form onSubmit={handleSubmit(onSubmit)}>
     <Stack>
      <Controller
       name={"date"}
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) =>
        desktopViewPort ? (
         <DatePicker
          {...field}
          label={intl.formatMessage({ id: "profile.orders.dateOfTransfer" })}
          disableFuture
          views={["day", "month", "year"]}
          timezone="system"
          onChange={(newValue) => setValue("date", dayjs(newValue).toDate().toDateString() || "")}
         />
        ) : (
         <MobileDatePicker
          {...field}
          label={intl.formatMessage({ id: "profile.orders.dateOfTransfer" })}
          disableFuture
          views={["day", "month", "year"]}
          timezone="system"
          onChange={(newValue) => setValue("date", dayjs(newValue).toDate().toDateString() || "")}
         />
        )
       }
      />
      <Controller
       name={"transaction"}
       control={control}
       render={({ field }) => (
        <TextField
         {...field}
         onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          e.stopPropagation();
         }}
         fullWidth={true}
         label={intl.formatMessage({ id: "profile.orders.transactionNotes" })}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         autoComplete="off"
        />
       )}
      />
      <Controller
       name="paid"
       control={control}
       render={({ field }) => (
        <FormControlLabel
         {...field}
         control={
          <Checkbox
           checked={watch("paid")}
           onChange={(e) => {
            setValue("paid", e.target.checked);
           }}
          />
         }
         label={intl.formatMessage({ id: "profile.orders.setPaid" })}
        />
       )}
      />
     </Stack>
    </form>
   </AppModal>
  </>
 );
};

export default CompleteTransfer;
