import React, { ReactElement } from "react";
import { searchOs } from "helpers/server";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { IOsDetails } from "types/api/serversApiInterface";

const RebuildBox = ({
 os,
 selected,
 selectOs
}: {
 os: IOsDetails;
 selected: boolean;
 selectOs: (os: IOsDetails) => void;
}): ReactElement => {
 return (
  <Paper
   sx={{
    px: 2,
    py: 1,
    minWidth: 150,
    cursor: selected ? "default" : "pointer",
    backgroundColor: selected ? "#c8e6c9" : "white"
   }}
   onClick={() => selectOs(os)}>
   <Stack spacing={1}>
    <Stack>
     <img src={searchOs(os.name)} alt="os-logo" height={40} />
    </Stack>
    <Typography textTransform="uppercase" fontWeight="bold">
     {os.os_flavor}
    </Typography>
    <Typography variant="caption" fontStyle="italic">{`Version: ${os.os_version}`}</Typography>
   </Stack>
  </Paper>
 );
};

export default RebuildBox;
