import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const OrdersIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <circle cx="10" cy="10" r="10" fill="transparent" />
  <path
   fillRule="evenodd"
   clipRule="evenodd"
   d="M5 5.5C5 5.22386 5.22386 5 5.5 5H7.5C7.77614 5 8 5.22386 8 5.5V7.5C8 7.77614 7.77614 8 7.5 8H5.5C5.22386 8 5 7.77614 5 7.5V5.5ZM5.5 5.5H7.5V7.5H5.5L5.5 5.5Z"
   fill="currentColor"
  />
  <path
   fillRule="evenodd"
   clipRule="evenodd"
   d="M5 9.5C5 9.22386 5.22386 9 5.5 9H7.5C7.77614 9 8 9.22386 8 9.5V11.5C8 11.7761 7.77614 12 7.5 12H5.5C5.22386 12 5 11.7761 5 11.5V9.5ZM5.5 9.5H7.5V11.5H5.5L5.5 9.5Z"
   fill="currentColor"
  />
  <path
   fillRule="evenodd"
   clipRule="evenodd"
   d="M5.5 13C5.22386 13 5 13.2239 5 13.5V15.5C5 15.7761 5.22386 16 5.5 16H7.5C7.77614 16 8 15.7761 8 15.5V13.5C8 13.2239 7.77614 13 7.5 13H5.5ZM7.5 13.5H5.5L5.5 15.5H7.5V13.5Z"
   fill="currentColor"
  />
  <path d="M15 6H9V7H15V6Z" fill="currentColor" />
  <path d="M9 10H15V11H9V10Z" fill="currentColor" />
  <path d="M15 14H9V15H15V14Z" fill="currentColor" />
 </svg>,
 "Orders"
);
