import React, { ReactElement, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux/es/exports";
import { useParams } from "react-router-dom";

import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import { getMailsList } from "redux/selectors/mailsSelector";

import MailAlias from "./sections/MailAlias";
import MailAutoresponder from "./sections/MailAutoresponder";
import MailBlacklist from "./sections/MailBlacklist";
import MailDetails from "./sections/MailDetails";
import MailForward from "./sections/MailForward";
import MailMailboxes from "./sections/MailsMailboxes";
import MailWhitelist from "./sections/MailWhitelist";

const MailDetailsPage = (): ReactElement => {
 const intl = useIntl();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));
 const params = useParams<{ mail_id: string; section: string }>();
 const maildetails = useSelector(getMailsList).find(
  (element) => element.id === Number(params.mail_id)
 );

 const [section, setSection] = useState<number>(0);

 const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
  setSection(newValue);
 };

 const RenderTabContent = (): ReactElement => {
  switch (section) {
   case 0:
    return <MailDetails />;
   case 1:
    return <MailMailboxes />;
   case 2:
    return <MailAutoresponder />;
   case 3:
    return <MailWhitelist id={maildetails?.id || 0} spamfilter={maildetails?.spam_filter || []} />;
   case 4:
    return <MailBlacklist id={maildetails?.id || 0} spamfilter={maildetails?.spam_filter || []} />;
   case 5:
    return <MailAlias />;
   case 6:
    return <MailForward />;
   default:
    return <MailDetails />;
  }
 };

 return (
  <>
   <Grid container spacing={2}>
    <Grid xs={12}>
     <Paper
      elevation={0}
      sx={{ mt: 3, pb: 0.7, minHeight: 216, borderRadius: "10px", boxShadow: 0 }}>
      <Tabs
       value={section}
       onChange={handleChangeTab}
       variant={desktopViewPort ? "standard" : "scrollable"}
       scrollButtons={true}
       allowScrollButtonsMobile>
       <Tab label={intl.formatMessage({ id: "mails.details" })} />
       <Tab label={intl.formatMessage({ id: "mails.mailboxes" })} />
       <Tab label={intl.formatMessage({ id: "mails.autoresponder" })} />
       <Tab label={intl.formatMessage({ id: "mails.whitelist" })} />
       <Tab label={intl.formatMessage({ id: "mails.blacklist" })} />
       <Tab label={intl.formatMessage({ id: "mails.alias" })} />
       <Tab label={intl.formatMessage({ id: "mails.forward" })} />
      </Tabs>
      <RenderTabContent />
     </Paper>
    </Grid>
   </Grid>
  </>
 );
};

export default MailDetailsPage;
