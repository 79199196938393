import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const ClipboardIcon = createSvgIcon(
 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="20" height="20" fill="transparent" />
  <path
   d="M13.9216 2.5288C13.7972 2.08855 13.5324 1.70098 13.1674 1.42502C12.8025 1.14907 12.3575 0.999831 11.9 1H9.1C8.13867 1 7.32667 1.6468 7.0784 2.5288M13.9216 2.5288C13.9729 2.70987 14 2.90213 14 3.1C14 3.28565 13.9262 3.4637 13.795 3.59498C13.6637 3.72625 13.4857 3.8 13.3 3.8H7.7C7.51435 3.8 7.3363 3.72625 7.20503 3.59498C7.07375 3.4637 7 3.28565 7 3.1C7 2.90213 7.028 2.70987 7.0784 2.5288M13.9216 2.5288C14.5245 2.57453 15.1237 2.63147 15.7201 2.70053C16.7468 2.82 17.5 3.70573 17.5 4.73987V17.1C17.5 17.657 17.2788 18.1911 16.8849 18.5849C16.4911 18.9788 15.957 19.2 15.4 19.2H5.6C5.04305 19.2 4.5089 18.9788 4.11508 18.5849C3.72125 18.1911 3.5 17.657 3.5 17.1V4.73987C3.5 3.70573 4.25227 2.82 5.27987 2.70053C5.87817 2.63126 6.4778 2.574 7.0784 2.5288"
   stroke="currentColor"
   strokeWidth="1"
   strokeLinecap="round"
   strokeLinejoin="round"
  />
 </svg>,
 "Clipboard"
);
