import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

import { IDomainDataset } from "types/api/domainsApiInterface";

export const domainsApi = (state: AppState) => state.domains;

export const getDomainsNumber = createSelector(domainsApi, (domains) => {
 return domains?.totalCount;
});

export const getDomainsList = createSelector(domainsApi, (domains) => {
 return (
  domains?.dataset.map((domain: IDomainDataset) => {
   return {
    autoRenew: domain.autorenew,
    createdAt: domain.createdAt,
    domainStatus: domain.status,
    contactAdmin: domain.contactAdmin,
    contactBilling: domain.contactBilling,
    contactRegistrant: domain.contactRegistrant,
    contactTechnical: domain.contactTechnical,
    id: domain.id,
    name: domain.domain,
    updatedAt: domain.updatedAt,
    personalName: domain.personalName,
    registrarlock: domain.registrarlock,
    expirationdate: domain.expire,
    authinfo: domain.transferauthinfo,
    ns1: domain.nameserver0,
    ns2: domain.nameserver1,
    isp_id: domain.isp_id,
    zone_id: domain.zone_id,
    status: domain.service_status,
    userid: domain.userId || "",
    provider: domain.provider || "",
    automigration: domain.automigration,
    warningicanndomainlocked: domain.warningicanndomainlocked,
    domainstatusreason: domain.domainstatusreason,
    emailVerified: domain.emailVerified,
    email: domain.emailToBeVerified
   };
  }) || []
 );
});
