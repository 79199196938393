import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { IBaremetalApi } from "types/api/baremetalApiInterface";

const initialState: IBaremetalApi = { dataset: [], totalCount: 0 };

const BaremetalReducer = createSlice({
 name: "baremetal",
 initialState,
 reducers: {
  setBaremetals: (state, { payload }: PayloadAction<IBaremetalApi>) => {
   return payload;
  },
  resetBaremetals: () => {
   return initialState;
  }
 }
});

export default BaremetalReducer;
