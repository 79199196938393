import React, { ReactElement } from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";

const SiteVersion = ({ version }: { version: string }): ReactElement => {
 const formatIcon = (): string => {
  if (version.includes("php")) {
   return "PhpIcon";
  } else {
   return "";
  }
 };

 return version ? (
  <Stack justifyContent="center" alignItems="center">
   <IconSelector icon={formatIcon()} props={{ fontSize: "large" }} />
   <Typography variant="caption">{version}</Typography>
  </Stack>
 ) : (
  <></>
 );
};

export default SiteVersion;
