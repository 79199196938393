import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import { firewallLinks } from "constants/staticLinks";

import FirewallManage from "components/addons/firewall/manageSections/FirewallManage";
import FirewallUpperPage from "components/addons/firewall/manageSections/FirewallUpperPage";
import InboundRules from "components/addons/firewall/manageSections/InboundRules";
import OutboundRules from "components/addons/firewall/manageSections/OutboundRules";
import SectionsBreadCrumbs from "components/shared/breadCrumbs/SectionsBreadCrumbs";
import MobileSectionMenu from "components/shared/sideMenu/MobileSectionMenu";
import SectionMenu from "components/shared/sideMenu/SectionMenu";

import { getAllFirewall } from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

const FirewallManagePage = (): ReactElement => {
 const dispatch = useAppDispatch();
 const location = useLocation();
 const { socket } = useContext(AppContext);
 const params = useParams<{ id: string; section: string }>();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const [reloadData, setReloadData] = useState<boolean>(true);

 useEffect(() => {
  !isNil(socket) &&
   socket.on("addons_data", (value): void => {
    if (value?.completed && value?.section === "firewall") {
     setReloadData(true);
    }
   });
  return () => {
   !isNil(socket) && socket.off("addons_data");
  };
 }, [socket]);

 useEffect(() => {
  (async () => {
   if (reloadData) {
    await dispatch(getAllFirewall(0, 10, "", "", Number(params.id)));
    setReloadData(false);
   }
  })();
 }, [reloadData]);

 const RenderFirewallPage = (): ReactElement => {
  switch (params.section) {
   case "details":
    return <FirewallManage />;
   case "inboundrules":
    return <InboundRules />;
   case "outboundrules":
    return <OutboundRules />;
   default:
    return <FirewallManage />;
  }
 };

 return (
  <Container maxWidth="xl">
   <Stack pt={1}>
    <SectionsBreadCrumbs
     links={[
      { name: "home", url: "/" },
      { name: "addons", url: "/addons/cloudbox" },
      { name: "firewall", url: "/addons/firewall" },
      { name: params?.section || "", url: location.pathname }
     ]}
    />
   </Stack>
   <Grid container>
    {desktopViewPort ? (
     <Grid xs={desktopViewPort ? 3 : 0}>
      <Stack mt={6}>
       <SectionMenu
        menuList={firewallLinks.map((item) => ({
         name: item.name,
         url: `/addons/firewall/manage/${params?.id}/${item.url}`,
         icon: item.icon
        }))}
       />
      </Stack>
     </Grid>
    ) : (
     <MobileSectionMenu
      links={firewallLinks}
      disabled={[]}
      url={`/addons/firewall/manage/${params?.id}`}
     />
    )}
    <Grid xs={desktopViewPort ? 9 : 12} mt={3}>
     <FirewallUpperPage />
     <RenderFirewallPage />
    </Grid>
   </Grid>
  </Container>
 );
};

export default FirewallManagePage;
