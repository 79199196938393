import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import { isNil } from "ramda";

import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import AppModal from "components/shared/modal/AppModal";

import { postAssignBaremetal } from "redux/handlers/baremetalHandle";

import { useAppDispatch } from "hooks/reduxHook";

import { IUsersList } from "types/api/userApiInterface";

const ChangeBaremetalPartner = ({
 id,
 partner,
 partnerList,
 closeMenu,
 reloadData
}: {
 id: number;
 partner: string;
 partnerList: Array<IUsersList>;
 closeMenu: () => void;
 reloadData: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpenModal = () => {
  reset();
  setOpenModal(true);
 };
 const handleCloseModal = () => {
  setOpenModal(false);
  closeMenu();
 };

 const { control, reset, handleSubmit, watch, setValue } = useForm({
  defaultValues: {
   partner: partner || ""
  }
 });

 const onSubmit: SubmitHandler<{ partner: string }> = async (data: { partner: string }) => {
  setLoading(true);
  await dispatch(postAssignBaremetal(id, data.partner));
  handleCloseModal();
  setLoading(false);
  reloadData();
 };

 return (
  <>
   <MenuItem onClick={() => handleOpenModal()}>
    <FormattedMessage id="configurator.baremetal.changePartner" />
   </MenuItem>
   <AppModal
    open={openModal}
    close={handleCloseModal}
    title={intl.formatMessage({ id: "configurator.baremetal.changePartner" })}
    handleClose={handleCloseModal}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={loading || watch("partner") === ""}>
    <Stack spacing={2}>
     <Controller
      name="partner"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) =>
         isNil(value) ? setValue("partner", "") : setValue("partner", value.userid || "")
        }
        options={partnerList.map((element) => {
         return {
          label: `${element.firstname} ${element.lastname} - ${element.companyName}`,
          userid: element.userid
         };
        })}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
           e.stopPropagation();
          }}
          label={<FormattedMessage id="configurator.baremetal.selectPartner" />}
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
      )}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default ChangeBaremetalPartner;
