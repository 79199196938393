import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import AppModal from "components/shared/modal/AppModal";

import { postUpdateLoadBalancerAlgorithm } from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

const UpdateAlgorithm = ({
 id,
 oldType,
 closeMenu
}: {
 id: number;
 oldType: string;
 closeMenu: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => {
  reset();
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const { control, reset, watch, setValue, handleSubmit, formState } = useForm({
  defaultValues: {
   type: oldType
  }
 });

 const onSubmit: SubmitHandler<{ type: string }> = async (data: { type: string }) => {
  setLoading(true);
  await dispatch(postUpdateLoadBalancerAlgorithm(id, data.type));
  handleClose();
  setLoading(false);
  closeMenu();
 };

 return (
  <>
   <MenuItem onClick={() => handleOpen()}>
    <FormattedMessage id="addons.loadBalancer.updateAlgorithm" />
   </MenuItem>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "addons.loadBalancer.updateAlgorithm" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={loading || watch("type") === oldType}>
    <Stack spacing={2}>
     <Controller
      name="type"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) => setValue("type", value || "")}
        options={["round_robin", "least_connections"]}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          error={formState.isDirty && !!formState?.errors?.type}
          label={<FormattedMessage id="addons.loadBalancer.algorithm" />}
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
      )}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default UpdateAlgorithm;
