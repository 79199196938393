export const steps = [
 {
  label: "sites.add.host",
  description: "sites.add.hostMessage"
 },
 {
  label: "sites.add.selectName",
  description: "sites.add.selectNameMessage"
 },
 {
  label: "sites.add.hdQuota",
  description: "sites.add.hdQuotaMessage"
 },
 {
  label: "sites.add.php",
  description: "sites.add.phpMessage"
 },
 {
  label: "sites.add.ip",
  description: "sites.add.ipMessage"
 }
];

export const backupIntervals = ["daily", "weekly", "monthly"];
export const backupCopies = ["1", "2", "3", "4", "5", "6", "7"];
