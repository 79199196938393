import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import BaremetalReducer from "redux/reducers/baremetalReducer";
import notificationReducer from "redux/reducers/notificationReducer";

import { ApiService } from "service/ApiService";

export const getAllBaremetals =
 (
  currentIndex: number,
  sizePerPage: number,
  q?: string,
  userid?: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService(
    `/barmetal/getbarmetals?page=${currentIndex}&limit=${sizePerPage}&q=${q || ""}&partner=${
     userid || ""
    }`
   );
   dispatch(BaremetalReducer.actions.setBaremetals(data || {}));
  } catch (error) {
   console.warn("FAILED TO GET BAREMETAL");
  }
 };

export const postUpdateBaremetalName =
 (id: number, name: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/barmetal/updatename", "POST", {
    id: id,
    name: name
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Renaming Bare metal",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to rename bare metal - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postAssignBaremetal =
 (id: number, userid: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/barmetal/assign", "POST", {
    id: id,
    userid: userid
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Assigning Bare metal",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to assign bare metal - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCompleteBaremetalCreation =
 (id: number, product: string, server_number: number, amount: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/barmetal/completecreation", "POST", {
    id: id,
    product: product,
    server_number: server_number,
    amount: amount
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Completing baremetal creation",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to complete bare metal creation - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCreateBaremetal =
 (name: string, dim_ram: number, dim_disk: number, disk_type: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/barmetal/create", "POST", {
    name: name,
    dim_ram: dim_ram,
    dim_disk: dim_disk,
    disk_type: disk_type
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating order for baremetal",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create order for baremetal - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postRebootBaremetal =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/barmetal/reboot", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Rebooting baremetal",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to reboot baremetal - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };
